import React from "react";
import {Checkbox, Form, Select, Row, Col, InputNumber, Card, Button} from "antd";
import {
  CaretDownOutlined, ExclamationCircleFilled
} from "@ant-design/icons";
import {
  Accordion, AccordionActions,
  AccordionDetails, AccordionSummary,
  Button as MatButton, Divider as MiDivider,
  IconButton, Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";

import {RenderPropSticky} from "react-sticky-el";
import CoatingPicker from "../CoatingPicker";
import {blue} from "@material-ui/core/colors";
import {translator} from "../../../translations/translators";

const { Option } = Select;
const { OptGroup } = Select;

const theme = createMuiTheme({
  palette: {
    primary: blue,
  }
});

export const OrderMultiEdit = (props) => {

  const {
    selectedItemsCount, handleRemoveMultipleItems, selectedAllItems,
    setSelectedAllItems, handleSelectAll, indeterminate,
    selectableItemsCount, handleMultiMaterialSelect, handleMultiMaterialClear,
    materials, selectableMaterials, multiformRef,
    selectedParameters, handleMultiThicknessSelect, handleMultiThicknessClear,
    handleMultiQuantityChange, handleMultiMaterialSurchargePerPieceChange, 
    handleMultiColorSelect, handleMultiColorClear,
    handleMultiClear, handleMultiApply, selectableThickness,
    isBulkOrder
  } = props

  const strForSearch = (str) => {
    return str
      ? str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
      : str;
  };

  return (
    <RenderPropSticky
      scrollElement={"window"}
      positionRecheckInterval={1}
    >
      {({isFixed, wrapperStyles, wrapperRef, holderStyles, holderRef}) => (
        <div ref={holderRef} style={holderStyles}>
          <div
            className={'mulSticky'}
            style={isFixed ?
              {zIndex: 1100, position: 'relative', ...wrapperStyles}
              :
              {zIndex: 5, position: 'relative', ...wrapperStyles}
            }
            ref={wrapperRef}
          >
            <Card
              style={ isFixed ?
                {
                  background: "#f1f1f1",
                  marginLeft: -10,
                  marginRight: -10,
                  borderRadius: "0 0 4px 4px",
                  borderLeft: '1px solid #b0b0b0',
                  borderRight: '1px solid #b0b0b0',
                  borderBottom: '1px solid #b0b0b0',
                  boxShadow: "0 1px 4px 1px rgba(0, 0, 0, 0.1)"
                }
                :
                {
                  background: "rgba(0,0,0,0)",
                  marginLeft: -10,
                  marginRight: -10,
                  borderRadius: "4px",
                  borderLeft: 0,
                  borderRight: 0,
                  borderBottom: 0,
                  borderTop: 0,
                  boxShadow: "none"
                }
              }
              bodyStyle={{padding: "10px 10px 10px 10px"}}
            >
              <div style={{width: "calc(100% - 30)"}}>
                <Accordion
                  style={
                    selectedItemsCount > 0 ?
                      {borderRadius: "4px", border: '1px solid #1890ff', boxShadow: 'none'}
                      :
                      {borderRadius: "4px", border: '1px solid #e0e0e0', boxShadow: 'none'}
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    style={{pointerEvents: 'auto', maxHeight: 58}}
                  >
                    <div style={{flexBasis: '50%'}}>
                      <span
                        className={"text14-600"}
                        style={{position: 'relative', top: selectedItemsCount > 0  && !isFixed ? 9 : 8}}
                      >
                        Handle multiple parts:
                      </span>
                      <span
                        className={"text14-400"}
                        style={{
                          position: 'relative',
                          top: selectedItemsCount > 0 && !isFixed ? 9 : 8,
                          marginLeft: 10
                        }}
                      >
                        {selectedItemsCount} Selected
                      </span>
                    </div>
                    <div style={{flexBasis: '50%'}}>
                      <div align={'right'}>
                        {selectedItemsCount > 0 ?
                          <ThemeProvider theme={theme}>
                          <MatButton
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation()
                              handleRemoveMultipleItems()
                            }}
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color="inherit"
                              style={{
                                textTransform: 'none',
                                fontSize: 13,
                                fontWeight: 600,
                                fontFamily: "Montserrat,sans-serif"
                              }}
                            >
                              Delete selected
                            </Typography>
                          </MatButton>
                          </ThemeProvider>
                          :
                          <div style={{height: 34}}>
                          </div>
                        }
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      alignItems: 'center',
                      borderTop: '1px solid rgba(0, 0, 0, 0.12)'
                    }}
                  >
                    <span style={{width: '100%'}}>
                      <span
                        onClick={event => {
                          event.stopPropagation();
                          const checked = !selectedAllItems
                          setSelectedAllItems(checked)
                          handleSelectAll(checked)
                        }}
                        style={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          cursor: "pointer"
                        }}
                      >
                        <Checkbox
                          className={"item-head-checkbox"}
                          onClick={event => {
                            event.stopPropagation();
                            const checked = event.target.checked
                            setSelectedAllItems(checked)
                            handleSelectAll(checked)
                          }}
                          indeterminate={indeterminate}
                          checked={selectedAllItems || selectableItemsCount === selectedItemsCount}
                        />
                        <span
                          className={"text12-500"}
                          style={{
                            paddingLeft: 6,
                            marginRight: 6
                          }}
                        >
                          Select all
                        </span>
                        <span
                          className={"text12-600"}
                          style={{
                            paddingLeft: "4px",
                            borderRadius: "15px",
                            paddingRight: "4px",
                            backgroundColor: "#eeeeee"
                          }}
                        >
                         {selectedItemsCount} / {selectableItemsCount}
                        </span>
                      </span>
                      <Form
                        name={"multiEditOrder"}
                        ref={multiformRef}
                      >
                        <Row
                          gutter={20}
                          style={{
                            marginTop: 10,
                            marginLeft: 0,
                            width: '100%'
                          }}
                        >
                          <Col span={8}>
                            <span
                              className={"text12-500-12"}>Material</span>
                            <Form.Item
                              name={"material"}
                              style={{marginBottom: 8}}
                            >
                              <Select
                                className={"materialMultiSelect"}
                                suffixIcon={<CaretDownOutlined/>}
                                getPopupContainer={trigger => trigger.parentNode}
                                placeholder={"Select material"}
                                virtual={false}
                                showSearch
                                allowClear
                                filterOption={(input, option) => {
                                  if (option.value) {
                                    return strForSearch(option.children.props.children).includes(strForSearch(input));
                                  } else {
                                    return false;
                                  }
                                }}
                                onChange={handleMultiMaterialSelect}
                                onClear={handleMultiMaterialClear}
                              >
                                {materials.map(material => {
                                  return (
                                    <OptGroup
                                      key={material.groupName}
                                      label={material.groupName}
                                    >
                                      {material.grades.map(t => {
                                        const disabled = !selectableMaterials.has(t.grade)
                                        return (
                                          <Option
                                            key={t.grade}
                                            value={t.grade}
                                            disabled={disabled}
                                          >
                                            <span
                                              className={"text12-600-colorless"}
                                              style={disabled ?
                                                {}
                                                :
                                                {color: "#333f48"}
                                              }
                                            >
                                              {translator(t.grade)}
                                            </span>
                                          </Option>
                                        )
                                      })}
                                    </OptGroup>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <React.Fragment>
                              <span
                                className={"text12-500-12"}>Thickness</span>
                              <Form.Item
                                name={'thickness'}
                                style={{marginBottom: 8}}
                              >
                                <Select
                                  className={"thicknessMultiSelect"}
                                  suffixIcon={<CaretDownOutlined/>}
                                  disabled={!selectedParameters.material}
                                  getPopupContainer={trigger => trigger.parentNode}
                                  placeholder={"Select thickness"}
                                  virtual={false}
                                  onChange={handleMultiThicknessSelect}
                                  onClear={handleMultiThicknessClear}
                                  allowClear
                                >
                                  {selectedParameters.material &&
                                    (selectedParameters.material.thickness.map(item => {
                                    const disabled = !selectableThickness.has(item.id)
                                      return (
                                        <Option disabled={disabled} key={item.id} value={item.thickness}>
                                          <span
                                            className={"text12-600-colorless"}
                                            style={disabled ?
                                              {}
                                              :
                                              {color: "#333f48"}
                                            }
                                          >
                                            {item.thickness} mm
                                          </span>
                                        </Option>
                                      )
                                    }))}
                                </Select>
                              </Form.Item>
                            </React.Fragment>
                          </Col>
                          <Col span={6}>
                            <React.Fragment>
                              <span
                                className={"text12-500-12"}>Coating</span>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: "space-between",
                                  width: '100%'
                                }}
                              >
                                <div>
                                  <div style={{
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignContent: 'right'
                                  }}>
                                    <CoatingPicker
                                      changeColor={handleMultiColorSelect}
                                      hasCoating={selectedParameters.coating.hasCoating}
                                      colorRAL={selectedParameters.coating.colorRAL}
                                      colorGlossId={selectedParameters.coating.colorGlossId}
                                      ralRow={selectedParameters.coating.ralRow}
                                      ralHex={selectedParameters.coating.ralHex}
                                      isMultiEdit={true}
                                    />
                                    {selectedParameters.coating.hasCoating &&
                                      <IconButton
                                        size={'small'}
                                        aria-label="clear"
                                        style={{
                                          marginLeft: 5,
                                          width: 25,
                                          height: 25,
                                          marginRight: -6,
                                          marginTop: 4
                                        }}
                                        onClick={() => {
                                          handleMultiColorClear()
                                        }}
                                      >
                                        <CloseIcon style={{
                                          fontSize: 14,
                                          color: '#fc505a'
                                        }}/>
                                      </IconButton>
                                    }
                                  </div>
                                </div>
                                <div>
                                </div>
                              </div>
                            </React.Fragment>
                          </Col>
                        </Row>
                        <Row 
                          gutter={20}
                          style={{
                            marginTop: 10,
                            marginLeft: 0,
                            width: '100%'
                          }}>
                          <Col span={4}>
                            <span className={"text12-500-12"}>Quantity</span>
                            <Form.Item
                                name={"quantity"}
                                style={{marginBottom: 8}}
                                className={"quantity-field"}
                            >
                              <InputNumber
                                  className={"item-number-input"}
                                  placeholder={"Enter quantity"}
                                  type={"number"}
                                  style={{width: '100%'}}
                                  min={1}
                                  max={10000}
                                  step={1}
                                  onChange={handleMultiQuantityChange}
                              />
                            </Form.Item>
                          </Col>

                          { isBulkOrder && 
                            <Col span={5}>
                              <span
                                className={"text12-500-12"}>Material Surcharge</span>
                              <Form.Item
                                name={"materialSurchargePerPiece"}
                                style={{marginBottom: 8}}
                                className={"quantity-field"}
                              >
                                <InputNumber
                                  className={"item-number-input"}
                                  placeholder={"Material surcharge"}
                                  type={"number"}
                                  style={{width: '100%'}}
                                  max={100000}
                                  step={1}
                                  onChange={handleMultiMaterialSurchargePerPieceChange}
                                />
                              </Form.Item>
                            </Col>
                          }
                        </Row>
                      </Form>
                    </span>
                  </AccordionDetails>
                  <MiDivider/>
                  <AccordionActions style={{justifyContent: 'space-between'}}>
                    <div align={'left'} style={{marginLeft: 10}}>
                      {selectedParameters.thickness &&
                        <span className={"multiNoteText"}>
                          <ExclamationCircleFilled style={{marginRight: 5}}/>
                          Note: Thickness won't be applied to items whose thickness was automatically detected
                        </span>
                      }
                    </div>
                    <div>
                      <Button
                        onClick={handleMultiClear}
                        style={{marginRight: 8}}
                      >
                        <span style={{ fontSize: 13, fontWeight: 500, textAlign: "center" }}>Clear</span>
                      </Button>
                        <Button
                          disabled={selectedItemsCount === 0}
                          onClick={handleMultiApply}
                          style={{marginRight: 8}}
                          type={"primary"}
                        >
                          <span style={{ fontSize: 13, fontWeight: 500, textAlign: "center" }}>Apply</span>
                        </Button>
                    </div>
                  </AccordionActions>
                </Accordion>
              </div>
            </Card>
          </div>
        </div>
      )}
    </RenderPropSticky>
  )
}