import {
    CUSTOMER_SETTINGS_SET_EDITING_CUSTOMER_SETTINGS,
    CUSTOMER_SETTINGS_SET_RESETTING_PASSWORD
} from '../actions/actionTypes'
import initialState from './initialState'

const customerSettingsReducer = (
    state = initialState.customerSettings,
    action
) => {
    switch (action.type) {
        case CUSTOMER_SETTINGS_SET_EDITING_CUSTOMER_SETTINGS:
            return { ...state, editingCustomerSettings: action.payload }
        case CUSTOMER_SETTINGS_SET_RESETTING_PASSWORD:
            return { ...state, resettingPassword: action.payload}
        default:
            return state
    }
}

export default customerSettingsReducer
