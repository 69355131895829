import {
  ORDER_SET_ORDER,
  ORDER_RESET_ORDER,
  ORDERS_REMOVE_DRAFT_ORDER,
  ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS,
  ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS_PAGINATION,
  ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS,
  ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS_PAGINATION,
  ORDERS_SET_CUSTOMER_PENDING_ORDERS,
  ORDERS_SET_CUSTOMER_PENDING_ORDERS_PAGINATION,
  ORDERS_SET_DRAFT_ORDERS,
  ORDERS_SET_IS_CREATING_DRAFT_ORDER,
  ORDERS_SET_IS_FETCHING_CUSTOMER_ACCEPTED_ORDERS,
  ORDERS_SET_IS_FETCHING_CUSTOMER_ARCHIVED_ORDERS,
  ORDERS_SET_IS_FETCHING_CUSTOMER_PENDING_ORDERS,
  ORDERS_SET_IS_FETCHING_DRAFT_ORDER,
  ORDERS_SET_IS_FETCHING_DRAFT_ORDERS,
  ORDERS_SET_IS_FETCHING_PRODUCER_ACCEPTED_ORDERS,
  ORDERS_SET_IS_FETCHING_PRODUCER_ARCHIVED_ORDERS,
  ORDERS_SET_IS_FETCHING_PRODUCER_PENDING_ORDERS,
  ORDERS_SET_IS_REMOVING_DRAFT_ORDER,
  ORDERS_SET_PRODUCER_ACCEPTED_ORDERS,
  ORDERS_SET_PRODUCER_ACCEPTED_ORDERS_PAGINATION,
  ORDERS_SET_PRODUCER_ARCHIVED_ORDERS,
  ORDERS_SET_PRODUCER_ARCHIVED_ORDERS_PAGINATION,
  ORDERS_SET_PRODUCER_PENDING_ORDERS,
  ORDERS_SET_PRODUCER_PENDING_ORDERS_PAGINATION,
  ORDERS_SET_PARTS_TO_ANALYZE,
  ORDERS_SET_IS_FETCHING_PENDING_ORDER,
  ORDERS_SET_PENDING_ORDER,
  ORDERS_SET_IS_ADDING_NEW_PART,
  ORDERS_SET_PENDING_ORDER_PRICE,
  ORDERS_SET_PENDING_ORDER_PARTS,
  ORDERS_SET_IS_FETCHING_PENDING_ORDER_PARTS,
  ORDERS_SET_IS_EDITING_PENDING_ORDER,
  ORDERS_SET_PENDING_ORDER_DELIVERY_DATE,
  ORDERS_SET_PENDING_ORDER_STAGE,
  ORDERS_SET_IS_RESOLVING_PENDING_ORDER,
  ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_ORDERS,
  ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_ORDERS,
  ORDERS_SET_IS_FETCHING_PRODUCER_AWAITING_CONFIRMATION_ORDERS,
  ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_PAGINATION,
  ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_ORDERS,
  ORDERS_SET_IS_REVERTING_ORDER,
  ORDERS_SET_IS_FETCHING_AWAITING_CHANGES,
  ORDERS_SET_AWAITING_CHANGES,
  ORDERS_SET_IS_FETCHING_RECENT_CHANGES,
  ORDERS_SET_RECENT_CHANGES,
  ORDERS_SET_IS_GENERATING_ZIP,
  ORDERS_SET_IS_FETCHING_INTERNAL_DRAFT_ORDER,
  BULK_ODER_RESET_BULK_ODER,
  BULK_ODER_SET_BULK_ORDER,
  BULK_ORDER_SET_PARTS_TO_ANALYZE,
  ORDERS_SET_INTERNAL_ARCHIVED_ORDERS,
  ORDERS_SET_IS_ARCHIVING_CALCULATION,
  ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION,
  ORDERS_SET_ARCHIVED_CALCULATION,
  ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION_CUSTOMERS,
  ORDERS_SET_ARCHIVED_CALCULATION_CUSTOMERS,
  ORDERS_SET_PENDING_ORDER_PART_PRODUCED_QUANTITY,
  ORDERS_SET_FILES_TO_UPLOAD
} from "./actionTypes";
import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import history from "../utils/history";
import {getAvailableThicknesses} from "../utils/material";
import {forceLogoutUser} from "./authActions";

const fetchProducerPendingOrders = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_FETCHING_PRODUCER_PENDING_ORDERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/order/pending?pageRowCount=${payload.pagination.pageSize}&pageIndex=${payload.page}&orderName=${payload.orderName}&customerName=${payload.customerName}&sortField=${payload.sortField}&sortOrder=${payload.sortDirection}`)
    .then(response => {
      const pagination = { ...payload.pagination };
      pagination.total = response.data.totalCount;

      dispatch({
        type: ORDERS_SET_PRODUCER_PENDING_ORDERS_PAGINATION,
        payload: pagination,
      })

      dispatch({
        type: ORDERS_SET_PRODUCER_PENDING_ORDERS,
        payload: response.data.orders,
      })

      dispatch({
        type: ORDERS_SET_IS_FETCHING_PRODUCER_PENDING_ORDERS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_PRODUCER_PENDING_ORDERS,
          payload: false,
        })
      }
    })
}

const fetchProducerAwaitingConfirmationOrders = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_FETCHING_PRODUCER_AWAITING_CONFIRMATION_ORDERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/order/awaiting-confirmation?pageRowCount=${payload.pagination.pageSize}&pageIndex=${payload.page}&orderName=${payload.orderName}&customerName=${payload.customerName}&sortField=${payload.sortField}&sortOrder=${payload.sortDirection}`)
    .then(response => {
      const pagination = { ...payload.pagination };
      pagination.total = response.data.totalCount;

      dispatch({
        type: ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_PAGINATION,
        payload: pagination,
      })

      dispatch({
        type: ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_ORDERS,
        payload: response.data.orders,
      })

      dispatch({
        type: ORDERS_SET_IS_FETCHING_PRODUCER_AWAITING_CONFIRMATION_ORDERS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_PRODUCER_AWAITING_CONFIRMATION_ORDERS,
          payload: false,
        })
      }
    })
}

const fetchProducerAcceptedOrders = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_FETCHING_PRODUCER_ACCEPTED_ORDERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/order/accepted?pageRowCount=${payload.pagination.pageSize}&pageIndex=${payload.page}&orderName=${payload.orderName}&customerName=${payload.customerName}&sortField=${payload.sortField}&sortOrder=${payload.sortDirection}&state=${payload.state}`)
    .then(response => {
      const pagination = { ...payload.pagination };
      pagination.total = response.data.totalCount;

      dispatch({
        type: ORDERS_SET_PRODUCER_ACCEPTED_ORDERS_PAGINATION,
        payload: pagination,
      })

      dispatch({
        type: ORDERS_SET_PRODUCER_ACCEPTED_ORDERS,
        payload: response.data.orders,
      })

      dispatch({
        type: ORDERS_SET_IS_FETCHING_PRODUCER_ACCEPTED_ORDERS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_PRODUCER_ACCEPTED_ORDERS,
          payload: false,
        })
      }
    })
}

const fetchProducerArchivedOrders = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_FETCHING_PRODUCER_ARCHIVED_ORDERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/order/completed?pageRowCount=${payload.pagination.pageSize}&pageIndex=${payload.page}&orderName=${payload.orderName}&customerName=${payload.customerName}&sortField=${payload.sortField}&sortOrder=${payload.sortDirection}&state=${payload.state}`)
    .then(response => {
      const pagination = { ...payload.pagination };
      pagination.total = response.data.totalCount;

      dispatch({
        type: ORDERS_SET_PRODUCER_ARCHIVED_ORDERS_PAGINATION,
        payload: pagination,
      })

      dispatch({
        type: ORDERS_SET_PRODUCER_ARCHIVED_ORDERS,
        payload: response.data.orders,
      })

      dispatch({
        type: ORDERS_SET_IS_FETCHING_PRODUCER_ARCHIVED_ORDERS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_PRODUCER_ARCHIVED_ORDERS,
          payload: false,
        })
      }
    })
}

const fetchCustomerAwaitingConfirmationOrders = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_ORDERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/order/awaiting-confirmation`)
    .then(response => {

      dispatch({
        type: ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_ORDERS,
        payload: response.data.orders,
      })

      dispatch({
        type: ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_ORDERS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_ORDERS,
          payload: false,
        })
      }
    })
}

const fetchCustomerPendingOrders = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_FETCHING_CUSTOMER_PENDING_ORDERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/order/pending?pageRowCount=${payload.pagination.pageSize}&pageIndex=${payload.page}&orderName=${payload.orderName}&sortField=${payload.sortField}&sortOrder=${payload.sortDirection}`)
    .then(response => {
      const pagination = { ...payload.pagination };
      pagination.total = response.data.totalCount;

      dispatch({
        type: ORDERS_SET_CUSTOMER_PENDING_ORDERS_PAGINATION,
        payload: pagination,
      })

      dispatch({
        type: ORDERS_SET_CUSTOMER_PENDING_ORDERS,
        payload: response.data.orders,
      })

      dispatch({
        type: ORDERS_SET_IS_FETCHING_CUSTOMER_PENDING_ORDERS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_CUSTOMER_PENDING_ORDERS,
          payload: false,
        })
      }
    })
}

const fetchCustomerAcceptedOrders = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_FETCHING_CUSTOMER_ACCEPTED_ORDERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/order/accepted?pageRowCount=${payload.pagination.pageSize}&pageIndex=${payload.page}&orderName=${payload.orderName}&sortField=${payload.sortField}&sortOrder=${payload.sortDirection}&state=${payload.state}`)
    .then(response => {
      const pagination = { ...payload.pagination };
      pagination.total = response.data.totalCount;

      dispatch({
        type: ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS_PAGINATION,
        payload: pagination,
      })

      dispatch({
        type: ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS,
        payload: response.data.orders,
      })

      dispatch({
        type: ORDERS_SET_IS_FETCHING_CUSTOMER_ACCEPTED_ORDERS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_CUSTOMER_ACCEPTED_ORDERS,
          payload: false,
        })
      }
    })
}

const fetchCustomerArchivedOrders = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_FETCHING_CUSTOMER_ARCHIVED_ORDERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/order/completed?pageRowCount=${payload.pagination.pageSize}&pageIndex=${payload.page}&orderName=${payload.orderName}&sortField=${payload.sortField}&sortOrder=${payload.sortDirection}&state=${payload.state}`)
    .then(response => {
      const pagination = { ...payload.pagination };
      pagination.total = response.data.totalCount;

      dispatch({
        type: ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS_PAGINATION,
        payload: pagination,
      })

      dispatch({
        type: ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS,
        payload: response.data.orders,
      })

      dispatch({
        type: ORDERS_SET_IS_FETCHING_CUSTOMER_ARCHIVED_ORDERS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_CUSTOMER_ARCHIVED_ORDERS,
          payload: false,
        })
      }
    })
}

const fetchDraftOrders = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_FETCHING_DRAFT_ORDERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/order/draft`)
    .then(response => {

      dispatch({
        type: ORDERS_SET_DRAFT_ORDERS,
        payload: response.data,
      })

      dispatch({
        type: ORDERS_SET_IS_FETCHING_DRAFT_ORDERS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_DRAFT_ORDERS,
          payload: false,
        })
      }
    })
}

const removeDraftOrder = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_REMOVING_DRAFT_ORDER,
    payload: {value: true, id: payload.draftId}
  })

  return http
    .delete(`${API_BASE_URL}/order/draft/${payload.draftId}`)
    .then(() => {
      dispatch({
        type: ORDERS_REMOVE_DRAFT_ORDER,
        payload: payload.draftId,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_REMOVING_DRAFT_ORDER,
          payload: {value: false, id: payload.draftId},
        })
      }
    })
}

const createNewDraftOrder = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_CREATING_DRAFT_ORDER,
    payload: true
  })

  dispatch({
    type: ORDER_RESET_ORDER,
    payload: null
  })

  return http
    .get(`${API_BASE_URL}/order/draft/new`)
    .then(response => {

      dispatch({
        type: ORDER_SET_ORDER,
        payload: response.data,
      })

      dispatch({
        type: ORDERS_SET_IS_CREATING_DRAFT_ORDER,
        payload: false,
      })

      history.push(`/draft?order=${response.data.id}`)

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_CREATING_DRAFT_ORDER,
          payload: false,
        })
      }
    })
}

const createNewDraftOrderWithFiles = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_CREATING_DRAFT_ORDER,
    payload: true
  })

  dispatch({
    type: ORDER_RESET_ORDER,
    payload: null
  })

  return http
    .get(`${API_BASE_URL}/order/draft/new`)
    .then(response => {

      dispatch({
        type: ORDER_SET_ORDER,
        payload: response.data,
      })

      dispatch({
        type: ORDERS_SET_IS_CREATING_DRAFT_ORDER,
        payload: false,
      })

      dispatch({
        type: ORDERS_SET_FILES_TO_UPLOAD,
        payload: payload,
      })

      history.push(`/draft?order=${response.data.id}`)

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_CREATING_DRAFT_ORDER,
          payload: false,
        })
      }
    })
}

const resetFilesToUpload = () => dispatch => {
  dispatch({
    type: ORDERS_SET_FILES_TO_UPLOAD,
    payload: [],
  })
}

const fetchDraftOrder = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_FETCHING_DRAFT_ORDER,
    payload: true
  })

  dispatch({
    type: ORDER_RESET_ORDER,
    payload: null
  })

  return http
    .get(`${API_BASE_URL}/order/draft/${payload.id}`)
    .then(response => {
      const toProcess = response.data.parts.filter(item => item.state === "processing" || item.state === "uploaded").length
      const toAnalyze = response.data.parts.filter(item => item.state === "uploaded")

      const order = {
        ...response.data,
        toProcess: toProcess,
        currentlyUploaded: toProcess,
        currentlyProcessed: 0,
        selectableMaterials: response.data.selectableGrades || [],
        parts: response.data.parts.map(item => {
          if(item.state === "processed" && (item.fileType === "dxf" || item.processingType === "manual")) {
            return {
              ...item,
              availableThicknesses: getAvailableThicknesses(item.fileType, item.processingType, response.data.materials, item.selectedMaterial, item.materials)
            }
          }
          return item
        })
      }

      // console.log(order)

      dispatch({
        type: ORDER_SET_ORDER,
        payload: order,
      })

      if(toAnalyze.length > 0) {
        dispatch({
          type: ORDERS_SET_PARTS_TO_ANALYZE,
          payload: toAnalyze,
        })
      }

      dispatch({
        type: ORDERS_SET_IS_FETCHING_DRAFT_ORDER,
        payload: false,
      })

      if(payload.withRedirect) {
        history.push(`/draft?order=${response.data.id}`)
      }
      else {
        return order
      }
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_DRAFT_ORDER,
          payload: false,
        })
      }
    })
}

const fetchInternalDraftOrder = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_FETCHING_INTERNAL_DRAFT_ORDER,
    payload: true
  })

  dispatch({
    type: BULK_ODER_RESET_BULK_ODER,
    payload: null
  })

  return http
    .get(`${API_BASE_URL}/internal-order/draft`)
    .then(response => {
      const internalOrder = response.data.internalOrder
      const toProcess = internalOrder.parts.filter(item => item.state === "processing" || item.state === "uploaded").length
      const toAnalyze = internalOrder.parts.filter(item => item.state === "uploaded")

      const order = {
        ...internalOrder,
        toProcess: toProcess,
        currentlyUploaded: toProcess,
        currentlyProcessed: 0,
        selectableMaterials: internalOrder.selectableGrades || [],
        parts: internalOrder.parts.map(item => {
          if (item.state === "processed" && (item.fileType === "dxf" || item.processingType === "manual")) {
            return {
              ...item,
              availableThicknesses: getAvailableThicknesses(item.fileType, item.processingType, internalOrder.materials, item.selectedMaterial, item.materials)
            }
          }
          return item
        })
      }

      dispatch({
        type: BULK_ODER_SET_BULK_ORDER,
        payload: order,
      })

      if(toAnalyze.length > 0) {
        dispatch({
          type: BULK_ORDER_SET_PARTS_TO_ANALYZE,
          payload: toAnalyze,
        })
      }

      dispatch({
        type: ORDERS_SET_IS_FETCHING_INTERNAL_DRAFT_ORDER,
        payload: false,
      })

      return order
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_INTERNAL_DRAFT_ORDER,
          payload: false,
        })
      }
    })
}

const resetToAnalyze = () => dispatch => {
  dispatch({
    type: ORDERS_SET_PARTS_TO_ANALYZE,
    payload: [],
  })
}

const fetchProcessingParts = payload => dispatch => {

}

const fetchPendingOrder = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_FETCHING_PENDING_ORDER,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/order/${payload.type}/${payload.id}`)
    .then(response => {
      dispatch({
        type: ORDERS_SET_PENDING_ORDER,
        payload: response.data.order
      })

      dispatch({
        type: ORDERS_SET_IS_FETCHING_PENDING_ORDER,
        payload: false
      })

      return response.data.order
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        //message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_PENDING_ORDER,
          payload: false
        })
        throw error
      }
    })
}

const addNewPart = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_ADDING_NEW_PART,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/order/pending/${payload.orderId}/add/${payload.data.type}`, {...payload.data.data})
    .then(response => {
      dispatch({
        type: ORDERS_SET_IS_ADDING_NEW_PART,
        payload: false
      })
      dispatch({
        type: ORDERS_SET_PENDING_ORDER_STAGE,
        payload: "edited"
      })
      dispatch({
        type: ORDERS_SET_PENDING_ORDER_PRICE,
        payload: response.data
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_ADDING_NEW_PART,
          payload: false
        })
        throw error
      }
    })
}

const fetchPendingOrderParts = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_FETCHING_PENDING_ORDER_PARTS,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/order/pending/${payload.id}`)
    .then(response => {

      dispatch({
        type: ORDERS_SET_PENDING_ORDER_PARTS,
        payload: response.data.order
      })

      dispatch({
        type: ORDERS_SET_IS_FETCHING_PENDING_ORDER_PARTS,
        payload: false
      })

      return response.data.order
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_PENDING_ORDER_PARTS,
          payload: false
        })
        throw error
      }
    })
}

const quoteManualPart = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_ADDING_NEW_PART,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/order/pending/${payload.orderId}/quote/${payload.partId}/${payload.type}`, {...payload.data})
    .then(response => {
      dispatch({
        type: ORDERS_SET_IS_ADDING_NEW_PART,
        payload: false
      })
      dispatch({
        type: ORDERS_SET_PENDING_ORDER_STAGE,
        payload: "edited"
      })
      dispatch({
        type: ORDERS_SET_PENDING_ORDER_PRICE,
        payload: response.data
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_ADDING_NEW_PART,
          payload: false
        })
        throw error
      }
    })
}

const editPendingOrder = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_EDITING_PENDING_ORDER,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/order/pending/${payload.orderId}/edit`, {...payload.data})
    .then(response => {
      dispatch({
        type: ORDERS_SET_IS_EDITING_PENDING_ORDER,
        payload: false
      })
      if(payload.data.deliveryDate) {
        dispatch({
          type: ORDERS_SET_PENDING_ORDER_DELIVERY_DATE,
          payload: payload.data.deliveryDate
        })
      }
      dispatch({
        type: ORDERS_SET_PENDING_ORDER_STAGE,
        payload: "edited"
      })
      dispatch({
        type: ORDERS_SET_PENDING_ORDER_PRICE,
        payload: response.data
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_EDITING_PENDING_ORDER,
          payload: false
        })
        throw error
      }
    })
}

const resolveOrder = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_RESOLVING_PENDING_ORDER,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/order/${payload.orderState}/${payload.orderId}/${payload.type}`, {orderNote: payload.orderNote})
    .then(() => {
      dispatch({
        type: ORDERS_SET_IS_RESOLVING_PENDING_ORDER,
        payload: false
      })
      message.show(message.type.success, "Successfully processed order")
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_RESOLVING_PENDING_ORDER,
          payload: false
        })
        throw error
      }
    })
}

const revertOrder = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_REVERTING_ORDER,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/order/pending/${payload.orderId}/revert-edits`)
    .then(() => {
      dispatch({
        type: ORDERS_SET_IS_REVERTING_ORDER,
        payload: false
      })
      message.show(message.type.success, "Successfully reverted order")
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_REVERTING_ORDER,
          payload: false
        })
        throw error
      }
    })
}

const producerFetchRecentAwaitingChanges = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_FETCHING_AWAITING_CHANGES,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/order/general/recent`)
    .then((response) => {
      dispatch({
        type: ORDERS_SET_IS_FETCHING_AWAITING_CHANGES,
        payload: false
      })

      dispatch({
        type: ORDERS_SET_AWAITING_CHANGES,
        payload: response.data.recent
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_AWAITING_CHANGES,
          payload: false
        })
        throw error
      }
    })
}

const customerFetchRecentChanges = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_FETCHING_RECENT_CHANGES,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/order/general/recent`)
    .then((response) => {
      dispatch({
        type: ORDERS_SET_IS_FETCHING_RECENT_CHANGES,
        payload: false
      })

      dispatch({
        type: ORDERS_SET_RECENT_CHANGES,
        payload: response.data.recent
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_RECENT_CHANGES,
          payload: false
        })
        throw error
      }
    })
}

const downloadOrderZip = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_GENERATING_ZIP,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/order-parts/filesZipDownloadOnOrder/${payload.orderId}`)
    .then((response) => {
      return response.data.zipUrl
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error
      }
    })
}


const setGeneratingZip = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_GENERATING_ZIP,
    payload: payload
  })
}


const fetchInternalArchivedOrders = payload => dispatch => {
  return http
    .get(`${API_BASE_URL}/internal-order/archived?filter=${payload.filter}`)
    .then((response) => {
      dispatch({
        type: ORDERS_SET_INTERNAL_ARCHIVED_ORDERS,
        payload: response.data.orders
      })
      return response.data.orders
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const archiveCurrentCalculation = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_ARCHIVING_CALCULATION,
    payload: true
  })
  return http
    .post(`${API_BASE_URL}/internal-order/archive-draft`, payload)
    .then(() => {
      dispatch({
        type: ORDERS_SET_IS_ARCHIVING_CALCULATION,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_ARCHIVING_CALCULATION,
          payload: false
        })
      }
    })
}


const fetchArchivedCalculation = payload => dispatch => {

  dispatch({
    type: ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION,
    payload: true
  })
  return http
    .get(`${API_BASE_URL}/internal-order/archived/${payload.orderId}`)
    .then((response) => {
      dispatch({
        type: ORDERS_SET_ARCHIVED_CALCULATION,
        payload: response.data.order
      })
      dispatch({
        type: ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION,
          payload: false
        })
      }
    })

}

const fetchArchivedCalculationCustomers = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION_CUSTOMERS,
    payload: true
  })
  return http
    .get(`${API_BASE_URL}/customer-relations/for-internal-order`)
    .then((response) => {
      dispatch({
        type: ORDERS_SET_ARCHIVED_CALCULATION_CUSTOMERS,
        payload: response.data.customers
      })
      dispatch({
        type: ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION_CUSTOMERS,
        payload: false
      })
      return response.data.customers
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION_CUSTOMERS,
          payload: false
        })
      }
    })

}

const fetchArchivedCalculationCustomerById = payload => dispatch => {

  return http
    .get(`${API_BASE_URL}/customer-relations/for-internal-order/${payload.customerId}`, {customerType: payload.type})
    .then((response) => {
      return response.data.customer
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error
      }
    })

}

const calculationToOrder = payload => dispatch => {
  return http
    .post(`${API_BASE_URL}/internal-order/archived/to-accepted/${payload.orderId}`, {
      deliveryDate: payload.deliveryDate,
      customerId: payload.customerId,
      customerType: payload.customerType,
      shippingData: payload.shippingData
    })
    .then(() => {
      message.show(message.type.success, "Successfully created order!")
      history.push("/calculation")
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error
      }
    })
}

const deleteCalculation  = payload => dispatch => {
  return http
    .delete(`${API_BASE_URL}/internal-order/archived/${payload.calculationId}`)
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error
      }
    })
}

const changePartProducedQuantity = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_PENDING_ORDER_PART_PRODUCED_QUANTITY,
    payload: payload
  })
  return http
    .put(`${API_BASE_URL}/order/accepted/${payload.orderId}/produced-quantity/${payload.partId}`, {quantity: payload.newQuantity})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: ORDERS_SET_PENDING_ORDER_PART_PRODUCED_QUANTITY,
          payload: {...payload, newQuantity: payload.oldQuantity}
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

/*
{
  "deliveryDate": 1652141215151,
  "customerId": "ckz887qc90000bstuezp5ahx7",
  "customerType": "dummy",
  "shippingData": {
    "country": "Serbia",
    "city": "Belgrade",
    "postalCode": "11000",
    "address": "Neka adresa 12",
    "phone": "+38164123456"
  }
}
 */

export {
  fetchProducerPendingOrders, fetchProducerAcceptedOrders,
  fetchProducerArchivedOrders, fetchCustomerPendingOrders,
  fetchCustomerAcceptedOrders, fetchCustomerArchivedOrders,
  fetchDraftOrders, removeDraftOrder, createNewDraftOrder,
  fetchDraftOrder, fetchProcessingParts, resetToAnalyze,
  fetchPendingOrder, addNewPart, fetchPendingOrderParts,
  quoteManualPart, editPendingOrder, resolveOrder,
  fetchCustomerAwaitingConfirmationOrders,
  fetchProducerAwaitingConfirmationOrders,
  revertOrder, producerFetchRecentAwaitingChanges,
  customerFetchRecentChanges, downloadOrderZip,
  fetchInternalDraftOrder, setGeneratingZip,
  fetchInternalArchivedOrders, archiveCurrentCalculation,
  fetchArchivedCalculation, fetchArchivedCalculationCustomers,
  fetchArchivedCalculationCustomerById, calculationToOrder,
  deleteCalculation, changePartProducedQuantity,
  createNewDraftOrderWithFiles, resetFilesToUpload
}