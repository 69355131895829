import * as React from 'react';
import {AutoSizer, MultiGrid} from 'react-virtualized';
import "./style.css";
import "../tableStyle.css";
import {Button, Spin} from "antd";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {EmptyTable} from "../EmptyTable";
import {Trans} from 'react-i18next';

const STYLE = {
  borderRadius: '0 0 5px 0'
};

export default class ModalTable extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.state = {
      fixedColumnCount: 1,
      fixedRowCount: 1,
      scrollToColumn: 0,
      scrollToRow: 0
    };

    this._cellRenderer = this._cellRenderer.bind(this);
    this._onScrollToRowChange = this._createEventHandler('scrollToRow');
    // this.changeValue = debounce(this.changeValue, 500);
  }


  refreshGrid = () => {
    if(this.multiGridRef) {
      this.multiGridRef.forceUpdateGrids()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevProps.updateTable && this.props.updateTable) {
      this.props.setUpdateTable(false)
      this.refreshGrid()
    }
    if(prevProps.activeLoadingTabs[this.props.tabIndex] && !this.props.activeLoadingTabs[this.props.tabIndex]) {
      this.refreshGrid()
    }
  }


  getClassName = (rowIndex, columnIndex) => {
    const {columns, rows, allAvailable} = this.props
    if (rowIndex > rows.length) {
      return "laserSpeedDisabledRow"
    }
    if (rowIndex === 0) {
      return "laserSpeedZeroRow"
    } else {
      if (columnIndex === 0) {
        return "laserSpeedLeftColumn"
      } else {
        let row = rows[rowIndex - 1]
        let name = row[0]
        let value = row[columnIndex]
        if (!value) {
          if (allAvailable[name][columns[columnIndex]]) {
            return "laserSpeedOddRow"
          } else {
            return "laserSpeedDisabledRow"
          }
        } else {
          return "laserSpeedOddRow"
        }
      }
    }
  }

  render() {
    const {columns, rows, tabIndex, activeLoadingTabs} = this.props;
    if (rows.length === 0) {
      return (
        <React.Fragment>
          <Spin spinning={Boolean(activeLoadingTabs && activeLoadingTabs[tabIndex])}
                indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
          >
            <EmptyTable/>
          </Spin>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <Spin spinning={Boolean(activeLoadingTabs && activeLoadingTabs[tabIndex])}
                indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
          >
            <AutoSizer disableHeight>
              {({width}) => (
                <MultiGrid
                  ref={(grid) => {
                    this.multiGridRef = grid;
                  }}
                  {...this.state}
                  cellRenderer={this._cellRenderer}
                  columnWidth={this._getColumnWidth}
                  columnCount={this._getColumnCount(columns, width)}
                  enableFixedColumnScroll
                  enableFixedRowScroll
                  height={260}
                  rowHeight={35}
                  rowCount={rows.length < 6 ? 7 : rows.length+1}
                  style={STYLE}
                  classNameTopLeftGrid={"topLeftGrid"}
                  classNameTopRightGrid={"topRightGrid"}
                  classNameBottomLeftGrid={"bottomLeftGrid"}
                  classNameBottomRightGrid={"bottomRightGrid"}
                  width={width}
                  hideTopRightGridScrollbar
                  hideBottomLeftGridScrollbar
                />
              )}
          </AutoSizer>
          </Spin>
        </React.Fragment>
      );
    }
  }

  _cellRenderer({columnIndex, key, rowIndex, style}) {
    const {columns, rows, allAvailable, groupName, tabIndex, onClick} = this.props
    return (
      <div className={this.getClassName(rowIndex, columnIndex)}
           key={key} style={{
        ...style,
        padding: 5,
        borderTop: rowIndex === 0 ? "1px solid #888888" : "none",
        borderBottom: '1px solid #888888',
        borderRight: '2px solid #001529',
        display: 'inline-block',
        alignItems: 'center',
        right: -2
      }}>
        <div style={{fontSize: 13}} className={"cellText"}>
          {rowIndex === 0 ?
            <div className={"speedTableCellText"}>{columns[columnIndex]}</div>
            :
            (rows.length < rowIndex ?
              null
              :
              (columnIndex === 0 ?
                <div className={"speedTableCellLeft yesSelect"}><Trans>{rows[rowIndex - 1][columnIndex]}</Trans></div>
                :
                <div>
                  {allAvailable[rows[rowIndex - 1][0]][columns[columnIndex]] ?
                    <Button
                      type={"primary"}
                      style={{marginRight: 1, marginTop: -2, width: 44, paddingTop: 1, paddingBottom: 1, height: 28}}
                      onClick={() => onClick(
                          {
                            materialGrade: rows[rowIndex - 1][0],
                            materialThickness: columns[columnIndex],
                            materialId: allAvailable[rows[rowIndex - 1][0]][columns[columnIndex]],
                            groupName: groupName,
                            tab: tabIndex
                          }
                        )
                      }
                    >
                      {!rows[rowIndex - 1][columnIndex] ?
                        <PlusOutlined style={{fontSize: 12}}/>
                        :
                        <span style={{fontSize: 12, fontWeight: 600, height: 12}}>
                          {rows[rowIndex - 1][columnIndex]}
                        </span>
                      }
                    </Button>
                    :
                    null}
                </div>
              ))
          }
        </div>
      </div>
    );
  }

  _getColumnWidth = ({index}) => {
    return index === 0 ? 140 : 55
  };

  _createEventHandler(property) {
    return event => {
      const value = parseInt(event.target.value, 10) || 0;

      this.setState({
        [property]: value,
      });
    };
  }

  _getColumnCount = (columns, width) =>  {
    const count = Object.keys(columns).length
    if(140 + (count-1) * 55 < width) {
      return Math.floor((width-140)/55) + 1
    }
    else {
      return count
    }
  }

}