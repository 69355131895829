import {
  COMPANY_SETTINGS_SET_FETCHING_COMPANY_DETAILS,
  COMPANY_SETTINGS_SET_COMPANY_DETAILS,
  COMPANY_SETTINGS_SET_EDITING_COMPANY_SETTINGS,
  COMPANY_SETTINGS_SET_RESETTING_PASSWORD,
} from '../actions/actionTypes'
import initialState from './initialState'

const companySettingsReducer = (
  state = initialState.companySettings,
  action
) => {
  switch (action.type) {
    case COMPANY_SETTINGS_SET_COMPANY_DETAILS:
      return {
        ...state,
        companyDetails: action.payload,
      }
    case COMPANY_SETTINGS_SET_EDITING_COMPANY_SETTINGS:
      return { ...state, editingCompanySettings: action.payload }

    case COMPANY_SETTINGS_SET_FETCHING_COMPANY_DETAILS:
      return { ...state, fetchingCompanyDetails: action.payload }
    case COMPANY_SETTINGS_SET_RESETTING_PASSWORD:
      return { ...state, resettingPassword: action.payload}
    default:
      return state
  }
}

export default companySettingsReducer
