import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationISO from './translations/locales/iso/translation.json';
import translationDIN from './translations/locales/din/translation.json';

const resources = {
  iso: {
    translation: translationISO
  },
  din: {
    translation: translationDIN
  }
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "iso",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
        escapeValue: false // react already safes from xss
      }
  });

export default i18n;