import {Card} from "antd";
import {S3_BUCKET_URL} from "../../../../constants";
import React from "react";
import {formatDate} from "../../../../utils/utility";
import "./style.css";
import {errorTypes} from "../../../../utils/errorTypes";


export const LeftoverPartList = (props) => {
  const { parts, showDetailsItem, handleShowDownloadFiles } = props;

  return (
    <div
      className={"quotedItemsList"}
      style={{
        minHeight: 250,
        height: "calc(100vh - 200px)",
        padding: "0 6px 0 6px",
      }}
    >
      {parts.map((part, index) => (
        <Card
          className={"partCard"}
          style={{height: 80, marginTop: 10, marginBottom: 10}}
          bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
          onMouseDown={(e) => {
            if (e.button === 1 || e.button === 0) {
              const win = window.open(`/accepted-order?order=${part.orderId}`, "_blank");
              win.focus();
            }
          }}
          key={index}
        >
          <div style={{display: "flex", width: "100%"}}>
            <div
              style={{
                border: "1px solid rgb(205, 205, 205)",
                borderRadius: 3,
                marginRight: 8,
                background: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: 55,
                  height: 55,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      maxWidth: 62,
                      maxHeight: 55,
                      padding: 3,
                    }}
                    src={S3_BUCKET_URL + part.thumbnailUrl}
                    alt={"part"}
                  />
                </div>
              </div>
            </div>
            <div style={{width: "100%"}}>
              <div style={{width: "100%"}}>
                <table style={{width: "100%"}}>
                  <tbody>
                  <tr>
                    <td style={{width: "calc(100% - 100px)", maxWidth: 150}}>
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          paddingRight: 10,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                          whiteSpace: "nowrap",
                          marginTop: -5
                        }}
                      >
                        {part.partName}
                      </div>
                    </td>
                    <td style={{width: 200}}>
                      <div style={{display: "flex", height: 24, justifyContent: "flex-end"}}>
                        <div
                          style={{
                            fontSize: 13,
                            fontWeight: 600
                          }}
                        >
                          {errorTypes[part.errorType]}
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div style={{marginTop: -5}}>
                <div
                  style={{fontSize: 12, fontWeight: 600}}
                >
                  Order: <span style={{ color:  "rgba(0, 0, 0, 0.85)"}}>{part.orderName}</span>
                </div>
              </div>
              <div>
                <div style={{display:"flex", justifyContent: "space-between"}}>
                  <div
                    style={{fontSize: 12, fontWeight: 500, marginRight: 8}}
                  >
                    Quantity: <span style={{fontWeight: 600}}>{part.quantity}</span>
                  </div>
                  <div
                    style={{fontSize: 12, fontWeight: 500}}
                  >
                    Delivery Date: <span style={{fontWeight: 600, color:  "rgba(0, 0, 0, 0.85)"}}>{formatDate(part.deliveryDate)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  )
}