import * as React from "react";
import { AutoSizer, List } from "react-virtualized"
import {Button, Card, Select, Spin} from "antd";
import "./style.css"
import {CaretDownOutlined, DeleteOutlined, DownloadOutlined, LoadingOutlined, SearchOutlined} from "@ant-design/icons";
import nest from "../../../../assets/images/nest2.png"
import {S3_BUCKET_URL} from "../../../../constants";
import {formatTableDate, formatTime} from "../../../../utils/utility";
import {useState} from "react";
import {DeletionModal} from "../../../DeletionModal";
import {useDispatch} from "react-redux";
import {fetchAcceptedNestingJobs, scrapPlate} from "../../../../actions/nestingActions";

const ITEM_SIZE = 550

const { Option } = Select

const CuttingMachineData = (props) => {
  const { plateState, item } = props;
  const [cuttingTime, setCuttingTime] = useState(item?.cuttingMachines?.length > 0 ? item.cuttingMachines[0].cuttingTime : 0)

  if (plateState === "current") {
    return (
      item?.cuttingMachines?.length > 0 ? (
        <div>
          <div className={"nestingText"} style={{marginBottom: 1}}>Laser:
            <span className={"nestingText500"} style={{marginLeft: 5}}>
              <Select
                size={"small"}
                style={{width: 150}}
                defaultValue={0}
                suffixIcon={<CaretDownOutlined/>}
                onChange={(value) => {setCuttingTime(item.cuttingMachines[value].cuttingTime)}}
              >
                {item.cuttingMachines.map((laser, index) =>
                  <Option
                    key={index}
                    value={index}
                  >
                    {laser.name}
                  </Option>
                )}
              </Select>
            </span>
          </div>
          <div className={"nestingText"}>Cutting Time: <span className={"nestingText500"}>{formatTime(cuttingTime)}</span></div>
        </div>
      ) : (
        <div />
      )
    )}
  else {
    return (
      item?.selectedCuttingMachine && item?.selectedCuttingMachine !== {} ? (
        <div>
          <div className={"nestingText"} style={{marginBottom: 1}}>
            Laser: <span className={"nestingText500"} style={{marginLeft: 5}}>{item.selectedCuttingMachine.name}</span>
          </div>
          <div className={"nestingText"}>
            Cutting Time: <span className={"nestingText500"}>{formatTime(item.selectedCuttingMachine.cuttingTime)}</span>
          </div>
        </div>
      ) : (
        <div />
      )
    )
  }
}

const NestingCard = (props) => {
  const { item, cardWidth, collapse, buttonText, onClick, viewPlate, plateState } = props;

  const dispatch = useDispatch();

  return (
    <div
      className={'nestingItem'}
      style={{ width: cardWidth - 6}}
    >
      <Card
        className={"nestedPlateCard fadeInCard"}
        bodyStyle={{height: "100%", width: "100%"}}
      >
        <div
          style={{display: "flex", justifyContent: "space-between", height: "100%", width: "100%"}}
        >
          <div style={{display: "flex"}}>
            <div>
              <div
                className={"nestingPicture"}
                style={{height: "calc(100% - 6px)"}}
                onClick={()=>{viewPlate(plateState, item.id)}}
              >
                <img alt={item.name} style={{objectFit: "contain", maxHeight: 145}} src={item.thumbnailPath ? `${S3_BUCKET_URL}${item.thumbnailPath}` : nest} width={185}/>
              </div>
              {plateState === "completed" ?
                <div align={"center"} style={{fontSize: 11, fontWeight: 500, marginRight: 10}}>Completed: {formatTableDate(item.completionDate)}</div>
                :
                <div align={"center"} style={{fontSize: 11, fontWeight: 500, marginRight: 10}}>Delivery: {formatTableDate(item.deliveryDate)}</div>
              }

            </div>

            {cardWidth > 460 &&
            <div style={cardWidth > 730 ? {display: "flex", marginTop: -2} : {display: "block", marginTop: -2}}>
              <div style={{marginRight: 10}}>
                <div className={"nestingText"}>Plate ID: <span className={"nestingText500"} style={{display: collapse ? "inline-block" : "block"}}>{item.name}</span></div>
                <div className={"nestingText"}>Material: <span className={"nestingText500"} style={{display: collapse ? "inline-block" : "block"}}>{item.grade}</span></div>
                <div className={"nestingText"}>Thickness: <span className={"nestingText500"}>{item.thickness}mm</span></div>
                <div className={"nestingText"}>Dimensions: <span className={"nestingText500"} style={{display: collapse ? "inline-block" : "block"}}>{item.width}x{item.height}mm</span></div>
              </div>
              {collapse &&
              <div>
                <div className={"nestingText"}>Efficiency: <span className={"nestingText500"}>{item.efficiency}%</span></div>
                <CuttingMachineData item={item}
                                    plateState={plateState}
                />
              </div>
              }
            </div>
            }
          </div>
          <div
            align={"right"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <div style={{display: "flex", justifyContent: "right"}}>
              {plateState === "accepted" &&
                <Button
                  size={"small"}
                  // type={"danger"}
                  style={{marginLeft: 10}}
                  onClick={(e)=>{
                    e.stopPropagation();
                    DeletionModal(
                      true,
                      "Remove Plate",
                      "Are you sure you want to remove this plate",
                      () => {},
                      () => {
                        dispatch(scrapPlate({plateId: item.id}))
                          .then(() => {
                            dispatch(fetchAcceptedNestingJobs())
                          })
                      })
                  }}
                >
                  <DeleteOutlined style={{fontSize: 13, color: "#ff4d4f"}}/>
                </Button>
              }
              <Button
                size={"small"}
                // type={"primary"}
                style={{marginLeft: 5}}
                href={`${S3_BUCKET_URL}${item.nestedDxfPath}`}
              >
                <DownloadOutlined style={{fontSize: 13, color: "#1890ff"}}/>
              </Button>
              <Button
                size={"small"}
                // type={"primary"}
                style={{marginLeft: 5}}
                onClick={()=>{viewPlate(plateState, item.id)}}
              >
                <SearchOutlined style={{fontSize: 13, color: "#1890ff"}}/>
              </Button>
            </div>
            {buttonText &&
              <div>
                <Button
                  type={"primary"}
                  onClick={() => {
                    onClick(item)
                  }}
                >
                  <span style={{fontWeight: 600, fontSize: 13}}>
                    {buttonText}
                  </span>
                </Button>
              </div>
            }
          </div>
        </div>
      </Card>
    </div>
  )
}

export const NestingGrid = (props) => {
  const { gridItems, isFetchingItems, isSearching, buttonText, onClick, viewPlate, plateState } = props;

  if (gridItems) {
    const item_count = gridItems.length;

    if (item_count === 0) {
      return (
        <React.Fragment>
          <Spin
            spinning={isSearching || isFetchingItems}
            indicator={
              <div align={"center"}>
                <LoadingOutlined style={{ fontSize: 50, marginLeft: -20 }} spin />
              </div>
            }
          >
            <div className={"emptyAwaiting"}>
              {
                !isFetchingItems &&
                <div className={"emptyDraftsText"} style={{ position: "relative", bottom: "30%" }}>No Data</div>
              }
            </div>
          </Spin>
        </React.Fragment>
      )
    } else {
      return (
        <AutoSizer>
          {({ height, width }) => {
            const itemsPerRow = Math.floor(width / ITEM_SIZE) || 1;
            const rowCount = Math.ceil(item_count / itemsPerRow);
            const cardWidth = width / itemsPerRow
            const collapse = cardWidth > 550

            return (
              <Spin
                spinning={isSearching || isFetchingItems}
                style={{width}}
                indicator={
                  <div align={"center"}>
                    <LoadingOutlined style={{fontSize: 50, marginLeft: -20}} spin/>
                  </div>
                }
              >
                <List
                  className={'nestingList'}
                  width={width}
                  height={height}
                  rowCount={rowCount}
                  rowHeight={189}
                  overscanRowCount={2}
                  rowRenderer={
                    ({ index, key, style }) => {
                      const items = [];
                      const fromIndex = index * itemsPerRow;
                      const toIndex = Math.min(fromIndex + itemsPerRow, item_count);
                      const count = toIndex - fromIndex
                      const cardWidth = width / itemsPerRow

                      for (let i = fromIndex; i < toIndex; i++) {
                        items.push(
                          <NestingCard item={gridItems[i]}
                                       cardWidth={cardWidth}
                                       collapse={collapse}
                                       buttonText={buttonText || null}
                                       onClick={onClick}
                                       key={i}
                                       plateState={plateState}
                                       viewPlate={viewPlate}
                          />
                        )
                      }

                      return (
                        <div
                          className={'nestingRow'}
                          key={key}
                          style={style}
                        >
                          {items}
                        </div>
                      )
                    }
                  }
                />
              </Spin>
            )
          }}
        </AutoSizer>
      )
    }
  }

  return (
    <React.Fragment>
      <div className={"centeredCol"}>
        <Spin
          spinning={true}
          indicator={
            <div align={"center"}>
              <LoadingOutlined style={{ fontSize: 50, marginLeft: -20 }} spin />
            </div>
          }
        >
        </Spin>
      </div>
    </React.Fragment>
  )
}