import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import CubeView from './cubeview';
import "react-datepicker/dist/react-datepicker.css";
import './style.css';
import * as ThreeDxf from './three-dxf'
import { NodeMaterialLoader } from 'three/examples/jsm/loaders/NodeMaterialLoader'
import Stats from 'three/examples/jsm/libs/stats.module'
import DxfParser from 'dxf-parser';

import {Helper} from 'dxf'

import fs from 'fs'

import drawing from "../../../assets/testDrawing.dxf"



//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import {
    Button,
    Layout,
    Modal,
    Col,
    Row,
    // Tooltip,
    // Anchor,
    // Upload,
    // Select,
    // Table,
    // Card,
    // InputNumber,
    // List,
    // Spin,
    Slider,
    Radio
} from 'antd';
import Icon, {CloseOutlined} from '@ant-design/icons'
// import DatePicker from "react-datepicker";
import part from  '../../../assets/Step 1_fold.stl'
import part1 from  '../../../assets/Step 1_unfold.stl'

import {logoutUser} from '../../../actions/authActions';
import { AdminSidebar} from '../../../components/admin';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
// import {message} from "../../../utils";
import "antd/dist/antd.css";


var STLLoader = require('three-stl-loader')(THREE);
var OrbitControls = require("react-cubeview/lib/OrbitControls")(THREE);
var scene;
var camera;
var renderer;
var cube;
var cloud;
var container;
let controls;
var currphi;
var currtheta;
var currphi2;
var currtheta2;
var id;
var canvas;
var mesh1_solid;
var mesh1_clipLine;
var mesh1_line;
var mesh2_solid;
var mesh2_clipLine;
var mesh2_line;

var materialSolidSolid;
var materialSolidDark;
var materialSolidXray;
var materialLineSolid;
var materialLineXray;
// var materialCameraPosition;

var clippingPlaneLen = 0;
var localPlane;
var localPlaneUpper;
var localPlaneLower;

var gridHelper;
var gridHelper2;

// const { confirm } = Modal;

const LeftMouseClick = () => (
    <svg width="0.7em" height="1.4em" viewBox="0 0 1024 1024">
        <g transform="translate(0.000000,1318.000000) scale(0.100000,-0.100000)"
           fill="currentColor" stroke="none">
            <path d="M3010 13169 c-476 -21 -867 -125 -1300 -345 -313 -158 -564 -346
            -831 -622 -434 -448 -700 -960 -830 -1599 -28 -137 -31 -172 -40 -473 -5 -190
            -8 -1781 -6 -3825 3 -3860 -3 -3505 62 -3812 111 -525 345 -994 704 -1413 530
            -619 1358 -1034 2135 -1070 267 -13 2846 -12 3021 0 273 20 590 97 905 220
            102 40 352 171 473 247 231 147 389 276 593 484 390 398 669 903 799 1443 63
            258 67 297 75 696 11 588 4 7187 -9 7301 -22 203 -97 505 -178 722 -278 742
            -830 1359 -1532 1712 -300 152 -570 238 -933 301 -129 22 -201 27 -463 34
            -373 11 -2412 10 -2645 -1z m838 -737 c13 -4 24 -11 25 -15 1 -5 -40 -24 -92
            -44 l-93 -35 -37 34 c-20 18 -40 37 -46 41 -21 17 24 27 120 26 55 -1 110 -4
            123 -7z m1699 -12 c663 -89 1228 -361 1668 -804 439 -443 707 -979 800 -1601
            19 -130 24 -399 30 -1882 l5 -1203 -1645 0 -1645 0 0 478 0 477 109 26 c199
            49 386 156 527 301 99 102 136 154 199 282 71 146 93 230 105 401 5 78 10 682
            10 1370 0 1204 0 1232 -20 1305 -115 423 -371 702 -755 823 -44 14 -89 28
            -100 30 -43 10 638 6 712 -3z m-2325 -212 c48 -43 97 -88 110 -98 17 -14 20
            -22 13 -33 -23 -32 -98 -122 -104 -125 -6 -2 -204 172 -381 335 l-54 50 104
            25 105 25 60 -49 c33 -28 99 -86 147 -130z m-517 -334 c182 -162 340 -305 352
            -317 22 -22 23 -26 15 -139 l-7 -117 -100 91 c-55 50 -179 161 -275 247 -275
            245 -490 442 -490 450 0 9 141 80 160 80 8 1 164 -132 345 -295z m1833 -10
            c207 -43 404 -217 501 -444 54 -126 62 -174 72 -415 11 -275 2 -1788 -11
            -1895 -22 -184 -134 -387 -279 -507 -140 -115 -298 -170 -470 -160 -164 8
            -295 66 -423 186 -114 106 -187 234 -237 411 l-26 95 0 1020 c0 961 1 1025 19
            1100 24 105 48 163 104 259 163 280 447 412 750 350z m-2040 -604 l572 -514 0
            -118 c0 -65 -3 -118 -6 -118 -5 0 -315 276 -584 520 -116 105 -159 143 -530
            476 -140 125 -255 230 -255 232 0 2 31 30 69 62 l70 58 46 -41 c25 -23 304
            -274 618 -557z m-708 -165 c195 -175 378 -339 406 -364 28 -25 236 -212 462
            -415 l412 -370 0 -119 0 -118 -27 20 c-27 19 -330 290 -438 390 -45 41 -480
            431 -653 585 -107 94 -426 382 -549 495 -98 89 -112 105 -101 120 7 9 32 40
            56 69 39 47 46 51 61 39 9 -7 176 -157 371 -332z m-538 -316 c232 -209 675
            -607 1301 -1168 l517 -465 0 -124 0 -124 -142 128 c-79 71 -192 173 -253 228
            -173 156 -177 160 -425 381 -220 197 -698 626 -1085 975 -93 84 -171 161 -173
            171 -4 21 66 162 78 157 5 -2 87 -73 182 -159z m-218 -603 c98 -89 204 -183
            235 -211 216 -193 556 -498 606 -544 33 -31 98 -90 145 -132 47 -41 220 -197
            385 -345 727 -653 915 -822 995 -893 46 -42 107 -97 135 -122 27 -25 60 -54
            72 -65 12 -10 109 -98 217 -194 l195 -175 1 -123 0 -124 -52 48 c-29 26 -111
            99 -183 163 -71 63 -175 156 -230 206 -56 49 -141 126 -190 170 -119 106 -289
            259 -345 311 -25 23 -137 124 -250 225 -113 101 -268 240 -345 309 -77 69
            -207 186 -290 260 -82 73 -213 191 -290 260 -129 117 -1045 939 -1054 947 -5
            4 14 111 29 166 8 25 15 35 23 30 7 -4 93 -79 191 -167z m-32 -773 c123 -110
            288 -258 367 -329 126 -112 369 -331 671 -604 47 -42 105 -94 130 -116 25 -21
            90 -79 145 -129 55 -49 159 -142 230 -205 71 -63 228 -205 350 -315 673 -607
            800 -722 829 -747 l31 -27 -137 -1 -137 0 -314 283 c-697 628 -1153 1038
            -1253 1126 -27 24 -87 78 -134 121 -47 42 -191 171 -320 286 -573 509 -718
            644 -724 676 -8 39 -8 156 0 185 8 30 -2 37 266 -204z m123 -908 c164 -147
            622 -558 725 -650 19 -18 87 -79 150 -136 63 -58 241 -217 395 -354 287 -256
            333 -297 420 -378 l50 -47 -137 0 -137 0 -214 193 c-117 105 -331 298 -476
            427 -146 129 -341 304 -435 389 -94 85 -211 191 -261 235 -49 45 -117 106
            -150 137 -33 30 -120 108 -192 173 l-133 118 0 125 0 124 103 -93 c56 -51 188
            -169 292 -263z m-251 -572 c72 -64 158 -142 191 -173 33 -30 98 -89 145 -131
            250 -224 500 -448 619 -554 l134 -120 -59 -6 c-33 -4 -91 -7 -130 -8 l-71 -1
            -219 196 c-747 669 -741 664 -748 703 -8 47 -8 211 0 211 3 0 65 -53 138 -117z
            m190 -984 c-3 -5 -61 -9 -129 -9 l-123 0 -41 37 -41 36 0 124 0 124 170 -152
            c93 -83 167 -156 164 -160z m6981 -1921 c-7 -1542 -10 -1709 -35 -1875 -49
            -330 -125 -565 -281 -873 -391 -776 -1132 -1325 -1999 -1484 -238 -43 -441
            -49 -1499 -43 -1043 6 -1015 5 -1286 63 -722 157 -1335 581 -1752 1211 -205
            310 -345 675 -418 1083 -24 131 -27 183 -35 530 -5 212 -9 874 -9 1473 l-1
            1087 3660 0 3660 0 -5 -1172z"
            />
        </g>
    </svg>
);

const RightMouseClick = () => (
    <svg width="0.7em" height="1.4em" viewBox="0 0 1024 1024">
        <g transform="translate(0.000000,1318.000000) scale(0.100000,-0.100000)"
           fill="currentColor" stroke="none">
            <path d="M3010 13169 c-476 -21 -867 -125 -1300 -345 -313 -158 -564 -346
            -831 -622 -466 -481 -747 -1053 -856 -1742 -17 -110 -18 -288 -18 -3880 0
            -4159 -6 -3778 60 -4087 111 -525 345 -994 704 -1413 530 -619 1358 -1034
            2135 -1070 267 -13 2846 -12 3021 0 273 20 590 97 905 220 102 40 352 171 473
            247 231 147 389 276 593 484 390 398 669 903 799 1443 63 258 67 297 75 696
            11 590 4 7187 -9 7301 -22 203 -97 505 -178 722 -278 742 -830 1359 -1532
            1712 -300 152 -570 238 -933 301 -129 22 -201 27 -463 34 -373 11 -2412 10
            -2645 -1z m2466 -821 c205 -191 661 -615 729 -678 75 -71 182 -171 280 -261
            120 -110 242 -224 320 -299 44 -42 143 -134 220 -205 77 -71 201 -186 275
            -255 383 -358 474 -443 574 -535 139 -129 152 -151 161 -282 4 -57 3 -103 -2
            -103 -4 0 -33 23 -63 51 -30 28 -78 72 -105 98 -58 53 -301 280 -420 391 -44
            41 -105 98 -135 125 -30 28 -100 93 -155 145 -55 52 -125 118 -155 145 -30 28
            -183 170 -340 316 -157 146 -357 332 -445 414 -88 82 -223 208 -300 280 -164
            153 -434 405 -520 484 -33 31 -109 102 -169 158 l-108 103 129 0 129 0 100
            -92z m-1581 62 c-307 -81 -568 -290 -710 -570 -15 -30 -43 -100 -62 -155 -30
            -88 -35 -118 -43 -255 -5 -85 -9 -704 -10 -1375 l0 -1220 25 -96 c62 -243 146
            -391 318 -556 139 -134 307 -225 498 -272 l109 -26 0 -477 0 -478 -1645 0
            -1645 0 0 1188 c1 1265 9 1729 35 1907 52 364 182 731 370 1047 192 321 485
            636 795 854 357 250 768 413 1210 479 138 21 192 23 490 24 l335 0 -70 -19z
            m2336 -156 c115 -44 350 -156 384 -182 14 -11 87 -78 163 -149 75 -72 288
            -270 472 -442 335 -311 335 -312 398 -419 68 -117 187 -351 181 -357 -3 -2
            -65 53 -139 122 -74 69 -189 176 -255 237 -65 61 -153 143 -195 182 -41 38
            -111 104 -155 145 -44 41 -138 128 -208 193 -71 66 -137 127 -147 136 -108 94
            -608 570 -599 570 4 0 49 -16 100 -36z m-1693 -390 c204 -42 407 -218 497
            -434 58 -138 67 -183 75 -395 9 -219 4 -1790 -6 -1895 -13 -145 -88 -320 -189
            -442 -220 -265 -580 -334 -863 -166 -67 40 -169 133 -217 198 -55 75 -114 201
            -143 307 l-27 98 0 1025 c1 1020 1 1025 23 1112 76 306 294 533 577 601 46 11
            201 6 273 -9z m1397 -729 c88 -82 189 -176 225 -210 36 -33 159 -148 275 -255
            115 -107 250 -233 300 -280 50 -47 122 -114 160 -149 39 -35 99 -91 135 -125
            36 -34 97 -90 135 -125 39 -36 104 -97 145 -136 41 -39 199 -186 350 -326 151
            -141 301 -280 333 -310 l58 -54 -3 -118 -3 -117 -30 27 c-37 34 -318 295 -325
            303 -3 3 -66 61 -140 130 -229 212 -441 410 -525 490 -44 41 -143 134 -220
            205 -77 71 -167 154 -200 185 -33 31 -96 90 -140 131 -44 41 -114 106 -155
            145 -61 57 -311 290 -557 518 l-43 39 0 121 0 120 33 -30 c17 -17 104 -97 192
            -179z m355 -1115 c168 -156 330 -307 360 -335 147 -138 220 -206 275 -256 33
            -30 127 -118 210 -194 82 -77 177 -165 210 -196 33 -30 132 -123 220 -205 366
            -341 431 -402 458 -424 26 -23 27 -27 26 -119 -1 -145 -2 -146 -85 -70 -140
            130 -361 335 -429 400 -38 36 -171 160 -295 274 -124 115 -252 234 -285 265
            -33 31 -163 153 -290 271 -126 117 -235 218 -241 224 -6 5 -67 62 -135 125
            -68 63 -158 147 -199 185 -109 103 -194 181 -292 271 l-86 79 -1 124 -1 123
            138 -129 c75 -71 274 -257 442 -413z m-433 -385 c106 -100 283 -266 303 -285
            3 -3 30 -27 60 -54 30 -27 87 -79 125 -115 39 -36 133 -125 210 -196 77 -71
            167 -155 200 -186 33 -30 98 -91 145 -134 47 -44 110 -102 140 -131 30 -28
            122 -114 205 -190 82 -76 192 -180 245 -229 175 -166 502 -470 531 -494 28
            -23 29 -27 28 -110 -1 -47 -4 -99 -8 -116 l-6 -30 -80 75 c-44 41 -134 125
            -200 185 -66 61 -156 144 -200 186 -44 42 -141 132 -215 201 -120 110 -448
            416 -1125 1047 -296 276 -320 298 -416 387 l-87 79 -1 118 c-1 67 3 117 8 115
            5 -1 67 -57 138 -123z m-27 -761 c36 -34 96 -90 135 -125 69 -64 185 -171 300
            -279 33 -31 128 -119 210 -195 237 -219 572 -531 665 -619 47 -45 144 -135
            214 -201 71 -66 143 -133 161 -150 17 -16 113 -106 213 -199 100 -93 182 -171
            182 -173 0 -2 -57 -3 -127 -3 l-128 0 -75 73 c-82 79 -564 529 -779 727 -130
            119 -503 466 -661 614 -42 39 -158 147 -258 240 -203 189 -187 160 -175 314
            l6 83 26 -23 c14 -13 55 -50 91 -84z m-216 -581 c153 -144 214 -201 281 -263
            222 -204 606 -561 725 -674 95 -90 353 -332 415 -389 l40 -36 -130 -1 -130 0
            -55 53 c-31 28 -217 202 -415 386 -198 184 -396 368 -440 410 -44 42 -169 159
            -278 260 -117 110 -196 190 -193 198 10 25 88 123 99 123 6 0 42 -30 81 -67z
            m-341 -470 c95 -87 228 -210 297 -273 69 -64 139 -129 155 -144 17 -15 135
            -125 264 -244 l233 -217 -58 -6 c-33 -4 -88 -7 -123 -8 -73 -1 -39 -26 -306
            224 -77 72 -210 196 -295 275 -232 215 -256 238 -365 341 -55 52 -113 106
            -129 120 l-28 27 78 30 c44 17 85 31 92 31 8 1 91 -70 185 -156z m-235 -561
            c147 -137 284 -264 304 -281 21 -18 35 -37 32 -42 -3 -5 -57 -9 -121 -9 -134
            0 -99 -21 -322 189 -73 69 -142 132 -152 139 -17 12 -19 27 -19 133 0 65 2
            119 5 119 3 0 126 -112 273 -248z m3007 -2244 c-7 -1542 -10 -1709 -35 -1875
            -49 -330 -125 -565 -281 -873 -391 -776 -1132 -1325 -1999 -1484 -238 -43
            -441 -49 -1499 -43 -1043 6 -1015 5 -1286 63 -722 157 -1335 581 -1752 1211
            -205 310 -345 675 -418 1083 -24 131 -27 183 -35 530 -5 212 -9 874 -9 1473
            l-1 1087 3660 0 3660 0 -5 -1172z"
            />
        </g>
    </svg>
);

const MiddleMouseClick = () => (
    <svg width="0.75em" height="1.4em" viewBox="0 0 1024 1024">
        <g transform="translate(0.000000,1318.000000) scale(0.100000,-0.100000)"
           fill="currentColor" stroke="none">
            <path d="M2640 12266 c-171 -32 -279 -60 -450 -118 -306 -104 -664 -301 -906
            -499 -108 -88 -373 -356 -448 -454 -332 -430 -520 -873 -612 -1447 l-26 -163
            4 -3460 c4 -3224 6 -3466 22 -3550 68 -355 138 -590 247 -829 209 -462 544
            -866 969 -1172 372 -269 795 -439 1280 -516 132 -21 165 -21 1447 -25 831 -3
            1380 -1 1495 6 408 23 799 139 1193 353 503 275 908 682 1184 1193 166 307
            277 648 318 975 27 221 31 759 29 3970 l-3 3145 -27 140 c-72 375 -139 582
            -281 867 -191 382 -428 676 -790 978 -330 275 -735 469 -1225 587 l-155 37
            -1570 2 -1570 2 -125 -22z m1193 -666 c-162 -42 -337 -140 -455 -254 -132
            -128 -232 -301 -283 -488 l-26 -97 4 -1283 c3 -940 7 -1296 15 -1332 16 -65
            108 -256 163 -337 83 -124 226 -247 374 -322 49 -24 198 -76 283 -97 l42 -11
            0 -444 0 -445 -1531 0 -1531 0 5 1363 c3 949 8 1392 16 1462 34 282 116 566
            240 820 133 275 274 477 479 687 404 415 895 672 1467 767 162 27 167 27 510
            28 l300 1 -72 -18z m1687 -13 c161 -30 265 -56 380 -95 114 -39 293 -113 355
            -147 28 -15 79 -43 115 -63 80 -43 260 -165 355 -240 100 -80 280 -257 364
            -360 271 -331 432 -654 536 -1076 19 -77 40 -173 46 -215 11 -80 26 -1317 28
            -2338 l1 -563 -1530 0 -1530 0 0 445 0 445 28 6 c249 54 485 188 615 349 75
            92 158 248 199 372 l38 113 0 1254 c0 1080 -2 1264 -15 1326 -84 401 -345 681
            -740 796 l-80 24 350 -4 c324 -3 360 -5 485 -29z m-1195 -584 c66 -53 210
            -172 320 -264 110 -92 223 -187 252 -211 64 -53 75 -84 71 -202 l-3 -90 -245
            204 c-368 306 -674 566 -689 583 -10 13 -8 18 20 32 40 21 107 43 134 44 11 0
            73 -42 140 -96z m-272 -532 c177 -147 352 -293 388 -322 37 -30 174 -144 304
            -253 l238 -200 -7 -95 c-3 -53 -6 -102 -6 -109 0 -15 -16 -3 -325 253 -119 99
            -258 214 -309 255 -170 139 -581 488 -629 535 -41 40 -48 51 -42 74 9 36 52
            131 60 131 4 0 152 -121 328 -269z m-313 -504 c52 -44 128 -107 169 -141 40
            -33 108 -90 150 -126 43 -36 140 -117 217 -180 76 -63 264 -220 417 -348 l277
            -233 0 -115 0 -115 -76 63 c-41 35 -106 88 -143 118 -129 105 -815 677 -1053
            878 l-88 73 0 78 c0 96 8 143 24 134 6 -4 54 -43 106 -86z m10 -773 c279 -233
            1105 -920 1113 -925 12 -9 -14 -56 -60 -108 l-39 -44 -112 92 c-62 51 -215
            179 -341 284 -125 106 -334 281 -465 390 l-236 198 0 63 c0 35 3 84 6 109 8
            54 -8 61 134 -59z m29 -784 c57 -47 125 -103 151 -125 26 -22 137 -114 247
            -205 123 -101 196 -168 189 -172 -31 -21 -222 -1 -281 29 -39 20 -199 148
            -282 226 -46 44 -73 80 -98 131 -29 60 -75 201 -75 229 0 9 4 6 149 -113z
            m3917 -3906 c-3 -731 -11 -1362 -17 -1433 -18 -229 -83 -497 -175 -719 -200
            -484 -544 -905 -969 -1186 -163 -108 -250 -156 -395 -219 -197 -86 -345 -132
            -543 -171 -252 -49 -270 -50 -1317 -51 -894 0 -997 2 -1110 18 -403 57 -769
            193 -1092 406 -427 280 -772 688 -966 1141 -95 221 -147 398 -188 645 l-25
            150 -6 1363 -6 1362 3408 0 3408 0 -7 -1306z"
            />
        </g>
    </svg>
);

const LeftMouse = props => <Icon component={LeftMouseClick} {...props} />;

const RightMouse = props => <Icon component={RightMouseClick} {...props} />;

const MiddleMouse = props => <Icon component={MiddleMouseClick} {...props} />;

class TestPage extends Component {
    static propTypes = {
        logoutUser: PropTypes.func,
    }

    state = {
        visible: false,
        width: window.innerWidth-100,
        height: window.innerHeight-135,
        unfoldVisible: false,
        date: null,
        startDate: null,
        fileList: [],
        material_id: this.props.materials[0].id,
        id: 0,
        amount: 1,
        rowSelection: undefined,
        slider: 100,
        selectedRadio: 'solid'
    }

    handleChange = date => {
        const datum = new Date(date)
        const datum_string = datum.getDate().toString() + '-' + (datum.getMonth() + 1).toString() + '-' + datum.getFullYear().toString()
        if(datum < new Date()) {
            console.log(datum_string)
        }
        this.setState({
            startDate: date
        });
    };


    handleResize = e => {
        const windowSize = window.innerWidth;
        this.setState({
            width: window.innerWidth-100,
            height: window.innerHeight-135,
        })
        if(this.state.visible && renderer !== undefined && camera !== undefined) {
            renderer.setSize(this.state.width+30, this.state.height);
            camera.aspect = (this.state.width+30) / this.state.height;
            camera.updateProjectionMatrix()
            this.forceUpdate()
        }
        if(canvas !== undefined && canvas !== null) {
            canvas.resize(this.state.width-this.state.margin, this.state.height)
            this.forceUpdate()
        }
        if(windowSize <= 850)
        {
            this.setState({
                margin: 60
            })
            this.forceUpdate()
        }
        else {
            this.setState({
                margin: 200
            })
            this.forceUpdate()
        }
    };



    componentDidMount() {
        const windowSize = window.innerWidth;
        if(windowSize <= 850)
        {
            this.setState({
                margin: 60
            })
            this.forceUpdate()
        }
        else {
            this.setState({
                margin: 200
            })
            this.forceUpdate()
        }
        window.addEventListener("resize", this.handleResize);
        document.getElementById('dxf').addEventListener('change', this.onFileSelected, false);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    componentDidUpdate(prevProps,prevState,snapshot) {
        if (this.state.visible === true && prevState.visible === false) {
            this.showFile();
        }
    }

    onFileSelected = (evt) => {
        var file = evt.target.files[0];

        if(file !== undefined) {
            var reader = new FileReader();
            reader.onloadend = this.onSuccess;
            reader.onabort = this.abortUpload;
            reader.readAsText(file);
        }
    }

    abortUpload = () => {
        console.log('Aborted read!')
    }

    onSuccess = (evt) => {
        var fileReader = evt.target;
        if(fileReader.error) return console.log("error onloadend!?");

        var parser = new DxfParser();
        var dxf = parser.parseSync(fileReader.result);
        var error = [
            {
                x: -10.9333,
                y: 21.9510,
            },
            {
                x: 5.3279,
                y: 33.1174,
            },
        ]
        let width = this.state.width - this.state.margin
        let height = this.state.height
        const loader = new THREE.FontLoader();

        console.log(dxf)

        // loader.load( 'fonts/arial.typeface.json', function ( font ) {
        //     canvas = new ThreeDxf.Viewer(dxf, document.getElementById('cad-view'), width, height, error, font);
        // })
    }

    showUnfold = () => {
        var xray = this.state.selectedRadio === 'xray'
        var loader = new STLLoader();
        loader.load( part1, function ( geometry ) {
            if(!xray) {
                mesh2_line = new THREE.LineSegments(new THREE.EdgesGeometry(geometry, 20), materialLineSolid);
                mesh2_solid = new THREE.Mesh( geometry, materialSolidSolid );
                mesh2_clipLine = new THREE.Mesh( geometry, materialSolidDark );
            }
            else {
                mesh2_line = new THREE.LineSegments(new THREE.EdgesGeometry(geometry, 20), materialLineXray);
                mesh2_solid = new THREE.Mesh( geometry, materialSolidXray );
            }

            mesh2_solid.position.set( 0, 0, 0);
            mesh2_solid.rotation.x = -(Math.PI / 2);
            mesh2_solid.scale.set( 0.1, 0.1, 0.1 );


            mesh2_line.position.set( 0, 0, 0);
            mesh2_line.rotation.x = -(Math.PI / 2);
            mesh2_line.scale.set( 0.1, 0.1, 0.1 );


            mesh2_solid.castShadow = true;
            mesh2_solid.receiveShadow = true;


            var bbox = new THREE.Box3().setFromObject(mesh2_solid);

            if(!xray) {
                mesh2_clipLine.position.set( 0, 0, 0);
                mesh2_clipLine.rotation.x = -(Math.PI / 2);
                mesh2_clipLine.scale.set( 0.1, 0.1, 0.1 );

                mesh2_solid.material.clippingPlanes = [localPlane]
                mesh2_line.material.clippingPlanes = [localPlane]
                mesh2_clipLine.material.clippingPlanes = [localPlaneLower,localPlaneUpper]

                clippingPlaneLen = (bbox.max.y - bbox.min.y) * 1.01
                localPlane.constant = clippingPlaneLen

                localPlaneLower.constant = -clippingPlaneLen*0.996
                localPlaneUpper.constant =  clippingPlaneLen

                mesh2_clipLine.geometry.center();
                mesh2_clipLine.position.set(0,(bbox.max.y-bbox.min.y)/2,0)
            }

            mesh2_solid.geometry.center();
            mesh2_solid.position.set(0,(bbox.max.y-bbox.min.y)/2,0)
            mesh2_line.geometry.center();
            mesh2_line.position.set(0,(bbox.max.y-bbox.min.y)/2,0)

            mesh1_solid.geometry.dispose();
            mesh1_solid.material.dispose();
            mesh1_line.geometry.dispose();
            mesh1_line.material.dispose();
            if(!xray) {
                mesh1_clipLine.geometry.dispose();
                mesh1_clipLine.material.dispose();
                scene.remove(mesh1_clipLine);
            }
            scene.remove(mesh1_solid);
            scene.remove(mesh1_line);

            renderer.renderLists.dispose();
            if(!xray) {
                scene.add( mesh2_clipLine );
            }
            scene.add( mesh2_solid );
            scene.add( mesh2_line );
        } );
        this.setState({
            unfoldVisible: true
        })
    }

    showFold = () => {
        var xray = this.state.selectedRadio === 'xray';
        var loader = new STLLoader();
        loader.load( part, function ( geometry ) {

            if(!xray) {
                mesh1_line = new THREE.LineSegments(new THREE.EdgesGeometry(geometry, 20), materialLineSolid);
                mesh1_solid = new THREE.Mesh( geometry, materialSolidSolid );
                mesh1_clipLine = new THREE.Mesh( geometry, materialSolidDark );
            }
            else {
                mesh1_line = new THREE.LineSegments(new THREE.EdgesGeometry(geometry, 20), materialLineXray);
                mesh1_solid = new THREE.Mesh( geometry, materialSolidXray );
            }

            mesh1_solid.position.set( 0, 0, 0);
            mesh1_solid.rotation.x = -(Math.PI / 2);
            mesh1_solid.scale.set( 0.1, 0.1, 0.1 );

            mesh1_line.position.set( 0, 0, 0);
            mesh1_line.rotation.x = -(Math.PI / 2);
            mesh1_line.scale.set( 0.1, 0.1, 0.1 );

            mesh1_solid.castShadow = true;
            mesh1_solid.receiveShadow = true;

            var bbox = new THREE.Box3().setFromObject(mesh1_solid);

            if(!xray) {
                mesh1_clipLine.position.set( 0, 0, 0);
                mesh1_clipLine.rotation.x = -(Math.PI / 2);
                mesh1_clipLine.scale.set( 0.1, 0.1, 0.1 );


                mesh1_solid.material.clippingPlanes = [localPlane]
                mesh1_line.material.clippingPlanes = [localPlane]
                mesh1_clipLine.material.clippingPlanes = [localPlaneLower,localPlaneUpper]

                clippingPlaneLen = (bbox.max.y - bbox.min.y) * 1.01
                localPlane.constant = clippingPlaneLen

                localPlaneLower.constant = -clippingPlaneLen*0.996
                localPlaneUpper.constant =  clippingPlaneLen

                mesh1_clipLine.geometry.center();
                mesh1_clipLine.position.set(0,(bbox.max.y-bbox.min.y)/2,0)
            }

            mesh1_solid.geometry.center();
            mesh1_solid.position.set(0,(bbox.max.y-bbox.min.y)/2,0)
            mesh1_line.geometry.center();
            mesh1_line.position.set(0,(bbox.max.y-bbox.min.y)/2,0)

            mesh2_solid.geometry.dispose();
            mesh2_solid.material.dispose();
            mesh2_line.geometry.dispose();
            mesh2_line.material.dispose();
            if(!xray) {
                mesh2_clipLine.geometry.dispose();
                mesh2_clipLine.material.dispose();
                scene.remove(mesh2_clipLine);
            }
            scene.remove(mesh2_solid);
            scene.remove(mesh2_line);
            renderer.renderLists.dispose();

            if(!xray) {
                scene.add( mesh1_clipLine );
            }
            scene.add( mesh1_solid );
            scene.add( mesh1_line );

        } );
        this.setState({
            unfoldVisible: false
        })
    }



    showFile = () => {
        scene = new THREE.Scene();
        //
        scene.background = new THREE.Color( 0xf2f2f2 );
        scene.fog = new THREE.FogExp2( 0xefd1b5, 0.0025 );
        //x-ray
        //scene.background = new THREE.Color( 0x222233 );
        camera = new THREE.PerspectiveCamera( 75, (this.state.width+30)/this.state.height, 0.1, 1000 );
        renderer = new THREE.WebGLRenderer({ antialias: true });
        controls = new OrbitControls(camera, renderer.domElement,this.updateAngles)
        controls.enableDamping = true
        controls.dampingFactor = 0.1
        controls.rotateSpeed = 0.1
        renderer.setSize( this.state.width+30, this.state.height );
        container.appendChild( renderer.domElement );
        // var mainCanvas = ReactDOM.findDOMNode(this.thing);
        // mainCanvas.appendChild( renderer.domElement );
        // komentar
        // var mesh = new THREE.Mesh( new THREE.PlaneBufferGeometry( 3000, 3000 ), new THREE.MeshPhongMaterial( { color: 0x4e96b6, depthWrite: false } ) );
        // mesh.rotation.x = - Math.PI / 2;
        //scene.add( mesh );

        // var grid = new THREE.GridHelper( 1000, 200, 0x000000, 0x000000 );
        // grid.material.opacity = 0.2;
        // grid.material.transparent = true;
        // scene.add( grid );

        materialLineSolid = new THREE.LineBasicMaterial({color: 0x1b1b1b, linewidth: 2});
        materialLineXray = new THREE.LineBasicMaterial({color: 0xffffff, linewidth: 2});
        materialSolidSolid = new THREE.MeshPhongMaterial( { color: 0x424447, specular: 0x444444, shininess: 20, side: THREE.DoubleSide} );
        materialSolidDark  = new THREE.MeshLambertMaterial( { color: 0x111111, side: THREE.DoubleSide, clipIntersection: false} );

        // materialCameraPosition = camera.position.clone();
        // materialCameraPosition.z += 10;

        // customMaterial = new THREE.ShaderMaterial({
        //     uniforms: {
        //         "c": { type: "f", value: 1.0 },
        //         "p": { type: "f", value: 3 },
        //         glowColor: { type: "c", value: new THREE.Color(0x84ccff) },
        //         viewVector: { type: "v3", value: materialCameraPosition }
        //     },
        //     vertexShader: vertexShader,
        //     fragmentShader: fragmentShader,
        //     side: THREE.FrontSide,
        //     blending: THREE.AdditiveBlending,
        //     transparent: true,
        //     depthWrite: false,
        //     clipping: true,
        // });

        // console.log(customMaterial)

        var url = "nodes/xray.json";

        var library = {
            "cloud": cloud
        };
        var xrayloader = new NodeMaterialLoader( undefined, library ).load( url, function () {
            materialSolidXray = xrayloader.material;
        });

        renderer.localClippingEnabled = true;
        cloud = new THREE.TextureLoader().load( '../../../assets/cloud.png' );
        cloud.wrapS = cloud.wrapT = THREE.RepeatWrapping;
        var loader = new STLLoader();
        loader.load( part, function ( geometry ) {
            //const material_line = new THREE.LineBasicMaterial({color: 0xffffff, linewidth: 1});
            mesh1_line = new THREE.LineSegments(new THREE.EdgesGeometry(geometry, 20), materialLineSolid);


            mesh1_solid = new THREE.Mesh( geometry, materialSolidSolid );

            mesh1_clipLine = new THREE.Mesh( geometry, materialSolidDark );

            mesh1_solid.position.set( 0, 0, 0);
            mesh1_solid.scale.set( 0.1, 0.1, 0.1 );
            mesh1_solid.rotation.x = -(Math.PI / 2);

            mesh1_clipLine.position.set( 0, 0, 0);
            mesh1_clipLine.scale.set( 0.1, 0.1, 0.1 );
            mesh1_clipLine.rotation.x = -(Math.PI / 2);

            mesh1_line.position.set( 0, 0, 0);
            mesh1_line.rotation.x = -(Math.PI / 2);
            mesh1_line.scale.set( 0.1, 0.1, 0.1 );

            mesh1_solid.castShadow = true;
            mesh1_solid.receiveShadow = true;

            var bbox = new THREE.Box3().setFromObject(mesh1_solid);
            clippingPlaneLen = (bbox.max.y-bbox.min.y)*1.01
            localPlane = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), clippingPlaneLen);
            localPlaneLower = new THREE.Plane( new THREE.Vector3( 0, 1, 0 ), -clippingPlaneLen*0.996);
            localPlaneUpper = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), clippingPlaneLen);

            mesh1_solid.material.clippingPlanes = [localPlane]
            mesh1_line.material.clippingPlanes = [localPlane]
            mesh1_clipLine.material.clippingPlanes = [localPlaneLower,localPlaneUpper]
            //console.log(bbox.max.x-bbox.min.x,bbox.max.z-bbox.min.z)

            var size = Math.max(bbox.max.x-bbox.min.x,bbox.max.z-bbox.min.z)

            var podela
            var skala
            if(size <= 15) {
                podela = Math.round(size)
                skala = 10
            }
            else if(size > 15 && size <= 150) {
                podela = Math.round(size/10)
                skala = 100
            }
            else {
                podela = Math.round(size/100)
                skala = 1000
            }
            console.log(skala)
            // scene.fog = new THREE.Fog(0x96c7dd, 0.0025, skala*3.5);
            var finalSize = (Math.round(size/podela))*(podela+1)
            gridHelper2 = new THREE.GridHelper( finalSize, (podela+1)*2, 0x777777, 0x777777 );
            gridHelper2.position.y = -0.03;
            gridHelper2.position.x = 0;
            scene.add( gridHelper2 );
            gridHelper = new THREE.GridHelper( finalSize, (podela+1), 0x222222, 0x222222 );
            gridHelper.position.y = -0.03;
            gridHelper.position.x = 0;
            gridHelper.material.linewidth = 2;
            scene.add( gridHelper );

            console.log(gridHelper.material)

            mesh1_solid.geometry.center();
            mesh1_solid.position.set(0,(bbox.max.y-bbox.min.y)/2,0)
            mesh1_clipLine.geometry.center();
            mesh1_clipLine.position.set(0,(bbox.max.y-bbox.min.y)/2,0)
            mesh1_line.geometry.center();
            mesh1_line.position.set(0,(bbox.max.y-bbox.min.y)/2,0)

            //scene.add( new THREE.BoxHelper( mesh1_solid ) );
            scene.add( mesh1_clipLine );
            scene.add( mesh1_solid );
            scene.add( mesh1_line );

            const boundingSphere = new THREE.Box3().setFromObject( mesh1_solid ).getBoundingSphere( mesh1_solid );

            const scale = 1.2; // object size / display size
            const objectAngularSize = ( camera.fov * Math.PI / 180 ) * scale;
            const distanceToCamera = boundingSphere.radius / Math.tan( objectAngularSize / 2 )
            const len = Math.sqrt( Math.pow( distanceToCamera, 2 ) + Math.pow( distanceToCamera, 2 ) )

            camera.position.set(len, len, len);
            controls.update();
            const center = boundingSphere.position
            camera.lookAt( center );
            controls.target.set( center.x, center.y, center.z );

            camera.updateProjectionMatrix();

        } );


        //scene.add( new THREE.HemisphereLight( 0x443333, 0x111122 ) );

        scene.add( new THREE.AmbientLight( 0x464646 ) );

        var light1 = new THREE.DirectionalLight( 0xf2f2f2, 1 );
        light1.position.set( 1, 0.75, 0.5 );
        scene.add( light1 );

        var light2 = new THREE.DirectionalLight( 0xbcbcbc, 1 );
        light2.position.set( - 1, 0.75, - 0.5 );
        scene.add( light2 );

        // var globalPlane = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), 4 );
        //
        // renderer.clippingPlanes = [ globalPlane ];
        // renderer.localClippingEnabled = true;

        // var stats = this.createStats();
        // document.body.appendChild( stats.domElement );

        renderer.gammaInput = true;
        renderer.gammaOutput = true;
        renderer.shadowMap.enabled = true;
        controls.update();
        var animate = function () {
            // if(mesh1_solid !== undefined)
            //     mesh1_solid.material.uniforms.viewVector.value = new THREE.Vector3().subVectors(camera.position, mesh1_solid.position);
            id = requestAnimationFrame( animate );
            controls.update();
            renderer.render( scene, camera );
            //stats.update();
        };
        animate();
    }

    closeModal = () => {
        cancelAnimationFrame( id );
        container.removeChild(renderer.domElement);
        this.setState(
            {
                visible: false,
                slider: 100,
                selectedRadio: 'solid',
                unfoldVisible: false,
            }
        )
    }

    removeItem = (id) => {
        this.setState(state => {
            const index = state.fileList.findIndex(x => x.id === id);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList,
            };
        });
    }


    updateAngles = (phi, theta) => {
        if( this.state.visible && container && (currphi !== phi || currtheta !== theta)) {
            currphi = phi;
            currtheta = theta;
            if (controls) {
                controls.setPolarAngle(phi);
                controls.setAzimuthalAngle(theta);
                this.updateAngle2(phi,theta)
            }
        }
    };

    updateAngle2 = (phi, theta) => {
        if( this.state.visible && cube && (currphi2 !== phi || currtheta2 !== theta)) {
            currphi2 = phi;
            currtheta2 = theta;
            cube.setAngles(phi, theta);
        }
    };

    onOk = (value) => {
        console.log('onOk: ', value);
    }

    renderDayContents = (day, date) => {
        const numOfDays = Math.floor((Math.abs(new Date(date)-new Date())/1000)/ 86400)
        const rez = ((1-(numOfDays < 28 ? Math.floor(numOfDays/7)*0.025 : 0.1))*(140.0134)).toFixed(2)
        const tooltipText = `Price for this date: €${rez}`;
        return <span data-tooltip={tooltipText} data-tooltip-position="bottom">{day}</span>;
    };

    handleAnchorClick = (e, link) => {
        e.preventDefault();
    }

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState(state=> {
            return {
                rowSelection: {...state.rowSelection, selectedRowKeys: selectedRowKeys}
            }});
    };

    getMaterial = (id) => {
        const index = this.props.materials.findIndex(x => x.id === id);
        const name = this.props.materials[index].name
        const thickness = this.props.materials[index].thickness
        return `${name} (${thickness}mm)`;
    }

    onSlide = (value) => {
        localPlane.constant = (clippingPlaneLen * (value < 1 ? 1/100 : value/100))
        localPlaneUpper.constant = (clippingPlaneLen * (value < 1 ? 1/100 : value/100))
        localPlaneLower.constant = -(clippingPlaneLen * 0.996 * (value < 1 ? 1/100 : value/100))
        this.setState({slider: value})
    }

    handleTypeChange = (e) => {
        this.setState({ selectedRadio: e.target.value, slider: 100 }
        , ()=> {
            if(this.state.selectedRadio === 'solid') {
                scene.background = new THREE.Color(0xf2f2f2);
                if (this.state.unfoldVisible) {
                    if ( mesh2_solid.material ) mesh2_solid.material.dispose();

                    mesh2_solid.material = materialSolidSolid;

                    if ( mesh2_line.material ) mesh2_line.material.dispose();
                    mesh2_line.material = materialLineSolid;

                    if ( mesh2_clipLine.material ) mesh2_clipLine.material.dispose();
                    mesh2_clipLine.material = materialSolidDark;

                    let bbox = new THREE.Box3().setFromObject(mesh2_solid);
                    clippingPlaneLen = (bbox.max.y-bbox.min.y)*1.01
                    localPlane = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), clippingPlaneLen);
                    localPlaneLower = new THREE.Plane( new THREE.Vector3( 0, 1, 0 ), -clippingPlaneLen*0.996);
                    localPlaneUpper = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), clippingPlaneLen);
                    mesh2_solid.material.clippingPlanes = [localPlane]
                    mesh2_line.material.clippingPlanes = [localPlane]
                    mesh2_clipLine.material.clippingPlanes = [localPlaneLower, localPlaneUpper]

                    scene.add(mesh2_clipLine)
                }
                else {
                    if ( mesh1_solid.material ) mesh1_solid.material.dispose();

                    mesh1_solid.material = materialSolidSolid;

                    if ( mesh1_line.material ) mesh1_line.material.dispose();
                    mesh1_line.material = materialLineSolid;

                    if ( mesh1_clipLine.material ) mesh1_clipLine.material.dispose();
                    mesh1_clipLine.material = materialSolidDark;


                    let bbox = new THREE.Box3().setFromObject(mesh1_solid);
                    clippingPlaneLen = (bbox.max.y-bbox.min.y)*1.01
                    localPlane = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), clippingPlaneLen);
                    localPlaneLower = new THREE.Plane( new THREE.Vector3( 0, 1, 0 ), -clippingPlaneLen*0.996);
                    localPlaneUpper = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), clippingPlaneLen);
                    mesh1_solid.material.clippingPlanes = [localPlane]
                    mesh1_line.material.clippingPlanes = [localPlane]
                    mesh1_clipLine.material.clippingPlanes = [localPlaneLower, localPlaneUpper]

                    scene.add(mesh1_clipLine)
                }
                scene.add( gridHelper2 );
                scene.add( gridHelper );
            }
            else {
                scene.background = new THREE.Color(0x222233);
                if (this.state.unfoldVisible) {
                    if ( mesh2_solid.material ) mesh2_solid.material.dispose();

                    mesh2_solid.material = materialSolidXray;

                    if ( mesh2_line.material ) mesh2_line.material.dispose();
                    mesh2_line.material = materialLineXray;

                    if ( mesh2_clipLine.material ) mesh2_clipLine.material.dispose();

                    scene.remove(mesh2_clipLine)
                }
                else {
                    if ( mesh1_solid.material ) mesh1_solid.material.dispose();
                    mesh1_solid.material = materialSolidXray;

                    if ( mesh1_line.material ) mesh1_line.material.dispose();
                    mesh1_line.material = materialLineXray;

                    if ( mesh1_clipLine.material ) mesh1_clipLine.material.dispose();

                    scene.remove(mesh1_clipLine)
                }
                scene.remove( gridHelper2 );
                scene.remove( gridHelper );

            }

        })

    }

    createStats = () => {
        var stats = new Stats();
        stats.setMode(0);

        stats.domElement.style.position = 'absolute';
        stats.domElement.style.left = '0';
        stats.domElement.style.top = '0';

        return stats;
    }

    render() {
        const { logoutUser} = this.props
        return (
            <Layout
                style={{
                    overflow: 'auto',
                    minHeight: '100vh',
                }}
            >
                <AdminSidebar history={this.props.history} onLogout={logoutUser} />
                <Layout.Content style={{marginLeft: this.state.margin}} type="flex">
                    <Button
                        type="primary"
                        size={"small"}
                        style={{height: "30px"}}
                        onClick={() => {

                            this.setState({visible:true});
                        }}>
                        View
                    </Button>

                    <label htmlFor="exampleInputFile">Choose a DXF file</label>
                    <input type="file" accept=".dxf" id="dxf" name="file"/>
                    <div id="cad-view" style={{margin: '10px', padding: '20px'}}>
                    </div>
                    <Modal
                        onCancel={this.closeModal}
                        visible={this.state.visible}
                        footer={null}
                        maskClosable={true}
                        closable={false}
                        centered={true}
                        forceRender={true}
                        header={null}
                        width={this.state.width+50}
                        bodyStyle={{padding: 10, background: this.state.selectedRadio === 'solid' ? '#FFFFFF' : '#333333', color: this.state.selectedRadio === 'solid' ? '#333333' : '#FFFFFF', transition: 'all 0.4s'}}
                        className={this.state.selectedRadio === 'solid' ? "whiteModal partView" : "blackModal partView" }
                    >
                        <div>
                            {/*<div style={{marginTop: '-30px'}}>*/}
                            {/*    <LeftMouse style={{ fontSize: '30px' }} />*/}
                            {/*    <p style={{display: "inline-block", marginLeft: '3px',marginRight: '4px', verticalAlign: 'middle'}}>Left Click To Rotate</p>*/}
                            {/*    <RightMouse style={{ fontSize: '30px' }} />*/}
                            {/*    <p style={{display: "inline-block", marginLeft: '3px',marginRight: '4px', verticalAlign: 'middle'}}>Right Click To Pan</p>*/}
                            {/*    <MiddleMouse style={{ fontSize: '32px' }} />*/}
                            {/*    <p style={{display: "inline-block", marginLeft: '3px',marginRight: '4px', verticalAlign: 'middle'}}>Scroll To Zoom</p>*/}
                            {/*</div>*/}
                            <div style={{marginTop: 15, marginBottom: -45, marginLeft: 15, marginRight: 15, background: 'rgba(0,0,0,0)', borderRadius: 6}}>
                                <Radio.Group className={'typeGroup'} value={this.state.selectedRadio} buttonStyle="solid" onChange={this.handleTypeChange}>
                                    <Radio.Button value="solid">Solid</Radio.Button>
                                    <Radio.Button value="xray">X-Ray</Radio.Button>
                                </Radio.Group>
                                <Row style={{marginLeft: 160}}>
                                    <Col span={24}>
                                        <div style={{marginTop: "-42.5px" ,marginBottom: -45}}>
                                            <div>
                                                <LeftMouse style={{fontSize: '30px'}}/>
                                                <p style={{
                                                    display: "inline-block",
                                                    marginLeft: '2px',
                                                    marginRight: '10px',
                                                    verticalAlign: 'middle'
                                                }}>Rotate</p>
                                                <MiddleMouse style={{fontSize: '32px'}}/>
                                                <p style={{
                                                    display: "inline-block",
                                                    marginLeft: '2px',
                                                    marginRight: '10px',
                                                    verticalAlign: 'middle'
                                                }}>Zoom</p>
                                                <RightMouse style={{fontSize: '30px'}}/>
                                                <p style={{
                                                    display: "inline-block",
                                                    marginLeft: '2px',
                                                    verticalAlign: 'middle'
                                                }}>Pan</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div align={'right'} style={{marginTop: -32}}>
                                    <Button
                                        style={{
                                            background: this.state.selectedRadio === 'solid' ? 'white' : '#333333',
                                            color: this.state.selectedRadio === 'solid' ? '#333333' : 'white',
                                            boxShadow: '0 4px 8px 2px rgba(0, 0, 0, 0.12)'
                                        }}
                                    >
                                        <CloseOutlined />Close
                                    </Button>
                                </div>
                            </div>
                            <div key={"thing"} className={'step-canvas'} ref={ref => (container = ref)} />
                            <div style={{marginTop: -155, marginLeft: -20, borderRadius: 6}}>
                                { this.state.visible ?
                                    (<CubeView
                                        aspect={1}
                                        hoverColor={0x0088FF}
                                        cubeSize={2}
                                        zoom={6}
                                        antialias={true}
                                        width={150}
                                        height={150}
                                        ref={c => (cube = c)}
                                        onUpdateAngles={this.updateAngles}
                                    />) : null
                                }
                            </div>
                            <div align={'right'}
                                 style={{marginTop: -210, paddingRight: 20}}
                            >
                                <Button  style={{height: 130, paddingTop: 5, paddingLeft: 5, paddingRight: 5}} className={"slider-okvir"}>
                                    <div style={{height: 120, paddingTop: 10}}>
                                        <Slider
                                            vertical
                                            disabled={this.state.selectedRadio === 'xray'}
                                            step={0.5}
                                            defaultValue={100}
                                            onChange={this.onSlide}
                                            style={{height: 90}}
                                            tipFormatter={null}
                                            value={this.state.slider}
                                        />
                                    </div>
                                </Button>
                            </div>
                            <div align={"right"} style={{marginTop: 15, marginBottom: 15, paddingRight: 20}}>
                            {this.state.unfoldVisible ?
                                (<Button
                                    type="primary"
                                    size={"large"}
                                    style={{boxShadow: '0 4px 8px 2px rgba(0, 0, 0, 0.12)'}}
                                    onClick={() => {
                                        this.showFold();
                                        this.setState({slider: 100})
                                    }}>
                                    Show Fold
                                </Button>) :
                                (<Button
                                    type="primary"
                                    size={"large"}
                                    style={{boxShadow: '0 4px 8px 2px rgba(0, 0, 0, 0.12)'}}
                                    onClick={() => {
                                        this.showUnfold();
                                        this.setState({slider: 100})
                                    }}>
                                    Show Unfold
                                </Button>)
                            }
                            </div>
                        </div>
                    </Modal>

                    {/*<Modal*/}
                    {/*    closable={false}*/}
                    {/*    onOk={null}*/}
                    {/*    footer={[*/}
                    {/*        <Button key="Ok" type={'danger'} onClick={null}>*/}
                    {/*            Ok*/}
                    {/*        </Button>,*/}
                    {/*    ]}*/}
                    {/*    centered={true}*/}
                    {/*    className={'warningWindow'}*/}
                    {/*    visible={false}*/}
                    {/*    bodyStyle={{color:'red'}}*/}
                    {/*>*/}
                    {/*    <React.Fragment>*/}
                    {/*        <div align={'center'}><Icon type={"warning"} style={{color: 'red',fontSize: 40}} /></div>*/}
                    {/*        <div align={'center'} style={{fontSize: 20}}>WARNING!</div>*/}
                    {/*        <div align={'center'} style={{fontSize: 16, marginTop: 15}}>The thickness of the material does not match the thickness of the part!</div>*/}
                    {/*        <div align={'center'} style={{fontSize: 16}}>Please choose a different material!</div>*/}
                    {/*    </React.Fragment>*/}
                    {/*</Modal>*/}

                    {/*<Button onClick={this.showConfirm}>Confirm</Button>*/}

                    {/*<MyComponent/>*/}


                    {/*<DatePicker*/}
                    {/*    selected={this.state.startDate}*/}
                    {/*    onChange={this.handleChange}*/}
                    {/*    renderDayContents={this.renderDayContents}*/}
                    {/*    dateFormat={'dd-MM-yyyy'}*/}
                    {/*    minDate={new Date()}*/}
                    {/*    isClearable={true}*/}
                    {/*    placeholderText="Click to select a date"*/}
                    {/*/>*/}
                    {/*    <Row style={{margin: 20}} gutter={10}>*/}
                    {/*        <Col xxl={18} xl={24} lg={24}>*/}
                    {/*            <Card style={{height: this.state.height < 565 ? 700 : this.state.height+90}}>*/}
                    {/*                <Row >*/}
                    {/*                    <Col span = {15}>*/}
                    {/*                        <Upload.Dragger*/}
                    {/*                            name= 'file'*/}
                    {/*                            accept=".dxf,.step,.stp"*/}
                    {/*                            multiple = {true}*/}
                    {/*                            beforeUpload = {file => {*/}
                    {/*                                this.setState(state => { return{*/}
                    {/*                                    fileList: [...state.fileList, {id: state.id, file: file, name: file.name, material: state.material_id, amount: state.amount}],*/}
                    {/*                                    id: state.id+1*/}
                    {/*                                }});*/}
                    {/*                                return false;*/}
                    {/*                            }}*/}
                    {/*                            showUploadList={false}*/}
                    {/*                        >*/}
                    {/*                            <p className="ant-upload-drag-icon">*/}
                    {/*                                <Icon type="inbox" />*/}
                    {/*                            </p>*/}
                    {/*                            <p className="ant-upload-text">Click or drag files to this area to upload</p>*/}
                    {/*                            <p className="ant-upload-hint">*/}
                    {/*                                Support for bulk upload. Use only STEP or DXF file format.*/}
                    {/*                            </p>*/}
                    {/*                        </Upload.Dragger>*/}
                    {/*                    </Col>*/}

                    {/*                    <Col  offset={1} span={8}>*/}
                    {/*                        <Col span={this.state.rowSelection === undefined ? 24 : 15}>*/}
                    {/*                            Material*/}
                    {/*                            <Select*/}
                    {/*                                defaultValue={this.props.materials[0].id}*/}
                    {/*                                style={{*/}
                    {/*                                    display: 'block',*/}
                    {/*                                    width: '100%',*/}
                    {/*                                    marginBottom: '20px',*/}
                    {/*                                }}*/}
                    {/*                                onChange={value => {*/}
                    {/*                                    this.setState({*/}
                    {/*                                        material_id: value,*/}
                    {/*                                    })*/}
                    {/*                                }}>*/}
                    {/*                                {this.props.materials.map(material => (*/}
                    {/*                                    <Select.Option key={material.id} value={material.id}>*/}
                    {/*                                        {material.name} ({material.thickness}mm)*/}
                    {/*                                    </Select.Option>*/}
                    {/*                                ))}*/}
                    {/*                            </Select>*/}
                    {/*                        </Col>*/}
                    {/*                        {this.state.rowSelection === undefined ? null :*/}
                    {/*                            (<Col offset={1} span={8}>*/}
                    {/*                                <div align={'right'} style={{marginTop: 20}}>*/}
                    {/*                                    <Button*/}
                    {/*                                        type={'primary'}*/}
                    {/*                                        onClick={()=>{*/}
                    {/*                                            this.setState(state => {*/}
                    {/*                                                let i;*/}
                    {/*                                                let keys = state.rowSelection.selectedRowKeys;*/}
                    {/*                                                let newFileList = state.fileList;*/}
                    {/*                                                for(i=0;i<keys.length;i++) {*/}
                    {/*                                                    const index = newFileList.findIndex(x => x.id === keys[i]);*/}
                    {/*                                                    newFileList[index] = {...newFileList[index], material: state.material_id};*/}
                    {/*                                                }*/}
                    {/*                                                return {*/}
                    {/*                                                    fileList: newFileList,*/}
                    {/*                                                }*/}
                    {/*                                            })*/}
                    {/*                                        }}*/}
                    {/*                                    >*/}
                    {/*                                        Apply Material*/}
                    {/*                                    </Button>*/}
                    {/*                                </div>*/}
                    {/*                            </Col>)*/}
                    {/*                        }*/}

                    {/*                        <Col span={this.state.rowSelection === undefined ? 24 : 16}>*/}
                    {/*                        Amount*/}
                    {/*                        <br/>*/}
                    {/*                        <InputNumber*/}
                    {/*                            min={1}*/}
                    {/*                            step={1}*/}
                    {/*                            defaultValue={this.state.amount}*/}
                    {/*                            type={'number'}*/}
                    {/*                            value={this.state.amount}*/}
                    {/*                            onChange={value => {*/}
                    {/*                                this.setState({*/}
                    {/*                                    amount: value === null ? 1 : value,*/}
                    {/*                                })*/}
                    {/*                            }}*/}
                    {/*                        />*/}
                    {/*                        </Col>*/}
                    {/*                        {this.state.rowSelection === undefined ? null :*/}
                    {/*                            (<Col span={8}>*/}
                    {/*                                <div align={'right'} style={{marginTop: 20}}>*/}
                    {/*                                    <Button*/}
                    {/*                                        type={'primary'}*/}
                    {/*                                        onClick={()=>{*/}
                    {/*                                            this.setState(state => {*/}
                    {/*                                                let i;*/}
                    {/*                                                let keys = state.rowSelection.selectedRowKeys;*/}
                    {/*                                                let newFileList = state.fileList;*/}
                    {/*                                                for(i=0;i<keys.length;i++) {*/}
                    {/*                                                    const index = newFileList.findIndex(x => x.id === keys[i]);*/}
                    {/*                                                    newFileList[index] = {...newFileList[index], amount: state.amount};*/}
                    {/*                                                }*/}
                    {/*                                                return {*/}
                    {/*                                                    fileList: newFileList,*/}
                    {/*                                                }*/}
                    {/*                                            })*/}
                    {/*                                        }}*/}
                    {/*                                    >*/}
                    {/*                                        Apply Amount*/}
                    {/*                                    </Button>*/}
                    {/*                                </div>*/}
                    {/*                            </Col>)*/}
                    {/*                        }*/}
                    {/*                            {this.state.fileList.length !== 0 && this.state.rowSelection === undefined ?*/}
                    {/*                                (*/}
                    {/*                                    <div style={{position: 'absolute', bottom: 0, right: 0, top: '170%'}}>*/}
                    {/*                                    <Button*/}
                    {/*                                        type={'primary'}*/}
                    {/*                                        onClick={()=>{*/}
                    {/*                                            this.setState({*/}
                    {/*                                                rowSelection: {*/}
                    {/*                                                    selectedRowKeys: [],*/}
                    {/*                                                    onChange: this.onSelectChange*/}
                    {/*                                                }*/}
                    {/*                                            })*/}
                    {/*                                        }}*/}
                    {/*                                    >*/}
                    {/*                                        Edit Table*/}
                    {/*                                    </Button>*/}
                    {/*                                    </div>*/}
                    {/*                                )*/}
                    {/*                            :*/}
                    {/*                                null*/}
                    {/*                            }*/}
                    {/*                            {this.state.fileList.length !== 0 && this.state.rowSelection !== undefined ?*/}
                    {/*                                (*/}
                    {/*                                    <React.Fragment>*/}
                    {/*                                        <div style={{position: 'absolute', bottom: 0, top: '170%'}}>*/}
                    {/*                                            <Button*/}
                    {/*                                                type={"danger"}*/}
                    {/*                                                onClick={()=>{*/}
                    {/*                                                    this.setState(state => {*/}
                    {/*                                                        let i;*/}
                    {/*                                                        let keys = state.rowSelection.selectedRowKeys;*/}
                    {/*                                                        let newFileList = state.fileList;*/}
                    {/*                                                        for(i=0;i<keys.length;i++) {*/}
                    {/*                                                            const index = newFileList.findIndex(x => x.id === keys[i]);*/}
                    {/*                                                            newFileList = newFileList.slice();*/}
                    {/*                                                            newFileList.splice(index, 1);*/}
                    {/*                                                        }*/}
                    {/*                                                        return {fileList: newFileList,*/}
                    {/*                                                        rowSelection: undefined}*/}
                    {/*                                                    })*/}
                    {/*                                                }}*/}
                    {/*                                            >*/}
                    {/*                                                Delete Selected*/}
                    {/*                                            </Button>*/}
                    {/*                                        </div>*/}
                    {/*                                        <div style={{position: 'absolute', bottom: 0, right: 0, top: '170%'}}>*/}
                    {/*                                            <Button*/}
                    {/*                                                onClick={()=>{*/}
                    {/*                                                    this.setState({*/}
                    {/*                                                        rowSelection: undefined*/}
                    {/*                                                    })*/}
                    {/*                                                }}*/}
                    {/*                                                style={{marginRight: 5}}*/}
                    {/*                                            >*/}
                    {/*                                                Cancel*/}
                    {/*                                            </Button>*/}
                    {/*                                            <Button*/}
                    {/*                                                type={'primary'}*/}
                    {/*                                                onClick={()=>{*/}
                    {/*                                                    this.setState({*/}
                    {/*                                                        rowSelection: undefined*/}
                    {/*                                                    })*/}
                    {/*                                                }}*/}
                    {/*                                            >*/}
                    {/*                                                Done*/}
                    {/*                                            </Button>*/}
                    {/*                                        </div>*/}
                    {/*                                    </React.Fragment>*/}
                    {/*                                )*/}
                    {/*                                :*/}
                    {/*                                null*/}
                    {/*                            }*/}
                    {/*                    </Col>*/}
                    {/*                    <Col span={24} style={{marginTop: 20}}>*/}
                    {/*                        <Table*/}
                    {/*                            columns = {[*/}
                    {/*                                {*/}
                    {/*                                    title: 'Name',*/}
                    {/*                                    key: 'name',*/}
                    {/*                                    width: this.state.width/5,*/}
                    {/*                                    render: row => (*/}
                    {/*                                        <div>*/}
                    {/*                                            {row.name}*/}
                    {/*                                        </div>*/}
                    {/*                                    ),*/}
                    {/*                                },*/}
                    {/*                                {*/}
                    {/*                                    title: 'Material',*/}
                    {/*                                    key: 'material',*/}
                    {/*                                    width: this.state.width/8,*/}
                    {/*                                    render: row => (*/}
                    {/*                                        <div >*/}
                    {/*                                            {this.getMaterial(row.material)}*/}
                    {/*                                        </div>*/}
                    {/*                                    ),*/}
                    {/*                                },*/}
                    {/*                                {*/}
                    {/*                                    title: 'Amount',*/}
                    {/*                                    key: 'amount',*/}
                    {/*                                    width: this.state.width/18,*/}
                    {/*                                    render: row => (*/}
                    {/*                                        <div align={"right"}>*/}
                    {/*                                            {row.amount}*/}
                    {/*                                        </div>*/}
                    {/*                                    ),*/}
                    {/*                                },*/}
                    {/*                                {*/}
                    {/*                                    title: 'Remove',*/}
                    {/*                                    key: 'Remove',*/}
                    {/*                                    render: row => (*/}

                    {/*                                        <div align={"right"}>*/}
                    {/*                                            <Icon*/}
                    {/*                                                type="close-circle"*/}
                    {/*                                                disabled={this.state.rowSelection !== undefined}*/}
                    {/*                                                theme="twoTone"*/}
                    {/*                                                style={{fontSize: 28}}*/}
                    {/*                                                twoToneColor="#eb4d4d"*/}
                    {/*                                                onClick={ () => {*/}
                    {/*                                                    this.removeItem(row.id)*/}
                    {/*                                                }}*/}
                    {/*                                            />*/}
                    {/*                                            <Button*/}
                    {/*                                                type={"danger"}*/}
                    {/*                                                disabled={this.state.rowSelection !== undefined}*/}
                    {/*                                                onClick={ () => {*/}
                    {/*                                                    this.removeItem(row.id)*/}
                    {/*                                                }}*/}
                    {/*                                            >*/}
                    {/*                                                <strong>X</strong>*/}
                    {/*                                            </Button>*/}
                    {/*                                        </div>*/}
                    {/*                                    ),*/}

                    {/*                                }*/}
                    {/*                            ]}*/}
                    {/*                            rowKey="id"*/}
                    {/*                            size={'small'}*/}
                    {/*                            scroll={{ y: this.state.height < 565 ? 310 : this.state.height - 330 }}*/}
                    {/*                            dataSource={this.state.fileList}*/}
                    {/*                            pagination={false}*/}
                    {/*                            bordered={true}*/}
                    {/*                            rowSelection={this.state.rowSelection}*/}
                    {/*                        />*/}
                    {/*                    </Col>*/}
                    {/*                </Row>*/}
                    {/*                {this.state.fileList.length !== 0 ?*/}
                    {/*                    (<div style={{position: 'absolute', bottom: 20, right: 24}}>*/}
                    {/*                        <Button*/}
                    {/*                            type={'primary'}*/}
                    {/*                            disabled={this.state.rowSelection !== undefined}*/}
                    {/*                            onClick={()=>{*/}
                    {/*                                console.log("Order place")*/}
                    {/*                                this.setState({visible:true});*/}
                    {/*                            }}*/}
                    {/*                        >*/}
                    {/*                            Process Bulk Order*/}
                    {/*                        </Button>*/}
                    {/*                    </div>)*/}
                    {/*                    :*/}
                    {/*                    null*/}
                    {/*                }*/}
                    {/*            </Card>*/}
                    {/*        </Col>*/}
                    {/*        <Col xxl={6} xl={24} lg={24}>*/}
                    {/*            <Card*/}
                    {/*                title={"Bulk Order Archive"}*/}
                    {/*            >*/}
                    {/*                <List>*/}

                    {/*                </List>*/}
                    {/*            </Card>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}

                    {/*    <Modal*/}
                    {/*        onCancel={this.closeModal}*/}
                    {/*        visible={this.state.visible}*/}
                    {/*        footer={null}*/}
                    {/*        maskClosable={false}*/}
                    {/*        centered={true}*/}
                    {/*        title={"Processing Order"}*/}
                    {/*    >*/}
                    {/*        <div align={'center'}>*/}
                    {/*            <h1>Please wait, your order is being processed</h1>*/}
                    {/*            <h3>This can take some time</h3>*/}
                    {/*            <div align={'center'} style={{marginTop: 40, marginBottom:40}}>*/}
                    {/*                <Spin indicator={<Icon type="loading" style={{fontSize: 90}} spin/>}/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <h3 style={{border: '1px solid rgb(210, 210, 210)', borderRadius: 4, backgroundColor: 'rgb(250, 250, 250)', padding: 10}}>When the order is processed the result will be displayed here and added to your bulk order archive for later use.</h3>*/}
                    {/*            <h3 style={{border: '1px solid rgb(210, 210, 210)', borderRadius: 4, backgroundColor: 'rgb(250, 250, 250)', padding: 10}}>You can wait here until the order is processed or visit this page later and the order will be in your bulk order archive </h3>*/}
                    {/*        </div>*/}
                    {/*    </Modal>*/}
                </Layout.Content>

            </Layout>
        )
    }
}

const mapStateToProps = state => ({
    materials: [{id:1, name: "Steel", thickness: "3"},{id:2, name: "Aluminium", thickness: "2"},{id:3, name: "Stainless steel", thickness: "5"}]
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({logoutUser }, dispatch)

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TestPage)
)