import React from 'react'

const Link = ({ to, history, children, style }) => (
  <a
    href={to}
    style={{fontWeight: 600, fontSize: 13, ...style}}
    onClick={event => {
      event.preventDefault()
      history.push(to)
    }}>
    {children}
  </a>
)

export default Link
