import styled from 'styled-components'

const LinkWrapper = styled.div`
  text-align: center;
`

const CardTitle = styled.h2`
  text-align: center;
  margin-bottom: 15px;
`

const Logo = styled.img`
  width: 100px;
  margin-bottom: 10px;
`

export { LinkWrapper, CardTitle, Logo }
