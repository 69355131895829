import React from "react";
import {Button, Card, Col, Form, Input, Row, Select} from "antd";
import {
  CaretDownOutlined,
  GlobalOutlined, HomeOutlined,
  MailOutlined,
  PhoneOutlined,
  SearchOutlined,
  UserOutlined
} from "@ant-design/icons";
import DatePicker from "react-datepicker";
import {countries} from "../../../../data/countries";
import PublicIcon from "@mui/icons-material/Public";
import {ManualQuote} from "../../../../assets/icons/manualQuote";

export const OrderShippingStage = (props) => {

  const {
    largeThreshold, user, order, proceedToConfirmation,
    selectedDate, handleDateChange, screens, isChangingDeliveryDate,
    isChangingDraftStage
  } = props

  const getDeliveryDate = () => {
    if(order.deliveryDate < Date.now()) {
      return null
    }
    return order.deliveryDate
  }

  return (
    <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
      <Card
        style={{
          borderRadius: 5,
          border: '1px solid #c0c0c0',
          width: "100%",
          height: "100%",
          marginRight: largeThreshold ? 10 : 20
        }}
        bodyStyle={{padding: "20px 20px 20px 20px", width: "100%", height: "100%"}}
      >
        <div>
          <div style={{fontSize: 20, fontWeight: 600, color: "#333f48"}}>
            Delivery information
          </div>
          <Card
            style={{
              boxShadow: "0 0 24px 0 rgba(0, 0, 0, 0.13)",
              border: '1px solid #e0e0e0',
              borderRadius: 5,
              marginTop: 15
            }}
          >
            <Form
              name={"shippingForm"}
              onFinish={proceedToConfirmation}
            >
              <Row gutter={30}>
                <Col span={24} style={{borderBottom: "1px dashed rgba(0, 0, 0, 0.14)"}}>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex"}}>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          color: "#333f48",
                          marginBottom: 15
                        }}
                      >
                        Delivery date
                      </div>
                      <div style={{marginLeft: 20, marginTop: -3}}
                      >
                        <Form.Item
                          name={"deliveryDate"}
                          rules={[
                            {required: true, message: 'Required'}
                          ]}
                          autoComplete="off"
                          initialValue={getDeliveryDate()}
                        >
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat={'dd/MM/yyyy'}
                            minDate={new Date()}
                            isClearable={true}
                            autoComplete="off"
                            placeholderText="Click to select a date"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </Col>
                <Col
                  lg={12}
                  md={24}
                  xs={24}
                  style={
                    screens['lg'] ?
                      {borderRight: "1px dashed rgba(0, 0, 0, 0.14)"}
                      :
                      {borderBottom: "1px dashed rgba(0, 0, 0, 0.14)"}
                  }
                >
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#333f48",
                      marginBottom: 15,
                      marginTop: 15
                    }}
                  >
                    Contact information
                  </div>
                  <Row gutter={10}>
                    <Col span={12}>
                      <div style={{fontSize: 13, fontWeight: 500}}>Full Name:</div>
                      <Form.Item
                        name={"name"}
                        rules={[
                          {required: true, message: 'Required'}
                        ]}
                        initialValue={user.name}
                      >
                        <Input
                          prefix={<UserOutlined />}
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <div style={{fontSize: 13, fontWeight: 500}}>Email:</div>
                      <Form.Item
                        name={"email"}
                        rules={[
                          {required: true, message: 'Required'}
                        ]}
                        initialValue={user.email}
                      >
                        <Input
                          prefix={<MailOutlined />}
                          disabled={true}
                        />
                      </Form.Item>
                      <div style={{fontSize: 13, fontWeight: 500}}>Phone:</div>
                      <Form.Item
                        name={"phone"}
                        rules={[
                          {required: true, message: 'Required'}
                        ]}
                        initialValue={order?.shippingData?.phone || user.phone}
                      >
                        <Input
                          placeholder={"Enter contact phone"}
                          prefix={<PhoneOutlined/>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                </Col>
                <Col lg={12} md={24} xs={24}>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#333f48",
                      marginBottom: 15,
                      marginTop: 15
                    }}
                  >
                    Delivery address
                  </div>
                  <div style={{fontSize: 13, fontWeight: 500}}>Country:</div>
                  <Form.Item
                    name={"country"}
                    rules={[
                      {required: true, message: 'Required'}
                    ]}
                    initialValue={order?.shippingData?.country || user.country}
                  >
                    <Select
                      showSearch
                      style={{width: '100%'}}
                      prefix={<GlobalOutlined />}
                      suffixIcon={(e) => {
                        return e.focused ? <SearchOutlined/> : <CaretDownOutlined/>
                      }}
                      placeholder="Select your country"
                      optionFilterProp="children"
                      notFoundContent={"Country not found"}
                      filterOption={(input, option) =>
                        option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      autoComplete="new-password"
                    >
                      {
                        countries.map(item => {
                          return (
                            <Select.Option
                              key={item.name}
                              value={item.name}
                              className={"countrySelect"}
                            >
                              <img
                                src={item.flag}
                                alt=""
                                style={{
                                  position: "relative",
                                  top: -2,
                                  width: 18
                                }}
                              /> {item.name}
                            </Select.Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                  <Row gutter={10}>
                    <Col span={16}>
                      <div style={{fontSize: 13, fontWeight: 500}}>City:</div>
                      <Form.Item
                        name={"city"}
                        rules={[
                          {required: true, message: 'Required'}
                        ]}
                        initialValue={order?.shippingData?.city || user.city}
                      >
                        <Input
                          placeholder={"Enter country"}
                          prefix={<PublicIcon style={{fontSize: 17}}/>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <div style={{fontSize: 13, fontWeight: 500}}>Postal Code:</div>
                      <Form.Item
                        name={"postalCode"}
                        rules={[
                          {required: true, message: 'Required'}
                        ]}
                        initialValue={order?.shippingData?.postalCode || user.postalCode}
                      >
                        <Input
                          placeholder={"Enter postal code"}
                          prefix={<PublicIcon style={{fontSize: 17}}/>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div style={{fontSize: 13, fontWeight: 500}}>Address:</div>
                  <Form.Item
                    name={"address"}
                    rules={[
                      {required: true, message: 'Required'}
                    ]}
                    initialValue={order?.shippingData?.address || user.address}
                  >
                    <Input
                      placeholder={"Enter delivery address"}
                      prefix={<HomeOutlined />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <div
            style={{
              display: largeThreshold ? 'block' : 'flex',
              marginTop: 20, backgroundColor: "#D3E9FF",
              borderRadius: 5, overflow: "hidden",
              width: "100%"
            }}
          >
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                padding: 20,
                justifyContent: largeThreshold ? 'center' : 'left'
              }}
            >
              <span style={{
                display: "flex",
                alignItems: "center"
              }}>
                <ManualQuote/>
              </span>
              <span style={{
                display: "flex",
                fontSize: 14,
                fontWeight: 600,
                marginLeft: 20,
                alignItems: "center",
                color: "#333f48"
              }}
              >
                Automated shipping quote will be available in the future.
              </span>
            </div>
            <div style={{
              boxShadow: "inset 0 1px 50px 0 rgb(155 185 216 / 50%)",
              padding: 20,
              width: largeThreshold ? "100%" : 380,
              flexShrink: 0
            }}
            >
              <div
                align={"center"}
                style={{
                  paddingBottom: 10,
                  fontSize: 12,
                  fontWeight: 600,
                  color: "#333f48"
                }}
              >
                We will quote your shipping manually.
                <br/>
                Proceed and we will send you a quote later.
              </div>
              <div align={"center"}>
                <Button
                  type={"primary"}
                  size={'large'}
                  style={{width: '100%', maxWidth: 600}}
                  disabled={isChangingDeliveryDate || isChangingDraftStage}
                  form={"shippingForm"}
                  key={"submit"}
                  htmlType={"submit"}
                >
                  <span className={"nextStepButtonText"}>Proceed to Confirmation</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </Card>
    </div>
  )
}