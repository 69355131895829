import React from "react";
import {Card, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

export const ProcessingItemCard = (props) => {

  const { item } = props;

  return (
    <Card
      style={{
        borderRadius: "5px",
        border: "1px solid #07223d",
        marginBottom: 20,
        backgroundColor: "#082745"
      }}
      bodyStyle={{
        padding: 0
      }}
    >
      <div
        className={"item-head"}
      >
        <div className={"item-head-left-side"}>
          <Spin
            className={"item-head-spin"}
            indicator={<LoadingOutlined style={{color: "rgb(83,170,238)", fontSize: 18}}/>}
          />
          <span
            className={"text14-600"}
            style={{marginLeft: 10, color: "white"}}
          >
            {item.fileName}
          </span>
        </div>
        <div className={"item-head-right-side"}>
          <span className={"text12-500"} style={{color: "rgb(77,159,226)"}}>Processing...</span>
        </div>
      </div>
    </Card>
  )
}