import React, { useEffect, useState } from "react";
import {Button, Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import cadSheetMetal from "../assets/icons/cad_sheet.svg";
import cadMachined from "../assets/icons/cad_machined.svg";
import cadProfile from "../assets/icons/cad_profile.svg";
import cadOther from "../assets/icons/cad_other.svg";
import format from "date-fns/format";

export const slideUp = (target, duration=500) => {
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.boxSizing = 'border-box';
  target.style.height = target.offsetHeight + 'px';
  target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout( () => {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
    //alert("!");
  }, duration);
}

export const slideDown = (target, duration=500, forceFlexDisplay=false) => {
  target.style.removeProperty('display');
  let display = window.getComputedStyle(target).display;

  if (display === 'none')
    display = 'block';

  if (forceFlexDisplay) {
    target.style.display = "flex";
  } else {
    target.style.display = display;
  }
  let height = target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + 'ms';
  target.style.height = height + 'px';
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');
  window.setTimeout( () => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
}

export const slideToggle = (target, duration = 500) => {
  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration);
  } else {
    return slideUp(target, duration);
  }
}

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export const getColumnSearchProps = (dataIndex, searchInput, name="") => ({
  filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => { searchInput = node; }}
        placeholder={`Search ${name || dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => confirm()}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        <span style={{ fontWeight: 600, fontSize: 13 }}>Search</span>
      </Button>
      <Button
        onClick={() => {
          clearFilters()
          confirm()
        }}
        size="small"
        style={{ width: 90 }}
      >
        <span style={{ fontWeight: 600, fontSize: 13 }}>Reset</span>
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#23d317' : '#1890ff' }} />,
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.select());
    }
  },
})

export const formatDate = date => {
  let datum = new Date(date)
  return (datum.getDate() < 10 ? '0' : '') + datum.getDate().toString() + (datum.getMonth() < 9 ? '/0' : '/') + (datum.getMonth() + 1).toString() + '/' + datum.getFullYear().toString()
};

export const getPlaceholderImage = (type) => {
  switch (type) {
    case "sheetMetal":
      return cadSheetMetal
    case "machined":
      return cadMachined
    case "profile":
      return cadProfile
    case "other":
      return cadOther
    default:
      return cadOther
  }
};

export const getManualType = (type) => {
  switch (type) {
    case "sheetMetal": return "Sheet Metal";
    case "profile": return "Profile"
    case "machined": return "Machined Part"
    case "assembly": return "Assembly"
    case "other": return "Other"

    default: return "Other"
  }
}

export const getOrderStateName = (state) => {
  switch (state) {
    case "quoted":
      return "Pending"
    case "awaitingQuote":
      return "Pending"
    case "awaitingConfirmation":
      return "Awaiting"
    case "accepted":
      return "Accepted"
    case "inProgress":
      return "In Progress"
    case "declined":
      return "Declined"
    case "completed":
      return "Completed"
    case "internalArchived":
      return "Internal"
    default:
      return "Pending"
  }
}

export const formatTableDate = (timestamp) => {
  let date;
  try {
    date = format(new Date(timestamp), "dd/MM/yyyy")
  } catch {
    date = format(new Date(null), "dd/MM/yyyy")
  }
  return date
}

export const formatDateTime = (timestamp) => {
  let date;
  try {
    date = format(new Date(timestamp), "dd/MM/yyyy HH:mm")
  } catch {
    date = format(new Date(null), "dd/MM/yyyy HH:mm")
  }
  return date
}

export const formatTime = the_time => {
  let sec_num = parseInt(the_time, 10);

  let hours   = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  return hours+'h:'+minutes+'m';
}

export const escapeRegExp = (text) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export const getTotalSuggestedPrice = (opPrices, quantity) => {
  return (
    (opPrices.cuttingPricePerPiece * quantity + opPrices.cuttingPreparationPrice)/quantity +
    (opPrices.bendingPricePerPiece * quantity + opPrices.bendingPreparationPrice)/quantity +
    (opPrices.machiningPricePerPiece * quantity + opPrices.machiningPreparationPrice)/quantity +
    opPrices.coatingPricePerPiece + opPrices.materialPricePerPiece
  )
}

export const calcAdjustment = (adjustment) => {
  return 1 + (adjustment || 0)
}