import React from "react";
import {Col, Row, Upload} from "antd";
import {
  PlusCircleFilled,
} from "@ant-design/icons";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

import guideline1 from "../../../assets/icons/guideline_1.svg";
import guideline2 from "../../../assets/icons/guideline_2.svg";
import guideline3 from "../../../assets/icons/guideline_3.svg";
import guideline4 from "../../../assets/icons/guideline_4.svg";
import guideline5 from "../../../assets/icons/guideline_5.svg";
import dragAndDrop from "../../../assets/icons/dragndrop.png";
import addFile from "../../../assets/icons/addfile.png";

export const OrderUploadButton = (props) => {

  const {
    allItemsCount, errorItems, smallThreshold, screens,
    openFileSizeNotification, triggerUpload, onOpen
  } = props

  return (
    <div>
        <div style={{marginBottom: 8}}>
          <div className={"guidelinesCard"}>


            <div >
              <div style={{color: "#0b89ff", fontSize: 13, display: "flex", marginTop: 5, width: "100%", justifyContent: "center"}}>
                <div style={{marginRight: 15}}>
                  <span style={{fontWeight: 500}}>Automatic file types:</span> STEP, STP & DXF
                </div>
                <div style={{marginRight: 15}}>
                  <span style={{fontWeight: 500}}>File size: </span>{"< 100 MB"}
                </div>
                <div>
                  <span style={{fontWeight: 500}}>Files per drag & drop: </span>{" < 100"}
                </div>
              </div>
            </div>
          </div>
          {/*<div style={{marginTop: 8}}>*/}
          {/*  <a href={`${window.location.origin}/step-tips`}*/}
          {/*     onClick={(e)=> {*/}
          {/*       e.preventDefault()*/}
          {/*       window.open(`${window.location.origin}/step-tips`, "_blank")*/}
          {/*     }}*/}
          {/*     style={{fontWeight: 500, fontSize: 14}}*/}
          {/*  >*/}
          {/*    <OpenInNewOutlinedIcon style={{fontSize: 16, marginBottom: -3}}/> Read more about Tips for Proper Part Design*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>


      <Upload
        showUploadList={false}
        multiple={true}
        onClick={onOpen}
        beforeUpload={(file, fileList) => {
          if(fileList.length > 100) {
            let num = fileList.findIndex(item => item.uid === file.uid)
            if(num > 99) {
              return false
            }
          }
          const is2Large = file.size / 1024 / 1024 < 100;
          if (!is2Large) {
            openFileSizeNotification()
          }
          else {
            triggerUpload(file)
          }
          return false;
        }}
        className={allItemsCount === 0 && !errorItems?.length ? "upload-button-big" : "upload-button" }
      >
        <div className={"drop-button"} style={{marginBottom: 10}}>
          <div
            className={"upload-button-side upload-button-side-left"}
          >
            {!(allItemsCount === 0 && !errorItems?.length) && <PlusCircleFilled style={{color: "#1890ff", fontSize: 32, marginRight: 20}}/>}
            <div style={{display: 'flex'}}>
              <div align={allItemsCount === 0 && !errorItems?.length ? "center" : "left"} style={{display: 'block'}}>
                <div className={"text14-600"}>
                  Drop or add files from your computer
                </div>
                <div className={"text12-500"} style={{paddingTop: 4}}>
                  Supported automatic file formats: .dxf .stp .step
                </div>
              </div>
            </div>
            {(allItemsCount === 0 && !errorItems?.length) && <PlusCircleFilled style={{color: "#1890ff", fontSize: 32, marginRight: 22}}/>}
          </div>
        </div>
      </Upload>
    </div>
  )
}