import React from "react";
import {Button, Card, Col, Row, Spin} from "antd";
import {S3_BUCKET_URL} from "../../../../constants";
import twoD from "../../../../assets/icons/2D.svg";
import treeD from "../../../../assets/icons/3D.svg";
import {SearchOutlined} from "@ant-design/icons";
import {calcAdjustment, formatDate, getPlaceholderImage} from "../../../../utils/utility";
import {CircularProgress} from "@mui/material";
import {Trans} from "react-i18next";

export const OrderConfirmationStage = (props) => {

  const {
    largeThreshold, user, order, needsManualQuote,
    vatValue, totalPrice, parts, showOtherItem,
    showDetailsItem, isChangingDraftStage
  } = props

  return (
    <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
      <Card
        style={{
          borderRadius: 5,
          border: '1px solid #c0c0c0',
          width: "100%",
          height: "100%",
          marginRight: largeThreshold ? 10 : 20
        }}
        bodyStyle={{padding: "10px 20px 20px 20px", width: "100%", height: "100%"}}
      >
        <div
          style={{
            fontSize: 20,
            fontWeight: 600,
            color: "#333f48",
            marginBottom: 5
          }}
        >
          Summary:
        </div>
        <div
          style={{
            background: "#f0f0f0",
            width: "100%",
            borderRadius: 5,
            border: "1px solid rgb(205, 205, 205)",
            boxShadow: "rgba(0, 0, 0, 0.07) 0 2px 4px 1px",
            marginBottom: 15
          }}
        >
          <Row>
            <Col xxl={16} xl={16} lg={16} span={24}>
              <Spin
                spinning={isChangingDraftStage}
                style={{maxHeight: "100%"}}
                indicator={
                  <CircularProgress
                    sx={{color: "#1890ff"}}
                    size={42}
                    thickness={3}
                    style={{marginLeft: -21, marginTop: -21}}
                  />
                }
              >
                <Card
                  style={{
                    borderRadius: 5,
                    height: "100%"
                  }}
                  bodyStyle={{
                    height: "100%",
                    padding: "10px 20px 10px 20px"
                  }}
                >
                  <Row>
                    <Col xxl={13} xl={13} lg={13} span={24}>
                      <div style={{fontSize: 13}}>Date of order:</div>
                      <div className={"dataValue"}>{formatDate(new Date)}</div>
                      <div style={{fontSize: 13}}>Email:</div>
                      <div className={"dataValue"}>{user.email}</div>
                      <div style={{fontSize: 13}}>Customer:</div>
                      <div className={"dataValue"}>{user.name}</div>
                      <div style={{fontSize: 13}}>Phone:</div>
                      <div className={"dataValue"}>{order.shippingData.phone}</div>
                    </Col>
                    <Col xxl={11} xl={11} lg={11} span={24}>
                      <div style={{fontSize: 13}}>Address:</div>
                      <div className={"dataValue"}>{order.shippingData.address}</div>
                      <div style={{fontSize: 13}}>City:</div>
                      <div className={"dataValue"}>{order.shippingData.city}</div>
                      <div style={{fontSize: 13}}>Country:</div>
                      <div className={"dataValue"}>{order.shippingData.country}</div>
                      <div style={{fontSize: 13}}>Postal code:</div>
                      <div className={"dataValue"}>{order.shippingData.postalCode}</div>
                    </Col>
                  </Row>
                </Card>
              </Spin>
            </Col>
            <Col xxl={8} xl={8} lg={8} span={24}>
              <Row style={{height: "100%"}}>
                <Col xxl={24} xl={24} lg={24} md={8} span={24}>
                  <Card
                    style={{borderRadius: 5, height: "100%"}}
                    bodyStyle={{height: "100%", padding: "10px 15px 10px 15px"}}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%"
                      }}
                    >
                      <div style={{display: "block"}}>
                        <div style={{fontSize: 13}}>Delivery Date:</div>
                        <div className={"dataValue2"}>{formatDate(order.deliveryDate)}</div>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xxl={24} xl={24} lg={24} md={8} span={24}>
                  <Card
                    style={{borderRadius: 5, height: "100%"}}
                    bodyStyle={{height: "100%", padding: "10px 15px 10px 15px"}}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%"
                      }}
                    >
                      <div style={{display: "block"}}>
                        <div style={{fontSize: 13}}>Total Price:</div>
                        <div
                          className={"dataValue2"}
                        >
                          {((totalPrice || 0) * calcAdjustment(order.adjustment)).toFixed(2)} € {needsManualQuote && "+ RFQ"}
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xxl={24} xl={24} lg={24} md={8} span={24}>
                  <Card
                    style={{borderRadius: 5, height: "100%"}}
                    bodyStyle={{height: "100%", padding: "10px 15px 10px 15px"}}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%"
                      }}
                    >
                      <div style={{display: "block"}}>
                        <div style={{fontSize: 13}}>Total with VAT:</div>
                        <div
                          className={"dataValue2"}
                        >
                          {
                            ((
                              totalPrice * (1 + vatValue) ||
                              totalPrice ||
                              0
                            ) * calcAdjustment(order.adjustment)).toFixed(2)} € {needsManualQuote && "+ RFQ"}
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className={"quotedItemsList"}
          style={{
            height: "100%",
            maxHeight: 550,
            padding: "0 6px 0 6px"
          }}
        >
          {parts.map(part => (
            <Card
              className={"bendCard"}
              style={{height: 80, marginTop: 12, marginBottom: 12}}
              bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
              key={part.id}
            >
              <div style={{display: "flex", width: "100%"}}>
                <div
                  style={{
                    border: "1px solid rgb(205, 205, 205)",
                    borderRadius: 3,
                    marginRight: 8,
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  {part.fileType === "dxf" &&
                    <div>
                      {part.dxfData.pngFilePath ?
                        <div
                          style={{
                            width: 55,
                            height: 55,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <div>
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                maxWidth: 62,
                                maxHeight: 55,
                                padding: 3,
                              }}
                              src={S3_BUCKET_URL + part.dxfData.pngFilePath}
                              alt={"part"}
                            />
                          </div>
                        </div>
                        :
                        <div
                          style={{
                            width: 55,
                            height: 55,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <img
                            style={{
                              width: 45,
                              height: 45,
                              padding: 3,
                              marginTop: 5,
                              marginLeft: 4
                            }}
                            src={twoD}
                            alt={"part"}
                          />
                        </div>
                      }
                    </div>
                  }
                  {part.fileType === "step" &&
                    <div>
                      {part.stepData.filePaths.pngFilePath ?
                        <img style={{width: 55, height: 55, padding: 3}} src={S3_BUCKET_URL + part.stepData.filePaths.pngFilePath} alt={"part"}/>
                        :
                        <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                          <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={treeD} alt={"part"}/>
                        </div>
                      }
                    </div>
                  }
                  {part.fileType === "other" &&
                    <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                      <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={getPlaceholderImage(part.partType)} alt={"part"}/>
                    </div>
                  }
                </div>
                <div style={{width: "100%"}}>
                  <div>
                    <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                      <div
                        style={{
                          display: "inline-block",
                          fontSize: 14,
                          fontWeight: 600,
                          marginRight: 8,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: 280,
                          whiteSpace: "nowrap"
                        }}
                      >
                        {part.fileName}
                      </div>
                      <div>
                        <Button
                          size={"small"}
                          type={"primary"}
                          style={{marginLeft: 20}}
                          onClick={()=>{
                            if(part.fileType === "other") {
                              showOtherItem(part)
                            } else {
                              showDetailsItem(part)
                            }
                          }}
                        >
                          <SearchOutlined style={{fontSize: 13}}/>
                        </Button>
                      </div>
                    </div>
                    <div>
                      {part.partType === "sheetMetal" ?
                        <div
                          style={{fontSize: 12, fontWeight: 500}}
                        >
                          Material: <span style={{fontWeight: 600}}><Trans>{part.selectedMaterial.grade}</Trans> ({part.selectedMaterial.thickness}mm)</span>
                        </div>
                        :
                        (part.partType === "machined" || part.partType === "profile" ?
                            <div
                              style={{fontSize: 12, fontWeight: 500}}
                            >
                              Material: <span style={{fontWeight: 600}}><Trans>{part.selectedMaterial.grade}</Trans></span>
                            </div>
                            :
                            <div style={{height: 18}}/>
                        )
                      }
                      <div style={{display:"flex", justifyContent: "space-between"}}>
                        <div
                          style={{fontSize: 12, fontWeight: 500, marginRight: 8}}
                        >
                          Quantity: <span style={{fontWeight: 600}}>{part.quantity}</span>
                        </div>
                        {part.processingType === "automatic" &&
                          <div
                            style={{fontSize: 12, fontWeight: 500}}
                          >
                            1pc: <span style={{fontWeight: 600}}>{((part.pricePerPiece || 0) * calcAdjustment(order.adjustment)).toFixed(2)} €</span>
                          </div>
                        }
                        {part.processingType === "manual" &&
                          <div
                            style={{fontSize: 12, fontWeight: 500}}
                          >
                            1pc: <span style={{fontWeight: 600}}>RFQ</span>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </Card>
    </div>
  )
}