import {
  ORDER_SET_PART_COLOR,
  ORDER_REMOVE_PART_COLOR,
  ORDER_SET_PART_QUANTITY,
  ORDER_SET_PART_NOTE,
  ORDER_SET_PART_MATERIAL,
  ORDER_SET_PART_THICKNESS,
  ORDER_RESET_TO_PROCESS,
  ORDER_REMOVE_ITEM,
  ORDER_REMOVE_MULTIPLE_ITEMS,
  ORDER_APPLY_MULTIPLE_ITEMS,
  ORDER_SET_MANUAL_PART_TYPE,
  ORDER_ADD_NEW_PART,
  ORDER_UPDATE_PART,
  ORDER_INCREMENT_TO_PROCESS,
  ORDER_INCREMENT_UPLOADED,
  ORDER_INCREMENT_PROCESSED,
  ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
  ORDER_SET_DRAFT_STAGE,
  ORDER_SET_SHIPPING_DATA,
  ORDER_UPDATE_PART_DATA,
  ORDER_UPDATE_PART_FILE_PATH,
  ORDER_CHANGE_PART_TO_ERROR,
  ORDER_UPDATE_ORDER_PRICE,
  ORDER_REMOVE_ERROR_PARTS,
  ORDER_SET_DELIVERY_DATE,
  ORDERS_SET_IS_CHANGING_DELIVERY_DATE,
  ORDER_UPDATE_PART_PRICE,
  ORDERS_SET_IS_CHANGING_DRAFT_NAME,
  ORDER_SET_DRAFT_NAME,
  ORDER_SET_PART_DRAWING,
  ORDER_UPDATE_MANY_PART_PRICES,
  ORDER_SET_CHANGED_ADJUSTMENT
} from "./actionTypes";
import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import history from "../utils/history";
import {forceLogoutUser} from "./authActions";

const changeItemColor = payload => dispatch => {
  dispatch({
    type: ORDER_SET_PART_COLOR,
    payload,
  })
}

const removeItemColor = payload => dispatch => {
  dispatch({
    type: ORDER_REMOVE_PART_COLOR,
    payload,
  })
}

const changeItemQuantity = payload => dispatch => {
  if (payload.id && !isNaN(parseInt(payload.quantity))) {
    dispatch({
      type: ORDER_SET_PART_QUANTITY,
      payload,
    })
  }
}

const changeItemNote = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: ORDER_SET_PART_NOTE,
      payload,
    })
  }
}

const changeItemMaterial = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: ORDER_SET_PART_MATERIAL,
      payload,
    })
  }
}

const changeItemThickness = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: ORDER_SET_PART_THICKNESS,
      payload,
    })
  }
}

const resetToProcess = payload => dispatch => {
  dispatch({
    type: ORDER_RESET_TO_PROCESS,
    payload,
  })
}

const removeItem = payload => dispatch => {
  dispatch({
    type: ORDER_REMOVE_ITEM,
    payload,
  })
}

const removeMultipleItems = payload => dispatch => {
  dispatch({
    type: ORDER_REMOVE_MULTIPLE_ITEMS,
    payload,
  })
}

const applyMultipleItems = payload => dispatch => {
  dispatch({
    type: ORDER_APPLY_MULTIPLE_ITEMS,
    payload,
  })
}

const changeManualItemType = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: ORDER_SET_MANUAL_PART_TYPE,
      payload,
    })
  }
}

const uploadNewPart = payload => dispatch => {
  const formData = new FormData()
  formData.set('data', JSON.stringify({fileId: payload.data.fileId, uploadTime: payload.data.uploadTime, uploadIndex: payload.data.uploadIndex}))
  formData.set('file', payload.file)

  return http.formPost(`${API_BASE_URL}/order-parts/createOnOrder/${payload.data.orderId}`, formData).then(
    response => {
      for (let d of response.data) {
        dispatch({
          type: ORDER_ADD_NEW_PART,
          payload: {
            ...payload.data,
            fileId: d.fileId,
            fileName: d.fileName,
            uploadIndex: d.uploadIndex,
            state: "uploading"
          },
        })

        dispatch({
          type: ORDER_INCREMENT_TO_PROCESS,
          payload: null
        })

        if (d.state !== "error") {
          dispatch({
            type: ORDER_UPDATE_PART,
            payload: {...d}
          })
          payload.callback({orderId: d.orderId, orderPartId: d.id})
        } else {
          dispatch({
            type: ORDER_CHANGE_PART_TO_ERROR,
            payload: {...d}
          })
        }

        dispatch({
          type: ORDER_INCREMENT_UPLOADED,
          payload: null
        })
      }
    }
  ).catch((error) => {
    if(error?.response?.status === 401) {
      dispatch(forceLogoutUser())
    }
  })
}

const changeStageToShipping = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
    payload: true
  })

  dispatch({
    type: ORDER_SET_DRAFT_STAGE,
    payload: "shipping"
  })

  return http
    .post(`${API_BASE_URL}/order/draft/${payload.orderId}/stage`, {stage: "shipping"})
    .then(response => {
      dispatch({
        type: ORDER_SET_SHIPPING_DATA,
        payload: response.data
      })
      dispatch({
        type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const changeStageToConfirmation = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
    payload: true
  })

  dispatch({
    type: ORDER_SET_DRAFT_STAGE,
    payload: "confirmation"
  })

  return http
    .post(`${API_BASE_URL}/order/draft/${payload.orderId}/stage`, {stage: "confirmation", shippingData: payload.shippingData})
    .then(() => {
      dispatch({
        type: ORDER_SET_SHIPPING_DATA,
        payload: payload.shippingData
      })
      dispatch({
        type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const changeToPreviousStage = payload => dispatch => {
  dispatch({
    type: ORDER_SET_DRAFT_STAGE,
    payload: payload.stage
  })

  return http
    .post(`${API_BASE_URL}/order/draft/${payload.orderId}/stage`, {stage: payload.stage})
    .then(() => {

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const updatePartData = payload => dispatch => {
  dispatch({
    type: ORDER_UPDATE_PART_DATA,
    payload: payload
  })
  dispatch({
    type: ORDER_INCREMENT_PROCESSED,
    payload: null
  })
}

const changePartToError = payload => dispatch => {
  dispatch({
    type: ORDER_CHANGE_PART_TO_ERROR,
    payload: payload
  })
  dispatch({
    type: ORDER_INCREMENT_PROCESSED,
    payload: null
  })
}

const updatePartFilePath = payload => dispatch => {
  dispatch({
    type: ORDER_UPDATE_PART_FILE_PATH,
    payload: payload
  })
}

const changeOrderPrice = payload => dispatch => {
  dispatch({
    type: ORDER_UPDATE_ORDER_PRICE,
    payload: payload
  })
}

const removeErrorParts = payload => dispatch => {
  dispatch({
    type: ORDER_REMOVE_ERROR_PARTS,
    payload: null
  })

  return http
    .deleteData(`${API_BASE_URL}/order-parts/deletePartsWithErrorOnOrder/${payload.orderId}`, {partIds: payload.partIds})
    .then(() => {
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }

    })
}

const changeDeliveryDate = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_CHANGING_DELIVERY_DATE,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/order/draft/${payload.orderId}/deliveryDate`, {deliveryDate: payload.deliveryDate})
    .then(() => {

      dispatch({
        type: ORDER_SET_DELIVERY_DATE,
        payload: payload.dateTimestamp,
      })

      dispatch({
        type: ORDERS_SET_IS_CHANGING_DELIVERY_DATE,
        payload: false,
      })

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: ORDERS_SET_IS_CHANGING_DELIVERY_DATE,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }

    })
}

const placeOrder = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/order/draft/${payload.orderId}/finalize`)
    .then(() => {
      dispatch({
        type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
        payload: false
      })
      message.show(message.type.success, "Order has been placed")
      history.push(`/`)
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else if (error?.response?.status === 437) {
        dispatch({
          type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
          payload: false
        })
        throw error
      } else {
        dispatch({
          type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const changePartPrice = payload => dispatch => {
  dispatch({
    type: ORDER_UPDATE_PART_PRICE,
    payload: payload
  })
}

const changeManyPartPrices = payload => dispatch => {
  dispatch({
    type: ORDER_UPDATE_MANY_PART_PRICES,
    payload: payload
  })
}

const changeDraftOrderName = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_CHANGING_DRAFT_NAME,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/order/draft/${payload.orderId}/custom-name`, {customName: payload.name})
    .then(() => {
      dispatch({
        type: ORDERS_SET_IS_CHANGING_DRAFT_NAME,
        payload: false
      })
      dispatch({
        type: ORDER_SET_DRAFT_NAME,
        payload: payload.name
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: ORDERS_SET_IS_CHANGING_DRAFT_NAME,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const uploadPartDrawing = payload => dispatch => {
  const formData = new FormData()
  formData.set('file', payload.file)

  return http
    .formPost(`${API_BASE_URL}/order-parts/${payload.orderPartId}/addPartDrawingOnOrder/${payload.orderId}`, formData)
    .then(() => {
      dispatch({
        type: ORDER_SET_PART_DRAWING,
        payload: {
          orderPartId: payload.orderPartId,
          data: {
            drawingPDFFileName: payload.file.name,
            drawingPDFFilePath: ""
          }
        }
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })

}

const removePartDrawing = payload => dispatch => {
  dispatch({
    type: ORDER_SET_PART_DRAWING,
    payload: {
      orderPartId: payload.orderPartId,
      data: {
        drawingPDFFileName: "",
        drawingPDFFilePath: ""
      }
    }
  })
  return http
    .delete(`${API_BASE_URL}/order-parts/${payload.orderPartId}/removePartDrawingOnOrder/${payload.orderId}`)
    .then(() => {
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })

}

const confirmAdjustmentChange = payload => dispatch => {
  return http
    .get(`${API_BASE_URL}/order/draft/${payload.orderId}/adjustment-seen`)
    .then(() => {
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const changeAdjustments = payload => dispatch => {
  dispatch({
    type: ORDER_SET_CHANGED_ADJUSTMENT,
    payload: payload
  })
}

export {
  changeItemColor, removeItemColor, changeItemQuantity, changeItemNote,
  changeItemMaterial, changeItemThickness, resetToProcess,
  removeItem, removeMultipleItems, applyMultipleItems,
  changeManualItemType, uploadNewPart, changeStageToShipping,
  changeToPreviousStage, updatePartData, updatePartFilePath,
  changePartToError, changeOrderPrice, removeErrorParts,
  changeDeliveryDate, changeStageToConfirmation, placeOrder,
  changePartPrice, changeDraftOrderName, uploadPartDrawing,
  removePartDrawing, changeManyPartPrices, confirmAdjustmentChange,
  changeAdjustments
}
