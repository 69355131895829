import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'

import rootReducer from '../reducers/rootReducer'

const configureStore = (history) => {
  const middleware = routerMiddleware(history)
  return createStore(
    rootReducer,
    applyMiddleware(thunk, middleware)
  )
}

export { configureStore }
