import {
  CUSTOMER_RELATIONS_SET_INTERNAL_RELATIONS,
  CUSTOMER_RELATIONS_INTERNAL_PAGINATION,
  CUSTOMER_RELATIONS_SET_FETCHING_INTERNAL_RELATIONS,
  CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER,

  CUSTOMER_RELATIONS_SET_EXTERNAL_RELATIONS,
  CUSTOMER_RELATIONS_EXTERNAL_PAGINATION,
  CUSTOMER_RELATIONS_SET_FETCHING_EXTERNAL_RELATIONS,
  CUSTOMER_RELATIONS_SET_UPDATING_REGULAR_CUSTOMER,
  CUSTOMER_RELATIONS_SET_UPDATING_CORPORATE_CUSTOMER, CUSTOMER_RELATIONS_SET_IS_UPDATING_EXTERNAL_CUSTOMER,


} from '../actions/actionTypes'
import initialState from './initialState'

const customerRelationsReducer = (state = initialState.customerRelations, action) => {
  switch (action.type) {
    case CUSTOMER_RELATIONS_SET_INTERNAL_RELATIONS:
      return {
        ...state,
        internalCustomerRelations: action.payload,
      }

    case CUSTOMER_RELATIONS_INTERNAL_PAGINATION:
      return {
        ...state,
        internalCustomerRelationsPagination: action.payload,
      }

    case CUSTOMER_RELATIONS_SET_FETCHING_INTERNAL_RELATIONS:
      return {
        ...state,
        fetchingInternalCustomerRelations: action.payload,
      }

    case CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER:
      return {
        ...state,
        updatingInternalCustomer: action.payload,
      }
    //-----------------------------------------------------------------------
    case CUSTOMER_RELATIONS_SET_EXTERNAL_RELATIONS:
      return {
        ...state,
        externalCustomerRelations: action.payload,
      }

    case CUSTOMER_RELATIONS_EXTERNAL_PAGINATION:
      return {
        ...state,
        externalCustomerRelationsPagination: action.payload,
      }

    case CUSTOMER_RELATIONS_SET_FETCHING_EXTERNAL_RELATIONS:
      return {
        ...state,
        fetchingExternalCustomerRelations: action.payload,
      }

    case CUSTOMER_RELATIONS_SET_UPDATING_REGULAR_CUSTOMER:
      return {
        ...state,
        updatingRegularCustomer: action.payload
      }

    case CUSTOMER_RELATIONS_SET_UPDATING_CORPORATE_CUSTOMER:
      return {
        ...state,
        updatingCorporateCustomer: action.payload
      }
    case CUSTOMER_RELATIONS_SET_IS_UPDATING_EXTERNAL_CUSTOMER:
      return {
        ...state,
        updatingExternalCustomer: action.payload
      }
    default:
      return state
  }
}

export default customerRelationsReducer