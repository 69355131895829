import Icon from "@ant-design/icons";
import React from "react";

const WaterJet = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36px" height="36px"
        viewBox="0 0 480 480"
    >
        <g transform={"translate(-300 -300)"}>
            <path
                d="M540 756.09c-119.156 0-216.09-96.934-216.09-216.09S420.844 323.91 540 323.91 756.09 420.844 756.09 540 659.156 756.09 540 756.09zm0-422.1c-113.597 0-206.01 92.413-206.01 206.01 0 113.592 92.413 206.01 206.01 206.01 113.592 0 206.01-92.418 206.01-206.01 0-113.597-92.418-206.01-206.01-206.01z"
                fill="#428dff"
                opacity={0.98}
            />
            <g>
                <path

                    d="M654.49 648.236L545.702 692.29a15.866 15.866 0 01-11.74 0c-92.022-37.02-2.328-2.697-108.787-44.054a2.591 2.591 0 010-4.866c124.388-47.65 109.368-43.049 114.657-45.164l114.656 45.164a2.591 2.591 0 010 4.866z"
                    fill="#cad9fc"
                />
                <path
                    d="M633.357 642.936c-116.878 45.165-109.369 44.318-110.85 44.689-37.496-15.179-26.866-12.005-97.31-39.4a2.591 2.591 0 010-4.866l111.06-43.63 97.364 38.341a2.591 2.591 0 01-.264 4.866z"
                    fill="#e8edfc"
                />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M481.677 434.185h116.349v31.731h-116.35z"
                    fill="#a4c2f7"
                />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M598.026 465.929l-31.732 52.886h-52.886l-31.731-52.886z"
                    fill="#cad9fc"
                />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M582.163 465.929l-31.732 52.886h-37.02l-31.731-52.886z"
                    fill="#e8edfc"
                />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M566.307 518.836v19.674a5.659 5.659 0 01-.74 2.75l-13.593 22.582a5.289 5.289 0 01-4.548 2.592h-15.495a4.971 4.971 0 01-4.178-2.539l-13.592-22.582a5.659 5.659 0 01-.74-2.75v-19.727z"
                    fill="#cad9fc"
                />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M550.438 518.812v19.673a5.659 5.659 0 01-.74 2.75l-13.592 22.582a4.707 4.707 0 01-8.356 0l-13.592-22.582a5.659 5.659 0 01-.74-2.75v-19.673z"
                    fill="#e8edfc"
                />
                <rect
                    vectorEffect="non-scaling-stroke"
                    x={-16}
                    y={-4.5}
                    rx={1}
                    ry={1}
                    width={32}
                    height={9}
                    fill="#cad9fc"
                    transform="matrix(5.29 0 0 5.29 539.845 410.364)"
                />
                <rect
                    vectorEffect="non-scaling-stroke"
                    x={-14.5}
                    y={-4.5}
                    rx={1}
                    ry={1}
                    width={29}
                    height={9}
                    fill="#e8edfc"
                    transform="matrix(5.29 0 0 5.29 531.909 410.364)"
                />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M514.147 541.223l13.592 22.582a5.289 5.289 0 004.548 2.592h15.496a5.289 5.289 0 01-4.178-2.539l-13.592-22.582c-.74-1.851-.74-.74-.74-22.477l-31.732-52.886v-31.731h-15.866v31.731l31.732 52.886c0 21.736 0 20.626.74 22.424zM425.171 648.236l108.787 44.054a15.866 15.866 0 0011.74 0l2.063-.846-106.724-43.208a2.591 2.591 0 010-4.866l106.724-42.044-7.933-3.12L425.17 643.37a2.591 2.591 0 000 4.866zM476.382 386.565h-15.866a5.289 5.289 0 00-5.289 5.29v37.02a5.289 5.289 0 005.289 5.288h15.866a5.289 5.289 0 01-5.289-5.289v-37.02a5.289 5.289 0 015.289-5.289z"
                    fill="#fff"
                />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M498.459 608.719l39.382-15.475 3.869 9.845-39.382 15.475z"
                    fill="#fff"
                />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M619.174 381.28H460.516c-5.842 0-10.578 4.736-10.578 10.577v37.02c0 5.842 4.736 10.578 10.578 10.578h15.865v26.443c.034.944.288 1.867.74 2.697l30.992 51.67v18.192a10.577 10.577 0 001.534 5.289l13.539 22.635a10.577 10.577 0 009.096 5.289h2.274v70.655l-51.247 23.64-50.03-20.255 69.122-27.236a5.289 5.289 0 003.015-6.875 5.289 5.289 0 00-6.875-2.962l-75.046 29.722a7.88 7.88 0 000 14.702l108.84 44.054a21.154 21.154 0 007.51 1.481 20.255 20.255 0 007.827-1.534l108.786-44a7.774 7.774 0 005.024-7.352 7.933 7.933 0 00-5.288-7.404l-111.06-43.79V571.67h2.273a10.577 10.577 0 009.097-5.289l13.539-22.635a10.577 10.577 0 001.533-5.289v-18.193l30.991-51.67a6.61 6.61 0 00.74-2.696v-26.443h15.867c5.841 0 10.577-4.736 10.577-10.578v-37.02c0-5.841-4.736-10.577-10.577-10.577zm27.236 264.43l-102.652 41.568a10.577 10.577 0 01-7.774 0l-39.241-15.865 45.323-21.155a5.289 5.289 0 003.067-4.548v-39.823zm-85.411-107.253l-13.592 22.635h-15.125l-13.592-22.635v-14.385H561zm31.732-77.848h-58.175a5.289 5.289 0 000 10.577h54.102l-25.385 42.309h-46.857l-25.385-42.309h17.082a5.289 5.289 0 000-10.577h-21.154v-21.154H592.73zm5.288-31.732H460.516v-37.02h10.577v10.577a5.289 5.289 0 0010.577 0v-10.577h10.577v10.577a5.289 5.289 0 0010.577 0v-10.577h10.578v10.577a5.289 5.289 0 0010.577 0v-10.577h10.577v10.577a5.289 5.289 0 0010.577 0v-10.577h10.577v10.577a5.289 5.289 0 0010.578 0v-10.577h10.577v10.577a5.289 5.289 0 0010.577 0v-10.577h10.577v10.577a5.289 5.289 0 0010.577 0v-10.577h10.578v37.02z"
                    fill="#428dff"
                />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M525.585 605.918a5.289 5.289 0 10-10.577 0 5.289 5.289 0 0010.577 0zM615.142 514.919a5.293 5.293 0 10-3.332-10.048 33.794 33.794 0 00-21.154 18.14 33.847 33.847 0 001.375 27.976 21.154 21.154 0 01-13.645 31.732 5.352 5.352 0 001.64 10.577 5.923 5.923 0 001.64 0 33.794 33.794 0 0021.154-18.14 34.059 34.059 0 00-1.375-27.977 23.058 23.058 0 01-1.27-19.25 23.27 23.27 0 0114.967-13.01zM654.88 462.96a5.289 5.289 0 003.384-6.716 5.289 5.289 0 00-6.664-3.331 33.794 33.794 0 00-21.154 18.14 33.953 33.953 0 001.375 27.923 23.217 23.217 0 011.27 19.304 23.217 23.217 0 01-14.915 12.27 5.289 5.289 0 00-3.384 6.716 5.289 5.289 0 005.024 3.596 4.707 4.707 0 001.692-.265 33.794 33.794 0 0021.155-18.14 33.741 33.741 0 00-1.375-27.923 23.376 23.376 0 01-1.27-19.304 23.376 23.376 0 0114.861-12.27z"
                    fill="#428dff"
                />
            </g>
        </g>
    </svg>
);

export const WaterJetIcon = props => <Icon component={WaterJet} {...props} />;