import axios from 'axios'

import storage from '../utils/storage'

const getDefaultRequestOptions = () => {
  const authData = storage.retrieveAuthData();
  if(authData !== null) {
    const accessToken = storage.retrieveAccessToken();
    return {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${accessToken}`,
      },
    }
  }
  else {
    return {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  }
};

const refreshToken = () => {
  const authData = storage.retrieveAuthData();
  const sessionStart = storage.retrieveSessionStartTime();
  if(authData === null || sessionStart === null){
    return Promise.resolve(1);
  }
  const accessToken = storage.retrieveAccessToken();
  const raz = (new Date() - new Date(sessionStart.timestamp))/1000

  if (accessToken && (raz < 240)) {
    return Promise.resolve(1);
  }

  return storage.refreshAccessToken();
}

const httpGet = (url, payload = null) =>
  refreshToken().then(()=> axios.get(`${url}${createQueryString(payload)}`, getDefaultRequestOptions()))

const httpPost = (url, payload) =>
  refreshToken().then(()=>{ return axios.post(url, JSON.stringify(payload), getDefaultRequestOptions())})

const httpFormPost = (url, payload) =>
  refreshToken().then(()=>{ return axios.post(url, payload, getDefaultRequestOptions())})

const httpFormPut = (url, payload) =>
  refreshToken().then(()=>{ return axios.put(url, payload, getDefaultRequestOptions())})

const httpPut = (url, payload) =>
  refreshToken().then(()=>{ return axios.put(url, JSON.stringify(payload), getDefaultRequestOptions())})

const httpDelete = (url) =>
  refreshToken().then(()=>{ return axios.delete(url, getDefaultRequestOptions())})

const httpDeleteWithData = (url, payload) =>
  refreshToken().then(()=>{ return axios.delete(url, {...getDefaultRequestOptions(), data: {...payload}})})

const createQueryString = payload => {
  if (payload === null || Object.keys(payload).length === 0) {
    return ''
  }

  const esc = encodeURIComponent

  let query = '?'

  query += Object.keys(payload)
    .map(key => esc(key) + '=' + esc(payload[key]))
    .join('&')

  return query
}

const getErrorMessage = error => {
  if(error.response?.data?.msg) {
    return error.response.data.msg
  }
  if(error?.response?.status === 401) {
    return "Unauthorized"
  }
  return "Something went wrong!"
}

export default {
  get: httpGet,
  post: httpPost,
  put: httpPut,
  delete: httpDelete,
  deleteData: httpDeleteWithData,
  formPost: httpFormPost,
  formPut: httpFormPut,
  getErrorMessage,
}
