import * as React from 'react';
import {AutoSizer, Grid} from "react-virtualized";
import {
  Button as AntButton, Row, Col,
  Card, Input, Select, Form,
  Divider, Empty
} from "antd";
import {
  ClickAwayListener, Popper,
  Fade, Button, IconButton
} from "@material-ui/core";
import {CaretDownOutlined, SearchOutlined} from "@ant-design/icons";
import CloseIcon from '@material-ui/icons/Close';
import { ral } from '../../../assets/ral';
import {DropIcon} from "../../../assets/icons/drop";
import SquareIcon from '@mui/icons-material/Square';

const { Option } = Select;

export default class CoatingPicker extends React.PureComponent {
  colorFormRef = React.createRef();

  constructor(props, context) {
    super(props, context);

    this._cellRenderer = this._cellRenderer.bind(this);
    this._noContentRenderer = this._noContentRenderer.bind(this);
    this._onScrollToRowChange = this._onScrollToRowChange.bind(this);
    this._onRowCountChange = this._onRowCountChange.bind(this);

  }

  componentDidMount() {
    this.dropdown = React.createRef();
    this.dropdownButton = React.createRef();
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('resize', this.handleResize)
  }

  state = {
    selectedRAL: undefined,
    listLength: ral.length,
    rowCount: Math.ceil((ral.length)/3),
    scrollToRow: undefined,
    list: ral,
    pickerOpen: false,
    selectedGloss: "1",
    anchorEl: null,
  };

  _cellRenderer({columnIndex, key, rowIndex, style}) {
    const {selectedRAL} = this.state;
    const datum = this._getDatum(columnIndex, rowIndex);
    return (
      <div key={key} style={style}>
        {datum &&
          <Button
            variant={selectedRAL === datum.code ? "contained" : "outlined"}
            style={{width: 126, height: 40, paddingLeft: 10, paddingRight: 10, justifyContent: "flex-start", boxShadow: 'none'}}
            startIcon={<SquareIcon style={{color: datum.color.hex, marginLeft: 5}}/>}
            onClick={()=>{
              this.props.changeColor({
                id: this.props.partId,
                selectedRAL: datum.code,
                selectedRow: Math.floor(datum.id / 3),
                selectedGloss: this.colorFormRef.current.getFieldValue("gloss"),
                ralHex: datum.color.hex
              })
              this.handleClose()
            }}
          >
            <span className={"text12-600"}>RAL {datum.code}</span>
          </Button>
        }
      </div>
    );
  }

  _getDatum(columnIndex, rowIndex) {
    const {listLength, list} = this.state;
    const elem_id = (columnIndex+1)+3*(rowIndex)
    return elem_id <= listLength ? list[elem_id-1] : null;
  }

  _noContentRenderer() {
    return <div align={'center'} style={{marginRight: 18}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>;
  }

  _onScrollToRowChange(value) {
    const {rowCount} = this.state;
    let scrollToRow = Math.min(rowCount - 1, value+5);

    if(value < 6) {
      scrollToRow = value;
    }

    if (isNaN(scrollToRow)) {
      scrollToRow = undefined;
    }

    this.setState({scrollToRow});
  }

  escapeRegExp = (text) => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  _onRowCountChange(event) {
    const value = event.target.value
    this.setState({firstType: true})
    let list;
    if(value === '' ) {
      list = ral
    }
    else {
      list = ral.filter(item => (("RAL ").concat(item.code)).toLowerCase().search(this.escapeRegExp(value.toLowerCase())) !== -1)
    }

    const rowCount = Math.ceil((list.length)/3);
    const listLength = list.length

    this.setState({rowCount, listLength, list});
  }

  handleClose = () => {
    this.setState({
      pickerOpen: false,
      scrollToRow: undefined,
      selectedGloss: "1",
      list: ral,
      listLength: ral.length,
      rowCount: Math.ceil((ral.length)/3),
    })
  }

  handleClick = (event) => {
    const {pickerOpen} =this.state;
    if(pickerOpen) {
      this.handleClose()
    } else {
      const {ralRow, colorRAL, colorGlossId} = this.props;
      this.setState({
        anchorEl: event.currentTarget,
        pickerOpen: true,
        selectedRAL: colorRAL || undefined,
        selectedGloss: colorGlossId || "1"
      })
      this._onScrollToRowChange(ralRow || 0)
    }
  };

  handleClickAway = () => {
    this.handleClose()
  }

  render() {
    const {
      rowCount,
      scrollToRow,
      pickerOpen,
      selectedGloss,
      anchorEl,
    } = this.state;

    const {
      hasCoating,
      colorRAL,
      colorGlossId,
      ralHex,
      disabled
    } = this.props;

    return (
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={this.handleClickAway}>
        <div>
          <AntButton
            className={"colorSelectButton"}
            onClick={this.handleClick}
            disabled={disabled}
          >
            {hasCoating && <SquareIcon style={{color: ralHex, fontSize: 14, position: "relative", top: 2, marginRight: 5}}/>}
            <span className={"colorSelectButtonText"} style={{color: disabled ? "#bdbdbd" : "#333f48"}}>
              {hasCoating ? "RAL "+ colorRAL + " " + (colorGlossId === "1" ? "Glossy (70±5)" : "Matte (30±5)") : "Pick a color"}
            </span>
            <CaretDownOutlined style={{position: "relative", top: 2}}/>
          </AntButton>
          <Popper
            open={pickerOpen}
            anchorEl={anchorEl}
            placement={'bottom-end'}
            style={this.props.isMultiEdit ? {zIndex: 1101} : {zIndex: 10}}
            popperOptions={{positionFixed: true}}
            transition
            modifiers={{
              preventOverflow: {
                enabled: false,
                boundariesElement: 'scrollParent',
              },
              hide: {
                enabled: false
              },
              offset: {
                enabled: true,
                offset: '0,5',
              }
            }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <div style={{width: 450}}>
                  <Card className={"colorCard"} bodyStyle={{padding: "12px 2px 10px 20px"}}>
                    <div>
                      <div style={{width: '100%'}}>
                        <div style={{display: 'flex', justifyContent: "space-between", marginRight: 18, marginBottom: -8}}>
                          <div>
                            <span className={"text14-600"}>Pick Color</span>
                          </div>
                          <div>
                            <IconButton
                              size={'small'}
                              aria-label="close"
                              onClick={()=>{
                                this.handleClose()
                              }}
                            >
                              <CloseIcon/>
                            </IconButton>
                          </div>
                        </div>
                        <Form
                          name={'colorForm'}
                          ref={this.colorFormRef}
                        >
                          <Row gutter={15} style={{marginRight: 18}}>
                            <Col span={12}>
                              <div style={{marginTop: 22}}>
                                <Input
                                  prefix={<SearchOutlined />}
                                  className={"searchInput"}
                                  placeholder={"Find RAL.."}
                                  onChange={this._onRowCountChange}
                                />
                              </div>
                            </Col>
                            <Col span={12}>
                              <span className={"text12-400"}>Gloss level</span>
                              <Form.Item
                                name={"gloss"}
                                initialValue={selectedGloss}
                              >
                                <Select
                                  suffixIcon={<CaretDownOutlined />}
                                  getPopupContainer={trigger => trigger.parentNode}
                                >
                                  <Option
                                    key={"1"}
                                    value={"1"}>
                                    <span className={"text12-600"}>Glossy (70±5) (default)</span>
                                  </Option>
                                  <Option
                                    key={"2"}
                                    value={"2"}>
                                    <span className={"text12-600"}>Matte (30±5)</span>
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </div>
                    <Divider className={'colorDivider'}/>
                    <AutoSizer disableHeight>
                      {({width}) => (
                        <Grid
                          cellRenderer={this._cellRenderer}
                          columnWidth={(width-15)/3}
                          columnCount={3}
                          height={300}
                          noContentRenderer={this._noContentRenderer}
                          overscanRowCount={3}
                          rowHeight={50}
                          rowCount={rowCount}
                          scrollToRow={scrollToRow}
                          width={width}
                        />
                      )}
                    </AutoSizer>
                  </Card>
                </div>
              </Fade>
            )}
          </Popper>
        </div>
      </ClickAwayListener>
    );
  }
}