import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Card, Button, Form, Input } from 'antd'

import logo from '../../assets/images/logo.svg'
import { CardTitle, Logo } from '../LoginPage/styles'


import {http, message} from "../../utils";
import {API_BASE_URL} from "../../constants";
import { LockOutlined } from "@ant-design/icons";
import hLogo from "../../assets/logo/H.svg";
import HefeosLogo from "../../assets/Hefeos-IQT-Dark.svg";

class ResetPassword extends Component {

  state = {
    token: new URLSearchParams(this.props.location.search).get('token'),
    isResettingPassword: false,
  }

  componentDidMount() {
    const token = new URLSearchParams(this.props.location.search).get('token')
    if (token === null) {
      this.props.history.push('/login')
    }
    else {
      this.setState({
        token: token,
      })
    }
  }

  handleClick = (values) => {

    this.setState({isResettingPassword: true})
    http
      .post(`${API_BASE_URL}/auth/forgot-password`, {newPassword: values.repeatPassword, token: this.state.token})
      .then(() => {
        this.setState({isResettingPassword: false})
        message.show(message.type.success, 'Password changed successfully!')
        this.props.history.push('/login')
      })
      .catch(error => {
        this.setState({isResettingPassword: false})
        message.show(message.type.error, http.getErrorMessage(error))
      })
  }


  render() {
    return (
      <div className={"publicWrapper"}>
        <div className={"tiledBackground"}>
          <Card className={"publicCard"}>
            <div style={{ textAlign: 'center', marginTop: 20, marginBottom: "6em" }}>
              <img src={HefeosLogo} alt={"Hefeos Logo"} width={300}/>
            </div>
            <CardTitle className={"signInText"}>Reset Password</CardTitle>
            <div className={"loginLabel"} style={{ marginBottom: 18}}>
              Enter a new password for your account.
            </div>
            <Form
              name={'password-reset'}
              onFinish={this.handleClick}
            >
              <span className={"loginLabel"}>New password</span>
              <Form.Item
                name={'password'}
                rules={[
                  {required: true, message: 'This field is required'},
                  {type: 'string', min: 6 ,message: 'Password must be 6 characters or longer'},
                  {type: 'string', max: 60 ,message: 'Password must be 60 characters or shorter'},
                ]}
                validateTrigger={'onSubmit'}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter your new password"
                />
              </Form.Item>
              <span className={"loginLabel"}>Repeat new password</span>
              <Form.Item
                name={'repeatPassword'}
                rules={[
                  {required: true, message: 'This field is required'},
                  {type: 'string', min: 6 ,message: 'Password must be 6 characters or longer'},
                  {type: 'string', max: 60 ,message: 'Password must be 60 characters or shorter'},
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The passwords that you entered do not match!');
                    },
                  }),
                ]}
                validateTrigger={'onSubmit'}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter your new password again"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: '100%', marginTop: 15}}
                  loading={this.state.isResettingPassword}
                  type="primary"
                  htmlType="submit"
                  className={"loginText darkButton"}
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
)
