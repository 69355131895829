import {AUTH_SET_USER} from "../actions/actionTypes";

let store = null

const user = () => {
  return store ? store.getState().auth.user : null
}

const getAuth = () => {
  return store ? store.getState().auth : null
};

const resetUser = () => {
  store.dispatch({
    type: AUTH_SET_USER,
    payload: null,
  })
};

const setStore = newStore => {
  store = newStore
}

export default {
  user,
  setStore,
  resetUser,
  getAuth
}
