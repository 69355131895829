import React, {useState} from "react";
import {Button, Card, Divider, Modal, Tooltip} from "antd";
import {
  FileSearchOutlined
} from "@ant-design/icons";
import {formatDate, getPlaceholderImage} from "../../../utils/utility";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import {S3_BUCKET_URL} from "../../../constants";
import twoD from "../../../assets/icons/2D.svg";
import treeD from "../../../assets/icons/3D.svg";
import {Trans} from "react-i18next";

export const OrderChanges = (props) => {

  const {order} = props

  const [changesVisible, setChangesVisible] = useState(false)

  const getEditType = (type) => {
    switch (type) {
      case "delete":
        return "Rejected"
      case "newQuote":
        return "Quote"
      case "priceChange":
        return "Edited"
      default:
        return ""
    }
  }

  return (
    <div>
      <Button
        type="primary"
        style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
        onClick={()=>setChangesVisible(true)}
      >
        <FileSearchOutlined style={{fontSize: 14}}/>
        View Changes
      </Button>

      <Modal
        visible={changesVisible}
        centered={true}
        onCancel={()=> setChangesVisible(false)}
        title="Order Changes"
        footer={null}
        destroyOnClose={true}
        width={950}
      >
        {order.deliveryDate !== order?.["archive"].deliveryDate ?
          <div>
            <div style={{fontWeight: 400, fontSize: 13}}>Delivery Date:</div>
            <div style={{display: "flex"}}>
              <div style={{fontWeight: 600, fontSize: 14, color: "#b60a1c"}}>
                {formatDate(order["archive"].deliveryDate)}
              </div>
              <div align={"center"} style={{display: "flex"}}>
                <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
              </div>
              <div style={{fontWeight: 600, fontSize: 14, color: "#047a04"}}>
                {formatDate(order.deliveryDate)}
              </div>
            </div>
            <Divider style={{marginTop: 10, marginBottom: 10}}/>
          </div>
          :
          null
        }
        {order?.["archive"].edits.length !== 0 ?
          <div>
            <div style={{fontWeight: 400, fontSize: 13}}>Total Price:</div>
            <div style={{display: "flex"}}>
              <div style={{fontWeight: 600, fontSize: 14, color: "#b60a1c"}}>
                € {(order["archive"].price|| 0).toFixed(2)}
              </div>
              <div align={"center"} style={{display: "flex"}}>
                <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
              </div>
              <div style={{fontWeight: 600, fontSize: 14, color: "#047a04"}}>
                € {(order.totalPrice || 0).toFixed(2)}
              </div>
            </div>
            <div style={{fontWeight: 400, fontSize: 13}}>Total with VAT:</div>
            <div style={{display: "flex"}}>
              <div style={{fontWeight: 600, fontSize: 14, color: "#b60a1c"}}>
                € {(order["archive"].price * (1 + order.vatValue) || order["archive"].price || 0).toFixed(2)}
              </div>
              <div align={"center"} style={{display: "flex"}}>
                <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
              </div>
              <div style={{fontWeight: 600, fontSize: 14, color: "#047a04"}}>
                € {(order.totalPrice * (1 + order.vatValue) || order.totalPrice || 0).toFixed(2)}
              </div>
            </div>
            <Divider style={{marginTop: 10, marginBottom: 20}}/>
            <div
              className={"quotedItemsList"}
              style={{
                maxHeight: 410,
                padding: "0 6px 0 6px",
                overflowX: "hidden"
              }}
            >
              {order["archive"].edits.map(item => {
                const oldPart = item.oldPart
                const newPart = item.newPart
                return (
                  <div key={newPart.id}>
                    <div style={{display: "flex"}}>
                      <Card
                        className={"bendCard"}
                        style={{height: 80, marginTop: 12, marginBottom: 12}}
                        bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
                      >
                        <div style={{display: "flex", width: "100%"}}>
                          <div
                            style={{
                              border: "1px solid rgb(205, 205, 205)",
                              borderRadius: 3,
                              marginRight: 8,
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            {oldPart.fileType === "dxf" &&
                              <div>
                                {oldPart.dxfData.pngFilePath ?
                                  <div
                                    style={{
                                      width: 55,
                                      height: 55,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <div>
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          maxWidth: 62,
                                          maxHeight: 55,
                                          padding: 3,
                                        }}
                                        src={S3_BUCKET_URL + oldPart.dxfData.pngFilePath}
                                        alt={"part"}
                                      />
                                    </div>
                                  </div>
                                  :
                                  <div
                                    style={{
                                      width: 55,
                                      height: 55,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: 45,
                                        height: 45,
                                        padding: 3,
                                        marginTop: 5,
                                        marginLeft: 4
                                      }}
                                      src={twoD}
                                      alt={"part"}
                                    />
                                  </div>
                                }
                              </div>
                            }
                            {oldPart.fileType === "step" &&
                              <div>
                                {oldPart.stepData.filePaths.pngFilePath ?
                                  <img style={{width: 55, height: 55, padding: 3}} src={S3_BUCKET_URL + oldPart.stepData.filePaths.pngFilePath} alt={"part"}/>
                                  :
                                  <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={treeD} alt={"part"}/>
                                  </div>
                                }
                              </div>
                            }
                            {(oldPart.fileType === "other" || (!oldPart.fileType && oldPart.processingType === "manual")) &&
                              <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={getPlaceholderImage(oldPart.partType)} alt={"part"}/>
                              </div>
                            }
                          </div>
                          <div style={{width: "100%"}}>
                            <div>
                              <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                <Tooltip
                                  overlayClassName={"delete-tooltip"}
                                  color={"rgba(82,82,82,0.9)"}
                                  title={<span className={"text12-500"} style={{color: "white"}}>{newPart.fileName || newPart.name}</span>}
                                >
                                  <div
                                    style={{
                                      display: "inline-block",
                                      fontSize: 14,
                                      fontWeight: 600,
                                      marginRight: 8,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: 200,
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    {newPart.fileName || newPart.name}
                                  </div>
                                </Tooltip>
                                <div>
                                  <div style={{height: 24}}/>
                                </div>
                              </div>
                              <div>
                                {oldPart.partType === "sheetMetal" ?
                                  <div
                                    style={{fontSize: 12, fontWeight: 500}}
                                  >
                                    Material: <span style={{fontWeight: 600}}><Trans>{oldPart.selectedMaterial.grade}</Trans> ({oldPart.selectedMaterial.thickness}mm)</span>
                                  </div>
                                  :
                                  (oldPart.partType === "machined" || oldPart.partType === "profile" ?
                                      <div
                                        style={{fontSize: 12, fontWeight: 500}}
                                      >
                                        Material: <span style={{fontWeight: 600}}><Trans>{oldPart.selectedMaterial?.grade || oldPart.materialGrade}</Trans></span>
                                      </div>
                                      :
                                      <div
                                        style={{fontSize: 12, fontWeight: 500}}
                                      >
                                        Standard: <span style={{fontWeight: 600}}>{oldPart.standard}</span>
                                      </div>
                                  )
                                }
                                <div style={{display:"flex", justifyContent: "space-between"}}>
                                  <div
                                    style={{fontSize: 12, fontWeight: 500, marginRight: 8}}
                                  >
                                    Quantity: <span style={{fontWeight: 600}}>{oldPart.quantity}</span>
                                  </div>
                                  {item.editType === "newQuote" || oldPart.quoteType === 'unquoted' ?
                                    <div
                                      style={{fontSize: 12, fontWeight: 500}}
                                    >
                                      1pc: <span style={{fontWeight: 600, color: "rgba(0, 0, 0, 0.85)"}}>RFQ</span>
                                    </div>
                                    :
                                    <div
                                      style={{fontSize: 12, fontWeight: 500}}
                                    >
                                      1pc: <span style={{fontWeight: 600, color: "rgba(0, 0, 0, 0.85)"}}>{(parseFloat(oldPart.pricePerPiece) || 0).toFixed(2)} €</span>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                      <div align={"center"} style={{display: "flex"}}>
                        <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
                      </div>
                      <Card
                        className={item.editType === 'delete' ? "declinedCard bendCard" : "changedCard bendCard"}
                        style={{height: 80, marginTop: 12, marginBottom: 12}}
                        bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
                      >
                        <div style={{display: "flex", width: "100%"}}>
                          <div
                            style={{
                              border: "1px solid rgb(205, 205, 205)",
                              borderRadius: 3,
                              marginRight: 8,
                              background: "white",
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            {newPart.fileType === "dxf" &&
                              <div>
                                {newPart.dxfData.pngFilePath ?
                                  <div
                                    style={{
                                      width: 55,
                                      height: 55,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <div>
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          maxWidth: 62,
                                          maxHeight: 55,
                                          padding: 3,
                                        }}
                                        src={S3_BUCKET_URL + newPart.dxfData.pngFilePath}
                                        alt={"part"}
                                      />
                                    </div>
                                  </div>
                                  :
                                  <div
                                    style={{
                                      width: 55,
                                      height: 55,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: 45,
                                        height: 45,
                                        padding: 3,
                                        marginTop: 5,
                                        marginLeft: 4
                                      }}
                                      src={twoD}
                                      alt={"part"}
                                    />
                                  </div>
                                }
                              </div>
                            }
                            {newPart.fileType === "step" &&
                              <div>
                                {newPart.stepData.filePaths.pngFilePath ?
                                  <img style={{width: 55, height: 55, padding: 3}} src={S3_BUCKET_URL + newPart.stepData.filePaths.pngFilePath} alt={"part"}/>
                                  :
                                  <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={treeD} alt={"part"}/>
                                  </div>
                                }
                              </div>
                            }
                            {(newPart.fileType === "other" || (!newPart.fileType && newPart.processingType === "manual")) &&
                              <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={getPlaceholderImage(newPart.partType)} alt={"part"}/>
                              </div>
                            }
                          </div>
                          <div style={{width: "100%"}}>
                            <div>
                              <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                <Tooltip
                                  overlayClassName={"delete-tooltip"}
                                  color={"rgba(82,82,82,0.9)"}
                                  title={<span className={"text12-500"} style={{color: "white"}}>{newPart.fileName || newPart.name}</span>}
                                >
                                <div
                                  style={{
                                    display: "inline-block",
                                    fontSize: 14,
                                    fontWeight: 600,
                                    marginRight: 8,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: 200,
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {newPart.fileName || newPart.name}
                                </div>
                                </Tooltip>
                                <div>
                                  <div style={{height: 24, fontSize: 12, fontWeight: 600}}>
                                    {getEditType(item.editType)}
                                  </div>
                                </div>
                              </div>
                              <div>
                                {newPart.partType === "sheetMetal" ?
                                  <div
                                    style={{fontSize: 12, fontWeight: 500}}
                                  >
                                    Material: <span style={{fontWeight: 600}}><Trans>{newPart.selectedMaterial.grade}</Trans> ({newPart.selectedMaterial.thickness}mm)</span>
                                  </div>
                                  :
                                  (newPart.partType === "machined" || newPart.partType === "profile" ?
                                      <div
                                        style={{fontSize: 12, fontWeight: 500}}
                                      >
                                        Material: <span style={{fontWeight: 600}}><Trans>{newPart.selectedMaterial?.grade || newPart.materialGrade}</Trans></span>
                                      </div>
                                      :
                                      <div
                                        style={{fontSize: 12, fontWeight: 500}}
                                      >
                                        Standard: <span style={{fontWeight: 600}}>{newPart.standard}</span>
                                      </div>
                                  )
                                }
                                <div style={{display:"flex", justifyContent: "space-between"}}>
                                  <div
                                    style={{fontSize: 12, fontWeight: 500, marginRight: 8}}
                                  >
                                    Quantity: <span style={{fontWeight: 600}}>{newPart.quantity}</span>
                                  </div>
                                  {item.editType === "delete" ?
                                    <div
                                      style={{fontSize: 12, fontWeight: 500}}
                                    >
                                      1pc: <span style={{fontWeight: 600, color: "rgba(0, 0, 0, 0.85)"}}>0.00 €</span>
                                    </div>
                                    :
                                    <div
                                      style={{fontSize: 12, fontWeight: 500}}
                                    >
                                      1pc: <span style={{fontWeight: 600, color: item.editType !== 'delete' ? "#047a04" : "rgba(0, 0, 0, 0.85)"}}>{(parseFloat(newPart.pricePerPiece) || 0).toFixed(2)} €</span>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                    <Divider style={{marginTop: 0, marginBottom: 0}}/>
                  </div>
                )
              })}
              {order["archive"].newParts && order["archive"].newParts.map(item => {
                return (
                  <div key={item.id || item._id}>
                    <Card
                      className={"changedCard bendCard"}
                      style={{height: 80, marginTop: 12, marginBottom: 12}}
                      bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
                    >
                      <div style={{display: "flex", width: "100%"}}>
                        <div
                          style={{
                            border: "1px solid rgb(205, 205, 205)",
                            borderRadius: 3,
                            marginRight: 8,
                            background: "white",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          {item.fileType === "dxf" &&
                            <div>
                              {item.dxfData.pngFilePath ?
                                <img
                                  style={{
                                    width: 55,
                                    height: 55,
                                    padding: 3
                                  }}
                                  src={S3_BUCKET_URL + item.dxfData.pngFilePath}
                                  alt={"part"}
                                />
                                :
                                <div
                                  style={{
                                    width: 55,
                                    height: 55,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                  }}
                                >
                                  <img
                                    style={{
                                      width: 45,
                                      height: 45,
                                      padding: 3,
                                      marginTop: 5,
                                      marginLeft: 4
                                    }}
                                    src={twoD}
                                    alt={"part"}
                                  />
                                </div>
                              }
                            </div>
                          }
                          {item.fileType === "step" &&
                            <div>
                              {item.stepData.filePaths.pngFilePath ?
                                <img style={{width: 55, height: 55, padding: 3}} src={S3_BUCKET_URL + item.stepData.filePaths.pngFilePath} alt={"part"}/>
                                :
                                <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                  <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={treeD} alt={"part"}/>
                                </div>
                              }
                            </div>
                          }
                          {(item.fileType === "other" || (!item.fileType && item.processingType === "manual")) &&
                            <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                              <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={getPlaceholderImage(item.partType)} alt={"part"}/>
                            </div>
                          }
                        </div>
                        <div style={{width: "100%"}}>
                          <div>
                            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                              <div
                                style={{
                                  display: "inline-block",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  marginRight: 8,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: 280,
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.fileName || item.name}
                              </div>
                              <div>
                                <div style={{height: 24, fontSize: 12, fontWeight: 600}}>
                                  Additionally Added
                                </div>
                              </div>
                            </div>
                            <div>
                              {item.partType === "sheetMetal" ?
                                <div
                                  style={{fontSize: 12, fontWeight: 500}}
                                >
                                  Material: <span style={{fontWeight: 600}}><Trans>{item.selectedMaterial.grade}</Trans> ({item.selectedMaterial.thickness}mm)</span>
                                </div>
                                :
                                (item.partType === "machined" || item.partType === "profile" ?
                                    <div
                                      style={{fontSize: 12, fontWeight: 500}}
                                    >
                                      Material: <span style={{fontWeight: 600}}><Trans>{item.selectedMaterial?.grade || item.materialGrade}</Trans></span>
                                    </div>
                                    :
                                    <div
                                      style={{fontSize: 12, fontWeight: 500}}
                                    >
                                      Standard: <span style={{fontWeight: 600}}>{item.standard}</span>
                                    </div>
                                )
                              }
                              <div style={{display:"flex", justifyContent: "space-between"}}>
                                <div
                                  style={{fontSize: 12, fontWeight: 500, marginRight: 8}}
                                >
                                  Quantity: <span style={{fontWeight: 600}}>{item.quantity}</span>
                                </div>
                                <div
                                  style={{fontSize: 12, fontWeight: 500}}
                                >
                                  1pc: <span style={{fontWeight: 600, color: "#047a04"}}>{(parseFloat(item.pricePerPiece) || 0).toFixed(2)} €</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Divider style={{marginTop: 0, marginBottom: 0}}/>
                  </div>
                )
              })}
            </div>
          </div>
          :
          null
        }
      </Modal>
    </div>
  )
}