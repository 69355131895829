import {
  PRODUCERS_FETCHING_PRODUCER_DETAILS,
  PRODUCERS_FETCHING_PRODUCERS,
  PRODUCERS_PAGINATION,
  PRODUCERS_PRODUCERS,
  PRODUCERS_IS_ADDING_SUB_ADMIN
} from './actionTypes'
import { API_BASE_URL } from '../constants'
import { http, message } from '../utils'

const fetchProducers = payload => dispatch => {
  dispatch({
    type: PRODUCERS_FETCHING_PRODUCERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/producer?pageRowCount=6&pageIndex=${payload.page}&email=${payload.email}&name=${payload.name}`)
    .then(response => {
      const pagination = { ...payload.pagination };
      pagination.total = response.data.totalCount;

      dispatch({
        type: PRODUCERS_PAGINATION,
        payload: pagination,
      })
      dispatch({
        type: PRODUCERS_PRODUCERS,
        payload: response.data.producers,
      })
      dispatch({
        type: PRODUCERS_FETCHING_PRODUCERS,
        payload: false,
      })
    })
    .catch(error => {
      console.log(error)
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {

      }
      else {
        dispatch({
          type: PRODUCERS_FETCHING_PRODUCERS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchProducer = payload => dispatch => {
  dispatch({
    type: PRODUCERS_FETCHING_PRODUCER_DETAILS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/producer/${payload.producerId}`)
    .then(response => {
      dispatch({
        type: PRODUCERS_FETCHING_PRODUCER_DETAILS,
        payload: false,
      })

      return response.data
    })
    .catch(error => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {

      }
      else {
        dispatch({
          type: PRODUCERS_FETCHING_PRODUCER_DETAILS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const changeProducerOrders = payload => dispatch => {
  return http
    .put(`${API_BASE_URL}/producer/order/${payload.producerId}`, { "hasOrdering": payload.hasOrdering })
    .then((response) => {
      return payload.producerId
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const changeProducerBulk = payload => dispatch => {
  return http
    .put(`${API_BASE_URL}/producer/bulk/${payload.producerId}`, { "hasBulk": payload.hasBulk })
    .then((response) => {
      return payload.producerId
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const changeProducerNesting = payload => dispatch => {
  return http
    .put(`${API_BASE_URL}/producer/nest/${payload.producerId}`, { "hasNesting": payload.hasNesting })
    .then((response) => {
      return payload.producerId
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

export { fetchProducers, fetchProducer, changeProducerOrders, changeProducerNesting, changeProducerBulk }
