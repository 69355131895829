import {
  SURFACE_COATING_SET_DATA, SURFACE_COATING_IS_FETCHING_DATA,
  SURFACE_COATING_SET_SURFACE_COATING_SERVICE, SURFACE_COATING_SET_PAINTING_SERVICE,
  SURFACE_COATING_SET_POWDER_PAINTING_SERVICE, SURFACE_COATING_SET_GALVANIZING_SERVICE,
  SURFACE_COATING_SET_PAINTING_DATA, SURFACE_COATING_SET_POWDER_PAINTING_DATA,
  SURFACE_COATING_SET_GALVANIZING_DATA
} from '../actions/actionTypes'
import initialState from './initialState'

const surfaceCoatingReducer = (state = initialState.surfaceCoating, action) => {
  switch (action.type) {
    case SURFACE_COATING_SET_DATA:
      return {
        ...state,
        surfaceCoatingData: action.payload,
      }

    case SURFACE_COATING_IS_FETCHING_DATA:
      return {
        ...state,
        isFetchingSurfaceCoatingData: action.payload,
      }

    case SURFACE_COATING_SET_SURFACE_COATING_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, hasSurfaceCoating: action.payload},
      }

    case SURFACE_COATING_SET_PAINTING_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, painting: {...state.surfaceCoatingData.painting, hasPainting: action.payload}},
      }

    case SURFACE_COATING_SET_POWDER_PAINTING_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, powderPainting: {...state.surfaceCoatingData.powderPainting, hasPowderPainting: action.payload}},
      }

    case SURFACE_COATING_SET_GALVANIZING_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, galvanizing: {...state.surfaceCoatingData.galvanizing, hasGalvanizing: action.payload}},
      }

    case SURFACE_COATING_SET_PAINTING_DATA:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, painting: {...state.surfaceCoatingData.painting, ...action.payload}},
      }

    case SURFACE_COATING_SET_POWDER_PAINTING_DATA:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, powderPainting: {...state.surfaceCoatingData.powderPainting, ...action.payload}},
      }

    case SURFACE_COATING_SET_GALVANIZING_DATA:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, galvanizing: {...state.surfaceCoatingData.galvanizing, ...action.payload}},
      }

    default:
      return state
  }
}

export default surfaceCoatingReducer
