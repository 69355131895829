import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";

import {Divider, Layout, Modal, Select, Spin, Tabs, Tag} from "antd";
import {Divider as MiDivider} from "@material-ui/core";
import {forceLogoutUser, logoutUser} from "../../../actions/authActions";

import {CompanySidebar} from "../../../components/company";

import {NewNesting} from "../../../components/company/CompanyNesting/NestingTabs/NewNesting";
import {AcceptedNesting} from "../../../components/company/CompanyNesting/NestingTabs/AcceptedNesting";
import {CompletedNesting} from "../../../components/company/CompanyNesting/NestingTabs/CompletedNesting";
import {NestingSettings} from "../../../components/company/CompanyNesting/NestingTabs/NestingSettings";
import {CaretDownOutlined, InfoCircleOutlined, LoadingOutlined} from "@ant-design/icons";

import storage from "../../../utils/storage";
import {io} from "socket.io-client";
import {S3_BUCKET_URL, SOCKET_PATH, SOCKET_URL} from "../../../constants";
import {NestedPlateModal} from "../../../components/company/CompanyNesting/NestedPlateModal";
import {
  acceptJob, completeJob, fetchAcceptedNestingJobs,
  fetchPlateData,
  startNewNestingJob
} from "../../../actions/nestingActions";
import {LeftoverPartList} from "../../../components/company/CompanyNesting/LeftoverPartList";
import nest from "../../../assets/images/nest2.png";
import {formatTableDate, formatTime} from "../../../utils/utility";
import {NestedPartsList} from "../../../components/company/CompanyNesting/NestedPartsList";

const { TabPane } = Tabs;
const { Option } = Select;

var socket;

export const NestingPage = () => {
  const dispatch = useDispatch()

  const [selectedTab, setSelectedTab] = useState("1")
  const [confirmNestingModalVisible, setConfirmNestingModalVisible] = useState(false);
  const [totalParts, setTotalParts] = useState(0);
  const [ordersForNesting, setOrdersForNesting] = useState([]);
  const [showPlate, setShowPlate] = useState(false);

  const [showLeftovers, setShowLeftovers] = useState(false);
  const [leftoverParts, setLeftoverParts] = useState([]);

  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const [modalCuttingMIndex, setModalCuttingMIndex] = useState(null);
  const [modalPlateData, setModalPlateData] = useState(null);
  const [modalSpinning, setModalSpinning] = useState(false);
  const [acceptingPlate, setAcceptingPlate] = useState(false);

  const [showCompleteConfirmation, setShowCompleteConfirmation] = useState(false);
  const [completingPlate, setCompletingPlate] = useState(false);

  const [viewPlateData, setViewPlateData] = useState(null);

  const [socketConnected, setSocketConnected] = useState(false)

  const user = useSelector(state => state.auth.user);
  const plateData = useSelector(state => state.nesting.plateData);
  const isFetchingPlateData = useSelector(state => state.nesting.isFetchingPlateData)
  const isCollapsed = useSelector(state => state.util.isCollapsed);

  const onCancelNesting = () => {
    setConfirmNestingModalVisible(false);
  }

  const onStartNesting = (orders) => {
    let total = 0;
    const ordersArray = Object.entries(orders)
    ordersArray.forEach(elem => {
      total += elem[1].parts;
    })
    setTotalParts(total)
    setOrdersForNesting(ordersArray);
    setConfirmNestingModalVisible(true);
  }

  const startNestingJob = () => {
    let orders = ordersForNesting.map(item => item[0])
    dispatch(startNewNestingJob({orders}))
    setConfirmNestingModalVisible(false);
  }

  const onViewPlate = (plateState, plateId) => {
    setShowPlate(true);
    dispatch(fetchPlateData({plateState, plateId})).then((plateData)=>{
      setViewPlateData(plateData)
    }).catch(()=>{
      setShowPlate(false);
    });
  }

  const onClosePlate = () => {
    setShowPlate(false);
    setViewPlateData(null)
  }

  const onViewLeftovers = (parts) => {
    setLeftoverParts(parts)
    setShowLeftovers(true);
  }

  const onAcceptJob = (plate) => {
    setModalSpinning(true);
    setModalCuttingMIndex(plate.cuttingMachines ? 0 : null);
    setModalPlateData(plate);
    setShowAcceptConfirmation(true);
    setModalSpinning(false);
  }

  const handleAcceptJob = () => {
    setAcceptingPlate(true)
    let payloadData;
    if (modalPlateData.cuttingMachines !== null && modalCuttingMIndex !== null) {
      payloadData = {
        plateId: modalPlateData.id,
        cuttingMachineId: modalPlateData.cuttingMachines[modalCuttingMIndex].machineId
      }
    } else {
      payloadData = {plateId: modalPlateData.id,}
    }
    dispatch(acceptJob(payloadData))
      .then(()=>{
        setAcceptingPlate(false)
        setShowAcceptConfirmation(false);
      }).catch(()=>{
        setShowAcceptConfirmation(false);
      })
  }

  const onCompleteJob = (item) => {
    setModalSpinning(true);
    setShowCompleteConfirmation(true);
    setModalPlateData(item);
    dispatch(fetchPlateData({plateState: "accepted", plateId: item.id}))
      .then(() => {
        setModalSpinning(false);
      })
      .catch(() => {
        setModalSpinning(false);
        setShowCompleteConfirmation(false);
      })
  }

  const handleCompleteJob = () => {
    setCompletingPlate(true);
    dispatch(completeJob({plateId: modalPlateData.id}))
      .then(() => {
        setCompletingPlate(false);
        dispatch(fetchAcceptedNestingJobs());
      })
      .catch(() => {
        setShowCompleteConfirmation(false);;
      })
    setShowCompleteConfirmation(false);;
  }

  useEffect(() => {
    const accessToken = storage.retrieveAccessToken();

    socket = io(SOCKET_URL, {
      path: SOCKET_PATH,
      auth: {
        token: accessToken.substring(7)
      },
    });

    socket.on("connect", () => {
      setSocketConnected(true)
    });

    socket.on("connect_error", (err) => {
      if (err.message === "invalid credentials") {
        storage.refreshAccessToken().then(()=>{
          const accessToken = storage.retrieveAccessToken();
          if(accessToken) {
            socket.auth.token = accessToken.substring(7);
            socket.connect();
          }
        })
      }
    });

    socket.on("server-error", (err) => {
      console.log(err.msg)
      if (err.msg === "invalid credentials") {
        dispatch(forceLogoutUser())
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const changeMaterialMargin = ({materialId, margin}) => {
    if(socket) {
      socket.emit("put-nesting-margin", { materialId, margin })
    }
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}} type="flex">
        <Spin
          spinning={false}
          indicator={<div style={{width: 400, fontWeight: 500, fontSize: 38, marginLeft: -200, marginTop: 100}}>
            Under construction
          </div>}
        >
        <div style={{paddingLeft: 15, paddingTop: 15, paddingRight: 15}}>
          <Tabs size={"small"} activeKey={selectedTab} onChange={(e)=>{setSelectedTab(e)}} type="card" className={"materialsTabs noSelect"}>
            <TabPane className={"materialsTabPane noSelect"} tab="New Nesting Job" key="1">
              <NewNesting
                selectedTab={selectedTab}
                onStartNesting={onStartNesting}
                onViewPlate={onViewPlate}
                onViewLeftovers={onViewLeftovers}
                onAcceptJob={onAcceptJob}
              />
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Accepted Nesting Jobs" key="2">
              <AcceptedNesting
                selectedTab={selectedTab}
                onViewPlate={onViewPlate}
                onCompleteJob={onCompleteJob}
              />
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Completed Nesting Jobs" key="3">
              <CompletedNesting
                selectedTab={selectedTab}
                onViewPlate={onViewPlate}
              />
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Settings" key="4">
              <NestingSettings
                changeMaterialMargin={changeMaterialMargin}
                selectedTab={selectedTab}
                socketConnected={socketConnected}
              />
            </TabPane>
          </Tabs>
        </div>
        </Spin>
      </Layout.Content>
      <Modal
        title={"Start a New Nesting Job"}
        visible={confirmNestingModalVisible}
        okText={<span style={{ fontWeight: 500 }}>Start Nesting</span>}
        cancelText={<span style={{ fontWeight: 500 }}>Cancel</span>}
        onCancel={onCancelNesting}
        onOk={startNestingJob}
        width={580}
      >
        <div style={{ width: "100%", height: "100%" }}>
          {ordersForNesting.length > 0 &&
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
                <div style={{ display: "flex", flexDirection: "column", width: 440 }}>
                  <span>Orders for nesting:</span>
                  <div align={"left"} style={{marginTop: 4}}>
                    <div className={"nestingCard nestingCardScroll"} style={{maxHeight: '250px', minHeight: 40, paddingRight: 0}}>
                      {ordersForNesting.map(item => {
                        return(
                          <Tag align={"left"} style={{width: 90, lineHeight: "18px"}} key={item[1].id} color="#1890ff">
                            <span style={{fontWeight: 500, display: "inline-block", width: 60, minWidth: 60}}>{item[1].name}</span>
                          </Tag>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <MiDivider orientation={"vertical"} flexItem style={{ marginInline: 12}}/>
                <div style={{ display: "flex", flexDirection: "column", width: 100 }}>
                  <span>Total parts:</span>
                  <div className={"nestingCard totalParts"} style={{maxHeight: '250px', minHeight: 40 }}>
                    <b>{totalParts}</b>
                  </div>
                </div>
              </div>
              <div style={{ fontSize: 12, lineHeight: "14px", marginTop: 4, color: "#555" }}>
                <InfoCircleOutlined /> Nesting may take some time depending on the total number of parts.
              </div>
            </div>
          }
        </div>
      </Modal>
      <NestedPlateModal
        isPlateViewVisible={showPlate}
        onClose={onClosePlate}
        plateData={viewPlateData}
      />
      <Modal
        className={"leftoversModal"}
        visible={showLeftovers}
        footer={null}
        centered={true}
        onCancel={() => {
          setShowLeftovers(false);
          setLeftoverParts([]);
        }}
        destroyOnClose={true}
        width={600}
      >
        <div style={{fontSize: 16, fontWeight: 500, marginBottom: 4}}>Leftover parts from the latest nesting job:</div>
        <LeftoverPartList parts={leftoverParts}/>
      </Modal>
      <Modal
        title={"Are you sure you want to accept this plate?"}
        visible={showAcceptConfirmation}
        onCancel={() => {
          setShowAcceptConfirmation(false);
        }}
        cancelText={<span style={{ fontWeight: 500 }}>Cancel</span>}
        onOk={() => {
          handleAcceptJob();
        }}
        okButtonProps={{loading: acceptingPlate}}
        okText={<span style={{ fontWeight: 500 }}>Confirm</span>}
        destroyOnClose={true}
        centered={true}
      >
        <div style={{ width: "100%", height: "100%" , display: "flex" }}>
          <Spin spinning={modalSpinning}
                className={"centeredCol"}
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          >
            {modalPlateData !== null &&
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: 190, height: 176, borderRadius: 3, marginRight: 12 }}>
                    <div align={"center"} className={"nestingPicture"} style={{ cursor: "unset"}}>
                      <img style={{objectFit: "contain", maxHeight: 165}} alt={modalPlateData.name} src={modalPlateData.thumbnailPath ? `${S3_BUCKET_URL}${modalPlateData.thumbnailPath}` : nest} width={185}/>
                    </div>
                  </div>

                  <div>
                    <div className={"nestingText"}>Plate ID: <span className={"nestingText500"}>{modalPlateData.name}</span></div>
                    <div className={"nestingText"}>Material: <span className={"nestingText500"}>{modalPlateData.grade}</span></div>
                    <div className={"nestingText"}>Thickness: <span className={"nestingText500"}>{modalPlateData.thickness}mm</span></div>
                    <div className={"nestingText"}>Dimensions: <span className={"nestingText500"}>{modalPlateData.width}x{modalPlateData.height}mm</span></div>
                    <div className={"nestingText"}>Efficiency: <span className={"nestingText500"}>{modalPlateData.efficiency}%</span></div>
                    {modalPlateData?.cuttingMachines && (
                      modalPlateData.cuttingMachines.length === 1 ? (
                        <div>
                          <div className={"nestingText"}>Selected Laser:
                            <div className={"disabledInput"}>
                              <span className={"nestingText500"}>{modalPlateData.cuttingMachines[modalCuttingMIndex].name}</span>
                            </div>
                          </div>
                          <div className={"nestingText"}>Cutting Time: <span className={"nestingText500"}>{formatTime(modalPlateData.cuttingMachines[0].cuttingTime)}</span></div>
                        </div>
                        ) : (
                        <div>
                          <div className={"nestingText"}>Selected Laser:
                            <div className={"disabledInput"}>
                              <span className={"nestingText500"}>{modalPlateData.cuttingMachines[modalCuttingMIndex].name}</span>
                            </div>
                          </div>
                          <div className={"nestingText"}>Cutting Time: <span className={"nestingText500"}>{formatTime(modalPlateData.cuttingMachines[modalCuttingMIndex].cuttingTime)}</span></div>
                        </div>
                        )
                    )}
                    <div className={"nestingText"}>Delivery date: <span className={"nestingText500"}>{formatTableDate(modalPlateData.deliveryDate)}</span></div>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", marginTop: 6 }}>
                  {modalPlateData.cuttingMachines?.length > 1 &&
                    <div>
                      <Divider style={{ marginTop: 4, marginBottom: 10 }}/>
                      <div className={"nestingText"}>Change Laser:
                        <span className={"nestingText500"} style={{marginLeft: 5}}>
                      <Select
                        size={"small"}
                        style={{width: 150}}
                        defaultValue={0}
                        suffixIcon={<CaretDownOutlined/>}
                        onChange={(value) => {setModalCuttingMIndex(value)}}
                      >
                        {modalPlateData.cuttingMachines.map((laser, index) =>
                          <Option
                            key={index}
                            value={index}
                          >
                            {laser.name}
                          </Option>
                        )}
                      </Select>
                    </span>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
          </Spin>
        </div>
      </Modal>
      <Modal
        visible={showCompleteConfirmation}
        title={"Complete this order?"}
        okText={<span style={{ fontWeight: 500 }}>Confirm</span>}
        cancelText={<span style={{ fontWeight: 500 }}>Cancel</span>}
        onOk={() => {
          handleCompleteJob()
        }}
        okButtonProps={{loading: completingPlate}}
        onCancel={() => {
          setShowCompleteConfirmation(false);
        }}
        destroyOnClose={true}
        centered={true}
      >
        <div style={{ width: "100%", height: "100%" , display: "flex" }}>
          <Spin spinning={modalSpinning || isFetchingPlateData}
                className={"centeredCol"}
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          >
            {modalPlateData !== null && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: 190, height: 176, borderRadius: 3, marginRight: 12 }}>
                    <div className={"nestingPicture"} style={{ cursor: "unset"}}>
                      <img style={{objectFit: "contain", maxHeight: 165}} alt={modalPlateData.name} src={modalPlateData.thumbnailPath ? `${S3_BUCKET_URL}${modalPlateData.thumbnailPath}` : nest} width={185}/>
                    </div>
                  </div>

                  <div>
                    <div className={"nestingText"}>Plate ID: <span className={"nestingText500"}>{modalPlateData.name}</span></div>
                    <div className={"nestingText"}>Material: <span className={"nestingText500"}>{modalPlateData.grade}</span></div>
                    <div className={"nestingText"}>Thickness: <span className={"nestingText500"}>{modalPlateData.thickness}mm</span></div>
                    <div className={"nestingText"}>Dimensions: <span className={"nestingText500"}>{modalPlateData.width}x{modalPlateData.height}mm</span></div>
                    <div className={"nestingText"}>Efficiency: <span className={"nestingText500"}>{modalPlateData.efficiency}%</span></div>
                    {modalPlateData?.selectedCuttingMachine && (
                      <div>
                        <div className={"nestingText"}>Selected Laser:
                          <div className={"disabledInput"}>
                            <span className={"nestingText500"}>{modalPlateData.selectedCuttingMachine.name}</span>
                          </div>
                        </div>
                        <div className={"nestingText"}>Cutting Time: <span className={"nestingText500"}>{formatTime(modalPlateData.selectedCuttingMachine.cuttingTime)}</span></div>
                      </div>
                    )}
                    <div className={"nestingText"}>Delivery date: <span className={"nestingText500"}>{formatTableDate(modalPlateData.deliveryDate)}</span></div>
                  </div>
                </div>
                <Divider style={{ marginTop: 8, marginBottom: 4 }}/>
                <div style={{ fontWeight: 500, fontSize: 16 }}>
                  Nested parts:
                </div>
                {plateData !== null && !isFetchingPlateData && (
                  <div className={"relatedPartsContainer"}>
                    <NestedPartsList plateData={plateData}/>
                  </div>
                )}
              </div>
            )}
          </Spin>
        </div>
      </Modal>
    </Layout>
  )
}