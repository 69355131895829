import React, {useEffect, useState, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./style.css";

import {
  Form, Row, Col,
  Card, InputNumber,
  Divider, Switch,
  Tabs, Modal,
  Typography, Button,
  Input, Checkbox,
  Spin, Empty
} from "antd";

import MaterialTableContainer from "../../MaterialTableContainer";
import ModalTable from "../../MaterialTables/ModalTable";

import { useWindowSize } from "../../../../../utils/utility";
import {data as bends} from "../../../../../data/materialBends_test";
import {DeleteOutlined, EditOutlined, LoadingOutlined} from "@ant-design/icons";
import {lineBreakLimit} from "../../../../../constants";
import {DeletionModal} from "../../../../DeletionModal";
import {
  fetchBendingData, setBendingMaxLength,
  setBendingService, setPreparationPrice,
  setAveragePreparationTime, resetMaterialBendsData,
  fetchSingleMaterialBendsData, addNewBend,
  fetchSingleMaterialBends, editBend,
  deleteBends
} from "../../../../../actions/bendingActions";
import {debounce} from "lodash";
import {Trans} from "react-i18next";

const { TabPane } = Tabs;

const fetchRadii = (materialCategory, materialGrade, materialThickness) => {
  return [{
    "radiusId": "1",
    "radius": 1,
  },
    {
      "radiusId": "2",
      "radius": 5,
    },
    {
      "radiusId": "3",
      "radius": 10,
    },
    {
      "radiusId": "4",
      "radius": 15,
    },
    {
      "radiusId": "5",
      "radius": 20,
    },
    {
      "radiusId": "6",
      "radius": 25,
    },
    {
      "radiusId": "7",
      "radius": 30,
    },
    {
      "radiusId": "8",
      "radius": 35,
    },
    {
      "radiusId": "9",
      "radius": 40,
    }]
}

export const MaterialBendingTab = (props) => {
  const { selectedTab, changeBendingParams } = props

  const [disableSwitching, setDisableSwitching] = useState(false);

  const [mainModalVisible, setMainModalVisible] = useState(false);
  const [selectedFieldData, setSelectedFieldData] = useState({});
  const [selectedDataType, setSelectedDataType] = useState(null);

  const [radiiData, setRadiiData] = useState([]);

  const [editBendModalVisible, setEditBendModalVisible] = useState(false);
  const [addBendModalVisible, setAddBendModalVisible] = useState(false);
  const [currentBend, setCurrentBend] = useState({});
  const [deleteBendModalVisible, setDeleteBendModalVisible] = useState(false);

  const [editRadiusModalVisible, setEditRadiusModalVisible] = useState(false);
  const [addRadiusModalVisible, setAddRadiusModalVisible] = useState(false);
  const [currentRadius, setCurrentRadius] = useState({});
  const [deleteRadiusModalVisible, setDeleteRadiusModalVisible] = useState(false);
  
  const [multiDeleteMode, setMultiDeleteMode] = useState(false);
  const [multiDeleteBendSelection, setMultiDeleteBendSelection] = useState([]);
  const [multiDeleteRadiusSelection, setMultiDeleteRadiusSelection] = useState([]);

  const [shouldUpdateBends, setShouldUpdateBends] = useState(false)
  const [activeLoadingBendsTabs, setActiveLoadingBendsTabs] = useState({})

  const [editBendForm] = Form.useForm();
  const [addBendForm] = Form.useForm();
  const [editRadiusForm] = Form.useForm();
  const [addRadiusForm] = Form.useForm();

  const bendingForm = React.createRef()

  const dispatch = useDispatch()

  const windowSize = useWindowSize();

  const bendingData = useSelector(state => state.bending.bendingData)

  const bendingBendsData = useSelector(state => state.bending.bendingBendsData)
  const isFetchingSingleMaterialBends = useSelector(state => state.bending.isFetchingSingleMaterialBends)

  const isAddingNewBend = useSelector(state => state.bending.isAddingNewBend)
  const isEditingBend = useSelector(state => state.bending.isEditingBend)
  const isRemovingBend = useSelector(state => state.bending.isRemovingBend)

  useEffect(()=>{
    if(selectedTab === "2") {
      dispatch(fetchBendingData()).then(()=>{
        setShouldUpdateBends(true)
      })
    }
  }, [selectedTab])

  useEffect(()=>{
    bendingForm.current?.setFieldsValue({
      maxLength: bendingData.maxLength,
      preparationPrice: bendingData.preparationPrice,
      averagePreparationTime: bendingData.averagePreparationTime
    })
  }, [bendingData])

  const triggerSlideToggle = (value) => {
    setDisableSwitching(true);
    dispatch(setBendingService({value})).then(()=>{
      setDisableSwitching(false);
      if(value) {
        dispatch(fetchBendingData())
      }
    })
  }

  const setupMainModal = (params) => {
    setSelectedFieldData({
      materialGrade: params.materialGrade,
      materialThickness: params.materialThickness,
      bendsMaterialId: params.materialId,
      bendsGroupName: params.groupName,
      tab: params.tab
    })

    dispatch(fetchSingleMaterialBendsData({materialId: params.materialId}))


    setMainModalVisible(true);
  }

  const handleBendModalOpen = (params) => {
    setSelectedDataType("bends")
    setupMainModal(params)
  }

  const handleRadiusModalOpen = (params) => {
    setSelectedDataType("radii")
    setupMainModal(params)
  }

  const handleMainModalClose = () => {
    setMainModalVisible(false);
    setMultiDeleteMode(false)
    setMultiDeleteBendSelection([]);
    setMultiDeleteRadiusSelection([]);
    dispatch(resetMaterialBendsData())
  }

  const handleEditBend = (item) => {
    setCurrentBend({
      ...item
    })

    editBendForm.setFieldsValue({
      length: item.length,
      price: item.price
    })
    setEditBendModalVisible(true);
  }

  const handleEditBendModalClose = () => {
    editBendForm.resetFields()
    setCurrentBend({})
    setEditBendModalVisible(false);
  }

  const handleEditRadius = (radius) => {
    setCurrentRadius({
      "radius": radius.radius,
    });
    setEditRadiusModalVisible(true);
  }

  const handleEditRadiusModalClose = () => {
    setCurrentRadius({});
    setEditRadiusModalVisible(false);
  }

  const handleAddBendModalClose = () => {
    addBendForm.resetFields()
    setAddBendModalVisible(false);
  }

  const handleAddRadiusModalClose = () => {
    setAddRadiusModalVisible(false);
  }

  const handleMultiDeleteCheckBend = (item) => {
    if (multiDeleteBendSelection.includes(item.id)) {
      setMultiDeleteBendSelection(multiDeleteBendSelection.filter(elem => elem !== item.id));
    } else {
      setMultiDeleteBendSelection(prevState => [...prevState, item.id])
    }
  }

  const handleMultiBendDelete = () => {
    dispatch(deleteBends({materialId: selectedFieldData.bendsMaterialId, bends: multiDeleteBendSelection})).then(()=>{
      setMultiDeleteMode(false);
      setMultiDeleteBendSelection([]);
      setActiveLoadingBendsTabs(prevState => ({...prevState, [selectedFieldData.tab]: true}))
      dispatch(fetchSingleMaterialBends({groupName: selectedFieldData.bendsGroupName})).then(()=>{
        setActiveLoadingBendsTabs(prevState => ({...prevState, [selectedFieldData.tab]: false}))
      })
    })
  }

  const handleSingleBendDelete = (item) => {
    dispatch(deleteBends({materialId: selectedFieldData.bendsMaterialId, bends: [item.id]})).then(()=>{
      setDeleteBendModalVisible(false)
      setActiveLoadingBendsTabs(prevState => ({...prevState, [selectedFieldData.tab]: true}))
      dispatch(fetchSingleMaterialBends({groupName: selectedFieldData.bendsGroupName})).then(()=>{
        setActiveLoadingBendsTabs(prevState => ({...prevState, [selectedFieldData.tab]: false}))
      })
    })
  }

  const handleMultiDeleteCheckRadius = (item) => {
    if (multiDeleteRadiusSelection.includes(item.radiusId)) {
      setMultiDeleteRadiusSelection(multiDeleteRadiusSelection.filter(elem => elem !== item.radiusId));
    } else {
      setMultiDeleteRadiusSelection(prevState => [...prevState, item.radiusId])
    }
  }

  const submitEditBendForm = (values) => {
    if(Number(values.length) === Number(currentBend.length) && Number(values.price) === Number(currentBend.price)) {
      handleEditBendModalClose()
    }
    else {
      dispatch(editBend({
        materialId: selectedFieldData.bendsMaterialId,
        length: Number(values.length),
        price: Number(values.price),
        bendId: currentBend.id
      })).then(()=>{
        handleEditBendModalClose()
        dispatch(fetchSingleMaterialBendsData({materialId: selectedFieldData.bendsMaterialId}))
      }).catch(()=>{})
    }
  }

  const submitAddBendForm = (values) => {
    dispatch(addNewBend({
      materialId: selectedFieldData.bendsMaterialId,
      length: Number(values.length),
      price: Number(values.price)
    })).then(()=>{
      handleAddBendModalClose()
      dispatch(fetchSingleMaterialBendsData({materialId: selectedFieldData.bendsMaterialId}))
      setActiveLoadingBendsTabs(prevState => ({...prevState, [selectedFieldData.tab]: true}))
      dispatch(fetchSingleMaterialBends({groupName: selectedFieldData.bendsGroupName})).then(()=>{
        setActiveLoadingBendsTabs(prevState => ({...prevState, [selectedFieldData.tab]: false}))
      })
    }).catch(()=>{})
  }

  const submitEditRadiusForm = async () => {
    try {
      const values = await editRadiusForm.validateFields();
      console.log('Success:', values);
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  }

  const submitAddRadiusForm = async () => {
    try {
      const values = await addRadiusForm.validateFields();
      console.log('Success:', values);
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  }
 /*------- Max Length -------*/
  const changeMaxLength = (data) => {
    dispatch(setBendingMaxLength({value: data.maxLength}))
    changeBendingParams(data)
  }

  const debouncedMaxLengthChange = useCallback(
    debounce(data => changeMaxLength(data), 800),
    []
  );

  const changeBendingMaxLength = () => {
    debouncedMaxLengthChange({
      ...bendingForm.current.getFieldsValue()
    })
  }

  /*------- Preparation Price -------*/
  const changePreparationPrice = (data) => {
    dispatch(setPreparationPrice({value: data.preparationPrice}))
    changeBendingParams(data)
  }

  const debouncedPreparationPriceChange = useCallback(
    debounce(data => changePreparationPrice(data), 800),
    []
  );

  const changeBendingPreparationPrice = () => {
    debouncedPreparationPriceChange({
      ...bendingForm.current.getFieldsValue()
    })
  }

  /*------- Average Preparation Time -------*/
  const changeAveragePreparationTime = (data) => {
    dispatch(setAveragePreparationTime({value: data.averagePreparationTime}))
    changeBendingParams(data)
  }

  const debouncedAveragePreparationTimeChange = useCallback(
    debounce(data => changeAveragePreparationTime(data), 800),
    []
  );

  const changeBendingAveragePreparationTime = () => {
    debouncedAveragePreparationTimeChange({
      ...bendingForm.current.getFieldsValue()
    })
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"} bodyStyle={{paddingRight: 0}}>
        <div style={{height: 'calc(100vh - 120px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
          {bendingData && Object.keys(bendingData).length === 0 ? <div/>
            :
          <Form
            ref={bendingForm}
          >
            <Row>
              <Col xxl={12} span={24}>
                <div className={"switchContainer"}>
                  <span style={{marginRight: 30, marginBottom: 6}}>Do you offer Bending service?</span>
                  <span>No</span>
                  <Switch checked={bendingData.hasBending}
                          onChange={triggerSlideToggle}
                          style={{marginLeft: 6, marginRight: 6}}
                          disabled={disableSwitching}
                  />
                  <span>Yes</span>
                </div>
              </Col>
            </Row>

            <Divider style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 0"}}/>
            {bendingData.hasBending &&
            <div>
              <Row>
                <Col xxl={12} span={24}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <Form.Item
                      label="Maximum bending length:"
                      name="maxLength"
                      style={{marginBottom: 10, marginTop: 10}}
                      initialValue={bendingData.maxLength}
                    >
                      <InputNumber
                        style={{minWidth: 100, marginLeft: 5}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 1}
                        parser={value => value ? value : 1}
                        onChange={changeBendingMaxLength}
                      />
                    </Form.Item>
                    <Typography.Text className={"inputInfoText"} style={{marginLeft: 12}}>mm</Typography.Text>
                  </div>
                </Col>
              </Row>

              <Divider style={{width: "100%", minWidth: "0%", margin: "8px 0 30px 0"}}/>

              <div className={"newLine"}>
                <div className={"customColumn"}>
                  <div style={{maxWidth: 1220}}>
                    <Tabs style={{maxWidth: 1220, marginLeft: -10, marginBottom: 20}}
                          type="card"
                          className={"laserSpeedTabs"}
                    >
                      {bendingData.bends.map((item, index) => (
                        <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                          <MaterialTableContainer tableText={"Cost of bending per punch and material group (€/punch)"}
                                                  xAxisText={"Available Thickness (mm)"}
                                                  yAxisText={"Material Grade"}
                                                  xAxisStyle={{marginTop: 12, marginBottom: -12}}
                          >
                            <ModalTable
                              columns={item.columns}
                              rows={item.rows}
                              allAvailable={item.allAvailable}
                              tabIndex={index}
                              onClick={handleBendModalOpen}
                              groupName={item.groupName}
                              updateTable={shouldUpdateBends}
                              setUpdateTable={setShouldUpdateBends}
                              activeLoadingTabs={activeLoadingBendsTabs}
                            />
                          </MaterialTableContainer>
                        </TabPane>
                      ))}
                    </Tabs>
                  </div>
                </div>
                {/*<div className={"customColumn"}>*/}
                {/*  <Tabs style={{maxWidth: 900, marginLeft: -10, marginBottom: 20}}*/}
                {/*        type="card"*/}
                {/*        className={"laserSpeedTabs"}*/}
                {/*        onChange={(tabId) => {setRadiusTableTabId(tabId)}}*/}
                {/*  >*/}
                {/*    {bends.map(item => (*/}
                {/*    <TabPane className={"materialsTabPane"} tab={item.groupName} key={item.id}>*/}
                {/*      <MaterialTableContainer tableText={"Available bending Radius relative to thickness and material group:"}*/}
                {/*                              xAxisText={"Available Thickness (mm)"}*/}
                {/*                              yAxisText={"Material Grade"}*/}
                {/*                              xAxisStyle={{marginTop: 12, marginBottom: -12}}*/}
                {/*      >*/}
                {/*        <ModalTable columns={item.columns} rows={item.rows} allAvailable={item.allAvailable} onButtonClick={handleRadiusModalOpen}/>*/}
                {/*      </MaterialTableContainer>*/}
                {/*    </TabPane>*/}
                {/*    ))}*/}
                {/*  </Tabs>*/}
                {/*</div>*/}
              </div>

              <Divider style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 0"}}/>

              <Row gutter={16}>
                <Col xxl={12} span={24}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <Form.Item
                      label="Preparation price:"
                      name="preparationPrice"
                      style={{marginBottom: 10, marginTop: 10}}
                      initialValue={bendingData.preparationPrice}
                    >
                      <InputNumber
                        style={{minWidth: 30, marginLeft: 5}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={changeBendingPreparationPrice}
                      />
                    </Form.Item>
                    <Typography.Text className={"inputInfoText"} style={{marginLeft: 12}}>€/h</Typography.Text>
                  </div>

                  <div style={{display: "flex", alignItems: "center"}}>
                    <Form.Item
                      label="Average preparation time:"
                      name="averagePreparationTime"
                      style={{marginBottom: 10, marginTop: 10}}
                      initialValue={bendingData.averagePreparationTime}
                    >
                      <InputNumber
                        style={{minWidth: 30, marginLeft: 5}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={changeBendingAveragePreparationTime}
                      />
                    </Form.Item>
                    <Typography.Text className={"inputInfoText"} style={{marginLeft: 12}}>min</Typography.Text>
                  </div>

                  <Divider style={{width: "50%", minWidth: "0%", margin: "8px 0 8px 0"}}/>
                </Col>
              </Row>
            </div>
            }
          </Form>
          }
        </div>
      </Card>
      <Modal visible={mainModalVisible}
             onCancel={handleMainModalClose}
             title={selectedDataType === "bends" ? "Available bends" : "Available radii"}
             footer={<div style={{ height: 20 }}/>}
             centered={true}
             bodyStyle={{ height: 660 }}
             destroyOnClose={true}
             zIndex={1}
             width={550}
      >
        <React.Fragment>
          <div style={{ fontWeight: 500 }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <div>Material Grade: <Trans>{selectedFieldData.materialGrade}</Trans></div>
                <div>Material Thickness: {selectedFieldData.materialThickness} mm</div>
              </div>
              <div>
                {multiDeleteMode ?
                  (
                    <div style={{ display: "flex", flexDirection: "row"}}>
                      <Button type={"danger"}
                              onClick={() => handleMultiBendDelete()}
                              style={{ marginRight: 6 }}
                              disabled={selectedDataType === "bends" ?
                                (multiDeleteBendSelection.length === 0 || isRemovingBend) :
                                multiDeleteRadiusSelection.length === 0}>
                        <span className={"inputInfoText"}>Delete</span>
                      </Button>
                      <Button onClick={() => {
                                setMultiDeleteMode(false)
                                setMultiDeleteBendSelection([]);
                                setMultiDeleteRadiusSelection([]);
                              }}
                              disabled={isRemovingBend}
                      >
                        <span className={"inputInfoText"}>Cancel</span>
                      </Button>
                    </div>
                  )
                  :
                  (
                    <div>
                      {selectedDataType === "bends" ?
                        <Button type={"primary"} style={{ marginRight: 6 }}
                                onClick={() => {setAddBendModalVisible(true)}}
                        >
                          <span className={"inputInfoText"}>Add bend</span>
                        </Button>
                      :
                        <Button type={"primary"} style={{ marginRight: 6 }}
                                onClick={() => {setAddRadiusModalVisible(true)}}
                        >
                          <span className={"inputInfoText"}>Add radius</span>
                        </Button>}
                      <Button type={"danger"} onClick={() => {
                        setMultiDeleteMode(prevState => !prevState)
                      }}>
                        <span className={"inputInfoText"}>Delete multiple</span>
                      </Button>
                    </div>
                  )}
              </div>
            </div>
            <Spin spinning={isFetchingSingleMaterialBends || isRemovingBend}
                  indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-25%, -50%)" }} spin />}
            >
              <div className={"modalDataContainer"}>
                {selectedDataType === "bends" ?
                  (bendingBendsData.length === 0 ?
                    (
                      <div style={{position: "relative", top: "25%"}}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Bends"} />
                      </div>
                    )
                    :
                    bendingBendsData.map(item => (
                      <div className={"checkboxStyle"} key={item.id}>
                        {multiDeleteMode && <div className={"multiDeleteCheckbox"}>
                          <Checkbox onChange={() => handleMultiDeleteCheckBend(item)}/>
                        </div>}
                        <Card className={"bendCard"}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                              <div>Length: {item.length} mm</div>
                              <div>Price per bend: {item.price} €</div>
                            </div>
                            <div style={{ display: "flex"}}>
                              <Button onClick={() => {handleEditBend(item)}} disabled={multiDeleteMode}>
                                <EditOutlined style={{ color: "rgba(0, 21, 41, 0.6)" }}/>
                              </Button>
                              <Button disabled={multiDeleteMode} onClick={() => {
                                DeletionModal(deleteBendModalVisible,
                                  "Remove Bend",
                                  "Are you sure you want to remove this bend",
                                  ()=>{setDeleteBendModalVisible(false)},
                                  ()=>{handleSingleBendDelete(item)},
                                  "Confirm",
                                  "Cancel",
                                  {loading: isRemovingBend}
                                )
                              }}>
                                <DeleteOutlined style={{ color: multiDeleteMode ? "grey" : "red"}}/>
                              </Button>
                            </div>
                          </div>
                        </Card>
                      </div>
                    )))
                :
                  (radiiData === [] ?
                    <span>No radii available.</span>
                    :
                    radiiData.map(item => (
                      <div className={"checkboxStyle"} key={item.radiusId}>
                        {multiDeleteMode && <div className={"multiDeleteCheckbox"}>
                          <Checkbox onChange={() => handleMultiDeleteCheckRadius(item)}/>
                        </div>}
                        <Card className={"bendCard"}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                              <div>Radius: {item.radius} mm</div>
                            </div>
                            <div style={{ display: "flex"}}>
                              <Button onClick={() => {handleEditRadius(item)}} disabled={multiDeleteMode}>
                                <EditOutlined style={{ color: "rgba(0, 21, 41, 0.6)" }}/>
                              </Button>
                              <Button disabled={multiDeleteMode} onClick={() => {
                                DeletionModal(deleteRadiusModalVisible,
                                  "Remove Radius",
                                  "Are you sure you want to remove this radius",
                                  ()=>{},
                                  ()=>{})
                              }}>
                                <DeleteOutlined style={{ color: "red" }}/>
                              </Button>
                            </div>
                          </div>
                        </Card>
                      </div>
                  )))
                }
              </div>
            </Spin>
          </div>
        </React.Fragment>
      </Modal>
      <Modal visible={editBendModalVisible}
             onCancel={handleEditBendModalClose}
             title="Edit bend parameters"
             width={400}
             footer={
               <div align={'right'}>
                <Button
                  type={"primary"}
                  style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
                  form={"edit-bend"}
                  key={"submit"}
                  htmlType={"submit"}
                  loading={isEditingBend}
                >
                  Save Changes
                </Button>
              </div>}
             centered={true}
             bodyStyle={{ height: 132 }}
             zIndex={2}
             destroyOnClose={true}
      >
        <React.Fragment>
          <div className={"inputInfoText"}>
            <Form form={editBendForm}
                  name={'edit-bend'}
                  onFinish={submitEditBendForm}
            >
              <div style={{ display: "flex", flexDirection: "row"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 134}}>
                  Length:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'length'}
                    initialValue={currentBend.length}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {
                        required: true,
                        message: 'Input required!',
                      },
                    ]}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      type={"number"}
                      suffix={<span className={"inputInfoText"}>mm</span>}
                      min={1}
                    />
                  </Form.Item>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 134}}>
                  Price per bend:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'price'}
                    initialValue={currentBend.price}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {
                        required: true,
                        message: 'Input required!',
                      },
                    ]}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      type={"number"}
                      suffix={<span className={"inputInfoText"}>€</span>}
                      min={0}
                      step={0.01}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </React.Fragment>
      </Modal>

      <Modal visible={addBendModalVisible}
             onCancel={handleAddBendModalClose}
             title="Add new bend"
             width={400}
             footer={
               <div align={'right'}>
                 <Button
                   type={"primary"}
                   style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
                   form={"add-bend"}
                   key={"submit"}
                   htmlType={"submit"}
                   loading={isAddingNewBend}
                 >
                   Add bend
                 </Button>
               </div>}
             centered={true}
             bodyStyle={{ height: 132 }}
             zIndex={2}
             destroyOnClose={true}
      >
        <React.Fragment>
          <div className={"inputInfoText"}>
            <Form form={addBendForm}
                  name={'add-bend'}
                  onFinish={submitAddBendForm}
            >
              <div style={{ display: "flex", flexDirection: "row"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 134}}>
                  Length:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'length'}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {
                        required: true,
                        message: 'Input required!',
                      },
                    ]}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      type={"number"}
                      placeholder={"Bending length"}
                      suffix={<span className={"inputInfoText"}>mm</span>}
                      min={1}
                      max={bendingData.maxLength}
                    />
                  </Form.Item>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 134}}>
                  Price per bend:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'price'}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {
                        required: true,
                        message: 'Input required!',
                      },
                    ]}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      placeholder={"Bending price"}
                      type={"number"}
                      suffix={<span className={"inputInfoText"}>€</span>}
                      min={0}
                      step={0.01}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </React.Fragment>
      </Modal>

      <Modal visible={editRadiusModalVisible}
             onCancel={handleEditRadiusModalClose}
             title="Edit radius value"
             width={400}
             footer={
               <div align={'right'}>
                 <Button
                   type={"primary"}
                   style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
                   form={"edit-radius"}
                   key={"submit"}
                   htmlType={"submit"}
                 >
                   Save Changes
                 </Button>
               </div>}
             centered={true}
             bodyStyle={{ height: 82 }}
             zIndex={2}
             destroyOnClose={true}
      >
        <React.Fragment>
          <div className={"inputInfoText"}>
            <Form form={editRadiusForm}
                  name={'edit-radius'}
                  onFinish={submitEditRadiusForm}
            >
              <div style={{ display: "flex", flexDirection: "row"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 96}}>
                  Radius value:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'radius'}
                    initialValue={currentRadius.radius}
                    validateTrigger={"onSubmit"}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      type={"number"}
                      suffix={<span className={"inputInfoText"}>mm</span>}
                      min={1}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </React.Fragment>
      </Modal>

      <Modal visible={addRadiusModalVisible}
             onCancel={handleAddRadiusModalClose}
             title="Add radius"
             width={400}
             footer={
               <div align={'right'}>
                 <Button
                   type={"primary"}
                   style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
                   form={"add-radius"}
                   key={"submit"}
                   htmlType={"submit"}
                 >
                   Add radius
                 </Button>
               </div>}
             centered={true}
             bodyStyle={{ height: 82 }}
             zIndex={2}
             destroyOnClose={true}
      >
        <React.Fragment>
          <div className={"inputInfoText"}>
            <Form form={submitAddRadiusForm}
                  name={'add-radius'}
                  onFinish={submitAddRadiusForm}
            >
              <div style={{ display: "flex", flexDirection: "row"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 96}}>
                  Radius value:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'radius'}
                    initialValue={10}
                    validateTrigger={"onSubmit"}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      type={"number"}
                      suffix={<span className={"inputInfoText"}>mm</span>}
                      min={1}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </React.Fragment>
      </Modal>

    </React.Fragment>
  )
}