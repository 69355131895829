import {
  MATERIALS_FETCHING_AVAILABLE_MATERIALS,
  MATERIALS_SET_AVAILABLE_MATERIALS,
  MATERIALS_UPDATE_AVAILABLE_MATERIALS,
  MATERIALS_FETCHING_MATERIAL_PRICES,
  MATERIALS_SET_MATERIAL_PRICES,
  MATERIALS_UPDATE_MATERIAL_PRICE,
  MATERIALS_SET_SINGLE_MATERIAL_PRICES,
  MATERIALS_FETCHING_MATERIAL_PLATES,
  MATERIALS_SET_MATERIAL_PLATES,
  MATERIALS_SET_SINGLE_MATERIAL_PLATES,
  MATERIALS_SET_MATERIAL_PLATES_DATA,
  MATERIALS_FETCHING_SINGLE_MATERIAL_PLATES,
  MATERIALS_RESET_PLATES_DATA,
  MATERIALS_IS_ADDING_NEW_PLATE,
  MATERIALS_IS_EDITING_PLATE,
  MATERIALS_IS_DELETING_PLATES,
  MATERIALS_FETCHING_MATERIAL_MARGINS,
  MATERIALS_SET_MATERIAL_MARGINS, MATERIALS_UPDATE_MATERIAL_MARGIN
} from '../actions/actionTypes'
import initialState from './initialState'

const materialsReducer = (state = initialState.materials, action) => {
  switch (action.type) {
    case MATERIALS_FETCHING_AVAILABLE_MATERIALS:
      return {
        ...state,
        isFetchingAvailableMaterials: action.payload,
      }

    case MATERIALS_SET_AVAILABLE_MATERIALS:
      return {
        ...state,
        availableMaterials: action.payload,
      }

    case MATERIALS_UPDATE_AVAILABLE_MATERIALS: {
      let tmp = [...state.availableMaterials]
      tmp[action.payload.tab].rows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        availableMaterials: [...tmp]
      }
    }

    case MATERIALS_FETCHING_MATERIAL_PRICES:
      return {
        ...state,
        isFetchingMaterialPrices: action.payload,
      }

    case MATERIALS_SET_MATERIAL_PRICES:
      return {
        ...state,
        materialPrices: action.payload,
      }

    case MATERIALS_SET_SINGLE_MATERIAL_PRICES:
      return {
        ...state,
        materialPrices: state.materialPrices.map(item => {
          if(item.groupName === action.payload.groupName) {
            return action.payload
          }
          return item
        }),
      }

    case MATERIALS_UPDATE_MATERIAL_PRICE: {
      let tmp = [...state.materialPrices]
      tmp[action.payload.tab].rows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        materialPrices: [...tmp]
      }
    }

    case MATERIALS_FETCHING_MATERIAL_PLATES:
      return {
        ...state,
        isFetchingMaterialPlates: action.payload,
      }

    case MATERIALS_SET_MATERIAL_PLATES:
      return {
        ...state,
        materialPlates: action.payload,
      }

    case MATERIALS_SET_SINGLE_MATERIAL_PLATES:
      return {
        ...state,
        materialPlates: state.materialPlates.map(item => {
          if(item.groupName === action.payload.groupName) {
            return action.payload
          }
          return item
        }),
      }

    case MATERIALS_SET_MATERIAL_PLATES_DATA:
      return {
        ...state,
        materialPlatesData: action.payload,
      }

    case MATERIALS_RESET_PLATES_DATA:
      return {
        ...state,
        materialPlatesData: [],
      }

    case MATERIALS_FETCHING_SINGLE_MATERIAL_PLATES:
      return {
        ...state,
        isFetchingSingleMaterialPlates: action.payload,
      }

    case MATERIALS_IS_ADDING_NEW_PLATE:
      return {
        ...state,
        isAddingNewPlate: action.payload,
      }

    case MATERIALS_IS_EDITING_PLATE:
      return {
        ...state,
        isEditingPlate: action.payload,
      }

    case MATERIALS_IS_DELETING_PLATES:
      return {
        ...state,
        isRemovingPlate: action.payload,
      }
    // ---------------------------------------------------------
    case MATERIALS_FETCHING_MATERIAL_MARGINS:
      return {
        ...state,
        isFetchingMaterialMargins: action.payload,
      }

    case MATERIALS_SET_MATERIAL_MARGINS:
      return {
        ...state,
        materialMargins: action.payload,
      }

    case MATERIALS_UPDATE_MATERIAL_MARGIN: {
      let tmp = [...state.materialMargins]
      tmp[action.payload.tab].rows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        materialMargins: [...tmp]
      }
    }

    default:
      return state
  }
}

export default materialsReducer
