import {
  BULK_ORDER_SET_PART_COLOR,
  BULK_ORDER_REMOVE_PART_COLOR,
  BULK_ORDER_SET_PART_QUANTITY,
  BULK_ORDER_SET_PART_MATERIAL_SURCHARGE,
  BULK_ORDER_SET_PART_NOTE,
  BULK_ORDER_SET_PART_MATERIAL,
  BULK_ORDER_SET_PART_THICKNESS,
  BULK_ORDER_SET_PART_STANDARD,
  BULK_ORDER_RESET_TO_PROCESS,
  BULK_ORDER_REMOVE_ITEM,
  BULK_ORDER_REMOVE_MULTIPLE_ITEMS,
  BULK_ORDER_APPLY_MULTIPLE_ITEMS,
  BULK_ORDER_SET_MANUAL_PART_TYPE,
  BULK_ORDER_CLEAR_ORDER,
  BULK_ODER_SET_BULK_ORDER,
  BULK_ODER_RESET_BULK_ODER,
  BULK_ORDER_UPDATE_PART,
  BULK_ORDER_INCREMENT_PROCESSED,
  BULK_ORDER_INCREMENT_UPLOADED,
  BULK_ORDER_INCREMENT_TO_PROCESS,
  BULK_ORDER_ADD_NEW_PART,
  BULK_ORDER_UPDATE_ORDER_PRICE,
  BULK_ORDER_UPDATE_PART_DATA,
  BULK_ORDER_CHANGE_PART_TO_ERROR,
  BULK_ORDER_REMOVE_ERROR_PARTS,
  BULK_ORDER_UPDATE_PART_FILE_PATH,
  BULK_ORDER_UPDATE_PART_PRICE,
  BULK_ORDER_SET_PART_DRAWING, BULK_ORDER_UPDATE_MANY_PART_PRICES
} from '../actions/actionTypes'

import initialState from './initialState'
import {getAvailableThicknesses} from "../utils/material";

const bulkOrderReducer = (state = initialState.bulkOrder, action) => {
  switch (action.type) {
    case BULK_ODER_SET_BULK_ORDER:
      return {
        ...state,
        ...action.payload,
      }

    case BULK_ORDER_UPDATE_ORDER_PRICE:
      return {
        ...state,
        ...action.payload,
      }

    case BULK_ODER_RESET_BULK_ODER:
      return {
        toProcess: 0,
        currentlyUploaded: 0,
        currentlyProcessed: 0
      }

    case BULK_ORDER_ADD_NEW_PART:
      return {
        ...state,
        parts: [...state.parts, action.payload]
      }

    case  BULK_ORDER_INCREMENT_TO_PROCESS:
      return {
        ...state,
        toProcess: state.toProcess + 1
      }

    case BULK_ORDER_INCREMENT_UPLOADED:
      return {
        ...state,
        currentlyUploaded: state.currentlyUploaded + 1
      }

    case BULK_ORDER_INCREMENT_PROCESSED:
      return {
        ...state,
        currentlyProcessed: state.currentlyProcessed + 1
      }

    case BULK_ORDER_UPDATE_PART:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.fileId === action.payload.fileId) {
            return {
              ...item,
              ...action.payload,
              availableThicknesses: item.availableThicknesses || []
            }
          }
          return item;
        })
      }

    case BULK_ORDER_UPDATE_PART_DATA:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            const tmp = {...action.payload.data}
            if(item?.stepData?.filePaths && tmp?.stepData?.filePaths) {
              tmp.stepData.filePaths.pngFilePath = tmp.stepData.filePaths.pngFilePath || item.stepData.filePaths.pngFilePath
              tmp.stepData.filePaths.foldedStlFilePath = tmp.stepData.filePaths.foldedStlFilePath || item.stepData.filePaths.foldedStlFilePath
              tmp.stepData.filePaths.unfoldedStlFilePath = tmp.stepData.filePaths.unfoldedStlFilePath || item.stepData.filePaths.unfoldedStlFilePath
            }
            if(!(tmp.fileType === "step" && tmp.processingType === "automatic")) {
              tmp.availableThicknesses =  getAvailableThicknesses(tmp.fileType, tmp.processingType, state.materials, tmp.selectedMaterial, tmp.materials)
            }
            return {
              ...item,
              ...tmp
            }
          }
          return item;
        })
      }

    case BULK_ORDER_CHANGE_PART_TO_ERROR:
      let newErrorList = [...state.errorList, action.payload]
      return {
        ...state,
        parts: state.parts.filter(item => item.id !== action.payload.id),
        errorList: newErrorList
      }

    case BULK_ORDER_REMOVE_ERROR_PARTS:
      return {
        ...state,
        errorList: []
      }

    case BULK_ORDER_UPDATE_PART_FILE_PATH:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            let tmp = {...item}
            if(!item?.stepData?.filePaths) {
              tmp.stepData = {filePaths: {}}
            }
            tmp.stepData.filePaths[action.payload.filePathName] = action.payload.filePathValue
            return tmp
          }
          return item;
        })
      }

    case BULK_ORDER_SET_PART_COLOR:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              coating: {
                hasCoating: true,
                colorRAL: action.payload.selectedRAL,
                colorGlossId: action.payload.selectedGloss,
                ralRow: action.payload.selectedRow,
                ralHex: action.payload.ralHex,
              }
            }
          }
          return item;
        })
      }

    case BULK_ORDER_REMOVE_PART_COLOR:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              coating: {
                hasCoating: false,
                colorRAL: undefined,
                colorGlossId: undefined,
                ralRow: undefined,
                ralHex: undefined,
              }
            }
          }
          return item;
        })
      }

    case BULK_ORDER_SET_PART_QUANTITY:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              quantity: action.payload.quantity
            }
          }
          return item;
        })
      }

    case BULK_ORDER_SET_PART_MATERIAL_SURCHARGE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              materialSurchargePerPiece: action.payload.materialSurchargePerPiece
            }
          }
          return item;
        })
      }

    case BULK_ORDER_SET_PART_NOTE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              note: action.payload.note
            }
          }
          return item;
        })
      }

    case BULK_ORDER_SET_PART_MATERIAL:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              selectedMaterial: {
                grade: action.payload.material.grade,
                materialId: action.payload.material.selectedMaterial.id,
                thickness: action.payload.material.selectedMaterial.thickness
              },
              availableThicknesses: [...action.payload.material.thickness]
            }
          }
          return item;
        })
      }

    case BULK_ORDER_SET_PART_THICKNESS:
      return {
        ...state,
        parts: state.parts.map(item=>{
          if(item.id === action.payload.id) {
            return {
              ...item,
              selectedMaterial: {
                ...item.selectedMaterial,
                materialId: action.payload.thickness.id,
                thickness: action.payload.thickness.thickness,
                isAvailable: true
              },
            }
          }
          return item;
        })
      }

    case BULK_ORDER_SET_PART_STANDARD:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              standard: action.payload.standard
            }
          }
          return item;
        })
      }

    case BULK_ORDER_RESET_TO_PROCESS:
      return {
        ...state,
        toProcess: 0,
        currentlyUploaded: 0,
        currentlyProcessed: 0
      }

    case BULK_ORDER_REMOVE_ITEM:
      return {
        ...state,
        parts: state.parts.filter(item => item.id !== action.payload.id)
      }

    case BULK_ORDER_REMOVE_MULTIPLE_ITEMS:
      return {
        ...state,
        parts: state.parts.filter(item => !action.payload.selected[item.id])
      }

    case BULK_ORDER_APPLY_MULTIPLE_ITEMS:
      return {
        ...state,
        parts: state.parts.map(item=>{
          if(action.payload.applyingChanges[item.id]) {
            let changes = action.payload.applyingChanges[item.id]
            return {
              ...item,
              selectedMaterial: changes.material.materialId ? changes.material : item.selectedMaterial,
              availableThicknesses: changes.material.materialId ? changes.material.availableThicknesses : item.availableThicknesses,
              quantity: changes.quantity || item.quantity,
              materialSurchargePerPiece: changes.materialSurchargePerPiece || item.materialSurchargePerPiece,
              coating: {
                hasCoating: changes.coating.hasCoating || item.coating.hasCoating,
                colorRAL: changes.coating.hasCoating ? changes.coating.colorRAL : item.coating.colorRAL,
                colorGlossId: changes.coating.hasCoating ? changes.coating.colorGlossId : item.coating.colorGlossId,
                ralRow: changes.coating.hasCoating ? changes.coating.ralRow : item.coating.ralRow,
                ralHex: changes.coating.hasCoating ? changes.coating.ralHex : item.coating.ralHex,
              }
            }
          }
          return item;
        })
      }

    case BULK_ORDER_SET_MANUAL_PART_TYPE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              partType: action.payload.partType
            }
          }
          return item;
        })
      }

    case BULK_ORDER_UPDATE_PART_PRICE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            return {
              ...item,
              pricePerPiece: action.payload.pricePerPiece,
              totalPrice: action.payload.totalPrice,
              operationPrices: action.payload?.operationPrices || item.operationPrices
            }
          }
          return item;
        })
      }

    case BULK_ORDER_UPDATE_MANY_PART_PRICES:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (action.payload.parts[item.id]) {
            return {
              ...item,
              pricePerPiece: action.payload.parts[item.id]?.pricePerPiece,
              totalPrice: action.payload.parts[item.id]?.totalPrice,
              operationPrices: action.payload.parts[item.id]?.operationPrices || item.operationPrices
            }
          }
          return item;
        })
      }

    case BULK_ORDER_SET_PART_DRAWING:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            return {
              ...item,
              drawingPDFFile: {...action.payload.data}
            }
          }
          return item;
        })
      }

    case BULK_ORDER_CLEAR_ORDER:
      return {
        toProcess: 0,
        currentlyUploaded: 0,
        currentlyProcessed: 0,
        ...action.payload
      }

    default:
      return state
  }
}

export default bulkOrderReducer