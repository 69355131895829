import {
  NESTING_REMOVE_NESTED_PLATE,
  NESTING_SET_ACCEPTED_JOBS,
  NESTING_SET_COMPLETED_JOBS,
  NESTING_SET_CURRENT_JOB,
  NESTING_SET_FETCHING_ACCEPTED_JOBS,
  NESTING_SET_FETCHING_COMPLETED_JOBS,
  NESTING_SET_IS_CURRENTLY_NESTING,
  NESTING_SET_IS_FETCHING_CURRENT_JOB,
  NESTING_SET_IS_FETCHING_ORDERS,
  NESTING_SET_IS_FETCHING_PLATE_DATA,
  NESTING_SET_ORDERS,
  NESTING_SET_PLATE_DATA
} from "./actionTypes";
import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import {forceLogoutUser} from "./authActions";

const fetchAcceptedOrdersForNesting = () => dispatch => {
  dispatch({
    type: NESTING_SET_IS_FETCHING_ORDERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/order/accepted/for-nesting`)
    .then(response => {
      dispatch({
        type: NESTING_SET_ORDERS,
        payload: response.data.orders,
      })

      dispatch({
        type: NESTING_SET_IS_FETCHING_ORDERS,
        payload: false,
      })

      return response.data.orders
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: NESTING_SET_IS_FETCHING_ORDERS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}

const fetchCompletedNestingJobs = () => dispatch => {
  dispatch({
    type: NESTING_SET_FETCHING_COMPLETED_JOBS,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/nesting/completed`)
    .then(res => {
      dispatch({
        type: NESTING_SET_COMPLETED_JOBS,
        payload: res.data.plates
      })

      dispatch({
        type: NESTING_SET_FETCHING_COMPLETED_JOBS,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: NESTING_SET_FETCHING_COMPLETED_JOBS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}

const fetchAcceptedNestingJobs = () => dispatch => {
  dispatch({
    type: NESTING_SET_FETCHING_ACCEPTED_JOBS,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/nesting/accepted`)
    .then(res => {
      dispatch({
        type: NESTING_SET_ACCEPTED_JOBS,
        payload: res.data.plates
      })
      dispatch({
        type: NESTING_SET_FETCHING_ACCEPTED_JOBS,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: NESTING_SET_FETCHING_ACCEPTED_JOBS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}

const fetchCurrentNestingJob = () => dispatch => {
  dispatch({
    type: NESTING_SET_IS_FETCHING_CURRENT_JOB,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/nesting/current`)
    .then(response => {
      dispatch({
        type: NESTING_SET_CURRENT_JOB,
        payload: response.data,
      })

      dispatch({
        type: NESTING_SET_IS_FETCHING_CURRENT_JOB,
        payload: false,
      })
      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: NESTING_SET_IS_FETCHING_CURRENT_JOB,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}

const acceptJob = payload => dispatch => {
  return http
    .post(`${API_BASE_URL}/nesting/current/accept`, payload)
    .then(() => {
      dispatch({
        type: NESTING_REMOVE_NESTED_PLATE,
        payload: payload.plateId
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}

const completeJob = payload => dispatch => {
  return http
    .get(`${API_BASE_URL}/nesting/accepted/${payload.plateId}/complete`)
    .then(res => {
      // console.log(res)
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}

const scrapPlate = payload => dispatch => {
  return http
    .get(`${API_BASE_URL}/nesting/accepted/${payload.plateId}/scrap`)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}

const fetchPlateData = payload => dispatch => {
  dispatch({
    type: NESTING_SET_IS_FETCHING_PLATE_DATA,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/nesting/plate/${payload.plateState}/${payload.plateId}`)
    .then(response => {
      dispatch({
        type: NESTING_SET_PLATE_DATA,
        payload: response.data,
      })
      dispatch({
        type: NESTING_SET_IS_FETCHING_PLATE_DATA,
        payload: false,
      })
      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: NESTING_SET_IS_FETCHING_PLATE_DATA,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}

const checkCurrentNestingJob = () => dispatch => {
  return http
    .get(`${API_BASE_URL}/nesting/last-change-time`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      }
      throw error
    })
}

const reFetchCurrentNestingJob = () => dispatch => {
  return http
    .get(`${API_BASE_URL}/nesting/current`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      }
      throw error
    })
}

const setCurrentNestingJob = payload => dispatch => {
  dispatch({
    type: NESTING_SET_CURRENT_JOB,
    payload: payload,
  })
}

const startNewNestingJob = payload => dispatch => {
  dispatch({
    type: NESTING_SET_IS_CURRENTLY_NESTING,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/nesting`, {orders: payload.orders})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: NESTING_SET_IS_CURRENTLY_NESTING,
          payload: false,
        })
      }
      throw error
    })
}

export {
  fetchAcceptedOrdersForNesting,
  fetchCurrentNestingJob,
  fetchAcceptedNestingJobs,
  fetchCompletedNestingJobs,
  fetchPlateData,
  acceptJob,
  completeJob,
  scrapPlate,
  checkCurrentNestingJob,
  setCurrentNestingJob,
  startNewNestingJob,
  reFetchCurrentNestingJob
}