import React from "react";
import {formatDate, getManualType, getTotalSuggestedPrice} from "../../../utils/utility";
import writeXlsxFile from 'write-excel-file'
import {FileExcelOutlined} from "@ant-design/icons";
import {Button} from "antd";
import "./style.css";
import {translator} from "../../../translations/translators";


export const OrderExcelExportButton = (props) => {
  const { isEditing, parts, name, role, adjustment } = props;

  const exportWithWEF = () => {
    const fileName = name + "_" + formatDate(Date.now());
    const headerStyle = {
      backgroundColor: "#dee7e5",
      borderColor: "#000000",
      borderStyle: "thin",
      fontWeight: 600,
      color: "#000000",
      align: "left"
    }

    const getWidths = (schema, objects) => {
      return schema.map(schemaItem => {
        return Math.max(...objects.map(item => {
          if (schemaItem.value(item))
            return schemaItem.value(item).toString().length
          return 0
        }), Math.max(10, Math.round(schemaItem.column.length * 0.9)))
      })
    }

    if (role === "regularCustomer" || role === "corporateCustomer") {
      const objects = parts.map(item => {
        let name = (item.fileName || item.name)
        return {
          fileName: name.substring(0, name.lastIndexOf(".")),
          fileType: item.fileType ? (item.fileType).charAt(0).toUpperCase() + (item.fileType).slice(1) : "Manual",
          partType: getManualType(item.partType),
          material: translator(item.selectedMaterial?.grade) || "",
          thickness: item.selectedMaterial?.thickness || "",
          quantity: item.quantity,
          pricePerPiece: item.pricePerPiece.toFixed(5),
          totalPrice: item.totalPrice.toFixed(5)
        }
      })

      const schema = [
        {
          column: "Part name:",
          value: part => part.fileName || part.name,
          align: "left",
        },
        {
          column: "File type:",
          value: part => part.fileType,
          align: "left",
        },
        {
          column: "Part type:",
          value: part => part.partType,
          align: "left",
        },
        {
          column: "Material:",
          value: part => part.material,
          align: "left",
        },
        {
          column: "Quantity:",
          value: part => part.quantity,
          align: "right",
        },
        {
          column: "Thickness (mm):",
          value: part => part.thickness,
          align: "right",
        },
        {
          column: "Price per piece (€):",
          value: part => part.pricePerPiece,
          align: "right",
        },
        {
          column: "Total price (€):",
          value: part => part.totalPrice,
          align: "right",
        },
      ]
      const widths = getWidths(schema, objects)
      const schemaWithWidth = schema.map((item, index) => {
        return {
          ...item,
          width: widths[index]
        }
      })

      writeXlsxFile(objects, {
        schema: schemaWithWidth,
        fileName: fileName + ".xlsx",
        headerStyle: headerStyle,
      }).then(()=>{})

    } else if (role === "producerAdmin" || role === "producerSubAdmin") {
      const objects = parts.map(item => {
        let area = "";
        let volume = "";
        if(item.fileType === "step") {
          area = item.stepData?.partData?.area ? (item.stepData.partData.area / 1000000).toFixed(5) : ""
          volume = item.stepData?.partData?.volume ? (item.stepData.partData.volume / 1000000000).toFixed(5) : ""
        }
        if(item.fileType === "dxf") {
          area = item.dxfData?.partData?.area ? (item.dxfData.partData.area / 1000000).toFixed(5) : ""
          volume = item.dxfData?.partData?.area ? ((item.dxfData.partData.area * item.selectedMaterial?.thickness) / 1000000000).toFixed(5) : ""
        }

        const quantity = item.quantity
        const materialPrice = (item.operationPrices?.materialPricePerPiece) || 0
        const bendingPricePerPiece = (item.operationPrices?.bendingPricePerPiece * quantity + item.operationPrices?.bendingPreparationPrice) / quantity || 0
        const machiningPricePerPiece = (item.operationPrices?.machiningPricePerPiece * quantity + item.operationPrices?.machiningPreparationPrice) / quantity || 0
        const coatingPricePerPiece = (item.operationPrices?.coatingPricePerPiece) || 0
        const cuttingPricePerPiece = (item.operationPrices?.cuttingPricePerPiece * quantity + item.operationPrices?.cuttingPreparationPrice) / quantity || 0
        const suggestedPricePerPiece = getTotalSuggestedPrice(item?.operationPrices, quantity) || 0

        let name = (item.fileName || item.name)
        return {
          fileName: name.substring(0, name.lastIndexOf(".")),
          fileType: item.fileType ? (item.fileType).charAt(0).toUpperCase() + (item.fileType).slice(1) : "Manual",
          partType: getManualType(item.partType),
          processing: item.processingType.charAt(0).toUpperCase() + item.processingType.slice(1),
          material: translator(item.selectedMaterial?.grade) || "",
          materialPrice: materialPrice.toFixed(5),
          thickness: item.selectedMaterial?.thickness || "",
          area: area,
          volume: volume,
          quantity: item.quantity,
          numberOfBends: item.stepData?.partData?.numberOfBends || 0,
          bendingPricePerPiece: bendingPricePerPiece.toFixed(5),
          hasCoating: item.coating?.hasCoating ? "Yes" : "No",
          coatingColor: item.coating?.hasCoating ? ("RAL " + item.coating.colorRAL) : "",
          coatingPricePerPiece: coatingPricePerPiece.toFixed(5),
          numOfSlopedEdges: item.stepData?.partData?.numOfSlopedEdges || 0,
          numOfMachinedHoles: item.stepData?.partData?.numOfMachinedHoles || 0,
          machiningPricePerPiece: machiningPricePerPiece.toFixed(5),
          cuttingPricePerPiece: cuttingPricePerPiece.toFixed(5),
          suggestedPricePerPiece: suggestedPricePerPiece.toFixed(5),
          adjustedPricePerPiece: (suggestedPricePerPiece * (1 + adjustment)).toFixed(5),
          pricePerPiece: item.state !== "declined" ? item.pricePerPiece.toFixed(5) : (0).toFixed(5),
          totalPrice: item.state !== "declined" ? item.totalPrice.toFixed(5) : (0).toFixed(5)
        }
      })

      let schema = [
        {
          column: "Part name:",
          value: part => (part.fileName || part.name),
          align: "left",
        },
        {
          column: "File type:",
          value: part => part.fileType,
          align: "left",
        },
        {
          column: "Part type:",
          value: part => part.partType,
          align: "left",
        },
        {
          column: "Processing:",
          value: part => part.processing,
          align: "left",
        },
        {
          column: "Material:",
          value: part => part.material,
          align: "left",
        },
        {
          column: "Thickness (mm):",
          value: part => part.thickness,
          align: "right",
        },
        {
          column: "Area (m²):",
          value: part => part.area,
          align: "right",
        },
        {
          column: "Volume (m³):",
          value: part => part.volume,
          align: "right",
        },
        {
          column: "Bends:",
          value: part => part.numberOfBends,
          align: "right",
        },
        {
          column: "Has coating:",
          value: part => part.hasCoating,
          align: "left",
        },
        {
          column: "Coating color:",
          value: part => part.coatingColor,
          align: "left",
        },
        {
          column: "Sloped edges:",
          value: part => part.numOfSlopedEdges,
          align: "right",
        },
        {
          column: "Machined holes:",
          value: part => part.numOfMachinedHoles,
          align: "right",
        },
        {
          column: "Quantity:",
          value: part => part.quantity,
          align: "right",
        },
        {
          column: "Material 1pc (€):",
          value: part => part.materialPrice,
          align: "right",
        },
        {
          column: "Cutting 1pc (€):",
          value: part => part.cuttingPricePerPiece,
          align: "right",
        },
        {
          column: "Bending 1pc (€):",
          value: part => part.bendingPricePerPiece,
          align: "right",
        },
        {
          column: "Machining 1pc (€):",
          value: part => part.machiningPricePerPiece,
          align: "right",
        },
        {
          column: "Coating 1pc (€):",
          value: part => part.coatingPricePerPiece,
          align: "right",
        },
        {
          column: "Suggested price 1pc (€):",
          value: part => part.suggestedPricePerPiece,
          align: "right",
        },
        {
          column: "Selected price 1pc (€):",
          value: part => part.pricePerPiece,
          align: "right",
        },
        {
          column: "Total price (€):",
          value: part => part.totalPrice,
          align: "right",
        },
      ]

      if(adjustment !== 0) {
        const adjustValue = (adjustment * 100).toFixed(2)
        schema = [
          {
            column: "Part name:",
            value: part => (part.fileName || part.name),
            align: "left",
          },
          {
            column: "File type:",
            value: part => part.fileType,
            align: "left",
          },
          {
            column: "Part type:",
            value: part => part.partType,
            align: "left",
          },
          {
            column: "Processing:",
            value: part => part.processing,
            align: "left",
          },
          {
            column: "Material:",
            value: part => part.material,
            align: "left",
          },
          {
            column: "Thickness (mm):",
            value: part => part.thickness,
            align: "right",
          },
          {
            column: "Area (m²):",
            value: part => part.area,
            align: "right",
          },
          {
            column: "Volume (m³):",
            value: part => part.volume,
            align: "right",
          },
          {
            column: "Bends:",
            value: part => part.numberOfBends,
            align: "right",
          },
          {
            column: "Has coating:",
            value: part => part.hasCoating,
            align: "left",
          },
          {
            column: "Coating color:",
            value: part => part.coatingColor,
            align: "left",
          },
          {
            column: "Sloped edges:",
            value: part => part.numOfSlopedEdges,
            align: "right",
          },
          {
            column: "Machined holes:",
            value: part => part.numOfMachinedHoles,
            align: "right",
          },
          {
            column: "Quantity:",
            value: part => part.quantity,
            align: "right",
          },
          {
            column: "Material 1pc (€):",
            value: part => part.materialPrice,
            align: "right",
          },
          {
            column: "Cutting 1pc (€):",
            value: part => part.cuttingPricePerPiece,
            align: "right",
          },
          {
            column: "Bending 1pc (€):",
            value: part => part.bendingPricePerPiece,
            align: "right",
          },
          {
            column: "Machining 1pc (€):",
            value: part => part.machiningPricePerPiece,
            align: "right",
          },
          {
            column: "Coating 1pc (€):",
            value: part => part.coatingPricePerPiece,
            align: "right",
          },
          {
            column: "Suggested price 1pc (€):",
            value: part => part.suggestedPricePerPiece,
            align: "right",
          },
          {
            column: "Adjustment (%):",
            value: () => adjustValue,
            align: "right",
          },
          {
            column: "Adjusted suggested price 1pc (€):",
            value: part => part.adjustedPricePerPiece,
            align: "right",
          },
          {
            column: "Selected price 1pc (€):",
            value: part => part.pricePerPiece,
            align: "right",
          },
          {
            column: "Total price (€):",
            value: part => part.totalPrice,
            align: "right",
          },
        ]
      }

      const widths = getWidths(schema, objects)
      const schemaWithWidth = schema.map((item, index) => {
        return {
          ...item,
          width: widths[index]
        }
      })

      writeXlsxFile(objects, {
        schema: schemaWithWidth,
        fileName: fileName + ".xlsx",
        headerStyle: headerStyle,
      }).then(()=>{})
    }
  }

  return (
    <Button
      type="primary"
      className={"exportExcelButton " + (isEditing ? "editingDisabled" : "")}
      disabled={isEditing}
      style={{
        marginTop: 5
      }}
      onClick={() => {
        exportWithWEF();
      }}
    >
      <FileExcelOutlined style={{fontSize: 14}}/>
      Export Excel
    </Button>
  )
}