import {Button, Card, Modal, Spin, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";

import {Trans} from "react-i18next";

import "./style.css"
import {S3_BUCKET_URL} from "../../../../constants";
import {useSelector} from "react-redux";
import {NestedPartsList} from "../NestedPartsList";
import {formatTableDate} from "../../../../utils/utility";


export const NestedPlateModal = (props) => {
  const {isPlateViewVisible, onClose, plateData } = props

  const [showMargins, setShowMargins] = useState(true)
  const [drawingLoaded, setDrawingLoaded]  = useState(false)

  const isFetchingPlateData = useSelector(state => state.nesting.isFetchingPlateData);

  useEffect(()=>{
    if(!isPlateViewVisible) {
      setShowMargins(true)
      setDrawingLoaded(false)
      let drawing = document.getElementById('plateDrawing')
      if(drawing) {
        let svg = drawing.getElementsByTagName('svg')
        if(svg.length > 0) {
          drawing.removeChild(svg[0])
        }
      }
      let margin = document.getElementById("marginDrawing")
      if(margin) {
        let svg = margin.getElementsByTagName('svg')
        if(svg.length > 0) {
          margin.removeChild(svg[0])
        }
      }
    }
  }, [isPlateViewVisible])

  useEffect(()=>{
    if(plateData && !drawingLoaded) {
      let xhr1 = new XMLHttpRequest();
      xhr1.open("GET",`${S3_BUCKET_URL}${plateData.svgPath}`,false);

      let xhr2 = new XMLHttpRequest();
      xhr2.open("GET",`${S3_BUCKET_URL}${plateData.marginPath}`,false);

      xhr1.overrideMimeType("image/svg+xml");
      xhr1.onload = function(e) {
        document.getElementById("plateDrawing").appendChild(xhr1.responseXML.documentElement);
        setDrawingLoaded(true)
      };

      xhr2.overrideMimeType("image/svg+xml");
      xhr2.onload = function(e) {
        document.getElementById("marginDrawing").appendChild(xhr2.responseXML.documentElement);
      };

      xhr1.send();
      xhr2.send();
    }
  }, [plateData])

  return (
    <Modal
      visible={isPlateViewVisible}
      footer={null}
      centered={true}
      maskClosable={true}
      closable={false}
      destroyOnClose={true}
      forceRender={true}
      width={"98%"}
      bodyStyle={{height: "90vh", overflow: "hidden"}}
      onCancel={()=>{
        setDrawingLoaded(false)
        onClose()
      }}
    >
      <div style={{display: "flex", width: "100%", height: "100%"}}>
        <div style={{position: "absolute", top: 20, right: 20}}>
          <Button
            style={{
              boxShadow: '0 4px 8px 2px rgba(0, 0, 0, 0.12)',
              zIndex: 1001
            }}
            onClick={()=>{
              setDrawingLoaded(false)
              onClose()
            }}
          >
            <CloseOutlined />Close
          </Button>
        </div>
        <Spin
          spinning={!drawingLoaded || isFetchingPlateData}
          wrapperClassName={"drawingSpinWrapper"}
          indicator={
            <div style={{marginTop: -72, marginLeft: -36}}>
              <LoadingOutlined style={{ fontSize: 72 }} spin />
            </div>
          }
        >
        <div style={{width: "100%", height: "100%", paddingRight: 15}}>
          <div style={{display: "flex", alignItems: "center", width: "100%", height: "100%", border: "1px solid rgb(215, 215, 215)", borderRadius: 5}}>
            <div style={{position: "relative", top: 0, left: 0, width: "100%"}}>
              <div>
                <figure>
                  <div id={"plateDrawing"} style={{position: "relative", width: "100%", maxHeight: "calc(100vh - 150px)", zIndex: 1001, top: 0, left: 0}}/>
                </figure>
                <figure>
                  <div id={"marginDrawing"} style={{position: "absolute", width: "100%", maxHeight: "calc(100vh - 150px)", zIndex: 1000, top: 0, left: 0, opacity: showMargins ? 1 : 0}}/>
                </figure>
              </div>
            </div>
          </div>
        </div>
        </Spin>
        <Spin
          spinning={isFetchingPlateData}
          wrapperClassName={"detailsSpinWrapper"}
          indicator={
            <div style={{marginTop: -108, marginLeft: -36}}>
              <LoadingOutlined style={{ fontSize: 72 }} spin />
            </div>
          }
        >
        <div style={{width: "100%", minWidth: 400, height: "100%"}}>
          <div style={{zIndex: 1001, marginTop: -35, marginBottom: 11}}>
            <Switch checked={showMargins} disabled={!drawingLoaded || isFetchingPlateData} onChange={toggle => setShowMargins(toggle)} checkedChildren={<span style={{fontSize: 13}}>Margin</span>} unCheckedChildren={<span style={{fontSize: 13}}>Margin</span>} defaultChecked={showMargins} />
          </div>
          <Card
            className={"whiteModalCard"}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 5,
              border: "1px solid rgb(215, 215, 215)",
              boxShadow: "rgba(0, 0, 0, 0.07) 0 2px 4px 1px"
            }}
            bodyStyle={{
              overflowY: "scroll",
              height: "100%",
              borderRadius: 5,
              paddingRight: 10,
              paddingBottom: 10,
              paddingTop: 15
            }}
          >
            {plateData &&
              <div>
                <div style={{fontSize: 20, fontWeight: 600, marginBottom: 10}}>Plate {plateData.name}</div>
                <div className={"orderItemDetailsLabel"}>Dimensions:</div>
                <div className={"orderItemDetailsValue"}>
                  {`${plateData.width}×${plateData.height}mm`}
                </div>
                <div className={"orderItemDetailsLabel"}>Material Grade:</div>
                <div className={"orderItemDetailsValue"}><Trans>{plateData.grade}</Trans></div>
                <div className={"orderItemDetailsLabel"}>Thickness:</div>
                <div className={"orderItemDetailsValue"}>{plateData.thickness}mm</div>
                <div className={"orderItemDetailsLabel"}>Efficiency:</div>
                <div className={"orderItemDetailsValue"}>{plateData.efficiency}%</div>
                {plateData.completionDate ?
                  <div>
                    <div className={"orderItemDetailsLabel"}>Completion Date:</div>
                    <div className={"orderItemDetailsValue"}>{formatTableDate(plateData.completionDate)}</div>
                  </div>
                  :
                  <div>
                    <div className={"orderItemDetailsLabel"}>Delivery Date:</div>
                    <div className={"orderItemDetailsValue"}>{formatTableDate(plateData.deliveryDate)}</div>
                  </div>
                }
                <div className={"orderItemDetailsLabel"}>Parts:</div>
                <div className={"nestingCard"} style={{width: "100%", minHeight: 250, maxHeight: "calc(100vh - 530px)", paddingRight: 0}}>
                  <NestedPartsList plateData={plateData}/>
                </div>
              </div>
            }
          </Card>
        </div>
        </Spin>
      </div>
    </Modal>
  )
}