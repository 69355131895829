import Icon from "@ant-design/icons";
import React from "react";


const CustomIcon = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="22.000000px" height="17.000px" viewBox="0 0 22.000000 17.0000"
       preserveAspectRatio="xMidYMid meet"
       fill="currentColor"
  >
    <g transform="translate(-2.000000,18.000000) scale(0.012600,-0.012600)"
       fill="currentColor" stroke="currentColor" strokeWidth="50px">
      <path d="M859.7,1253.8c-137-55-349-139-470-187c-310-123-319-95,103-341c181-105,337-191,345-191
			c22,0,968,427,986,445c34,34,4,53-334,216c-184,89-340,159-354,158S997.7,1307.8,859.7,1253.8z M1499.7,1119.8
			c238-116,240-118,210-130c-16-7-216-97-444-199c-227-102-419-186-425-186c-14,0-581,328-581,336c0,4,198,84,439,179l439,173l61-28
			C1232.7,1249.8,1367.7,1183.8,1499.7,1119.8z"/>
      <path d="M1725.2,839.8c-36-13-35-21,4-36c17-6,30-13,27-16c-7-6-897-396-904-396c-6,0-573,317-584,326
			c-2,2,10,7,27,10c42,8,40,20-3,38c-62,26-147-18-119-61c14-22,649-393,672-393c16,0,149,59,752,330c229,103,235,107,235,135
			C1832.2,816.8,1767.2,854.8,1725.2,839.8z"/>
      <path d="M1731.5,637.8c-35-14-35-16-11-36c21-18,14-21-416-207c-241-104-447-191-458-193c-12-3-137,60-319,161
			c-261,144-295,166-272,172c32,8,29,26-6,38c-39,14-83-12-83-49c0-33-28-15,416-273c151-88,236-132,255-132c16,1,243,97,504,214
			l475,214v33C1816.5,621.8,1771.5,652.8,1731.5,637.8z"/>
    </g>
  </svg>
);

export const MaterialIcon = props => <Icon component={CustomIcon} {...props} />;