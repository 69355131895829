import * as React from "react";
import "./style.css";
import {Button, Card} from "antd";

const MaterialTableContainer = (props) => {
  return (
    <Card className={'laserSpeedCard'}
          bodyStyle={{ paddingTop: 0, paddingLeft: 10, paddingRight: 5 }}>
      <div align={"center"} className={"cuttingSpeedText"} style={{ fontSize: 16, fontWeight: 600, textDecoration: "underline", marginTop: 8 }}>
        {props.tableText}
      </div>
      <div className={"materialTableYAxis"}>
        {props.yAxisText}
      </div>
      <div className={"materialTableXAxis"}>
        {props.xAxisText}
      </div>
      <div className={"materialTableXAxisContentLeft"}>
        {props.additionalLeft}
      </div>
      <div className={"materialTableXAxisContentRight"}>
        {props.additionalRight}
      </div>
      <div style={{ maxWidth: 1220 }}>
        <Card className={"tableCardWrapper"}
              style={{ borderRadius: 5, marginLeft: 30, marginRight: 10 }}
              bodyStyle={{ padding: 0 }}>
          {props.children}
        </Card>
      </div>
    </Card>
  )
}

export default MaterialTableContainer;