import React, {useCallback, useEffect, createRef, useState} from "react";
import "./style.css";
import {Card, Col, Divider, Form, Row, Switch, Checkbox, InputNumber, Typography} from "antd";
import {
  fetchSurfaceCoatingData, setGalvanizingData,
  setPaintingData, setPowderPaintingData,
  setSurfaceCoatingGalvanizing,
  setSurfaceCoatingPainting,
  setSurfaceCoatingPowderPainting,
  setSurfaceCoatingService
} from "../../../../../actions/surfaceCoatingActions";
import {useDispatch, useSelector} from "react-redux";
import {debounce} from "lodash";

export const SurfaceCoatingTab = (props) => {
  const {
    selectedTab,
    isFetchingSurfaceCoatingData,
    changePaintingParams,
    changePowderPaintingParams,
    changeGalvanizingParams
  } = props;

  const [disableSwitching, setDisableSwitching] = useState(false);

  const [disablePainting, setDisablePainting] = useState(false);

  const [disablePowderPainting, setDisablePowderPainting] = useState(false);

  const [disableGalvanizing, setDisableGalvanizing] = useState(false);

  const surfaceCoatingData = useSelector(state => state.surfaceCoating.surfaceCoatingData)

  const dispatch = useDispatch()

  const paintingForm = createRef()
  const powderPaintingForm = createRef()
  const galvanizingForm = createRef()

  useEffect(()=>{
    if(selectedTab === "4") {
      fetchData()
    }
  }, [selectedTab])

  const fetchData = () => {
    dispatch(fetchSurfaceCoatingData())
  }

  const triggerSlideToggle = (value) => {
    setDisableSwitching(true);
    dispatch(setSurfaceCoatingService({value})).then(()=>{
      setDisableSwitching(false);
      if(value) {
        fetchData()
      }
    })
  }

  /*----------- Painting -----------*/

  const setPainting = (value) => {
    setDisablePainting(true)
    dispatch(setSurfaceCoatingPainting({value})).then(()=>{
      setDisablePainting(false)
    })
  }

  const changePaintingData = (data) => {
    dispatch(setPaintingData(data))
    changePaintingParams(data)
  }

  const debouncedPainting = useCallback(
    debounce(data => changePaintingData(data), 800),
    []
  );

  const changePainting = () => {
    debouncedPainting({
      ...paintingForm.current.getFieldsValue()
    })
  }

  const setPrimerPainting = () => {
    changePaintingData({
      ...paintingForm.current.getFieldsValue()
    })
  }

  /*----------- Powder Painting -----------*/

  const setPowderPainting = (value) => {
    setDisablePowderPainting(true)
    dispatch(setSurfaceCoatingPowderPainting({value})).then(()=>{
      setDisablePowderPainting(false)
    })
  }

  const changePowderPaintingData = (data) => {
    dispatch(setPowderPaintingData(data))
    changePowderPaintingParams(data)
  }

  const debouncedPowderPainting  = useCallback(
    debounce(data => changePowderPaintingData(data), 800),
    []
  );

  const changePowderPainting  = () => {
    debouncedPowderPainting({
      ...powderPaintingForm.current.getFieldsValue()
    })
  }

  const setPrimerPowderPainting  = () => {
    changePowderPaintingData({
      ...powderPaintingForm.current.getFieldsValue()
    })
  }

  /*----------- Galvanizing -----------*/

  const setGalvanizing = (value) => {
    setDisableGalvanizing(true)
    dispatch(setSurfaceCoatingGalvanizing({value})).then(()=>{
      setDisableGalvanizing(false)
    })
  }

  const changeGalvanizingData = (data) => {
    dispatch(setGalvanizingData(data))
    changeGalvanizingParams(data)
  }

  const debouncedGalvanizing = useCallback(
    debounce(data => changeGalvanizingData(data), 800),
    []
  );

  const changeGalvanizing  = () => {
    debouncedGalvanizing({
      ...galvanizingForm.current.getFieldsValue()
    })
  }

  useEffect(()=>{
    if(!isFetchingSurfaceCoatingData) {
      paintingForm.current?.setFieldsValue({
        paintingPrice: surfaceCoatingData.painting.paintingPrice,
        hasPrimer: surfaceCoatingData.painting.hasPrimer,
        paintingPriceWithPrimer: surfaceCoatingData.painting.paintingPriceWithPrimer,
      })
      powderPaintingForm.current?.setFieldsValue({
        powderPaintingPrice: surfaceCoatingData.powderPainting.powderPaintingPrice,
        hasPrimer: surfaceCoatingData.powderPainting.hasPrimer,
        powderPaintingPriceWithPrimer: surfaceCoatingData.powderPainting.powderPaintingPriceWithPrimer
      })
      galvanizingForm.current?.setFieldsValue({
        hotGalvanizingPrice: surfaceCoatingData.galvanizing.hotGalvanizingPrice,
        coldGalvanizingPrice: surfaceCoatingData.galvanizing.coldGalvanizingPrice
      })
    }
  }, [isFetchingSurfaceCoatingData])

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"} bodyStyle={{paddingRight: 0}}>
        <div style={{height: 'calc(100vh - 120px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
            <Row>
              <Col xl={14} span={24}>
                <div className={"switchContainer"}>
                  <span style={{marginRight: 30, marginBottom: 6}}>Do you offer Surface Coating service?</span>
                  <span>No</span>
                  <Switch checked={surfaceCoatingData.hasSurfaceCoating}
                          onChange={triggerSlideToggle}
                          style={{marginLeft: 6, marginRight: 6}}
                          disabled={disableSwitching}
                  />
                  <span>Yes</span>
                </div>
              </Col>
            </Row>

            <Divider style={{width: "50%", minWidth: "0%", margin: "8px 0 8px 0"}}/>
            {surfaceCoatingData.hasSurfaceCoating &&
            <div>
              <div className={"mainForm"}>

                {/*------------------------------------------------------------------------------------------------*/}
                <Row gutter={16}>
                  <Col xl={12} span={24}>
                    <Form>
                    <div style={{ display: "flex", alignItems: "center"}}>
                      <Form.Item label={<span className={"mainFromLabel"}>Painting</span>}>
                        <Checkbox checked={surfaceCoatingData.painting.hasPainting}
                                  onChange={(event) => {
                                    setPainting(event.target.checked)
                                  }}
                                  disabled={disablePainting}
                        />
                      </Form.Item>
                      <Divider style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px", borderTopColor: "#5f5f5f"}}/>
                    </div>
                    </Form>
                    <span>(Standard Painting with frequently-used colors)</span>
                    <Form
                      ref={paintingForm}
                    >
                    <Card className={"inputInfoText"} style={{margin: "12px 0px 12px 0px"}}>
                      <div className={surfaceCoatingData.painting.hasPainting ? "" : "disabledField"} style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Painting price"}
                          name="paintingPrice"
                          style={{marginBottom: 10, marginTop: 10}}
                          initialValue={surfaceCoatingData.painting.paintingPrice}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changePainting}
                            disabled={!surfaceCoatingData.painting.hasPainting}
                          />
                        </Form.Item>
                        <Typography.Text style={{ marginLeft: 12}}>€/m<sup>2</sup></Typography.Text>
                      </div>
                      <div className={(surfaceCoatingData.painting.hasPainting && surfaceCoatingData.painting.hasPrimer) ? "" : "disabledField"}
                           style={{ display: "flex", alignItems: "center" }}>
                        <Form.Item
                          name="hasPrimer"
                          valuePropName={"checked"}
                          initialValue={surfaceCoatingData.painting.hasPrimer}
                        >
                          <Checkbox style={{ marginTop: 1, marginRight: 10 }}
                                    onChange={setPrimerPainting}
                                    disabled={!surfaceCoatingData.painting.hasPainting}
                          />
                        </Form.Item>
                        <Form.Item
                          label={"Painting price with the use of primer"}
                          name="paintingPriceWithPrimer"
                          style={{marginBottom: 10, marginTop: 10}}
                          initialValue={surfaceCoatingData.painting.paintingPriceWithPrimer}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changePainting}
                            disabled={!(surfaceCoatingData.painting.hasPrimer && surfaceCoatingData.painting.hasPainting)}
                          />
                        </Form.Item>
                        <Typography.Text style={{ marginLeft: 12}}>
                          €/m<sup>2</sup>
                        </Typography.Text>
                      </div>
                    </Card>
                    </Form>
                  </Col>
                  {/*</Row>*/}
                  {/*------------------------------------------------------------------------------------------------*/}
                  {/*<Row>*/}
                  <Col xl={12} span={24}>
                    <Form>
                    <div style={{ display: "flex", alignItems: "center"}}>
                      <Form.Item label={<span className={"mainFromLabel"}>Powder Painting</span>}>
                        <Checkbox checked={surfaceCoatingData.powderPainting.hasPowderPainting}
                                  onChange={(event) => {
                                              setPowderPainting(event.target.checked)
                                            }}
                                  disabled={disablePowderPainting}
                        />
                      </Form.Item>
                      <Divider style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px", borderTopColor: "#5f5f5f"}}/>
                    </div>
                    </Form>
                    <span>(Standard Powder Painting with frequently-used colors)</span>
                    <Form
                      ref={powderPaintingForm}
                    >
                    <Card className={"inputInfoText"} style={{margin: "12px 0px 12px 0px"}}>
                      <div className={(surfaceCoatingData.powderPainting.hasPowderPainting) ? "" : "disabledField"} style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Powder Painting price"}
                          name="powderPaintingPrice"
                          style={{marginBottom: 10, marginTop: 10}}
                          initialValue={surfaceCoatingData.powderPainting.powderPaintingPrice}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changePowderPainting}
                            disabled={!surfaceCoatingData.powderPainting.hasPowderPainting}
                          />
                        </Form.Item>
                        <Typography.Text style={{ marginLeft: 12}}>€/m<sup>2</sup></Typography.Text>
                      </div>
                      <div className={(surfaceCoatingData.powderPainting.hasPrimer && surfaceCoatingData.powderPainting.hasPowderPainting) ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          name="hasPrimer"
                          valuePropName={"checked"}
                          initialValue={surfaceCoatingData.powderPainting.hasPrimer}
                        >
                          <Checkbox style={{ marginTop: 1, marginRight: 10 }}
                                    onChange={setPrimerPowderPainting}
                                    disabled={!surfaceCoatingData.powderPainting.hasPowderPainting}
                          />
                        </Form.Item>
                        <Form.Item
                          label={"Powder Painting price with the use of primer"}
                          name="powderPaintingPriceWithPrimer"
                          style={{marginBottom: 10, marginTop: 10}}
                          initialValue={surfaceCoatingData.powderPainting.powderPaintingPriceWithPrimer}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changePowderPainting}
                            disabled={!(surfaceCoatingData.powderPainting.hasPrimer && surfaceCoatingData.powderPainting.hasPowderPainting)}
                          />
                        </Form.Item>
                        <Typography.Text style={{ marginLeft: 12}}>€/m<sup>2</sup></Typography.Text>
                      </div>
                    </Card>
                    </Form>
                  </Col>
                  {/*</Row>*/}
                  {/*------------------------------------------------------------------------------------------------*/}
                  {/*<Row>*/}
                  {/*<Col xl={12} span={24}>*/}
                  {/*  <Form>*/}
                  {/*  <div style={{ display: "flex", alignItems: "center"}}>*/}
                  {/*    <Form.Item label={<span className={"mainFromLabel"}>Galvanizing</span>}>*/}
                  {/*      <Checkbox checked={surfaceCoatingData.galvanizing.hasGalvanizing}*/}
                  {/*                onChange={(event) => {*/}
                  {/*                  setGalvanizing(event.target.checked)*/}
                  {/*                }}*/}
                  {/*                disabled={disableGalvanizing}*/}
                  {/*      />*/}
                  {/*    </Form.Item>*/}
                  {/*    <Divider style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px", borderTopColor: "#5f5f5f"}}/>*/}
                  {/*  </div>*/}
                  {/*  </Form>*/}
                  {/*  <span>(Standard Galvanizing process)</span>*/}
                  {/*  <Form*/}
                  {/*    ref={galvanizingForm}*/}
                  {/*  >*/}
                  {/*  <Card className={"inputInfoText"} style={{margin: "12px 0px 12px 0px"}}>*/}
                  {/*    <div className={surfaceCoatingData.galvanizing.hasGalvanizing ? "" : "disabledField"}*/}
                  {/*         style={{display: "flex", alignItems: "center"}}>*/}

                  {/*      <Form.Item*/}
                  {/*        label={"Price for Hot-Galvanizing process"}*/}
                  {/*        name="hotGalvanizingPrice"*/}
                  {/*        style={{marginBottom: 10, marginTop: 10}}*/}
                  {/*        initialValue={surfaceCoatingData.galvanizing.hotGalvanizingPrice}*/}
                  {/*      >*/}
                  {/*        <InputNumber*/}
                  {/*          style={{minWidth: 30, marginLeft: 5}}*/}
                  {/*          min={0}*/}
                  {/*          step={1}*/}
                  {/*          formatter={value => value ? value : 0}*/}
                  {/*          parser={value => value ? value : 0}*/}
                  {/*          onChange={changeGalvanizing}*/}
                  {/*          disabled={!(surfaceCoatingData.galvanizing.hasGalvanizing)}*/}
                  {/*        />*/}
                  {/*      </Form.Item>*/}
                  {/*      <Typography.Text style={{ marginLeft: 12}}>€/kg</Typography.Text>*/}
                  {/*    </div>*/}
                  {/*    <div className={surfaceCoatingData.galvanizing.hasGalvanizing ? "" : "disabledField"}*/}
                  {/*         style={{display: "flex", alignItems: "center"}}>*/}
                  {/*      <Form.Item*/}
                  {/*        label={"Price for Cold-Galvanizing process"}*/}
                  {/*        name="coldGalvanizingPrice"*/}
                  {/*        style={{marginBottom: 10, marginTop: 10}}*/}
                  {/*        initialValue={surfaceCoatingData.galvanizing.coldGalvanizingPrice}*/}
                  {/*      >*/}
                  {/*        <InputNumber*/}
                  {/*          style={{minWidth: 30, marginLeft: 5}}*/}
                  {/*          min={0}*/}
                  {/*          step={1}*/}
                  {/*          formatter={value => value ? value : 0}*/}
                  {/*          parser={value => value ? value : 0}*/}
                  {/*          onChange={changeGalvanizing}*/}
                  {/*          disabled={!(surfaceCoatingData.galvanizing.hasGalvanizing)}*/}
                  {/*        />*/}
                  {/*      </Form.Item>*/}
                  {/*      <Typography.Text style={{ marginLeft: 12}}>€/kg</Typography.Text>*/}
                  {/*    </div>*/}
                  {/*  </Card>*/}
                  {/*  </Form>*/}
                  {/*</Col>*/}
                  {/*------------------------------------------------------------------------------------------------*/}
                </Row>
              </div>
            </div>
            }
        </div>
      </Card>
    </React.Fragment>
  )
}