import React, {useEffect} from "react";
import {Layout, Row, Menu, Button} from "antd";
import history from "../../../utils/history";
import {MaterialIcon} from "../../../assets/icons/material"
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SnippetFolderOutlinedIcon from '@mui/icons-material/SnippetFolderOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import "./style.css"
import {useDispatch, useSelector} from "react-redux";
import {collapseSidebar, updateActiveSubmenuKeys} from "../../../actions/utilActions";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

const SubMenu = Menu.SubMenu

let selectedField = '1';

function activeComponent(history) {
  switch (history.location.pathname) {
    case('/'):
      selectedField = '1';
      break;
    case('/step-tips'):
    case('/calculation'):
      selectedField = '2';
      break
    case('/pending-order'):
    case('/pending'):
      selectedField = '3';
      break;
    case('/awaiting-order'):
    case('/awaiting'):
      selectedField = '4';
      break;
    case('/accepted-order'):
    case('/accepted'):
      selectedField = '5';
      break;
    case('/completed-order'):
    case('/archive'):
      selectedField = '6';
      break;
    case('/materials'):
      selectedField = '7';
      break;
    case('/nesting'):
      selectedField = '8';
      break
    case('/employeeManagement'):
      selectedField = '9';
      break;
    case('/internal-customer-relations'):
      selectedField = '10';
      break;
    case('/external-customer-relations'):
      selectedField = '11';
      break;
    case('/company-settings'):
      selectedField = '14';
      break;
    default:
      break;
  }
  return selectedField;
}

const checkPermission = (user, permission) => {
  return (user?.role === "producerAdmin" || (user?.role === "producerSubAdmin" && user?.permissions?.includes(permission)))
}

const Sidebar = (props) => {

  const activeSubmenuKeys = useSelector(state => state.util.activeSubmenuKeys);
  const {onLogout, user} = props;

  const dispatch = useDispatch();

  const isCollapsed = useSelector(state => state.util.isCollapsed)
  const onCollapse = () => {
    dispatch(collapseSidebar(!isCollapsed))
  }

  const hasOrderFunction = user?.producer?.hasOrderFunction;
  const hasBulkFunction = user?.producer?.hasBulkFunction;
  const hasNestingFunction = user?.producer?.hasNestingFunction;

  return (
    <Layout.Sider
      style={{
        overflow: 'auto',
        minHeight: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 10,
      }}
      collapsed={isCollapsed}
    >
      <Button className={"collapseButton"}
              style={{ left: isCollapsed ? '50px' : '190px'}}
              onClick={() => onCollapse()}
      >
        { isCollapsed ?
          <RightOutlined style={{ fontSize: 14, position: "relative", top: 1 }}/>
          :
          <LeftOutlined style={{ fontSize: 14, position: "relative", top: 1  }}/>
        }
      </Button>
      <Row style={{marginTop: '2rem', height: "100%"}}>
        <Menu
          className={"companyMenu"}
          theme="dark"
          mode="inline"
          selectedKeys={[activeComponent(history)]}
          defaultOpenKeys={isCollapsed ? [] : activeSubmenuKeys}
        >
          <Menu.Item
            key="1"
            onClick={() => {
              history.push('/')
            }}
            title={<span className="nav-text">Dashboard</span>}
            style={{ paddingLeft: 22 }}
          >
            <a href={"/"} onClick={(e) => e.preventDefault()}>
              <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <DashboardOutlinedIcon style={{fontSize: 22}}/>
                <span
                  className={"menuLine"}
                  style={{ opacity: isCollapsed ? 0 : 1}}
                >Dashboard</span>
              </div>
            </a>
          </Menu.Item>
          {checkPermission(user, "bulk") && hasBulkFunction &&
            <Menu.Item
              key="2"
              onClick={() => {
                history.push('/calculation')
              }}
              title={<span className="nav-text">Instant Quoting</span>}
              style={{ paddingLeft: 21 }}
            >
              <a href={"/calculation"} onClick={(e) => e.preventDefault()}>
                <div align={'center'}
                     style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                  <WysiwygOutlinedIcon style={{fontSize: 22}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Instant Quoting</span>
                </div>
              </a>
            </Menu.Item>
          }
          {checkPermission(user, "orders") && hasOrderFunction &&
            <SubMenu
              key="sub1"
              className={"subMenuC"}
              title={
                <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                  <PendingActionsIcon style={{fontSize: 21}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Orders</span>
                </div>
              }
              onTitleClick={() => {
                dispatch(updateActiveSubmenuKeys('sub1'));
              }}
            >
              <Menu.Item
                key="3"
                onClick={() => {
                  history.push('/pending')
                }}
              >
                <a href={"/pending"} onClick={(e) => e.preventDefault()}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{display: "flex", marginLeft: -2}}>
                      <AccessTimeIcon style={{fontSize: 20}}/>
                    </div>
                    <div style={{marginLeft: 7}}>
                      <span className="nav-text">Pending</span>
                    </div>
                  </div>
                </a>
              </Menu.Item>
              <Menu.Item
                key="4"
                onClick={() => {
                  history.push('/awaiting')
                }}>
                <a href={"/awaiting"} onClick={(e) => e.preventDefault()}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{display: "flex", marginLeft: -2}}>
                      <HourglassEmptyOutlinedIcon style={{fontSize: 20}}/>
                    </div>
                    <div style={{marginLeft: 7}}>
                      <span className="nav-text">Awaiting</span>
                    </div>
                  </div>
                </a>
              </Menu.Item>
              <Menu.Item
                key="5"
                onClick={() => {
                  history.push('/accepted')
                }}
              >
                <a href={"/accepted"} onClick={(e) => e.preventDefault()}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{display: "flex", marginLeft: -2}}>
                      {hasOrderFunction ?
                        <InventoryOutlinedIcon style={{fontSize: 20}}/>
                        :
                        <PendingActionsIcon style={{fontSize: 20}}/>
                      }
                    </div>
                    <div style={{marginLeft: 7}}>
                      <span className="nav-text">{hasOrderFunction ? "Accepted" : "Orders"}</span>
                    </div>
                  </div>
                </a>
              </Menu.Item>
              <Menu.Item
                key="6"
                onClick={() => {
                  history.push('/archive')
                }}>
                <a href={"/archive"} onClick={(e) => e.preventDefault()}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{display: "flex", marginLeft: -2}}>
                      <SnippetFolderOutlinedIcon style={{fontSize: 20}}/>
                    </div>
                    <div style={{marginLeft: 7}}>
                      <span className="nav-text">Archive</span>
                    </div>
                  </div>
                </a>
              </Menu.Item>
            </SubMenu>
          }
          {checkPermission(user, "materials") &&
            <Menu.Item
              key="7"
              onClick={(e) => {
                history.push('/materials')
              }}
              title={<span className="nav-text">Price Parameters</span>}
              style={{ paddingLeft: 21, display: "block" }}
            >
              <a href={"/materials"} onClick={(e) => e.preventDefault()}>
                <MaterialIcon style={{fontSize: 20, position: "relative", right: 2, top: 1}}/>
                <span
                  className={"menuLine"}
                  style={{ marginLeft: 9, opacity: isCollapsed ? 0 : 1}}
                >Price Parameters</span>
              </a>
            </Menu.Item>
          }
          {checkPermission(user, "nesting") && hasNestingFunction &&
            <Menu.Item
              key="8"
              onClick={() => {
                history.push('/nesting')
              }}
              title={<span className="nav-text">Nesting</span>}
              style={{ paddingLeft: 21 }}
            >
              <a href={"/nesting"} onClick={(e) => e.preventDefault()}>
                <div align={'center'}
                     style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                  <BackupTableOutlinedIcon style={{fontSize: 22}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Nesting</span>
                </div>
              </a>
            </Menu.Item>
          }
          {user?.role === "producerAdmin" &&
            <Menu.Item
              key="9"
              onClick={() => {
                history.push('/employeeManagement')
              }}
              title={<span className="nav-text">Employee Overview</span>}
              style={{ paddingLeft: 20 }}
            >
              <a href={"/employeeManagement"} onClick={(e) => e.preventDefault()}>
                <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -1}}>
                  <BadgeOutlinedIcon style={{fontSize: 22}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Employee Overview</span>
                </div>
              </a>
            </Menu.Item>
          }
          {hasBulkFunction && hasOrderFunction && checkPermission(user, "customers") &&
            <SubMenu
              key="sub2"
              className={"subMenuC"}
              title={
                <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -1}}>
                  <PeopleAltOutlinedIcon style={{fontSize: 21, position: "relative"}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Customers</span>
                </div>
              }
              onTitleClick={() => {
                dispatch(updateActiveSubmenuKeys('sub2'));
              }}
            >
              <Menu.Item
                key="10"
                onClick={() => {
                  history.push('/internal-customer-relations')
                }}
              >
                <a href={"/internal-customer-relations"} onClick={(e) => e.preventDefault()}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{display: "flex"}}>
                      <PeopleAltOutlinedIcon style={{fontSize: 21, position: "relative"}}/>
                    </div>
                    <div style={{marginLeft: 7}}>
                      <span className="nav-text">On-Hefeos</span>
                    </div>
                  </div>
                </a>
              </Menu.Item>
              <Menu.Item
                key="11"
                onClick={() => {
                  history.push('/external-customer-relations')
                }}
              >
                <a href={"/external-customer-relations"} onClick={(e) => e.preventDefault()}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{display: "flex"}}>
                      <ThreePOutlinedIcon style={{fontSize: 21, position: "relative"}}/>
                    </div>
                    <div style={{marginLeft: 7}}>
                      <span className="nav-text">Off-Hefeos</span>
                    </div>
                  </div>
                </a>
              </Menu.Item>
            </SubMenu>
          }
          {!hasBulkFunction && hasOrderFunction && checkPermission(user, "customers") &&
            <Menu.Item
              key="10"
              onClick={() => {
                history.push('/internal-customer-relations')
              }}
              title={<span className="nav-text">Customer Relations</span>}
              style={{ paddingLeft: 20 }}
            >
              <a href={"/internal-customer-relations"} onClick={(e) => e.preventDefault()}>
                <div align={'center'}
                     style={{display: "flex", alignItems: "center", height: '100%', width: "100%"}}
                >
                  <PeopleAltOutlinedIcon style={{fontSize: 21, position: "relative"}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Customer Relations</span>
                </div>
              </a>
            </Menu.Item>
          }
          {hasBulkFunction && !hasOrderFunction && checkPermission(user, "customers") &&
            <Menu.Item
              key="11"
              onClick={() => {
                history.push('/external-customer-relations')
              }}
              title={<span className="nav-text">Customers</span>}
              style={{ paddingLeft: 20 }}
            >
              <a href={"/external-customer-relations"} onClick={(e) => e.preventDefault()}>
                <div align={'center'}
                     style={{display: "flex", alignItems: "center", height: '100%', width: "100%"}}
                >
                  <PeopleAltOutlinedIcon style={{fontSize: 21, position: "relative"}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Customers</span>
                </div>
              </a>
            </Menu.Item>
          }
          {checkPermission(user, "settings") &&
            <Menu.Item
              key="14"
              onClick={() => {
                history.push('/company-settings')
              }}
              title={<span className="nav-text">Settings</span>}
              style={{ paddingLeft: 22 }}
            >
              <a href={"/company-settings"} onClick={(e) => e.preventDefault()}>
                <div align={'center'}
                     style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                  <SettingsOutlinedIcon style={{fontSize: 22}}/>
                  <span
                    className={"menuLine"}
                    style={{ opacity: isCollapsed ? 0 : 1}}
                  >Settings</span>
                </div>
              </a>
            </Menu.Item>
          }
        </Menu>
        <Menu
          theme="dark"
          mode="inline"
          style={{position: "absolute", bottom: 20, width: "100%"}}
        >
          <Menu.Item
            key="15"
            onClick={onLogout}
            title={<span className="nav-text">Sign out</span>}
            style={{ paddingLeft: 22 }}
          >
            <div align={'center'}
                 style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
              <LogoutOutlinedIcon style={{fontSize: 22}}/>
              <span
                className={"menuLine"}
                style={{ opacity: isCollapsed ? 0 : 1}}
              >Sign Out</span>
            </div>
          </Menu.Item>
        </Menu>
      </Row>
    </Layout.Sider>
  )
}

export default Sidebar