import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Card, Button, Form, Input} from 'antd'

import HefeosLogo from '../../../assets/Hefeos-IQT-Dark.svg'
import {CardTitle, Logo} from './styles'
import {loginAdmin, setRedirect} from '../../../actions/authActions'
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import "./style.css"



export const AdminLoginPage = (props) => {
  const redirect = useSelector(state => state.auth.redirect);
  const isLoggingIn = useSelector(state => state.auth.loggingIn);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      location.state &&
      location.state.from &&
      location.state.from.pathname === '/order'
    ) {
      setRedirect(
        `${location.state.from.pathname}${
          location.state.from.search
        }`
      )
    }
  }, [])

  const handleClick = values => {
    const form = {
      email: values.email,
      password: values.password
    }
    dispatch(loginAdmin({form: form, redirectTo: redirect}))
  }

  return (
    <React.Fragment>
      <div className={"publicWrapper"}>
        <div className={"tiledBackground"}>
          <Card className={"publicCard"}>
            <div style={{textAlign: 'center', marginBottom: "6em"}}>
              <img src={HefeosLogo} alt="Hefeos Logo" width={300}/>
            </div>
            <Form
              name={'login'}
              onFinish={handleClick}
            >
              <span className={"loginLabel"}>Email</span>
              <Form.Item
                name={'email'}
                rules={[
                  {required: true, message: 'This field is required'},
                  {type: 'email', message: 'Please enter a valid email address'},
                ]}
                validateTrigger={'onSubmit'}
                className={"formItem"}
              >
                <Input
                  placeholder="Enter your email address"
                  className={"loginInputText"}
                  prefix={<UserOutlined/>}
                  autoComplete={'email'}
                />
              </Form.Item>
              <span className={"loginLabel"}>Password</span>
              <Form.Item
                name={'password'}
                rules={[
                  {required: true, message: 'This field is required'},
                  {type: 'string', min: 6, message: 'Password must be 6 characters or longer'},
                  {type: 'string', max: 60, message: 'Password must be 60 characters or shorter'},
                ]}
                validateTrigger={'onSubmit'}
              >
                <Input.Password
                  className={"loginInputText"}
                  prefix={<LockOutlined/>}
                  placeholder="Enter your password"
                  autoComplete={'current-password'}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{width: '100%', marginTop: 5}}
                  loading={isLoggingIn}
                  type="primary"
                  htmlType="submit"
                  className={"loginText"}
                >
                  Sign in
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

