import Icon from "@ant-design/icons";
import React from "react";

const MiddleMouseClick = () => (
    <svg width="0.75em" height="1.4em" viewBox="0 0 1024 1024">
        <g transform="translate(0.000000,1318.000000) scale(0.100000,-0.100000)"
           fill="currentColor" stroke="none">
            <path d="M2640 12266 c-171 -32 -279 -60 -450 -118 -306 -104 -664 -301 -906
            -499 -108 -88 -373 -356 -448 -454 -332 -430 -520 -873 -612 -1447 l-26 -163
            4 -3460 c4 -3224 6 -3466 22 -3550 68 -355 138 -590 247 -829 209 -462 544
            -866 969 -1172 372 -269 795 -439 1280 -516 132 -21 165 -21 1447 -25 831 -3
            1380 -1 1495 6 408 23 799 139 1193 353 503 275 908 682 1184 1193 166 307
            277 648 318 975 27 221 31 759 29 3970 l-3 3145 -27 140 c-72 375 -139 582
            -281 867 -191 382 -428 676 -790 978 -330 275 -735 469 -1225 587 l-155 37
            -1570 2 -1570 2 -125 -22z m1193 -666 c-162 -42 -337 -140 -455 -254 -132
            -128 -232 -301 -283 -488 l-26 -97 4 -1283 c3 -940 7 -1296 15 -1332 16 -65
            108 -256 163 -337 83 -124 226 -247 374 -322 49 -24 198 -76 283 -97 l42 -11
            0 -444 0 -445 -1531 0 -1531 0 5 1363 c3 949 8 1392 16 1462 34 282 116 566
            240 820 133 275 274 477 479 687 404 415 895 672 1467 767 162 27 167 27 510
            28 l300 1 -72 -18z m1687 -13 c161 -30 265 -56 380 -95 114 -39 293 -113 355
            -147 28 -15 79 -43 115 -63 80 -43 260 -165 355 -240 100 -80 280 -257 364
            -360 271 -331 432 -654 536 -1076 19 -77 40 -173 46 -215 11 -80 26 -1317 28
            -2338 l1 -563 -1530 0 -1530 0 0 445 0 445 28 6 c249 54 485 188 615 349 75
            92 158 248 199 372 l38 113 0 1254 c0 1080 -2 1264 -15 1326 -84 401 -345 681
            -740 796 l-80 24 350 -4 c324 -3 360 -5 485 -29z m-1195 -584 c66 -53 210
            -172 320 -264 110 -92 223 -187 252 -211 64 -53 75 -84 71 -202 l-3 -90 -245
            204 c-368 306 -674 566 -689 583 -10 13 -8 18 20 32 40 21 107 43 134 44 11 0
            73 -42 140 -96z m-272 -532 c177 -147 352 -293 388 -322 37 -30 174 -144 304
            -253 l238 -200 -7 -95 c-3 -53 -6 -102 -6 -109 0 -15 -16 -3 -325 253 -119 99
            -258 214 -309 255 -170 139 -581 488 -629 535 -41 40 -48 51 -42 74 9 36 52
            131 60 131 4 0 152 -121 328 -269z m-313 -504 c52 -44 128 -107 169 -141 40
            -33 108 -90 150 -126 43 -36 140 -117 217 -180 76 -63 264 -220 417 -348 l277
            -233 0 -115 0 -115 -76 63 c-41 35 -106 88 -143 118 -129 105 -815 677 -1053
            878 l-88 73 0 78 c0 96 8 143 24 134 6 -4 54 -43 106 -86z m10 -773 c279 -233
            1105 -920 1113 -925 12 -9 -14 -56 -60 -108 l-39 -44 -112 92 c-62 51 -215
            179 -341 284 -125 106 -334 281 -465 390 l-236 198 0 63 c0 35 3 84 6 109 8
            54 -8 61 134 -59z m29 -784 c57 -47 125 -103 151 -125 26 -22 137 -114 247
            -205 123 -101 196 -168 189 -172 -31 -21 -222 -1 -281 29 -39 20 -199 148
            -282 226 -46 44 -73 80 -98 131 -29 60 -75 201 -75 229 0 9 4 6 149 -113z
            m3917 -3906 c-3 -731 -11 -1362 -17 -1433 -18 -229 -83 -497 -175 -719 -200
            -484 -544 -905 -969 -1186 -163 -108 -250 -156 -395 -219 -197 -86 -345 -132
            -543 -171 -252 -49 -270 -50 -1317 -51 -894 0 -997 2 -1110 18 -403 57 -769
            193 -1092 406 -427 280 -772 688 -966 1141 -95 221 -147 398 -188 645 l-25
            150 -6 1363 -6 1362 3408 0 3408 0 -7 -1306z"
            />
        </g>
    </svg>
);

export const MiddleMouse = props => <Icon component={MiddleMouseClick} {...props} />;