import React from "react";
import {OrderUploadButton} from "../../OrderUploadButton";
import {OrderUploadProcessProgress} from "../../OrderUploadProcessProgress";
import {OrderMultiEdit} from "../../OrderMultiEdit";
import {OrderErrorPartsList} from "../../OrderErrorPartsList";
import {OrderPartsList} from "../../OrderPartsList";

export const OrderProcessingStage = (props) => {

  const {
    orderId, largeThreshold, allItemsCount, errorItems, screens, openFileSizeNotification, triggerUpload,
    smallThreshold, toProcess, currentlyUploaded, currentlyProcessed, selectableItemsCount,
    handleRemoveMultipleItems, selectedAllItems, setSelectedAllItems, handleSelectAll,
    indeterminate, selectedItemsCount, handleMultiMaterialSelect, handleMultiMaterialClear,
    materials, selectableMaterials, multiformRef, selectedParameters, handleMultiThicknessSelect,
    handleMultiThicknessClear, handleMultiQuantityChange, handleMultiColorSelect, handleMultiColorClear,
    handleMultiClear, handleMultiApply, handleRemoveErrorParts, items, selectedItems,
    setSelectedItems, setSelectedItemsCount, handleMaterialFilter, handleRemoveItem,
    changePartMaterial, changePartThickness, changePartQuantity, changePartNote, changePartColor,
    handleColorRemove, showItem, handleChangeManualItemType, selectableThickness, onUploadClick,
    adjustment
  } = props

  return (
    <div>
      <div style={{marginRight: largeThreshold ? 10 : 20}}>
        {/*----------------Upload Button-----------------*/}
        <OrderUploadButton
          allItemsCount={allItemsCount}
          errorItems={errorItems}
          smallThreshold={smallThreshold}
          screens={screens}
          openFileSizeNotification={openFileSizeNotification}
          triggerUpload={triggerUpload}
          onOpen={onUploadClick}
        />
        {/*--------------------------------------------*/}

        {/*----------------Upload/Process Progress-----------------*/}
        {toProcess > 0 &&
          <OrderUploadProcessProgress
            toProcess={toProcess}
            currentlyUploaded={currentlyUploaded}
            currentlyProcessed={currentlyProcessed}
          />
        }
        {/*--------------------------------------------*/}

        {/*----------------Multi Edit-----------------*/}
        {selectableItemsCount > 0 &&
          <OrderMultiEdit
            selectedItemsCount={selectedItemsCount}
            handleRemoveMultipleItems={handleRemoveMultipleItems}
            selectedAllItems={selectedAllItems}
            setSelectedAllItems={setSelectedAllItems}
            handleSelectAll={handleSelectAll}
            indeterminate={indeterminate}
            selectableItemsCount={selectableItemsCount}
            handleMultiMaterialSelect={handleMultiMaterialSelect}
            handleMultiMaterialClear={handleMultiMaterialClear}
            materials={materials}
            selectableMaterials={selectableMaterials}
            selectableThickness={selectableThickness}
            multiformRef={multiformRef}
            selectedParameters={selectedParameters}
            handleMultiThicknessSelect={handleMultiThicknessSelect}
            handleMultiThicknessClear={handleMultiThicknessClear}
            handleMultiQuantityChange={handleMultiQuantityChange}
            handleMultiMaterialSurchargePerPieceChange={(_n) => {}}
            handleMultiColorSelect={handleMultiColorSelect}
            handleMultiColorClear={handleMultiColorClear}
            handleMultiClear={handleMultiClear}
            handleMultiApply={handleMultiApply}
            isBulkOrder={false}
          />
        }
        {/*--------------------------------------------*/}
        {allItemsCount > 0 &&
          <OrderPartsList
            orderId={orderId}
            items={items}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            setSelectedItemsCount={setSelectedItemsCount}
            selectedItemsCount={selectedItemsCount}
            setSelectedAllItems={setSelectedAllItems}
            selectedAllItems={selectedAllItems}
            handleMaterialFilter={handleMaterialFilter}
            handleRemoveItem={handleRemoveItem}
            materials={materials}
            changePartMaterial={changePartMaterial}
            changePartThickness={changePartThickness}
            changePartQuantity={changePartQuantity}
            changePartNote={changePartNote}
            changePartColor={changePartColor}
            handleColorRemove={handleColorRemove}
            showItem={showItem}
            handleChangeManualItemType={handleChangeManualItemType}
            bulkItem={false}
            adjustment={adjustment}
          />
        }
        {errorItems?.length > 0 &&
          <OrderErrorPartsList
            errorParts={errorItems}
            handleRemoveErrorParts={handleRemoveErrorParts}
          />
        }
      </div>
    </div>
  )
}