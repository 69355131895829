import React from "react";

import "../tableStyle.css";

export const EmptyTable = () => {
  return (
    <div className={"emptyTable"}>
      <div className={"emptyTableHeader"} />
      <div className={"emptyTableBody"}>
        <div className={"emptyTableText"}>No data available.</div>
      </div>
    </div>
  )
}