import {
  AUTH_SET_USER,
  AUTH_SET_LOGGING_IN,
  AUTH_SET_REGISTERING,
  AUTH_SET_REDIRECT,
  AUTH_SET_REGISTERING_COMPANY,
} from '../actions/actionTypes'
import initialState from './initialState'

const authReducer = (state = initialState.auth, action) => {
  switch (action.type) {
    case AUTH_SET_USER:
      return {
        ...state,
        user: action.payload ? {...state.user, ...action.payload} : action.payload
      }

    case AUTH_SET_LOGGING_IN:
      return {
        ...state,
        loggingIn: action.payload,
      }

    case AUTH_SET_REGISTERING:
      return {
        ...state,
        registering: action.payload,
      }

    case AUTH_SET_REGISTERING_COMPANY:
      return {
        ...state,
        registeringCompany: action.payload,
      }

    case AUTH_SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload,
      }

    default:
      return state
  }
}

export default authReducer
