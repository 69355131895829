import {
  NESTING_REMOVE_NESTED_PLATE,
  NESTING_SET_ACCEPTED_JOBS,
  NESTING_SET_COMPLETED_JOBS,
  NESTING_SET_CURRENT_JOB,
  NESTING_SET_FETCHING_ACCEPTED_JOBS,
  NESTING_SET_FETCHING_COMPLETED_JOBS,
  NESTING_SET_IS_CURRENTLY_NESTING,
  NESTING_SET_IS_FETCHING_CURRENT_JOB,
  NESTING_SET_IS_FETCHING_ORDERS,
  NESTING_SET_IS_FETCHING_PLATE_DATA,
  NESTING_SET_ORDERS,
  NESTING_SET_PLATE_DATA
} from '../actions/actionTypes'
import initialState from './initialState'

const nestingReducer = (state = initialState.nesting, action) => {
  switch (action.type) {
    case NESTING_SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      }

    case NESTING_SET_IS_FETCHING_ORDERS:
      return {
        ...state,
        isFetchingOrders: action.payload,
      }

    case NESTING_SET_IS_FETCHING_CURRENT_JOB:
      return {
        ...state,
        isFetchingCurrentNesting: action.payload,
      }

    case NESTING_SET_CURRENT_JOB:
      return {
        ...state,
        currentNesting: action.payload,
      }

    case NESTING_SET_IS_FETCHING_PLATE_DATA:
      return {
        ...state,
        isFetchingPlateData: action.payload,
      }

    case NESTING_SET_PLATE_DATA:
      return {
        ...state,
        plateData: action.payload,
      }

    case NESTING_SET_FETCHING_ACCEPTED_JOBS:
      return {
        ...state,
        isFetchingAcceptedJobs: action.payload
      }

    case NESTING_SET_ACCEPTED_JOBS:
      return {
        ...state,
        acceptedJobs: action.payload
      }

    case NESTING_SET_FETCHING_COMPLETED_JOBS:
      return {
        ...state,
        isFetchingCompletedJobs: action.payload
      }

    case NESTING_SET_COMPLETED_JOBS:
      return {
        ...state,
        completedJobs: action.payload
      }

    case NESTING_SET_IS_CURRENTLY_NESTING:
      return {
        ...state,
        currentNesting: {...state.currentNesting, isCurrentlyNesting: action.payload}
      }

    case NESTING_REMOVE_NESTED_PLATE:
      return {
        ...state,
        currentNesting: {...state.currentNesting, nestedPlates: state.currentNesting.nestedPlates.filter(item => item.id !== action.payload)}
      }
    default:
      return state
  }
}

export default nestingReducer