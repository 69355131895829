import React, {createRef, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Col, Form, Input, InputNumber, List, Row, Select, Tooltip, Upload, Radio, Spin} from "antd";
import {
  CaretDownOutlined,
  CheckOutlined,
  ExclamationCircleFilled,
  FilePdfOutlined, LoadingOutlined,
  MinusOutlined
} from "@ant-design/icons";

import CoatingPicker from "../CoatingPicker";

import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import cadSheetMetal from "../../../assets/icons/cad_sheet.svg";
import cadMachined from "../../../assets/icons/cad_machined.svg";
import cadProfile from "../../../assets/icons/cad_profile.svg";
import cadOther from "../../../assets/icons/cad_other.svg";

import {S3_BUCKET_URL} from "../../../constants";
import treeD from "../../../assets/icons/3D.svg";
import cube from "../../../assets/icons/cube.gif";
import twoD from "../../../assets/icons/2D.svg";
import squareGif from "../../../assets/icons/2d2.gif";
import {DrawingIcon2} from "../../../assets/icons/drawing2";
import {removePartDrawing, uploadPartDrawing} from "../../../actions/orderActions";
import {useDispatch} from "react-redux";
import {DropIcon} from "../../../assets/icons/drop";
import {removeBulkPartDrawing, uploadBulkPartDrawing} from "../../../actions/bulkOrderActions";
import {translator} from "../../../translations/translators";

const { Option } = Select;
const { OptGroup } = Select;

export const ManualOrderItem = (props) => {
  const form = createRef()

  const [loaded, setLoaded] = useState(false)
  const [uploadingDrawing, setUploadingDrawing] = useState(false)
  const dispatch = useDispatch()

  const {
    orderId, item, materials,  changePartMaterial,
    changePartQuantity, changePartThickness,
    changePartNote, changePartColor, changePartStandard,
    handleColorRemove, showItem, bulkItem,
    changePrice
  } = props

  const addPartDrawing = (file) => {
    setUploadingDrawing(true)
    if(bulkItem) {
      dispatch(uploadBulkPartDrawing({orderId: orderId, orderPartId: item.id, file: file})).then(()=>{
        setUploadingDrawing(false)
      })
    } else {
      dispatch(uploadPartDrawing({orderId: orderId, orderPartId: item.id, file: file})).then(()=>{
        setUploadingDrawing(false)
      })
    }

  }

  const handleRemovePartDrawing = () => {
    if(bulkItem) {
      dispatch(removeBulkPartDrawing({orderId: orderId, orderPartId: item.id}))
    } else {
      dispatch(removePartDrawing({orderId: orderId, orderPartId: item.id}))
    }
  }

  useEffect(()=>{
    form.current.setFieldsValue({
      material: item.selectedMaterial.grade,
      quantity: item.quantity
    })
    if (bulkItem) {
      form.current.setFieldsValue({
        price: (item.pricePerPiece || 0).toFixed(2)
      })
    }
  }, [item, form])

  const strForSearch = (str) => {
    return str
      ? str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
      : str;
  };

  const getPlaceholderImage = (type) => {
    switch (type) {
      case "sheetMetal":
        return cadSheetMetal
      case "machined":
        return cadMachined
      case "profile":
        return cadProfile
      case "other":
        return cadOther
      default:
        return cadOther
    }
  }

  return (
    <Form
      ref={form}
      name={"form"+item.id}
      style={{height: "100%"}}
    >
      <Row style={{height: "100%"}}>
        <Col flex={"250px"} style={{borderRight: "1px solid #d8d8d8"}}>
          {(item.fileType === "step" && item.stepData.filePaths.pngFilePath) &&
            (<Row style={{height: 240}}>
              <Col>
                <div className={item.stepData.filePaths.foldedStlFilePath && "container"}>
                  <img
                    className={"productImage"}
                    style={{width: 240, height: 240, padding: 8, opacity: loaded ? 100 : 0}}
                    src={S3_BUCKET_URL + item.stepData.filePaths.pngFilePath}
                    alt={"part"}
                    onLoad={() => {
                      setLoaded(true)
                    }}
                  />
                  {item.stepData.filePaths.foldedStlFilePath && (
                    <div>
                      {item.coating?.hasCoating &&
                        <div className={"dropOnOverlay"}>
                          <DropIcon style={{color: item.coating.ralHex, width: 16, height: 16}}/>
                        </div>
                      }
                      <div className={"overlay"} onClick={() => showItem(item)}>
                        <div className={"overlay-text"}>
                          <div className={"overlay-text-3D"}>3D</div>
                          <div className={"overlay-text-view"}>View</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col>
              </Col>
            </Row>)
          }
          {(item.fileType === "dxf" && item.dxfData.pngFilePath) &&
            (<Row style={{height: 240}}>
              <Col>
                <div
                  className={item.s3FilePath && "container"}
                  style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center", width: 180}}
                >
                  <div style={{width: loaded ? 240 : 0, height: 240, display: "flex", background: "white", alignItems: "center", justifyContent: "center"}}>
                    <img
                      className={"productImage"}
                      style={{
                        maxWidth: "99%",
                        maxHeight: "99%",
                        padding: 10,
                        position: "relative",
                        zIndex: 3
                      }}
                      src={S3_BUCKET_URL + item.dxfData.pngFilePath}
                      alt={"part"}
                      onLoad={() => {
                        setLoaded(true)
                      }}
                    />
                  </div>
                  {item.s3FilePath && (
                    <div>
                      {item.coating?.hasCoating &&
                        <div className={"dropOnOverlay"}>
                          <DropIcon style={{color: item.coating.ralHex, width: 16, height: 16}}/>
                        </div>
                      }
                      <div className={"overlay"} onClick={() => showItem(item)}>
                        <div className={"overlay-text"}>
                          <div className={"overlay-text-3D"}>2D</div>
                          <div className={"overlay-text-view"}>View</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col>
              </Col>
            </Row>)
          }
          {!loaded &&
            (<div style={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80%",
              width: "100%",
              top: 0
            }}>
              <div style={{marginLeft: 6, marginTop: -20}}>
                <div>
                  <img className={"productImage"} style={{width: 120, height: 120, padding: 8}} src={getPlaceholderImage(item.partType)} alt={"part3D"}/>
                </div>
              </div>
            </div>)
          }
        </Col>
        <Col flex="1 1 33%" style={{borderRight: window.innerWidth > 948  ? "1px solid #d8d8d8" : "none"}}>
          <Row gutter={10} style={{marginLeft: 10, marginRight: 10}}>
            <Col span={24}>
              <span className={"text12-500-12"}>Material</span>
              <Tooltip
                getPopupContainer={trigger => trigger.parentNode}
                overlayClassName={item.partType !== "sheetMetal" ? "small-tooltip" : "small-tooltip dont-show-wrapper"}
                color={"rgba(82,82,82,0.9)"}
                title={
                  <span
                    className={"text12-500"}
                    style={{color: "white"}}
                  >
                    Not applicable to this part type
                  </span>
                }
              >
              <Form.Item
                name={"material"}
                style={{marginBottom: 8}}
                initialValue={item.selectedMaterial.grade}
              >
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  onChange={(e)=> changePartMaterial(item.id, e, item.fileType, item.processingType, item.selectedMaterial.thickness)}
                  getPopupContainer={trigger => trigger.parentNode}
                  disabled={item.partType === "other" || item.partType === "assembly"}
                  className={item.selectedMaterial.isAvailable === false && !(item.partType === "other" || item.partType === "assembly") ? "notAvailable" : ""}
                  showSearch
                  filterOption={(input, option) => {
                    if (option.value) {
                      return strForSearch(option.children.props.children).includes(strForSearch(input));
                    } else {
                      return false;
                    }
                  }}
                  virtual={false}
                >
                  {materials.map(material => {
                    return (
                      <OptGroup
                        key={material.groupName}
                        label={material.groupName}>
                        {material.grades.map(t => {
                          return (
                            <Option
                              key={t.grade}
                              value={t.grade}>
                              <span className={"text12-600"}
                                    style={item.partType === "other" || item.partType === "assembly" ? {color: "#f5f5f5"} : {}}
                              >
                                {translator(t.grade)}
                              </span>
                            </Option>
                          )
                        })}
                      </OptGroup>
                      )
                    })}
                </Select>
              </Form.Item>
              </Tooltip>
            </Col>
            <Col span={12}>
              <span className={"text12-500-12"}>
                Thickness
                {item.selectedMaterial.isAvailable === false && item.partType === "sheetMetal" &&
                  <Tooltip
                    color={"#ffc107"}
                    overlayClassName={"small-tooltip"}
                    size={"small"}
                    title={<span className={"text12-500"} style={{color: "#333f48"}}>Material/Thickness not available.</span>}
                  >
                    <ExclamationCircleFilled style={{color: "#ffc107", fontSize: 13, marginLeft: 6}} />
                  </Tooltip>
                }
              </span>
              <Tooltip
                getPopupContainer={trigger => trigger.parentNode}
                overlayClassName={item.partType !== "sheetMetal" ? "small-tooltip" : "small-tooltip dont-show-wrapper"}
                color={"rgba(82,82,82,0.9)"}
                title={
                  <span
                    className={"text12-500"}
                    style={{color: "white"}}
                  >
                    Not applicable to this part type
                  </span>
                }
              >
                <Form.Item
                  style={{marginBottom: 8}}
                >
                  <Select
                    suffixIcon={<CaretDownOutlined />}
                    value={item.partType === "sheetMetal" ? (item.selectedMaterial.isAvailable === false ? `${item.selectedMaterial.thickness} mm` : item.selectedMaterial.materialId) : ""}
                    onChange={(value)=> changePartThickness({id: item.id, thickness: item.availableThicknesses.find(thick => thick.id === value)})}
                    getPopupContainer={trigger => trigger.parentNode}
                    virtual={false}
                    className={item.selectedMaterial.isAvailable === false && item.partType === "sheetMetal" ? "notAvailable" : ""}
                    disabled={item.partType !== "sheetMetal"}
                  >
                    {item.availableThicknesses.map(thick => {
                      return (<Option
                        key={thick.id}
                        value={thick.id}>
                        <span
                          className={"text12-600"}
                          style={item.partType !== "sheetMetal" ? {color: "#f5f5f5"} : {}}
                        >
                          {thick.thickness} mm
                        </span>
                      </Option>)
                    })}
                  </Select>
                </Form.Item>
              </Tooltip>
            </Col>
            <Col span={12}>
              <span className={"text12-500-12"}>Quantity</span>
              <Form.Item
                name={"quantity"}
                style={{marginBottom: 8}}
                initialValue={item.quantity}
                rules={[
                  {required: true, message: ""}
                ]}
                className={"quantity-field"}
              >
                <InputNumber
                  className={"item-number-input"}
                  placeholder={"Enter quantity"}
                  type={"number"}
                  style={{width: '100%'}}
                  min={1}
                  max={10000}
                  step={1}
                  formatter={value => value ? value : 1}
                  parser={value => value ? value : 1}
                  onChange={(q) => changePartQuantity({orderId: orderId, id: item.id, quantity: q})}
                />
              </Form.Item>
            </Col>
            {!bulkItem ? (
              <div>
                <Col span={24}>
                  <span className={"text12-500-12"}>Note (Optional)</span>
                  <Form.Item
                    name={"note"}
                    initialValue={item.note}
                  >
                    <Input
                      size={'large'}
                      className={"text12-500"}
                      placeholder={"Notes and additional requirements"}
                      onChange={(e) => changePartNote({orderId: orderId, id: item.id, note: e.target.value})}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div style={{
                  display: "flex",
                  backgroundColor: "#ececec",
                  padding: 5,
                  borderRadius: 5,
                  marginTop: -10,
                  marginBottom: 10,
                  border: "1px solid #aaaaaa"
                }}>
                  <div style={{display:"flex", height: "100%", alignItems: "center", marginTop: "auto", marginBottom: "auto"}}>
                    <div>
                      <ExclamationCircleFilled style={{color: "#ffc107", fontSize: 15, position: "relative", marginLeft: 5}}/>
                    </div>
                  </div>
                    <div style={{display:"flex", height: "100%", alignItems: "center", marginTop: "auto", marginBottom: "auto"}}>
                      <div className={"text12-600"} style={{marginLeft: 10, lineHeight: 1.4, fontSize: 11}}>
                      Automatic analysis for this part is not available, it will be quoted manually.
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                {item.partType !== "sheetMetal" && <Col span={24} style={{height: 62}}>
                  <span className={"text12-500-12"}>Standard</span>
                  <Form.Item
                    name={"standard"}
                    initialValue={item.standard}
                    style={{marginBottom: 0}}
                  >
                    <Input
                      size={'large'}
                      className={"text12-500"}
                      placeholder={"eg. ISO 4762"}
                      onChange={(e) => {changePartStandard({orderId: orderId, id: item.id, standard: e.target.value})}}
                    />
                  </Form.Item>
                </Col>}
                <Col span={24}>
                  <span className={"text12-500-12"}>Note (Optional)</span>
                  <Form.Item
                    name={"note"}
                    initialValue={item.note}
                  >
                    <Input
                      size={'large'}
                      className={"text12-500"}
                      placeholder={"Notes and additional requirements"}
                      onChange={(e) => changePartNote({orderId: orderId, id: item.id, note: e.target.value})}
                    />
                  </Form.Item>
                </Col>
              </div>
            )
            }
          </Row>
        </Col>
        <Col flex="1 1 38%">
          <List
            style={item.coating.hasCoating ? {marginLeft: 10, minWidth: 300} : {marginLeft: 10, minWidth: 300}}
          >

            <List.Item style={{paddingTop: 4, paddingBottom: 4}}>
              <div
                style={{display: 'flex', justifyContent: "space-between", width: '100%'}}
              >
                <div>
                  <MinusOutlined style={{color: '#bdbdbd'}}/>
                  <span
                    className={"text14-400"}
                    style={{color: '#bdbdbd', marginLeft: 8}}
                  >
                    Cutting
                  </span>
                </div>
              </div>
            </List.Item>
            <List.Item style={{paddingTop: 4, paddingBottom: 4}}>
              <div
                style={{display: 'flex', justifyContent: "space-between", width: '100%'}}
              >
                <div>
                  <MinusOutlined style={{color: '#bdbdbd'}}/>
                  <span
                    className={"text14-400"}
                    style={{color: '#bdbdbd', marginLeft: 8}}
                  >
                    Bending
                  </span>
                </div>
              </div>
            </List.Item>
            <List.Item style={{paddingTop: 4, paddingBottom: 4}}>
              <div
                style={{display: 'flex', justifyContent: "space-between", width: '100%'}}
              >
                <div>
                  <span>{<MinusOutlined style={{color: '#bdbdbd'}}/>}</span>
                  <span
                    className={"text14-400"}
                    style={{color: '#bdbdbd', marginLeft: 8}}
                  >
                    Countersinking
                  </span>
                </div>
              </div>
            </List.Item>
            <List.Item style={{paddingTop: 4, paddingBottom: 4}}>
              <div
                style={{display: 'flex', justifyContent: "space-between", width: '100%'}}
              >
                <div>
                  <span>{<MinusOutlined style={{color: '#bdbdbd'}}/>}</span>
                  <span
                    className={"text14-400"}
                    style={{color: '#bdbdbd', marginLeft: 8}}
                  >
                    Sloped Edges
                  </span>
                </div>
              </div>
            </List.Item>
            <List.Item style={{paddingTop: 4, paddingBottom: 4}}>
              <div
                style={{display: 'flex', justifyContent: "space-between", width: '100%'}}
              >
                <div style={{marginTop: 7}}>
                  <span>{item.coating.hasCoating ? <CheckOutlined style={{color: '#00C853'}}/> : <MinusOutlined style={{color: '#bdbdbd'}}/>}</span>
                  <span
                    className={"text14-400"}
                    style={item.coating.hasCoating ? {fontWeight: 500, marginLeft: 8} : {color: '#bdbdbd', marginLeft: 8}}
                  >
                    Coating
                  </span>
                </div>
                <div style={{marginRight: 15}}>
                  <div style={{display: 'flex', justifyContent: "space-between"}}>
                    <CoatingPicker
                      partId={item.id}
                      changeColor={changePartColor}
                      hasCoating={item.coating.hasCoating}
                      colorRAL={item.coating.colorRAL}
                      colorGlossId={item.coating.colorGlossId}
                      ralRow={item.coating.ralRow}
                      ralHex={item.coating.ralHex}
                    />
                    { item.coating.hasCoating &&
                      <IconButton
                        size={'small'}
                        aria-label="clear"
                        style={{marginLeft: 5, width: 25, height: 25, marginRight: -8, marginTop: 4}}
                        onClick={()=>{
                          handleColorRemove(item.id)
                        }}
                      >
                        <CloseIcon style={{ fontSize: 14, color: '#fc505a'}}/>
                      </IconButton>
                    }
                  </div>
                </div>
              </div>
            </List.Item>
            <List.Item>
              <div style={{width: '100%'}}>
                <table style={{width: "100%", height: "100%"}}>
                  <tbody style={{height: "100%"}}>
                  <tr style={{height: "100%"}}>
                    <td style={{width: "67%", maxWidth: 150, height: "100%"}}>
                      <div style={{display: "flex", height: '100%'}}>
                        {item.drawingPDFFile?.drawingPDFFileName ?
                          <div
                            style={{border: "1px dashed #1890ff", padding: "8px 10px 8px 10px", borderRadius: 5, marginBottom: -10, height: 40, width: "100%"}}
                          >
                            <div
                              style={{display: "flex", justifyContent: "space-between", width: "100%"}}
                            >
                              <div
                                style={{display: "flex", width: "calc(100% - 20px)"}}
                              >
                                <div>
                                  <FilePdfOutlined style={{color: "#1890ff", marginRight: 4}}/>
                                </div>
                                <div
                                  className={"addDrawingText ellipsisText"}
                                  style={{color: "#1890ff", marginTop: 4, cursor: "default"}}
                                >
                                  {item.drawingPDFFile.drawingPDFFileName}
                                </div>
                              </div>
                              <div style={{marginTop: 0, justifyContent: "flex-end", alignItems: "center"}}>
                                <IconButton
                                  size={'small'}
                                  aria-label="clear"
                                  style={{width: 18, height: 18, marginRight: -3, marginTop: -1}}
                                  onClick={()=> handleRemovePartDrawing() }
                                >
                                  <CloseIcon style={{ fontSize: 14, color: '#fc505a'}}/>
                                </IconButton>
                              </div>
                            </div>
                          </div>
                          :
                          <Spin
                            spinning={uploadingDrawing}
                            wrapperClassName={"documentUploadSpin"}
                            indicator={<LoadingOutlined style={{ fontSize: 30, marginLeft: -71, marginTop: -17 }} spin />}
                          >
                            <Upload
                              showUploadList={false}
                              accept=".pdf"
                              beforeUpload={(file) => {
                                addPartDrawing(file)
                                return false
                              }}
                            >
                              <Button
                                style={{border: "1px dashed", borderRadius: 5, marginBottom: -10, height: '100%', paddingInline: 8}}
                                icon={<DrawingIcon2 className={"drawingIcon"}/>}
                              >
                                <span
                                  className={"addDrawingText"}
                                  style={{position: 'relative', top: -3}}
                                >
                                  Add part drawing
                                </span>
                              </Button>
                            </Upload>
                          </Spin>
                        }
                      </div>
                    </td>
                    <td style={{width: "33%", paddingLeft: 10}}>
                      {bulkItem ?
                        (<div style={{minWidth: 180, display: "flex", justifyContent: "flex-end"}}>
                          <div style={{marginRight: 15, display: 'flex', justifyContent: "space-between", textAlign: 'right'}}>
                            <div style={{marginRight: 30}}>
                              <div className={"text12-400"}>Price per piece</div>
                              <div className={"priceText"}
                                   style={{paddingTop: 6.2}}
                              >
                                <Form.Item
                                  className={"priceInputField"}
                                  name={"price"}
                                  initialValue={(item.pricePerPiece || 0).toFixed(2)}
                                >
                                  <InputNumber formatter={(value, info) => info.userTyping ? `${value}` : `${Number(value).toFixed(2)} €`}
                                               parser={value => value ? value.replace('€', '') : 0}
                                               min={0}
                                               onChange={(p) => changePrice({orderId: orderId, id: item.id, quantity: item.quantity, price: p})}
                                               size={"small"}
                                               style={{ width: 115 }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div>
                              <div className={"priceText"}>Total</div>
                              <div className={"priceText"} style={{paddingTop: 6, whiteSpace: "nowrap"}}>
                                {(item.pricePerPiece * item.quantity).toFixed(2)} €
                              </div>
                            </div>
                          </div>
                        </div>
                        ) : (
                          <div style={{marginRight: 15, display: 'flex', justifyContent: "flex-end", textAlign: 'right'}}>
                            <div>
                              <div className={"priceText"}>Total</div>
                              <div className={"priceText"} style={{paddingTop: 6}}>Manual RFQ</div>
                            </div>
                          </div>
                        )
                      }
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </List.Item>
          </List>
        </Col>
      </Row>
    </Form>
  )
}

ManualOrderItem.propTypes = {
  item: PropTypes.object,
  changePartMaterial: PropTypes.func,
  changePartQuantity: PropTypes.func,
  changePartThickness: PropTypes.func,
  changePartNote: PropTypes.func,
  changePartColor: PropTypes.func,
  handleColorRemove: PropTypes.func,
};