import React from "react";
import {Button, Card, Col, Row, Form, Input, Select} from "antd";
import {
  BankOutlined,
  CiCircleOutlined, GlobalOutlined, HomeOutlined,
  MailOutlined,
  PercentageOutlined, PhoneOutlined, UserOutlined,
} from "@ant-design/icons";
import {countries} from "../../../../data/countries";

export const CompanyForm = (props) => {
  const { isRegistering } = props;

  const handleClick = async (values) => {
    props.handleClick({
      email: values.registerCompanyEmail,
      name: values.registerCompanyName,
      country: values.registerCompanyCountry,
      region: values.registerCompanyRegion,
      city: values.registerCompanyCity,
      address: values.registerCompanyAddress,
      postalCode: values.registerCompanyPostalCode,
      vat: values.registerCompanyVat,
      uid: values.registerCompanyUid,
      contactName: values.registerCompanyContactName,
      phone: values.registerCompanyPhone,
    })
  }

  return (
    <Row>
      <Col span={24}>
        <Form
          name={'register-company-personal'}
          onFinish={handleClick}
        >
          <Card
            size={"small"}
            bodyStyle={{paddingBottom: 10}}
          >
            <div align={"left"}>
              <Row gutter={18}>
                <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                  <span className={"loginLabel"}>Email</span>
                  <Form.Item
                    name={'registerCompanyEmail'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'email', message: 'Please enter a valid email address'},
                    ]}
                    validateTrigger={'onSubmit'}

                  >
                    <Input
                      placeholder="Enter your email address"
                      prefix={<MailOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                  <span className={"loginLabel"}>Country</span>
                  <Form.Item
                    name={'registerCompanyCountry'}
                    rules={[
                      {required: true, message: 'This field is required'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >

                    <Select
                      showSearch
                      style={{width: '100%'}}
                      prefix={<GlobalOutlined />}
                      placeholder="Select your country"
                      optionFilterProp="children"
                      notFoundContent={"Country not found"}
                      filterOption={(input, option) =>
                        option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      autoComplete="new-password"
                    >
                      {
                        countries.map(item => {
                          return (
                            <Select.Option key={item.name} value={item.name} className={"countrySelect"}>
                              <img src={item.flag} alt="" style={{position: "relative", top: -2, width: 18}}/> {item.name}
                            </Select.Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <span className={"loginLabel"}>Company ID</span>
                  <Form.Item
                    name={"registerCompanyUid"}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 3 ,message: 'Too short!'},
                      {type: 'string', max: 60 ,message: 'Too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      placeholder="Enter your company id number"
                      prefix={<CiCircleOutlined />}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={18}>
                <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                  <span className={"loginLabel"}>Company Name</span>
                  <Form.Item
                    name={'registerCompanyName'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 2 ,message: 'Too short!'},
                      {type: 'string', max: 60 ,message: 'Too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      placeholder="Enter your company name"
                      prefix={<BankOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                  <span className={"loginLabel"}>Region</span>
                  <Form.Item
                    name={'registerCompanyRegion'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 2 ,message: 'City must be 2 characters or longer'},
                      {type: 'string', max: 60 ,message: 'City name too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      placeholder="Enter your region"
                      prefix={<GlobalOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <span className={"loginLabel"}>VAT</span>
                  <Form.Item
                    name={'registerCompanyVat'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 3 ,message: 'Too short!'},
                      {type: 'string', max: 60 ,message: 'Too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      placeholder="Enter your VAT number"
                      prefix={<PercentageOutlined />}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={18}>
                <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                  <div style={{height: 74}}>
                    <span className={"loginLabel"}>Address</span>
                    <Form.Item
                      name={'registerCompanyAddress'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 2 ,message: 'Too short!'},
                        {type: 'string', max: 60 ,message: 'Too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                    >
                      <Input
                        placeholder="Enter your address"
                        prefix={<HomeOutlined />}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                  <span className={"loginLabel"}>City</span>
                  <Form.Item
                    name={'registerCompanyCity'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 2 ,message: 'Too short!'},
                      {type: 'string', max: 60 ,message: 'Too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      placeholder="Enter your city"
                      prefix={<GlobalOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <span className={"loginLabel"}>Contact Name</span>
                  <Form.Item
                    name={'registerCompanyContactName'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 2 ,message: 'Too short!'},
                      {type: 'string', max: 60 ,message: 'Too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      placeholder="Enter your contact first name"
                      prefix={<UserOutlined />}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={18}>
                <Col span={8} style={{borderRight: "1px solid #dadada", height: 55}}>

                </Col>
                <Col span={8} style={{borderRight: "1px solid #dadada", height: 55}}>
                  <div style={{height: 74}}>
                    <span className={"loginLabel"}>Postal Code</span>
                    <Form.Item
                      name={'registerCompanyPostalCode'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 3 ,message: 'Too short!'},
                        {type: 'string', max: 60 ,message: 'Too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                    >
                      <Input
                        prefix={<GlobalOutlined />}
                        placeholder="Enter your postal code"
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={8}>
                  <span className={"loginLabel"}>Phone</span>
                  <Form.Item
                    name={'registerCompanyPhone'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 6 ,message: 'Too short!'},
                      {type: 'string', max: 60 ,message: 'Too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      placeholder="Enter your contact phone number"
                      prefix={<PhoneOutlined />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Card>
          <Form.Item>
            <div style={{display: "flex", width: '100%', justifyContent: "center"}}>
              <Button
                style={{width: '100%', marginTop: '10px', maxWidth: 500}}
                type="primary"
                htmlType="submit"
                className={"loginText"}
                loading={isRegistering}
              >
                Create Customer
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}


