import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Card, Radio, Spin } from 'antd'
import { CustomerForm } from './Components/CustomerRegistration'

import { CompanyForm } from "./Components/CompanyRegistration";
import logo from '../../assets/images/logo.svg'
import hLogo from '../../assets/logo/H.svg'
import { LinkWrapper, CardTitle, Logo } from './styles'
import { registerCustomer, registerCompany, getCompanyBasicInfo, checkPermissions } from '../../actions/authActions'
import { Link } from '../../components'
import "./style.css"

import HefeosLogo from "../../assets/Hefeos-IQT-Dark.svg";
import {HOME_PAGE_URL, S3_BUCKET_URL} from "../../constants";
import logoPlaceholder from "../../assets/images/logo-placeholder.svg";

import {LoadingOutlined, LockOutlined, UserOutlined} from "@ant-design/icons";
import DownChevron from "../../assets/down-chevron.svg";

const RegisterWrapper = (props) => {
  const {selected, isRegistering, handleClick} = props;
  return (
    <div style={{transition: "width 0.1s linear", width: selected === 'customer' ? 680 : 750}}>
      {selected === 'customer' ?
        (
          <CustomerForm
            handleClick={handleClick}
            isRegistering={isRegistering}
          />
        )
        :
        (
          <CompanyForm
            handleClick={handleClick}
            isRegistering={isRegistering}
          />
        )
      }
    </div>
  );
}

class RegisterPage extends Component {
  static propTypes = {
    registering: PropTypes.bool,
    registeringCompany: PropTypes.bool,
    registerCustomer: PropTypes.func,
    registerCompany: PropTypes.func,
  }

  state = {
    selected: 'customer',
    hasOrderPermission: false,
    basicInfo: {
      name: "",
      country: "",
      logoUrl: "",
      showLoginForm: true
    },
    isFetchingPermissions: true,
    isFetchingDomain: false
  }

  componentDidMount() {
    this.setState({
      isFetchingPermissions: true
    })
    this.props.checkPermissions({}).then((hasOrderPermission)=>{
      this.setState({
        isFetchingPermissions: false,
        hasOrderPermission: hasOrderPermission
      })
    }).catch(()=>{
      this.setState({
        isFetchingPermissions: false,
      })
      window.location.replace(HOME_PAGE_URL)
    });
    this.setState({
      isFetchingDomain: true
    })
    this.props.getCompanyBasicInfo({}).then((basicInfo)=>{
      this.setState({
        isFetchingDomain: false
      })
      this.setState({
        basicInfo: {
          name: basicInfo.name,
          country: basicInfo.country,
          logoUrl: `${S3_BUCKET_URL}${basicInfo.logoUrl}`,
          showLoginForm: true
        },
      });
      document.title = `${basicInfo.name} @ Hefeos`
    }).catch(()=>{
      this.setState({
        basicInfo: {
          name: 'Company not found',
          country: '',
          logoUrl: logoPlaceholder,
          showLoginForm: false,
        },
      });
    });
  }

  handleClick = (form) => {
    const { registerCustomer } = this.props
    return registerCustomer({ ...form })
  }

  handleCompanyClick = (form) => {
    const { registerCompany } = this.props
    return registerCompany({ ...form })
  }

  handleSizeChange = e => {
    this.setState({ selected: e.target.value })
  }

  render() {
    const { isRegistering, isRegisteringCompany, history } = this.props
    const { basicInfo } = this.state;
    const {isFetchingPermissions, hasOrderPermission, isFetchingDomain} = this.state

    return (
      <div className={"publicWrapper"}>
        <div className={"tiledBackground"}>
          <div style={{height: "100%", display: "flex", minHeight: 750}}>
            <Card className={"registerCard"}>
              <Spin
                spinning={isFetchingPermissions || isFetchingDomain}
                wrapperClassName={"loginLoadingWrapper"}
                indicator={<LoadingOutlined style={{ fontSize: 82, marginLeft: -41, marginTop: 10 }} spin />}
              >
                <div style={{textAlign: 'center', marginBottom: "3em"}}>
                  <div><a href="https://hefeos.com" target="_blank"><img src={HefeosLogo} alt="Hefeos Logo" width={300}/></a></div>
                  <div><img src={DownChevron} alt="arrow down" width={30} style={{marginTop: "2em", marginBottom: "0.5em"}} /></div>
                  <Logo src={basicInfo.logoUrl} alt={"Company Logo"}/>
                  <h2>{basicInfo.name}</h2>
                  <h4>{basicInfo.country}</h4>

                  {!basicInfo.showLoginForm ? <a href = {HOME_PAGE_URL}>Go to Hefeos home</a> : ''}
                </div>

                <CardTitle className={"signInText"} style={{marginBottom: 5}}>Sign up as Customer</CardTitle>
                <div align={'center'} style={{marginBottom: 10}}>
                  <Radio.Group
                    value={this.state.selected}
                    buttonStyle="solid"
                    onChange={this.handleSizeChange}
                    className={"loginText regSwitch"}
                  >
                    <Radio.Button value="customer">Personal Account</Radio.Button>
                    <Radio.Button value="company">Company Account</Radio.Button>
                  </Radio.Group>
                </div>
                <RegisterWrapper
                  selected={this.state.selected}
                  isRegistering={this.state.selected === "customer" ? isRegistering : isRegisteringCompany}
                  handleClick={this.state.selected === "customer" ? this.handleClick : this.handleCompanyClick}
                />
                <LinkWrapper>
                  <Link to="/login" history={history}>
                    Already have an account? Log in!
                  </Link>
                </LinkWrapper>
                <LinkWrapper style={{marginTop: "2em"}}>
                  <a  style={{fontWeight: 600, fontSize: 13}} href="https://hefeos.com" target="_blank">
                    What is Hefeos Instant Quoting Tool?
                  </a>
                </LinkWrapper>
              </Spin>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  redirect: state.auth.redirect,
  isRegistering: state.auth.registering,
  isRegisteringCompany: state.auth.registeringCompany,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ registerCustomer, registerCompany, getCompanyBasicInfo, checkPermissions }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
)
