import React from 'react'
import {Layout, Row, Menu} from 'antd'
import {
    BankOutlined,
    HomeOutlined,
    IdcardOutlined, LogoutOutlined,
    ProfileOutlined,
    TeamOutlined,
    UserOutlined
} from "@ant-design/icons";
import history from "../../../utils/history";

const SubMenu = Menu.SubMenu
const { Sider } = Layout;

let selectedField = '1';

function activeComponent(history) {
    switch (history.location.pathname)
    {
        case('/'):
            selectedField = '1';
            break;
        case('/customers'):
            selectedField = '2';
            break;
        case('/corporate'):
            selectedField = '3';
            break;
        case('/producers'):
            selectedField = '4';
            break;
        case('/logs'):
            selectedField = '5';
            break;
        default:
            selectedField = '1';
    }
    return selectedField;
}

class AdminSidebar extends React.Component {

    render() {
        const {onLogout} = this.props
        return(
        <Sider
            style={{
                overflow: 'auto',
                minHeight: '100vh',
                position: 'fixed',
                left: 0,
            }}
            defaultCollapsed={true}
        >
            <Row style={{marginTop: '2rem'}}>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[activeComponent(history)]}
                >
                    <Menu.Item
                        key="1"
                        onClick={() => {
                            history.push('/')
                        }}>
                        <HomeOutlined />
                        <span className="nav-text">Home</span>
                    </Menu.Item>
                    <SubMenu key="sub1" title={<span><TeamOutlined /><span>Customers</span></span>} >
                        <Menu.Item
                            key="2"
                            onClick={() => {
                                history.push('/customers')
                            }}>
                            <UserOutlined />
                            <span className="nav-text">Regular customers</span>
                        </Menu.Item>
                        <Menu.Item
                            key="3"
                            onClick={() => {
                                history.push('/corporate')
                            }}>
                            <IdcardOutlined />
                            <span className="nav-text">Corporate customers</span>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                        key="4"
                        onClick={() => {
                            history.push('/producers')
                        }}>
                        <BankOutlined />
                        <span className="nav-text">Producers</span>
                    </Menu.Item>
                    <Menu.Item
                        key="5"
                        onClick={() => {
                            history.push('/logs')
                        }}>
                        <ProfileOutlined />
                        <span className="nav-text">Logs</span>
                    </Menu.Item>
                    <Menu.Item key="6" onClick={onLogout}>
                        <LogoutOutlined />
                        <span className="nav-text">Sign out</span>
                    </Menu.Item>
                </Menu>
            </Row>
        </Sider>
    )}
}

export default AdminSidebar
