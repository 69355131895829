import * as React from 'react';
import {AutoSizer, MultiGrid} from 'react-virtualized';
import "./style.css";
import "../tableStyle.css";
import {Checkbox} from "antd";
import {EmptyTable} from "../EmptyTable";
import {Trans} from 'react-i18next';

const STYLE = {
  borderRadius: '0 0 5px 0'
};

export default class CheckboxTable extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.state = {
      fixedColumnCount: 1,
      fixedRowCount: 1,
      scrollToColumn: 0,
      scrollToRow: 0,
      rowLength: this.props.rows.length
    };

    this._cellRenderer = this._cellRenderer.bind(this);
    this._onScrollToRowChange = this._createEventHandler('scrollToRow');
    // this.changeValue = debounce(this.changeValue, 500);
  }

  getClassName = (rowIndex, columnIndex) => {
    const {columns, rows, allAvailable} = this.props
    if (rowIndex > this.state.rowLength) {
      return "laserSpeedDisabledRow"
    }
    if (rowIndex === 0) {
      return "laserSpeedZeroRow"
    } else {
      if (columnIndex === 0) {
        return "laserSpeedLeftColumn"
      } else {
        let row = rows[rowIndex - 1]
        let name = row[0]
        let value = row[columnIndex]
        if (!value) {
          if (allAvailable[name][columns[columnIndex]]) {
            return "laserSpeedOddRow"
          } else {
            return "laserSpeedDisabledRow"
          }
        } else {
          return "laserSpeedOddRow"
        }
      }
    }
  }

  refreshGrid = () => {
    if(this.multiGridRef) {
      this.multiGridRef.forceUpdateGrids()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevProps.updateTable && this.props.updateTable) {
      this.props.setUpdateTable(false)
      this.refreshGrid()
    }
  }

  render() {
    const {columns, rows} = this.props

    if (rows.length === 0) {
      return <EmptyTable />
    } else {
      return (
        <React.Fragment>
          <AutoSizer disableHeight>
            {({width}) => (
              <MultiGrid
                ref={(grid) => {
                  this.multiGridRef = grid;
                }}
                {...this.state}
                cellRenderer={this._cellRenderer}
                columnWidth={this._getColumnWidth}
                columnCount={Object.keys(columns).length}
                enableFixedColumnScroll
                enableFixedRowScroll
                height={260}
                rowHeight={35}
                rowCount={rows.length < 6 ? 7 : rows.length+1}
                style={STYLE}
                classNameTopLeftGrid={"topLeftGrid"}
                classNameTopRightGrid={"topRightGrid"}
                classNameBottomLeftGrid={"bottomLeftGrid"}
                classNameBottomRightGrid={"bottomRightGrid"}
                width={width}
                hideTopRightGridScrollbar
                hideBottomLeftGridScrollbar
              />
            )}
          </AutoSizer>
        </React.Fragment>
      );
    }
  }

  _cellRenderer({columnIndex, key, rowIndex, style}) {
    const {columns, rows, allAvailable, tabIndex, onChange, groupName} = this.props
    return (
      <div className={this.getClassName(rowIndex, columnIndex)}
           key={key} style={{
        ...style,
        padding: 5,
        borderTop: rowIndex === 0 ? "1px solid #888888" : "none",
        borderBottom: '1px solid #888888',
        borderRight: '2px solid #001529',
        display: 'inline-block',
        alignItems: 'center',
        right: -2
      }}>
        <div style={{fontSize: 13}} className={"cellText"}>
          {rowIndex === 0 ?
            <div className={"speedTableCellText"}>{columns[columnIndex]}</div>
            :
            (this.state.rowLength < rowIndex ?
              null
              :
              (columnIndex === 0 ?
                <div className={"speedTableCellLeft yesSelect"}><Trans>{rows[rowIndex - 1][columnIndex]}</Trans></div>
                :
                <div>
                  {allAvailable[rows[rowIndex - 1][0]][columns[columnIndex]] ?
                    <div style={{display: 'flex', justifyContent: "center", marginTop: 1}}>
                      <Checkbox checked={rows[rowIndex - 1][columnIndex]} onChange={e => {
                        onChange({
                          value: e.target.checked,
                          row: rowIndex - 1,
                          col: columnIndex,
                          tab: tabIndex,
                          materialId: allAvailable[rows[rowIndex - 1][0]][columns[columnIndex]],
                          groupName: groupName
                        })
                        this.refreshGrid()
                      }}/>
                    </div>
                    :
                    null}
                </div>
              ))
          }
        </div>
      </div>
    );
  }

  _getColumnWidth = ({index}) => {
    return index === 0 ? 140 : 50
  };

  _createEventHandler(property) {
    return event => {
      const value = parseInt(event.target.value, 10) || 0;

      this.setState({
        [property]: value,
      });
    };
  }
}