import React from "react";
import {List} from "antd";
import {ProcessedItemCard, ProcessingItemCard, UploadingItemCard} from "../OrderItemCards";


export const OrderPartsList = (props) => {
  const {
    orderId, items, selectedItems, setSelectedItems, setSelectedItemsCount,
    selectedItemsCount, setSelectedAllItems, selectedAllItems,
    handleMaterialFilter, handleRemoveItem, materials,
    changePartMaterial, changePartThickness, changePartQuantity,
    changePartNote, changePartColor, handleColorRemove,
    showItem, handleChangeManualItemType, bulkItem, adjustment
  } = props

  const onCheck = (item, checked) => {
    setSelectedItems({...selectedItems, [item.id]: checked})
    setSelectedItemsCount(checked ?
      selectedItemsCount + 1 :
      selectedItemsCount - 1
    )
    setSelectedAllItems(!checked ? false : selectedAllItems)
    handleMaterialFilter(item, checked)
  }

  return (
    <List
      dataSource={items}
      key={"id"}
      style={{marginTop: 10}}
      renderItem={item => {
        switch (item.state) {
          case "processed":
            return (
              <ProcessedItemCard
                orderId={orderId}
                item={item}
                materials={materials}
                changePartMaterial={changePartMaterial}
                changePartThickness={changePartThickness}
                changePartQuantity={changePartQuantity}
                changePartNote={changePartNote}
                changePartColor={changePartColor}
                handleColorRemove={handleColorRemove}
                handleRemoveItem={handleRemoveItem}
                showItem={showItem}
                selectedItems={selectedItems}
                onCheck={onCheck}
                handleChangeManualItemType={handleChangeManualItemType}
                bulkItem={bulkItem}
                isOrderDetails={false}
                parameterMissMatch={false}
                adjustment={adjustment}
              />
            )
          case "uploaded":
          case "processing":
            return (
              <ProcessingItemCard item={item} />
            )
          case "uploading":
            return (
              <UploadingItemCard item={item}/>
            )
          default:
            return (<div/>)
        }
      }}
    />
  );
}