import {
  CUSTOMERS_CUSTOMERS,
  CUSTOMERS_FETCHING_CUSTOMERS,
  CUSTOMERS_CUSTOMERS_PAGINATION,
  CUSTOMER_FETCHING_CUSTOMER_DETAILS,
  CUSTOMER_SET_IS_REMOVING_CUSTOMER,
  CUSTOMER_REMOVE_CUSTOMER, CUSTOMER_BLOCK_CUSTOMER, CUSTOMER_TOGGLING_BLOCK,
} from '../actions/actionTypes'
import initialState from './initialState'

const customersReducer = (state = initialState.customers, action) => {
  switch (action.type) {
    case CUSTOMERS_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      }

    case CUSTOMERS_CUSTOMERS_PAGINATION:
      return {
        ...state,
        customersPagination: action.payload,
      }

    case CUSTOMERS_FETCHING_CUSTOMERS:
      return {
        ...state,
        fetchingCustomers: action.payload,
      }

    case CUSTOMER_FETCHING_CUSTOMER_DETAILS:
      return {
        ...state,
        fetchingCustomerDetails: action.payload,
      }

    case CUSTOMER_SET_IS_REMOVING_CUSTOMER:
      return {
        ...state,
        removingCustomer: action.payload
      }

    case CUSTOMER_REMOVE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter(item => item.id !== action.payload)
      }

    case CUSTOMER_BLOCK_CUSTOMER:
      return {
        ...state,
        customers: state.customers.map(item => {
          if (item.id === action.payload) {
            return {...item, isBlocked: !item.isBlocked}
          }
          return item
        })
      }

    case CUSTOMER_TOGGLING_BLOCK:
      return {
        ...state,
        togglingCustomerBlock: action.payload
      }

    default:
      return state
  }
}

export default customersReducer