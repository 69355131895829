import React from "react";
import {Button, Col, Radio, Row, Slider, Spin, Modal, Progress, Card} from "antd";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import {LeftMouse} from "../../../assets/icons/leftMouse";
import {MiddleMouse} from "../../../assets/icons/middleMouse";
import {RightMouse} from "../../../assets/icons/rightMouse";
import CubeView from "../../../containers/admin/TestPage/cubeview";
import DxfParser from "dxf-parser";
import * as ThreeDxf from "../../../containers/admin/TestPage/three-dxf";
import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import axios from "axios";
import {S3_BUCKET_URL} from "../../../constants";
import "./style.css"
import sheet from "../../../assets/icons/bendIcon.webp";
import counter from "../../../assets/icons/counterSinkIcon.webp";
import slope from "../../../assets/icons/slopedEdgeIcon.webp";
import {DropIcon} from "../../../assets/icons/drop";
import {calcAdjustment, getManualType} from "../../../utils/utility";
import {Trans} from "react-i18next";
// let STLLoader = require("three-stl-loader")(THREE);
let OrbitControls = require("react-cubeview/lib/OrbitControls")(THREE);
let scene;
let camera;
let renderer;
let cube;
let container;
let controls;
let currphi;
let currtheta;
let currphi2;
let currtheta2;

let mesh_folded_solid;
let mesh_folded_line;
let mesh_folded_clip_line;

let mesh_unfolded_solid;
let mesh_unfolded_line;
let mesh_unfolded_clip_line;

let materialSolidSolid;
let materialSolidDark;
let materialSolidXray;
let materialSolidLight;
let materialLineSolid;
let materialLineXray;


let clippingPlaneLen = 0;
let localPlane;
let localPlaneUpper;
let localPlaneLower;

let gridHelper;
let gridHelper2;

let part_folded = null;
let part_unfolded = null;

let frameId;

export default class ItemVisualisation extends React.Component {

  state = {
    loading: true,
    unfoldVisible: false,
    slider: 100,
    progress: 0,
    loadingProgress: 0,
    foldLoadingProgress: 0,
    unfoldLoadingProgress: 0,
    selectedRadio: 'solid',
    foldLoaded: false,
    unfoldLoaded: false,
    working: false
  }

  componentDidUpdate(prevProps,prevState,snapshot) {
    if (this.props.isModelViewVisible === true && prevProps.isModelViewVisible === false) {
      this.setState({loading: true});
      let self = this
      if(this.props.itemView) {
        if (this.props.itemView.fileType === "step") {
          let num = this.props.itemView.processingType === "automatic" && self.props.itemView.stepData.filePaths.unfoldedStlFilePath && this.props.itemView.stepData.partData?.hasBends ? 25 : 50
          axios({
            method: 'get',
            url: `${S3_BUCKET_URL}${this.props.itemView.stepData.filePaths.foldedStlFilePath}`,
            responseType: 'arraybuffer',
            onDownloadProgress(progressEvent) {
              let loadingProgress = (progressEvent.loaded / progressEvent.total) * num
              self.setState({loadingProgress})
            }
          })
          .then(response => {
            part_folded = URL.createObjectURL(new File([response.data], "part_folded.stl"));
            if(this.props.itemView.processingType === "automatic" && this.props.itemView.stepData.filePaths.unfoldedStlFilePath && this.props.itemView.stepData.partData?.hasBends) {
              axios({
                method: 'get',
                url: `${S3_BUCKET_URL}${this.props.itemView.stepData.filePaths.unfoldedStlFilePath}`,
                responseType: 'arraybuffer',
                onDownloadProgress(progressEvent) {
                  let loadingProgress = (progressEvent.loaded / progressEvent.total) * 25 + 25
                  self.setState({loadingProgress})
                }
              })
                .then(response => {
                  part_unfolded = URL.createObjectURL(new File([response.data], "part_unfolded.stl"));
                  this.showFile();
                }).catch( error => {
                  console.log(error)
                })
            } else {
              this.showFile();
            }
          }).catch( error => {
            console.log(error)
          })
        }
        else {
          axios({
            method: 'get',
            url: `${S3_BUCKET_URL}${this.props.itemView.s3FilePath}`,
            onDownloadProgress(progressEvent) {
              let num = 100
              let loadingProgress = (progressEvent.loaded / progressEvent.total) * num
              self.setState({loadingProgress})
            }
          })
          .then(response => {
            this.setState({loading: false});
            let parser = new DxfParser();
            let dxf = parser.parseSync(response.data);
            let width = this.props.itemView.viewType === "model" ? this.props.modalWidth + 30 : this.props.modalWidth - 350
            new ThreeDxf.Viewer(
              dxf,
              document.getElementById('cad-view'),
              width,
              this.props.modalHeight,
              [],
              null
            );
          }).catch( error => {
            console.log(error)
          })
        }
      }

    }

    if (
      this.props.isModelViewVisible &&
      renderer !== undefined &&
      camera !== undefined &&
      (this.props.modalHeight !== prevProps.modalHeight || this.props.modalWidth !== prevProps.modalWidth)
    ) {
      let width = this.props.modalWidth + 30
      if(this.props.itemView.viewType === "details") {
        width = this.props.modalWidth < 800 ? 450 : this.props.modalWidth - 350
      }
      renderer.setSize(width, this.props.modalHeight);
      camera.aspect = (width) / this.props.modalHeight;
      camera.updateProjectionMatrix()
      this.forceUpdate()
    }
    if (this.props.isModelViewVisible === false && prevProps.isModelViewVisible === true) {
      cancelAnimationFrame( frameId );
      if(renderer && container) {
        container.removeChild(renderer.domElement);
        renderer = undefined
      }
      part_unfolded = null
      this.setState(
        {
          loading: true,
          unfoldVisible: false,
          slider: 100,
          loadingProgress: 0,
          foldLoadingProgress: 0,
          unfoldLoadingProgress: 0,
          selectedRadio: 'solid',
        });
    }
  }

  showUnfold = () => {
    let xray = this.state.selectedRadio === 'xray';
    let loader = new STLLoader();
    let self = this
    self.setState({
      working: true
    })
    loader.load( part_unfolded, function ( geometry ) {
      if(!xray) {
        mesh_unfolded_line = new THREE.LineSegments(new THREE.EdgesGeometry(geometry, 20), materialLineSolid);
        mesh_unfolded_solid = new THREE.Mesh( geometry, materialSolidSolid );
        mesh_unfolded_clip_line = new THREE.Mesh( geometry, materialSolidDark );
      }
      else {
        mesh_unfolded_line = new THREE.LineSegments(new THREE.EdgesGeometry(geometry, 20), materialLineXray);
        mesh_unfolded_solid = new THREE.Mesh( geometry, materialSolidXray );
        mesh_unfolded_clip_line = new THREE.Mesh( geometry, materialSolidLight );
      }


      mesh_unfolded_solid.position.set( 0, 0, 0);
      mesh_unfolded_solid.rotation.x = -(Math.PI / 2);
      mesh_unfolded_solid.scale.set( 0.1, 0.1, 0.1 );
      mesh_unfolded_solid.castShadow = true;
      mesh_unfolded_solid.receiveShadow = true;


      mesh_unfolded_line.position.set( 0, 0, 0);
      mesh_unfolded_line.rotation.x = -(Math.PI / 2);
      mesh_unfolded_line.scale.set( 0.1, 0.1, 0.1 );

      let bbox = new THREE.Box3().setFromObject(mesh_unfolded_solid);

      mesh_unfolded_clip_line.position.set( 0, 0, 0);
      mesh_unfolded_clip_line.rotation.x = -(Math.PI / 2);
      mesh_unfolded_clip_line.scale.set( 0.1, 0.1, 0.1 );


      mesh_unfolded_solid.material.clippingPlanes = [localPlane]
      mesh_unfolded_line.material.clippingPlanes = [localPlane]
      mesh_unfolded_clip_line.material.clippingPlanes = [localPlaneLower,localPlaneUpper]

      clippingPlaneLen = (bbox.max.y - bbox.min.y) * 1.01
      localPlane.constant = clippingPlaneLen

      localPlaneLower.constant = -clippingPlaneLen*0.996
      localPlaneUpper.constant =  clippingPlaneLen

      mesh_unfolded_clip_line.geometry.center();
      mesh_unfolded_clip_line.position.set(0,(bbox.max.y-bbox.min.y)/2,0)

      mesh_unfolded_solid.geometry.center();
      mesh_unfolded_solid.position.set(0,(bbox.max.y-bbox.min.y)/2,0)
      mesh_unfolded_line.geometry.center();
      mesh_unfolded_line.position.set(0,(bbox.max.y-bbox.min.y)/2,0)

      // let size = Math.max(bbox.max.x-bbox.min.x,bbox.max.z-bbox.min.z);

      // let divisions;
      // let scale;
      // if(size <= 18) {
      //     divisions = Math.round(size)
      //     scale = 10
      // }
      // else if(size > 18 && size <= 180) {
      //     divisions = Math.round(size/10)
      //     scale = 100
      // }
      // else {
      //     divisions = Math.round(size/100)
      //     scale = 1000
      // }

      mesh_folded_solid.geometry.dispose();
      mesh_folded_solid.material.dispose();
      mesh_folded_line.geometry.dispose();
      mesh_folded_line.material.dispose();

      mesh_folded_clip_line.geometry.dispose();
      mesh_folded_clip_line.material.dispose();
      scene.remove(mesh_folded_clip_line);

      scene.remove(mesh_folded_solid);
      scene.remove(mesh_folded_line);
      renderer.renderLists.dispose();

      scene.add( mesh_unfolded_clip_line );

      scene.add( mesh_unfolded_solid );
      scene.add( mesh_unfolded_line );
      self.setState({
        working: false
      })
    } );
    this.setState({
      unfoldVisible: true
    })
  }

  showFold = () => {
    let xray = this.state.selectedRadio === 'xray';
    let loader = new STLLoader();
    let self = this
    self.setState({
      working: true
    })
    loader.load( part_folded, function ( geometry ) {
      if(!xray) {
        mesh_folded_line = new THREE.LineSegments(new THREE.EdgesGeometry(geometry, 20), materialLineSolid);
        mesh_folded_solid = new THREE.Mesh( geometry, materialSolidSolid );
        mesh_folded_clip_line = new THREE.Mesh( geometry, materialSolidDark );
      }
      else {
        mesh_folded_line = new THREE.LineSegments(new THREE.EdgesGeometry(geometry, 20), materialLineXray);
        mesh_folded_solid = new THREE.Mesh( geometry, materialSolidXray );
        mesh_folded_clip_line = new THREE.Mesh( geometry, materialSolidLight );
      }

      mesh_folded_solid.position.set( 0, 0, 0);
      mesh_folded_solid.rotation.x = -(Math.PI / 2);
      mesh_folded_solid.scale.set( 0.1, 0.1, 0.1 );
      mesh_folded_solid.castShadow = true;
      mesh_folded_solid.receiveShadow = true;


      mesh_folded_line.position.set( 0, 0, 0);
      mesh_folded_line.rotation.x = -(Math.PI / 2);
      mesh_folded_line.scale.set( 0.1, 0.1, 0.1 );

      let bbox = new THREE.Box3().setFromObject(mesh_folded_solid);

      mesh_folded_clip_line.position.set( 0, 0, 0);
      mesh_folded_clip_line.rotation.x = -(Math.PI / 2);
      mesh_folded_clip_line.scale.set( 0.1, 0.1, 0.1 );


      mesh_folded_solid.material.clippingPlanes = [localPlane]
      mesh_folded_line.material.clippingPlanes = [localPlane]
      mesh_folded_clip_line.material.clippingPlanes = [localPlaneLower,localPlaneUpper]

      clippingPlaneLen = (bbox.max.y - bbox.min.y) * 1.01
      localPlane.constant = clippingPlaneLen

      localPlaneLower.constant = -clippingPlaneLen*0.996
      localPlaneUpper.constant =  clippingPlaneLen

      mesh_folded_clip_line.geometry.center();
      mesh_folded_clip_line.position.set(0,(bbox.max.y-bbox.min.y)/2,0)

      mesh_folded_solid.geometry.center();
      mesh_folded_solid.position.set(0,(bbox.max.y-bbox.min.y)/2,0)
      mesh_folded_line.geometry.center();
      mesh_folded_line.position.set(0,(bbox.max.y-bbox.min.y)/2,0)


      mesh_unfolded_solid.geometry.dispose();
      mesh_unfolded_solid.material.dispose();
      mesh_unfolded_line.geometry.dispose();
      mesh_unfolded_line.material.dispose();

      mesh_unfolded_clip_line.geometry.dispose();
      mesh_unfolded_clip_line.material.dispose();
      scene.remove(mesh_unfolded_clip_line);

      scene.remove(mesh_unfolded_solid);
      scene.remove(mesh_unfolded_line);
      renderer.renderLists.dispose();

      scene.add( mesh_folded_clip_line );

      scene.add( mesh_folded_solid );
      scene.add( mesh_folded_line );
      self.setState({
        working: false
      })
    } );
    this.setState({
      unfoldVisible: false
    })
  }

  showFile = () => {
    scene = new THREE.Scene();
    scene.background = new THREE.Color( 0xf2f2f2 );
    scene.fog = new THREE.FogExp2( 0xd4d8e1, 0.0025 );

    let width = this.props.modalWidth + 30
    if(this.props.itemView.viewType === "details") {
      width = this.props.modalWidth < 800 ? 450 : this.props.modalWidth - 350
    }

    camera = new THREE.PerspectiveCamera( 75, width/this.props.modalHeight, 0.1, 1000 );
    renderer = new THREE.WebGLRenderer({ antialias: true });
    controls = new OrbitControls(camera, renderer.domElement,this.updateAngles);
    controls.enableDamping = true
    controls.dampingFactor = 0.1
    controls.rotateSpeed = 0.1
    renderer.setSize( width, this.props.modalHeight);

    scene.add( new THREE.AmbientLight( 0x464646 ) );

    var light1 = new THREE.DirectionalLight( this.props.itemView?.coating?.hasCoating ? this.props.itemView.coating.ralHex : 0xf2f2f2, 1 );
    light1.position.set( 1, 0.75, 0.5 );
    scene.add( light1 );

    var light2 = new THREE.DirectionalLight( this.props.itemView?.coating?.hasCoating ? this.props.itemView.coating.ralHex : 0xbcbcbc, 1 );
    light2.position.set( - 1, 0.75, - 0.5 );
    scene.add( light2 );

    renderer.gammaInput = true;
    renderer.gammaOutput = true;
    renderer.shadowMap.enabled = true;
    controls.update();

    materialLineSolid = new THREE.LineBasicMaterial({color: 0x1b1b1b, linewidth: 2, fog: false});
    materialLineXray = new THREE.LineBasicMaterial({color: 0xffffff, linewidth: 2, fog: false});
    materialSolidSolid = new THREE.MeshPhongMaterial( {
      color: this.props.itemView?.coating?.hasCoating ? this.props.itemView.coating.ralHex : 0x424447,
      specular: this.props.itemView?.coating?.hasCoating ? this.props.itemView.coating.ralHex :  0x444444,
      shininess: this.props.itemView?.coating?.hasCoating ? (this.props.itemView.coating.colorGlossId === "1" ? 20 : 5) : 20,
      side: THREE.DoubleSide,
      fog: false
    });
    materialSolidDark  = new THREE.MeshLambertMaterial( { color: 0x111111, side: THREE.DoubleSide, clipIntersection: false, fog: false} );
    materialSolidLight  = new THREE.MeshLambertMaterial( { color: 0xeeeeee, side: THREE.DoubleSide, clipIntersection: false, fog: false} );
    materialSolidXray = new THREE.ShaderMaterial({
      uniforms: {
        p: {type: "f", value: 2},
        glowColor: {type: "c", value: new THREE.Color(0x7a7a7a)},
      },
      vertexShader: document.getElementById('XrayVertexShader').textContent,
      fragmentShader: document.getElementById('XrayFragmentShader').textContent,
      side: THREE.DoubleSide,
      blending: THREE.AdditiveBlending,
      transparent: true,
      depthWrite: false,
      clipping: true,
      clippingPlanes : []
    });

    renderer.localClippingEnabled = true;



    var unfoldSize;
    var loader = new STLLoader();
    let self = this
    if(part_unfolded) {
      loader.load( part_unfolded, function ( geometry ) {
        self.setState({unfoldLoadingProgress: 25})
        let tmp_shape = new THREE.Mesh( geometry, materialSolidSolid );
        tmp_shape.position.set( 0, 0, 0);
        tmp_shape.scale.set( 0.1, 0.1, 0.1 );
        tmp_shape.rotation.x = -(Math.PI / 2);
        let bbox = new THREE.Box3().setFromObject(tmp_shape);

        unfoldSize = Math.max(bbox.max.x-bbox.min.x,bbox.max.z-bbox.min.z);
        tmp_shape.geometry.dispose();
        tmp_shape.material.dispose();

        if(self.state.foldLoaded) {
          self.setState({foldLoaded: false, unfoldLoaded: false})
          setTimeout(()=>{
            self.setState({loading: false}, ()=>{
              if(container.childNodes.length === 0) {
                container.appendChild( renderer.domElement );
              }
            });
          }, 200)
        } else {
          self.setState({unfoldLoaded: true})
        }

      }, (xhr) => {
        let unfoldLoadingProgress = (xhr.loaded / xhr.total || 1) * 25
        self.setState({unfoldLoadingProgress})
      });
    } else {
      self.setState({unfoldLoaded: true})
    }



    loader.load( part_folded, function ( geometry ) {
      let foldLoadingProgress = part_unfolded ? 25 : 50
      self.setState({foldLoadingProgress})

      mesh_folded_solid = new THREE.Mesh( geometry, materialSolidSolid );
      mesh_folded_solid.position.set( 0, 0, 0);
      mesh_folded_solid.scale.set( 0.1, 0.1, 0.1 );
      mesh_folded_solid.rotation.x = -(Math.PI / 2);
      mesh_folded_solid.castShadow = true;
      mesh_folded_solid.receiveShadow = true;

      mesh_folded_line = new THREE.LineSegments(new THREE.EdgesGeometry(geometry, 20), materialLineSolid);
      mesh_folded_line.position.set( 0, 0, 0);
      mesh_folded_line.scale.set( 0.1, 0.1, 0.1 );
      mesh_folded_line.rotation.x = -(Math.PI / 2);

      mesh_folded_clip_line= new THREE.Mesh( geometry, materialSolidDark );
      mesh_folded_clip_line.position.set( 0, 0, 0);
      mesh_folded_clip_line.scale.set( 0.1, 0.1, 0.1 );
      mesh_folded_clip_line.rotation.x = -(Math.PI / 2);

      let bbox = new THREE.Box3().setFromObject(mesh_folded_solid);
      clippingPlaneLen = (bbox.max.y-bbox.min.y)*1.01;
      localPlane = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), clippingPlaneLen);
      localPlaneLower = new THREE.Plane( new THREE.Vector3( 0, 1, 0 ), -clippingPlaneLen*0.996);
      localPlaneUpper = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), clippingPlaneLen);

      materialSolidXray.clippingPlanes = [localPlane];
      mesh_folded_solid.material.clippingPlanes = [localPlane];
      mesh_folded_line.material.clippingPlanes = [localPlane];
      mesh_folded_clip_line.material.clippingPlanes = [localPlaneLower,localPlaneUpper];

      mesh_folded_solid.geometry.center();
      mesh_folded_solid.position.set(0,(bbox.max.y-bbox.min.y)/2,0)
      mesh_folded_line.geometry.center();
      mesh_folded_line.position.set(0,(bbox.max.y-bbox.min.y)/2,0)
      mesh_folded_clip_line.geometry.center();
      mesh_folded_clip_line.position.set(0,(bbox.max.y-bbox.min.y)/2,0)



      let size = Math.max(bbox.max.x-bbox.min.x,bbox.max.z-bbox.min.z);
      if(size < unfoldSize) {
        size = unfoldSize
      }
      let divisions;
      let scale;
      if(size <= 15) {
        divisions = Math.round(size)
        scale = 10
      }
      else if(size > 15) {
        divisions = Math.round(size/10)
        scale = 100
      }

      let finalSize = (Math.round(size/divisions))*(divisions+1)
      gridHelper2 = new THREE.GridHelper( finalSize, (divisions+1)*2, 0x777777, 0x777777 );
      gridHelper2.position.y = -0.003*scale;
      gridHelper2.position.x = 0;
      scene.add( gridHelper2 );
      gridHelper = new THREE.GridHelper( finalSize, (divisions+1), 0x222222, 0x222222 );
      gridHelper.position.y = -0.003*scale;
      gridHelper.position.x = 0;
      gridHelper.material.linewidth = 2;
      scene.add( gridHelper );

      scene.add( mesh_folded_clip_line );
      scene.add( mesh_folded_solid );
      scene.add( mesh_folded_line );

      const boundingSphere = new THREE.Box3().setFromObject( mesh_folded_solid ).getBoundingSphere( mesh_folded_solid );

      const objectScale = 1.2; // object size / display size
      const objectAngularSize = ( camera.fov * Math.PI / 180 ) * objectScale;
      const distanceToCamera = boundingSphere.radius / Math.tan( objectAngularSize / 2 )
      const len = Math.sqrt( Math.pow( distanceToCamera, 2 ) + Math.pow( distanceToCamera, 2 ) )

      camera.position.set(len, len, len);
      controls.update();
      const center = boundingSphere.position
      camera.lookAt( center );
      controls.target.set( center.x, center.y, center.z );

      camera.updateProjectionMatrix();
      if(self.state.unfoldLoaded) {
        self.setState({foldLoaded: false, unfoldLoaded: false})
        setTimeout(()=> {
          self.setState({loading: false}, () => {
            if (container.childNodes.length === 0) {
              container.appendChild(renderer.domElement);
            }
          });
        }, 200)
      } else {
        self.setState({foldLoaded: true})
      }
    }, (xhr) => {
      let num = part_unfolded ? 25 : 50
      let foldLoadingProgress = (xhr.loaded / xhr.total || 1) * num
      self.setState({foldLoadingProgress})
    });

    var animate = function () {
      frameId = requestAnimationFrame( animate );
      controls.update();
      renderer.render( scene, camera );
    };
    animate();
  };

  updateAngles = (phi, theta) => {
    if( this.props.isModelViewVisible && container && (currphi !== phi || currtheta !== theta)) {
      currphi = phi;
      currtheta = theta;
      if (controls) {
        controls.setPolarAngle(phi);
        controls.setAzimuthalAngle(theta);
        this.updateAngle2(phi,theta)
      }
    }
  };


  updateAngle2 = (phi, theta) => {
    if( this.props.isModelViewVisible && cube && (currphi2 !== phi || currtheta2 !== theta)) {
      currphi2 = phi;
      currtheta2 = theta;
      cube.setAngles(phi, theta);
    }
  };

  handleTypeChange = (e) => {
    this.setState({ selectedRadio: e.target.value, slider: 100 }
      , ()=> {
        if(this.state.selectedRadio === 'solid') {
          scene.background = new THREE.Color(0xf2f2f2);
          scene.add( gridHelper2 );
          scene.add( gridHelper );
        } else {
          scene.background = new THREE.Color(0x222233);
          scene.remove( gridHelper2 );
          scene.remove( gridHelper );
        }

        if(this.state.unfoldVisible) {
          if ( mesh_unfolded_solid.material ) mesh_unfolded_solid.material.dispose();
          if(this.state.selectedRadio === 'solid') {
            mesh_unfolded_solid.material = materialSolidSolid;
          } else {
            mesh_unfolded_solid.material = materialSolidXray;
          }

          if ( mesh_unfolded_line.material ) mesh_unfolded_line.material.dispose();
          if(this.state.selectedRadio === 'solid') {
            mesh_unfolded_line.material = materialLineSolid;
          } else {
            mesh_unfolded_line.material = materialLineXray;
          }

          if ( mesh_unfolded_clip_line.material ) mesh_unfolded_clip_line.material.dispose();
          if(this.state.selectedRadio === 'solid') {
            mesh_unfolded_clip_line.material = materialSolidDark;
          } else {
            mesh_unfolded_clip_line.material = materialSolidLight;
          }

          let bbox = new THREE.Box3().setFromObject(mesh_unfolded_solid);
          clippingPlaneLen = (bbox.max.y-bbox.min.y)*1.01
          localPlane = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), clippingPlaneLen);
          localPlaneLower = new THREE.Plane( new THREE.Vector3( 0, 1, 0 ), -clippingPlaneLen*0.996);
          localPlaneUpper = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), clippingPlaneLen);
          mesh_unfolded_solid.material.clippingPlanes = [localPlane]
          mesh_unfolded_line.material.clippingPlanes = [localPlane]
          mesh_unfolded_clip_line.material.clippingPlanes = [localPlaneLower, localPlaneUpper]

        } else {

          if ( mesh_folded_solid.material ) mesh_folded_solid.material.dispose();
          if(this.state.selectedRadio === 'solid') {
            mesh_folded_solid.material = materialSolidSolid;
          } else {
            mesh_folded_solid.material = materialSolidXray;
          }

          if ( mesh_folded_line.material ) mesh_folded_line.material.dispose();
          if(this.state.selectedRadio === 'solid') {
            mesh_folded_line.material = materialLineSolid;
          } else {
            mesh_folded_line.material = materialLineXray;
          }

          if ( mesh_folded_clip_line.material ) mesh_folded_clip_line.material.dispose();
          if(this.state.selectedRadio === 'solid') {
            mesh_folded_clip_line.material = materialSolidDark;
          } else {
            mesh_folded_clip_line.material = materialSolidLight;
          }

          let bbox = new THREE.Box3().setFromObject(mesh_folded_solid);
          clippingPlaneLen = (bbox.max.y-bbox.min.y)*1.01
          localPlane = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), clippingPlaneLen);
          localPlaneLower = new THREE.Plane( new THREE.Vector3( 0, 1, 0 ), -clippingPlaneLen*0.996);
          localPlaneUpper = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), clippingPlaneLen);
          mesh_folded_solid.material.clippingPlanes = [localPlane]
          mesh_folded_line.material.clippingPlanes = [localPlane]
          mesh_folded_clip_line.material.clippingPlanes = [localPlaneLower, localPlaneUpper]

        }
      })
  }

  onSlide = (value) => {
    localPlane.constant = (clippingPlaneLen * (value < 1 ? 1/100 : value/100))
    localPlaneUpper.constant = (clippingPlaneLen * (value < 1 ? 1/100 : value/100))
    localPlaneLower.constant = -(clippingPlaneLen * 0.996 * (value < 1 ? 1/100 : value/100))
    this.setState({slider: value})
  }

  render() {

    const item = this.props.itemView
    const adjustment = this.props.adjustment
    return (
      <Modal
        visible={this.props.isModelViewVisible}
        header={null}
        footer={null}
        maskClosable={true}
        closable={false}
        forceRender={true}
        centered={true}
        width={this.props.modalWidth+50}
        zIndex={3000}
        bodyStyle={{overflowX: "auto",padding: 10,height: this.props.modalHeight + 25, background: this.state.selectedRadio === 'solid' ? '#FFFFFF' : '#333333',color: this.state.selectedRadio === 'solid' ? '#000000' : '#FFFFFF', transition: 'all 0.4s'}}
        className={this.state.selectedRadio === 'solid' ? "whiteModal partView" : "blackModal partView" }
      >
        <React.Fragment>
          <div style={{display: "flex"}}>
          {!this.state.loading &&
            <div style={{position: "absolute", top: 20, right: 20}}>
              <Button
                style={{
                  background: this.state.selectedRadio === 'solid' ? 'white' : '#333333',
                  color: this.state.selectedRadio === 'solid' ? '#333333' : 'white',
                  boxShadow: '0 4px 8px 2px rgba(0, 0, 0, 0.12)'
                }}
                onClick={()=>{this.props.onCloseView()}}
              >
                <CloseOutlined />Close
              </Button>
            </div>
          }
          {this.state.loading &&
            <React.Fragment>
              <div style={{marginLeft: (this.props.modalWidth-90)/2, paddingTop: (this.props.modalHeight-100)/2}}>
                <div>
                  <Progress type="circle" percent={parseFloat((this.state.loadingProgress + this.state.foldLoadingProgress + this.state.unfoldLoadingProgress).toFixed(1))} />
                </div>
              </div>
            </React.Fragment>
          }
          {(item && item.fileType === 'dxf') &&
            <React.Fragment>
              <div style={{display: "flex"}}>
                <div id="cad-view" style={{marginBottom: -5}} align={'center'}/>
              </div>
            </React.Fragment>
          }
          {(item && item.fileType === 'step') &&
            (<div>
              {!this.state.loading &&
                (<div>
                  <div style={{marginTop: 15, marginBottom: -45, marginLeft: 15, marginRight: 15, background: 'rgba(0,0,0,0)', borderRadius: 6}}>
                    <Radio.Group style={{boxShadow: '0 4px 8px 2px rgba(0, 0, 0, 0.12)'}} value={this.state.selectedRadio} buttonStyle="solid" onChange={this.handleTypeChange}>
                      <Radio.Button value="solid" style={{boxShadow: "none"}}>Solid</Radio.Button>
                      <Radio.Button value="xray" style={{boxShadow: "none"}}>X-Ray</Radio.Button>
                    </Radio.Group>
                    <Row style={{marginLeft: 160}}>
                      <Col span={24}>
                        <div style={{marginTop: "-42.5px" ,marginBottom: -45, pointerEvents: 'none'}}>
                          <div>
                            <LeftMouse style={{fontSize: '30px'}}/>
                            <p style={{
                              display: "inline-block",
                              marginLeft: '2px',
                              marginRight: '10px',
                              verticalAlign: 'middle'
                            }}>Rotate</p>
                            <MiddleMouse style={{fontSize: '32px'}}/>
                            <p style={{
                              display: "inline-block",
                              marginLeft: '2px',
                              marginRight: '10px',
                              verticalAlign: 'middle'
                            }}>Zoom</p>
                            <RightMouse style={{fontSize: '30px'}}/>
                            <p style={{
                              display: "inline-block",
                              marginLeft: '2px',
                              verticalAlign: 'middle'
                            }}>Pan</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>)
              }
              <div key={"thing"}  className={'step-canvas'} ref={ref => (container = ref)} />
              <div align={"left"} className={"step-canvas-cube-view"} style={{marginTop: -155, marginLeft: -20, borderRadius: 6, width: 200}}>
                {!this.state.loading &&
                  (<React.Fragment>
                    <CubeView
                      aspect={1}
                      hoverColor={0x0088FF}
                      cubeSize={2}
                      zoom={6}
                      antialias={true}
                      width={150}
                      height={150}
                      ref={c => (cube = c)}
                      onUpdateAngles={this.updateAngles}
                      style={{}}
                    />
                  </React.Fragment>)}
              </div>
              <div align={'right'} style={{marginTop: part_unfolded ? -210 : -160, paddingRight: 20, width: 200, position: "absolute", right: 0}}>
                <div>
                  <Button style={{height: 130, paddingTop: 5, paddingLeft: 5, paddingRight: 5}} className={"slider-okvir"}>
                    <div style={{height: 120, paddingTop: 10}}>
                      <Slider
                        vertical
                        step={0.5}
                        defaultValue={100}
                        onChange={this.onSlide}
                        style={{height: 90}}
                        tipFormatter={null}
                        value={this.state.slider}
                      />
                    </div>
                  </Button>
                </div>
                {part_unfolded &&
                <div align={"right"} style={{marginTop: 15, paddingRight: 20, paddingBottom: 15}}>
                  {this.state.unfoldVisible ?
                    (<Button
                      type="primary"
                      size={"large"}
                      className={"foldBtn"}
                      style={{boxShadow: '0 4px 8px 2px rgba(0, 0, 0, 0.12)'}}
                      loading={this.state.working}
                      onClick={() => {
                        this.showFold();
                        this.setState({slider: 100})
                      }}>
                      <span style={{fontWeight: 500}}>Show Fold</span>
                    </Button>) :
                    (<Button
                      type="primary"
                      size={"large"}
                      className={"foldBtn"}
                      style={{boxShadow: '0 4px 8px 2px rgba(0, 0, 0, 0.12)'}}
                      loading={this.state.working}
                      onClick={() => {
                        this.showUnfold();
                        this.setState({slider: 100})
                      }}>
                      <span style={{fontWeight: 500}}>Show Unfold</span>
                    </Button>)
                  }
                </div>
                }
              </div>
            </div>)
          }
          {(!this.state.loading && item && item.viewType === "details") &&
            <div style={{width: 370, paddingLeft: 20, marginTop: 55}}>
              <Card
                className={this.state.selectedRadio === 'solid' ? "whiteModalCard" : "blackModalCard"}
                style={{
                  width: "100%",
                  height: this.props.modalHeight - 53,
                  marginBottom: 5,
                  borderRadius: 5,
                  border: "1px solid rgb(215, 215, 215)",
                  boxShadow: "rgba(0, 0, 0, 0.07) 0 2px 4px 1px"
                }}
                bodyStyle={{
                  overflowY: "scroll",
                  height: "100%",
                }}
              >
                <div>
                  <div style={{fontSize: 20, fontWeight: 600, marginBottom: 10}}>{item.fileName || item.name}</div>
                  {(item.fileType !== "other" && item.processingType === "automatic") &&
                    <div>
                    <div className={"orderItemDetailsLabel"}>Dimension:</div>
                    <div className={"orderItemDetailsValue"}>
                      {item.fileType === "step" ?
                        (item?.stepData?.partData?.boundingBox?.x + "×" + item?.stepData?.partData?.boundingBox?.y + "×" + item?.stepData?.partData?.boundingBox?.z + "mm")
                        :
                        (Math.round(((item?.dxfData?.partData?.minRectangle[0] || 0) + Number.EPSILON) * 100) / 100 + "×" + Math.round(((item?.dxfData?.partData?.minRectangle[1] || 0) + Number.EPSILON) * 100) / 100 + "mm")
                      }
                    </div>
                    </div>
                  }
                  {item.selectedMaterial.groupName &&
                    <div>
                      <div className={"orderItemDetailsLabel"}>Material Group:</div>
                      <div className={"orderItemDetailsValue"}>{item.selectedMaterial.groupName}</div>
                    </div>
                  }
                  <div className={"orderItemDetailsLabel"}>Material Grade:</div>
                  <div className={"orderItemDetailsValue"}><Trans>{item.selectedMaterial.grade}</Trans></div>
                  {item.selectedMaterial.thickness && item.partType === "sheetMetal" &&
                    <div>
                      <div className={"orderItemDetailsLabel"}>Thickness:</div>
                      <div className={"orderItemDetailsValue"}>{item.selectedMaterial.thickness}mm</div>
                    </div>
                  }
                  {item.standard &&
                    <div>
                      <div className={"orderItemDetailsLabel"}>Standard:</div>
                      <div className={"orderItemDetailsValue"}>{item.standard}</div>
                    </div>
                  }
                  <div className={"orderItemDetailsLabel"}>Part Type:</div>
                  <div className={"orderItemDetailsValue"}>{getManualType(item.partType)}</div>
                  <div className={"orderItemDetailsLabel"}>Quantity:</div>
                  <div className={"orderItemDetailsValue"}>{item.quantity}</div>
                  {item?.stepData?.partData?.hasBends || item?.stepData?.partData?.numOfMachinedHoles || item?.stepData?.partData?.numOfSlopedEdges ?
                    <div style={{display: "inline-block", width: 300}}>
                      {item?.stepData?.partData?.hasBends ?
                        <div style={{marginRight: 30, display: "inline-block"}}>
                          <div style={{display: "flex"}}>
                            <div style={{marginTop: 5, marginRight: 2}}>
                              <img style={{width: 25, height: 25}} src={sheet} alt={"part"}/>
                            </div>
                            <div>
                              <div className={"orderItemDetailsLabel"}>Bends:</div>
                              <div className={"orderItemDetailsValue"}>{item?.stepData?.partData?.numberOfBends}</div>
                            </div>
                          </div>
                        </div>
                        :
                        null
                      }
                      {item?.stepData?.partData?.numOfMachinedHoles ?
                        <div style={{marginRight: 30, display: "inline-block"}}>
                          <div style={{display: "flex"}}>
                            <div style={{marginTop: 6, marginRight: 2}}>
                              <img style={{width: 25, height: 25}} src={counter} alt={"part"}/>
                            </div>
                            <div>
                              <div className={"orderItemDetailsLabel"}>Countersinks:</div>
                              <div className={"orderItemDetailsValue"}>{item.stepData.partData.numOfMachinedHoles}</div>
                            </div>
                          </div>
                        </div>
                        :
                        null
                      }
                      {item?.stepData?.partData?.numOfSlopedEdges ?
                        <div style={{marginRight: 30, display: "inline-block"}}>
                          <div style={{display: "flex"}}>
                            <div style={{marginTop: 5, marginRight: 2}}>
                              <img style={{width: 25, height: 25}} src={slope} alt={"part"}/>
                            </div>
                            <div>
                              <div className={"orderItemDetailsLabel"}>Sloped Edges:</div>
                              <div className={"orderItemDetailsValue"}>{item.stepData.partData.numOfSlopedEdges}</div>
                            </div>
                          </div>
                        </div>
                        :
                        null
                      }
                    </div>
                    :
                    null
                  }
                  {item?.coating?.hasCoating ?
                    <div style={{marginRight: 30, display: "flex"}}>
                      <div style={{marginTop: 10, marginLeft: 2, marginRight: 5}}>
                        <DropIcon style={{color: item.coating.ralHex, fontSize: 20}}/>
                      </div>
                      <div>
                        <div className={"orderItemDetailsLabel"}>Coating:</div>
                        <div
                          className={"orderItemDetailsValue"}>RAL {item.coating.colorRAL}, {item.coating.colorGlossId === "1" ? "Glossy (70±5)" : "Matte (30±5)"}</div>
                      </div>
                    </div>
                    :
                    null
                  }
                  {item.note !== "" ?
                    <div>
                      <div className={"orderItemDetailsLabel"}>Note:</div>
                      <div className={"orderItemDetailsValue"}>{item.note}</div>
                    </div>
                    :
                    null
                  }
                  {item.quoteType === "quoted" ?
                    <div style={{display: "flex"}}>
                      <div style={{marginRight: 30}}>
                        <div className={"orderItemDetailsLabel"}>Price 1pc:</div>
                        <div className={"orderItemDetailsValue"}>€ {((parseFloat(item.pricePerPiece) || 0) * calcAdjustment(adjustment)).toFixed(2)}</div>
                      </div>
                      <div>
                        <div className={"orderItemDetailsLabel"}>Total Price:</div>
                        <div className={"orderItemDetailsValue"}>€ {((parseFloat(item.totalPrice) || item.pricePerPiece * item.quantity || 0) * calcAdjustment(adjustment)).toFixed(2)}</div>
                      </div>
                    </div>
                    :
                    <div style={{display: "flex"}}>
                      <div style={{marginRight: 30}}>
                        <div className={"orderItemDetailsLabel"}>Price:</div>
                        <div className={"orderItemDetailsValue"}>RFQ</div>
                      </div>
                    </div>
                  }
                </div>
              </Card>
            </div>
          }
          </div>
        </React.Fragment>
      </Modal>
    )
  }
}