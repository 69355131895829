import {
    ADMIN_LOGS,
    ADMIN_LOGS_PAGINATION,
    ADMIN_FETCHING_LOGS,
} from './actionTypes'
import { API_BASE_URL } from '../constants'
import { http, message } from '../utils'

const fetchLogs = payload => dispatch => {
    dispatch({
        type: ADMIN_FETCHING_LOGS,
        payload: true,
    })

    if(payload.search === undefined)
    {
        payload.search = ''
    }
    return http
        .get(`${API_BASE_URL}/logs?pageRowCount=5&pageIndex=${payload.page}&sortField=createdAt&sortOrder=DESC${payload.search}`)
        .then(response => {

            const pagination = { ...payload.pagination };
            pagination.total = response.data.meta.totalRowCount;
            dispatch({
                type: ADMIN_LOGS_PAGINATION,
                payload: pagination,
            })
            dispatch({
                type: ADMIN_LOGS,
                payload: response.data.data,
            })
            dispatch({
                type: ADMIN_FETCHING_LOGS,
                payload: false,
            })
        })
        .catch(error => {
            if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
                dispatch(payload.logout_user)
            }
            else {
                dispatch({
                    type: ADMIN_FETCHING_LOGS,
                    payload: false,
                })
                message.show(message.type.error, http.getErrorMessage(error))
            }
        })
}

export { fetchLogs }
