import React, {createRef, useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux'

import history from '../../utils/history'

import {
  Button, Card, Col, Layout, Row, Modal,
  InputNumber, Form, Spin, Divider, Tag,
  Tooltip, Input
} from 'antd';

import "./style.css"
import {
  CheckCircleOutlined,
  CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined,
  FileAddOutlined, FileDoneOutlined, FileExcelOutlined,
  FilePdfOutlined, FileTextOutlined, FileZipOutlined, LoadingOutlined, ProfileOutlined,
} from "@ant-design/icons";
import {logoutUser} from "../../actions/authActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import UndoIcon from '@mui/icons-material/Undo';

import {OrderDetailsManualQuote} from "../../components/Order/OrderDetailsManualQuote";
import {DropIcon} from "../../assets/icons/drop";
import {Sidebar} from "../../components";
import {
  addNewPart, fetchPendingOrder, fetchPendingOrderParts,
  quoteManualPart, editPendingOrder, resolveOrder,
  revertOrder, downloadOrderZip, setGeneratingZip
} from "../../actions/ordersActions";
import {CircularProgress, IconButton} from "@mui/material";
import {formatTableDate, getManualType, getPlaceholderImage} from "../../utils/utility";
import {OrderDetailsPartProcessedPartsList} from "../../components/Order/OrderDetailsPartProcessedPartsList";
import ItemVisualisation from "../../components/Order/ItemVisualisation";
import {S3_BUCKET_URL} from "../../constants";
import twoD from "../../assets/icons/2D.svg";
import treeD from "../../assets/icons/3D.svg";
import {OrderDetailsPartUnprocessedPartsList} from "../../components/Order/OrderDetailsPartUnprocessedPartsList";
import {CompanySidebar} from "../../components/company";
import {OrderChanges} from "../../components/Order/OrderChanges";
import {OrderExcelExportButton} from "../../components/Order/OrderExcelExportButton";
import {OrderPdfExportButton} from "../../components/Order/OrderPdfExportButton";
import {Trans} from "react-i18next";
import {changePartProducedQuantity} from "../../actions/ordersActions";

export const OrderDetailsPage = () => {
  const form = createRef()

  const [isEditing, setIsEditing] = useState(false)

  const [isEditFinalisationVisible, setIsEditFinalisationVisible] = useState(false)

  const [viewVisible, setViewVisible] = useState(false)
  const [itemView, setItemView] = useState(null)
  const [modalWidth, setModalWidth] = useState(window.innerWidth-100)
  const [modalHeight, setModalHeight] = useState(window.innerHeight-135)

  const [editItem, setEditItem] = useState(null)
  const [isEditItemVisible, setIsEditItemVisible] = useState(false)

  const [hasBeenEdited, setHasBeenEdited] = useState(false)

  const [isDeletingItem, setIsDeletingItem] = useState(false)

  const [isManualQuoteModalVisible, setIsManualQuoteModalVisible] = useState(false)

  const [manualQuoteItem, setManualQuoteItem] = useState(null)

  const [isDetailsView, setIsDetailsView] = useState(false)

  const [otherItemView, setOtherItemView] = useState(null)

  const [isAddNewPartVisible, setIsAddNewPartVisible] = useState(false)

  const [declineVisible, setDeclineVisible] = useState(false)
  const [acceptVisible, setAcceptVisible] = useState(false)
  const [sendQuoteVisible ,setSendQuoteVisible] = useState(false)
  const [inProgressVisible, setInProgressVisible] = useState(false)
  const [completeVisible, setCompleteVisible] = useState(false)
  const [changesVisible, setChangesVisible]  = useState(false)

  const [editMap, setEditMap] = useState({
    oldDeliveryDate: "",
    newDeliveryDate: "",
    oldTotalPrice: "",
    newTotalPrice: "",
    items : {}
  })

  const [selectedDate, setSelectedDate] = useState(null)

  const [filesItem, setFilesItem] = useState(null)
  const [downloadFilesVisible, setDownloadFilesVisible] = useState(false)

  const [quantityChangeVisible, setQuantityChangeVisible] = useState(false);
  const [selectedPartQuantityData, setSelectedPartQuantityData] = useState(null);

  const user = useSelector(state => state.auth.user)
  const isFetchingPendingOrder = useSelector(state => state.orders.isFetchingPendingOrder)
  const orderDetails = useSelector(state => state.orders.pendingOrder)
  const isAddingNewPart = useSelector(state => state.orders.isAddingNewPart)

  const isFetchingPendingOrderParts = useSelector(state => state.orders.isFetchingPendingOrderParts)
  const isEditingPendingOrder = useSelector(state => state.orders.isEditingPendingOrder)
  const isResolvingPendingOrder = useSelector(state => state.orders.isResolvingPendingOrder)
  const isRevertingOrder = useSelector(state => state.orders.isRevertingOrder)
  const isGeneratingZip = useSelector(state => state.orders.isGeneratingZip)
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const role = user?.role

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  const handleOrderReturn = () => {
    if(role === "producerAdmin" || role === "producerSubAdmin" ) {
      switch (history.location.pathname) {
        case('/pending-order'):
          history.push('/pending')
          break;
        case('/awaiting-order'):
          history.push('/awaiting')
          break;
        case('/accepted-order'):
          history.push('/accepted')
          break;
        case('/completed-order'):
          history.push('/archive')
          break;
      }
    }
    else {
      switch (history.location.pathname) {
        case('/pending-order'):
          history.push('/pending-orders')
          break;
        case('/awaiting-order'):
          history.push('/')
          break;
        case('/accepted-order'):
          history.push('/accepted-orders')
          break;
        case('/completed-order'):
          history.push('/competed-orders')
          break;
      }
    }
  }

  const fetchOrderType = () => {
    switch (history.location.pathname) {
      case('/pending-order'):
        return "pending"
      case('/awaiting-order'):
        return "awaiting-confirmation"
      case('/accepted-order'):
        return "accepted"
      case('/completed-order'):
        return "completed"
    }
  }

  useEffect(()=>{
    const id = new URLSearchParams(history.location.search).get('order')
    const type = fetchOrderType()
    if(id) {
      dispatch(fetchPendingOrder({id: id, type: type}))
        .then((order)=>{
          setSelectedDate(new Date(order.deliveryDate))
        })
        .catch(() => {
          if(type === "accepted") {
            history.push(`/completed-order?order=${id}`)
          } else {
            handleOrderReturn()
          }
        })
    } else {
      handleOrderReturn()
    }
  }, [])

  const handleResize = () => {
    const width = window.innerWidth

    setModalWidth(width-100);
    setModalHeight(window.innerHeight-135);
  };

  const showItem = (item) => {
    setItemView({...item, viewType: "details"})
    setViewVisible(true)
  }

  const cancelShowItem = ()  => {
    setItemView(null)
    setViewVisible(false)
  }

  const startEdit = () => {
    setSelectedDate(new Date(orderDetails.deliveryDate))
    setIsEditing(true)
    setEditMap({
      oldDeliveryDate: orderDetails.deliveryDate,
      newDeliveryDate: "",
      oldTotalPrice: orderDetails.totalPrice,
      newTotalPrice: "",
      items : {}
    })
  }

  const cancelEdit = () => {
    setIsEditing(false)
    setEditMap({
      oldDeliveryDate: "",
      newDeliveryDate: "",
      oldTotalPrice: "",
      newTotalPrice: "",
      items : {}
    })
    setHasBeenEdited(false)
  }

  const handleChange = date => {
    if(date) {
      setEditMap({...editMap, newDeliveryDate: date})
      setSelectedDate(date)
      setHasBeenEdited(true)
    }
  };

  const confirmChanges = () => {
    setIsEditFinalisationVisible(true)
  }

  const editItemFunc = (item) => {
    setIsEditItemVisible(true)
    setEditItem(item)
  }

  const handleEditModalClose = () => {
    setIsEditItemVisible(false)
    setEditItem(null)
  }

  const getOrderPrice = (items) => {
    const reducer = (accumulator, currentValue) => {
      return parseFloat(accumulator) + parseFloat((items[currentValue.id] ?
          (items[currentValue.id].state === "edited" ? items[currentValue.id].newPrice * currentValue.quantity : 0) :
          currentValue.totalPrice
      ))
    }
    const price = orderDetails.processedParts.reduce(reducer, 0)
    return price.toFixed(2)
  }

  const handleItemApply = (values) => {
    if(editMap.items[editItem.id] || parseFloat(values.itemPrice) !== parseFloat(editItem.pricePerPiece)) {
      const items = {...editMap.items}
      items[editItem.id] = {state: "edited", oldPrice: editItem.pricePerPiece, newPrice: values.itemPrice.toFixed(2)}
      setEditMap({...editMap, newTotalPrice: getOrderPrice(items), items: {...items}})
    }
    setIsEditItemVisible(false)
    setEditItem(null)
    setHasBeenEdited(true)
  }

  const itemRemoveOpen = () => {
    setIsDeletingItem(true)
  }

  const itemRemoveClose = () => {
    setIsDeletingItem(false)
  }

  const handleItemRemove = (item) => {
    const items = {...editMap.items}
    items[item.id] = {state: "declined", oldPrice: item.pricePerPiece, newPrice: "0.00"}
    setEditMap({...editMap, newTotalPrice: getOrderPrice(items), items: {...items}})
    setHasBeenEdited(true)
  }

  const handleEditFinalisationModalClose = () => {
    setIsEditFinalisationVisible(false)
  }

  const handleEditFinalisation = () => {
    const data = {}
    if(editMap.newDeliveryDate !== "") {
      data.deliveryDate = editMap.newDeliveryDate
    }
    if(editMap.newTotalPrice !== "") {
      let edits = [];
      for(let key of Object.keys(editMap.items)) {
        let item = editMap.items[key]
        let obj = {partId: key, editType: item.state === "declined" ? "delete" : "priceChange"}
        if(item.state === "edited") {
          obj.newPrice = parseFloat(item.newPrice)
        }
        edits.push(obj)
      }
      data.edits = edits
    }
    dispatch(editPendingOrder({orderId: orderDetails.id, data})).then(()=>{
      dispatch(fetchPendingOrderParts({id: orderDetails.id}))
      setIsEditFinalisationVisible(false)
      setIsEditing(false)
      setHasBeenEdited(false)
      setEditMap({
        oldDeliveryDate: "",
        newDeliveryDate: "",
        oldTotalPrice: "",
        newTotalPrice: "",
        items : {}
      })
    })
  }

  const openQuantityChangeModal = (item) => {
    setSelectedPartQuantityData({
      quantity: item.quantity,
      producedQuantity: item.producedQuantity,
      pendingProductionQuantity: item.pendingProductionQuantity,
      partId: item.id
    })
    setQuantityChangeVisible(true);
  }

  const handleQuantityChange = (values) => {
    dispatch(changePartProducedQuantity({
      orderId: orderDetails.id,
      partId: selectedPartQuantityData.partId,
      newQuantity: values.newQuantity,
      oldQuantity: selectedPartQuantityData.producedQuantity
    }))
    setQuantityChangeVisible(false);
  }

  const closeManualQuoteModal = () => {
    setIsManualQuoteModalVisible(false)
  }

  const openManualQuoteModal = (item) => {
    setManualQuoteItem(item)
    setIsManualQuoteModalVisible(true)
  }

  const openAddPartModal = () => {
    setIsAddNewPartVisible(true)
  }

  const closeAddPartModal = () => {
    setIsAddNewPartVisible(false)
  }

  const showDetailsView = (item) => {
    setIsDetailsView(true)
    setOtherItemView(item)
  }

  const closeDetailsView = () => {
    setIsDetailsView(false)
  }

  const handleRemovePartOnEdit = (item) => {
    itemRemoveOpen(item)
    Modal.confirm({
      className: "inputInfoText",
      visible: isDeletingItem,
      style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
      centered: true,
      onCancel: itemRemoveClose,
      onOk: () => {
        handleItemRemove(item)
      },
      destroyOnClose: true,
      width: 400,
      title: <span>{"Reject Part"}</span>,
      content: <div>
        <div>Are you sure you want to reject this part ?</div>
        <Divider style={{
          marginBottom: 0,
          position: 'relative',
          right: 36,
          width: 334
        }}/>
      </div>,
      okText: <span style={{fontSize: 13, fontWeight: 500}}>Reject</span>,
      okButtonProps: {type: "danger"},
      cancelText: <span
        style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
      icon: <DeleteOutlined style={{color: "red"}}/>,
      zIndex: 2,
    })
  }

  const formatOrderState = () => {
    switch (orderDetails.state) {
      case "quoted":
        return (
          <div>
            <Tag color="#f0bd27" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>Pending</Tag>
            <Tag color="#808080" style={{fontWeight: 600, textAlign: "center", width: 78, padding: "2px 10px 2px 10px", marginRight: 0}}>Quoted</Tag>
          </div>
        )
      case "awaitingQuote":
        return (
          <div>
            <Tag color="#f0bd27" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>Pending</Tag>
            <Tag color="#b60a1c" style={{fontWeight: 600, textAlign: "center", width: 98, padding: "2px 10px 2px 10px", marginRight: 0}}>Unquoted</Tag>
          </div>
        )
      case "awaitingConfirmation":
        return <Tag color="#9966cc" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>Awaiting</Tag>
      case "accepted":
        return <Tag color="#51b364" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>Accepted</Tag>
      case "inProgress":
        return <Tag color="#7cbbf4" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>In Progress</Tag>
      case "declined":
        return <Tag color="#b60a1c" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>Declined</Tag>
      case "completed":
        return <Tag color="#1890ff" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>Completed</Tag>
      default:
        return <span>Pending</span>
    }
  }

  const formatOrderStateProducer = () => {
    switch (orderDetails.state) {
      case "awaitingConfirmation":
        return <Tag color="#9966cc" style={{fontWeight: 600, fontSize: 11, textAlign: "center", padding: "0 8px 0 8px"}}>Awaiting</Tag>
      case "accepted":
        return <Tag color="#51b364" style={{fontWeight: 600, fontSize: 11, textAlign: "center", padding: "0 8px 0 8px"}}>Accepted</Tag>
      case "inProgress":
        return <Tag color="#7cbbf4" style={{fontWeight: 600, fontSize: 11, textAlign: "center", padding: "0 8px 0 8px"}}>In Progress</Tag>
      case "declined":
        return <Tag color="#b60a1c" style={{fontWeight: 600, fontSize: 11, textAlign: "center", padding: "0 8px 0 8px"}}>Declined</Tag>
      case "completed":
        return <Tag color="#1890ff" style={{fontWeight: 600, fontSize: 11, textAlign: "center", padding: "0 8px 0 8px"}}>Completed</Tag>
      default:
        return <span/>
    }
  }

  const handleAddNewPart = (data) => {
    dispatch(addNewPart({orderId: orderDetails.id, data})).then(()=>{
      closeAddPartModal()
      dispatch(fetchPendingOrderParts({id: orderDetails.id}))
    })
  }

  const handleQuotePart = (data) => {
    let {name, uploadTime, uploadIndex, ...filteredData} = data.data
    dispatch(quoteManualPart({orderId: orderDetails.id, partId: data.partId, type: data.type, data: filteredData})).then(()=>{
      closeManualQuoteModal()
      dispatch(fetchPendingOrderParts({id: orderDetails.id}))
    })
  }

  const handleManualPartReject = (partId, e) => {
    let data = {edits: [{partId, editType: "delete"}]}
    dispatch(editPendingOrder({orderId: orderDetails.id, data})).then(()=>{
      dispatch(fetchPendingOrderParts({id: orderDetails.id}))
      e()
    })
  }

  const handleRejectManualPart = (partId) => {
    let modal = Modal.confirm({
      className: "inputInfoText",
      visible: true,
      style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
      centered: true,
      onOk: (e) => {
        modal.update(prevConfig => ({
          ...prevConfig,
          cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
          okButtonProps: {...prevConfig.okButtonProps, loading: true}
        }));
        handleManualPartReject(partId, e)
      },
      confirmLoading: true,
      destroyOnClose: true,
      width: 400,
      title: <span>{"Reject Part"}</span>,
      content: <div>
        <div>Are you sure you want to reject this part ?</div>
        <Divider style={{
          marginBottom: 0,
          position: 'relative',
          right: 36,
          width: 334
        }}/>
      </div>,
      okText: <span style={{fontSize: 13, fontWeight: 500}}>Reject</span>,
      okButtonProps: {type: "danger", loading: false},
      cancelText: <span
        style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
      icon: <DeleteOutlined style={{color: "red"}}/>,
      zIndex: 2,
    })
  }

  const handleRevertOrder = () => {
    dispatch(revertOrder({orderId: orderDetails.id})).then(()=> {
      setChangesVisible(false)
      dispatch(fetchPendingOrder({id: orderDetails.id, type: "pending"}))
        .then((order)=>{
          setSelectedDate(new Date(order.deliveryDate))
        })
    })
  }

  const getOrderState = (state) => {
    switch (state) {
      case "quoted":
        return "pending"
      case "awaitingQuote":
        return "pending"
      case "awaitingConfirmation":
        return "awaiting-confirmation"
      case "accepted":
        return "accepted"
      case "inProgress":
        return "accepted"
      default:
        return "pending"
    }
  }

  const handleShowDownloadFiles = (item) => {
    setFilesItem(item)
    setDownloadFilesVisible(true)
  }

  const handleCloseDownloadFiles = () => {
    setDownloadFilesVisible(false)
    setFilesItem(null)
  }

  const handleDownloadZip = () => {
    dispatch(downloadOrderZip({orderId: orderDetails.id})).then((zipUrl)=>{
      setTimeout(()=>{
        const link = document.createElement('a');
        link.href = zipUrl;

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        dispatch(setGeneratingZip(false))
      }, 1000)
    }).catch(()=>{
      dispatch(setGeneratingZip(false))
    })
  }

  return (
    <Layout
      style={{
        overflow: "auto",
        minHeight: "100vh",
      }}
    >
      {(role === "regularCustomer" || role === "corporateCustomer") &&
        <Sidebar
          onLogout={() => {
            dispatch(logoutUser())
          }}
          user={user}
        />
      }
      {(role === "producerAdmin" || role === "producerSubAdmin") &&
        <CompanySidebar
          onLogout={() => {
            dispatch(logoutUser())
          }}
          user={user}
          collapsed={true}
        />
      }
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <Spin
          spinning={isFetchingPendingOrder}
          wrapperClassName={"fetchingOrderSpin"}
          indicator={
            <div style={{position: "absolute", top: "35%", left: "50%", width: 400, transform: "translateX(-50%)"}}>
              <LoadingOutlined style={{ fontSize: 72 }} spin />
              <div style={{fontSize: 27, fontWeight: 500, marginTop: 10}}>Fetching Your Order</div>
            </div>
          }
        >
        <div style={{overflowX: "hidden", minHeight: "100vh"}}>
          <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
            {orderDetails.id && !isFetchingPendingOrder &&
              <Row gutter={[20, 10]}>
                <Col span={24}>
                  <Card
                    bodyStyle={{
                      padding: "10px 15px 15px 15px"
                    }}
                    style={{
                      width: "100%",
                      borderRadius: 5,
                      border: "1px solid rgb(205, 205, 205)",
                      boxShadow: "rgba(0, 0, 0, 0.07) 0 2px 4px 1px"
                    }}
                  >
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                      <div style={{display: "flex"}}>
                        <div style={{marginRight: 5, marginTop: 6}}>
                          <IconButton
                            color="primary"
                            aria-label="back to pending orders"
                            size="small"
                            onClick={()=>{
                              history.goBack()
                            }}
                          >
                            <ArrowBackIosRoundedIcon  fontSize="inherit" />
                          </IconButton>
                        </div>
                        <div
                          className={"orderDetailsTitle"}
                          style={{
                            marginTop: 5
                          }}
                        >
                          {(role === "regularCustomer" || role === "corporateCustomer") && (orderDetails.customName || `Order ${orderDetails.name}`)}
                          {(role === "producerAdmin" || role === "producerSubAdmin") && `Order ${orderDetails.name}`}
                        </div>
                        <div>
                          {(role === "producerAdmin" || role === "producerSubAdmin") &&
                            <div style={{marginLeft: -5, marginTop: 8}}>
                              {formatOrderStateProducer()}
                            </div>
                          }
                        </div>
                        {(role === "producerAdmin" || role === "producerSubAdmin") && (orderDetails.state === "quoted" || orderDetails.state === "awaitingQuote")  &&
                          <div>
                            <Button
                              type="primary"
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                                marginRight: 5,
                                marginTop: 5
                              }}
                              disabled={isEditing || isFetchingPendingOrderParts}
                              onClick={() => startEdit()}
                            >
                              <EditOutlined style={{fontSize: 14}}/>
                              Edit Order
                            </Button>
                            <Button
                              disabled={isEditing || isFetchingPendingOrderParts}
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                                marginTop: 5,
                                marginRight: 10
                              }}
                              onClick={() => {
                                openAddPartModal()
                              }}
                            >
                              <FileAddOutlined style={{fontSize: 14}}/>
                              Add Part
                            </Button>
                          </div>
                        }
                        {orderDetails.state === "awaitingConfirmation" && (role === "regularCustomer" || role === "corporateCustomer") &&
                          <div style={{marginTop: 4}}>
                            <OrderChanges
                              order={orderDetails}
                            />
                          </div>
                        }
                      </div>
                      <div align={"right"}>
                        <OrderExcelExportButton
                          role={role}
                          name={orderDetails.name}
                          isEditing={isEditing}
                          parts={orderDetails.processedParts || orderDetails.parts || []}
                          adjustment={orderDetails.adjustment || 0}
                        />
                        <OrderPdfExportButton
                          orderData={{
                            name: orderDetails.name,
                            orderDate: orderDetails.orderDate,
                            deliveryDate: orderDetails.deliveryDate,
                            totalPrice: orderDetails.totalPrice,
                            customer: orderDetails.customer,
                            shippingData: orderDetails.shippingData,
                            producer: orderDetails.producer || user.producer,
                          }}
                          isEditing={isEditing}
                          parts={orderDetails.processedParts || orderDetails.parts || []}
                        />
                        <Button
                          type="primary"
                          style={{
                            marginRight: 5,
                            fontSize: 13,
                            fontWeight: 500,
                            marginTop: 5
                          }}
                          onClick={handleDownloadZip}
                          loading={isGeneratingZip}
                          disabled={isEditing}
                        >
                          <FileZipOutlined style={{fontSize: 14}}/>
                          Download Files
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col span={24}>
                  <Row gutter={[10, 10]}>
                    <Col xxl={15} xl={13} lg={12} span={24}>
                      <span style={{fontSize: 20, fontWeight: 600, color: "#333f48", marginBottom: 5}}>Details</span>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: 5,
                          border: "1px solid rgb(205, 205, 205)",
                          boxShadow: "rgba(0, 0, 0, 0.07) 0 2px 4px 1px"
                        }}
                      >
                        <Row>
                          <Col xxl={16} xl={24} span={24}>
                            <Card
                              style={{
                                borderRadius: 5,
                                height: "100%"
                              }}
                              bodyStyle={{
                                height: "100%",
                                paddingTop: 10,
                                paddingLeft: 20
                              }}
                            >
                              <Row>
                                <Col xxl={13} xl={13} lg={24} span={24}>
                                  <div style={{fontSize: 13}}>Date of order:</div>
                                  <div className={"dataValue"}>{formatTableDate(orderDetails.orderDate)}</div>
                                  <div style={{fontSize: 13}}>Email:</div>
                                  <div className={"dataValue"}>{orderDetails.customer.email}</div>
                                  <div style={{fontSize: 13}}>Customer:</div>
                                  <div className={"dataValue"}>{orderDetails.customer.name}</div>
                                  <div style={{fontSize: 13}}>Phone:</div>
                                  <div className={"dataValue"}>{orderDetails.shippingData.phone}</div>
                                </Col>
                                <Col xxl={11} xl={11} lg={24} span={24}>
                                  <div style={{fontSize: 13}}>Address:</div>
                                  <div className={"dataValue"}>{orderDetails.shippingData.address}</div>
                                  <div style={{fontSize: 13}}>City:</div>
                                  <div className={"dataValue"}>{orderDetails.shippingData.city}</div>
                                  <div style={{fontSize: 13}}>Country:</div>
                                  <div className={"dataValue"}>{orderDetails.shippingData.country}</div>
                                  <div style={{fontSize: 13}}>Postal code:</div>
                                  <div className={"dataValue"}>{orderDetails.shippingData.postalCode}</div>
                                </Col>
                                {orderDetails.orderNote &&
                                  <Col span={24} style={{borderTop: "1px dotted #bababa", marginTop: 5}}>
                                    <div style={{display: "flex", marginTop: 10, marginBottom: -10}}>
                                      <div style={{fontSize: 14, marginRight: 5, color: "#047a04"}}>Note:</div>
                                      <div className={"dataValue"}>{orderDetails.orderNote}</div>
                                    </div>
                                  </Col>
                                }
                              </Row>
                            </Card>
                          </Col>
                          <Col xxl={8} xl={24} span={24}>
                            <Row style={{height: "100%"}}>
                              <Col xxl={24} xl={8} lg={24} span={24}>
                                <Card
                                  style={{borderRadius: 5, height: "100%"}}
                                  bodyStyle={{height: "100%", padding: "10px 15px 10px 15px"}}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      height: "100%"
                                    }}
                                  >
                                    <div style={{display: "block"}}>
                                      <div style={{fontSize: 13}}>Delivery Date:</div>
                                      <div style={{height: 30}}>
                                        {isEditing ?
                                          <DatePicker
                                            selected={selectedDate}
                                            dateFormat={'dd/MM/yyyy'}
                                            minDate={new Date()}
                                            onChange={handleChange}
                                            className={"orderDetailsDatePicker"}
                                          />
                                          :
                                          <div className={"dataValue2"}>{formatTableDate(orderDetails.deliveryDate)}</div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                              <Col xxl={24} xl={8} lg={24} span={24}>
                                <Card
                                  style={{borderRadius: 5, height: "100%"}}
                                  bodyStyle={{height: "100%", padding: "10px 15px 10px 15px"}}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      height: "100%"
                                    }}
                                  >
                                    <div style={{display: "block"}}>
                                      <div style={{fontSize: 13}}>Total Price:</div>
                                      <div style={{height: 30}}>
                                        {editMap.newTotalPrice ?
                                          <div
                                            className={"dataValue2"}
                                            style={{
                                              color: editMap.oldTotalPrice < editMap.newTotalPrice ? "#047a04" : "#b60a1c",
                                              display: "flex"
                                            }}
                                          >
                                            <div>
                                              {editMap.newTotalPrice} € {orderDetails?.unprocessedParts?.length > 0 && "+ RFQ"}
                                            </div>
                                            <div>
                                              {editMap.oldTotalPrice < editMap.newTotalPrice ?
                                                <ArrowRightAltIcon
                                                  style={{
                                                    transform: "rotate(-90deg)",
                                                    fontSize: 18,
                                                    marginTop: 2,
                                                    marginBottom: -4
                                                  }}
                                                />
                                                :
                                                <ArrowRightAltIcon
                                                  style={{
                                                    transform: "rotate(90deg)",
                                                    fontSize: 18,
                                                    marginTop: 2,
                                                    marginBottom: -4
                                                  }}
                                                />
                                              }
                                            </div>
                                          </div>
                                          :
                                          <div className={"dataValue2"}>{(orderDetails.totalPrice || 0).toFixed(2)} € {orderDetails?.unprocessedParts?.length > 0 && "+ RFQ"}</div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                              <Col xxl={24} xl={8} lg={24} span={24}>
                                <Card
                                  style={{borderRadius: 5, height: "100%"}}
                                  bodyStyle={{height: "100%", padding: "10px 15px 10px 15px"}}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      height: "100%"
                                    }}
                                  >
                                    <div style={{display: "block"}}>
                                      <div style={{fontSize: 13}}>Total with VAT:</div>
                                      <div style={{height: 30}}>
                                        {editMap.newTotalPrice ?
                                          <div
                                            className={"dataValue2"}
                                            style={{
                                              color: editMap.oldTotalPrice < editMap.newTotalPrice ? "#047a04" : "#b60a1c",
                                              display: "flex"
                                            }}
                                          >
                                            <div>
                                              {(Number(editMap.newTotalPrice) * (1 + orderDetails.vatValue) || Number(editMap.newTotalPrice) || 0).toFixed(2)} €  {orderDetails?.unprocessedParts?.length > 0 && "+ RFQ"}
                                            </div>
                                            <div>
                                              {editMap.oldTotalPrice < editMap.newTotalPrice ?
                                                <ArrowRightAltIcon
                                                  style={{
                                                    transform: "rotate(-90deg)",
                                                    fontSize: 18,
                                                    marginTop: 2,
                                                    marginBottom: -4
                                                  }}
                                                />
                                                :
                                                <ArrowRightAltIcon
                                                  style={{
                                                    transform: "rotate(90deg)",
                                                    fontSize: 18,
                                                    marginTop: 2,
                                                    marginBottom: -4
                                                  }}
                                                />
                                              }
                                            </div>
                                          </div>
                                          :
                                          <div className={"dataValue2"}>
                                            {(orderDetails.totalPrice * (1 + orderDetails.vatValue) || orderDetails.totalPrice || 0).toFixed(2)} € {orderDetails?.unprocessedParts?.length > 0 && "+ RFQ"}
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                              <Col xxl={24} xl={24} lg={24} span={24}>
                                <Card
                                  style={{
                                    borderRadius: 5,
                                    height: "100%"
                                  }}
                                  bodyStyle={{
                                    height: "100%",
                                    paddingLeft: 0
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      height: "100%",
                                      alignItems: "center",
                                      justifyContent: "flex-end"
                                    }}
                                  >
                                    {(role === "producerAdmin" || role === "producerSubAdmin") &&
                                      <div>
                                        {(orderDetails.state === "quoted" || orderDetails.state === "awaitingQuote") &&
                                          <div>
                                            {isEditing ?
                                              <div>
                                                <Button
                                                  type="primary"
                                                  disabled={!hasBeenEdited}
                                                  style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                  onClick={() => {
                                                    confirmChanges()
                                                  }}
                                                >
                                                  Save
                                                </Button>
                                                <Button
                                                  style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                  onClick={() => {
                                                    cancelEdit()
                                                  }}
                                                >
                                                  Cancel
                                                </Button>
                                              </div>
                                              :
                                              <div>
                                                {(orderDetails.stage === "edited" || orderDetails.state === "awaitingQuote") ?
                                                  (orderDetails.unprocessedParts.length > 0 ?
                                                    <Tooltip
                                                      getPopupContainer={trigger => trigger.parentNode}
                                                      color={"rgba(82,82,82,0.9)"}
                                                      placement={"bottom"}
                                                      title={<span className={"text12-500"} style={{color: "white"}}>All parts must be quoted!</span>}
                                                    >
                                                      <Button
                                                        type="primary"
                                                        style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                        disabled={true}
                                                      >
                                                        <FileDoneOutlined style={{fontSize: 14}}/>
                                                        Send Quote
                                                      </Button>
                                                    </Tooltip>
                                                    :
                                                    <Button
                                                      type="primary"
                                                      style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                      onClick={() => setSendQuoteVisible(true)}
                                                    >
                                                      <FileDoneOutlined style={{fontSize: 14}}/>
                                                      Send Quote
                                                    </Button>
                                                  )
                                                  :
                                                  <Button
                                                    type="primary"
                                                    className={"placeOrderButton"}
                                                    style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                    onClick={() => setAcceptVisible(true)}
                                                  >
                                                    <CheckOutlined style={{fontSize: 14}}/>
                                                    Accept
                                                  </Button>
                                                }
                                                <Button
                                                  type="danger"
                                                  style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                  onClick={() => setDeclineVisible(true)}
                                                >
                                                  <CloseOutlined style={{fontSize: 14}}/>
                                                  Decline
                                                </Button>
                                              </div>
                                            }
                                          </div>
                                        }
                                        {orderDetails.state === "awaitingConfirmation" &&
                                          <div style={{position: "absolute", left: 15, top: 21}}>
                                            <OrderChanges
                                              order={orderDetails}
                                            />
                                          </div>
                                        }
                                        {orderDetails.state === "accepted" &&
                                          <div>
                                            <Button
                                              type="primary"
                                              className={"inProgressButton"}
                                              style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                              onClick={()=> setInProgressVisible(true)}
                                            >
                                              <CheckCircleOutlined style={{fontSize: 14}}/>
                                              Begin Production
                                            </Button>
                                            <Button
                                              type="danger"
                                              style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                              onClick={() => setDeclineVisible(true)}
                                            >
                                              <CloseOutlined style={{fontSize: 14}}/>
                                              Decline
                                            </Button>
                                          </div>
                                        }
                                        {orderDetails.state === "inProgress" &&
                                          <div>
                                            <Button
                                              type="primary"
                                              style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                              onClick={()=>setCompleteVisible(true)}
                                            >
                                              <ProfileOutlined style={{fontSize: 14}}/>
                                              Complete
                                            </Button>
                                            <Button
                                              type="danger"
                                              style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                              onClick={() => setDeclineVisible(true)}
                                            >
                                              <CloseOutlined style={{fontSize: 14}}/>
                                              Decline
                                            </Button>
                                          </div>
                                        }
                                      </div>
                                    }
                                    {(orderDetails.state === "completed" || orderDetails.state === "declined") &&
                                      <div style={{position: "absolute", left: 15}}>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            height: "100%"
                                          }}
                                        >
                                          <div style={{display: "block"}}>
                                            <div style={{fontSize: 13}}>Completion Date:</div>
                                            <div className={"dataValue2"}>{formatTableDate(orderDetails.completionDate)}</div>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    {(role === "regularCustomer" ||
                                      role === "corporateCustomer" ||
                                      orderDetails.state === "declined" ||
                                      orderDetails.state === "completed") &&
                                      orderDetails.state !== "awaitingConfirmation"  &&
                                      <div>
                                        {formatOrderState()}
                                      </div>
                                    }
                                    {(role !== "regularCustomer" && role !== "corporateCustomer" && orderDetails.state === "awaitingConfirmation") &&
                                      <div>
                                        {formatOrderState()}
                                      </div>
                                    }
                                    {((role === "regularCustomer" || role === "corporateCustomer") && orderDetails.state === "awaitingConfirmation") &&
                                      <div>
                                        <Button
                                          type="primary"
                                          className={"placeOrderButton"}
                                          style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                          onClick={() => setAcceptVisible(true)}
                                        >
                                          <CheckOutlined style={{fontSize: 14}}/>
                                          Accept
                                        </Button>
                                        <Button
                                          type="danger"
                                          style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                          onClick={() => setDeclineVisible(true)}
                                        >
                                          <CloseOutlined style={{fontSize: 14}}/>
                                          Decline
                                        </Button>
                                      </div>
                                    }
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xxl={9} xl={11} lg={12} span={24}>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            color: "#333f48",
                          }}
                        >
                          Processed Parts
                        </div>
                        <div>
                          {orderDetails.stage === "edited" && (role === "producerAdmin" || role === "producerSubAdmin") &&
                            <Button
                              type="primary"
                              disabled={isEditing || isFetchingPendingOrderParts}
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                                height: 30,
                                padding: "4px 8px"
                              }}
                              onClick={() => setChangesVisible(true)}
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <UndoIcon style={{ fontSize: 16, marginRight: 3 }}/>
                                <span>Undo Changes</span>
                              </div>
                            </Button>
                          }
                        </div>
                      </div>
                      <OrderDetailsPartProcessedPartsList
                        parts={orderDetails.processedParts || orderDetails.parts || []}
                        showOtherItem={showDetailsView}
                        showDetailsItem={showItem}
                        unprocessedPartsLength={orderDetails.unprocessedParts?.length || 0}
                        isEditing={isEditing}
                        orderState={orderDetails.state}
                        editMap={editMap}
                        editItemFunc={editItemFunc}
                        role={role}
                        handleRemovePartOnEdit={handleRemovePartOnEdit}
                        isFetchingProcessedList={isFetchingPendingOrderParts}
                        handleShowDownloadFiles={handleShowDownloadFiles}
                        handleQuantityChange={openQuantityChangeModal}
                      />
                      {orderDetails.unprocessedParts?.length > 0 &&
                        <div style={{marginTop: 10}}>
                          <span style={{fontSize: 20, fontWeight: 600, color: "#333f48", marginBottom: 5}}>Unprocessed Parts</span>
                          <OrderDetailsPartUnprocessedPartsList
                            parts={orderDetails.unprocessedParts || []}
                            isEditing={isEditing}
                            role={role}
                            openManualQuoteModal={openManualQuoteModal}
                            showManualItem={showItem}
                            showOtherItem={showDetailsView}
                            isFetchingUnprocessedList={isFetchingPendingOrderParts}
                            rejectManualPart={handleRejectManualPart}
                            handleShowDownloadFiles={handleShowDownloadFiles}
                          />
                        </div>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          </div>
        </div>
        </Spin>
        <ItemVisualisation
          isModelViewVisible={viewVisible}
          itemView={itemView}
          modalWidth={modalWidth}
          modalHeight={modalHeight}
          onCloseView={cancelShowItem}
          adjustment={0}
        />
        <Modal
          visible={isEditItemVisible}
          centered={true}
          onCancel={handleEditModalClose}
          title="Edit Part Price"
          bodyStyle={{paddingBottom: 0}}
          footer={
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end"
              }}
            >
              <Button
                style={{marginRight: 5, fontWeight: 500, fontSize: 13}}
                onClick={()=>{handleEditModalClose()}}
              >
                Cancel
              </Button>
              <Button
                type={"primary"}
                htmlType="submit"
                form={"orderItemEditForm"}
                style={{fontWeight: 500, fontSize: 13}}
              >
                Apply
              </Button>
            </div>
          }
          destroyOnClose={true}
          width={600}
        >
          {editItem ?
            <div style={{display: "flex", width: "100%"}}>
              <div style={{border: "1px solid rgb(205, 205, 205)", borderRadius: 3, marginRight: 8, height: 58}}>
                {editItem.fileType === "dxf" &&
                  <div>
                    {editItem.dxfData.pngFilePath ?
                      <img
                        style={{
                          width: 55,
                          height: 55,
                          padding: 3
                        }}
                        src={S3_BUCKET_URL + editItem.dxfData.pngFilePath}
                        alt={"part"}
                      />
                      :
                      <div
                        style={{
                          width: 55,
                          height: 55,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <img
                          style={{
                            width: 45,
                            height: 45,
                            padding: 3,
                            marginTop: 5,
                            marginLeft: 4
                          }}
                          src={twoD}
                          alt={"part"}
                        />
                      </div>
                    }
                  </div>
                }
                {editItem.fileType === "step" &&
                  <div>
                    {editItem.stepData.filePaths.pngFilePath ?
                      <img style={{width: 55, height: 55, padding: 3}} src={S3_BUCKET_URL + editItem.stepData.filePaths.pngFilePath} alt={"part"}/>
                      :
                      <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={treeD} alt={"part"}/>
                      </div>
                    }
                  </div>
                }
                {editItem.fileType === "other" &&
                  <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={getPlaceholderImage(editItem.partType)} alt={"part"}/>
                  </div>
                }
              </div>
              <div style={{width: "100%"}}>
                <div>
                  <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 14,
                        fontWeight: 600,
                        marginRight: 8,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 280,
                        whiteSpace: "nowrap"
                      }}
                    >
                      {editItem.fileName || editItem.name}
                    </div>
                  </div>
                  <div>
                    {editItem.partType === "sheetMetal" ?
                      <div
                        style={{fontSize: 12, fontWeight: 500}}
                      >
                        Material: <span style={{fontWeight: 600}}><Trans>{editItem.selectedMaterial.grade}</Trans> ({editItem.thickness}mm)</span>
                      </div>
                      :
                      (editItem.partType === "machined" || editItem.partType === "profile" ?
                          <div
                            style={{fontSize: 12, fontWeight: 500}}
                          >
                            Material: <span style={{fontWeight: 600}}><Trans>{editItem.selectedMaterial.grade}</Trans></span>
                          </div>
                          :
                          <div style={{height: 18}}/>
                      )
                    }
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                      <div
                        style={{fontSize: 12, fontWeight: 500, marginRight: 8}}
                      >
                        Quantity: <span style={{fontWeight: 600}}>{editItem.quantity}</span>
                      </div>
                      <div style={{marginTop: -6}}>
                        <Form
                          ref={form}
                          onFinish={handleItemApply}
                          name={"orderItemEditForm"}
                        >
                          <div style={{display: "flex"}}>
                            <div style={{fontSize: 12, fontWeight: 500, marginRight: 5, marginTop: 7}}>
                              1pc: <span style={{fontWeight: 600}}>€</span>
                            </div>
                            <Form.Item
                              name={"itemPrice"}
                              initialValue={editMap.items[editItem.id] ? editMap.items[editItem.id].newPrice : (editItem.pricePerPiece || 0).toFixed(2)}
                              rules={[
                                {required: true, message: 'Price is required'},
                              ]}
                              validateTrigger={"onSubmit"}
                            >
                              <InputNumber
                                min={0}
                                max={10000000}
                                placeholder={"Enter price"}
                                type={"number"}
                                style={{width: "110px"}}
                              />
                            </Form.Item>
                          </div>
                        </Form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            null
          }
        </Modal>

        <Modal
          visible={isEditFinalisationVisible}
          centered={true}
          onCancel={handleEditFinalisationModalClose}
          title="Edit Confirmation"
          footer={<div style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end"
          }}
          >
            <Button
              style={{marginRight: 5, fontWeight: 500, fontSize: 13}}
              onClick={()=>{handleEditFinalisationModalClose()}}
              disabled={isEditingPendingOrder}
            >
              Cancel
            </Button>
            <Button
              type={"primary"}
              onClick={()=>{handleEditFinalisation()}}
              style={{fontWeight: 500, fontSize: 13}}
              loading={isEditingPendingOrder}
            >
              Save Changes
            </Button>
          </div>
          }
          destroyOnClose={true}
          width={600}
        >
          {editMap.newDeliveryDate !== "" ?
            <div>
              <div style={{fontWeight: 400, fontSize: 13}}>Delivery Date:</div>
              <div style={{display: "flex"}}>
                <div style={{fontWeight: 600, fontSize: 14, color: "#b60a1c"}}>
                  {formatTableDate(editMap.oldDeliveryDate)}
                </div>
                <div align={"center"} style={{display: "flex"}}>
                  <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
                </div>
                <div style={{fontWeight: 600, fontSize: 14, color: "#047a04"}}>
                  {formatTableDate(editMap.newDeliveryDate)}
                </div>
              </div>
              <Divider style={{marginTop: 10, marginBottom: 10}}/>
            </div>
            :
            null
          }
          {editMap.newTotalPrice !== "" ?
            <div>
              <div style={{fontWeight: 400, fontSize: 13}}>Total Price:</div>
              <div style={{display: "flex"}}>
                <div style={{fontWeight: 600, fontSize: 14, color: "#b60a1c"}}>
                  € {(editMap.oldTotalPrice|| 0).toFixed(2)}
                </div>
                <div align={"center"} style={{display: "flex"}}>
                  <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
                </div>
                <div style={{fontWeight: 600, fontSize: 14, color: "#047a04"}}>
                  € {editMap.newTotalPrice}
                </div>
              </div>
              <div style={{fontWeight: 400, fontSize: 13}}>Total with VAT:</div>
              <div style={{display: "flex"}}>
                <div style={{fontWeight: 600, fontSize: 14, color: "#b60a1c"}}>
                  € {(editMap.oldTotalPrice * (1 + orderDetails.vatValue) || editMap.oldTotalPrice || 0).toFixed(2)}
                </div>
                <div align={"center"} style={{display: "flex"}}>
                  <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
                </div>
                <div style={{fontWeight: 600, fontSize: 14, color: "#047a04"}}>
                  € {(Number(editMap.newTotalPrice) * (1 + orderDetails.vatValue) || Number(editMap.newTotalPrice) || 0).toFixed(2)}
                </div>
              </div>
              <Divider style={{marginTop: 10, marginBottom: 20}}/>
              <Card
                bodyStyle={{padding: "10px 15px"}}
              >
                <div style={{maxHeight: 310, overflowY: "scroll", overflowX: "hidden"}}>
                  {orderDetails.processedParts.map(item=>{
                    return (editMap.items[item.id] ?
                      <div key={item.id}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                          <div style={{
                            fontWeight: 600,
                            fontSize: 13,
                            textDecoration: editMap.items[item.id].state === "declined" ? "line-through" : "none"
                          }}>{item.fileName || item.name}:</div>
                          <div style={{display: "flex", marginRight: Object.keys(editMap.items).length > 7 ? 8 : 0}}>
                            <div style={{fontWeight: 600, fontSize: 14, color: "#b60a1c"}}>
                              € {(editMap.items[item.id].oldPrice|| 0).toFixed(2)}
                            </div>
                            <div align={"center"} style={{display: "flex"}}>
                              <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
                            </div>
                            <div style={{fontWeight: 600, fontSize: 14, color: "#047a04"}}>
                              € {editMap.items[item.id].newPrice}
                            </div>
                          </div>
                        </div>
                        <Divider style={{marginTop: 10, marginBottom: 10}}/>
                      </div>
                      :
                      null
                    )
                  })}
                </div>
              </Card>
            </div>
            :
            null
          }
        </Modal>
        <OrderDetailsManualQuote
          isManualQuoteModalVisible={isManualQuoteModalVisible}
          handleClose={closeManualQuoteModal}
          item={manualQuoteItem}
          addNewPart={handleQuotePart}
          isAddingNewPart={isAddingNewPart}
          materials={orderDetails.materials}
        />
        <OrderDetailsManualQuote
          isManualQuoteModalVisible={isAddNewPartVisible}
          handleClose={closeAddPartModal}
          item={null}
          addNewPart={handleAddNewPart}
          isAddingNewPart={isAddingNewPart}
          materials={orderDetails.materials}
        />

        <Modal
          title="Decline"
          centered={true}
          visible={declineVisible}
          onCancel={() => setDeclineVisible(false)}
          destroyOnClose
          footer={[
            <Button
              key="cancel"
              style={{fontWeight: 500}}
              onClick={() => setDeclineVisible(false)}
              disabled={isResolvingPendingOrder}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="danger"
              htmlType="submit"
              form={"declineForm"}
              style={{fontWeight: 500}}
              loading={isResolvingPendingOrder}
            >
              Decline
            </Button>
          ]}
        >
          <h4>Are you sure you want to DECLINE this order ?</h4>
          <Form
            name={"declineForm"}
            onFinish={(values) => {
              dispatch(resolveOrder({orderId: orderDetails.id, orderState: getOrderState(orderDetails.state), orderNote: values.orderNote || "", type: "decline"})).then(()=>{
                setDeclineVisible(false)
                handleOrderReturn()
              }).catch(()=>{})
            }}
          >
            <h4>Optional remark:</h4>
            <Form.Item
              name={"orderNote"}
              className={"textAreaInput"}
            >
              <Input.TextArea
                rows={3}
                className={"textAreaInputText"}
                maxLength={500}
                showCount
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Accept"
          centered={true}
          visible={acceptVisible}
          onCancel={() => setAcceptVisible(false)}
          destroyOnClose
          footer={[
            <Button
              key="cancel"
              style={{fontWeight: 500}}
              onClick={() => setAcceptVisible(false)}
              disabled={isResolvingPendingOrder}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              className={"placeOrderButton"}
              form={"acceptForm"}
              style={{fontWeight: 500}}
              loading={isResolvingPendingOrder}
            >
              <CheckOutlined style={{fontSize: 14}}/>
              Accept
            </Button>
          ]}
        >
          <h4>Are you sure you want to ACCEPT this order ?</h4>
          <Form
            name={"acceptForm"}
            onFinish={(values) => {
              dispatch(resolveOrder({orderId: orderDetails.id, orderState: getOrderState(orderDetails.state), orderNote: values.orderNote || "", type: "accept"})).then(()=>{
                setAcceptVisible(false)
                handleOrderReturn()
              }).catch(()=>{})
            }}
          >
            <h4>Optional remark:</h4>
            <Form.Item
              className={"textAreaInput"}
              name={"orderNote"}
            >
              <Input.TextArea
                rows={3}
                className={"textAreaInputText"}
                maxLength={500}
                showCount
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Begin Production"
          centered={true}
          visible={inProgressVisible}
          onCancel={() => setInProgressVisible(false)}
          width={550}
          footer={[
            <Button
              key="cancel"
              style={{fontWeight: 500}}
              onClick={() => setInProgressVisible(false)}
              disabled={isResolvingPendingOrder}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              className={"inProgressButton"}
              form={"inProgressForm"}
              style={{fontWeight: 500}}
              loading={isResolvingPendingOrder}
            >
              <CheckCircleOutlined style={{fontSize: 14}}/>
              Begin Production
            </Button>
          ]}
        >
          <h4>Are you sure you want to BEGIN PRODUCTION for this order?</h4>
          <Form
            name={"inProgressForm"}
            onFinish={(values) => {
              dispatch(resolveOrder({orderId: orderDetails.id, orderState: getOrderState(orderDetails.state), orderNote: values.orderNote || "", type: "progress"})).then(()=>{
                dispatch(fetchPendingOrder({id: orderDetails.id, type: "accepted"}))
                setInProgressVisible(false)
              }).catch(()=>{})
            }}
          >
            <h4>Optional remark:</h4>
            <Form.Item
              className={"textAreaInput"}
              name={"orderNote"}
            >
              <Input.TextArea
                rows={3}
                className={"textAreaInputText"}
                maxLength={500}
                showCount
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Complete"
          centered={true}
          visible={completeVisible}
          onCancel={() => setCompleteVisible(false)}
          destroyOnClose
          width={550}
          footer={[
            <Button
              key="cancel"
              style={{fontWeight: 500}}
              onClick={() => setCompleteVisible(false)}
              disabled={isResolvingPendingOrder}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              form={"completedForm"}
              style={{fontWeight: 500}}
              loading={isResolvingPendingOrder}
            >
              <ProfileOutlined style={{fontSize: 14}}/>
              Complete
            </Button>
          ]}
        >
          <h4>Are you sure you want to mark this order as COMPLETED ?</h4>
          <Form
            name={"completedForm"}
            onFinish={(values) => {
              dispatch(resolveOrder({orderId: orderDetails.id, orderState: getOrderState(orderDetails.state), orderNote: values.orderNote || "", type: "complete"})).then(()=>{
                setCompleteVisible(false)
                handleOrderReturn()
              }).catch(()=>{})
            }}
          >
            <h4>Optional remark:</h4>
            <Form.Item
              className={"textAreaInput"}
              name={"orderNote"}
            >
              <Input.TextArea
                rows={3}
                className={"textAreaInputText"}
                maxLength={500}
                showCount
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Send Quote"
          centered={true}
          visible={sendQuoteVisible}
          onCancel={() => setSendQuoteVisible(false)}
          destroyOnClose
          footer={[
            <Button
              key="cancel"
              style={{fontWeight: 500}}
              onClick={() => setSendQuoteVisible(false)}
              disabled={isResolvingPendingOrder}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              form={"sendQuoteForm"}
              style={{fontWeight: 500}}
              loading={isResolvingPendingOrder}
            >
              Send Quote
            </Button>
          ]}
        >
          <h4>Are you sure you want to FORWARD QUOTE to the customer ?</h4>
          <Form
            name={"sendQuoteForm"}
            onFinish={(values) => {
              dispatch(resolveOrder({orderId: orderDetails.id, orderState: getOrderState(orderDetails.state), orderNote: values.orderNote || "", type: "send-quote"})).then(()=>{
                setSendQuoteVisible(false)
                handleOrderReturn()
              }).catch(()=>{})
            }}
          >
            <h4>Optional remark:</h4>
            <Form.Item
              name={"orderNote"}
              className={"textAreaInput"}
            >
              <Input.TextArea
                rows={3}
                className={"textAreaInputText"}
                maxLength={500}
                showCount
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Undo Changes"
          centered={true}
          visible={changesVisible}
          onCancel={() => setChangesVisible(false)}
          destroyOnClose
          footer={[
            <Button
              key="cancel"
              style={{fontWeight: 500}}
              onClick={() => setChangesVisible(false)}
              disabled={isRevertingOrder}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              style={{fontWeight: 500}}
              onClick={() => handleRevertOrder()}
              loading={isRevertingOrder}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <UndoIcon style={{ fontSize: 16, marginRight: 3 }}/>
                <span>Undo Changes</span>
              </div>
            </Button>
          ]}
        >
          <h4>Are you sure you want to UNDO all the changes this order ?</h4>
          <h4>All the parts will be reverted to their original state and all the additional parts will be removed.</h4>
          <h4>This action CAN NOT be reverted!</h4>
        </Modal>

        <Modal
          visible={isDetailsView}
          centered={true}
          onCancel={closeDetailsView}
          title="Part Details"
          footer={null}
          width={600}
        >
          <div>
            {otherItemView &&
              <div style={{width: "100%"}}>
                <Card
                  className={"whiteModalCard"}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 5,
                    border: "1px solid rgb(215, 215, 215)",
                    boxShadow: "rgba(0, 0, 0, 0.07) 0 2px 4px 1px"
                  }}
                >
                  <div>
                    <div style={{fontSize: 20, fontWeight: 600, marginBottom: 10}}>{otherItemView.fileName || otherItemView.name}</div>
                    {otherItemView.partType === "sheetMetal" &&
                      <div>
                        <div className={"orderItemDetailsLabel"}>Material Grade:</div>
                        <div className={"orderItemDetailsValue"}><Trans>{otherItemView.selectedMaterial?.grade}</Trans></div>
                        <div className={"orderItemDetailsLabel"}>Thickness:</div>
                        <div className={"orderItemDetailsValue"}>{otherItemView.selectedMaterial?.thickness}mm</div>
                      </div>
                    }
                    {(otherItemView.partType === "machined" || otherItemView.partType === "profile")  &&
                      <div>
                        <div className={"orderItemDetailsLabel"}>Material Grade:</div>
                        <div className={"orderItemDetailsValue"}><Trans>{otherItemView.selectedMaterial?.grade || otherItemView.materialGrade}</Trans></div>
                      </div>
                    }
                    {otherItemView.standard &&
                      <div>
                        <div className={"orderItemDetailsLabel"}>Standard:</div>
                        <div className={"orderItemDetailsValue"}>{otherItemView.standard}</div>
                      </div>
                    }
                    <div className={"orderItemDetailsLabel"}>Quantity:</div>
                    <div className={"orderItemDetailsValue"}>{otherItemView.quantity}</div>
                    {otherItemView.coating?.hasCoating ?
                      <div style={{marginRight: 30, display: "flex"}}>
                        <div style={{marginTop: 10, marginLeft: 2, marginRight: 5}}>
                          <DropIcon style={{color: otherItemView.coating.ralHex, fontSize: 20}}/>
                        </div>
                        <div>
                          <div className={"orderItemDetailsLabel"}>Coating:</div>
                          <div
                            className={"orderItemDetailsValue"}
                          >
                            RAL {otherItemView.coating.colorRAL}, {otherItemView.coating.colorGlossId === "1" ? "Glossy (70±5)" : "Matte (30±5)"}
                          </div>
                        </div>
                      </div>
                      :
                      null
                    }
                    {otherItemView.note ?
                      <div>
                        <div className={"orderItemDetailsLabel"}>Note:</div>
                        <div className={"orderItemDetailsValue"}>{otherItemView.note}</div>
                      </div>
                      :
                      null
                    }
                    {otherItemView.quoteType === "quoted" &&
                      <div>
                        <div className={"orderItemDetailsLabel"}>Part Type:</div>
                        <div className={"orderItemDetailsValue"}>{getManualType(otherItemView.partType)}</div>
                        <div style={{display: "flex"}}>
                          <div style={{marginRight: 30}}>
                            <div className={"orderItemDetailsLabel"}>Price 1pc:</div>
                            <div className={"orderItemDetailsValue"}>€ {(parseFloat(otherItemView.pricePerPiece) || 0).toFixed(2)}</div>
                          </div>
                          <div>
                            <div className={"orderItemDetailsLabel"}>Total Price:</div>
                            <div className={"orderItemDetailsValue"}>€ {(parseFloat(otherItemView.totalPrice) || 0).toFixed(2)}</div>
                          </div>
                        </div>
                      </div>
                    }
                    {otherItemView.quoteType === "unquoted" &&
                      <div>
                        <div className={"orderItemDetailsLabel"}>Proposed Type:</div>
                        <div className={"orderItemDetailsValue"}>{getManualType(otherItemView.partType)}</div>
                        <div style={{display: "flex"}}>
                          <div style={{marginRight: 30}}>
                            <div className={"orderItemDetailsLabel"}>Price:</div>
                            <div className={"orderItemDetailsValue"}>RFQ</div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </Card>
              </div>
            }
          </div>
        </Modal>

        <Modal
          title="Download files"
          centered={true}
          visible={downloadFilesVisible}
          onCancel={() => handleCloseDownloadFiles()}
          footer={null}
          width={350}
        >
          <div>
            {filesItem &&
              <div align={"center"}>
                {filesItem.s3FilePath &&
                  <div style={{marginBottom: 8}}>
                    <Button
                      style={{width: 150}}
                      type={"primary"}
                      href={`${S3_BUCKET_URL + filesItem.s3FilePath}`}
                    >
                      <FileTextOutlined style={{fontSize: 14}} />
                      <span style={{fontSize: 13, fontWeight: 500}}>
                        Original File
                      </span>
                    </Button>
                  </div>
                }
                {filesItem.fileType === "step" &&
                  <div>
                    {filesItem.stepData?.filePaths?.unfoldedStepFilePath &&
                      <div style={{marginBottom: 8}}>
                        <Button
                          style={{width: 150}}
                          type={"primary"}
                          href={`${S3_BUCKET_URL + filesItem.stepData.filePaths.unfoldedStepFilePath}`}
                        >
                          <FileTextOutlined style={{fontSize: 14}} />
                          <span style={{fontSize: 13, fontWeight: 500}}>
                            Unfolded STEP
                          </span>
                        </Button>
                      </div>
                    }
                    {filesItem.stepData?.filePaths?.unfoldedDxfFilePath &&
                      <div style={{marginBottom: 8}}>
                        <Button
                          style={{width: 150}}
                          type={"primary"}
                          href={`${S3_BUCKET_URL + filesItem.stepData.filePaths.unfoldedDxfFilePath}`}
                        >
                          <FileTextOutlined style={{fontSize: 14}} />
                          <span style={{fontSize: 13, fontWeight: 500}}>
                            Generated DXF
                          </span>
                        </Button>
                      </div>
                    }
                  </div>
                }
                {filesItem.drawingPDFFile?.drawingPDFFilePath &&
                  <div>
                    <Button
                      style={{width: 150}}
                      className={"exportPdfButton"}
                      type={"primary"}
                      href={`${S3_BUCKET_URL + filesItem.drawingPDFFile.drawingPDFFilePath}`}
                    >
                      <FilePdfOutlined style={{fontSize: 14}}/>
                      <span style={{fontSize: 13, fontWeight: 500}}>
                        Part Drawing
                      </span>
                    </Button>
                  </div>
                }
              </div>
            }
          </div>
        </Modal>

        <Modal
          title={"Change Part Quantity"}
          visible={quantityChangeVisible}
          footer={
            <div align={"right"}>
              <Button onClick={() => {
                setQuantityChangeVisible(false);
                setSelectedPartQuantityData(null);
              }}>
                <span style={{ fontWeight: 500 }}>Cancel</span>
              </Button>
              <Button type={"primary"}
                      form={"producedQuantityForm"}
                      key={"submit"}
                      htmlType={"submit"}
                      loading={false}
              >
                <span style={{ fontWeight: 500 }}>Save</span>
              </Button>
            </div>
          }
          bodyStyle={{ paddingBottom: 6 }}
          centered={true}
          onCancel={() => {
            setQuantityChangeVisible(false);
            setSelectedPartQuantityData(null);
          }}
          destroyOnClose={true}
          width={520}
        >
          {selectedPartQuantityData !== null &&
            <div>
              <div>
                <span>Total Quantity:</span>
                <span style={{ marginLeft: 4, fontWeight: 500 }}>{selectedPartQuantityData.quantity}</span>
              </div>
              <div>
                <span >Produced:</span>
                <span style={{ marginLeft: 4, fontWeight: 500 }}>{selectedPartQuantityData.producedQuantity}</span>
              </div>
              <div>
                <span>Pending:</span>
                <span style={{ marginLeft: 4, fontWeight: 500 }}>{selectedPartQuantityData.pendingProductionQuantity}</span>
              </div>
              <Divider style={{ marginTop: 6, marginBottom: 6 }}/>
              {selectedPartQuantityData.pendingProductionQuantity !== 0 &&
                <div style={{marginBottom: 4}}>
                  <span style={{fontSize: 12}}>Scrap the plates where this part is pending to add more than the current limit.</span>
                </div>
              }
              <div className={"editQuant"}>
                <span>Edit Produced Quantity:</span>
                <Form name={"producedQuantityForm"}
                      validateTrigger={"onSubmit"}
                      onFinish={handleQuantityChange}
                >
                  <Form.Item name={"newQuantity"}
                             initialValue={selectedPartQuantityData.producedQuantity}
                             rules={[
                               {required: true, message: 'Input is required'},
                             ]}
                             validateTrigger={"onSubmit"}
                  >
                    <InputNumber style={{ width: 102, display: "inline-block" }}
                                 min={0}
                                 max={selectedPartQuantityData.quantity - selectedPartQuantityData.pendingProductionQuantity}
                                 size={"small"}
                                 disabled={selectedPartQuantityData.quantity - selectedPartQuantityData.pendingProductionQuantity === 0}
                    />
                  </Form.Item>
                </Form>
                  <Tooltip title={"Current Limit"}>
                    <span style={{ fontWeight: 500 }}>
                      <span style={{ fontSize: 12, fontWeight: 600, marginLeft: 2 }}>/</span>
                      {(selectedPartQuantityData.quantity - selectedPartQuantityData.pendingProductionQuantity)}
                    </span>
                  </Tooltip>
              </div>
            </div>
          }
        </Modal>
      </Layout.Content>
    </Layout>
  )
}
