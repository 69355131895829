import React, {useState} from "react";
import {Button, Checkbox, Collapse, Radio, Tooltip} from "antd";
import {DeleteFilled} from "@ant-design/icons";
import LazyLoad, { forceCheck } from "react-lazyload";
import {OrderItem} from "../../OrderItem";
import {ManualOrderItem} from "../../ManualOrderItem";

const {Panel} = Collapse

export const ProcessedItemCard = (props) => {

  const {
    orderId, item, materials, changePartMaterial,
    changePartThickness, changePartQuantity, changePartMaterialSurchargePerPiece,
    changePartNote, changePartColor, changePartStandard,
    handleColorRemove, handleRemoveItem,
    showItem, selectedItems, onCheck,
    handleChangeManualItemType, isOrderDetails,
    parameterMissMatch, bulkItem,
    changePrice, adjustment
  } = props;

  const handleCollapse = () => {
    forceCheck()
  }

  if(!item.id) {
    return <div/>
  }

  return (
    <div key={item.id}>
      {item.processingType !== "manual" ?
        <Collapse id={item.id} defaultActiveKey={item.id} className={"collapse"} onChange={handleCollapse}>
          <Panel
            className={"collapse-head"}
            key={item.id}
            header={
              <div
                className={"item-head"}
              >
                <div className={"item-head-left-side"}>
                  <span
                    onClick={event => {
                      event.stopPropagation();
                      let checked = !selectedItems[item.id]
                      onCheck(item, checked);
                    }}
                    style={{paddingTop: 4, paddingBottom: 4, display: "flex"}}
                  >
                  <Checkbox
                    className={"item-head-checkbox"}
                    style={{marginTop: -2}}
                    onClick={event => {
                      event.stopPropagation();
                      let checked = event.target.checked
                      onCheck(item, checked);
                    }}
                    checked={selectedItems[item.id] || false}
                  />
                  <div
                    className={"text14-600"}
                    style={{
                      paddingLeft: 10,
                      paddingRight: 14,
                      color: "white",
                      maxWidth: 700,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      marginTop: 1
                    }}
                  >
                    {item.fileName}
                  </div>
                  </span>
                  <span
                    className={"text14-400"}
                    style={{color: "white"}}
                  >
                  {item.fileType === "step" ?
                    (
                      item.stepData?.partData?.boundingBox?.x + "×" +
                      item.stepData?.partData?.boundingBox?.y + "×" +
                      item.stepData?.partData?.boundingBox?.z + "mm"
                    )
                    :
                    (
                      Math.round(((item?.dxfData?.partData?.minRectangle[0] || 0) + Number.EPSILON) * 100) / 100
                      + "×" +
                      Math.round(((item?.dxfData?.partData?.minRectangle[1] || 0) + Number.EPSILON) * 100) / 100
                      + "mm"
                    )
                  }
                </span>
                </div>
                <div className={"item-head-right-side"}>
                  <Tooltip
                    overlayClassName={"delete-tooltip"}
                    color={"rgba(82,82,82,0.9)"}
                    title={
                      <span
                        className={"text12-500"}
                        style={{color: "white"}}
                      >
                        Delete
                      </span>
                    }
                  >
                    <Button
                      type={'circle'}
                      style={{boxShadow: "5px 0 16px 0 rgba(101,101,101,.1)"}}
                      onClick={event => {
                        event.stopPropagation();
                        handleRemoveItem(item.id)
                      }}
                    >
                      <DeleteFilled/>
                    </Button>
                  </Tooltip>
                </div>
              </div>
            }
            showArrow={false}
          >
            <div style={{minHeight: 266}}>
              <LazyLoad
                resize={true}
                offset={600}
                once
              >
                <div
                  style={{backgroundColor: "white", borderRadius: 2, padding: 10}}
                >
                  <OrderItem
                    orderId={orderId}
                    item={item}
                    materials={materials}
                    changePartMaterial={changePartMaterial}
                    changePartThickness={changePartThickness}
                    changePartQuantity={changePartQuantity}
                    changePartMaterialSurchargePerPiece = {changePartMaterialSurchargePerPiece}
                    changePartNote={changePartNote}
                    changePartColor={changePartColor}
                    handleColorRemove={handleColorRemove}
                    showItem={showItem}
                    isOrderDetails={isOrderDetails}
                    parameterMissMatch={parameterMissMatch}
                    bulkItem={bulkItem}
                    changePrice={changePrice}
                    adjustment={adjustment}
                  />
                </div>
              </LazyLoad>
            </div>
          </Panel>
        </Collapse>
        :
        <Collapse id={item.id} defaultActiveKey={item.id} className={"collapseManual"} onChange={handleCollapse}>
          <Panel
            className={"collapseManual-head"}
            key={item.id}
            header={
              <div
                className={"manualItem-head"}
              >
                <div className={"item-head-left-side"}>
                  <span
                    onClick={event => {
                      event.stopPropagation();
                      let checked = !selectedItems[item.id]
                      onCheck(item, checked)
                    }}
                    style={{paddingTop: 4, paddingBottom: 4, display: "flex"}}
                  >
                    <Checkbox
                      className={"manual-item-head-checkbox"}
                      style={{marginTop: -3}}
                      onClick={event => {
                        event.stopPropagation();
                        let checked = event.target.checked
                        onCheck(item, checked)
                      }}
                      checked={selectedItems[item.id] || false}
                    />
                    <div className={"text14-600"}
                         style={{
                           paddingLeft: 10,
                           paddingRight: 14,
                           color: "#333f48",
                           maxWidth: 700,
                           whiteSpace: "nowrap",
                           overflow: "hidden",
                           textOverflow: "ellipsis",
                         }}
                    >
                      {item.fileName}
                    </div>
                  </span>
                </div>
                <div
                  className={"item-head-right-side"}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <span style={{fontWeight: 500, fontSize: 12}}>
                    Select Type:
                  </span>
                  <Radio.Group
                    defaultValue={item.partType}
                    buttonStyle="solid"
                    style={{marginRight: 20, marginLeft: 10}}
                    onChange={(e) => {
                      handleChangeManualItemType(item.id, e.target.value)
                    }}
                  >
                    <Radio.Button
                      value="sheetMetal"
                      style={{fontWeight: 600, fontSize: 12, height: 30, boxShadow: "none"}}
                    >
                      Sheet Metal
                    </Radio.Button>
                    <Radio.Button
                      value="profile"
                      style={{fontWeight: 600, fontSize: 12, height: 30, boxShadow: "none"}}
                    >
                      Profile
                    </Radio.Button>
                    <Radio.Button
                      value="machined"
                      style={{fontWeight: 600, fontSize: 12, height: 30, boxShadow: "none"}}
                    >
                      Machined
                    </Radio.Button>
                    {/*<Radio.Button*/}
                    {/*  value="assembly"*/}
                    {/*  style={{fontWeight: 600, fontSize: 12, height: 30}}*/}
                    {/*>*/}
                    {/*  Assembly*/}
                    {/*</Radio.Button>*/}
                    <Radio.Button
                      value="other"
                      style={{fontWeight: 600, fontSize: 12, height: 30, boxShadow: "none"}}
                    >
                      Other
                    </Radio.Button>
                  </Radio.Group>
                  <Tooltip
                    overlayClassName={"delete-tooltip"}
                    color={"rgba(82,82,82,0.9)"}
                    title={<span className={"text12-500"} style={{color: "white"}}>Delete</span>}
                  >
                    <Button
                      type={'circle'}
                      style={{boxShadow: "5px 0 16px 0 rgba(101,101,101,.1)"}}
                      onClick={event => {
                        event.stopPropagation();
                        handleRemoveItem(item.id)
                      }}
                    >
                      <DeleteFilled/>
                    </Button>
                  </Tooltip>
                </div>
              </div>
            }
            showArrow={false}
          >
            <div style={{minHeight: 266}}>
              <LazyLoad
                resize={true}
                offset={600}
                once
              >
                <div
                  style={{backgroundColor: "white", borderRadius: 2, padding: 10}}
                >
                  <ManualOrderItem
                    orderId={orderId}
                    item={item}
                    materials={materials}
                    showItem={showItem}
                    changePartMaterial={changePartMaterial}
                    changePartThickness={changePartThickness}
                    changePartQuantity={changePartQuantity}
                    changePartNote={changePartNote}
                    changePartColor={changePartColor}
                    changePartStandard={changePartStandard}
                    handleColorRemove={handleColorRemove}
                    bulkItem={bulkItem}
                    changePrice={changePrice}
                  />
                </div>
              </LazyLoad>
            </div>
          </Panel>
        </Collapse>
      }
    </div>
  )

  // return (
  //   <div key={item.id}>
  //     {item.processingType !== "manual" ?
  //       <div className={"itemCardWrapper"}>
  //         <div
  //           className={"item-head"}
  //         >
  //           <div className={"item-head-left-side"}>
  //             <span
  //               onClick={event => {
  //                 event.stopPropagation();
  //                 let checked = !selectedItems[item.id]
  //                 onCheck(item, checked);
  //               }}
  //               style={{paddingTop: 4, paddingBottom: 4, display: "flex"}}
  //             >
  //             <Checkbox
  //               className={"item-head-checkbox"}
  //               style={{marginTop: -3}}
  //               onClick={event => {
  //                 event.stopPropagation();
  //                 let checked = event.target.checked
  //                 onCheck(item, checked);
  //               }}
  //               checked={selectedItems[item.id] || false}
  //             />
  //             <div
  //               className={"text14-600"}
  //               style={{
  //                 paddingLeft: 10,
  //                 paddingRight: 14,
  //                 color: "white",
  //                 maxWidth: 700,
  //                 cursor: "pointer",
  //                 whiteSpace: "nowrap",
  //                 overflow: "hidden",
  //                 textOverflow: "ellipsis"
  //               }}
  //             >
  //               {item.fileName}
  //             </div>
  //             </span>
  //             <span
  //               className={"text14-400"}
  //               style={{color: "white"}}
  //             >
  //               {item.fileType === "step" ?
  //                 (
  //                   item.stepData?.partData?.boundingBox?.x + "×" +
  //                   item.stepData?.partData?.boundingBox?.y + "×" +
  //                   item.stepData?.partData?.boundingBox?.z + "mm"
  //                 )
  //                 :
  //                 (
  //                   Math.round(((item?.dxfData?.partData?.minRectangle[0] || 0) + Number.EPSILON) * 100) / 100
  //                   + "×" +
  //                   Math.round(((item?.dxfData?.partData?.minRectangle[1] || 0) + Number.EPSILON) * 100) / 100
  //                   + "mm"
  //                 )
  //               }
  //             </span>
  //           </div>
  //           <div className={"item-head-right-side"}>
  //             <Tooltip
  //               overlayClassName={"delete-tooltip"}
  //               color={"rgba(82,82,82,0.9)"}
  //               title={
  //                 <span
  //                   className={"text12-500"}
  //                   style={{color: "white"}}
  //                 >
  //                       Delete
  //                     </span>
  //               }
  //             >
  //               <Button
  //                 type={'circle'}
  //                 style={{boxShadow: "5px 0 16px 0 rgba(101,101,101,.1)"}}
  //                 onClick={event => {
  //                   event.stopPropagation();
  //                   handleRemoveItem(item.id)
  //                   forceCheck()
  //                 }}
  //               >
  //                 <DeleteFilled/>
  //               </Button>
  //             </Tooltip>
  //           </div>
  //         </div>
  //         <div style={{minHeight: 266}}>
  //           <LazyLoad
  //             resize={true}
  //             offset={600}
  //             once
  //           >
  //             <div
  //               style={{backgroundColor: "white", padding: 10}}
  //             >
  //               <OrderItem
  //                 orderId={orderId}
  //                 item={item}
  //                 materials={materials}
  //                 changePartMaterial={changePartMaterial}
  //                 changePartThickness={changePartThickness}
  //                 changePartQuantity={changePartQuantity}
  //                 changePartNote={changePartNote}
  //                 changePartColor={changePartColor}
  //                 handleColorRemove={handleColorRemove}
  //                 showItem={showItem}
  //                 isOrderDetails={isOrderDetails}
  //                 parameterMissMatch={parameterMissMatch}
  //                 bulkItem={bulkItem}
  //                 changePrice={changePrice}
  //               />
  //             </div>
  //           </LazyLoad>
  //         </div>
  //       </div>
  //       :
  //       <div className={"manualItemCardWrapper"}>
  //         <div
  //           className={"manualItem-head"}
  //         >
  //           <div className={"item-head-left-side"}>
  //             <span
  //               onClick={event => {
  //                 event.stopPropagation();
  //                 let checked = !selectedItems[item.id]
  //                 onCheck(item, checked)
  //               }}
  //               style={{paddingTop: 4, paddingBottom: 4, display: "flex"}}
  //             >
  //               <Checkbox
  //                 className={"manual-item-head-checkbox"}
  //                 style={{marginTop: -3}}
  //                 onClick={event => {
  //                   event.stopPropagation();
  //                   let checked = event.target.checked
  //                   onCheck(item, checked)
  //                 }}
  //                 checked={selectedItems[item.id] || false}
  //               />
  //               <div className={"text14-600"}
  //                    style={{
  //                      paddingLeft: 10,
  //                      paddingRight: 14,
  //                      color: "#333f48",
  //                      maxWidth: 700,
  //                      cursor: "pointer",
  //                      whiteSpace: "nowrap",
  //                      overflow: "hidden",
  //                      textOverflow: "ellipsis",
  //                    }}
  //               >
  //                 {item.fileName}
  //               </div>
  //             </span>
  //           </div>
  //           <div
  //             className={"item-head-right-side"}
  //             onClick={e => {
  //               e.stopPropagation()
  //             }}
  //           >
  //             <span style={{fontWeight: 500, fontSize: 12}}>
  //               Select Type:
  //             </span>
  //             <Radio.Group
  //               defaultValue={item.partType}
  //               buttonStyle="solid"
  //               style={{marginRight: 20, marginLeft: 10}}
  //               onChange={(e) => {
  //                 handleChangeManualItemType(item.id, e.target.value)
  //               }}
  //             >
  //               <Radio.Button
  //                 value="sheetMetal"
  //                 style={{fontWeight: 600, fontSize: 12, height: 30, boxShadow: "none"}}
  //               >
  //                 Sheet Metal
  //               </Radio.Button>
  //               <Radio.Button
  //                 value="profile"
  //                 style={{fontWeight: 600, fontSize: 12, height: 30, boxShadow: "none"}}
  //               >
  //                 Profile
  //               </Radio.Button>
  //               <Radio.Button
  //                 value="machined"
  //                 style={{fontWeight: 600, fontSize: 12, height: 30, boxShadow: "none"}}
  //               >
  //                 Machined
  //               </Radio.Button>
  //               {/*<Radio.Button*/}
  //               {/*  value="assembly"*/}
  //               {/*  style={{fontWeight: 600, fontSize: 12, height: 30}}*/}
  //               {/*>*/}
  //               {/*  Assembly*/}
  //               {/*</Radio.Button>*/}
  //               <Radio.Button
  //                 value="other"
  //                 style={{fontWeight: 600, fontSize: 12, height: 30, boxShadow: "none"}}
  //               >
  //                 Other
  //               </Radio.Button>
  //             </Radio.Group>
  //             <Tooltip
  //               overlayClassName={"delete-tooltip"}
  //               color={"rgba(82,82,82,0.9)"}
  //               title={<span className={"text12-500"} style={{color: "white"}}>Delete</span>}
  //             >
  //               <Button
  //                 type={'circle'}
  //                 style={{boxShadow: "5px 0 16px 0 rgba(101,101,101,.1)"}}
  //                 onClick={event => {
  //                   event.stopPropagation();
  //                   handleRemoveItem(item.id)
  //                 }}
  //               >
  //                 <DeleteFilled/>
  //               </Button>
  //             </Tooltip>
  //           </div>
  //         </div>
  //         <div style={{minHeight: 266}}>
  //           <LazyLoad
  //             resize={true}
  //             offset={600}
  //             once
  //           >
  //             <div
  //               style={{backgroundColor: "white", borderRadius: 2, padding: 10}}
  //             >
  //               <ManualOrderItem
  //                 orderId={orderId}
  //                 item={item}
  //                 materials={materials}
  //                 showItem={showItem}
  //                 changePartMaterial={changePartMaterial}
  //                 changePartThickness={changePartThickness}
  //                 changePartQuantity={changePartQuantity}
  //                 changePartNote={changePartNote}
  //                 changePartColor={changePartColor}
  //                 changePartStandard={changePartStandard}
  //                 handleColorRemove={handleColorRemove}
  //                 bulkItem={bulkItem}
  //                 changePrice={changePrice}
  //               />
  //             </div>
  //           </LazyLoad>
  //         </div>
  //       </div>
  //     }
  //   </div>
  // )
}