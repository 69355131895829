import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Card, Button, Form, Input} from 'antd'

import { LinkWrapper, CardTitle, Logo } from '../LoginPage/styles'

import { Link } from '../../components'
import {http, message} from "../../utils";
import {API_BASE_URL} from "../../constants";
import {UserOutlined} from "@ant-design/icons";

import HefeosLogo from '../../assets/Hefeos-IQT-Dark.svg'

class ForgotPassword extends Component {

  state = {
    isSendingEmail: false,
  }

  handleClick = (values) => {
    this.setState({isSendingEmail:true})
    http
      .post(`${API_BASE_URL}/auth/forgot-password-email`, values)
      .then(()=>{
        this.setState({isSendingEmail:false})
        message.show(message.type.success, 'A password reset email has been sent.')
        this.props.history.push('/login')
      })
      .catch(error=>{
        this.setState({isSendingEmail:false})
        message.show(message.type.error, http.getErrorMessage(error))
      })
  }

  render() {
    const {history} = this.props
    return (
      <div className={"publicWrapper"}>
        <div className={"tiledBackground"}>
          <Card className={"publicCard"}>
            <div style={{ textAlign: 'center', marginTop: 20, marginBottom: "6em" }}>
              <img src={HefeosLogo} alt={"Hefeos Logo"} width={300}/>
            </div>
            <CardTitle className={"signInText"}>Forgot Your Password?</CardTitle>
            <div className={"loginLabel"} style={{ marginBottom: 18}}>
              Enter your email address below and we'll send you a link to reset your password.
            </div>
            <Form
              name={'forgot-password'}
              onFinish={this.handleClick}
            >
              <span className={"loginLabel"}>Email</span>
              <Form.Item
                name={'email'}
                rules={[
                  {required: true, message: 'This field is required'},
                  {type: 'email', message: 'Please enter a valid email address'},
                ]}
                validateTrigger={'onSubmit'}
              >
                <Input
                  placeholder="Enter your email address"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: '100%', marginTop: 15}}
                  loading={this.state.isSendingEmail}
                  type="primary"
                  htmlType="submit"
                  className={"loginText darkButton"}
                >
                  Send Reset Email
                </Button>
              </Form.Item>
            </Form>
            <LinkWrapper>
              <Link to="/login" history={history}>
                Already have an account? Sign in!
              </Link>
            </LinkWrapper>
          </Card>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
)
