import {
  UTIL_COLLAPSE, UTIL_UPDATE_ACTIVE_SUBMENU_KEYS, UTIL_UPDATE_CUSTOMER_ACTIVE_SUBMENU_KEYS
} from "./actionTypes";

const collapseSidebar = (collapseState) => dispatch => {
  dispatch({
    type: UTIL_COLLAPSE,
    payload: collapseState
  })
}

const updateActiveSubmenuKeys = (submenuKey) => dispatch => {
  dispatch({
    type: UTIL_UPDATE_ACTIVE_SUBMENU_KEYS,
    payload: submenuKey
  })
}

const updateActiveCustomerSubmenuKeys = (submenuKey) => dispatch => {
  dispatch({
    type: UTIL_UPDATE_CUSTOMER_ACTIVE_SUBMENU_KEYS,
    payload: submenuKey
  })
}

export {
  collapseSidebar, updateActiveSubmenuKeys,
  updateActiveCustomerSubmenuKeys
};