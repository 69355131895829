import {
  CORPORATE_CUSTOMERS_BLOCK_CUSTOMER,
  CORPORATE_CUSTOMERS_CUSTOMERS,
  CORPORATE_CUSTOMERS_FETCHING_CUSTOMERS,
  CORPORATE_CUSTOMERS_FETCHING_DETAILS,
  CORPORATE_CUSTOMERS_IS_REMOVING_CUSTOMER,
  CORPORATE_CUSTOMERS_PAGINATION, CORPORATE_CUSTOMERS_REMOVE_CUSTOMER, CORPORATE_CUSTOMERS_TOGGLING_BLOCK,
} from "./actionTypes";

import {API_BASE_URL} from "../constants";
import {http, message} from "../utils";

const fetchCorporateCustomers = payload => dispatch => {
  dispatch({
    type: CORPORATE_CUSTOMERS_FETCHING_CUSTOMERS,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/corporate-customer?pageRowCount=6&pageIndex=${payload.page}email=${payload.email}&name=${payload.name}`)
    .then(response => {
      const pagination = {...payload.pagination};
      pagination.total = response.data.totalCount;

      dispatch({
        type: CORPORATE_CUSTOMERS_PAGINATION,
        payload: pagination,
      })
      dispatch({
        type: CORPORATE_CUSTOMERS_CUSTOMERS,
        payload: response.data.corporateCustomers,
      })
      dispatch({
        type: CORPORATE_CUSTOMERS_FETCHING_CUSTOMERS,
        payload: false,
      })
    })
    .catch(error => {
      dispatch({
        type: CORPORATE_CUSTOMERS_FETCHING_CUSTOMERS,
        payload: false,
      })
      message.show(message.type.error, http.getErrorMessage(error))
    })
}

const fetchCorporateCustomerDetails = payload => dispatch => {
  dispatch({
    type: CORPORATE_CUSTOMERS_FETCHING_DETAILS,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/corporate-customer/${payload.id}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      message.show(message.type.error, http.getErrorMessage(error))
      dispatch({
        type: CORPORATE_CUSTOMERS_FETCHING_DETAILS,
        payload: false
      })
    })
}

const toggleCorporateCustomerBlock = payload => dispatch => {
  dispatch({
    type: CORPORATE_CUSTOMERS_TOGGLING_BLOCK,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/corporate-customer/block/${payload.id}`, {isBlocked: payload.isBlocked})
    .then(response => {
      dispatch({
        type: CORPORATE_CUSTOMERS_BLOCK_CUSTOMER,
        payload: payload.id
      })
      dispatch({
        type: CORPORATE_CUSTOMERS_TOGGLING_BLOCK,
        payload: false
      })
    })
    .catch(error => {
      message.show(message.type.error, http.getErrorMessage(error))
      dispatch({
        type: CORPORATE_CUSTOMERS_TOGGLING_BLOCK,
        payload: false
      })
    })
}

const removeCorporateCustomer = payload => dispatch => {
  dispatch({
    type: CORPORATE_CUSTOMERS_IS_REMOVING_CUSTOMER,
    payload: true
  })

  return http
    .delete(`${API_BASE_URL}/corporate-customer/${payload.id}`)
    .then(response => {
      dispatch({
        type: CORPORATE_CUSTOMERS_REMOVE_CUSTOMER,
        payload: payload.id,
      })
      dispatch({
        type: CORPORATE_CUSTOMERS_IS_REMOVING_CUSTOMER,
        payload: false
      })
    })
    .catch(error => {
      dispatch({
        type: CORPORATE_CUSTOMERS_IS_REMOVING_CUSTOMER,
        payload: false
      })
      message.show(message.type.error, http.getErrorMessage(error))
    })
}

export {
  fetchCorporateCustomers,
  fetchCorporateCustomerDetails,
  toggleCorporateCustomerBlock,
  removeCorporateCustomer
}