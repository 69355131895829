import React from "react";
import {Button, Card, List, Tooltip} from "antd";
import {errorTypes} from "../../../utils/errorTypes";
import {CloseCircleOutlined, DeleteFilled, FileOutlined} from "@ant-design/icons";

export const OrderErrorPartsList = (props) => {
  const {
    errorParts, handleRemoveErrorParts
  } = props

  return (
    <Card
      style={{
        borderRadius: "5px",
        border: "1px solid #f9423a",
        marginBottom: 20,
        background: "#f9423a"
      }}
      bodyStyle={{
        padding: 0
      }}
    >
      <div
        className={"item-error-head"}
      >
        <div className={"item-head-left-side"}>
          <CloseCircleOutlined
            className={"item-head-spin"}
            style={{fontSize: 18, color: 'white'}}
          />
          <span style={{position: 'relative', top: 1, marginLeft: 12}}>
            <div className={"text14-600"} style={{color: 'white'}}>
              Oops!
            </div>
            <span className={"text12-500"} style={{color: 'white'}}>
              We were unable to process some of your files.
            </span>
          </span>
        </div>
        <div className={"item-head-right-side"}>
          <Tooltip
            overlayClassName={"delete-tooltip"}
            color={"rgba(82,82,82,0.9)"}
            title={<span className={"text12-500"} style={{color: "white"}}>Delete</span>}
          >
            <Button
              type={"circle"}
              danger
              style={{boxShadow: "5px 0 16px 0 rgba(101,101,101,.1)"}}
              onClick={event => {
                event.stopPropagation();
                handleRemoveErrorParts()
              }}
            >
              <DeleteFilled/>
            </Button>
          </Tooltip>
        </div>
      </div>
      <List
        dataSource={errorParts}
        key={"id"}
        className={"errorList"}
        renderItem={item => {
          return (
            <List.Item style={{backgroundColor: "white"}}>
              <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
                <div className={"text12-600"} style={{marginLeft: 15}}>
                  <span style={{color: "#858585"}}>
                    <FileOutlined style={{fontSize: 22}}/>
                  </span>
                  <span style={{position: "relative", marginLeft: 10, top: -3}}>
                    {item.fileName}
                  </span>
                </div>
                <div className={"text12-600"}
                     style={{position: "relative", marginRight: 15, color: "#f9423a", top: 3}}
                >
                  {errorTypes[item.errorType]}
                </div>
              </div>
            </List.Item>
          )
        }}
      />
    </Card>
  );
}