import React, {useState} from "react";
import {FilePdfOutlined} from "@ant-design/icons";
import {Button, Col, Row, Table} from "antd";
import ReactToPrint from "react-to-print";
import logo from "../../../assets/logo/hefeos_logo_01.svg";
import "./style.css";
import {formatDate} from "../../../utils/utility";
import {translator} from "../../../translations/translators";
import {S3_BUCKET_URL} from "../../../constants";


const PrintComponent = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <div className="page-header">
      </div>
      <table>
        <thead>
        <tr>
          <td>
            <div className="page-header-space"/>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <Row>
              <Col span={24}>
                <div style={{margin: "0 30px 30px 30px"}}>
                  <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                    <div className={"centeredCol"} style={{ width: 340 }}>
                      <img style={{objectFit: "contain", maxWidth: "100%", maxHeight: 140}}
                           src={`${S3_BUCKET_URL}${props.orderData.producer.logoUrl}` || logo}
                           alt={''}
                      />
                      <span className={"logoText"}>{props.orderData.producer.name}</span>
                    </div>
                    <div style={{ width: 300 }}>
                      <Row>
                        <Col span={24}>
                          <div className={"orderNoteTitle"}>Order Note</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className={"headerTextFields"}>Order name:</div>
                          <div className={"headerTextFields"} style={{marginTop: 2}}>Date of order:</div>
                          <div className={"headerTextFields"} style={{marginTop: 2}}>Delivery date:</div>
                          <div className={"headerTextFields"} style={{marginTop: 2}}>Total price:</div>
                        </Col>
                        <Col span={12}>
                          <div className={"headerTextValues"}>{props.orderData.name}</div>
                          <div className={"headerTextValues"}>{formatDate(props.orderData.orderDate)}</div>
                          <div className={"headerTextValues"}>{formatDate(props.orderData.deliveryDate)}</div>
                          <div className={"headerTextValues"}>{props.orderData.totalPrice.toFixed(2)}€</div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className={"dataRow"}>
              <div className={"centeredCol"} style={{ alignItems: "flex-start", width: "50%", marginRight: 10, borderRight: "1px solid #cccccc"}}>
                <div className={"dataTitle"}>Delivery To:</div>
                <div className={"invoiceField"}>
                  {props.orderData.customer.name}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.customer.email}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.shippingData.address}, {props.orderData.shippingData.city}, {props.orderData.shippingData.country}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.shippingData.phone}
                </div>
              </div>

              <div className={"centeredCol"} style={{ alignItems: "flex-start", width: "50%", paddingLeft: 10}}>
                <div className={"dataTitle"}>Company Info:</div>
                <div className={"invoiceField"}>
                  {props.orderData.producer.name}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.producer.contactEmail}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.producer.address}, {props.orderData.producer.city}, {props.orderData.producer.country}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.producer.phone}
                </div>
              </div>
            </div>
            <Table columns={[
              {
                title: <span className={"orderNoteColumnTitle"}>Part</span>,
                className: "darkerColumn",
                key: "fileName",
                align: "left",
                ellipsis: true,
                width: "25%",
                dataIndex: "fileName",
                render: data => <span className={"orderNoteValues"}>{data}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>Material</span>,
                key: "material",
                align: "left",
                ellipsis: true,
                width: "25%",
                dataIndex: "material",
                render: data => <span className={"orderNoteValues"}>{data}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>THK</span>,
                className: "darkerColumn",
                key: "thickness",
                align: "left",
                ellipsis: true,
                width: "10%",
                dataIndex: "thickness",
                render: data => <span className={"orderNoteValues"}>{data ? data + "mm" : ""}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>Qty</span>,
                key: "quantity",
                align: "left",
                ellipsis: true,
                width: "10%",
                dataIndex: "quantity",
                render: data => <span className={"orderNoteValues"}>{data}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>PPU</span>,
                className: "darkerColumn",
                key: "pricePerPiece",
                align: "left",
                ellipsis: true,
                width: "15%",
                dataIndex: "pricePerPiece",
                render: data => <span className={"orderNoteValues"}>{data}€</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>Total</span>,
                key: "totalPrice",
                align: "left",
                ellipsis: true,
                width: "15%",
                dataIndex: "totalPrice",
                render: data => <span className={"orderNoteValues"}>{data}€</span>
              },
              ]}
                   dataSource={props.parts}
                   size={"small"}
                   rowKey={"id"}
                   style={{margin: 30}}
                   pagination={false}
                   className={"deliveryNoteTable"}
            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
});

export const OrderPdfExportButton = (props) => {
  const { isEditing, orderData, parts } = props;
  const componentRef = React.useRef();
  const [loading, setLoading] = useState(false)
  const prepPartData = (parts) => {
    const filteredParts = parts.filter(item => item.state !== "declined")
    return filteredParts.map((item, index) => {
      let name = (item.fileName || item.name)
      return {
        id: index,
        fileName: name.substring(0, name.lastIndexOf(".")),
        material: translator(item.selectedMaterial?.grade) || "",
        thickness: item.selectedMaterial?.thickness || "",
        quantity: item.quantity,
        pricePerPiece: item.pricePerPiece.toFixed(4),
        totalPrice: item.totalPrice.toFixed(4)
      }
    })
  }

  const handleAfterPrint = React.useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  return (
    <div style={{ display: "inline-block"}}>
      <ReactToPrint
        content={() => componentRef.current}
        documentTitle={orderData.name + formatDate(Date.now()) + ".pdf"}
        onBeforeGetContent={handleOnBeforeGetContent}
        onAfterPrint={handleAfterPrint}
        trigger={() => (
          <Button
            type="primary"
            className={"exportPdfButton " + (isEditing ? "editingDisabled" : "")}
            disabled={isEditing}
            loading={loading}
            style={{
              marginTop: 5
            }}
          >
            <FilePdfOutlined style={{fontSize: 14, marginLeft: 0}}/>
            Export PDF
          </Button>
        )}
      />
      <div style={{ display: "none"}}>
        <PrintComponent ref={componentRef}
                        orderData={orderData}
                        parts={prepPartData(parts)}
        />
      </div>
    </div>
  )
}