import { API_BASE_URL } from '../constants'
import { http, message } from '../utils'
import {forceLogoutUser} from "./authActions";

const changeLogo = payload => dispatch => {
  const formData = new FormData()
  formData.set('image', payload.file)

  return http
    .formPut(`${API_BASE_URL}/producer/logo`, formData)
    .then(() => {
      message.show(
        message.type.success,
        'Successfully updated logo'
      )
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error;
      }
    })
}

const updateProducer = payload => dispatch => {

  return http
    .put(`${API_BASE_URL}/producer`, payload.data)
    .then(() => {
      message.show(
        message.type.success,
        'Successfully updated company'
      )
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error;
      }
    })
}

const changePassword = payload => dispatch => {
  return http
    .post(`${API_BASE_URL}/auth/change-password`, payload)
    .then(() => {
      message.show(message.type.success, 'Successfully changed password')
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error
      }
    })
}

const deleteAccount = payload => dispatch => {
  return http
    .delete(`${API_BASE_URL}/companies/${payload.company_id}`)
    .then(() => {
      dispatch(payload.logout_user)
      message.show(message.type.success, 'Successfully deleted company.')
    })
    .catch(error => {
      message.show(message.type.error, http.getErrorMessage(error))
    })
}

export {
  changeLogo,
  updateProducer,
  changePassword,
  deleteAccount,
}
