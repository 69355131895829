import {
  PRODUCERS_PAGINATION, PRODUCERS_PRODUCERS,
  PRODUCERS_FETCHING_PRODUCERS, PRODUCERS_FETCHING_PRODUCER_DETAILS,
} from '../actions/actionTypes'
import initialState from './initialState'

const producersReducer = (state = initialState.producers, action) => {
  switch (action.type) {
    case PRODUCERS_PRODUCERS:
      return {
        ...state,
        producers: action.payload,
      }

    case PRODUCERS_FETCHING_PRODUCERS:
      return {
        ...state,
        fetchingProducers: action.payload,
      }

    case PRODUCERS_PAGINATION:
      return {
        ...state,
        producersPagination: action.payload,
      }

    case PRODUCERS_FETCHING_PRODUCER_DETAILS:
      return {
        ...state,
        fetchingProducerDetails: action.payload
      }

    default:
      return state
  }
}

export default producersReducer
