import React, {useEffect} from 'react'
import {Row, Layout, Menu, Button} from 'antd'

import history from "../../../utils/history";

import "./style.css"
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {collapseSidebar, updateActiveCustomerSubmenuKeys} from "../../../actions/utilActions";
import {useDispatch, useSelector} from "react-redux";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

const SubMenu = Menu.SubMenu

let selectedField = '1';

function activeComponent(history) {
  switch (history.location.pathname) {
    case('/'):
    case('/awaiting-order'):
      selectedField = '1';
      break;
    case('/pending-order'):
    case('/pending-orders'):
      selectedField = '2';
      break;
    case('/accepted-order'):
    case('/accepted-orders'):
      selectedField = '3';
      break;
    case('/completed-order'):
    case('/completed-orders'):
      selectedField = '4';
      break
    case('/customer-settings'):
    case('/corporate-settings'):
      selectedField = '5';
      break;
    case('/company-settings'):
      selectedField = '6';
      break;
    default:
      break;
  }
  return selectedField;
}

const Sidebar = (props) => {

  const dispatch = useDispatch();

  const activeCustomerSubmenuKeys = useSelector(state => state.util.activeCustomerSubmenuKeys);

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const onCollapse = () => {
    dispatch(collapseSidebar(!isCollapsed));
  }

  const {onLogout, user} = props
  return (
    <Layout.Sider
      style={{
        overflow: 'auto',
        minHeight: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 1000,
      }}
      collapsed={isCollapsed}
    >
      <Button className={"collapseButton"}
              style={{ left: isCollapsed ? '50px' : '190px'}}
              onClick={() => onCollapse()}
      >
        { isCollapsed ?
          <RightOutlined style={{ fontSize: 14, position: "relative", top: 1 }}/>
          :
          <LeftOutlined style={{ fontSize: 14, position: "relative", top: 1  }}/>
        }
      </Button>
      <Row style={{marginTop: '2rem', height: "100%"}}>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[activeComponent(history)]}
          defaultOpenKeys={isCollapsed ? [] : activeCustomerSubmenuKeys}
        >
          <Menu.Item
            key="1"
            onClick={() => {
              history.push('/')
            }}
            title={<span className="nav-text">Dashboard</span>}
            style={{ paddingLeft: 22 }}
          >
            <a href={"/"} onClick={(e) => e.preventDefault()}>
              <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <DashboardOutlinedIcon style={{fontSize: 22}}/>
                <span
                  className={"menuLine"}
                  style={{opacity: isCollapsed ? 0 : 1}}
                >Dashboard</span>
              </div>
            </a>
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => {
              history.push('/pending-orders')
            }}
            title={<span className="nav-text">Pending Orders</span>}
            style={{ paddingLeft: 22 }}
          >
            <a href={"/pending-orders"} onClick={(e) => e.preventDefault()}>
              <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <PendingActionsIcon style={{fontSize: 22}}/>
                <span
                  className={"menuLine"}
                  style={{opacity: isCollapsed ? 0 : 1}}
                >Pending Orders</span>
              </div>
            </a>
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={() => {
              history.push('/accepted-orders')
            }}
            title={<span className="nav-text">Accepted Orders</span>}
            style={{ paddingLeft: 22 }}
          >
            <a href={"/accepted-orders"} onClick={(e) => e.preventDefault()}>
              <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <AssignmentOutlinedIcon style={{fontSize: 22}}/>
                <span
                  className={"menuLine"}
                  style={{opacity: isCollapsed ? 0 : 1}}
                >Accepted Orders</span>
              </div>
            </a>
          </Menu.Item>
          <Menu.Item
            key="4"
            onClick={() => {
              history.push('/completed-orders')
            }}
            title={<span className="nav-text">Completed Orders</span>}
            style={{ paddingLeft: 22 }}
          >
            <a href={"/completed-orders"} onClick={(e) => e.preventDefault()}>
              <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <InventoryOutlinedIcon style={{fontSize: 22}}/>
                <span
                  className={"menuLine"}
                  style={{opacity: isCollapsed ? 0 : 1}}
                >Completed Orders</span>
              </div>
            </a>
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={() => {
              if (user?.role === 'regularCustomer') {
                history.push('/customer-settings')
              } else {
                history.push('/corporate-settings')
              }
            }}
            title={<span className="nav-text">Account Settings</span>}
            style={{ paddingLeft: 22 }}
          >
            <a href={`/${user?.role === 'regularCustomer' ? "customer-settings" : "corporate-settings"}`}
               onClick={(e) => e.preventDefault()}
            >
              <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <SettingsOutlinedIcon style={{fontSize: 22}}/>
                <span
                  className={"menuLine"}
                  style={{opacity: isCollapsed ? 0 : 1}}
                >Account Settings</span>
              </div>
            </a>
          </Menu.Item>
        </Menu>
        <Menu
          theme="dark"
          mode="inline"
          style={{position: "absolute", bottom: 20}}
        >
          <Menu.Item
            key="6"
            onClick={onLogout}
            title={<span className="nav-text">Sign out</span>}
            style={{ paddingLeft: 22 }}
          >
            <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
              <LogoutOutlinedIcon style={{fontSize: 22}}/>
              <span
                className={"menuLine"}
                style={{opacity: isCollapsed ? 0 : 1}}
              >Sign Out</span>
            </div>
          </Menu.Item>
        </Menu>
      </Row>
    </Layout.Sider>
  )
}

export default Sidebar
