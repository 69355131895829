import React, {useCallback, useEffect, useState} from 'react';
import {
  Card, Tabs, Form, InputNumber,
  Typography, Space, Divider, Select,
  Button, Modal, Input, Spin, Col, Row
} from 'antd';

import "./style.css"
import {
  CaretDownOutlined,
  DeleteOutlined,
  EditOutlined, LoadingOutlined,
  WarningOutlined
} from "@ant-design/icons";
import MaterialTableContainer from "../../MaterialTableContainer";
import ValueTable from "../../MaterialTables/ValueTable";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { WaterJetIcon } from "../../../../../assets/icons/waterJetIcon";
import {useWindowSize} from "../../../../../utils/utility";
import { lineBreakLimit } from "../../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchCuttingMachineParameters, fetchCuttingMachinePriorities,
  fetchCuttingMachines, fetchCuttingMachineTypes,
  updateCuttingMachineSpeed, setMachineParameter,
  updateCuttingMachinePriorities, createCuttingMachine,
  editCuttingMachine, deleteCuttingMachine,
  setEmptyCuttingMachineData
} from "../../../../../actions/cuttinMachinesActions";
import {debounce} from "lodash";
import {getCuttingPricePerHour} from "../../../../../utils/calculator";
const {TabPane} = Tabs;
const {Option, OptGroup} = Select;

/*
    laser types:
    CO₂ Laser
    Fiber Laser
    Nd:YAG Laser
    CNC Gas
    CNC Plasma
    Water Jet
*/

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: "8px 8px 0 8px",
  margin: `0 0 ${grid}px 0`,
  borderRadius: 3,
  border: isDragging ? "2px solid #91d18a" : "1px solid #cfcfcf",
  boxShadow: "0 2px 4px 1px rgba(0, 0, 0, 0.12)",
  // change background colour if dragging
  background: isDragging ? '#a8eca0' : 'white',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#dfecf1' : '#ececec',
  border: "1px solid #bfbfbf",
  borderRadius: 5,
  padding: grid,
  width: 350,
});

export const LaserCuttingTab = (props) => {

  const {selectedTab, changeCuttingSpeed, changeCuttingMachineParams, socketConnected} = props;

  const [addMachineModalVisible, setAddMachineModalVisible] = useState(false)

  const [editMachine, setEditMachine] = useState(null)
  const [editMachineModalVisible, setEditMachineModalVisible] = useState(false)

  const [removeMachineId, setRemoveMachineId] = useState(null)
  const [removeMachineModalVisible, setRemoveMachineModalVisible] = useState(false)

  const [machinePriorityModalVisible, setMachinePriorityModalVisible] = useState(false)

  const [selectedMachine, setSelectedMachine] = useState(null);

  const [shouldUpdateSpeeds, setShouldUpdateSpeeds] = useState(false)

  const [selectedMachineType, setSelectedMachineType] = useState("")

  const [calculationData, setCalculationData] = useState(
    {
      energyConsumption: 0,
      electricityCost: 0,

      credit: 0,
      interestRate: 0,
      investedCapital: 0,
      machineLifespan: 1, // not 0
      insurance: 0,
      requiredSpace: 0,
      rent: 0,
      maintenanceExpense: 0,
      yearlyHoursPerShift: 0,
      timeEfficiency: 0,
      numberOfShifts: 0,

      singleJetLifespan: 1, // not 0
      singleJetCost: 0,
      numOfActiveJets: 1,

      type: "laser",
      //------------------------
      workingGasPrice: 0,
      workingGasConsumption: 0,
      laserGasPrice: 0,
      laserGasConsumption: 0,

      lensLifespan: 1,  // not 0
      lensPrice: 0,
      //------------------------
      coolingGasPrice: 0,
      coolingGasConsumption: 0,
      plasmaGasPrice: 0,
      plasmaGasConsumption: 0,

      cathodeLifespan: 1, // not 0
      cathodePrice: 0,
      //------------------------
      abrasivePrice: 0,
      abrasiveConsumption: 0,
      //------------------------
      oxygenConsumption: 0,
      oxygenPrice: 0,

      cuttingGasConsumption: 0,
      cuttingGasPrice: 0,

      preheatingGasConsumption: 0,
      preheatingGasPrice : 0
    }
  )

  const [suggestedPricePerHour, setSuggestedPricePerHour] = useState(0)

  const [isCalculationModalVisible, setIsCalculationModalVisible] = useState(false)

  const cuttingMachines = useSelector(state => state.cuttingMachines.cuttingMachines)
  const isFetchingMachines = useSelector(state => state.cuttingMachines.isFetchingMachines)

  const machineTypes = useSelector(state => state.cuttingMachines.machineTypes)
  const isFetchingMachineTypes = useSelector(state => state.cuttingMachines.isFetchingMachineTypes)

  const machinePriorities = useSelector(state => state.cuttingMachines.machinePriorities)
  const isFetchingMachinePriorities = useSelector(state => state.cuttingMachines.isFetchingMachinePriorities)
  const isUpdatingMachinePriorities = useSelector(state => state.cuttingMachines.isUpdatingMachinePriorities)

  const machineData = useSelector(state => state.cuttingMachines.selectedMachine)
  const isFetchingSelectedMachine = useSelector(state => state.cuttingMachines.isFetchingSelectedMachine)

  const isCreatingCuttingMachine = useSelector(state => state.cuttingMachines.isCreatingCuttingMachine)

  const isEditingCuttingMachine = useSelector(state => state.cuttingMachines.isEditingCuttingMachine)

  const isRemovingMachine = useSelector(state => state.cuttingMachines.isRemovingMachine)

  const [machinePriority, setMachinePriority] = useState(machinePriorities)
  const [fetchingAll, setFetchingAll] = useState(false)
  const dispatch = useDispatch();

  const laserCuttingRef = React.createRef();

  useEffect(()=>{
    if(selectedTab === "1") {
      setFetchingAll(true)
      dispatch(fetchCuttingMachineTypes())
      dispatch(fetchCuttingMachines()).then((machines)=>{
        if(machines.length !== 0) {
          if(selectedMachine) {
            let doesExist = machines.find(m => {
              return m?.machines.find(item => item.id === selectedMachine)
            })
            if(doesExist) {
              dispatch(fetchCuttingMachineParameters({cuttingMachineId: selectedMachine})).then(()=>{
                setShouldUpdateSpeeds(true)
                setFetchingAll(false)
              })
            }
            else {
              setSelectedMachine(machines[0].machines[0].id)
              dispatch(fetchCuttingMachineParameters({cuttingMachineId: machines[0].machines[0].id})).then(()=>{
                setShouldUpdateSpeeds(true)
                setFetchingAll(false)
              })
            }
          } else {
            setSelectedMachine(machines[0].machines[0].id)
            dispatch(fetchCuttingMachineParameters({cuttingMachineId: machines[0].machines[0].id})).then(()=>{
              setShouldUpdateSpeeds(true)
              setFetchingAll(false)
            })
          }
        } else {
          setFetchingAll(false)
        }
      }).catch(()=>{})
      dispatch(fetchCuttingMachinePriorities())
    }
  }, [selectedTab])

  const handleAddMachineOpen = () => {
    setAddMachineModalVisible(true)
  }

  const handleAddMachineClose = () => {
    setAddMachineModalVisible(false)
  }

  const handleEditMachine = (e, item) => {
    e.stopPropagation()
    setEditMachine(item)
    setEditMachineModalVisible(true)
  }

  const handleEditMachineClose = () => {
    setEditMachineModalVisible(false)
  }

  const windowSize = useWindowSize();

  const handleRemoveMachine = (e, id) => {
    e.stopPropagation()
    setRemoveMachineId(id)
    setRemoveMachineModalVisible(true)
  }

  const handleMachinePriorityClose = () => {
    setMachinePriorityModalVisible(false)
  }

  const handleMachinePriorityOpen = () => {
    setMachinePriority(machinePriorities)
    setMachinePriorityModalVisible(true)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      machinePriority,
      result.source.index,
      result.destination.index,
    );
    setMachinePriority(items)
  }

  const changeSelectedMachine = (id) => {
    setSelectedMachine(id)
    dispatch(fetchCuttingMachineParameters({cuttingMachineId: id})).then(()=>{
      setShouldUpdateSpeeds(true)
    })
  }

  useEffect(()=>{
    if(!isFetchingSelectedMachine) {
      laserCuttingRef.current?.setFieldsValue({
        approachLengthHigh: machineData.approachLengthHigh,
        approachLengthLow: machineData.approachLengthLow,
        approachLengthThreshold: machineData.approachLengthThreshold,
        averagePositioningSpeed: machineData.averagePositioningSpeed,
        averagePreparationTime: machineData.averagePreparationTime,
        cuttingPrice: machineData.cuttingPrice,
        exitLengthHigh: machineData.exitLengthHigh,
        exitLengthLow: machineData.exitLengthLow,
        exitLengthThreshold: machineData.exitLengthThreshold,
        maxCuttingLength: machineData.maxCuttingLength,
        maxCuttingWidth: machineData.maxCuttingWidth,
        pierceTimeHigh: machineData.pierceTimeHigh,
        pierceTimeLow: machineData.pierceTimeLow,
        pierceTimeThreshold: machineData.pierceTimeThreshold,
        preparationPrice: machineData.preparationPrice
      })
    }
  }, [isFetchingSelectedMachine])

  const changeSpeed = (params) => {
    changeCuttingSpeed({machineId: machineData.id, materialId: params.materialId, speed: params.value})
    dispatch(updateCuttingMachineSpeed(params))
    return Promise.resolve(10)
  }


  /*------- Max Width -------*/
  const changeMaxCuttingWidth = (data) => {
    dispatch(setMachineParameter({maxCuttingWidth: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedMaxCuttingWidthChange = useCallback(
    debounce(data => changeMaxCuttingWidth(data), 800),
    []
  );

  const changeCuttingMaxCuttingWidth = (value) => {
    debouncedMaxCuttingWidthChange({
      machineId: machineData.id,
      fieldName: "maxCuttingWidth",
      fieldValue: value
    })
  }

  /*------- Max Length -------*/
  const changeMaxCuttingLength = (data) => {
    dispatch(setMachineParameter({maxCuttingLength: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedMaxCuttingLengthChange = useCallback(
    debounce(data => changeMaxCuttingLength(data), 800),
    []
  );

  const changeCuttingMaxCuttingLength = (value) => {
    debouncedMaxCuttingLengthChange({
      machineId: machineData.id,
      fieldName: "maxCuttingLength",
      fieldValue: value
    })
  }

  /*------- Cutting Price -------*/
  const changeCuttingPrice = (data) => {
    dispatch(setMachineParameter({cuttingPrice: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedCuttingPriceChange = useCallback(
    debounce(data => changeCuttingPrice(data), 800),
    []
  );

  const changeCuttingCuttingPrice = (value) => {
    debouncedCuttingPriceChange({
      machineId: machineData.id,
      fieldName: "cuttingPrice",
      fieldValue: value
    })
  }

  /*------- Average Positioning Speed -------*/
  const changeAveragePositioningSpeed = (data) => {
    dispatch(setMachineParameter({averagePositioningSpeed: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedAveragePositioningSpeedChange = useCallback(
    debounce(data => changeAveragePositioningSpeed(data), 800),
    []
  );

  const changeCuttingAveragePositioningSpeed = (value) => {
    debouncedAveragePositioningSpeedChange({
      machineId: machineData.id,
      fieldName: "averagePositioningSpeed",
      fieldValue: value
    })
  }

  /*------- Preparation Price -------*/
  const changePreparationPrice = (data) => {
    dispatch(setMachineParameter({preparationPrice: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedPreparationPriceChange = useCallback(
    debounce(data => changePreparationPrice(data), 800),
    []
  );

  const changeCuttingPreparationPrice = (value) => {
    debouncedPreparationPriceChange({
      machineId: machineData.id,
      fieldName: "preparationPrice",
      fieldValue: value
    })
  }

  /*------- Average Preparation Time -------*/
  const changeAveragePreparationTime = (data) => {
    dispatch(setMachineParameter({averagePreparationTime: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedAveragePreparationTimeChange = useCallback(
    debounce(data => changeAveragePreparationTime(data), 800),
    []
  );

  const changeCuttingAveragePreparationTime = (value) => {
    debouncedAveragePreparationTimeChange({
      machineId: machineData.id,
      fieldName: "averagePreparationTime",
      fieldValue: value
    })
  }

  /*------- Pierce Time Low -------*/
  const changePierceTimeLow = (data) => {
    dispatch(setMachineParameter({pierceTimeLow: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedPierceTimeLowChange = useCallback(
    debounce(data => changePierceTimeLow(data), 800),
    []
  );

  const changeCuttingPierceTimeLow = (value) => {
    debouncedPierceTimeLowChange({
      machineId: machineData.id,
      fieldName: "pierceTimeLow",
      fieldValue: value
    })
  }

  /*------- Pierce Time High -------*/
  const changePierceTimeHigh = (data) => {
    dispatch(setMachineParameter({pierceTimeHigh: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedPierceTimeHighChange = useCallback(
    debounce(data => changePierceTimeHigh(data), 800),
    []
  );

  const changeCuttingPierceTimeHigh = (value) => {
    debouncedPierceTimeHighChange({
      machineId: machineData.id,
      fieldName: "pierceTimeHigh",
      fieldValue: value
    })
  }

  /*------- Pierce Time Threshold -------*/
  const changePierceTimeThreshold = (data) => {
    dispatch(setMachineParameter({pierceTimeThreshold: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedPierceTimeThresholdChange = useCallback(
    debounce(data => changePierceTimeThreshold(data), 800),
    []
  );

  const changeCuttingPierceTimeThreshold = (value) => {
    debouncedPierceTimeThresholdChange({
      machineId: machineData.id,
      fieldName: "pierceTimeThreshold",
      fieldValue: value
    })
  }

  /*------- Approach Length Low -------*/
  const changeApproachLengthLow = (data) => {
    dispatch(setMachineParameter({approachLengthLow: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedApproachLengthLowChange = useCallback(
    debounce(data => changeApproachLengthLow(data), 800),
    []
  );

  const changeCuttingApproachLengthLow = (value) => {
    debouncedApproachLengthLowChange({
      machineId: machineData.id,
      fieldName: "approachLengthLow",
      fieldValue: value
    })
  }

  /*------- Approach Length High -------*/
  const changeApproachLengthHigh = (data) => {
    dispatch(setMachineParameter({approachLengthHigh: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedApproachLengthHighChange = useCallback(
    debounce(data => changeApproachLengthHigh(data), 800),
    []
  );

  const changeCuttingApproachLengthHigh = (value) => {
    debouncedApproachLengthHighChange({
      machineId: machineData.id,
      fieldName: "approachLengthHigh",
      fieldValue: value
    })
  }

  /*------- Approach Length Threshold -------*/
  const changeApproachLengthThreshold = (data) => {
    dispatch(setMachineParameter({approachLengthThreshold: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedApproachLengthThresholdChange = useCallback(
    debounce(data => changeApproachLengthThreshold(data), 800),
    []
  );

  const changeCuttingApproachLengthThreshold = (value) => {
    debouncedApproachLengthThresholdChange({
      machineId: machineData.id,
      fieldName: "approachLengthThreshold",
      fieldValue: value
    })
  }

  /*------- Exit Length Low -------*/
  const changeExitLengthLow = (data) => {
    dispatch(setMachineParameter({exitLengthLow: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedExitLengthLowChange = useCallback(
    debounce(data => changeExitLengthLow(data), 800),
    []
  );

  const changeCuttingExitLengthLow = (value) => {
    debouncedExitLengthLowChange({
      machineId: machineData.id,
      fieldName: "exitLengthLow",
      fieldValue: value
    })
  }

  /*------- Exit Length High -------*/
  const changeExitLengthHigh = (data) => {
    dispatch(setMachineParameter({exitLengthHigh: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedExitLengthHighChange = useCallback(
    debounce(data => changeExitLengthHigh(data), 800),
    []
  );

  const changeCuttingExitLengthHigh = (value) => {
    debouncedExitLengthHighChange({
      machineId: machineData.id,
      fieldName: "exitLengthHigh",
      fieldValue: value
    })
  }

  /*------- Exit Length Threshold -------*/
  const changeExitLengthThreshold = (data) => {
    dispatch(setMachineParameter({exitLengthThreshold: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedExitLengthThresholdChange = useCallback(
    debounce(data => changeExitLengthThreshold(data), 800),
    []
  );

  const changeCuttingExitLengthThreshold = (value) => {
    debouncedExitLengthThresholdChange({
      machineId: machineData.id,
      fieldName: "exitLengthThreshold",
      fieldValue: value
    })
  }

  const applyEditMachine = (values) => {
    if(editMachine.typeId !== values.typeId || editMachine.name !== values.name) {
      dispatch(editCuttingMachine({cuttingMachineId: editMachine.id, ...values})).then(()=>{
        handleEditMachineClose()
      })
    } else {
      handleEditMachineClose()
    }
  }

  const createNewMachine = (values) => {
    dispatch(createCuttingMachine(values)).then((id)=>{
      setSelectedMachine(id)
      dispatch(fetchCuttingMachineParameters({cuttingMachineId: id})).then(()=>{
        setShouldUpdateSpeeds(true)
      })
      dispatch(fetchCuttingMachinePriorities())
      handleAddMachineClose()
    })
  }

  const getMachineType = (typeId) => {
    let type = machineTypes.find(item => item.id === typeId)
    switch (type?.name) {
      case "CO₂":
        return "laser"
      case "Fiber Laser":
        return "laser"
      case "Nd:YAG Laser":
        return "laser"
      case "CNC Gas":
        return "gas"
      case "CNC Plasma":
        return "plasma"
      case "Water Jet":
        return "abrasive"
      default:
        return ""
    }
  }

  const showCalculationModal = () => {
    let laserType = getMachineType(machineData.typeId)
    setSelectedMachineType(laserType)
    setCalculationData(prevState => ({...prevState, type: laserType}))
    setIsCalculationModalVisible(true)
  }

  const closeCalculationModal = () => {
    setSelectedMachineType("")
    setIsCalculationModalVisible(false)
  }

  const recalculateCuttingPrice = (data) => {
    let newData = {...data.data, [data.field]: data.value}
    setCalculationData(newData)
    setSuggestedPricePerHour(getCuttingPricePerHour(newData))
  }

  const debouncedRecalculateCuttingPrice = useCallback(
    debounce(data => recalculateCuttingPrice(data), 800),
    []
  );

  const onCalculationFieldChange = (field, value) => {
    debouncedRecalculateCuttingPrice({data: calculationData, field, value})
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"} bodyStyle={{paddingRight: 0}}>
        <div style={{height: 'calc(100vh - 120px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
          <Form
            layout={'horizontal'}
            style={{height: "100%"}}
            ref={laserCuttingRef}
          >
            <Spin spinning={isFetchingMachines || !socketConnected}
                  indicator={null}
            >
            <div className={windowSize.width > lineBreakLimit ? "inLine" : "newLIne"}>
              <div className={"customColumn2"} style={{width: "100%", maxWidth: 1605}}>
                <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'flex-end'}}>
                  <div style={{display: "flex", marginBottom: 10, alignItems: 'flex-end'}}>
                    <div>
                      <div
                        style={{
                          fontSize: 13,
                          fontWeight: 500,
                          display: "inline",
                          marginRight: 8
                        }}
                      >
                        Selected Machine :
                      </div>
                      <Select
                        value={selectedMachine}
                        style={{width: 250}}
                        virtual={false}
                        optionLabelProp={"label"}
                        placeholder={"Select Cutting Machine"}
                        suffixIcon={<CaretDownOutlined/>}
                        onChange={changeSelectedMachine}
                      >
                        {cuttingMachines.map(item => (
                          <OptGroup label={item.name} key={item.id}>
                            {item.machines.map(machine => (
                              <Option value={machine.id} key={machine.id} label={machine.name}
                                      style={{paddingRight: 8}}>
                                <div style={{display: "flex", alignItems: 'center', justifyContent: "space-between"}}>
                                  <div style={{
                                    maxWidth: 160,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    marginTop: 2
                                  }}>
                                    {machine.name}
                                  </div>
                                  <div style={{display: "flex"}}>
                                    <EditOutlined
                                      onClick={(e) => {
                                        if(!isFetchingMachineTypes) {
                                          handleEditMachine(e, {typeId: item.id, id: machine.id, name: machine.name})
                                        }
                                        else {
                                          e.stopPropagation()
                                        }
                                      }}
                                      style={{fontSize: 16, marginRight: 5, color: isFetchingMachineTypes ? "#d9d9d9" : "#1890ff", padding: 2}}
                                    />
                                    <DeleteOutlined
                                      onClick={(e) => {
                                        handleRemoveMachine(e, machine.id)
                                      }}
                                      style={{fontSize: 16, color: "#ff4d4f", padding: 2}}
                                    />
                                  </div>
                                </div>
                              </Option>
                            ))}
                          </OptGroup>
                        ))}

                      </Select>
                    </div>
                    <div style={{display: "flex"}}>
                      <Button
                        type={"primary"}
                        style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
                        onClick={() => {
                          handleAddMachineOpen()
                        }}
                        disabled={isFetchingMachineTypes || isFetchingMachinePriorities}
                      >
                        Add new Machine
                      </Button>
                      <div align={'right'} style={{marginRight: 10}}>
                        <Button
                          type={"primary"}
                          style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
                          onClick={() => {
                            handleMachinePriorityOpen()
                          }}
                          disabled={isFetchingMachineTypes || isFetchingMachinePriorities || machinePriorities.length === 0}
                        >
                          Machine Priority
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/*<div style={{display: "flex", marginBottom: 10, alignItems: 'flex-end'}}>*/}
                  {/*  <Button*/}
                  {/*    style={{fontSize: 13, fontWeight: 500}}*/}
                  {/*    type={"primary"}*/}
                  {/*    disabled={isFetchingMachineTypes || isFetchingMachinePriorities || machinePriorities.length === 0}*/}
                  {/*    onClick={()=>{showCalculationModal()}}*/}
                  {/*  >*/}
                  {/*    Price Help*/}
                  {/*  </Button>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
            <Divider style={{width: "75%", minWidth: "0%", margin: "8px 0 20px 0"}}/>
            </Spin>
            <Spin spinning={fetchingAll || isFetchingMachines || isFetchingSelectedMachine}
                  indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                  style={{
                    position: "absolute",
                    width: "100%",
                    top: machineData.id ? "41px" : "36%",
                    left: machineData.id ? "-40px" : "-10px",
                  }}
            >
            {machineData.id &&
              <div className={windowSize.width > lineBreakLimit ? "inLine" : "newLIne"}>
                <div className={"customColumn3"}>
                  <div className={"column3CardContainer"}>
                    <Card style={{marginBottom: 19}} bodyStyle={{paddingTop: 10, paddingBottom: 10}}>
                      <Form.Item className={"materialFormItem"}>
                        <Space>
                          <Form.Item
                            label="Maximum cutting length"
                            name="maxCuttingLength"
                            style={{marginBottom: 12}}
                            initialValue={machineData.maxCuttingLength}
                          >
                            <InputNumber
                              style={{minWidth: 100}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changeCuttingMaxCuttingLength}
                            />
                          </Form.Item>
                          <Typography.Text className={"cuttingSpeedText"} style={{position: 'relative', top: -7}}>mm</Typography.Text>
                        </Space>
                      </Form.Item>
                      <Form.Item className={"materialFormItem"}>
                        <Space>
                          <Form.Item
                            className={"materialFormItem"}
                            label="Maximum cutting width"
                            name="maxCuttingWidth"
                            initialValue={machineData.maxCuttingWidth}
                          >
                            <InputNumber
                              style={{minWidth: 100, marginLeft: 5}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changeCuttingMaxCuttingWidth}
                            />
                          </Form.Item>
                          <Typography.Text className={"cuttingSpeedText"} style={{position: 'relative', top: -1}}>mm</Typography.Text>
                        </Space>
                      </Form.Item>
                    </Card>
                  </div>
                  <Tabs style={{maxWidth: 1220, marginLeft: -10, marginBottom: 20}}
                        type="card"
                        className={"laserSpeedTabs"}
                  >
                    {machineData.tables.map((item, index) => (
                      <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                        <MaterialTableContainer tableText={"Cutting speed by material Grade and material Thickness (m/min)"}
                                                xAxisText={"Available Thickness (mm)"}
                                                yAxisText={"Material Grade"}
                                                xAxisStyle={{marginTop: 12, marginBottom: -12}}
                        >
                          <ValueTable
                            columns={item.columns}
                            rows={item.rows}
                            allAvailable={item.allAvailable}
                            tabIndex={index}
                            onChange={changeSpeed}
                            updateTable={shouldUpdateSpeeds}
                            setUpdateTable={setShouldUpdateSpeeds}
                            activeLoadingTabs={{}}
                          />
                        </MaterialTableContainer>
                      </TabPane>
                    ))}
                  </Tabs>
                </div>
                <div className={"customColumn4"}>
                  <Card>
                    <Form.Item className={"materialFormItem"}>
                      <Space>
                        <Form.Item
                          className={"materialFormItem"}
                          label="Cutting price"
                          name="cuttingPrice"
                          initialValue={machineData.cuttingPrice}
                        >
                          <InputNumber
                            style={{minWidth: 100}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeCuttingCuttingPrice}
                          />
                        </Form.Item>
                        <Typography.Text className={"cuttingSpeedText"}>€/h</Typography.Text>
                      </Space>
                    </Form.Item>
                    <div align={'right'} style={{marginRight: 21}}>
                      <Form.Item className={"materialFormItem"}>
                        <Space>
                          <Form.Item
                            className={"materialFormItem"}
                            label="Average Positioning speed"
                            name="averagePositioningSpeed"
                            initialValue={machineData.averagePositioningSpeed}
                          >
                            <InputNumber
                              style={{minWidth: 100}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changeCuttingAveragePositioningSpeed}
                            />
                          </Form.Item>
                          <Typography.Text className={"cuttingSpeedText"}>m/min</Typography.Text>
                        </Space>
                      </Form.Item>
                    </div>
                    <Divider className={"laserDivider"}/>
                    <div style={{marginTop: 23}}>
                      <Form.Item className={"materialFormItem"}>
                        <Space>
                          <Form.Item
                            className={"materialFormItem"}
                            label="Preparation price"
                            name="preparationPrice"
                            initialValue={machineData.preparationPrice}
                          >
                            <InputNumber
                              style={{minWidth: 100}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changeCuttingPreparationPrice}
                            />
                          </Form.Item>
                          <Typography.Text className={"cuttingSpeedText"}>€/h</Typography.Text>
                        </Space>
                      </Form.Item>
                      <div align={'right'} style={{marginRight: 21}}>
                        <Form.Item className={"materialFormItem"}>
                          <Space>
                            <Form.Item
                              className={"materialFormItem"}
                              label={"Average Preparation time"}
                              name="averagePreparationTime"
                              initialValue={machineData.averagePreparationTime}
                            >
                              <InputNumber
                                style={{minWidth: 100}}
                                min={0}
                                step={1}
                                formatter={value => value ? value : 0}
                                parser={value => value ? value : 0}
                                onChange={changeCuttingAveragePreparationTime}
                              />
                            </Form.Item>
                            <Typography.Text className={"cuttingSpeedText"}>min</Typography.Text>
                          </Space>
                        </Form.Item>
                      </div>
                    </div>
                    <Divider className={"laserDivider"}/>
                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                      <div className={"cuttingSpeedText"}
                           style={{display: 'flex', alignItems: 'center', marginRight: 35, marginTop: -20}}>
                        Average Piercing time :
                      </div>
                      <div>
                        <Form.Item className={"materialFormItem"}>
                          <Space>
                            <Form.Item
                              style={{marginBottom: 5}}
                              name="pierceTimeLow"
                              initialValue={machineData.pierceTimeLow}
                            >
                              <InputNumber
                                style={{maxWidth: 70}}
                                min={0}
                                step={1}
                                formatter={value => value ? value : 0}
                                parser={value => value ? value : 0}
                                onChange={changeCuttingPierceTimeLow}
                              />
                            </Form.Item>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -5}}>sec</Typography.Text>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -4, marginLeft: 16}}>for
                              thickness</Typography.Text>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -3}}>≤</Typography.Text>
                          </Space>
                        </Form.Item>
                        <Form.Item className={"materialFormItem"}>
                          <Space>
                            <Form.Item
                              style={{marginBottom: 5}}
                              name="pierceTimeHigh"
                              initialValue={machineData.pierceTimeHigh}
                            >
                              <InputNumber
                                style={{maxWidth: 70}}
                                min={0}
                                step={1}
                                formatter={value => value ? value : 0}
                                parser={value => value ? value : 0}
                                onChange={changeCuttingPierceTimeHigh}
                              />
                            </Form.Item>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -5}}>sec</Typography.Text>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -4, marginLeft: 16}}>for
                              thickness</Typography.Text>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -3}}>></Typography.Text>
                          </Space>
                        </Form.Item>
                      </div>
                      <div style={{display: 'flex', alignItems: 'center', marginLeft: 15, marginTop: -5}}>
                        <Form.Item className={"materialFormItem"}>
                          <Space>
                            <Form.Item
                              className={"materialFormItem"}
                              name="pierceTimeThreshold"
                              initialValue={machineData.pierceTimeThreshold}
                            >
                              <InputNumber
                                style={{maxWidth: 70}}
                                min={0}
                                step={1}
                                formatter={value => value ? value : 0}
                                parser={value => value ? value : 0}
                                onChange={changeCuttingPierceTimeThreshold}
                              />
                            </Form.Item>
                            <Typography.Text className={"cuttingSpeedText"}>mm</Typography.Text>
                          </Space>
                        </Form.Item>
                      </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: 17}}>
                      <div className={"cuttingSpeedText"}
                           style={{display: 'flex', alignItems: 'center', marginRight: 15, marginTop: -20}}>
                        Average approach length :
                      </div>
                      <div>
                        <Form.Item className={"materialFormItem"}>
                          <Space>
                            <Form.Item
                              style={{marginBottom: 5}}
                              name="approachLengthLow"
                              initialValue={machineData.approachLengthLow}
                            >
                              <InputNumber
                                style={{maxWidth: 70}}
                                min={0}
                                step={1}
                                formatter={value => value ? value : 0}
                                parser={value => value ? value : 0}
                                onChange={changeCuttingApproachLengthLow}
                              />
                            </Form.Item>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -5}}>mm</Typography.Text>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -4, marginLeft: 10}}>for
                              thickness</Typography.Text>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -3}}>≤</Typography.Text>
                          </Space>
                        </Form.Item>
                        <Form.Item className={"materialFormItem"}>
                          <Space>
                            <Form.Item
                              style={{marginBottom: 5}}
                              name="approachLengthHigh"
                              initialValue={machineData.approachLengthHigh}
                            >
                              <InputNumber
                                style={{maxWidth: 70}}
                                min={0}
                                step={1}
                                formatter={value => value ? value : 0}
                                parser={value => value ? value : 0}
                                onChange={changeCuttingApproachLengthHigh}
                              />
                            </Form.Item>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -5}}>mm</Typography.Text>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -4, marginLeft: 10}}>for
                              thickness</Typography.Text>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -3}}>></Typography.Text>
                          </Space>
                        </Form.Item>
                      </div>
                      <div style={{display: 'flex', alignItems: 'center', marginLeft: 15, marginTop: -5}}>
                        <Form.Item className={"materialFormItem"}>
                          <Space>
                            <Form.Item
                              className={"materialFormItem"}
                              name="approachLengthThreshold"
                              initialValue={machineData.approachLengthThreshold}
                            >
                              <InputNumber
                                style={{maxWidth: 70}}
                                min={0}
                                step={1}
                                formatter={value => value ? value : 0}
                                parser={value => value ? value : 0}
                                onChange={changeCuttingApproachLengthThreshold}
                              />
                            </Form.Item>
                            <Typography.Text className={"cuttingSpeedText"}>mm</Typography.Text>
                          </Space>
                        </Form.Item>
                      </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: 17}}>
                      <div className={"cuttingSpeedText"}
                           style={{display: 'flex', alignItems: 'center', marginRight: 55, marginTop: -20}}>
                        Average exit length :
                      </div>
                      <div>
                        <Form.Item className={"materialFormItem"}>
                          <Space>
                            <Form.Item
                              style={{marginBottom: 5}}
                              name="exitLengthLow"
                              initialValue={machineData.exitLengthLow}
                            >
                              <InputNumber
                                style={{maxWidth: 70}}
                                min={0}
                                step={1}
                                formatter={value => value ? value : 0}
                                parser={value => value ? value : 0}
                                onChange={changeCuttingExitLengthLow}
                              />
                            </Form.Item>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -5}}>mm</Typography.Text>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -4, marginLeft: 10}}>for
                              thickness</Typography.Text>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -3}}>≤</Typography.Text>
                          </Space>
                        </Form.Item>
                        <Form.Item className={"materialFormItem"}>
                          <Space>
                            <Form.Item
                              style={{marginBottom: 5}}
                              name="exitLengthHigh"
                              initialValue={machineData.exitLengthHigh}
                            >
                              <InputNumber
                                style={{maxWidth: 70}}
                                min={0}
                                step={1}
                                formatter={value => value ? value : 0}
                                parser={value => value ? value : 0}
                                onChange={changeCuttingExitLengthHigh}
                              />
                            </Form.Item>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -5}}>mm</Typography.Text>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -4, marginLeft: 10}}>for
                              thickness</Typography.Text>
                            <Typography.Text className={"cuttingSpeedText"}
                                             style={{position: 'relative', top: -3}}>></Typography.Text>
                          </Space>
                        </Form.Item>
                      </div>
                      <div style={{display: 'flex', alignItems: 'center', marginLeft: 15, marginTop: -5}}>
                        <Form.Item className={"materialFormItem"}>
                          <Space>
                            <Form.Item
                              className={"materialFormItem"}
                              name="exitLengthThreshold"
                              initialValue={machineData.exitLengthThreshold}
                            >
                              <InputNumber
                                style={{maxWidth: 70}}
                                min={0}
                                step={1}
                                formatter={value => value ? value : 0}
                                parser={value => value ? value : 0}
                                onChange={changeCuttingExitLengthThreshold}
                              />
                            </Form.Item>
                            <Typography.Text className={"cuttingSpeedText"}>mm</Typography.Text>
                          </Space>
                        </Form.Item>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            }
            </Spin>
          </Form>
        </div>
      </Card>
      <Modal
        visible={addMachineModalVisible}
        title={"Add New Machine"}
        destroyOnClose={true}
        onCancel={() => {
          handleAddMachineClose()
        }}
        footer={<div>
          <div align={'right'}>
            <Button
              type={"primary"}
              style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
              form={"addMachine"}
              key={"submit"}
              htmlType={"submit"}
              loading={isCreatingCuttingMachine}
            >
              Add Machine
            </Button>
          </div>
        </div>}
      >
        <Form
          name={"addMachine"}
          validateTrigger={"onSubmit"}
          onFinish={createNewMachine}
        >
          <Form.Item
            name={"typeId"}
            rules={[{required: true, message: "Select machine type!"}]}
          >
            <Select
              placeholder={"Select machine type"}
              virtual={false}
              suffixIcon={<CaretDownOutlined/>}
            >
              {machineTypes.map(item => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"name"}
            rules={[
              {required: true, message: "Enter machine name!"},
              {type: "string", min: 3, message: "Name too short!"},
              {type: "string", max: 60, message: "Name too long!"}
            ]}
          >
            <Input size={"large"} placeholder={"Enter machine name"}/>
          </Form.Item>

        </Form>
      </Modal>

      <Modal
        visible={editMachineModalVisible}
        title={"Edit Machine"}
        destroyOnClose={true}
        onCancel={() => {
          handleEditMachineClose()
        }}
        footer={<div>
          <div align={'right'}>
            <Button
              type={"primary"}
              style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
              form={"editMachine"}
              key={"submit"}
              htmlType={"submit"}
              loading={isEditingCuttingMachine}
            >
              Edit Machine
            </Button>
          </div>
        </div>}
      >
        <Form
          name={"editMachine"}
          validateTrigger={"onSubmit"}
          onFinish={applyEditMachine}
        >
          <Form.Item
            name={"typeId"}
            rules={[{required: true, message: "Select machine type!"}]}
            initialValue={editMachine ? editMachine.typeId : null}
          >
            <Select
              placeholder={"Select machine type"}
              virtual={false}
              suffixIcon={<CaretDownOutlined/>}
            >
              {machineTypes.map(item => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"name"}
            rules={[{required: true, message: "Enter machine name!"}]}
            initialValue={editMachine ? editMachine.name : null}
          >
            <Input size={"large"} placeholder={"Enter machine name"}/>
          </Form.Item>

        </Form>
      </Modal>

      <Modal
        visible={machinePriorityModalVisible}
        title={"Machine Priority"}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => {
          handleMachinePriorityClose()
        }}
        footer={<div>
          <div align={'right'}>
            <Button
              type={"primary"}
              style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
              onClick={()=>{
                let priorities = machinePriority.map(item => item.id)
                dispatch(updateCuttingMachinePriorities(priorities)).then(()=>{
                  handleMachinePriorityClose()
                })
              }}
              loading={isUpdatingMachinePriorities}
            >
              Save
            </Button>
          </div>
        </div>}
      >
        <div align={"center"}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(droppableProvided, droppableSnapshot) => (
                <div
                  ref={droppableProvided.innerRef}
                  style={getListStyle(droppableSnapshot.isDraggingOver)}
                >
                  {machinePriority.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(draggableProvided, draggableSnapshot) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={getItemStyle(
                            draggableSnapshot.isDragging,
                            draggableProvided.draggableProps.style,
                          )}
                        >
                          <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div style={{display: "flex"}}>
                              <WaterJetIcon/>
                              <div
                                style={{fontSize: 12, fontWeight: 500, marginLeft: 10, marginTop: 8}}>{item.name}</div>
                            </div>

                            <div style={{marginTop: 24, marginBottom: 5}}>
                              <div style={{fontSize: 9, fontWeight: 500, color: "#878787"}}>Priority: {index + 1}</div>
                            </div>
                          </div>

                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Modal>

      <Modal
        visible={removeMachineModalVisible}
        closable={false}
        okText={"Remove"}
        cancelText={"Cancel"}
        maskClosable={false}
        width={416}
        bodyStyle={{padding: "26px 26px 13px 26px"}}
        okButtonProps={{type: "danger", style: {fontSize: 13, fontWeight: 500}, loading: isRemovingMachine}}
        cancelButtonProps={{style: {fontSize: 13, fontWeight: 500}, disabled: isRemovingMachine}}
        onOk={()=>{
          dispatch(deleteCuttingMachine({cuttingMachineId: removeMachineId})).then( machines => {
            if(machines.length === 0) {
              setSelectedMachine(null)
              dispatch(setEmptyCuttingMachineData())
            } else {
              if(removeMachineId === selectedMachine) {
                setSelectedMachine(machines[0].machines[0].id)
                dispatch(fetchCuttingMachineParameters({cuttingMachineId: machines[0].machines[0].id})).then(()=>{
                  setShouldUpdateSpeeds(true)
                  setFetchingAll(false)
                })
              }
            }
            setRemoveMachineId(null)
            setRemoveMachineModalVisible(false)
            dispatch(fetchCuttingMachinePriorities())
          })
        }}
        onCancel={() => {
          setRemoveMachineId(null)
          setRemoveMachineModalVisible(false)
        }}
      >
        <div className="ant-modal-confirm-body">
          <WarningOutlined style={{color: "red"}}/>
          <span className="ant-modal-confirm-title">Remove Machine</span>
          <div className="ant-modal-confirm-content">
            <span style={{fontSize: 13, fontWeight: 500}}>Are you sure you want to remove this machine. This action can't be reverted!</span>
          </div>
        </div>
      </Modal>

      <Modal
        title={"Cutting Price Calculator"}
        visible={isCalculationModalVisible}
        maskClosable={false}
        width={1100}
        footer={null}
        centered={true}
        destroyOnClose={true}
        onCancel={closeCalculationModal}
        bodyStyle={{paddingTop: 10, paddingBottom: 20}}
      >
        <div style={{fontSize: 12, fontWeight: 500}}>
          This is a quick calculator for getting the price per work-hour for your machine.
          Fill the fields that apply to your machine and get an estimated minimal price per hour.
        </div>
        <div style={{fontSize: 12, fontWeight: 500}}>
          Please note that this is only a suggested price for cutting based on parameters and is best used as a reference for getting a baseline cutting cost.
        </div>
        <Form>
          <Row gutter={15}>
            <Col span={12} style={{marginTop: 10}}>
              <Card
                className={"calculationCard"}
                style={{borderRadius: 5, border: "1px solid #bbbbbb"}}
                bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                title={<span style={{fontSize: 14, fontWeight: 600}}>Electricity</span>}
              >
                <Row gutter={15}>
                  <Col span={10}>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Energy Consumption (kW):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("energyConsumption", value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Electricity Cost (€/kWh):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("electricityCost", value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card
                className={"calculationCard"}
                style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15}}
                bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                title={<span style={{fontSize: 14, fontWeight: 600}}>Jets</span>}
              >
                <Row gutter={15}>
                  <Col span={10}>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Single Jet Lifespan (h):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 1}
                        parser={value => value ? value : 1}
                        onChange={(value)=>onCalculationFieldChange("singleJetLifespan", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Number of Jets:</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 1}
                        parser={value => value ? value : 1}
                        onChange={(value)=>onCalculationFieldChange("numOfActiveJets", value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Single Jet Cost (€):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("singleJetCost", value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              {selectedMachineType === "laser" &&
                <Card
                  className={"calculationCard"}
                  style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15}}
                  bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                  title={<span style={{fontSize: 14, fontWeight: 600}}>Laser</span>}
                >
                  <Row gutter={15}>
                    <Col span={10}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Working Gas Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("workingGasPrice", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Laser Gas Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={1}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("laserGasPrice", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Lens Lifespan (h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={1}
                          step={1}
                          formatter={value => value ? value : 1}
                          parser={value => value ? value : 1}
                          onChange={(value)=>onCalculationFieldChange("lensLifespan", value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Working Gas Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("workingGasConsumption", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Laser Gas Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("laserGasConsumption", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Lens Price (€):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("lensPrice", value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              }
              {selectedMachineType === "plasma" &&
                <Card
                  className={"calculationCard"}
                  style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15}}
                  bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                  title={<span style={{fontSize: 14, fontWeight: 600}}>Plasma</span>}
                >
                  <Row gutter={15}>
                    <Col span={10}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Cooling Gas Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("coolingGasPrice", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Plasma Gas Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("plasmaGasPrice", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Cathode Lifespan (h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 1}
                          parser={value => value ? value : 1}
                          onChange={(value)=>onCalculationFieldChange("cathodeLifespan", value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Cooling Gas Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("coolingGasConsumption", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Plasma Gas Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("plasmaGasConsumption", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Cathode Price (€):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("cathodePrice", value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              }
              {selectedMachineType === "gas" &&
                <Card
                  className={"calculationCard"}
                  style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15}}
                  bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                  title={<span style={{fontSize: 14, fontWeight: 600}}>Gas</span>}
                >
                  <Row gutter={15}>
                    <Col span={10}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Oxygen Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("oxygenPrice", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Cutting Gas Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("cuttingGasPrice", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Preheating Gas Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("preheatingGasPrice", value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Oxygen Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("oxygenConsumption", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Cutting Gas Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("cuttingGasConsumption", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Preheating Gas Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("preheatingGasConsumption", value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              }
              {selectedMachineType === "abrasive" &&
                <Card
                  className={"calculationCard"}
                  style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15}}
                  bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                  title={<span style={{fontSize: 14, fontWeight: 600}}>Water Jet</span>}
                >
                  <Row gutter={15}>
                    <Col span={10}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Abrasive Water Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("abrasivePrice", value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Abrasive Water Price (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("abrasiveConsumption", value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              }
            </Col>
            <Col span={12} style={{marginTop: 10}}>
              <Card
                className={"calculationCard"}
                style={{borderRadius: 5, border: "1px solid #bbbbbb"}}
                bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                title={<span style={{fontSize: 14, fontWeight: 600}}>Capital</span>}
              >
                <Row gutter={15}>
                  <Col span={10}>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Credit (€):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("credit", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Invested Capital (€):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("investedCapital", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Machine Lifespan (year):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("machineLifespan", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Rent (€/m²):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("rent", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: -2, fontSize: 12, fontWeight: 500}}>Yearly Hours Per Shift (h):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("yearlyHoursPerShift", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: -2, fontSize: 12, fontWeight: 500}}>Number Of Shifts:</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("numberOfShifts", value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Interest Rate (%/year):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("interestRate", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Maintenance Expense (%):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("maintenanceExpense", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Required Space (m²):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("requiredSpace", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Insurance (%):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("insurance", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: -2, fontSize: 12, fontWeight: 500}}>Time Efficiency (%):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("timeEfficiency", value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              {selectedMachineType !== "abrasive" &&
                <Card
                  className={"calculationCard"}
                  style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15, height: 115}}
                  bodyStyle={{padding: 15, borderRadius: 5, background: "rgba(238,238,238,0.4)", height: "calc(100% - 31px)"}}
                  title={<span style={{fontSize: 14, fontWeight: 600}}>Cutting Price Per Hour</span>}
                >
                  <div style={{width: "100%", height: 50, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 13, fontWeight: 500}}>Suggested price based on your parameters:</div>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 13, fontWeight: 500}}>{(suggestedPricePerHour || 0).toFixed(2)} €/h</div>
                  </div>

                </Card>
              }
            </Col>
          </Row>
          {selectedMachineType === "abrasive" &&
            <Card
              className={"calculationCard"}
              style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15, height: 81}}
              bodyStyle={{padding: 15, borderRadius: 5, background: "rgba(238,238,238,0.4)", height: 50}}
              title={<span style={{fontSize: 14, fontWeight: 600}}>Cutting Price Per Hour</span>}
            >
              <div style={{width: "100%", height: 20, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <div style={{marginBottom: 4, marginTop: 5, fontSize: 13, fontWeight: 500}}>Suggested price based on your parameters:</div>
                <div style={{marginBottom: 4, marginTop: 5, fontSize: 13, fontWeight: 500}}>{(suggestedPricePerHour || 0).toFixed(2)} €/h</div>
              </div>

            </Card>
          }
        </Form>
      </Modal>
    </React.Fragment>
  )
}

/*

let data = {
  energyConsumption: (kW),
  electricityCost: (€/kWh),

  credit: (€),
  interestRate: (%/year),
  investedCapital: (€),
  machineLifespan: (years),
  insurance: (%),
  requiredSpace: (m²),
  rent: (€/m²),
  maintenanceExpense: (%),
  yearlyHoursPerShift: (h),
  timeEfficiency: (%),
  numberOfShifts: (Number),

  singleJetLifespan: (h),
  singleJetCost: (€),
  numOfActiveJets: (Number),

  type: "laser",
  laser: {
    workingGasPrice: (€/m³),
    workingGasConsumption: (l/h),
    laserGasPrice: (€/m³),
    laserGasConsumption: (l/h),

    lensLifespan: (h),
    lensPrice: (€)
  },
  plasma: {
    coolingGasPrice: (€/m³),
    coolingGasConsumption: (l/h),
    plasmaGasPrice: (€/m³),
    plasmaGasConsumption: (l/h),

    cathodeLifespan: (h),
    cathodePrice: (€)
  },
  abrasive: {
    abrasivePrice: (€/l),
    abrasiveConsumption: (l/h),
  },
  gas: {
    oxygenConsumptionWhilePreheating: (l/h),
    oxygenConsumptionWhileCutting: (l/h),
    oxygenPrice: (€/m³),
    cuttingGasConsumption: (l/h),
    preheatingGasConsumption: (l/h)
  }
}

*/