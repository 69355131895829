import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {io} from "socket.io-client";
import {Layout, Spin, Tabs} from "antd";
import {forceLogoutUser, logoutUser} from "../../../actions/authActions";
import {LaserCuttingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/LaserCuttingTab";
import {MaterialBendingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/MaterialBendingTab";
import {SimpleMachiningTab} from "../../../components/company/CompanyMaterials/MaterialTabs/SimpleMachiningTab";
import {SurfaceCoatingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/SurfaceCoatingTab";
import {MaterialsTab} from "../../../components/company/CompanyMaterials/MaterialTabs/MaterialsTab";
import "./style.css"
import {CompanySidebar} from "../../../components/company";
import {LoadingOutlined} from "@ant-design/icons";
import storage from "../../../utils/storage";
import {SOCKET_PATH, SOCKET_URL} from "../../../constants";

const { TabPane } = Tabs;

var socket;

export const MaterialsAndMachinesPage = () => {
  const dispatch = useDispatch()

  const getInitialTab = () => {
    if(history.state?.state?.tab) {
      return history.state.state.tab
    }
    const query = new URLSearchParams(window.location.search);
    const tab = query.get("tab")

    if(tab) {
      switch(tab) {
        case "material":
          return "6"
        case "cutting":
          return "1"
        case "bending":
          return "2"
        case "machining":
          return "3"
        case "coating":
          return "4"
        default:
          return "1"
      }
    }

    return "1"
  }

  const [selectedTab, setSelectedTab] = useState(getInitialTab())

  const [socketConnected, setSocketConnected] = useState(false)

  const isFetchingBendingData = useSelector(state => state.bending.isFetchingBendingData)

  const isFetchingSimpleMachiningData = useSelector(state => state.simpleMachining.isFetchingSimpleMachiningData)

  const isFetchingSurfaceCoatingData = useSelector(state => state.surfaceCoating.isFetchingSurfaceCoatingData)

  const user = useSelector(state => state.auth.user)

  const isCollapsed = useSelector(state => state.util.isCollapsed);

  useEffect(() => {
    const accessToken = storage.retrieveAccessToken();

    socket = io(SOCKET_URL, {
      path: SOCKET_PATH,
      auth: {
        token: accessToken.substring(7)
      },
    });

    socket.on("connect", () => {
      setSocketConnected(true)
    });

    socket.on("connect_error", (err) => {
      if (err.message === "invalid credentials") {
        storage.refreshAccessToken().then(() => {
          const accessToken = storage.retrieveAccessToken();
          if(accessToken) {
            socket.auth.token = accessToken.substring(7);
            socket.connect();
          }
        })
      }
    });

    socket.on("server-error", (err) => {
      console.log(err.msg)
      if (err.msg === "invalid credentials") {
        dispatch(forceLogoutUser())
      }
    });

    // Destroys the socket reference
    // when the connection is closed
    return () => {
      socket.disconnect();
    };
  }, []);

  const addMaterialToStock = ({materialId, available}) => {
    if(socket) {
      socket.emit("put-producer-material-available", { materialId, available });
    }
  }

  const changeMaterialPrice = ({materialId, price}) => {
    if(socket) {
      socket.emit("put-producer-material-price", { materialId, price })
    }
  }

  const changeBendingParams = (data) => {
    if(socket) {
      socket.emit("put-producer-bending-params", { ...data })
    }
  }

  const changeDeburringParams = (data) => {
    if(socket) {
      socket.emit("put-deburring-params", { ...data })
    }
  }

  const changeChamferingParams = (data) => {
    if(socket) {
      socket.emit("put-chamfering-params", { ...data })
    }
  }

  const changeDrillingParams = (data) => {
    if(socket) {
      socket.emit("put-drilling-params", { ...data })
    }
  }

  const changeSimpleMachiningParams = (data) => {
    if(socket) {
      socket.emit("put-simple-machining-params", { ...data })
    }
  }

  const changePaintingParams = (data) => {
    if(socket) {
      socket.emit("put-painting-params", { ...data })
    }
  }

  const changePowderPaintingParams = (data) => {
    if(socket) {
      socket.emit("put-powder-painting-params", { ...data })
    }
  }

  const changeGalvanizingParams = (data) => {
    if(socket) {
      socket.emit("put-galvanizing-params", { ...data })
    }
  }

  const changeCuttingSpeed = (data) => {
    if(socket) {
      socket.emit("cutting-machine_put-cutting-speed", { ...data })
    }
  }

  const changeCuttingMachineParams = (data) => {
    if(socket) {
      socket.emit("cutting-machine_put-cutting-machine-one-field", { ...data })
    }
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}} type="flex">
        <div style={{paddingLeft: 15, paddingTop: 15, paddingRight: 15}}>
          <Tabs size={"small"} activeKey={selectedTab} onChange={(e)=>{setSelectedTab(e)}} type="card" className={"materialsTabs noSelect"}>
            <TabPane className={"materialsTabPane noSelect"} tab="Cutting Machines" key="1">
                <LaserCuttingTab
                  selectedTab={selectedTab}
                  changeCuttingSpeed={changeCuttingSpeed}
                  changeCuttingMachineParams={changeCuttingMachineParams}
                  socketConnected={socketConnected}
                />
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Bending" key="2">
              <Spin spinning={isFetchingBendingData || !socketConnected}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <MaterialBendingTab
                  changeBendingParams={changeBendingParams}
                  selectedTab={selectedTab}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Simple Machining" key="3">
              <Spin spinning={isFetchingSimpleMachiningData || !socketConnected}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <SimpleMachiningTab
                  isFetchingSimpleMachiningData={isFetchingSimpleMachiningData || !socketConnected}
                  selectedTab={selectedTab}
                  changeDeburringParams={changeDeburringParams}
                  changeChamferingParams={changeChamferingParams}
                  changeDrillingParams={changeDrillingParams}
                  changeSimpleMachiningParams={changeSimpleMachiningParams}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Surface Coating" key="4">
              <Spin spinning={isFetchingSurfaceCoatingData || !socketConnected}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <SurfaceCoatingTab
                  isFetchingSurfaceCoatingData={isFetchingSurfaceCoatingData}
                  changePaintingParams={changePaintingParams}
                  changePowderPaintingParams={changePowderPaintingParams}
                  changeGalvanizingParams={changeGalvanizingParams}
                  selectedTab={selectedTab}
                />
              </Spin>
            </TabPane>
            {/*<TabPane className={"materialsTabPane noSelect"} tab="Shipping" key="5">*/}
            {/*  <Card className={"tabMaterialCard"}>*/}
            {/*    <div style={{height: 'calc(100vh - 120px)'}}>*/}

            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</TabPane>*/}
            <TabPane className={"materialsTabPane noSelect"} tab="Material" key="6">
              <MaterialsTab
                addMaterialToStock={addMaterialToStock}
                changeMaterialPrice={changeMaterialPrice}
                selectedTab={selectedTab}
                socketConnected={socketConnected}
              />
            </TabPane>
            {/*<TabPane className={"materialsTabPane noSelect"} tab="Help" key="7">*/}
            {/*  <Card className={"tabMaterialCard"}>*/}
            {/*    <div style={{height: 'calc(100vh - 120px)'}}>*/}

            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</TabPane>*/}
          </Tabs>
        </div>
      </Layout.Content>
    </Layout>
  )
}