import initialState from "./initialState";
import {
  ORDER_SET_PART_COLOR,
  ORDER_REMOVE_PART_COLOR,
  ORDER_SET_PART_QUANTITY,
  ORDER_SET_PART_NOTE,
  ORDER_SET_PART_MATERIAL,
  ORDER_SET_PART_THICKNESS,
  ORDER_RESET_TO_PROCESS,
  ORDER_REMOVE_ITEM,
  ORDER_REMOVE_MULTIPLE_ITEMS,
  ORDER_APPLY_MULTIPLE_ITEMS,
  ORDER_SET_MANUAL_PART_TYPE,
  ORDER_SET_ORDER,
  ORDER_ADD_NEW_PART,
  ORDER_UPDATE_PART,
  ORDER_INCREMENT_TO_PROCESS,
  ORDER_INCREMENT_UPLOADED,
  ORDER_INCREMENT_PROCESSED,
  ORDER_SET_DRAFT_STAGE,
  ORDER_SET_SHIPPING_DATA,
  ORDER_RESET_ORDER,
  ORDER_UPDATE_PART_DATA,
  ORDER_UPDATE_PART_FILE_PATH,
  ORDER_CHANGE_PART_TO_ERROR,
  ORDER_UPDATE_ORDER_PRICE,
  ORDER_REMOVE_ERROR_PARTS,
  ORDER_SET_IS_CHANGING_DELIVERY_DATE,
  ORDER_SET_DELIVERY_DATE,
  ORDER_UPDATE_PART_PRICE,
  ORDER_SET_DRAFT_NAME, ORDER_SET_PART_DRAWING, ORDER_UPDATE_MANY_PART_PRICES, ORDER_SET_CHANGED_ADJUSTMENT
} from "../actions/actionTypes";
import {getAvailableThicknesses} from "../utils/material";

const orderReducer = (state = initialState.order, action) => {
  switch (action.type) {

    case ORDER_SET_ORDER:
      return {
        ...state,
        ...action.payload
      }

    case ORDER_UPDATE_ORDER_PRICE:
      return {
        ...state,
        ...action.payload
      }

    case ORDER_RESET_ORDER:
      return {
        toProcess: 0,
        currentlyUploaded: 0,
        currentlyProcessed: 0
      }

    case ORDER_SET_DELIVERY_DATE:
      return {
        ...state,
        deliveryDate: action.payload
      }

    case ORDER_ADD_NEW_PART:
      return {
        ...state,
        parts: [...state.parts, action.payload]
      }

    case ORDER_INCREMENT_TO_PROCESS:
      return {
        ...state,
        toProcess: state.toProcess + 1
      }

    case ORDER_INCREMENT_UPLOADED:
      return {
        ...state,
        currentlyUploaded: state.currentlyUploaded + 1
      }

    case ORDER_INCREMENT_PROCESSED:
      return {
        ...state,
        currentlyProcessed: state.currentlyProcessed + 1
      }

    case ORDER_UPDATE_PART:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.fileId === action.payload.fileId) {
            return {
              ...item,
              ...action.payload,
              availableThicknesses: item.availableThicknesses || []
            }
          }
          return item;
        })
      }

    case ORDER_UPDATE_PART_DATA:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            const tmp = {...action.payload.data}
            if(item?.stepData?.filePaths && tmp?.stepData?.filePaths) {
              tmp.stepData.filePaths.pngFilePath = tmp.stepData.filePaths.pngFilePath || item.stepData.filePaths.pngFilePath
              tmp.stepData.filePaths.foldedStlFilePath = tmp.stepData.filePaths.foldedStlFilePath || item.stepData.filePaths.foldedStlFilePath
              tmp.stepData.filePaths.unfoldedStlFilePath = tmp.stepData.filePaths.unfoldedStlFilePath || item.stepData.filePaths.unfoldedStlFilePath
            }
            if(!(tmp.fileType === "step" && tmp.processingType === "automatic")) {
              tmp.availableThicknesses =  getAvailableThicknesses(tmp.fileType, tmp.processingType, state.materials, tmp.selectedMaterial, tmp.materials)
            }
            return {
              ...item,
              ...tmp
            }
          }
          return item;
        })
      }

    case ORDER_CHANGE_PART_TO_ERROR:
      let newErrorList = [...state.errorList, action.payload]
      return {
        ...state,
        parts: state.parts.filter(item => item.id !== action.payload.id),
        errorList: newErrorList
      }

    case ORDER_REMOVE_ERROR_PARTS:
      return {
        ...state,
        errorList: []
      }

    case ORDER_UPDATE_PART_FILE_PATH:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            let tmp = {...item}
            if(!item?.stepData?.filePaths) {
              tmp.stepData = {filePaths: {}}
            }
            tmp.stepData.filePaths[action.payload.filePathName] = action.payload.filePathValue
            return tmp
          }
          return item;
        })
      }

    case ORDER_SET_PART_COLOR:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              coating: {
                hasCoating: true,
                colorRAL: action.payload.selectedRAL,
                colorGlossId: action.payload.selectedGloss,
                ralRow: action.payload.selectedRow,
                ralHex: action.payload.ralHex,
              }
            }
          }
          return item;
        })
      }

    case ORDER_REMOVE_PART_COLOR:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              coating: {
                hasCoating: false,
                colorRAL: undefined,
                colorGlossId: undefined,
                ralRow: undefined,
                ralHex: undefined
              }
            }
          }
          return item;
        })
      }

    case ORDER_SET_PART_QUANTITY:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              quantity: action.payload.quantity
            }
          }
          return item;
        })
      }

    case ORDER_SET_PART_NOTE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              note: action.payload.note
            }
          }
          return item;
        })
      }

    case ORDER_SET_PART_MATERIAL:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              selectedMaterial: {
                grade: action.payload.material.grade,
                materialId: action.payload.material.selectedMaterial.id,
                thickness: action.payload.material.selectedMaterial.thickness
              },
              availableThicknesses: [...action.payload.material.thickness]
            }
          }
          return item;
        })
      }

    case ORDER_SET_PART_THICKNESS:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              selectedMaterial: {
                ...item.selectedMaterial,
                materialId: action.payload.thickness.id,
                thickness: action.payload.thickness.thickness,
                isAvailable: true
              },
            }
          }
          return item;
        })
      }

    case ORDER_RESET_TO_PROCESS:
      return {
        ...state,
        toProcess: 0,
        currentlyUploaded: 0,
        currentlyProcessed: 0
      }

    case ORDER_REMOVE_ITEM:
      return {
        ...state,
        parts: state.parts.filter(item => item.id !== action.payload.id)
      }

    case ORDER_REMOVE_MULTIPLE_ITEMS:
      return {
        ...state,
        parts: state.parts.filter(item => !action.payload.selected[item.id])
      }

    case ORDER_APPLY_MULTIPLE_ITEMS:
      return {
        ...state,
        parts: state.parts.map(item=>{
          if(action.payload.applyingChanges[item.id]) {
            let changes = action.payload.applyingChanges[item.id]
            return {
              ...item,
              selectedMaterial: changes.material.materialId ? changes.material : item.selectedMaterial,
              availableThicknesses: changes.material.materialId ? changes.material.availableThicknesses : item.availableThicknesses,
              quantity: changes.quantity || item.quantity,
              coating: {
                hasCoating: changes.coating.hasCoating || item.coating.hasCoating,
                colorRAL: changes.coating.hasCoating ? changes.coating.colorRAL : item.coating.colorRAL,
                colorGlossId: changes.coating.hasCoating ? changes.coating.colorGlossId : item.coating.colorGlossId,
                ralRow: changes.coating.hasCoating ? changes.coating.ralRow : item.coating.ralRow,
                ralHex: changes.coating.hasCoating ? changes.coating.ralHex : item.coating.ralHex,
              }

            }
          }
          return item;
        })
      }

    case ORDER_SET_MANUAL_PART_TYPE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              partType: action.payload.partType
            }
          }
          return item;
        })
      }

    case ORDER_SET_DRAFT_STAGE:
      return {
        ...state,
        stage: action.payload
      }

    case ORDER_SET_SHIPPING_DATA:
      return {
        ...state,
        shippingData: {...action.payload}
      }

    case ORDER_UPDATE_PART_PRICE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            return {
              ...item,
              pricePerPiece: action.payload.pricePerPiece,
              totalPrice: action.payload.totalPrice
            }
          }
          return item;
        })
      }

    case ORDER_UPDATE_MANY_PART_PRICES:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (action.payload.parts[item.id]) {
            return {
              ...item,
              pricePerPiece: action.payload.parts[item.id].pricePerPiece,
              totalPrice: action.payload.parts[item.id].totalPrice
            }
          }
          return item;
        })
      }

    case ORDER_SET_DRAFT_NAME:
      return {
        ...state,
        customName: action.payload
      }

    case ORDER_SET_PART_DRAWING:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            return {
              ...item,
              drawingPDFFile: {...action.payload.data}
            }
          }
          return item;
        })
      }

    case ORDER_SET_CHANGED_ADJUSTMENT:
      return {
        ...state,
        adjustment: action.payload.adjustment || state.adjustment,
        oldAdjustment: action.payload.oldAdjustment || state.oldAdjustment,
      }

    default:
      return state
  }
}

export default orderReducer