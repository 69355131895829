import {
  SIMPLE_MACHINING_SET_DATA, SIMPLE_MACHINING_IS_FETCHING_DATA,
  SIMPLE_MACHINING_SET_SIMPLE_MACHINING_SERVICE, SIMPLE_MACHINING_SET_DEBURRING_SERVICE,
  SIMPLE_MACHINING_SET_CHAMFERING_SERVICE, SIMPLE_MACHINING_SET_DRILLING_SERVICE,
  SIMPLE_MACHINING_SET_DEBURRING_DATA, SIMPLE_MACHINING_SET_CHAMFERING_DATA,
  SIMPLE_MACHINING_SET_DRILLING_DATA, SIMPLE_MACHINING_SET_PREPARATION_DATA
} from '../actions/actionTypes'
import initialState from './initialState'

const simpleMachiningReducer = (state = initialState.simpleMachining, action) => {
  switch (action.type) {
    case SIMPLE_MACHINING_SET_DATA:
      return {
        ...state,
        simpleMachiningData: action.payload,
      }

    case SIMPLE_MACHINING_IS_FETCHING_DATA:
      return {
        ...state,
        isFetchingSimpleMachiningData: action.payload,
      }

    case SIMPLE_MACHINING_SET_SIMPLE_MACHINING_SERVICE:
      return {
        ...state,
        simpleMachiningData: {...state.simpleMachiningData, hasSimpleMachining: action.payload},
      }

    case SIMPLE_MACHINING_SET_DEBURRING_SERVICE:
      return {
        ...state,
        simpleMachiningData: {...state.simpleMachiningData, deburring: {...state.simpleMachiningData.deburring, hasDeburring: action.payload}},
      }

    case SIMPLE_MACHINING_SET_CHAMFERING_SERVICE:
      return {
        ...state,
        simpleMachiningData: {...state.simpleMachiningData, chamfering: {...state.simpleMachiningData.chamfering, hasChamfering: action.payload}},
      }

    case SIMPLE_MACHINING_SET_DRILLING_SERVICE:
      return {
        ...state,
        simpleMachiningData: {...state.simpleMachiningData, drilling: {...state.simpleMachiningData.drilling, hasDrilling: action.payload}},
      }

    case SIMPLE_MACHINING_SET_DEBURRING_DATA:
      return {
        ...state,
        simpleMachiningData: {...state.simpleMachiningData, deburring: {...state.simpleMachiningData.deburring, ...action.payload}},
      }

    case SIMPLE_MACHINING_SET_CHAMFERING_DATA:
      return {
        ...state,
        simpleMachiningData: {...state.simpleMachiningData, chamfering: {...state.simpleMachiningData.chamfering, ...action.payload}},
      }

    case SIMPLE_MACHINING_SET_DRILLING_DATA:
      return {
        ...state,
        simpleMachiningData: {...state.simpleMachiningData, drilling: {...state.simpleMachiningData.drilling, ...action.payload}},
      }

    case SIMPLE_MACHINING_SET_PREPARATION_DATA:
      return {
        ...state,
        simpleMachiningData: {...state.simpleMachiningData, ...action.payload},
      }
    default:
      return state
  }
}

export default simpleMachiningReducer
