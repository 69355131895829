import React from "react";
import {Button, Card, Col, Form, Row, Input, Select} from "antd";
import {
  GlobalOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined
} from "@ant-design/icons";
import {countries} from "../../../../data/countries";

export const CustomerForm = (props) => {
  const { isRegistering } = props;

  const handleClick = async (values) => {
    props.handleClick({
      email: values.registerCustomerEmail,
      name: values.registerCustomerName,
      phone: values.registerCustomerPhone,
      country: values.registerCustomerCountry,
      region: values.registerCustomerCountry,
      city: values.registerCustomerCity,
      address: values.registerCustomerAddress,
      postalCode: values.registerCustomerPostalCode,
    })
  }

  return (
    <Row>
      <Col span={24}>
        <Form
            name={'register-customer-regional'}
            onFinish={handleClick}
        >
          <Card
            size={"small"}
            bodyStyle={{paddingBottom: 10}}
          >
            <div align={"left"}>
              <Row gutter={10}>
                <Col span={12}>
                  <span className={"loginLabel"}>Email</span>
                  <Form.Item
                    name={'registerCustomerEmail'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'email', message: 'Please enter a valid email address'},
                    ]}
                    validateTrigger={'onSubmit'}

                  >
                    <Input
                      placeholder="Enter your email address"
                      prefix={<MailOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className={"loginLabel"}>Full Name</span>
                  <Form.Item
                    name={'registerCustomerName'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 2 ,message: 'Full name must be 2 characters or longer'},
                      {type: 'string', max: 80 ,message: 'Full name too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      placeholder="Enter your full name"
                      prefix={<UserOutlined />}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <span className={"loginLabel"}>Phone</span>
                  <Form.Item
                    name={'registerCustomerPhone'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 6 ,message: 'Contact number must be 6 digits or longer'},
                      {type: 'string', max: 60 ,message: 'Contact number too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      placeholder="Enter your phone number"
                      prefix={<PhoneOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className={"loginLabel"}>Country</span>
                  <Form.Item
                    name={'registerCustomerCountry'}
                    rules={[
                      {required: true, message: 'This field is required'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >

                    <Select
                      showSearch
                      style={{width: '100%'}}
                      prefix={<GlobalOutlined />}
                      placeholder="Select your country"
                      optionFilterProp="children"
                      notFoundContent={"Country not found"}
                      filterOption={(input, option) =>
                        option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      autoComplete="new-password"
                    >
                      {
                        countries.map(item => {
                          return (
                            <Select.Option key={item.name} value={item.name} className={"countrySelect"}>
                              <img src={item.flag} alt="" style={{position: "relative", top: -2, width: 18}}/> {item.name}
                            </Select.Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <span className={"loginLabel"}>City</span>
                  <Form.Item
                    name={'registerCustomerCity'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 2 ,message: 'City name must be 2 characters or longer'},
                      {type: 'string', max: 60 ,message: 'City name too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      placeholder="Enter your city"
                      prefix={<GlobalOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col  span={12}>
                  <span className={"loginLabel"}>Postal Code</span>
                  <Form.Item
                    name={'registerCustomerPostalCode'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 2 ,message: 'Postal code must be 2 digits or longer'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      prefix={<GlobalOutlined />}
                      placeholder="Enter your postal code"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <span className={"loginLabel"}>Address</span>
                  <Form.Item
                    name={'registerCustomerAddress'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 2 ,message: 'Address must be 2 characters or longer'},
                      {type: 'string', max: 60 ,message: 'Address too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      placeholder="Enter your address"
                      prefix={<HomeOutlined />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Card>
          <Form.Item>
            <div style={{display: "flex", width: '100%', justifyContent: "center"}}>
              <Button
                style={{width: '100%', marginTop: '10px', maxWidth: 500}}
                type="primary"
                htmlType="submit"
                className={"loginText"}
                loading={isRegistering}
              >
                Create Customer
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}