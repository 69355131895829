import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  Table, Layout, Row, Col,
  Button, Modal, Form,
  InputNumber, Divider,
  Input, Dropdown, Menu, Tag,
  Radio, Card, Select
} from 'antd'

import {CompanySidebar} from '../../../components/company'
import {logoutUser} from '../../../actions/authActions'
import {
  fetchExternalRelations,
  addNewRegularCustomer,
  addNewCorporateCustomer,
  deleteExternalCustomer,
  fetchCustomerDetails2,
  updateRegularCustomer,
  updateCorporateCustomer,
  changeExternalCustomerAdjustment
} from '../../../actions/customerRelations'
import {
  BankOutlined,
  CiCircleOutlined,
  DeleteOutlined, GlobalOutlined, HomeOutlined,
  LoadingOutlined,
  MoreOutlined, PercentageOutlined,
  PhoneOutlined,
  UserOutlined
} from "@ant-design/icons";
import {getColumnSearchProps} from "../../../utils/utility";
import "./style.css";
import {CustomerForm} from "./Components/CustomerRegistration";
import {CompanyForm} from "./Components/CompanyRegistration"
import {countries} from "../../../data/countries";


const RegisterWrapper = (props) => {
  const {selected, isRegistering, handleClick} = props;
  return (
    <div style={{transition: "width 0.1s linear", width: selected === 'customer' ? 680 : 750}}>
      {selected === 'customer' ?
        (
          <CustomerForm
            handleClick={handleClick}
            isRegistering={isRegistering}
          />
        )
        :
        (
          <CompanyForm
            handleClick={handleClick}
            isRegistering={isRegistering}
          />
        )
      }
    </div>
  );
}

const UpdateWrapper = (props) => {
  const [hasChanges, setHasChanges] = useState(false);
  const isUpdatingRegularCustomer = useSelector(state => state.customerRelations.updatingRegularCustomer);
  const isUpdatingCorporateCustomer = useSelector(state => state.customerRelations.updatingCorporateCustomer);

  const { customer, onUpdate } = props;
  if (customer.role === "regularCustomer") {
    return (
      <Row>
        <Col span={24}>
          <Form
            name={'update-customer-regular'}
            onFinish={(data) => onUpdate(data, customer)}
          >
            <Card
              size={"small"}
              bodyStyle={{paddingBottom: 10}}
            >
              <div align={"left"}>
                <Row gutter={10}>
                  <Col span={12}>
                    <span className={"loginLabel"}>Full Name</span>
                    <Form.Item
                      name={'name'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 2 ,message: 'Full name must be 2 characters or longer'},
                        {type: 'string', max: 80 ,message: 'Full name too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.name}
                    >
                      <Input
                        placeholder="Enter your full name"
                        prefix={<UserOutlined />}
                        onChange={() => {setHasChanges(true)}}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className={"loginLabel"}>Phone</span>
                    <Form.Item
                      name={'phone'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 6 ,message: 'Contact number must be 6 digits or longer'},
                        {type: 'string', max: 60 ,message: 'Contact number too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.phone}
                    >
                      <Input
                        placeholder="Enter your phone number"
                        prefix={<PhoneOutlined />}
                        onChange={() => {setHasChanges(true)}}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={12}>
                    <span className={"loginLabel"}>Country</span>
                    <Form.Item
                      name={'country'}
                      rules={[
                        {required: true, message: 'This field is required'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.country}
                    >

                      <Select
                        showSearch
                        style={{width: '100%'}}
                        prefix={<GlobalOutlined />}
                        placeholder="Select your country"
                        optionFilterProp="children"
                        notFoundContent={"Country not found"}
                        filterOption={(input, option) =>
                          option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        autoComplete="new-password"
                        onChange={() => {setHasChanges(true)}}
                      >
                        {
                          countries.map(item => {
                            return (
                              <Select.Option key={item.name} value={item.name} className={"countrySelect"}>
                                <img src={item.flag} alt="" style={{position: "relative", top: -2, width: 18}}/> {item.name}
                              </Select.Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className={"loginLabel"}>City</span>
                    <Form.Item
                      name={'city'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 2 ,message: 'City name must be 2 characters or longer'},
                        {type: 'string', max: 60 ,message: 'City name too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.city}
                    >
                      <Input
                        placeholder="Enter your city"
                        prefix={<GlobalOutlined />}
                        onChange={() => {setHasChanges(true)}}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col  span={12}>
                    <span className={"loginLabel"}>Postal Code</span>
                    <Form.Item
                      name={'postalCode'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 2 ,message: 'Postal code must be 2 digits or longer'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.postalCode}
                    >
                      <Input
                        prefix={<GlobalOutlined />}
                        placeholder="Enter your postal code"
                        onChange={() => {setHasChanges(true)}}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className={"loginLabel"}>Address</span>
                    <Form.Item
                      name={'address'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 2 ,message: 'Address must be 2 characters or longer'},
                        {type: 'string', max: 60 ,message: 'Address too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.address}
                    >
                      <Input
                        placeholder="Enter your address"
                        prefix={<HomeOutlined />}
                        onChange={() => {setHasChanges(true)}}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Card>
            <Form.Item>
              <div style={{display: "flex", width: '100%', justifyContent: "center"}}>
                <Button
                  style={{width: '100%', marginTop: '10px', maxWidth: 500}}
                  type="primary"
                  htmlType="submit"
                  className={"loginText"}
                  disabled={!hasChanges}
                  loading={isUpdatingRegularCustomer}
                >
                  Update Customer
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
  else if (customer.role === "corporateCustomer") {
    return (
      <Row>
        <Col span={24}>
          <Form
            name={'update-customer-corporate'}
            onFinish={(data) => onUpdate(data, customer)}
          >
            <Card
              size={"small"}
              bodyStyle={{paddingBottom: 10}}
            >
              <div align={"left"}>
                <Row gutter={18}>
                  <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                    <span className={"loginLabel"}>Country</span>
                    <Form.Item
                      name={'country'}
                      rules={[
                        {required: true, message: 'This field is required'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.country}
                    >

                      <Select
                        showSearch
                        style={{width: '100%'}}
                        prefix={<GlobalOutlined />}
                        placeholder="Select your country"
                        optionFilterProp="children"
                        notFoundContent={"Country not found"}
                        filterOption={(input, option) =>
                          option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        autoComplete="new-password"
                        onChange={() => {setHasChanges(true)}}
                      >
                        {
                          countries.map(item => {
                            return (
                              <Select.Option key={item.name} value={item.name} className={"countrySelect"}>
                                <img src={item.flag} alt="" style={{position: "relative", top: -2, width: 18}}/> {item.name}
                              </Select.Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                    <span className={"loginLabel"}>Company ID</span>
                    <Form.Item
                      name={"uid"}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 3 ,message: 'Too short!'},
                        {type: 'string', max: 60 ,message: 'Too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.uid}
                    >
                      <Input
                        placeholder="Enter your company id number"
                        prefix={<CiCircleOutlined />}
                        onChange={() => {setHasChanges(true)}}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <span className={"loginLabel"}>Company Name</span>
                    <Form.Item
                      name={'name'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 2 ,message: 'Too short!'},
                        {type: 'string', max: 60 ,message: 'Too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.name}
                    >
                      <Input
                        placeholder="Enter your company name"
                        prefix={<BankOutlined />}
                        onChange={() => {setHasChanges(true)}}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={18}>
                  <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                    <span className={"loginLabel"}>Region</span>
                    <Form.Item
                      name={'region'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 2 ,message: 'City must be 2 characters or longer'},
                        {type: 'string', max: 60 ,message: 'City name too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.region}
                    >
                      <Input
                        placeholder="Enter your region"
                        prefix={<GlobalOutlined />}
                        onChange={() => {setHasChanges(true)}}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                    <span className={"loginLabel"}>VAT</span>
                    <Form.Item
                      name={'vat'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 3 ,message: 'Too short!'},
                        {type: 'string', max: 60 ,message: 'Too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.vat}
                    >
                      <Input
                        placeholder="Enter your VAT number"
                        prefix={<PercentageOutlined />}
                        onChange={() => {setHasChanges(true)}}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <div style={{height: 74}}>
                      <span className={"loginLabel"}>Address</span>
                      <Form.Item
                        name={'address'}
                        rules={[
                          {required: true, message: 'This field is required'},
                          {type: 'string', min: 2 ,message: 'Too short!'},
                          {type: 'string', max: 60 ,message: 'Too long!'},
                        ]}
                        validateTrigger={'onSubmit'}
                        initialValue={customer.address}
                      >
                        <Input
                          placeholder="Enter your address"
                          prefix={<HomeOutlined />}
                          onChange={() => {setHasChanges(true)}}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row gutter={18}>
                  <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                    <span className={"loginLabel"}>City</span>
                    <Form.Item
                      name={'city'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 2 ,message: 'Too short!'},
                        {type: 'string', max: 60 ,message: 'Too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.city}
                    >
                      <Input
                        placeholder="Enter your city"
                        prefix={<GlobalOutlined />}
                        onChange={() => {setHasChanges(true)}}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                    <span className={"loginLabel"}>Contact Name</span>
                    <Form.Item
                      name={'contactName'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 2 ,message: 'Too short!'},
                        {type: 'string', max: 60 ,message: 'Too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.name}
                    >
                      <Input
                        placeholder="Enter your contact first name"
                        prefix={<UserOutlined />}
                        onChange={() => {setHasChanges(true)}}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={18}>
                  <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                    <div style={{height: 74}}>
                      <span className={"loginLabel"}>Postal Code</span>
                      <Form.Item
                        name={'postalCode'}
                        rules={[
                          {required: true, message: 'This field is required'},
                          {type: 'string', min: 3 ,message: 'Too short!'},
                          {type: 'string', max: 60 ,message: 'Too long!'},
                        ]}
                        validateTrigger={'onSubmit'}
                        initialValue={customer.postalCode}
                      >
                        <Input
                          prefix={<GlobalOutlined />}
                          placeholder="Enter your postal code"
                          onChange={() => {setHasChanges(true)}}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                    <span className={"loginLabel"}>Phone</span>
                    <Form.Item
                      name={'phone'}
                      rules={[
                        {required: true, message: 'This field is required'},
                        {type: 'string', min: 6 ,message: 'Too short!'},
                        {type: 'string', max: 60 ,message: 'Too long!'},
                      ]}
                      validateTrigger={'onSubmit'}
                      initialValue={customer.phone}
                    >
                      <Input
                        placeholder="Enter your contact phone number"
                        prefix={<PhoneOutlined />}
                        onChange={() => {setHasChanges(true)}}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Card>
            <Form.Item>
              <div style={{display: "flex", width: '100%', justifyContent: "center"}}>
                <Button
                  style={{width: '100%', marginTop: '10px', maxWidth: 500}}
                  type="primary"
                  htmlType="submit"
                  className={"loginText"}
                  loading={isUpdatingCorporateCustomer}
                  disabled={!hasChanges}
                >
                  Update Customer
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}


export const ExternalCustomerRelations = () => {
  let searchInput = null;
  const [isCreateVisible, setIsCreateVisible] = useState(false);
  const [isUpdateVisible, setIsUpdateVisible] = useState(false);
  const [isAdjustmentVisible, setIsAdjustmentVisible] = useState(false);
  const [details, setDetails] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const [selected, setSelected] = useState("customer");
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);

  const [filteredInfo, setFilteredInfo] = useState({})

  const user = useSelector(state => state.auth.user);
  const relations = useSelector(state => state.customerRelations.externalCustomerRelations);
  const isFetchingCustomerRelations = useSelector(state => state.customerRelations.fetchingExternalCustomerRelations);
  const pagination = useSelector(state => state.customerRelations.externalCustomerRelationsPagination);
  const isUpdatingCustomer = useSelector(state => state.customerRelations.updatingExternalCustomer);
  const isCollapsed = useSelector(state => state.util.isCollapsed);

  const dispatch = useDispatch();

  const getPageSize = () => {
    const count = Math.floor((window.innerHeight - 150) / 50)
    return count > 10 ? count : 10;
  }

  const pageSize = getPageSize();

  const initializeData = () => {
    const email = filteredInfo.email ? filteredInfo.email[0] : ""
    const name = filteredInfo.name ? filteredInfo.name[0] : ""

    dispatch(fetchExternalRelations({
      email: email,
      name: name,
      page: pagination.current,
      pagination: pagination
    }))
  }

  useEffect(() => {
    dispatch(fetchExternalRelations({
      email: "",
      name: "",
      page: 1,
      pagination: {...pagination, pageSize: pageSize}
    }))
  }, [])

  const handleCreateClose = () => {
    setIsCreateVisible(false);
    setSelected("customer");
  }

  const handleUpdateClose = () => {
    setIsUpdateVisible(false);
    setSelectedCustomer(null);
  }

  const handleUpdateCustomer = (customerId) => {
    dispatch(fetchCustomerDetails2({id: customerId}))
    .then(res => {
      setSelectedCustomer({...res})
      setIsUpdateVisible(true);
    }).catch(()=>{})
  }

  const onUpdate = (data, customer) => {
    const newData = {...data, region: customer.region}
    if (customer.role === "regularCustomer") {
      dispatch(updateRegularCustomer({id: customer.id, data: newData}))
        .then(()=>{
          setIsUpdateVisible(false)
          initializeData()
        })
        .catch(() => {});
    } else if (customer.role === "corporateCustomer") {
      dispatch(updateCorporateCustomer({id: customer.id, data: newData}))
        .then(()=>{
          setIsUpdateVisible(false)
          initializeData()
        })
        .catch(() => {});
    }
  }

  const handleTableChange = (pagination, filters) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    const email = filters.email ? filters.email[0] : ""
    const name = filters.name ? filters.name[0] : ""

    setFilteredInfo(filters)

    dispatch(fetchExternalRelations({
      email: email,
      name: name,
      page: pagination.current,
      pagination: pager
    }))
  }

  const handleDeleteCustomer = (customerId, e) => {
    dispatch(deleteExternalCustomer({customerId})).then(()=>{
      e()
      initializeData()
    }).catch(()=>{
      e()
    })
  }

  const onDeleteCustomer = (customerId) => {
    let modal = Modal.confirm({
      className: "inputInfoText",
      visible: true,
      style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
      centered: true,
      onOk: (e) => {
        modal.update(prevConfig => ({
          ...prevConfig,
          cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
          okButtonProps: {...prevConfig.okButtonProps, loading: true}
        }));
        handleDeleteCustomer(customerId, e)
      },
      confirmLoading: true,
      destroyOnClose: true,
      width: 400,
      title: <span>{"Delete Customer"}</span>,
      content: <div>
        <div>Are you sure you want to DELETE this Customer?</div>
      </div>,
      okText: <span style={{fontSize: 13, fontWeight: 500}}>Delete</span>,
      okButtonProps: {type: "danger", loading: false},
      cancelText: <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
      icon: <DeleteOutlined style={{color: "red"}}/>,
      zIndex: 2,
    })
  }

  const handleSelectedChange = e => {
    setSelected(e.target.value)
  }

  const handleCreateRegularCustomer = (data) => {
    setIsCreatingCustomer(true)
    dispatch(addNewRegularCustomer(data)).then(()=>{
      setIsCreatingCustomer(false)
      handleCreateClose()
      initializeData()
    }).catch(()=>{
      setIsCreatingCustomer(false)
    })
  }

  const handleCreateCorporateCustomer = (data) => {
    setIsCreatingCustomer(true)
    dispatch(addNewCorporateCustomer(data)).then(()=>{
      setIsCreatingCustomer(false)
      handleCreateClose()
      initializeData()
    }).catch(()=>{
      setIsCreatingCustomer(false)
    })
  }

  const handleAdjustmentClose = () => {
    setIsAdjustmentVisible(false);
    setDetails(null);
    setHasChanges(false);
  }

  const handleAdjustmentUpdate = (values) => {
    const newAdjustment = values.adjustment / 100
    if(newAdjustment !== details.adjustment) {
      dispatch(changeExternalCustomerAdjustment({customerId: details.id, adjustment: newAdjustment})).then(()=>{
        handleAdjustmentClose()
        initializeData()
      }).catch(()=>{})
    }
    else {
      handleAdjustmentClose()
    }
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={() => {dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s", paddingLeft: 25, paddingRight: 25, paddingTop: 15}}>
        <div style={{width: "100%"}}>
          <div style={{ height: 32, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{fontWeight: 500, fontSize: 16}}>These are your {user?.producer?.hasOrderFunction && "external"} customer relations:</div>
            <Button
              type={"primary"}
              onClick={()=>{
                setIsCreateVisible(true)
              }}
            >
              <span style={{fontWeight: 500, fontSize: 13}}>Add New Customer</span>
            </Button>
          </div>
          <Divider style={{marginTop: 12, marginBottom: 14}}/>
        </div>
        <Row type="flex">
          <Col span={24}>
            <React.Fragment>
              <Table
                columns={[
                  {
                    title: 'Name',
                    key: 'name',
                    width: "30%",
                    render: row => (
                      <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#bdbdbd": "rgba(0, 0, 0, 0.85)"}}>
                        {row.name}
                      </div>
                    ),
                    ...getColumnSearchProps('name', searchInput, "name"),
                  },
                  {
                    title: 'Email',
                    className: "darkerColumn",
                    key: 'email',
                    width: "30%",
                    render: row => (
                      <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#bdbdbd": "rgba(0, 0, 0, 0.85)"}}>
                        {row.email}
                      </div>
                    ),
                    ...getColumnSearchProps('email', searchInput, "email"),
                  },
                  {
                    title: 'Adjustment',
                    key: 'discount',
                    align: "right",
                    width: "17%",
                    render: row => (
                      <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#bdbdbd": "rgba(0, 0, 0, 0.85)"}}>
                        {Number(row.adjustment * 100).toFixed(2)}%
                      </div>
                    ),
                  },
                  {
                    title: 'Customer Type',
                    className: "darkerColumn column200",
                    key: 'status',
                    width: "200px",
                    align: "center",
                    render: row => (
                      <div>
                        {row.role === "regularCustomer" ?
                          <Tag color="#1c84e0" style={{fontWeight: 600, textAlign: "center", width: 150, marginRight: 0}}>Regular Customer</Tag>
                          :
                          <Tag color="#9966cc" style={{fontWeight: 600, textAlign: "center", width: 150, marginRight: 0}}>Corporate Customer</Tag>
                        }
                      </div>
                    ),
                  },
                  {
                    title: '',
                    key: 'edit',
                    width: "20px",
                    align: "right",
                    render: (item) => (
                      <Dropdown
                        overlay={
                          <Menu className={'employeeEditMenu'}>
                            <Menu.Item
                              key='1'
                              onClick={()=>{
                                handleUpdateCustomer(item.id)
                              }}
                            >
                              <span style={{fontSize: 13, fontWeight: 600}}>
                                Update Customer
                              </span>
                            </Menu.Item>
                            <Menu.Item
                              key='2'
                              onClick={()=>{
                                setIsAdjustmentVisible(true);
                                setDetails(item);
                                setHasChanges(false);
                              }}
                            >
                              <span style={{fontSize: 13, fontWeight: 600}}>
                                Edit Adjustment
                              </span>
                            </Menu.Item>
                            <Menu.Item  className={"deleteMenuItem"} key='3' onClick={()=>{onDeleteCustomer(item.id)}}>
                              <span style={{fontSize: 13, fontWeight: 600}}>
                                Delete Customer
                              </span>
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <MoreOutlined style={{fontSize: 20, fontWeight: 'bold'}} />
                      </Dropdown>
                    ),
                  },
                ]}
                className={"pendingOrdersTable"}
                dataSource={relations}
                rowKey="id"
                pagination={pagination}
                loading={{
                  spinning: isFetchingCustomerRelations,
                  indicator: <LoadingOutlined style={{fontSize: 50, marginLeft: "-25px"}} spin/>
                }}
                onChange={handleTableChange}
                size={"small"}
                scroll={{x: '70%'}}
              />
            </React.Fragment>
          </Col>
        </Row>

        <Modal
          onCancel={handleCreateClose}
          title="Create New Customer"
          visible={isCreateVisible}
          footer={null}
          centered
          bodyStyle={{
            padding: 12,
          }}
          maskClosable={false}
          destroyOnClose
          width={800}
        >
          <div align={'center'} style={{marginTop: 10, marginBottom: 10}}>
            <Radio.Group
              value={selected}
              buttonStyle="solid"
              onChange={handleSelectedChange}
              className={"loginText"}
            >
              <Radio.Button value="customer">Regular</Radio.Button>
              <Radio.Button value="company">Corporate</Radio.Button>
            </Radio.Group>
          </div>
          <div align={'center'}>
            <RegisterWrapper
              selected={selected}
              isRegistering={isCreatingCustomer}
              handleClick={selected === "customer" ? handleCreateRegularCustomer : handleCreateCorporateCustomer}
            />
          </div>
        </Modal>

        <Modal
          onCancel={handleUpdateClose}
          title={selectedCustomer && (selectedCustomer.role === "regularCustomer" ? "Update customer" : "Update Corporate Customer")}
          visible={isUpdateVisible}
          footer={null}
          centered
          bodyStyle={{
            padding: 12,
          }}
          maskClosable={false}
          destroyOnClose={true}
          width={selectedCustomer && (selectedCustomer.role === "regularCustomer" ? 600 : 800)}
        >
          <div align={'center'}>
            <UpdateWrapper customer={selectedCustomer}
                           onUpdate={onUpdate}
            />
          </div>
        </Modal>
        <Modal
          onCancel={handleAdjustmentClose}
          title="Update Customer Adjustment"
          visible={isAdjustmentVisible}
          footer={null}
          centered
          bodyStyle={{
            padding: 12,
          }}
          maskClosable={false}
          destroyOnClose
        >
          {details && (
            <React.Fragment>
              <Form
                className={"customerUpdateForm"}
                onFinish={handleAdjustmentUpdate}
              >
                <div className={"infoBox"}>
                  <div className={"detailEntry"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 12, width: 195, whiteSpace: "nowrap" }}
                    >
                      Customer Name:
                    </div>
                    <Input
                      defaultValue={details.name}
                      disabled
                    />
                  </div>

                  <div className={"detailEntry"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 12, width: 195, whiteSpace: "nowrap" }}
                    >
                      Email:
                    </div>
                    <Input
                      defaultValue={details.email}
                      disabled
                    />
                  </div>

                  <div className={"inlineField"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 10, width: 130, whiteSpace: "nowrap" }}
                    >
                      Adjustment:
                    </div>
                    <div className={"customerUpdateFormItemContainer"}>
                      <Form.Item
                        name={"adjustment"}
                        initialValue={details.adjustment * 100}
                      >
                        <InputNumber
                          formatter={(value, info) => info.userTyping ? `${value}` : `${Number(value).toFixed(2)}%`}
                          parser={value => value ? value.replace('%', '') : 0}
                          onChange={() => {
                            setHasChanges(true);
                          }}
                          min={-100}
                          max={100000}
                          style={{width: 120}}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className={"updateButton"} align={"right"} style={{ width: "100%" }}>
                    <Form.Item>
                      <Button type={"primary"}
                              htmlType={"submit"}
                              style={{ fontWeight: 600, fontSize: 13, marginTop: 12 }}
                              disabled={!hasChanges}
                              loading={isUpdatingCustomer}
                      >
                        Update Customer
                      </Button>
                    </Form.Item>
                  </div>

                </div>
              </Form>
            </React.Fragment>
          )}
        </Modal>
      </Layout.Content>
    </Layout>
  )
}
