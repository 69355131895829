import React, {Fragment, useEffect} from 'react'
import { useDispatch, useSelector} from 'react-redux'
import {Table, Layout, Row, Col, Button, Tag, Input, Divider} from 'antd'
import { CompanySidebar } from '../../../components/company'
import { logoutUser } from '../../../actions/authActions'
import {LoadingOutlined} from "@ant-design/icons";
import {formatTableDate, getColumnSearchProps} from "../../../utils/utility";
import {
  fetchProducerArchivedOrders
} from "../../../actions/ordersActions";
import history from "../../../utils/history";

export const ArchivePage = (props) => {
  let searchInput = null;

  const user = useSelector(state => state.auth.user)
  const isFetchingArchivedOrders = useSelector(state => state.orders.isFetchingProducerArchivedOrders)
  const archivedOrders = useSelector(state => state.orders.producerArchivedOrders)
  const pagination = useSelector(state => state.orders.producerArchivedOrdersPagination)
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const dispatch = useDispatch()

  const getPageSize = () => {
    const count = Math.floor((window.innerHeight - 150) / 50)
    return count > 10 ? count : 10;
  }

  const pageSize = getPageSize();

  useEffect(() => {
    dispatch(fetchProducerArchivedOrders({
      sortField: "completionDate",
      sortDirection: "desc",
      orderName: "",
      customerName: "",
      state: "",
      page: pagination.current !== undefined ? pagination.current : 1,
      pagination: {...pagination, pageSize: pageSize}
    }))
  }, [])

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    const sortField = sorter.order ? sorter.columnKey : "completionDate"
    const sortDirection = sorter.order ? (sorter.order === "ascend" ? "asc" : "desc") : "desc"
    const orderName = filters.orderName ? filters.orderName[0] : ""
    const customerName = filters.customerName ? filters.customerName[0] : ""
    const state = filters.state && filters.state.length === 1 ? filters.state[0] : ""

    dispatch(fetchProducerArchivedOrders({
      sortField: sortField,
      sortDirection: sortDirection,
      orderName: orderName,
      customerName: customerName,
      page: pagination.current,
      pagination: pager,
      state: state
    }))
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
          <Row type="flex">
            <Col span={24}>
              <div style={{ height: 32, display: "flex", alignItems: "center" }}>
                <div style={{fontWeight: 500, fontSize: 16}}>These are your completed and declined orders:</div>
              </div>
              <Divider style={{marginTop: 6, marginBottom: 11}}/>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              <Fragment>
                <Table
                  columns={[
                    {
                      title: 'Order Code',
                      key: 'orderName',
                      width: "12%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {row.orderName}
                        </div>
                      ),
                      ...getColumnSearchProps('orderName', searchInput,"code"),
                    },
                    {
                      title: 'Order date',
                      key: 'orderDate',
                      className: "darkerColumn",
                      width: "12%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {formatTableDate(row.orderDate)}
                        </div>
                      ),
                      sorter: true,
                    },
                    {
                      title: 'Customer',
                      key: 'customerName',
                      width: "19%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {row.customerName}
                        </div>
                      ),
                      ...getColumnSearchProps('customerName', searchInput,"customer"),
                    },
                    {
                      title: 'Delivery date',
                      key: 'deliveryDate',
                      className: "darkerColumn",
                      width: "12%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {formatTableDate(row.deliveryDate)}
                        </div>
                      ),
                      sorter: true,
                    },
                    {
                      title: 'Total with VAT',
                      key: 'totalWithVat',
                      width: "14%",
                      align: "right",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {(row.totalPrice * (1 + row.vatValue) || row.totalPrice || 0).toFixed(2)}€
                        </div>
                      ),
                    },
                    {
                      title: 'Completed',
                      key: 'completionDate',
                      className: "darkerColumn",
                      width: "12%",
                      align: "right",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {formatTableDate(row.completionDate)}
                        </div>
                      ),
                      sorter: true,
                    },
                    {
                      title: 'Status',
                      key: 'state',
                      width: "12%",
                      align: "center",
                      render: row => (
                        <div>
                          <React.Fragment>
                            {row.state === "declined" ?
                              <Tag color="#b60a1c" style={{fontWeight: 600, textAlign: "center", width: 89}}>Declined</Tag>
                              :
                              <Tag color="#1890ff" style={{fontWeight: 600, textAlign: "center", width: 89}}>Completed</Tag>
                            }
                          </React.Fragment>
                        </div>
                      ),
                      filters: [{
                        text: 'Completed',
                        value: 'completed'
                      }, {
                        text: 'Declined',
                        value: 'declined'
                      }],
                    },
                    {
                      title: '',
                      key: 'more',
                      align: "right",
                      render: row => (
                        <Button
                          onMouseDown={(e) => {
                            if (e.button === 1) {
                              const win = window.open(`/completed-order?order=${row.id}`, "_blank");
                              win.focus();
                            } else if (e.button === 0) {
                              history.push(`/completed-order?order=${row.id}`)
                            }
                          }}
                          type="primary"
                          style={{
                            fontSize: 13,
                            fontWeight: 600
                          }}
                        >
                          Details
                        </Button>
                      ),
                    },
                  ]}
                  className={"pendingOrdersTable"}
                  dataSource={archivedOrders}
                  rowKey="id"
                  pagination={{...pagination, pageSize: pageSize}}
                  loading={{spinning: isFetchingArchivedOrders, indicator: <LoadingOutlined style={{ fontSize: 50,marginLeft: "-25px" }} spin />}}
                  onChange={handleTableChange}
                  size={"small"}
                  scroll={{x: '70%'}}
                />
              </Fragment>
            </Col>
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  )
}
