import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux'

import { Sidebar } from '../../../components'

import {
  Button, Card, Col,
  Layout, Modal, Row, Spin, Tag,
  Upload
} from 'antd';

import "./style.css"
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
  WarningOutlined,
  LockOutlined
} from "@ant-design/icons";
import dragAndDropWhite from "../../../assets/icons/dragndropwhite.png";
import {DeletionModal} from "../../../components/DeletionModal";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import {Icon2D} from "../../../assets/icons/2d";
import {Icon3D} from "../../../assets/icons/3d";
import {getCompanyBasicInfo, logoutUser} from "../../../actions/authActions";
import {
  createNewDraftOrder,
  fetchDraftOrder,
  fetchDraftOrders,
  removeDraftOrder,
  fetchCustomerAwaitingConfirmationOrders, customerFetchRecentChanges, createNewDraftOrderWithFiles
} from "../../../actions/ordersActions";
import history from "../../../utils/history";
import {formatTableDate, getOrderStateName} from "../../../utils/utility";
import {S3_BUCKET_URL} from "../../../constants";
import logoPlaceholder from "../../../assets/images/logo-placeholder.svg";

const { Dragger } = Upload;

let dragging = 0;
let uploading = false;

export const CustomerDashboard = () => {
  const [draftLimitVisible, setDraftLimitVisible] = useState(false);

  const user = useSelector(state => state.auth.user)
  const isFetchingOrder = useSelector(state => state.orders.isFetchingDraftOrder)
  const isFetchingDrafts = useSelector(state => state.orders.isFetchingDrafts)
  const draftOrders = useSelector(state => state.orders.draftOrders)
  const isCreatingDraftOrder = useSelector(state => state.orders.isCreatingDraftOrder)

  const isFetchingCustomerAwaitingConfirmationOrders  = useSelector(state => state.orders.isFetchingCustomerAwaitingConfirmationOrders)
  const customerAwaitingConfirmation  = useSelector(state => state.orders.customerAwaitingConfirmation)

  const isFetchingRecentChanges  = useSelector(state => state.orders.isFetchingRecentChanges)
  const recentChanges  = useSelector(state => state.orders.recentChanges)
  const isCollapsed = useSelector(state => state.util.isCollapsed);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDraftOrders())
    dispatch(fetchCustomerAwaitingConfirmationOrders())
    dispatch(customerFetchRecentChanges())
  }, [])

  useEffect(() => {
    console.log(user)
  }, [user])

  const handleInitialSize = () => {
    const width = window.innerWidth
    if(width >= 1600) {
      return 540
    } else if(width < 1600 && width >= 1200) {
      return 540
    } else if (width < 1200 && width >= 992) {
      return 570
    } else {
      return "calc(100% - 35px)"
    }
  };

  const [confirmationHeight, setConfirmationHeight] = useState(handleInitialSize())

  useEffect(() => {
    document.addEventListener("dragenter", handleDragEnter);
    document.addEventListener("dragleave", handleDragLeave);
    document.addEventListener("drop", handleDrop, false);
    window.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("dragenter", handleDragEnter);
      document.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("drop", handleDrop, false);
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  const handleDragEnter = e => {
    dragging++
    e.stopPropagation();
    e.preventDefault();

    let dt = e.dataTransfer;
    if (dt.types != null && ((dt.types.length && (dt.types[0] === 'Files' || dt.types[0] === 'application/x-moz-file')))) {
      let dropper = document.getElementsByClassName("fileDropZone")[0]
      if(dropper.style.display === "none" && !isCreatingDraftOrder && !isFetchingOrder ) {
        dropper.style.display = "block"
      }
    }
    return false
  };

  const handleDragLeave = e => {
    dragging--;
    if (dragging === 0) {
      document.getElementsByClassName("fileDropZone")[0].style.display = "none"
    }

    e.stopPropagation();
    e.preventDefault();
    return false
  };

  const handleDrop = e => {
    e.preventDefault();
    dragging=0
    document.getElementsByClassName("fileDropZone")[0].style.display = "none"
  };

  const handleResize = () => {
    const width = window.innerWidth
    if(width >= 1600) {
      setConfirmationHeight(540)
    } else if(width < 1600 && width >= 1200) {
      setConfirmationHeight(540)
    } else if (width < 1200 && width >= 992) {
      setConfirmationHeight(570)
    } else {
      setConfirmationHeight("calc(100% - 35px)")
    }
  };

  const tagColor = (type) => {
    switch (type) {
      case "quoted":
        return "#f0bd27"
      case "awaitingQuote":
        return "#f0bd27"
      case "awaitingConfirmation":
        return "#9966cc"
      case "accepted":
        return "#51b364"
      case "inProgress":
        return "#7cbbf4"
      case "declined":
        return "#b60a1c"
      case "completed":
        return "#1890ff"
      case "internalArchived":
        return "#f0bd27"
      default:
        return "#f0bd27"
    }
  }

  const removeDraft = (draftId) => {
    dispatch(removeDraftOrder({draftId}))
  }

  const createNewOrder = () => {
    if(!isFetchingDrafts) {
      if(draftOrders.length > 7) {
        setDraftLimitVisible(true)
      } else {
        dispatch(createNewDraftOrder())
      }
    }
  }

  const openDraftOrder = (id) => {
    dispatch(fetchDraftOrder({id: id, withRedirect: true}))
  }

  const getOrderDetailsPath = (state) => {
    switch (state) {
      case "quoted":
        return "pending-order"
      case "awaitingQuote":
        return "pending-order"
      case "awaitingConfirmation":
        return "awaiting-order"
      case "accepted":
        return "accepted-order"
      case "inProgress":
        return "accepted-order"
      case "declined":
        return "completed-order"
      case "completed":
        return "completed-order"
      default:
        return "pending-order"
    }
  }

  const handleCreateDraftOrder = (fileList) => {
    if(!uploading) {
      uploading = true
      if(draftOrders.length > 7) {
        setDraftLimitVisible(true)
      } else {
        dispatch(createNewDraftOrderWithFiles(fileList)).then(()=>{
          uploading = false
        })
      }
    }
  }

  return (
    <Layout
      style={{
        overflow: "auto",
        minHeight: "100vh",
      }}
    >
      <Sidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{overflowX: "hidden"}}>
          <Spin
            spinning={isCreatingDraftOrder || isFetchingOrder}
            style={{maxHeight: "100%"}}
            indicator={
              <div style={{position: "absolute", top: "35%", left: "50%", width: 400, transform: "translateX(-50%)"}}>
                <LoadingOutlined style={{ fontSize: 72 }} spin />
                <div style={{fontSize: 27, fontWeight: 500, marginTop: 10}}>Fetching Your Order</div>
              </div>
            }
          >
          <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
            <Row gutter={[20, 5]}>
              <Col span={24}>
                <div className={"dashboardWelcome"}>Welcome back, {user.name}.</div>
              </Col>
              <Col xxl={{span: 12, order: 1}} xl={{span: 12, order: 1}} lg={{span: 24, order: 1}} md={{span: 24, order: 1}} sm={{span: 24, order: 1}} xs={{span: 24, order: 1}}>
                <div
                  className={"newOrderBanner"}
                  style={{marginBottom: 20, minHeight: 380, maxHeight: 380}}
                  onClick={(e) => {
                    e.stopPropagation()
                    createNewOrder()
                  }}
                >
                  <div style={{fontSize: 16, fontWeight: 600, color: "black", textAlign: "center"}}>Upload your 2D or 3D model to get instant price</div>
                  <div style={{fontSize: 19, fontWeight: 600, color: "black", textAlign: "center"}}>STEP | STP | DXF file support </div>

                  <div className={"getQuoteUpper"}>
                    <img src={`${S3_BUCKET_URL}${user.producer.logoUrl}`} alt={"file"} style={{maxHeight: "114px", maxWidth: "300px", marginTop: "15px"}}/>
                    <div style={{ color: 'black', paddingLeft: 12, fontSize: 12, fontWeight: 400 }}>You logged in company:</div>
                    <div style={{ color: 'black', paddingLeft: 12, fontSize: 14, fontWeight: 600 }}>{user.producer?.name || user.name}</div>
                    <div style={{ width: "100%" }}>
                      <Button disabled={isFetchingDrafts} className={"getQuoteButton"} style={{marginTop: 30, marginBottom: 6}}>
                        Get Instant Quote
                      </Button>
                      <div style={{fontSize: 13, fontWeight: 400, color: "black", textAlign: "center"}}><LockOutlined /> All your uploads are confidential and secure.</div>
                    </div>
                    {draftOrders.length === 8 &&
                      <div style={{ marginTop: 5, marginBottom: 6}}>
                        <ExclamationCircleFilled style={{color: "#ffc739", fontSize: 15}} />
                        <span style={{fontSize: 12, fontWeight: 500, color: "black", marginLeft: 5, marginBottom: 5}}>
                        You can have a maximum of 8 Drafts.
                      </span>
                      </div>
                    }
                  </div>

                </div>

              </Col>
              <Col xxl={{span: 12, order: 2}} xl={{span: 12, order: 2}} lg={{span: 24, order: 3}} md={{span: 24, order: 3}} sm={{span: 24, order: 3}} xs={{span: 24, order: 3}}>
                <div className={"draftsTitle"} style={{marginTop: -11}}>
                  Recent Status Changes
                </div>
                <Spin
                  spinning={isFetchingRecentChanges}
                  wrapperClassName={"recentChangesSpin"}
                  indicator={<LoadingOutlined style={{ fontSize: 48, marginTop: -24, marginLeft: -24 }} spin />}
                >
                  <div className={"recentConfirmationsBox"} style={{backgroundColor: "rgba(255,255,255,0.6)", marginTop: 5}}>
                    {recentChanges.length === 0 ?
                      <div className={"emptyRecentAwaiting"}>
                        {
                          !isFetchingRecentChanges &&
                          <div className={"emptyDraftsText"}>No recent Status changes.</div>
                        }
                      </div>
                      :
                      (recentChanges.map(item => (
                        <Card
                          className={"recentCard"}
                          style={{height: 45, cursor: "pointer", marginBottom: 6}}
                          bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
                          key={item.id}
                          onMouseDown={(e) => {
                            if (e.button === 1) {
                              const win = window.open(`/${getOrderDetailsPath(item.state)}?order=${item.id}`, "_blank");
                              win.focus();
                            } else if (e.button === 0) {
                              history.push(`/${getOrderDetailsPath(item.state)}?order=${item.id}`)
                            }
                          }}
                        >
                          <div style={{display: "flex", alignItems: "center", width: "100%", marginTop: -5}}>
                            <table style={{width: "100%"}}>
                              <tbody>
                              <tr>
                                <td style={{width: "35%", maxWidth: 50}}>
                                  <div style={{ display: "flex", height: "100%", width: "100%"}}>
                                    <div className={"recentStatusLeft"} style={{width: "100%"}}>
                                      <div className={"ellipsisStatusChange"}>{item.customName || item.name}</div>
                                    </div>
                                  </div>
                                </td>
                                <td style={{width: 200}}>
                                  <div align={"left"} className={"recentStatusLeft"} style={{marginRight: 8, width: "100%"}}>
                                    <div style={{fontSize: 12, fontWeight: 500}}>Changed: {formatTableDate(item.lastStateChangeDate)}</div>
                                  </div>
                                </td>
                                <td style={{width: "280px"}}>
                                  <div style={{ display: "flex", height: "100%", width: "100%", justifyContent: "flex-end", alignItems: "center", alignSelf: "center"}}>
                                    <div>
                                      <Tag color={tagColor(item.previousState)} style={{fontWeight: 500, width: 96, textAlign: "center"}}>{getOrderStateName(item.previousState)}</Tag>
                                    </div>
                                    <div style={{marginRight: 8}}>
                                      <ArrowRightAltIcon style={{position: "relative", width: 60, top: 3, fontSize: 28, color: "#3f4850"}}/>
                                    </div>
                                    <div>
                                      <Tag color={tagColor(item.state)} style={{fontWeight: 500, width: 96, textAlign: "center"}}>{getOrderStateName(item.state)}</Tag>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </Card>
                      )))
                    }
                  </div>
                </Spin>
              </Col>

              <Col
                xxl={{span: 12, order: 3}}
                xl={{span: 12, order: 3}}
                lg={{span: 24, order: 2}}
                md={{span: 24, order: 2}}
                sm={{span: 24, order: 2}}
                xs={{span: 24, order: 2}}
              >
                <div className={"draftsTitle"}>
                  Drafts
                </div>
                <div className={"awaitingConfirmationBox"}
                     style={{backgroundColor: "rgba(255,255,255,0.6)", marginBottom: 11, padding: "12px 6px 12px 6px"}}
                >
                  <Spin spinning={isFetchingDrafts}
                        indicator={<LoadingOutlined style={{ fontSize: 72, marginTop: -36, marginLeft: -36 }} spin />}
                  >
                    <Row gutter={[6, 12]}>
                      {draftOrders.length === 0 ?
                        <div className={"emptyDrafts"}>
                          {
                            !isFetchingDrafts &&
                            <div className={"emptyDraftsText"}>You have no drafts at the moment.</div>
                          }
                        </div>
                        :
                        draftOrders.map(item => (
                          <Col key={item.id} xxl={6} xl={8} md={12} sm={24} xs={24}>
                            <Spin
                              spinning={item.loading === true}
                              indicator={<LoadingOutlined style={{ fontSize: 36, marginTop: -18, marginLeft: -18 }} spin />}
                            >
                              <div className={"draftCard"} onMouseDown={(e) => {
                                if (e.button === 1) {
                                  const win = window.open(`/draft?order=${item.id}`, "_blank");
                                  win.focus();
                                } else if (e.button === 0) {
                                  openDraftOrder(item.id)
                                }
                              }}>
                                <div className={"titleWrapper"}>
                                  <div className={"draftCardTitle"}>
                                    {item.customName || item.name}
                                  </div>
                                  <div>
                                    <Button
                                      className={"draftRemoveButton"}
                                      shape={"circle"}
                                      onMouseDown={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation();
                                        DeletionModal(true,
                                          "Remove Draft",
                                          "Are you sure you want to remove this draft",
                                          () => {},
                                          () => {
                                            removeDraft(item.id)
                                          })
                                      }}
                                    >
                                      <DeleteOutlined style={{color: "#f9423a"}}/>
                                    </Button>
                                  </div>
                                </div>
                                <div>
                                  <div className={"draftInfo"}>
                                    <div>
                                      <EditOutlined style={{fontSize: 16}}/>
                                    </div>
                                    <div className={"draftText"}>Draft</div>
                                  </div>
                                  <div>
                                    <Row gutter={5}>
                                      <Col span={8}>
                                        <div
                                          className={"draftProgressBar"}
                                          style={{backgroundColor: "#1890ff"}}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <div
                                          className={"draftProgressBar"}
                                          style={{
                                            backgroundColor:
                                              item.stage === "shipping" || item.stage === "confirmation" ?
                                                "#1890ff" : "#e0e0e0"
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <div
                                          className={"draftProgressBar"}
                                          style={{backgroundColor:
                                              item.stage === "confirmation" ? "#1890ff" : "#e0e0e0"
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </Spin>
                          </Col>
                        ))}
                    </Row>
                  </Spin>
                </div>
              </Col>
              <Col xxl={{span: 12, order: 4}} xl={{span: 12, order: 4}} lg={{span: 24, order: 4}} md={{span: 24, order: 4}} sm={{span: 24, order: 4}} xs={{span: 24, order: 4}}>
                <div className={"draftsTitle"}>
                  Awaiting Confirmation
                </div>
                <div className={"awaitingConfirmationBox"} style={{height: confirmationHeight, padding: "0 6px 0 6px"}}>
                  <Spin
                    spinning={isFetchingCustomerAwaitingConfirmationOrders}
                    wrapperClassName={"awaitingConfirmationSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72, marginTop: -36, marginLeft: -36 }} spin />}
                  >
                    <div>
                      {customerAwaitingConfirmation.length === 0 ?
                        <div className={"emptyAwaiting"}>
                          {
                            !isFetchingCustomerAwaitingConfirmationOrders &&
                            <div className={"emptyDraftsText"}>All orders have been confirmed.</div>
                          }
                        </div>
                        :
                        customerAwaitingConfirmation.map(item => (
                          <Card
                            onMouseDown={(e) => {
                              if (e.button === 1) {
                                const win = window.open(`/awaiting-order?order=${item.id}`, "_blank");
                                win.focus();
                              } else if (e.button === 0) {
                                history.push(`/awaiting-order?order=${item.id}`)
                              }
                            }}
                            type="primary"
                            className={"bendCard"}
                            style={{height: 75, marginTop: 12, marginBottom: 12, cursor: "pointer"}}
                            bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}} key={item.id}
                          >
                            <div style={{ display: "flex", justifyContent: "space-between", height: "100%"}}>
                              <div style={{ display: "flex", height: "100%"}}>
                                <div className={"awaitingConfirmationLeft"}>
                                  <div>{item.customName ? <span>{item.customName}</span> : <span>{item.orderName}</span>}</div>
                                  <div className={"inputInfoText"}>Changed: {formatTableDate(item.lastStateChangeDate)}</div>
                                </div>
                              </div>
                              <div style={{ display: "flex", height: "100%"}}>
                                <div className={"awaitingConfirmationRight"}>
                                  <div className={"inputInfoText"} align={"right"} style={{fontSize: 12, fontWeight: 500, lineHeight: 2}}>
                                    {
                                      (item.changedDelivery ? ("Date" + (item.changedPrice ? ", Price" : "")) : (item.changedPrice ? "Price" : ""))
                                      +
                                      (item.changedParts ? item.changedDelivery || item.changedPrice ? ", Parts" : "Parts" : "")
                                    }
                                    <ExclamationCircleFilled style={{color: "#ffc739", fontSize: 15, position: "relative", top: 1, marginLeft: 5}} />
                                  </div>
                                  <div align={"right"}>
                                    <Button size={"small"} type={"primary"}>
                                      <span className={"inputInfoText"}>Review</span>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        ))
                      }
                    </div>
                  </Spin>
                </div>
              </Col>
            </Row>
          </div>
          </Spin>
        </div>
        <Modal
          visible={draftLimitVisible}
          footer={null}
          centered={true}
          onCancel={()=>{
            uploading = false
            setDraftLimitVisible(false)
          }}
          title={"Draft Limit"}
        >
          <div style={{display: "flex", marginBottom: 20, marginTop: 15}}>
            <div style={{marginLeft: 10}}>
              <WarningOutlined style={{fontSize: 42, color: "#ffc739"}}/>
            </div>
            <div style={{marginLeft: 20, fontSize: 15, fontWeight: 500}}>
              <div>
                You can have a maximum of 8 Drafts at a time.
              </div>
              <div>
                To create a new Order please remove or finalize one or more previous Drafts.
              </div>
            </div>
          </div>
        </Modal>

        <Dragger
          className={"fileDropZone"}
          name = {'file'}
          beforeUpload={(file, fileList) => {
            handleCreateDraftOrder(fileList)
            return false;
          }}
          multiple={true}
          showUploadList={false}
          style={{
            display: 'none',
            position: 'fixed',
            margin: 5,
            top: 0,
            left: 0,
            width: 'calc(100% - 12px)',
            maxHeight: 'calc(100vh - 12px)',
            background: "rgba(0.5,0.5,0.5,0.2)",
            border: "2px dashed #066fc1",
            zIndex: 2000
          }}
        >
          <div align={'center'}>
            <Card
              style={{
                borderRadius: "5px",
                background: "#2076ba",
                border: "0px",
                maxWidth: "300px"
              }}
            >
              <img src={dragAndDropWhite} className={"drop-file-icon"} alt={"file"} />
              <span className={"text14-600"} style={{color: "white", marginLeft: 15, fontSize: 16}}>
                Drop your files here
              </span>
            </Card>
          </div>
        </Dragger>
      </Layout.Content>
    </Layout>
  )
}
