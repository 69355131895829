import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import {auth, storage} from '../utils'

const PrivateRoute = ({ component: Component, ...rest }) => {
  let isAuthenticated = auth.user();
  const authTime = storage.retrieveTimePassed();

  if (!isAuthenticated) {
    storage.storeAuthData(null)
    auth.resetUser()
  }
  else if(authTime >= 216000) {
    storage.storeAuthData(null)
    auth.resetUser()
    isAuthenticated = false;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

const withRedirect = ({
  ifTrue,
  thenRenderComponent: Component,
  elseRedirectTo,
}) => () =>
  ifTrue() ? <Component/> : <Redirect to={{ pathname: elseRedirectTo }} />

export { PrivateRoute, withRedirect }
