import axios from 'axios'
import { API_BASE_URL } from '../constants'
import {auth, message} from "./index";
import history from "./history";

const storeAuthData = data => {
  localStorage.setItem('hefeos_auth_data', JSON.stringify(data))
  const time_data = data === null ? null : {timestamp: new Date()}
  localStorage.setItem('hefeos_auth_data_session_time', JSON.stringify(time_data))
  localStorage.setItem('hefeos_auth_data_time', JSON.stringify(time_data))
}

const retrieveAuthData = () =>
  JSON.parse(localStorage.getItem('hefeos_auth_data'))

const retrieveAccessToken = () => {
  const authData = JSON.parse(localStorage.getItem('hefeos_auth_data'));
  if(authData) {
    return authData.accessToken
  }
  return null;
}

const retrieveRefreshToken = () => {
  const authData = JSON.parse(localStorage.getItem('hefeos_auth_data'));
  if(authData) {
    return authData.refreshToken
  }
  return null;
}

const retrieveSessionStartTime = () =>
  JSON.parse(localStorage.getItem('hefeos_auth_data_session_time'));

const retrieveAuthStartTime = () =>
  JSON.parse(localStorage.getItem('hefeos_auth_data_time'));

const retrieveTimePassed = () => {
  const authTime = retrieveAuthStartTime()
  if(authTime === null) {
    return 100000;
  }
  return (new Date() - new Date(authTime.timestamp))/1000
}

const refreshAccessToken = () => {
  const refreshToken = retrieveRefreshToken();
  return axios.get(`${API_BASE_URL}/auth/refresh-token`,  {headers: {'Content-Type': 'application/json', 'Authorization': `${refreshToken}`}})
    .then((response)=>{
      const newAccessToken = response.data.accessToken
      if(newAccessToken) {
        updateAccessToken(newAccessToken);
        return newAccessToken
      }
    })
    .catch(()=>{
      storeAuthData(null)
      auth.resetUser()
      message.show(message.type.info, 'Your session has expired.')
      history.push("/login")
    });
}

const updateAccessToken = (token) => {
  localStorage.setItem(
    'hefeos_auth_data',
    JSON.stringify({
      ...JSON.parse(localStorage.getItem('hefeos_auth_data')),
      accessToken: token,
    })
  )
  const time_data = {timestamp: new Date()}
  localStorage.setItem('hefeos_auth_data_session_time', JSON.stringify(time_data))
}

const updateUser = data => {
  const user = JSON.parse(localStorage.getItem('hefeos_auth_data'))
  localStorage.setItem(
    'hefeos_auth_data',
    JSON.stringify({
      ...user,
      user: {...user.user, ...data},
    })
  )
}

export default {
  storeAuthData,
  retrieveAuthData,
  updateUser,
  retrieveAccessToken,
  refreshAccessToken,
  retrieveSessionStartTime,
  retrieveAuthStartTime,
  retrieveTimePassed,
}
