import {
  UTIL_COLLAPSE, UTIL_UPDATE_ACTIVE_SUBMENU_KEYS, UTIL_UPDATE_CUSTOMER_ACTIVE_SUBMENU_KEYS
} from "../actions/actionTypes";
import initialState from './initialState';

const utilReducer = (state = initialState.util, action) => {
  switch (action.type) {
    case UTIL_COLLAPSE:
      return {
        ...state,
        isCollapsed: action.payload,
      }

    case UTIL_UPDATE_ACTIVE_SUBMENU_KEYS:
      const activeKeys = [...state.activeSubmenuKeys];
      const thisKey = action.payload;
      let updatedKeys = [];
      if (activeKeys.includes(thisKey)) {
        updatedKeys = activeKeys.filter(item => item !== thisKey);
      } else {
        updatedKeys = [...activeKeys, thisKey]
      }
      return {
        ...state,
        activeSubmenuKeys: updatedKeys
      }

    case UTIL_UPDATE_CUSTOMER_ACTIVE_SUBMENU_KEYS:
      const activeCustomerKeys = [...state.activeCustomerSubmenuKeys];
      const thisKey_ = action.payload;
      let updatedCustomerKeys = [];
      if (activeCustomerKeys.includes(thisKey_)) {
        updatedCustomerKeys = activeCustomerKeys.filter(item => item !== thisKey_);
      } else {
        updatedCustomerKeys = [...activeCustomerKeys, thisKey_]
      }
      return {
        ...state,
        activeCustomerSubmenuKeys: updatedCustomerKeys
      }

    default:
      return state
  }
}

export default utilReducer;