import {Button, Card, Spin, Tooltip} from "antd";
import {S3_BUCKET_URL} from "../../../constants";
import twoD from "../../../assets/icons/2D.svg";
import treeD from "../../../assets/icons/3D.svg";
import {calcAdjustment, getPlaceholderImage} from "../../../utils/utility";
import {DownloadOutlined, FilePdfOutlined, SearchOutlined} from "@ant-design/icons";
import React from "react";
import {Trans} from "react-i18next";


export const ArchivedBulkOrderPartList = (props) => {

  const {
    parts, showOtherItem, showDetailsItem, handleShowDownloadFiles,
    adjustment
  } = props;

  return (
    <div
      className={"quotedItemsList"}
      style={{
        minHeight: 250,
        height: "calc(100vh - 145px)",
        padding: "0 6px 0 6px"
      }}
    >
      {parts.map(part => (
        <Card
          className={"bendCard"}
          style={{height: 80, marginTop: 10, marginBottom: 10}}
          bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
          key={part.id}
        >
          <div style={{display: "flex", width: "100%"}}>
            <div
              style={{
                border: "1px solid rgb(205, 205, 205)",
                borderRadius: 3,
                marginRight: 8,
                background: "white",
                display: "flex",
                alignItems: "center"
              }}
            >
              {part.fileType === "dxf" &&
              <div>
                {part.dxfData.pngFilePath ?
                  <div
                    style={{
                      width: 55,
                      height: 55,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <div>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          maxWidth: 62,
                          maxHeight: 55,
                          padding: 3,
                        }}
                        src={S3_BUCKET_URL + part.dxfData.pngFilePath}
                        alt={"part"}
                      />
                    </div>
                  </div>
                  :
                  <div
                    style={{
                      width: 55,
                      height: 55,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <img
                      style={{
                        width: 45,
                        height: 45,
                        padding: 3,
                        marginTop: 5,
                        marginLeft: 4
                      }}
                      src={twoD}
                      alt={"part"}
                    />
                  </div>
                }
              </div>
              }
              {part.fileType === "step" &&
              <div>
                {part.stepData.filePaths.pngFilePath ?
                  <img style={{width: 55, height: 55, padding: 3}} src={S3_BUCKET_URL + part.stepData.filePaths.pngFilePath} alt={"part"}/>
                  :
                  <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={treeD} alt={"part"}/>
                  </div>
                }
              </div>
              }
              {(part.fileType === "other" || (!part.fileType && part.processingType === "manual")) &&
              <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={getPlaceholderImage(part.partType)} alt={"part"}/>
              </div>
              }
            </div>
            <div style={{width: "100%"}}>
              <div style={{width: "100%"}}>
                <table style={{width: "100%"}}>
                  <tbody>
                  <tr>
                    <td style={{width: "calc(100% - 100px)", maxWidth: 150}}>
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          paddingRight: 10,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {part.fileName || part.name}
                      </div>
                    </td>
                    <td style={{width: 100}}>
                      <div style={{display: "flex", height: 24, justifyContent: "flex-end"}}>
                        <div style={{display: "flex", height: 24}}>
                          {part.drawingPDFFile?.drawingPDFFilePath &&
                          <Tooltip
                            color={"rgba(82,82,82,0.9)"}
                            overlayClassName={"small-tooltip"}
                            size={"small"}
                            title={<span className={"text12-500"} style={{color: "white"}}>Part Drawing</span>}
                          >
                            <Button
                              size={"small"}
                              type={"primary"}
                              className={"exportPdfButton"}
                              href={`${S3_BUCKET_URL + part.drawingPDFFile.drawingPDFFilePath}`}
                            >
                              <div>
                                <FilePdfOutlined style={{fontSize: 13}}/>
                              </div>
                            </Button>
                          </Tooltip>
                          }
                          <Button
                            size={"small"}
                            type={"primary"}
                            onClick={() => handleShowDownloadFiles(part)}
                          >
                            <DownloadOutlined style={{fontSize: 13}}/>
                          </Button>
                          <Button
                            size={"small"}
                            type={"primary"}
                            style={{marginLeft: 5}}
                            onClick={()=>{
                              if(part.fileType === "other" || (part.processingType === "manual" && !part.fileType)) {
                                showOtherItem(part)
                              } else {
                                showDetailsItem(part)
                              }
                            }}
                          >
                            <div>
                              <SearchOutlined style={{fontSize: 13}}/>
                            </div>
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div>
                {part.partType === "sheetMetal" ?
                  <div
                    style={{fontSize: 12, fontWeight: 500}}
                  >
                    Material: <span style={{fontWeight: 600}}><Trans>{part.selectedMaterial.grade}</Trans> ({part.selectedMaterial.thickness}mm)</span>
                  </div>
                  :
                  (part.partType === "machined" || part.partType === "profile" ?
                      <div
                        style={{fontSize: 12, fontWeight: 500}}
                      >
                        Material: <span style={{fontWeight: 600}}><Trans>{part.selectedMaterial?.grade || part.materialGrade}</Trans></span>
                      </div>
                      :
                      <div
                        style={{fontSize: 12, fontWeight: 500}}
                      >
                        Standard: <span style={{fontWeight: 600}}>{part.standard}</span>
                      </div>
                  )
                }
                <div style={{display:"flex", justifyContent: "space-between"}}>
                  <div
                    style={{fontSize: 12, fontWeight: 500, marginRight: 8}}
                  >
                    Quantity: <span style={{fontWeight: 600}}>{part.quantity}</span>
                  </div>
                  <div
                    style={{fontSize: 12, fontWeight: 500}}
                  >
                    1pc: <span style={{fontWeight: 600, color: adjustment === 0 ? "rgba(0, 0, 0, 0.85)" : "#047a04"}}>{((parseFloat(part.pricePerPiece) || 0) * calcAdjustment(adjustment)).toFixed(2)} €</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  )
}