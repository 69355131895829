import React, {useEffect, useState, useCallback, createRef, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {forceLogoutUser, logoutUser} from '../../../actions/authActions';
import {
  changeItemColor, removeItemColor, changeItemQuantity,
  changeItemNote, changeItemMaterial, changeItemThickness,
  resetToProcess, removeItem, removeMultipleItems,
  applyMultipleItems, changeManualItemType, uploadNewPart,
  changeStageToShipping, changeToPreviousStage, updatePartData,
  updatePartFilePath, changePartToError, changeOrderPrice,
  removeErrorParts, changeDeliveryDate, changeStageToConfirmation,
  placeOrder, changePartPrice, changeDraftOrderName, changeManyPartPrices,
  confirmAdjustmentChange, changeAdjustments
} from '../../../actions/orderActions';
import {fetchDraftOrder, resetFilesToUpload, resetToAnalyze} from "../../../actions/ordersActions";

import Sticky from 'react-sticky-el';
import { debounce } from 'lodash';
import {io} from "socket.io-client";
import cuid from "cuid";

import {
  Button, Card, Grid, Col, Layout, Row,
  Spin, Tooltip, Upload, Divider, Progress,
  Input, notification, Modal
} from 'antd';
import {
  ExclamationCircleFilled, FileDoneOutlined, LoadingOutlined, QuestionCircleFilled, WarningOutlined,
} from '@ant-design/icons';

import {
  Divider as MiDivider
} from '@material-ui/core';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import dragAndDropWhite from '../../../assets/icons/dragndropwhite.png';
import {DropIcon} from "../../../assets/icons/drop";
import {ManualQuote} from "../../../assets/icons/manualQuote";

import { OrderProgress } from '../../../components/Order/OrderProgress';
import ItemVisualisation from "../../../components/Order/ItemVisualisation";
import { Sidebar } from '../../../components'

import history from '../../../utils/history';
import storage from "../../../utils/storage";
import {S3_BUCKET_URL, SOCKET_URL, SOCKET_PATH} from "../../../constants";

import './style.css';
import {OrderConfirmationStage} from "../../../components/Order/OrderStages/OrderConfirmationStage";
import {OrderShippingStage} from "../../../components/Order/OrderStages/OrderShippingStage";
import {OrderProcessingStage} from "../../../components/Order/OrderStages/OrderProcessingStage";
import {getAvailableThicknesses} from "../../../utils/material";
import {Trans} from "react-i18next";
import {calcAdjustment} from "../../../utils/utility";
import twoD from "../../../assets/icons/2D.svg";
import treeD from "../../../assets/icons/3D.svg";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";

const { Dragger } = Upload;

let dragging = 0;

var socket;

export const DraftOrderPage = () => {

  const user = useSelector(state => state.auth.user)
  const isFetchingOrder = useSelector(state => state.orders.isFetchingDraftOrder)
  const order = useSelector(state => state.order)
  const orderId = useSelector(state => state.order.id)
  const shippingData = useSelector(state => state.order.shippingData)
  const stage = useSelector(state => state.order.stage)
  const items = useSelector(state => state.order.parts)
  const errorItems = useSelector(state => state.order.errorList)
  const needsManualQuote = useSelector(state=>state.order.needsManualQuote)
  const materials = useSelector(state => state.order.materials)
  const processingPrice = useSelector(state => state.order.processingPrice)
  const totalPrice = useSelector(state => state.order.totalPrice)
  const vatValue = useSelector(state => state.order.vatValue)
  const orderName = useSelector(state => state.order.name)
  const toProcess = useSelector(state => state.order.toProcess)
  const currentlyUploaded = useSelector(state => state.order.currentlyUploaded)
  const currentlyProcessed = useSelector(state => state.order.currentlyProcessed)
  const companySelectableMaterials = useSelector(state => state.order.selectableMaterials)
  const adjustment = useSelector(state => state.order.adjustment)
  const isProceedingToShipping = useSelector(state => state.orders.isProceedingToShipping)
  const isChangingDraftStage = useSelector(state => state.orders.isChangingDraftStage)
  const toAnalyze = useSelector(state => state.orders.toAnalyze)
  const isChangingDeliveryDate = useSelector(state => state.orders.isChangingDeliveryDate)
  const isChangingDraftName = useSelector(state => state.orders.isChangingDraftName)
  const filesToUpload = useSelector(state => state.orders.filesToUpload)
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const screens = Grid.useBreakpoint();

  const [selectedItems, setSelectedItems] = useState({})
  const [selectableItemsCount, setSelectableItemsCount] = useState(0)
  const [allItemsCount, setAllItemsCount] = useState(0)
  const [selectedItemsCount, setSelectedItemsCount] = useState(0)
  const [selectedAllItems, setSelectedAllItems] = useState(false)
  const [selectedParameters, setSelectedParameters] = useState({
    material: null,
    thickness: null,
    quantity: null,
    coating: {
      hasCoating: false,
      colorRAL: undefined,
      colorGlossId: undefined,
      ralRow: undefined,
      ralHex: undefined,
    }
  })
  const [working, setWorking] = useState(false)
  const [actionMap, setActionMap] = useState(new Map());
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectableMaterials, setSelectableMaterials] = useState(new Set([...(companySelectableMaterials || [])]))
  const [selectableThickness, setSelectableThickness] = useState(new Set([]))
  const [selectedItemsMaterials, setSelectedItemsMaterials] = useState({})
  const [socketConnected, setSocketConnected] = useState(false)
  const [viewVisible, setViewVisible] = useState(false)
  const [itemView, setItemView] = useState(null)
  const [modalWidth, setModalWidth] = useState(window.innerWidth-100)
  const [modalHeight, setModalHeight] = useState(window.innerHeight-135)
  const [largeThreshold, _setLargeThreshold] = useState(window.innerWidth < 1300)
  const [smallThreshold, _setSmallThreshold] = useState(window.innerWidth < 750)
  const [viewOtherVisible, setViewOtherVisible] = useState(false)
  const [otherItemView, setOtherItemView] = useState(null)
  const [indeterminate, setIndeterminate] = useState(false);
  const [showAdjustmentChangeModal, setShowAdjustmentChangeModal] = useState(false);

  const largeThresholdRef = useRef(largeThreshold);
  const smallThresholdRef = useRef(smallThreshold);
  const workingRef = useRef(working)
  const stageRef = useRef(stage)
  const multiformRef = createRef()

  const dispatch = useDispatch()

  const updateActions = (k,v) => {
    setActionMap(new Map(actionMap.set(k,v)));
  }

  const handleAnalysedPart = (data) => {
    dispatch(updatePartData(data))
  }

  const handleUpdateOrderPartFilePath = (data) => {
    dispatch(updatePartFilePath(data))
  }

  const handlePartProductionError = (data) => {
    dispatch(changePartToError(data))
  }

  const handleOrderPriceChange = (data) => {
    dispatch(changeOrderPrice(data))
  }

  const handleDeletePartsResponse = (data) => {
    const tmpMap = actionMap
    tmpMap.delete(data.actionId)
    setActionMap(new Map(tmpMap));
  }

  const handleUpdatePartResponse = (data) => {
    const tmpMap = actionMap
    tmpMap.delete(data.actionId)
    setActionMap(new Map(tmpMap));
    dispatch(changePartPrice(data))
  }

  const handleUpdateManyPartsResponse = (data) => {
    const tmpMap = actionMap
    tmpMap.delete(data.actionId)
    setActionMap(new Map(tmpMap));
    dispatch(changeManyPartPrices(data))
  }

  useEffect(()=>{
    if(selectedItemsCount > 0 && selectedItemsCount < selectableItemsCount) {
      setIndeterminate(true);
    } else {
      setIndeterminate(false);
    }
  }, [selectableItemsCount, selectedItemsCount])

  useEffect(() => {
    const accessToken = storage.retrieveAccessToken();
    socket = io(SOCKET_URL, {
      path: SOCKET_PATH,
      auth: {
        token: accessToken.substring(7)
      },
    });

    socket.on("connect", () => {
      setSocketConnected(true)
    });

    socket.on("analysedPart_OrderPart", (data) => {
      handleAnalysedPart(data)
    });

    socket.on("partProductionError_OrderPart", (data) => {
      handlePartProductionError(data)
    });

    socket.on("updateOrderPartFilePaths_OrderPart", (data) => {
      handleUpdateOrderPartFilePath(data)
    });

    socket.on("orderPriceChange_OrderPart", (data) => {
      handleOrderPriceChange(data)
    });

    socket.on("deleteOneOrMoreParts_OrderPart", (data) => {
      handleDeletePartsResponse(data)
    })

    socket.on("updateOnePart_OrderPart", (data) => {
      handleUpdatePartResponse(data)
    })

    socket.on("put-many-order-parts", (data) => {
      handleUpdateManyPartsResponse(data)
    })

    socket.on("connect_error", (err) => {
      if (err.message === "invalid credentials") {
        storage.refreshAccessToken().then(()=>{
          const accessToken = storage.retrieveAccessToken();
          if(accessToken) {
            socket.auth.token = accessToken.substring(7);
            socket.connect();
          }
        })
      }
    });

    socket.on("server-error", (err) => {
      if (err.msg === "invalid credentials") {
        dispatch(forceLogoutUser())
      }
      if(err.event === "updateOnePart_OrderPart" && err.data?.actionId) {
        const actionId = err.data.actionId
        const tmpMap = actionMap
        tmpMap.delete(actionId)
        setActionMap(new Map(tmpMap));
      }
      console.log(err.msg)
    });

    // Destroys the socket reference
    // when the connection is closed
    return () => {
      socket.disconnect();
    };
  }, []);

  const triggerPartProcessing = ({orderId, orderPartId}) => {
    if(socket) {
      socket.emit("triggerPartProcessing_OrderPart", { orderId, orderPartId });
    }
  }

  const removeOneOrMoreParts = (data) => {
    if(socket) {
      socket.emit("deleteOneOrMoreParts_OrderPart", { ...data });
    }
  }

  const triggerUpdatePart = (data) => {
    if(socket) {
      socket.emit("updateOnePart_OrderPart", data);
    }
  }

  const triggerUpdateMultipleParts = (data) => {
    if(socket) {
      socket.emit("put-many-order-parts", data);
    }
  }

  const triggerPartNoteChange = (data) => {
    if(socket) {
      socket.emit("updateOnePartNote_OrderPart", data);
    }
  }

  const triggerChangeManualPartType = (data) => {
    if(socket) {
      socket.emit("updateManualPartType_OrderPart", data);
    }
  }

  const handleDragEnter = e => {
    dragging++
    e.stopPropagation();
    e.preventDefault();

    let dt = e.dataTransfer;
    if (dt.types != null && ((dt.types.length && (dt.types[0] === 'Files' || dt.types[0] === 'application/x-moz-file')))) {
      let dropper = document.getElementsByClassName("fileDropZone")[0]
      if(dropper.style.display === "none" && stageRef.current === "processing" && !isProceedingToShipping && ! isOpenBrowseRef.current) {
        dropper.style.display = "block"
      }
    }
    return false
  };

  useEffect(()=>{
    stageRef.current = stage
  }, [stage])

  useEffect(()=>{
    if(currentlyUploaded < toProcess || currentlyProcessed < toProcess) {
      setWorking(true)
      workingRef.current = true
    }
    else {
      if (workingRef.current) {
        // TODO fetch the order to confirm everything
      }
      setWorking(false)
      workingRef.current = false
    }
  }, [toProcess, currentlyUploaded])

  useEffect(()=>{
    if(socketConnected) {
      if(toAnalyze.length > 0) {
        analyzeParts([...toAnalyze])
        resetToAnalyze()
      }
    }
  }, [socketConnected, toAnalyze])

  const analyzeParts = parts => {
    for(let part of parts) {
      triggerPartProcessing({orderId: order.id, orderPartId: part.id})
    }
  }

  const handleDragLeave = e => {
    dragging--;
    if (dragging === 0) {
      document.getElementsByClassName("fileDropZone")[0].style.display = "none"
    }

    e.stopPropagation();
    e.preventDefault();
    return false
  };

  const handleDrop = e => {
    e.preventDefault();
    dragging=0
    document.getElementsByClassName("fileDropZone")[0].style.display = "none"
  };

  useEffect(()=>{
    if(toProcess !== 0 && currentlyProcessed === toProcess) {
      dispatch(resetToProcess({}))
    }
  }, [currentlyProcessed, toProcess, dispatch])

  useEffect(()=>{
    if(shippingData) {

    }
  }, [shippingData])

  const [isOpenBrowse, _setIsOpenBrowse] = useState(false);
  const isOpenBrowseRef = useRef(null);
  isOpenBrowseRef.current = isOpenBrowse;
  const setIsOpenBrowse = (value) => {
    isOpenBrowseRef.current = value;
    _setIsOpenBrowse(value);
  }

  const handleFocus = useCallback(() => {
    if (isOpenBrowseRef.current) {
      setIsOpenBrowse(false);
    }
  }, []);

  const dateSet = date => {
    let datum = new Date(date)
    if(datum < new Date()) {
      datum = null
    }
    setSelectedDate(date)
  };

  useEffect(() => {
    if(!order.id) {
      const id = new URLSearchParams(history.location.search).get('order')
      if(id) {
        dispatch(fetchDraftOrder({id: id, withRedirect: false})).then((order)=>{
          setSelectableMaterials(new Set([...(order?.selectableMaterials || [])]))
          if(order?.deliveryDate) {
            dateSet(order.deliveryDate)
          }
          if(order?.adjustmentChanged) {
            let hasParts = false
            if(order.parts.length > 0) {
              for(let part of order.parts) {
                if(part.processingType === "automatic" && part.state === "processed") {
                  setShowAdjustmentChangeModal(true)
                  hasParts = true
                  break
                }
              }
            }
            if(!hasParts) {
              handleAdjustmentConfirm(order.id)
            }
          }
        })
      } else {
        history.push('/')
      }
    } else {
      if(order?.deliveryDate) {
        dateSet(order.deliveryDate)
      }
      if(order?.adjustmentChanged) {
        let hasParts = false
        if(order.parts.length > 0) {
          for(let part of order.parts) {
            if(part.processingType === "automatic" && part.state === "processed") {
              setShowAdjustmentChangeModal(true)
              hasParts = true
              break;
            }
          }
        }
        if(!hasParts) {
          handleAdjustmentConfirm(order.id)
        }
      }
      if(filesToUpload.length > 0) {
        let i = 0;
        for(let file of filesToUpload) {
          if (i > 99) {
            break;
          }
          const is2Large = file.size / 1024 / 1024 < 100;
          if (!is2Large) {
            openFileSizeNotification()
          }
          else {
            triggerUpload(file)
          }
          i++
        }
        dispatch(resetFilesToUpload())
      }
    }
  }, [])

  useEffect(() => {
    document.addEventListener("dragenter", handleDragEnter);
    document.addEventListener("dragleave", handleDragLeave);
    document.addEventListener("drop", handleDrop, false);
    window.addEventListener("focus", handleFocus)
    window.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("dragenter", handleDragEnter);
      document.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("drop", handleDrop, false);
      window.removeEventListener("focus", handleFocus)
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  // const handleProcessingCheck = () => {
  //   // TODO fetch the processing parts to check for changes
  // }
  //
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if(workingRef.current) {
  //       handleProcessingCheck()
  //     }
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, [])

  const setLargeThreshold = value => {
    largeThresholdRef.current = value
    _setLargeThreshold(value)
  }

  const setSmallThreshold = value => {
    smallThresholdRef.current = value
    _setSmallThreshold(value)
  }

  const handleResize = () => {
    const width = window.innerWidth
    if(width < 1300) {
      if(!largeThresholdRef.current) {
        setLargeThreshold(true)
      }
    }
    else {
      if(largeThresholdRef.current) {
        setLargeThreshold(false)
      }
    }

    if(width < 750) {
      if(!smallThresholdRef.current) {
        setSmallThreshold(true)
      }
    }
    else {
      if(smallThresholdRef.current) {
        setSmallThreshold(false)
      }
    }

    setModalWidth(width-100);
    setModalHeight(window.innerHeight-135);
  };

  useEffect(()=>{
    if(items) {
      setAllItemsCount(items.length)
      setSelectableItemsCount(items.filter(item => item.state === "processed").length)
    }
  }, [items])

  const triggerUpload = (file) => {
    const id = cuid()
    const uploadTime = Date.now()
    const uploadIndex = parseInt(file.uid.slice(file.uid.lastIndexOf("-")+1))
    dispatch(uploadNewPart({
      file: file,
      data: {
        orderId: order.id,
        fileId: id,
        fileName: file.name,
        uploadTime,
        uploadIndex
      },
      callback: triggerPartProcessing
    }));
  }

  const handleSelectAll = (checked) => {
    if(checked) {
      let map = {}
      let selectable = {}
      let a = null
      for(let item of items) {
        if(item.state === "processed") {
          map[item.id] = true
          selectable[item.id] = new Set([...item.selectableGrades])
          if (!a) {
            a = selectable[item.id]
          } else {
            a = new Set([...a].filter(x => selectable[item.id].has(x)))
          }
        }
      }
      setSelectedItems({...map})
      setSelectedItemsCount(selectableItemsCount)
      setSelectedItemsMaterials({...selectable})

      for(const key in selectable) {
        if (!a) {
          a = selectable[key]
        } else {
          a = new Set([...a].filter(x => selectable[key].has(x)))
        }
      }

      if(selectedParameters.material) {
        if(!a.has(selectedParameters.material.grade)) {
          setSelectedParameters((prev) => ({
            ...prev,
            material: null,
            thickness: null
          }))
          multiformRef.current.setFieldsValue({
            material: null,
            thickness: null,
          })
        } else {
          let thick = [...selectedParameters.material.thickness]
          for (let part of items) {
            if(map[part.id] && part.fileType === "dxf" && part.processingType === "automatic") {
              let thicknesses = part.materials[selectedParameters.material.groupName][selectedParameters.material.grade]
              thick = thick.filter(t => thicknesses[t.id])
            }
          }
          setSelectableThickness(new Set(thick.map(item => item.id)))
          setSelectedParameters((prev) => ({
            ...prev,
            thickness: null
          }))
          multiformRef.current.setFieldsValue({
            thickness: null,
          })
        }
      }

      setSelectableMaterials(a)
    }
    else {
      setSelectedItems({})
      setSelectedItemsCount(0)
      setSelectedItemsMaterials({})
      setSelectableMaterials(new Set([...companySelectableMaterials]))
      if(selectedParameters.material) {
        setSelectableThickness(new Set([...(selectedParameters.material.thickness.map(item => item.id))]))
      } else {
        setSelectableThickness(new Set([]))
      }
    }
    setIndeterminate(false);
  }

  const handleQuantityChange = (data) => {
    if (data.id && !isNaN(parseInt(data.quantity))) {
      const actionId = cuid()
      const data2 = {
        orderId: data.orderId,
        orderPartId: data.id,
        actionId,
        fields: [
          {
            fieldName: "quantity",
            fieldValue: data.quantity
          },
        ]
      }
      updateActions(actionId, true)
      triggerUpdatePart(data2)
    }
    dispatch(changeItemQuantity(data))
  }

  const handleNoteChange = (data) => {
    const data2 = {
      orderId: data.orderId,
      orderPartId: data.id,
      note: data.note
    }
    triggerPartNoteChange(data2)
    dispatch(changeItemNote(data))
  }

  const handleOrderNameChange = (data) => {
    const data2 = {
      orderId: data.orderId,
      name: data.name
    }
    dispatch(changeDraftOrderName(data2))
  }

  const debouncedQuantityChange = useCallback(
    debounce(data => handleQuantityChange(data), 800),
    []
  );

  const debouncedNoteChange = useCallback(
    debounce(data => handleNoteChange(data), 1000),
    []
  );

  const debouncedNameChange = useCallback(
    debounce(data => handleOrderNameChange(data), 1000),
    []
  );

  const changePartColor = (data) => {
    const actionId = cuid()
    const data2 = {
      orderId: order.id,
      orderPartId: data.id,
      actionId,
      fields: [
        {
          fieldName: "coating",
          fieldValue: {
            hasCoating: true,
            colorRAL: data.selectedRAL,
            colorGlossId: data.selectedGloss.toString(),
            ralRow: data.selectedRow,
            ralHex: data.ralHex
          }
        },
      ]
    }

    updateActions(actionId, true)
    triggerUpdatePart(data2)

    dispatch(changeItemColor(data))
  }

  const handleColorRemove = (id) => {
    const actionId = cuid()
    const data2 = {
      orderId: order.id,
      orderPartId: id,
      actionId,
      fields: [
        {
          fieldName: "coating",
          fieldValue: {
            hasCoating: false,
            colorRAL: "",
            colorGlossId: "",
            ralRow: 0,
            ralHex: ""
          }
        },
      ]
    }

    updateActions(actionId, true)
    triggerUpdatePart(data2)
    dispatch(removeItemColor({id}))
  }

  const changePartQuantity = (data) => {
    debouncedQuantityChange(data)
  }

  const changePartNote = (data) => {
    debouncedNoteChange(data)
  }

  const findMaterial = (name) => {
    let rez;
    for(let material of materials) {
      rez = material.grades.find(type => type.grade === name)
      if(rez) {
        rez.groupName = material.groupName
        break;
      }
    }
    return rez
  }

  const findGroup = (name) => {
    let rez;
    for(let material of materials) {
      rez = material.grades.find(type => type.grade === name)
      if(rez) {
        rez = material.groupName
        break
      }
    }
    return rez
  }

  const changePartMaterial = (itemId, materialName, type, processingType, thickness, itemMaterials) => {
    let rez = findMaterial(materialName);

    if (type === "step" && processingType === "automatic") {
      let thick = rez.thickness.find(t => parseFloat(t.thickness) === parseFloat(thickness))
      rez.selectedMaterial = {id: thick.id, thickness: thick.thickness}
    } else {
      if(type === "dxf" && processingType === "automatic") {
        rez.thickness = rez.thickness.filter(item => itemMaterials?.[rez.groupName]?.[rez.grade]?.[item.id])
      }
      let thick = rez.thickness.find(t => parseFloat(t.thickness) === parseFloat(thickness))
      rez.selectedMaterial = {
        id: thick ? thick.id : rez.thickness[0].id,
        thickness: thick ? thick.thickness : rez.thickness[0].thickness
      }
    }

    if(rez?.selectedMaterial?.id) {
      const actionId =  cuid()
      const data = {
        orderId: order.id,
        orderPartId: itemId,
        actionId,
        fields: [
          {
            fieldName: "material",
            fieldValue: rez.selectedMaterial.id
          },
        ]
      }
      updateActions(actionId, true)
      triggerUpdatePart(data)
    }
    dispatch(changeItemMaterial({id: itemId, material: rez}))
  }

  const handleMultiMaterialSelect = (materialName) => {
    let rez = findMaterial(materialName);
    if(rez) {
      let a = [...rez.thickness]
      for (let part of items) {
        if(selectedItems[part.id] && part.fileType === "dxf" && part.processingType === "automatic") {
          let thicknesses = part.materials[rez.groupName][rez.grade]
          a = a.filter(t => thicknesses[t.id])
        }
      }
      let b = [...a]
      setSelectableThickness(new Set(b.map(item => item.id)))
      let oldThickness = selectedParameters.thickness
      setSelectedParameters((prev) => ({
        ...prev,
        material: rez
      }))
      if(!oldThickness || !a.some(e => e.thickness === oldThickness)) {
        setSelectedParameters((prev) => ({
          ...prev,
          thickness: null
        }))
        multiformRef.current.setFieldsValue({
          thickness: null
        })
      }
    }
  }

  const handleMultiThicknessSelect = (thickness) => {
    setSelectedParameters((prev) => ({
      ...prev,
      thickness: thickness
    }))
  }

  const handleMultiQuantityChange = (q) => {
    setSelectedParameters((prev) => ({
      ...prev,
      quantity: q
    }))
  }

  const changePartThickness = (data) => {
    const actionId =  cuid()
    const data2 = {
      orderId: order.id,
      orderPartId: data.id,
      actionId,
      fields: [
        {
          fieldName: "material",
          fieldValue: data.thickness.id
        },
      ]
    }
    updateActions(actionId, true)
    triggerUpdatePart(data2)
    dispatch(changeItemThickness(data))
  }

  const handleMultiThicknessClear = () => {
    setSelectedParameters((prev) => ({
      ...prev,
      thickness: null
    }))
  }

  const handleMultiMaterialClear = () => {
    setSelectedParameters((prev) => ({
      ...prev,
      material: null,
      thickness: null
    }))
    multiformRef.current.setFieldsValue({
      thickness: null
    })
  }

  const handleMultiClear = () => {
    setSelectedParameters({
      material: null,
      thickness: null,
      quantity: null,
      coating: {
        hasCoating: false,
        colorRAL: undefined,
        colorGlossId: undefined,
        ralRow: undefined,
        ralHex: undefined,
      }
    })
    multiformRef.current.setFieldsValue({
      material: null,
      thickness: null,
      quantity: null
    })
  }

  const handleMultiColorClear = () => {
    setSelectedParameters((prev) => ({
      ...prev,
      coating: {
        hasCoating: false,
        colorRAL: undefined,
        colorGlossId: undefined,
        ralRow: undefined,
        ralHex: undefined,
      }
    }))
  }

  const handleMultiColorSelect = (item) => {
    setSelectedParameters((prev) => ({
      ...prev,
      coating: {
        hasCoating: true,
        colorRAL: item.selectedRAL,
        colorGlossId: item.selectedGloss,
        ralRow: item.selectedRow,
        ralHex: item.ralHex,
      }
    }))
  }

  const handleMaterialFilter = (item, checked) => {
    let a = null

    if(checked) {
      a = new Set(item.selectableGrades)
      a = new Set([...a].filter(x => selectableMaterials.has(x)))
      setSelectedItemsMaterials((prev)=>({...prev, [item.id]: new Set(item.selectableGrades)}))
    }
    else {
      const tmpSelectedItemsMaterials = {...selectedItemsMaterials}
      delete tmpSelectedItemsMaterials[item.id]
      for(const key in tmpSelectedItemsMaterials) {
        if (!a) {
          a = selectedItemsMaterials[key]
        } else {
          a = new Set([...a].filter(x => selectedItemsMaterials[key].has(x)))
        }
      }
      setSelectedItemsMaterials({...tmpSelectedItemsMaterials})
    }
    if(!a) {
      a = new Set([...companySelectableMaterials])
    }
    if(selectedParameters.material) {
      if(!a.has(selectedParameters.material.grade)) {
        setSelectedParameters((prev) => ({
          ...prev,
          material: null,
          thickness: null
        }))
        multiformRef.current.setFieldsValue({
          material: null,
          thickness: null,
        })
      } else {
        if(item.fileType === "dxf" && item.processingType === "automatic") {
          if (checked) {
            let newThicknesses = [...selectableThickness]
            let thicknesses = item.materials[selectedParameters.material.groupName][selectedParameters.material.grade]
            newThicknesses = newThicknesses.filter(t => thicknesses[t])
            setSelectableThickness(new Set(newThicknesses))
            setSelectedParameters((prev) => ({
              ...prev,
              thickness: null
            }))
            multiformRef.current.setFieldsValue({
              thickness: null,
            })
          } else {
            let thick = [...selectedParameters.material.thickness]
            for (let part of items) {
              if(selectedItems[part.id] && part.fileType === "dxf" && part.processingType === "automatic" && part.id !== item.id) {
                let thicknesses = part.materials[selectedParameters.material.groupName][selectedParameters.material.grade]
                thick = thick.filter(t => thicknesses[t.id])
              }
              setSelectableThickness(new Set(thick.map(item => item.id)))
            }
          }
        }
      }
    }
    setSelectableMaterials(a)
  }

  const handleRemoveItem = (id) => {
    const actionId =  cuid()
    updateActions(actionId, true)
    const data = {
      actionId,
      orderId: order.id,
      partIds: [id]
    }
    removeOneOrMoreParts(data)
    dispatch(removeItem({id}))
    if (selectedItems[id]) {
      let map = {...selectedItems}
      delete map[id]
      setSelectedItems({...map})
      setSelectedItemsCount(prev => prev - 1)
      setSelectedAllItems(false)
      setIndeterminate(Object.keys(map).length !== 0)
      handleMaterialFilter({id}, false)
    }
  }

  // useEffect(()=>{
  //   if(order.stage === "processing") {
  //     console.log(actionMap) //TODO Recheck price
  //   }
  // }, [actionMap])

  const handleRemoveMultipleItems = () => {
    const actionId =  cuid()
    updateActions(actionId, true)
    const data = {
      actionId,
      orderId: order.id,
      partIds: []
    }

    dispatch(removeMultipleItems({selected: {...selectedItems}}))
    let map = {...selectedItems}
    for(let key of Object.keys(selectedItems)) {
      if(selectedItems[key]) {
        data.partIds.push(key)
        delete map[key]
      }
    }
    removeOneOrMoreParts(data)
    setSelectedItems({...map})
    setSelectedItemsCount(0)
    setSelectedAllItems(false)
    setIndeterminate(false)
    setSelectedItemsMaterials({})
    setSelectableMaterials(new Set([...companySelectableMaterials]))
    setSelectableThickness(new Set([]))
  }

  const handleMultiApply = () => {
    const applyingChanges = {}
    const partsToSend = {}
    const { thickness, material, quantity, coating } = selectedParameters;
    if(material || quantity || coating?.hasCoating) {
      for (let item of items) {
        if(selectedItems[item.id]) {
          let newMaterial = {};
          if (material) {
            if (item.fileType === 'step' && item.processingType === "automatic") {
              let rez = material.thickness.find(thick => parseFloat(thick.thickness) === parseFloat(item.selectedMaterial.thickness))
              newMaterial = {
                grade: material.grade,
                materialId: rez.id,
                thickness: rez.thickness
              }
              newMaterial.availableThicknesses = [{id: rez.id, thickness: rez.thickness}]
            } else {
              let availableThicknesses = getAvailableThicknesses(item.fileType, item.processingType, materials, material, item.materials)
              if (thickness) {
                let rez = material.thickness.find(thick => parseFloat(thick.thickness) === parseFloat(thickness))
                newMaterial = {
                  grade: material.grade,
                  materialId: rez.id,
                  thickness: rez.thickness
                }
              } else {
                let rez = availableThicknesses.find(thick => parseFloat(thick.thickness) === parseFloat(item.selectedMaterial.thickness))
                newMaterial = {
                  grade: material.grade,
                  materialId: rez ? rez.id : availableThicknesses[0].id,
                  thickness: rez ? item.thickness : availableThicknesses[0].thickness
                }
              }

              newMaterial.availableThicknesses = availableThicknesses
            }
          }
          let tmpCoating = coating
          if(coating.hasCoating) {
            if(!item.surfaceCoatingParams?.hasSurfaceCoating) {
              tmpCoating = {
                hasCoating: false,
                colorRAL: "",
                colorGlossId: "",
                ralRow: "",
                ralHex: ""
              }
            }
          }

          applyingChanges[item.id] = { material: newMaterial, quantity, coating: tmpCoating }
          let fields = []
          if(newMaterial.materialId) {
            fields.push({
              fieldName: "material",
              fieldValue: newMaterial.materialId
            })
          }

          if(quantity) {
            fields.push({
              fieldName: "quantity",
              fieldValue: quantity
            })
          }

          if(tmpCoating.hasCoating) {
            fields.push({
              fieldName: "coating",
              fieldValue: {
                hasCoating: true,
                colorRAL: coating.colorRAL,
                colorGlossId: coating.colorGlossId.toString(),
                ralRow: coating.ralRow,
                ralHex: coating.ralHex
              }
            })
          }

          if(fields.length === 0) {
            delete applyingChanges[item.id]
            continue
          }

          partsToSend[item.id] = fields
        }
      }
      const actionId = cuid()
      updateActions(actionId, true)
      triggerUpdateMultipleParts({
        orderId: order.id,
        actionId,
        parts: partsToSend
      })
      dispatch(applyMultipleItems({applyingChanges}))
    }

  }

  const changeOrderName = (data) => {
    debouncedNameChange(data)
  }

  const showItem = (item) => {
    setItemView({...item, viewType: "model"})
    setViewVisible(true)
  }

  const cancelShowItem = ()  => {
    setItemView(null)
    setViewVisible(false)
  }

  const handleDateChange = date => {
    let datum = new Date(date)
    if(datum < new Date()) {
      datum = null
    }
    setSelectedDate(datum)
    if(datum) {
      dispatch(changeDeliveryDate({orderId: order.id, deliveryDate: datum.toISOString(), dateTimestamp: datum.getTime()}))
    }
  };

  const openFileSizeNotification = () => {
    notification.error({
      key: "file2Large",
      placement: "bottomRight",
      message: (<div style={{fontWeight: 600}}>File Too Large</div>),
      description: (
        <div style={{fontSize: 13, fontWeight: 500}}>
          Some of the files are above the allowed 100MB file size limit.
        </div>
      )
    });
  };

  const proceedToShipping = () => {
    setSelectedDate(order.deliveryDate)
    dispatch(changeStageToShipping({orderId: order.id}))
  }

  const proceedToConfirmation = (data) => {
    const shippingData = {
      address: data.address,
      city: data.city,
      country: data.country,
      postalCode: data.postalCode,
      phone: data.phone
    }

    dispatch(changeStageToConfirmation({orderId: order.id, shippingData}))
  }

  const returnToStage = (stage) => {
    dispatch(changeToPreviousStage({orderId: order.id, stage}))
  }

  const handleRemoveErrorParts = () => {
    const partIds = errorItems.map(item => item.id)
    dispatch(removeErrorParts({orderId: order.id, partIds}))
  }

  const showDetailsItem = (item) => {
    let groupName = findGroup(item.selectedMaterial.grade)
    setItemView({...item, selectedMaterial: {...item.selectedMaterial, groupName}, viewType: "details"})
    setViewVisible(true)
  }

  const showOtherItem = (item) => {
    if(item.partType === "sheetMetal" || item.partType === "machined" || item.partType === "profile") {
      let groupName = findGroup(item.selectedMaterial.grade)
      setOtherItemView({...item, selectedMaterial: {...item.selectedMaterial, groupName}})
    }
    else {
      setOtherItemView(item)
    }
    setViewOtherVisible(true)
  }

  const closeOtherView = () => {
    setOtherItemView(null)
    setViewOtherVisible(false)
  }

  const handlePlaceOrder = () => {
    dispatch(placeOrder({orderId: order.id})).catch((error)=>{
      let data = error.response?.data?.msg
      dispatch(changeAdjustments(data))
      let hasParts = false
      if(order.parts.length > 0) {
        for(let part of order.parts) {
          if(part.processingType === "automatic" && part.state === "processed") {
            setShowAdjustmentChangeModal(true)
            hasParts = true
            break;
          }
        }
      }
      if(!hasParts) {
        dispatch(confirmAdjustmentChange({orderId: order.id})).then(()=>{
          dispatch(placeOrder({orderId: order.id}))
        })
      }
    })
  }

  const handleChangeManualItemType = (id, partType) => {
    triggerChangeManualPartType({orderId: order.id, orderPartId: id, partType})
    dispatch(changeManualItemType({id, partType}))
  }

  const checkSelectedDate = (date) => {
    if(date < Date.now()) {
      return null
    }
    return date
  }

  const handleAdjustmentConfirm = (id) =>{
    setShowAdjustmentChangeModal(false)
    dispatch(confirmAdjustmentChange({orderId: id}))
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <div style={{position: 'fixed', top:0, width: '100%', zIndex: 10000 }}>
        {(working || isChangingDraftStage || isChangingDeliveryDate || isChangingDraftName || actionMap.size > 0) &&
          <div style={{marginTop: -8}}>
            <Progress className={"progress"} percent={100} status="active" showInfo={false}/>
          </div>
        }
      </div>
      <Sidebar onLogout={()=>{dispatch(logoutUser())}} user={user}/>
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}} type="flex">
        <Spin
          spinning={isFetchingOrder || !socketConnected}
          wrapperClassName={"fetchingOrderSpin"}
          indicator={
            <div style={{position: "absolute", top: "35%", left: "50%", width: 400, transform: "translateX(-50%)"}}>
              <LoadingOutlined style={{ fontSize: 72 }} spin />
              <div style={{fontSize: 27, fontWeight: 500, marginTop: 10}}>Fetching Your Order</div>
            </div>
          }
        >
          <div style={{overflowX: 'hidden', height: "100%"}}>
            <div style={{marginRight: 15, marginBottom: 15, marginLeft: 25, marginTop: 15, minHeight: "calc(100vh - 30px)"}}>
              {orderId &&
              <Row wrap={false} style={{height: '100%', minHeight: "calc(100vh - 30px)"}}>
                <Col span={largeThreshold ? 24 : 18}>
                  <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
                    <div style={{display: 'flex', alignItems: "center"}}>
                      <div className={"text14-500"} style={{fontSize: 20, paddingRight: 15, borderRight: "1px dotted rgba(0, 0, 0, 0.22)"}}>
                        Order {orderName}
                      </div>
                    </div>
                    <div style={{marginRight: 8, display: 'flex', alignItems: "center"}}>
                      <div style={{ marginRight: 8, fontWeight: 600, whiteSpace: "nowrap", marginLeft: 40 }}>
                        Custom Order Name:
                      </div>
                      <Input
                        size={'large'}
                        className={"text12-600"}
                        placeholder={"e.g. Order 1"}
                        defaultValue={order.customName}
                        onChange={(e) => changeOrderName({name: e.target.value, orderId: order.id})}
                      />
                      <Tooltip
                        overlayClassName={"name-tooltip"}
                        placement={'bottom'}
                        overlayStyle={{width: 300}}
                        getPopupContainer={trigger => trigger.parentNode}
                        color={"rgba(82,82,82,0.9)"}
                        title={
                          <span className={"text12-500"} style={{color: "white"}}>
                            This is used only as a reference to help you keep track of orders more easily.
                          </span>
                        }
                      >
                        <QuestionCircleFilled style={{fontSize: 20, marginLeft: 5, color: "#898989"}}/>
                      </Tooltip>
                    </div>
                  </div>
                  <MiDivider style={{marginLeft: -25, marginBottom: 15, marginTop: 15}}/>
                  <div style={{paddingBottom: 15, marginRight: 10, display: largeThreshold ? 'block' : 'none'}}>
                    <Card
                      style={{borderRadius: 5, border: '1px solid #c0c0c0'}}
                      bodyStyle={{padding: "25px 10px 10px 10px"}}
                    >
                      <OrderProgress stage={stage} goToStage={returnToStage}/>
                      <div style={{marginLeft: 15, width: "calc(100% - 30px)"}}>
                        <Divider className={"orderDivider"}/>
                        {selectableItemsCount > 0 &&
                          <div>
                            <div className={"text14-600"} style={{fontSize: 19, lineHeight: 1.4}}>
                              Order summary
                            </div>
                            {stage === "shipping" &&
                              <div>
                                <div
                                  style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 10}}
                                >
                                  <div>
                                    <span className={"text14-400"}>Shipping</span>
                                  </div>

                                    <div className={"text12-600"} style={{lineHeight: 1.8}}>
                                      Manually by RFQ
                                    </div>

                                </div>
                                <Divider className={"orderDivider"} style={{marginBottom: 10, marginTop: 10}}/>
                              </div>
                            }
                            <div style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 10}}>
                              <div>
                                <span className={"text14-400"}>Total</span>
                              </div>
                              <div className={"text14-600"} style={{lineHeight: 1.5}}>
                                {((totalPrice  || 0) * calcAdjustment(adjustment)).toFixed(2)} € {needsManualQuote && "+ RFQ"}
                              </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 10}}>
                              <div>
                                <span className={"text14-400"}>Total with VAT</span>
                              </div>
                              <div className={"text14-600"} style={{lineHeight: 1.5}}>
                                {((totalPrice * (1 + vatValue) || totalPrice || 0)  * calcAdjustment(adjustment)).toFixed(2)} € {needsManualQuote && "+ RFQ"}
                              </div>
                            </div>
                            <div align={"center"} style={{marginTop: 20, marginBottom: 20, width: "100%"}}>
                              {errorItems?.length > 0 && stage === "processing" &&
                                <div style={{
                                  borderRadius: 4,
                                  backgroundColor: "#FFC4C7",
                                  marginBottom: 12,
                                  padding: 10,
                                  fontWeight: 600,
                                  fontSize: 12,
                                  color: "#333f48",
                                  textAlign: "left"
                                }}
                                >
                                  Remove invalid files from list and re-upload if they are fixed.
                                </div>
                              }
                              {stage === "processing" &&
                                <div>
                                  {needsManualQuote ?
                                    <div style={{backgroundColor: "#D3E9FF", borderRadius: 5}}>
                                      <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "15px 15px 10px 15px"
                                      }}>
                                    <span>
                                      <ManualQuote/>
                                    </span>
                                        <span style={{
                                          display: "flex",
                                          fontSize: 14,
                                          fontWeight: 600,
                                          marginLeft: 10,
                                          alignItems: "center",
                                          color: "#333f48"
                                        }}
                                        >
                                      Automated quote is not available
                                    </span>
                                      </div>
                                      <div style={{
                                        boxShadow: "inset 0 1px 50px 0 rgb(155 185 216 / 50%)",
                                        padding: "10px 15px 15px 15px",
                                        borderRadius: "0 0 5px 5px"
                                      }}
                                      >
                                        <div
                                          style={{paddingBottom: 10, fontSize: 12, fontWeight: 600, color: "#333f48"}}>
                                          We can still quote your order manually.
                                          <br/>
                                          Proceed and we will send you a quote later.
                                        </div>
                                        <div>
                                          <Button
                                            type={"primary"}
                                            size={'large'}
                                            style={{width: '100%'}}
                                            disabled={selectableItemsCount < 1 || working || isChangingDraftStage || actionMap.size > 0}
                                            onClick={() => proceedToShipping()}
                                          >
                                            <span className={"nextStepButtonText"}>Proceed to shipping</span>
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <Button
                                      type={"primary"}
                                      size={'large'}
                                      style={{width: '100%'}}
                                      disabled={selectableItemsCount < 1 || working || isChangingDraftStage || actionMap.size > 0}
                                      onClick={() => proceedToShipping()}
                                    >
                                      <span className={"nextStepButtonText"}>Proceed to shipping</span>
                                    </Button>
                                  }
                                </div>
                              }
                              {stage === "shipping" &&
                                <div style={{display: "flex"}}>
                                  <Button
                                    type={"default"}
                                    size={'large'}
                                    style={{width: '100%', marginBottom: 5, marginRight: 8, color: "#1890ff"}}
                                    disabled={isChangingDraftStage || isChangingDeliveryDate}
                                    onClick={()=>returnToStage("processing")}
                                  >
                                      <span>
                                        <ChevronLeftRoundedIcon style={{marginTop: -10, marginBottom: -6, fontSize: 22}}/> <span className={"nextStepButtonText"}>Return to Processing</span>
                                      </span>
                                  </Button>
                                  <Button
                                    type={"primary"}
                                    size={'large'}
                                    style={{width: '100%', marginLeft: 8}}
                                    disabled={isChangingDraftStage || isChangingDeliveryDate}
                                    form={"shippingForm"}
                                    key={"submit"}
                                    htmlType={"submit"}
                                  >
                                      <span>
                                        <span className={"nextStepButtonText"}>Proceed to Confirmation </span> <ChevronRightRoundedIcon style={{marginTop: -10, marginBottom: -7, fontSize: 22}}/>
                                      </span>
                                  </Button>
                                </div>
                              }
                              {stage === "confirmation" &&
                                <div style={{display: "flex"}}>
                                  <Button
                                    type={"default"}
                                    size={'large'}
                                    style={{width: '100%', marginBottom: 5, marginRight: 8, color: "#1890ff"}}
                                    disabled={isChangingDraftStage}
                                    onClick={()=>returnToStage("shipping")}
                                  >
                                      <span style={{marginLeft: -25}}>
                                        <ChevronLeftRoundedIcon style={{marginTop: -10, marginBottom: -6, fontSize: 22}}/> <span className={"nextStepButtonText"}>Return to Shipping</span>
                                      </span>
                                  </Button>
                                  <Button
                                    type={"primary"}
                                    size={'large'}
                                    className={isChangingDraftStage ? "placeOrderButtonDisabled" : "placeOrderButton"}
                                    style={{width: '100%', marginLeft: 8}}
                                    disabled={isChangingDraftStage}
                                    onClick={()=>{
                                      handlePlaceOrder()
                                    }}
                                  >
                                      <span>
                                        <FileDoneOutlined style={{fontSize: 18, marginTop: 3}}/>
                                        <span
                                          className={"nextStepButtonText"}
                                          style={{marginLeft: 5}}
                                        >
                                          {needsManualQuote ? "Request Quotation" : "Place Order"}
                                        </span>
                                      </span>
                                  </Button>
                                </div>
                              }
                              <div style={{marginTop: 10, fontWeight: 600, fontSize: 12, color: "#0072ce"}}>
                                {(working || actionMap.size > 0 || isChangingDeliveryDate || isChangingDraftName) ?
                                  "Saving Draft..."
                                  :
                                  "Draft saved"
                                }
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </Card>
                  </div>
                  {stage === 'processing' &&
                    <OrderProcessingStage
                      orderId={orderId}
                      largeThreshold={largeThreshold}
                      allItemsCount={allItemsCount}
                      errorItems={errorItems}
                      screens={screens}
                      openFileSizeNotification={openFileSizeNotification}
                      triggerUpload={triggerUpload}
                      smallThreshold={smallThreshold}
                      toProcess={toProcess}
                      currentlyUploaded={currentlyUploaded}
                      currentlyProcessed={currentlyProcessed}
                      selectableItemsCount={selectableItemsCount}
                      handleRemoveMultipleItems={handleRemoveMultipleItems}
                      selectedAllItems={selectedAllItems}
                      setSelectedAllItems={setSelectedAllItems}
                      handleSelectAll={handleSelectAll}
                      indeterminate={indeterminate}
                      selectedItemsCount={selectedItemsCount}
                      handleMultiMaterialSelect={handleMultiMaterialSelect}
                      handleMultiMaterialClear={handleMultiMaterialClear}
                      materials={materials}
                      selectableMaterials={selectableMaterials}
                      selectableThickness={selectableThickness}
                      multiformRef={multiformRef}
                      selectedParameters={selectedParameters}
                      handleMultiThicknessSelect={handleMultiThicknessSelect}
                      handleMultiThicknessClear={handleMultiThicknessClear}
                      handleMultiQuantityChange={handleMultiQuantityChange}
                      handleMultiColorSelect={handleMultiColorSelect}
                      handleMultiColorClear={handleMultiColorClear}
                      handleMultiClear={handleMultiClear}
                      handleMultiApply={handleMultiApply}
                      handleRemoveErrorParts={handleRemoveErrorParts}
                      items={items}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                      setSelectedItemsCount={setSelectedItemsCount}
                      handleMaterialFilter={handleMaterialFilter}
                      handleRemoveItem={handleRemoveItem}
                      changePartMaterial={changePartMaterial}
                      changePartThickness={changePartThickness}
                      changePartQuantity={changePartQuantity}
                      changePartNote={changePartNote}
                      changePartColor={changePartColor}
                      handleColorRemove={handleColorRemove}
                      showItem={showItem}
                      handleChangeManualItemType={handleChangeManualItemType}
                      onUploadClick={() => {setIsOpenBrowse(true)}}
                      adjustment={adjustment}
                    />
                  }
                  {stage === 'shipping' &&
                    <OrderShippingStage
                      largeThreshold={largeThreshold}
                      user={user}
                      order={order}
                      proceedToConfirmation={proceedToConfirmation}
                      selectedDate={checkSelectedDate(selectedDate)}
                      handleDateChange={handleDateChange}
                      screens={screens}
                      isChangingDeliveryDate={isChangingDeliveryDate}
                      isChangingDraftStage={isChangingDraftStage}
                    />
                  }
                  {stage === 'confirmation' &&
                    <OrderConfirmationStage
                      largeThreshold={largeThreshold}
                      user={user}
                      order={order}
                      needsManualQuote={needsManualQuote}
                      vatValue={vatValue}
                      totalPrice={totalPrice}
                      parts={items}
                      showOtherItem={showOtherItem}
                      showDetailsItem={showDetailsItem}
                      isChangingDraftStage={isChangingDraftStage}
                    />
                  }
                </Col>
                <MiDivider
                  orientation="vertical"
                  flexItem
                  style={{marginTop: -20, marginBottom: -15, display: largeThreshold ? 'none' : 'block'}}
                />
                <Col flex={"auto"} style={{display: largeThreshold ? 'none' : 'block'}}>
                  <div style={{marginLeft: 20, marginTop: -15}}>
                    <Sticky
                      scrollElement="window"
                    >
                      <div style={{paddingTop: 20}}>
                        <Card
                          style={{borderRadius: 5, border: '1px solid #c0c0c0'}}
                          bodyStyle={{padding: "25px 10px 5px 10px"}}
                        >
                          <OrderProgress stage={stage} goToStage={returnToStage}/>
                          <div style={{marginLeft: 15, width: "calc(100% - 30px)"}}>
                            <Divider className={"orderDivider"} style={{marginBottom: 10}}/>
                            {selectableItemsCount > 0 &&
                            <div>
                              <div className={"text14-600"} style={{fontSize: 19, lineHeight: 1.4}}>
                                Order summary
                              </div>
                              <div
                                style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 20}}
                              >
                                <div>
                                  <span className={"text14-400"}>Processing</span>
                                </div>
                                <div className={"text12-600"} style={{lineHeight: 1.8}}>
                                  {((processingPrice || 0) * calcAdjustment(adjustment)).toFixed(2)} € {needsManualQuote && "+ RFQ"}
                                </div>
                              </div>
                              <div
                                style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 10}}
                              >
                                <div>
                                  <span className={"text14-400"}>Shipping</span>
                                </div>
                                {stage === "processing" &&
                                  <div className={"text12-600"} style={{lineHeight: 1.8}}>
                                    Calculated in next step
                                  </div>
                                }
                                {(stage === "shipping" || stage === "confirmation") &&
                                  <div className={"text12-600"} style={{lineHeight: 1.8}}>
                                    Manually by RFQ
                                  </div>
                                }
                              </div>
                              <Divider className={"orderDivider"} style={{marginBottom: 10, marginTop: 10}}/>
                              <div
                                style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 10}}>
                                <div>
                                  <span className={"text14-400"}>Total</span>
                                </div>
                                <div className={"text12-600"} style={{lineHeight: 1.8}}>
                                  {((totalPrice  || 0) * calcAdjustment(adjustment)).toFixed(2)} € {needsManualQuote && "+ RFQ"}
                                </div>
                              </div>
                              <div
                                style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 10}}>
                                <div>
                                  <span className={"text14-400"}>Total with VAT</span>
                                </div>
                                <div className={"text12-600"} style={{lineHeight: 1.8}}>
                                  {((totalPrice * (1 + vatValue) || totalPrice || 0) * calcAdjustment(adjustment)).toFixed(2)} € {needsManualQuote && "+ RFQ"}
                                </div>
                              </div>
                              <div align={'center'} style={{marginTop: 20, marginBottom: 20, width: '100%'}}>
                                {errorItems?.length > 0 && stage === "processing" &&
                                  <div style={{
                                    borderRadius: 4,
                                    backgroundColor: "#FFC4C7",
                                    marginBottom: 12,
                                    padding: 10,
                                    fontWeight: 600,
                                    fontSize: 12,
                                    color: "#333f48",
                                    textAlign: "left"
                                  }}
                                  >
                                    Remove invalid files from list and re-upload if they are fixed.
                                  </div>
                                }
                                {stage === "processing" &&
                                  <div>
                                    {needsManualQuote ?
                                      <div style={{backgroundColor: "#D3E9FF", borderRadius: 5}}>
                                        <div align={'left'} style={{display: "flex", padding: "15px 15px 10px 15px"}}>
                                      <span>
                                        <ManualQuote/>
                                      </span>
                                          <span style={{
                                            display: "flex",
                                            fontSize: 14,
                                            fontWeight: 600,
                                            marginLeft: 10,
                                            alignItems: "center",
                                            color: "#333f48"
                                          }}
                                          >
                                        Full automated quote is not available
                                      </span>
                                        </div>
                                        <div style={{
                                          boxShadow: "inset 0 1px 50px 0 rgb(155 185 216 / 50%)",
                                          padding: "10px 15px 15px 15px",
                                          borderRadius: "0 0 5px 5px"
                                        }}
                                        >
                                          <div style={{paddingBottom: 10, fontSize: 12, fontWeight: 600, color: "#333f48"}}>
                                            We can still quote your order manually.
                                            <br/>
                                            Proceed and we will send you a quote later.
                                          </div>
                                          <div>
                                            <Button
                                              type={"primary"}
                                              size={'large'}
                                              style={{width: '100%'}}
                                              disabled={selectableItemsCount < 1 || working || isChangingDraftStage || actionMap.size > 0}
                                              onClick={() => proceedToShipping()}
                                            >
                                              <span className={"nextStepButtonText"}>Proceed to shipping</span>
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <Button
                                        type={"primary"}
                                        size={'large'}
                                        style={{width: '100%'}}
                                        disabled={selectableItemsCount < 1 || working || isChangingDraftStage || actionMap.size > 0}
                                        onClick={() => proceedToShipping()}
                                      >
                                        <span className={"nextStepButtonText"}>Proceed to shipping</span>
                                      </Button>
                                    }
                                  </div>
                                }
                                {stage === "shipping" &&
                                  <div>
                                    <Button
                                      type={"default"}
                                      size={'large'}
                                      style={{width: '100%', marginBottom: 10, color: "#1890ff"}}
                                      disabled={isChangingDeliveryDate || isChangingDraftStage}
                                      onClick={()=>returnToStage("processing")}
                                    >
                                      <span style={{marginLeft: -25}}>
                                        <ChevronLeftRoundedIcon style={{marginTop: -10, marginBottom: -6, fontSize: 22}}/> <span className={"nextStepButtonText"}>Return to Processing</span>
                                      </span>
                                    </Button>
                                    <Button
                                      type={"primary"}
                                      size={'large'}
                                      style={{width: '100%'}}
                                      disabled={isChangingDeliveryDate || isChangingDraftStage}
                                      form={"shippingForm"}
                                      key={"submit"}
                                      htmlType={"submit"}
                                    >
                                      <span style={{marginRight: -25}}>
                                        <span className={"nextStepButtonText"}>Proceed to Confirmation </span> <ChevronRightRoundedIcon style={{marginTop: -10, marginBottom: -7, fontSize: 22}}/>
                                      </span>
                                    </Button>
                                  </div>
                                }
                                {stage === "confirmation" &&
                                  <div>
                                    <Button
                                      type={"default"}
                                      size={'large'}
                                      style={{width: '100%', marginBottom: 10, color: "#1890ff"}}
                                      disabled={isChangingDraftStage}
                                      onClick={()=>returnToStage("shipping")}
                                    >
                                      <span style={{marginLeft: -25}}>
                                        <ChevronLeftRoundedIcon style={{marginTop: -10, marginBottom: -6, fontSize: 22}}/> <span className={"nextStepButtonText"}>Return to Shipping</span>
                                      </span>
                                    </Button>
                                    <Button
                                      type={"primary"}
                                      size={'large'}
                                      className={"placeOrderButton"}
                                      style={{width: '100%'}}
                                      disabled={isChangingDraftStage}
                                      onClick={()=>{
                                        handlePlaceOrder()
                                      }}
                                    >
                                      <span>
                                        <FileDoneOutlined style={{fontSize: 18, marginTop: 3}}/>
                                        <span
                                          className={"nextStepButtonText"}
                                          style={{marginLeft: 5}}
                                        >
                                          {needsManualQuote ? "Request Quotation" : "Place Order"}
                                        </span>
                                      </span>
                                    </Button>
                                  </div>
                                }
                                <div style={{marginTop: 10, fontWeight: 600, fontSize: 12, color: "#0072ce"}}>
                                  {(working || actionMap.size > 0 || isChangingDeliveryDate || isChangingDraftName)  ?
                                    "Saving Draft..."
                                    :
                                    "Draft saved"
                                  }
                                </div>
                              </div>
                            </div>
                            }
                          </div>
                        </Card>
                      </div>
                    </Sticky>
                  </div>
                </Col>
              </Row>
              }
            </div>
          </div>
        </Spin>


        <ItemVisualisation
          isModelViewVisible={viewVisible}
          itemView={itemView}
          modalWidth={modalWidth}
          modalHeight={modalHeight}
          onCloseView={cancelShowItem}
          adjustment={order.adjustment}
        />
        <Modal
          visible={viewOtherVisible}
          centered={true}
          onCancel={closeOtherView}
          title="Part Details"
          footer={null}
          width={600}
        >
          {otherItemView &&
            <div style={{width: "100%"}}>
              <Card
                className={"whiteModalCard"}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 5,
                  border: "1px solid rgb(215, 215, 215)",
                  boxShadow: "rgba(0, 0, 0, 0.07) 0 2px 4px 1px"
                }}
              >
                <div>
                  <div style={{fontSize: 20, fontWeight: 600, marginBottom: 10}}>{otherItemView.fileName}</div>
                  {otherItemView.partType === "sheetMetal" &&
                    <div>
                      <div className={"orderItemDetailsLabel"}>Material Group:</div>
                      <div className={"orderItemDetailsValue"}>{otherItemView.selectedMaterial.groupName}</div>
                      <div className={"orderItemDetailsLabel"}>Material Grade:</div>
                      <div className={"orderItemDetailsValue"}><Trans>{otherItemView.selectedMaterial.grade}</Trans></div>
                      <div className={"orderItemDetailsLabel"}>Thickness:</div>
                      <div className={"orderItemDetailsValue"}>{otherItemView.selectedMaterial.thickness}mm</div>
                    </div>
                  }
                  {(otherItemView.partType === "machined" || otherItemView.partType === "profile")  &&
                    <div>
                      <div className={"orderItemDetailsLabel"}>Material Group:</div>
                      <div className={"orderItemDetailsValue"}>{otherItemView.selectedMaterial.groupName}</div>
                      <div className={"orderItemDetailsLabel"}>Material Grade:</div>
                      <div className={"orderItemDetailsValue"}><Trans>{otherItemView.selectedMaterial.grade}</Trans></div>
                    </div>
                  }
                  <div className={"orderItemDetailsLabel"}>Quantity:</div>
                  <div className={"orderItemDetailsValue"}>{otherItemView.quantity}</div>
                  {otherItemView.coating?.hasCoating ?
                    <div style={{marginRight: 30, display: "flex"}}>
                      <div style={{marginTop: 10, marginLeft: 2, marginRight: 5}}>
                        <DropIcon style={{color: otherItemView.coating.ralHex, fontSize: 20}}/>
                      </div>
                      <div>
                        <div className={"orderItemDetailsLabel"}>Coating:</div>
                        <div
                          className={"orderItemDetailsValue"}
                        >
                          RAL {otherItemView.coating.colorRAL},
                          {otherItemView.coating.colorGlossId === "1" ? "Glossy (70±5)" : "Matte (30±5)"}
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                  {otherItemView.note ?
                    <div>
                      <div className={"orderItemDetailsLabel"}>Note:</div>
                      <div className={"orderItemDetailsValue"}>{otherItemView.note}</div>
                    </div>
                    :
                    null
                  }
                </div>
              </Card>
            </div>
          }
        </Modal>
        <Modal
          visible={showAdjustmentChangeModal}
          maskClosable={false}
          centered={true}
          closable={false}
          footer={
            <div>
              <Button
                type={"primary"}
                style={{fontSize: 13, fontWeight: 500}}
                onClick={()=>{handleAdjustmentConfirm(order.id)}}
              >
                Confirm
              </Button>
            </div>
          }
          width={520}
        >
          <div style={{fontSize: 13, fontWeight: 500, marginBottom: 10}}>
            <ExclamationCircleFilled style={{color: "#ffc739", fontSize: 16, marginRight: 5, position: "relative", top: 1}} /> The prices of your parts have changed, confirm to proceed.
          </div>
          {order.id &&
            <div
              className={"quotedItemsList"}
              style={{
                maxHeight: 480,
                padding: "0 6px 10px 6px",
                overflowX: "hidden"
              }}
            >
              {order.parts.map(part => {
                if(part.processingType === "automatic" && part.state === "processed") {
                  return (
                    <div key={part.id}>
                      <div style={{display: "flex"}}>
                        <Card
                          className={"bendCard"}
                          style={{height: 75, marginTop: 8, marginBottom: 0}}
                          bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
                        >
                          <div style={{display: "flex", width: "100%"}}>
                            <div
                              style={{
                                border: "1px solid rgb(205, 205, 205)",
                                borderRadius: 3,
                                marginRight: 8,
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              {part.fileType === "dxf" &&
                                <div>
                                  {part.dxfData.pngFilePath ?
                                    <div
                                      style={{
                                        width: 55,
                                        height: 55,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <div>
                                        <img
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            maxWidth: 62,
                                            maxHeight: 55,
                                            padding: 3,
                                          }}
                                          src={S3_BUCKET_URL + part.dxfData.pngFilePath}
                                          alt={"part"}
                                        />
                                      </div>
                                    </div>
                                    :
                                    <div
                                      style={{
                                        width: 55,
                                        height: 55,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <img
                                        style={{
                                          width: 45,
                                          height: 45,
                                          padding: 3,
                                          marginTop: 5,
                                          marginLeft: 4
                                        }}
                                        src={twoD}
                                        alt={"part"}
                                      />
                                    </div>
                                  }
                                </div>
                              }
                              {part.fileType === "step" &&
                                <div>
                                  {part.stepData.filePaths.pngFilePath ?
                                    <img style={{width: 55, height: 55, padding: 3}}
                                         src={S3_BUCKET_URL + part.stepData.filePaths.pngFilePath} alt={"part"}/>
                                    :
                                    <div style={{
                                      width: 55,
                                      height: 55,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}>
                                      <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}}
                                           src={treeD} alt={"part"}/>
                                    </div>
                                  }
                                </div>
                              }
                            </div>
                            <div style={{
                              width: "100%",
                              height: 55,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between"
                            }}>
                              <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                <Tooltip
                                  overlayClassName={"delete-tooltip"}
                                  color={"rgba(82,82,82,0.9)"}
                                  title={<span className={"text12-500"} style={{color: "white"}}>{part.fileName}</span>}
                                >
                                  <div
                                    style={{
                                      display: "inline-block",
                                      fontSize: 14,
                                      fontWeight: 600,
                                      marginRight: 8,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: 330,
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    {part.fileName}
                                  </div>
                                </Tooltip>
                                <div>
                                  <div style={{height: 24}}/>
                                </div>
                              </div>
                              <div>
                                <div align={"right"} style={{display: "flex", justifyContent: "flex-end"}}>
                                  <div
                                    style={{fontSize: 12, fontWeight: 500}}
                                  >
                                    1pc: <span style={{
                                    fontWeight: 600,
                                    color: "rgba(0, 0, 0, 0.85)"
                                  }}>{((parseFloat(part.pricePerPiece) || 0) * calcAdjustment(order.oldAdjustment)).toFixed(2)} €</span>
                                  </div>
                                  <div align={"center"} style={{display: "flex"}}>
                                    <TrendingFlatIcon style={{width: 35, fontSize: 18, alignSelf: "center"}}/>
                                  </div>
                                  <div
                                    style={{fontSize: 12, fontWeight: 500}}
                                  >
                                  <span style={{
                                    fontWeight: 600,
                                    color: "#047a04"
                                  }}>{((parseFloat(part.pricePerPiece) || 0) * calcAdjustment(order.adjustment)).toFixed(2)} €</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  )
                }
                else {
                  return <div key={part.id}/>
                }
              })}
            </div>
          }
        </Modal>
        <Dragger
          className={"fileDropZone"}
          name = {'file'}
          beforeUpload={(file, fileList) => {
            if(fileList.length > 100) {
              let num = fileList.findIndex(item => item.uid === file.uid)
              if(num > 99) {
                return false
              }
            }

            const is2Large = file.size / 1024 / 1024 < 100;
            if (!is2Large) {
              openFileSizeNotification()
            }
            else {
              triggerUpload(file)
            }
            return false;
          }}
          multiple={true}
          showUploadList={false}
          style={{
            display: 'none',
            position: 'fixed',
            margin: 5,
            top: 0,
            left: 0,
            width: 'calc(100% - 12px)',
            maxHeight: 'calc(100vh - 12px)',
            background: "rgba(0.5,0.5,0.5,0.2)",
            border: "2px dashed #066fc1",
            zIndex: 2000
          }}
        >
          <div align={'center'}>
            <Card
              style={{
                borderRadius: "5px",
                background: "#2076ba",
                border: "0px",
                maxWidth: "300px"
              }}
            >
              <img src={dragAndDropWhite} className={"drop-file-icon"} alt={"file"} />
              <span className={"text14-600"} style={{color: "white", marginLeft: 15, fontSize: 16}}>
                Drop your files here
              </span>
            </Card>
          </div>
        </Dragger>
      </Layout.Content>
    </Layout>
  )
}
