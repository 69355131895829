import React, {useCallback, useEffect, useState} from 'react';
import {
  Card, Form, Input
} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {debounce, escapeRegExp} from "lodash";
import {SearchOutlined} from "@ant-design/icons";
import {NestingGrid} from "../../NestingGrid";
import {fetchCompletedNestingJobs} from "../../../../../actions/nestingActions";

export const CompletedNesting = (props) => {
  const { onViewPlate, selectedTab } = props;

  const [searchForm] = Form.useForm();

  const [filteredJobs, setFilteredJobs] = useState(null);
  const [isSearchingJobs, setIsSearchingJobs] = useState(false);

  const isFetchingJobs = useSelector(state => state.nesting.isFetchingCompletedJobs);
  const completedJobs = useSelector(state => state.nesting.completedJobs);

  const dispatch = useDispatch();

  useEffect(() => {
    if(selectedTab === "3") {
      setFilteredJobs(completedJobs);
      dispatch(fetchCompletedNestingJobs());
    }
    resetAll()
  }, [selectedTab])

  const resetAll = () => {
    searchForm?.setFieldsValue({
      search: ""
    })
  }

  useEffect(() => {
    setFilteredJobs(completedJobs);
  }, [completedJobs]);

  const filterJobs = (data) => {
    if(!data.text) {
      setFilteredJobs(data.jobs)
    } else {
      const orders = data.jobs.filter(item => {
        const laserStr = item?.selectedCuttingMachine?.name || "";
        return (`${item.name} ${item.material} ${item.width} ${item.height} ${item.thickness}mm ${laserStr}`)
            .toLowerCase().search(escapeRegExp(data.text.toLowerCase())) !== -1
      })
      setFilteredJobs(orders)
    }
    setIsSearchingJobs(false)
  }

  const debounceSearchJobs = useCallback(
    debounce(data => filterJobs(data), 800),
    []
  );

  const searchJobs = (text) => {
    setIsSearchingJobs(true)
    debounceSearchJobs({jobs: completedJobs, text})
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"} bodyStyle={{paddingRight: 0}}>
        <div style={{height: 'calc(100vh - 120px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
          <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
            <div style={{fontSize: 13, fontWeight: 500}}>
              Search completed jobs:
            </div>
            <div style={{marginLeft: 8, fontSize: 13, fontWeight: 500}}>
              <Form
                form={searchForm}
              >
                <Form.Item
                  name={"search"}
                  style={{marginBottom: 0, height: 28}}
                >
                  <Input
                    autoComplete="off"
                    prefix={<SearchOutlined/>}
                    placeholder={"Type here to search"}
                    style={{height: 28, marginTop: -5}}
                    onChange={(e)=>searchJobs(e.target.value)}
                    disabled={isFetchingJobs}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className={"nestingCard"} style={{height: 'calc(100vh - 158px)', overflowX: "hidden"}}>
            <NestingGrid
              gridItems={filteredJobs}
              isFetchingItems={isFetchingJobs}
              isSearching={isSearchingJobs}
              plateState={"completed"}
              viewPlate={onViewPlate}
            />
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}