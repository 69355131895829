import React, {useState} from "react";
import {FilePdfOutlined} from "@ant-design/icons";
import {Button, Col, Row, Table} from "antd";
import ReactToPrint from "react-to-print";
import logo from "../../../assets/logo/hefeos_logo_01.svg";
import "./style.css";
import {formatDate} from "../../../utils/utility";
import {translator} from "../../../translations/translators";
import {S3_BUCKET_URL} from "../../../constants";

import { useCallback } from 'react';




const PrintComponent = React.forwardRef((props, ref) => {

  const getLang = props.getLang;

  console.log(getLang("Offer"));
  console.log("PATH: " + props.orderData.producer.offerHeaderImage);
 
  return (
    <div ref={ref}>
       { (props.orderData.producer.offerHeaderImage === "" || props.orderData.producer.offerHeaderImage === undefined) && (
          <div className="page-header">
          </div> 
         )
      }
      <table>
        { (props.orderData.producer.offerHeaderImage === "" || props.orderData.producer.offerHeaderImage === undefined) && (
          <thead>
          <tr>
            <td>
              <div className="page-header-space"/>
            </td>
          </tr>
          </thead>
          )
        }
        <tbody>
        <tr>
          <td>

            { (props.orderData.producer.offerHeaderImage === "" || props.orderData.producer.offerHeaderImage === undefined) ? (
              <Row>
                <Col span={24}>
                  <div style={{margin: "0 30px 30px 30px"}}>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                      <div style={{ width: 300 }}>
                        <Row>
                          <Col span={24}>
                          <div className={"orderNoteTitle"}>{ getLang("Offer") } { (new Date()).toLocaleDateString("en-GB") }</div>
                          </Col>
                        </Row>
                      </div>
                      <div className={"centeredCol"} style={{ width: 340 }}>
                        <img style={{objectFit: "contain", maxWidth: "100%", maxHeight: 130}}
                            src={`${S3_BUCKET_URL}${props.orderData.producer.logoUrl}` || logo}
                            alt={''}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
            
              <Row>
                <Col span={24}>
                  <div style={{margin: "20px 20px 20px 20px"}}>
                    <img style={{objectFit: "contain", maxWidth: "100%"}}
                            src={props.orderData.producer.offerHeaderImage}
                            alt={'Helloo'}
                    />
                  </div>
                </Col>
              </Row>
            ) }
            
            <div className={"dataRow"}>
              <div className={"centeredCol"} style={{ alignItems: "flex-start", width: "50%", marginRight: 10, borderRight: "1px solid #cccccc"}}>
                <div className={"invoiceFieldMain"}>
                  {props.orderData.customer.name}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.customer.address},
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.customer.postalCode} {props.orderData.customer.city}, {props.orderData.customer.country}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.customer.email}
                </div>
              </div>

              { (props.orderData.producer.offerHeaderImage === "" || props.orderData.producer.offerHeaderImage === undefined) ? (
                <div className={"centeredCol"} style={{ alignItems: "flex-start", width: "50%", paddingLeft: 10}}>
                  <div className={"invoiceFieldMain"}>
                    {props.orderData.producer.name}
                  </div>
                  <div className={"invoiceField"}>
                    {props.orderData.producer.address},
                  </div>

                  <div className={"invoiceField"}>
                    {props.orderData.producer.postalCode} {props.orderData.producer.city}, {props.orderData.producer.country}
                  </div>

                  <div>
                    <span className={"invoiceFieldName"}>{getLang("mail")}:</span> <span className={"invoiceField"}> {props.orderData.producer.contactEmail} </span>
                  </div>

                  <div>
                    <span className={"invoiceFieldName"}>{getLang("web")}:</span> <span className={"invoiceField"}> {props.orderData.producer.website} </span>
                  </div>
                </div>
              ) : (
                <div className={"centeredCol"} style={{ alignItems: "flex-start", width: "50%", paddingLeft: 10}}></div>
              )}
            </div>
            

            <Table columns={[
              {
                title: <span className={"orderNoteColumnTitle"}>{getLang("Part")}</span>,
                className: "darkerColumn",
                key: "fileName",
                align: "left",
                ellipsis: true,
                width: "25%",
                dataIndex: "fileName",
                render: data => <span className={"orderNoteValues"}>{data}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>{getLang("Material")}</span>,
                key: "material",
                align: "left",
                ellipsis: true,
                width: "22%",
                dataIndex: "material",
                render: data => <span className={"orderNoteValues"}>{data}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>{getLang("Thickness")}</span>,
                className: "darkerColumn",
                key: "thickness",
                align: "left",
                ellipsis: true,
                width: "13%",
                dataIndex: "thickness",
                render: data => <span className={"orderNoteValues"}>{data ? data + "mm" : ""}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>{getLang("Quantity")} </span>,
                key: "quantity",
                align: "left",
                ellipsis: true,
                width: "10%",
                dataIndex: "quantity",
                render: data => <span className={"orderNoteValues"}>{data}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>{getLang("Price Per Unit")}</span>,
                className: "darkerColumn",
                key: "pricePerPiece",
                align: "left",
                ellipsis: true,
                width: "15%",
                dataIndex: "pricePerPiece",
                render: data => <span className={"orderNoteValues"}>{Math.round((parseFloat(data) + Number.EPSILON) * 100) / 100}€</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>{getLang("Total Price")}</span>,
                key: "totalPrice",
                align: "left",
                ellipsis: true,
                width: "15%",
                dataIndex: "totalPrice",
                render: data => <span className={"orderNoteValues"}>{Math.round((parseFloat(data) + Number.EPSILON) * 100) / 100}€</span>
              },
              ]}
                   dataSource={props.parts}
                   size={"small"}
                   rowKey={"id"}
                   style={{margin: 30}}
                   pagination={false}
                   className={"deliveryNoteTable"}
            />
             <div className={"dataRow2"}>
              <div className={"headerTextValuesRight"}>{getLang("Price")}: {Math.round((props.orderData.totalPrice + Number.EPSILON) * 100) / 100} €</div>
              <div><p></p></div>
              <div style={{ width: "100%" }}>
                <div style={{ alignItems: "flex-start"}}>
                  <div className={"invoiceOfferText"}>
                    {props.orderData.producer.offerText}
                  </div>
                </div>
              </div>

              <div style={{ width: "100%" }}>
                <div style={{ alignItems: "flex-start"}}>
                  <div className={"invoiceOfferNote"}>
                    {props.orderData.producer.offerNote}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>


    </div>
  );
});

export const OrderOfferPdfExportButton = (props) => {
  const getLang =(str) => {
    const germanLangMap = {
      "mail": "E-Mail",
      "web": "Web",
      "Offer": "Angebot",
      "Offer.pdf": "Angebot.pdf",
      "Part": "Bauteil",
      "Material": "Material",
      "Thickness": "Blechstärke",
      "Quantity": "Menge",
      "Price Per Unit": "Einzelpreis",
      "Total Price": "Gesamtpreis",
      "Price": "Preis"
    };
    let retVal = str;
    if (props.orderData.producer.country === "Austria" ||
        props.orderData.producer.country === "Germany") {
      if (germanLangMap.hasOwnProperty(str)) {
        retVal = germanLangMap[str];
      } 
    }
    return retVal;
  };


  const { isEditing, orderData, parts } = props;
  const componentRef = React.useRef();
  const [loading, setLoading] = useState(false)
  const prepPartData = (parts) => {
    const filteredParts = parts.filter(item => item.state !== "declined")
    return filteredParts.map((item, index) => {
      let name = (item.fileName || item.name)
      return {
        id: index,
        fileName: name.substring(0, name.lastIndexOf(".")),
        material: translator(item.selectedMaterial?.grade) || "",
        thickness: item.selectedMaterial?.thickness || "",
        quantity: item.quantity,
        pricePerPiece: item.pricePerPiece.toFixed(4),
        totalPrice: item.totalPrice.toFixed(4)
      }
    })
  }

  const handleAfterPrint = React.useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const docName = getLang("Offer.pdf");

  return (
    <div style={{ display: "inline-block"}}>
      <ReactToPrint
        content={() => componentRef.current}
        documentTitle={docName}
        onBeforeGetContent={handleOnBeforeGetContent}
        onAfterPrint={handleAfterPrint}
        trigger={() => (
          <Button
            type="primary"
            className={"exportPdfButton " + (isEditing ? "editingDisabled" : "")}
            disabled={isEditing}
            loading={loading}
            style={{
              marginTop: 5
            }}
          >
            <FilePdfOutlined style={{fontSize: 14, marginLeft: 0}}/>
            Export PDF Offer
          </Button>
        )}
      />
      <div style={{ display: "none"}}>
        <PrintComponent ref={componentRef}
                        orderData={orderData}
                        parts={prepPartData(parts)}
                        getLang={getLang}

        />
      </div>
    </div>
  )
}