import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Table, Layout, Row, Col, Button, Tag, Input, Divider} from 'antd'
import { Sidebar } from '../../../components'
import { logoutUser } from '../../../actions/authActions'
import {LoadingOutlined, SearchOutlined} from "@ant-design/icons";
import {
  fetchCustomerAcceptedOrders
} from "../../../actions/ordersActions";
import format from "date-fns/format";
import {formatTableDate, getColumnSearchProps} from "../../../utils/utility";
import history from "../../../utils/history";

export const CustomerAcceptedPage = (props) => {
  let searchInput = null;
  
  const user = useSelector(state => state.auth.user);
  const isFetchingAcceptedOrders = useSelector(state => state.orders.isFetchingCustomerAcceptedOrders)
  const acceptedOrders = useSelector(state => state.orders.customerAcceptedOrders)
  const pagination = useSelector(state => state.orders.customerAcceptedOrdersPagination)
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const dispatch = useDispatch()

  const getPageSize = () => {
    const count = Math.floor((window.innerHeight - 150) / 50)
    return count > 10 ? count : 10;
  }

  const pageSize = getPageSize();

  useEffect(() => {
    dispatch(fetchCustomerAcceptedOrders({
      sortField: "orderDate",
      sortDirection: "desc",
      orderName: "",
      state: "",
      page: pagination.current !== undefined ? pagination.current : 1,
      pagination: {...pagination, pageSize: pageSize}
    }))
  }, [])

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    const sortField = sorter.order ? sorter.columnKey : "orderDate"
    const sortDirection = sorter.order ? (sorter.order === "ascend" ? "asc" : "desc") : "desc"
    const orderName = filters.orderName ? filters.orderName[0] : ""
    const state = filters.state && filters.state.length === 1 ? filters.state[0] : ""


    dispatch(fetchCustomerAcceptedOrders({
      sortField: sortField,
      sortDirection: sortDirection,
      orderName: orderName,
      page: pagination.current,
      pagination: pager,
      state: state
    }))
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <Sidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
          <Row type="flex">
            <Col span={24}>
              <div style={{ height: 32, display: "flex", alignItems: "center" }}>
                <div style={{fontWeight: 500, fontSize: 16}}>These are your orders that are in progress:</div>
              </div>
              <Divider style={{marginTop: 6, marginBottom: 11}}/>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              <React.Fragment>
                <Table
                  columns={[
                    {
                      title: 'Order Code',
                      key: 'orderName',
                      width: "18%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {row.orderName}
                        </div>
                      ),
                      ...getColumnSearchProps('code', searchInput),
                    },
                    {
                      title: 'Custom Order Name',
                      key: 'customName',
                      className: "darkerColumn",
                      width: "18%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {row.customName || "-"}
                        </div>
                      ),
                    },
                    {
                      title: 'Order date',
                      key: 'orderDate',
                      width: "12%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {formatTableDate(row.orderDate)}
                        </div>
                      ),
                      sorter: true,
                    },
                    {
                      title: 'Delivery date',
                      key: 'deliveryDate',
                      className: "darkerColumn",
                      width: "12%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {formatTableDate(row.deliveryDate)}
                        </div>
                      ),
                      sorter: true,
                    },
                    {
                      title: 'Total with VAT',
                      key: 'totalWithVat',
                      width: "10%",
                      align: "right",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {(row.totalPrice * (1 + row.vatValue) || row.totalPrice || 0).toFixed(2)}€
                        </div>
                      ),
                    },
                    {
                      title: 'Status',
                      key: 'state',
                      className: "darkerColumn",
                      width: "15%",
                      align: "center",
                      render: row => (
                        <div>
                          <React.Fragment>
                            {row.state === "accepted" ?
                              <Tag color="#51b364" style={{fontWeight: 600, textAlign: "center", width: 89}}>Accepted</Tag>
                              :
                              <Tag color="#7cbbf4" style={{fontWeight: 600, textAlign: "center", width: 89}}>In Progress</Tag>
                            }
                          </React.Fragment>
                        </div>
                      ),
                      filters: [{
                        text: 'Accepted',
                        value: 'accepted',
                      }, {
                        text: 'In Progress',
                        value: 'inProgress',
                      }],
                    },
                    {
                      title: '',
                      key: 'more',
                      align: "right",
                      render: row => (
                        <Button
                          onMouseDown={(e) => {
                            if (e.button === 1) {
                              const win = window.open(`/accepted-order?order=${row.id}`, "_blank");
                              win.focus();
                            } else if (e.button === 0) {
                              history.push(`/accepted-order?order=${row.id}`)
                            }
                          }}
                          type="primary"
                          style={{
                            fontSize: 13,
                            fontWeight: 600
                          }}
                        >
                          Details
                        </Button>
                      ),
                    },
                  ]}
                  className={"pendingOrdersTable"}
                  dataSource={acceptedOrders}
                  rowKey="id"
                  pagination={{...pagination, pageSize: pageSize}}
                  loading={{spinning: isFetchingAcceptedOrders, indicator: <LoadingOutlined style={{ fontSize: 50,marginLeft: "-25px" }} spin />}}
                  onChange={handleTableChange}
                  size={"small"}
                  scroll={{x: '70%'}}
                />
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  )
}

