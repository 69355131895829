import * as React from "react";
import { AutoSizer, List } from "react-virtualized"
import {Card, Spin} from "antd";
import "./style.css"
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {LoadingOutlined} from "@ant-design/icons";

export const CustomerGrid = (props) => {
  const {customers, itemCount, itemSize, selectedId, selectCustomer, searching} = props;
  if (customers.length === 0) {
    return (
      <React.Fragment>
        <Spin
          spinning={searching}
          indicator={
            <div align={"center"}>
              <LoadingOutlined style={{ fontSize: 50, marginLeft: -20, marginTop: -20 }} spin />
            </div>
          }
        >
          <div className={"emptyAwaiting"}>
            <div className={"emptyDraftsText"}>No Customers</div>
          </div>
        </Spin>
      </React.Fragment>
    )
  } else {
    return (
      <AutoSizer>
        {({ height, width }) => {
          const itemsPerRow = Math.floor(width / itemSize) || 1;
          const rowCount = Math.ceil(itemCount / itemsPerRow);

          return (
            <Spin
              spinning={searching}
              style={{width}}
              indicator={
                <div align={"center"}>
                  <LoadingOutlined style={{ fontSize: 50, marginLeft: -25, marginTop: -25 }} spin />
                </div>
              }
            >
              <List
                className={'customerList'}
                width={width}
                height={height}
                rowCount={rowCount}
                rowHeight={78}
                overscanRowCount={2}
                rowRenderer={
                  ({ index, key, style }) => {
                    const items = [];
                    const fromIndex = index * itemsPerRow;
                    const toIndex = fromIndex + itemsPerRow;

                    for (let i = fromIndex; i < toIndex; i++) {
                      items.push(
                        <div
                          className={'customerItem'}
                          key={i}
                        >
                          {i < itemCount &&
                            <Card
                              className={selectedId !== customers[i].id ? "customerCard fadeInCustomerCard" : "customerCard fadeInCustomerCard selectedCustomerCard"}
                              bodyStyle={{height: "100%", width: "100%"}}
                              onClick={() => selectCustomer(customers[i])}
                            >
                              <div style={{display: "flex"}}>
                                <div
                                  className={"nameIcon"}
                                  style={{backgroundColor: customers[i].type === "real" ? "#008000" : "#0054a1"}}>
                                  {customers[i].name.charAt(0).toUpperCase()}
                                </div>
                                <table style={{width: "100%"}}>
                                  <tbody>
                                  <tr>
                                    <td style={{width: "100%", maxWidth: 50}}>
                                      <div
                                        className={"customerGridText customerName"}
                                        style={{
                                          fontSize: 13,
                                          fontWeight: 600,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: "100%",
                                          whiteSpace: "nowrap"
                                        }}
                                      >
                                        {customers[i].name}
                                      </div>
                                    </td>
                                    <td>
                                      <div style={{height: 15}}>
                                        {customers[i].type === "real" ?
                                          <PersonIcon style={{fontSize: 15, color: "#008000"}}/>
                                          :
                                          <AccountBoxIcon style={{fontSize: 16, color: "#0054a1"}}/>
                                        }
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{width: "100%", maxWidth: 50}}>
                                      <div
                                        className={"customerGridText"}
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: "100%",
                                          whiteSpace: "nowrap"
                                        }}
                                      >
                                        {customers[i].email}
                                      </div>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </Card>
                          }
                        </div>
                      )
                    }

                    return (
                      <div
                        className={'customerRow'}
                        key={key}
                        style={style}
                      >
                        {items}
                      </div>
                    )
                  }
                }
              />
            </Spin>
          )
        }}
      </AutoSizer>
    )
  }
}