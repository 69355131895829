import Icon from "@ant-design/icons";
import React from "react";

const CustomIcon = () => (
  <svg width="39px" height="46px" viewBox="0 0 64 76">
    <g fill="none" stroke="currentColor" strokeWidth="2px">
      <path d="M10.586 46.268V11.025c0-.851.335-1.667.934-2.268l6.251-6.289a3.178 3.178 0 012.254-.94h32.028a1.84 1.84 0 011.835 1.846v42.87M16.756 38.207h32.266M43.379 43.228h5.643M16.756 23.142h13.488M43.905 23.142h5.254M16.756 28.164h9.284M43.905 28.164h5.254M16.756 33.185h16.64M43.905 33.185h5.254M16.756 13.364h9.284M43.905 13.364h5.254M16.756 18.385h16.64M43.905 18.385h5.254"/>
      <path d="M62.948 40.491v28.31c0 3.301-2.66 5.978-5.942 5.978H7.466c-3.28 0-5.94-2.677-5.94-5.978V40.49M43.195 53.06l11.901 12.107M21.279 53.06L9.378 65.167"/>
      <path d="M53.888 29.683l7.445 5.126a3.737 3.737 0 011.615 3.08v2.602L34.275 58.698a3.907 3.907 0 01-4.2-.004L1.526 40.491v-2.603c0-1.231.604-2.383 1.615-3.079l7.277-5.01"/>
    </g>
  </svg>
);

export const ManualQuote = props => <Icon component={CustomIcon} {...props} />;