import React, {Fragment, useEffect, useState} from "react";

import {
  Button, Row, Col, Divider,
  Layout, Table, Tag, Input,
  Dropdown, Menu, Modal, Form,
  Spin, Tooltip, Checkbox
} from 'antd'
import { CompanySidebar } from "../../../components/company";
import { logoutUser } from "../../../actions/authActions";

import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {
  LoadingOutlined, MoreOutlined, InfoCircleOutlined,
  LockOutlined, UserOutlined, MailOutlined, WarningOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import {formatTableDate, getColumnSearchProps} from "../../../utils/utility";
import { addSubAdmin, fetchSubAdminLimit, fetchSubAdmins, editSubAdmin, changeAdminState } from "../../../actions/subAdminActions";


export const AdminManagementPage = () => {

  const [editAdminModalVisible, setEditAdminModalVisible] = useState(false);
  const [addAdminModalVisible, setAddAdminModalVisible] = useState(false);
  const [currentAdminData, setCurrentAdminData] = useState(null);
  const [adminLimitReached, setAdminLimitReached] = useState(false)
  const [filteredInfo, setFilteredInfo] = useState({})


  const user = useSelector(state => state.auth.user);
  const isAddingSubAdmin = useSelector(state => state.subAdmins.isAddingSubAdmin)
  const subAdmins = useSelector(state => state.subAdmins.subAdmins)
  const isFetchingSubAdmins = useSelector(state => state.subAdmins.isFetchingSubAdmins)
  const pagination = useSelector(state => state.subAdmins.subAdminsPagination)
  const isFetchingSubAdminsLimit = useSelector(state => state.subAdmins.isFetchingSubAdminsLimit)
  const maxSubAdmins = useSelector(state => state.subAdmins.maxSubAdmins)
  const currentSubAdmins = useSelector(state => state.subAdmins.currentSubAdmins)
  const isEditingSubAdmin = useSelector(state => state.subAdmins.isEditingSubAdmin)
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const dispatch = useDispatch();

  const getPermissions = () => {
    let per = ["orders", "materials", "customers", "settings"]
    if(user?.producer?.hasBulkFunction) {
      per.push("bulk")
    }
    if(user?.producer?.hasNestingFunction) {
      per.push("nesting")
    }
    return per
  }

  const allPermissions = getPermissions()

  const getPageSize = () => {
    const count = Math.floor((window.innerHeight - 150) / 50)
    return count > 10 ? count : 10;
  }

  const pageSize = getPageSize();

  let searchInput = null;

  const initializeSubAdmins = () => {
    const email = filteredInfo.email ? filteredInfo.email[0] : ""
    const name = filteredInfo.name ? filteredInfo.name[0] : ""

    dispatch(fetchSubAdmins({
      email: email,
      name: name,
      page: pagination.current,
      pagination: pagination
    }))
    dispatch(fetchSubAdminLimit())
  }

  useEffect(() => {
    dispatch(fetchSubAdmins({
      email: "",
      name: "",
      page: 1,
      pagination: {...pagination, pageSize: pageSize}
    }))
    dispatch(fetchSubAdminLimit())
  }, [])

  useEffect(() => {
    if(!isFetchingSubAdminsLimit) {
      setAdminLimitReached(currentSubAdmins >= maxSubAdmins)
    }
  }, [maxSubAdmins, currentSubAdmins, isFetchingSubAdminsLimit])


  const onEditAdmin = (adminData) => {
    setCurrentAdminData(adminData);
    setEditAdminModalVisible(true);
  }

  const handleChangeAdminState = (subAdminId, status, e) => {
    dispatch(changeAdminState({subAdminId, status})).then(()=>{
      e()
      initializeSubAdmins()
    }).catch(()=>{
      e()
    })
  }

  const onToggleActiveAdminStatus = (isBlocked, subAdminId) => {
    if (isBlocked && adminLimitReached) {
      Modal.confirm({
        className: "inputInfoText",
        visible: true,
        style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
        centered: true,
        onOk: (e) => {
          e()
        },
        confirmLoading: true,
        destroyOnClose: true,
        width: 400,
        title: <span>{"Failed to activate Employee"}</span>,
        content: <div>
          <div>Maximum number of active Employees is reached.<br/> Please add more or deactivate an Employee to activate this one.</div>
        </div>,
        okText: <span style={{fontSize: 13, fontWeight: 500}}>Okay</span>,
        okButtonProps: {type: "primary", loading: false},
        cancelText: <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
        icon: <WarningOutlined style={{color: "red"}}/>,
        zIndex: 2,
      })
    } else {
      if (isBlocked) {
        let modal = Modal.confirm({
          className: "inputInfoText",
          visible: true,
          style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
          centered: true,
          onOk: (e) => {
            modal.update(prevConfig => ({
              ...prevConfig,
              cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
              okButtonProps: {...prevConfig.okButtonProps, loading: true}
            }));
            handleChangeAdminState(subAdminId, !isBlocked, e)
          },
          confirmLoading: true,
          destroyOnClose: true,
          width: 400,
          title: <span>{"Activate Admin"}</span>,
          content: <div>
            <div>Are you sure you want to activate this Employee?</div>
          </div>,
          okText: <span style={{fontSize: 13, fontWeight: 500}}>Activate</span>,
          okButtonProps: {type: "primary", loading: false},
          cancelText: <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
          icon: <QuestionCircleOutlined style={{color: "#ffc739"}}/>,
          zIndex: 2,
        })
      } else {
        let modal = Modal.confirm({
          className: "inputInfoText",
          visible: true,
          style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
          centered: true,
          onOk: (e) => {
            modal.update(prevConfig => ({
              ...prevConfig,
              cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
              okButtonProps: {...prevConfig.okButtonProps, loading: true}
            }));
            handleChangeAdminState(subAdminId, !isBlocked, e)
          },
          confirmLoading: true,
          destroyOnClose: true,
          width: 400,
          title: <span>{"Deactivate Admin"}</span>,
          content: <div>
            <div>Are you sure you want to deactivate this Employee?</div>
          </div>,
          okText: <span style={{fontSize: 13, fontWeight: 500}}>Deactivate</span>,
          okButtonProps: {type: "danger", loading: false},
          cancelText: <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
          icon: <QuestionCircleOutlined style={{color: "#ffc739"}}/>,
          zIndex: 2,
        })
      }

    }
  }

  const formatPermissionText = (permission) => {
    switch (permission) {
      case "orders":
        return "Orders"
      case "nesting":
        return "Nesting"
      case "bulk":
        return "Calculation"
      case "materials":
        return "Materials"
      case "customers":
        return "Customers"
      case "settings":
        return "Settings"
      default:
        return ""
    }
  }

  const formatPermissions = (permissions) => {
    if(permissions.length === allPermissions.length) {
      return "All Permissions"
    }
    else if(permissions.length > allPermissions.length) {
      const containsAll = allPermissions.every(element => {
        return permissions.includes(element);
      });
      if(containsAll) {
        return "All Permissions"
      } else {
        let rez = "";
        for(let p of permissions) {
          if(rez === "") {
            rez = formatPermissionText(p)
          } else {
            rez = `${rez}, ${formatPermissionText(p)}`
          }
        }
        return rez
      }
    } else {
      let rez = "";
      for(let p of permissions) {
        if(rez === "") {
          rez = formatPermissionText(p)
        } else {
          rez = `${rez}, ${formatPermissionText(p)}`
        }
      }
      return rez
    }
  }

  const handleAddAdmin = (values) => {
    let data = {
      name: values.subAdminName,
      email: values.subAdminEmail,
      password: values.subAdminPassword,
      permissions: values.permissions
    }

    dispatch(addSubAdmin({data})).then(()=>{
      setAddAdminModalVisible(false)
      initializeSubAdmins()
    }).catch(()=>{})
  }

  const handleEditAdmin = (values) => {
    dispatch(editSubAdmin({data: values.permissions, subAdminId: currentAdminData.id})).then(()=>{
      setEditAdminModalVisible(false)
    }).catch(()=>{})
  }

  const handleTableChange = (pagination, filters) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    const email = filters.email ? filters.email[0] : ""
    const name = filters.name ? filters.name[0] : ""

    setFilteredInfo(filters)

    dispatch(fetchSubAdmins({
      email: email,
      name: name,
      page: pagination.current,
      pagination: pager,
    }))
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{overflowX: "hidden"}}>
          <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
            <Row type="flex">
              <Col span={24}>
                <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                  <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column"}}>
                    <div style={{fontWeight: 500, fontSize: 16}}>List of all Employees:</div>
                  </div>
                  <div align={"right"} style={{alignSelf: "flex-end", display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={"activeAdminCounter"}>
                      Active Employees: <span style={{ fontWeight: 600, marginLeft: 4 }}>
                      {isFetchingSubAdminsLimit ?
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 22, marginLeft: 10, marginTop: 3 }} spin />}/>
                        :
                        `${currentSubAdmins}/${maxSubAdmins}`
                      }
                      </span>
                    </div>
                    <Button
                      disabled={adminLimitReached || isFetchingSubAdminsLimit || isFetchingSubAdmins}
                      style={{fontWeight: 500}}
                      onClick={() => {
                        setAddAdminModalVisible(true)
                      }}
                      type="primary">
                      <span className={"buttonText"}>Add Employee</span>
                    </Button>
                    {adminLimitReached &&
                    <div style={{display: "flex", alignItems: "center", marginLeft: 12}}>
                      <Tooltip
                        overlayClassName={"adminTooltip"}
                        placement={'bottomRight'}
                        overlayStyle={{width: 300}}
                        getPopupContainer={trigger => trigger.parentNode}
                        title={
                          <span className={"text12-500"} style={{color: "white", fontSize: 13}}>
                            Maximum number of Employees reached. Please delete or deactivate an existing Employee to add a new one.
                          </span>
                        }
                      >
                        <InfoCircleOutlined style={{fontSize: 18, marginRight: 6, color: "rgba(137,137,137,0.65)"}}/>
                      </Tooltip>
                    </div>
                    }
                  </div>
                </div>
                <Divider style={{marginTop: 12, marginBottom: 14}}/>
              </Col>
            </Row>
            <Row type="flex">
              <Col span={24}>
                <Fragment>
                  <Table
                    columns={[
                      {
                        title: 'Name',
                        className: "darkerColumn",
                        key: 'name',
                        width: "25%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#bdbdbd": "rgba(0, 0, 0, 0.85)"}}>
                            {row.name}
                          </div>
                        ),
                        ...getColumnSearchProps('name', searchInput),
                      },
                      {
                        title: 'Email',
                        key: 'email',
                        width: "25%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#bdbdbd": "rgba(0, 0, 0, 0.85)"}}>
                            {row.email}
                          </div>
                        ),
                        ...getColumnSearchProps('email', searchInput),
                      },
                      {
                        title: 'Permissions',
                        className: "darkerColumn",
                        key: 'permissions',
                        width: "35%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#bdbdbd": "rgba(0, 0, 0, 0.85)"}}>
                            {formatPermissions(row.permissions)}
                          </div>
                        ),
                      },
                      {
                        title: 'Admin since',
                        key: 'createdAt',
                        width: "15%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#bdbdbd": "rgba(0, 0, 0, 0.85)"}}>
                            {formatTableDate(row.createdAt)}
                          </div>
                        ),
                      },
                      {
                        title: 'Status',
                        key: 'isBlocked',
                        className: "darkerColumn",
                        width: "200px",
                        align: "center",
                        render: row => (
                          <div>
                            {!row.isBlocked ?
                              <Tag color="#51b364" style={{fontWeight: 600, textAlign: "center", width: 104, marginRight: 0}}>Active</Tag>
                              :
                              <Tag color="#b60a1c" style={{fontWeight: 600, textAlign: "center", width: 104, marginRight: 0}}>Inactive</Tag>
                            }
                          </div>
                        ),
                      },
                      {
                        title: '',
                        key: 'edit',
                        width: "20px",
                        align: "right",
                        render: row => (
                          <Dropdown
                            overlay={
                              <Menu className={'employeeEditMenu'}>
                                <Menu.Item key='1' disabled={row.isBlocked} onClick={() => {onEditAdmin(row)}}>
                                  <span style={{fontSize: 13, fontWeight: 600}}>
                                    Edit
                                  </span>
                                </Menu.Item>

                                <Menu.Item key='2' onClick={() => {onToggleActiveAdminStatus(row.isBlocked, row.id)}}>
                                  <span style={{fontSize: 13, fontWeight: 600}}>
                                    {!row.isBlocked ? "Deactivate" : "Activate"}
                                  </span>
                                </Menu.Item>
                              </Menu>
                            }
                            trigger={['click']}
                          >
                            <MoreOutlined style={{fontSize: 20, fontWeight: 'bold'}} />
                          </Dropdown>
                        ),
                      },
                    ]}
                    className={"pendingOrdersTable"}
                    dataSource={subAdmins}
                    rowKey="id"
                    pagination={pagination}
                    loading={{spinning: isFetchingSubAdmins, indicator: <LoadingOutlined style={{ fontSize: 50,marginLeft: "-25px" }} spin />}}
                    onChange={handleTableChange}
                    size={"small"}
                    scroll={{x: '70%'}}
                  />
                </Fragment>
              </Col>
            </Row>
          </div>
        </div>
      </Layout.Content>

      <Modal
        visible={addAdminModalVisible}
        maskClosable={false}
        title={"Add Employee"}
        destroyOnClose={true}
        className={"addAdminModal"}
        width={600}
        centered={true}
        onCancel={() => {setAddAdminModalVisible(false)}}
        footer={null}
        bodyStyle={{paddingBottom: 0}}
      >
        <Form
          name={"addNewSubAdmin"}
          onFinish={handleAddAdmin}
        >
          <div style={{display: "flex", alignItems: "center"}}>
            <div style={{fontWeight: 600, fontSize: 13, marginRight: 10, width: 110, textAlign: "end", marginTop: -20}}>Full Name:</div>
            <Form.Item
              className={"adminNameField"}
              name={"subAdminName"}
              rules={[
                {required: true, message: 'Required'},
                {type: 'string', min: 2, message: "Full name must have at least 2 characters."},
                {type: 'string', max: 60, message: "Full name is too long."},
              ]}
              style={{width: "100%"}}
              validateTrigger={"onSubmit"}
            >
              <Input
                prefix={<UserOutlined/>}
                autoComplete="off"
                placeholder="Enter employee name"
              />
            </Form.Item>
          </div>

          <div style={{display: "flex", alignItems: "center"}}>
            <span style={{fontWeight: 600, fontSize: 13, marginRight: 10, width: 110, textAlign: "end", marginTop: -20}}>Email:</span>
            <Form.Item
              name={"subAdminEmail"}
              rules={[
                {required: true, message: 'Required'},
                {type: 'email', message: 'Please enter a valid email address'},
              ]}
              style={{width: "100%"}}
              validateTrigger={"onSubmit"}
            >
              <Input
                prefix={<MailOutlined/>}
                autoComplete="off"
                placeholder="Enter employee email"
              />
            </Form.Item>
          </div>

          <div style={{display: "flex", alignItems: "center"}}>
            <span style={{fontWeight: 600, fontSize: 13, marginRight: 10, width: 110, textAlign: "end", marginTop: -20}}>Password:</span>
            <Form.Item
              name={"subAdminPassword"}
              rules={[
                {required: true, message: 'Required'},
                {type: 'string', min: 6, message: 'Password must be 6 characters or longer'},
                {type: 'string', max: 60, message: 'Password must be 60 characters or shorter'},
              ]}
              style={{width: "100%"}}
              validateTrigger={"onSubmit"}
              autoComplete="off"
            >
              <Input.Password
                className={"loginInputText"}
                prefix={<LockOutlined/>}
                placeholder="Enter employee password"
                autoComplete="current-password"
              />
            </Form.Item>
          </div>

          <div style={{display: "flex", alignItems: "center"}}>
            <span style={{fontWeight: 600, fontSize: 13, marginRight: 10, width: 110, marginTop: -20, textAlign: "end"}}>Permissions:</span>
            <Form.Item
              name={"permissions"}
              rules={[
                {required: true, message: 'At least one must be checked!'},
              ]}
              style={{width: "100%"}}
              className={"permissionCheckboxes"}
              initialValue={allPermissions}
            >
              <Checkbox.Group>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value="orders"
                    >
                      <span style={{fontSize: 13, fontWeight: 500}}>Orders</span>
                    </Checkbox>
                  </Col>
                  {user?.producer?.hasBulkFunction &&
                    <Col span={9}>
                      <Checkbox
                        value="bulk"
                      >
                        <span style={{fontSize: 13, fontWeight: 500}}>Calculation</span>
                      </Checkbox>
                    </Col>
                  }
                  {user?.producer?.hasNestingFunction &&
                    <Col span={9}>
                      <Checkbox
                        value="nesting"
                      >
                        <span style={{fontSize: 13, fontWeight: 500}}>Nesting</span>
                      </Checkbox>
                    </Col>
                  }
                  <Col span={6}>
                    <Checkbox
                      value="materials"
                    >
                      <span style={{fontSize: 13, fontWeight: 500}}>Materials</span>
                    </Checkbox>
                  </Col>
                  <Col span={9}>
                    <Checkbox
                      value="customers"
                    >
                      <span style={{fontSize: 13, fontWeight: 500}}>Customer Relations</span>
                    </Checkbox>
                  </Col>
                  <Col span={9}>
                    <Checkbox
                      value="settings"
                    >
                      <span style={{fontSize: 13, fontWeight: 500}}>Company Settings</span>
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            <Form.Item>
              <Button
                onClick={() => {
                  setAddAdminModalVisible(false)
                }}
                disabled={isAddingSubAdmin}
              >
                <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{marginLeft: 8}}
                loading={isAddingSubAdmin}
              >
                <span style={{fontSize: 13, fontWeight: 500}}>Create Employee</span>
              </Button>
            </Form.Item>

          </div>
        </Form>
      </Modal>

      <Modal
        visible={editAdminModalVisible}
        maskClosable={false}
        title={"Edit Employee"}
        destroyOnClose={true}
        className={"editAdminModal"}
        width={600}
        centered={true}
        onCancel={() => {setEditAdminModalVisible(false)}}
        footer={null}
        bodyStyle={{paddingBottom: 0}}
      >
        {currentAdminData ?
          <Form
            name={"editAdmin"}
            onFinish={handleEditAdmin}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <div style={{fontWeight: 600, fontSize: 13, marginRight: 10, width: 110, textAlign: "end", marginTop: -20}}>Full Name:</div>
              <Form.Item
                name={"name"}
                rules={[
                  {required: true, message: 'Required'},
                  {type: 'string', min: 2, message: "Full name must have at least 2 characters."},
                  {type: 'string', max: 60, message: "Full name is too long."},
                ]}
                style={{width: "100%"}}
                validateTrigger={"onSubmit"}
                initialValue={currentAdminData.name}
              >
                <Input
                  disabled={true}
                  prefix={<UserOutlined/>}
                  placeholder="Enter employee name"
                />
              </Form.Item>
            </div>

            <div style={{display: "flex", alignItems: "center"}}>
              <span style={{fontWeight: 600, fontSize: 13, marginRight: 10, width: 110, textAlign: "end", marginTop: -20}}>Email:</span>
              <Form.Item
                name={"email"}
                rules={[
                  {required: true, message: 'Required'},
                  {type: 'email', message: 'Please enter a valid email address'},
                ]}
                style={{width: "100%"}}
                validateTrigger={"onSubmit"}
                initialValue={currentAdminData.email}
              >
                <Input
                  disabled={true}
                  prefix={<MailOutlined/>}
                  placeholder="Enter employee email"
                />
              </Form.Item>
            </div>

            <div style={{display: "flex", alignItems: "center"}}>
              <span style={{fontWeight: 600, fontSize: 13, marginRight: 10, width: 110, marginTop: -20, textAlign: "end"}}>Permissions:</span>
              <Form.Item
                name={"permissions"}
                rules={[
                  {required: true, message: 'At least one must be checked!'},
                ]}
                style={{width: "100%"}}
                className={"permissionCheckboxes"}
                initialValue={currentAdminData.permissions}
              >
                <Checkbox.Group>
                  <Row>
                    <Col span={6}>
                      <Checkbox
                        value="orders"
                      >
                        <span style={{fontSize: 13, fontWeight: 500}}>Orders</span>
                      </Checkbox>
                    </Col>
                    {user?.producer?.hasBulkFunction &&
                      <Col span={9}>
                        <Checkbox
                          value="bulk"
                        >
                          <span style={{fontSize: 13, fontWeight: 500}}>Calculation</span>
                        </Checkbox>
                      </Col>
                    }
                    {user?.producer?.hasNestingFunction &&
                      <Col span={9}>
                        <Checkbox
                          value="nesting"
                        >
                          <span style={{fontSize: 13, fontWeight: 500}}>Nesting</span>
                        </Checkbox>
                      </Col>
                    }
                    <Col span={6}>
                      <Checkbox
                        value="materials"
                      >
                        <span style={{fontSize: 13, fontWeight: 500}}>Materials</span>
                      </Checkbox>
                    </Col>
                    <Col span={9}>
                      <Checkbox
                        value="customers"
                      >
                        <span style={{fontSize: 13, fontWeight: 500}}>Customer Relations</span>
                      </Checkbox>
                    </Col>
                    <Col span={9}>
                      <Checkbox
                        value="settings"
                      >
                        <span style={{fontSize: 13, fontWeight: 500}}>Company Settings</span>
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
              <Form.Item>
                <Button
                  onClick={() => {
                    setEditAdminModalVisible(false)
                  }}
                  disabled={isEditingSubAdmin}
                >
                  <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{marginLeft: 8}}
                  loading={isEditingSubAdmin}
                >
                  <span style={{fontSize: 13, fontWeight: 500}}>Save Changes</span>
                </Button>
              </Form.Item>

            </div>
          </Form>
        :
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 80 }} spin />}/>
          </div>
        }
      </Modal>
    </Layout>
  )
}