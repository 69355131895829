import React, {useEffect} from 'react'
import { Switch, Route } from 'react-router-dom'
import { PrivateRoute } from '../../routes/helpers'
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useSelector, useDispatch} from "react-redux";
import {fetchSelf} from "../../actions/authActions";

const App = ({ routes }) => {

  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  useEffect(()=> {
    if(user && user.role !== "headAdmin") {
      dispatch(fetchSelf()).catch(()=>{})
    }
  }, [])

  return(
    <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={3200}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
      />
        <Switch>
          {routes.map(
            (route, index) =>
              route.isPublic ? (
                <Route
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                />
              ) : (
                <PrivateRoute
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                />
              )
          )};
        </Switch>
    </React.Fragment>)
}

export default App
