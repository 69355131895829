import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Row, Col, Card, Button, Form, Input, Spin} from 'antd'
import {HOME_PAGE_URL, S3_BUCKET_URL} from "../../constants";

import logoPlaceholder from '../../assets/images/logo-placeholder.svg'
import hefLogo from '../../assets/logo/hefeos_logo_03.svg'
import {LinkWrapper, CardTitle, Logo} from './styles'
import {loginUser, setRedirect, getCompanyBasicInfo, checkPermissions} from '../../actions/authActions'
import {Link} from '../../components'
import {LoadingOutlined, LockOutlined, UserOutlined} from "@ant-design/icons";
import "./style.css"

import DownChevron from "../../assets/down-chevron.svg";
import HefeosLogo from "../../assets/Hefeos-IQT-Dark.svg";

class LoginPage extends Component {
  static propTypes = {
    loginUser: PropTypes.func,
    isLoggingIn: PropTypes.bool,
  }

  state = {
    form: {
      email: '',
      password: '',
    },
    hasOrderPermission: false,
    basicInfo: {
      name: '',
      country: '',
      logoUrl: logoPlaceholder,
      showLoginForm: true
    },
    isFetchingPermissions: true,
    isFetchingDomain: false
  }

  componentDidMount() {
    this.setState({
      isFetchingPermissions: true
    })
    this.props.checkPermissions({}).then((hasOrderPermission)=>{
      this.setState({
        isFetchingPermissions: false,
        hasOrderPermission: hasOrderPermission
      })
    }).catch(()=>{
      this.setState({
        isFetchingPermissions: false,
      })
      window.location.replace(HOME_PAGE_URL)
    });
    this.setState({
      isFetchingDomain: true
    })
    this.props.getCompanyBasicInfo({}).then((basicInfo)=>{
      this.setState({
        isFetchingDomain: false
      })
      this.setState({
        basicInfo: {
          name: basicInfo.name,
          country: basicInfo.country,
          logoUrl: `${S3_BUCKET_URL}${basicInfo.logoUrl}`,
          showLoginForm: true
        },
      });
      document.title = `${basicInfo.name} @ Hefeos`
    }).catch(()=>{
      this.setState({
        basicInfo: {
          name: 'Company not found',
          country: '',
          logoUrl: logoPlaceholder,
          showLoginForm: false,
        },
      });
    });
  }

  handleClick = values => {
    const {loginUser} = this.props

    this.setState({
      form: {
        email: values.email,
        password: values.password
      }
    }, () => {
      loginUser({form: this.state.form, redirectTo: this.props.redirect})
    });
  }


  render() {
    const { basicInfo } = this.state;
    const {isLoggingIn, history} = this.props
    const {isFetchingPermissions, hasOrderPermission, isFetchingDomain} = this.state
    return (
      <React.Fragment>
        <div className={"publicWrapper"}>
          <div className={"tiledBackground"}>
            <Card className={"publicCard"}>
              <Spin
                spinning={isFetchingPermissions || isFetchingDomain}
                wrapperClassName={"loginLoadingWrapper"}
                indicator={<LoadingOutlined style={{ fontSize: 82, marginLeft: -41, marginTop: 10 }} spin />}
              >
              <div>
              <div style={{textAlign: 'center'}}>
                <div><a href="https://hefeos.com" target="_blank"><img src={HefeosLogo} alt="Hefeos Logo" width={300}/></a></div>
                <div><img src={DownChevron} alt="arrow down" width={30} style={{marginTop: "2em", marginBottom: "0.5em"}} /></div>
                <Logo src={basicInfo.logoUrl} alt={"Company Logo"}/>
                <h2>{basicInfo.name}</h2>
                <h4>{basicInfo.country}</h4>

                {!basicInfo.showLoginForm ? <a href = {HOME_PAGE_URL}>Go to Hefeos home</a> : ''}
              </div>

              <div>
                <Form
                  name={'login'}
                  onFinish={this.handleClick}
                >
                  <span className={"loginLabel"}>Email</span>
                  <Form.Item
                    name={'email'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'email', message: 'Please enter a valid email address'},
                    ]}
                    validateTrigger={'onSubmit'}
                    className={"formItem"}
                  >
                    <Input
                      placeholder="Enter your email address"
                      className={"loginInputText"}
                      prefix={<UserOutlined/>}
                      autoComplete={'email'}
                    />
                  </Form.Item>
                  <span className={"loginLabel"}>Password</span>
                  <Form.Item
                    name={'password'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 6, message: 'Password must be 6 characters or longer'},
                      {type: 'string', max: 60, message: 'Password must be 60 characters or shorter'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input.Password
                      className={"loginInputText"}
                      prefix={<LockOutlined/>}
                      placeholder="Enter your password"
                      autoComplete={'current-password'}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      style={{width: '100%', marginTop: 5}}
                      loading={isLoggingIn}
                      type="primary"
                      htmlType="submit"
                      className={"loginText darkButton"}
                    >
                      Log in
                    </Button>
                  </Form.Item>
                </Form>
                <Spin
                  spinning={isFetchingPermissions}
                  indicator={
                    <div align={"center"}>
                      <LoadingOutlined style={{ fontSize: 28, marginLeft: -14 }} spin />
                    </div>
                  }
                >
                  {hasOrderPermission ?
                    <LinkWrapper>
                      <Link style={{fontWeight: 1000, fontSize: 17}} to="/register" history={history}>
                        Sign up for Free!
                      </Link>
                    </LinkWrapper>
                    :
                    <div style={{height: isFetchingPermissions ? 32 : 0}} />
                  }
                </Spin>
                <Row>
                  <Col span={12}>
                    <LinkWrapper style={{marginTop: hasOrderPermission ? 20 : 10}}>
                      <Link to="/forgot-password" history={history}>
                        Forgot Password?
                      </Link>
                    </LinkWrapper>
                  </Col>
                  <Col span={12}>
                    <LinkWrapper style={{marginTop: hasOrderPermission ? 20 : 10}}>
                      <Link to="/resend-email" history={history}>
                        Resend Verification Email
                      </Link>
                    </LinkWrapper>
                  </Col>
                </Row>


                <LinkWrapper style={{marginTop: "2em"}}>
                  <a  style={{fontWeight: 600, fontSize: 13}} href="https://hefeos.com" target="_blank">
                    What is Hefeos Instant Quoting Tool?
                  </a>
                </LinkWrapper>

              </div>
              </div>
              </Spin>
            </Card>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  redirect: state.auth.redirect,
  isLoggingIn: state.auth.loggingIn,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({loginUser, setRedirect, checkPermissions, getCompanyBasicInfo}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
)
