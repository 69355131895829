import {Button, Card, Input, Spin, Tooltip} from "antd";
import {S3_BUCKET_URL} from "../../../constants";
import twoD from "../../../assets/icons/2D.svg";
import treeD from "../../../assets/icons/3D.svg";
import {getPlaceholderImage} from "../../../utils/utility";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FilePdfOutlined,
  SearchOutlined
} from "@ant-design/icons";
import React, {useState} from "react";
import {CircularProgress} from "@mui/material";
import {Trans} from "react-i18next";
import CheckIcon from '@mui/icons-material/Check';


export const OrderDetailsPartProcessedPartsList = (props) => {
  const {
    parts, showOtherItem, showDetailsItem, unprocessedPartsLength,
    isEditing, editMap, editItemFunc, handleRemovePartOnEdit,
    isFetchingProcessedList, orderState, role, handleShowDownloadFiles,
    handleQuantityChange
  } = props;

  return (
    <Spin
      spinning={isFetchingProcessedList === true}
      indicator={<CircularProgress sx={{color: "#1890ff"}} size={48} thickness={2} style={{marginTop: -24, marginLeft: -24}}/>}
    >
    <div
      className={"quotedItemsList"}
      style={{
        minHeight: unprocessedPartsLength === 0 ? "550px" : "250px",
        maxHeight: unprocessedPartsLength === 0 ? "calc(100vh - 140px)" : "calc(50vh - 90px)",
        padding: "0 6px 0 6px"
      }}
    >
      {parts.map(part => (
        <Card
          className={isEditing && editMap.items[part.id] ?
            (editMap.items[part.id].state === "declined" ? "declinedCard bendCard" : "changedCard bendCard")
            :
            part.state === "declined" ? "declinedCard bendCard" : (part.state === "edited" && (orderState === "quoted" || orderState === "awaitingQuote" || orderState === "awaitingConfirmation") ? "editedCard bendCard" : "bendCard")
          }
          style={{height: 80, marginTop: 10, marginBottom: 10}}
          bodyStyle={{
            padding: "8px 12px 8px 12px",
            height: "100%",
            backgroundColor: (role === "producerAdmin" || role === "producerSubAdmin") && (orderState === "accepted" || orderState === "inProgress") && part.producedQuantity === part.quantity ? "#f8fff8" : "rgba(0, 0, 0, 0)"
          }}
          key={part.id}
        >
          <div style={{display: "flex", width: "100%"}}>
            <div
              style={{
                border: "1px solid rgb(205, 205, 205)",
                borderRadius: 3,
                marginRight: 8,
                background: "white",
                display: "flex",
                alignItems: "center"
              }}
            >
              {part.fileType === "dxf" &&
                <div>
                  {part.dxfData.pngFilePath ?
                    <div
                      style={{
                        width: 55,
                        height: 55,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <div>
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: 62,
                            maxHeight: 55,
                            padding: 3,
                          }}
                          src={S3_BUCKET_URL + part.dxfData.pngFilePath}
                          alt={"part"}
                        />
                      </div>
                    </div>
                    :
                    <div
                      style={{
                        width: 55,
                        height: 55,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <img
                        style={{
                          width: 45,
                          height: 45,
                          padding: 3,
                          marginTop: 5,
                          marginLeft: 4
                        }}
                        src={twoD}
                        alt={"part"}
                      />
                    </div>
                  }
                </div>
              }
              {part.fileType === "step" &&
                <div>
                  {part.stepData.filePaths.pngFilePath ?
                    <img style={{width: 55, height: 55, padding: 3}} src={S3_BUCKET_URL + part.stepData.filePaths.pngFilePath} alt={"part"}/>
                    :
                    <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                      <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={treeD} alt={"part"}/>
                    </div>
                  }
                </div>
              }
              {(part.fileType === "other" || (!part.fileType && part.processingType === "manual")) &&
                <div style={{width: 55, height: 55, display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <img style={{width: 45, height: 45, padding: 3, marginTop: 5, marginLeft: 4}} src={getPlaceholderImage(part.partType)} alt={"part"}/>
                </div>
              }
            </div>
            <div style={{width: "100%"}}>
              <div style={{width: "100%"}}>
                <table style={{width: "100%"}}>
                  <tbody>
                  <tr>
                    <td style={{width: "calc(100% - 100px)", maxWidth: 150}}>
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          paddingRight: 10,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                          whiteSpace: "nowrap",
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        {part.fileName || part.name}
                        {(role === "producerAdmin" || role === "producerSubAdmin") && part.producedQuantity === part.quantity && (orderState === "accepted" || orderState === "inProgress") &&
                          <CheckIcon style={{ color: "#51b364", fontSize: 22, position: "relative", bottom: 2, left: 4 }}/>
                        }
                      </div>
                    </td>
                    <td style={{width: 100}}>
                      <div style={{display: "flex", height: 24, justifyContent: "flex-end"}}>
                        {isEditing ?
                          (part.state === "declined" || editMap.items[part.id] && editMap.items[part.id].state === "declined" ?
                            <div style={{height: 24}}/>
                            :
                            <div style={{display: "flex", height: 24}}>
                              <EditOutlined
                                onClick={() => {
                                  editItemFunc(part)
                                }}
                                style={{fontSize: 16, marginRight: 5, color: "#1890ff", padding: 2}}
                              />
                              <DeleteOutlined
                                onClick={() => {
                                  handleRemovePartOnEdit(part)
                                }}
                                style={{fontSize: 16, color: "#ff4d4f", padding: 2}}
                              />
                            </div>
                          )
                          :
                          (part.state === "declined" || part.partType === "other" ?
                            <div style={{height: 24}}/>
                            :
                            <div style={{display: "flex", height: 24}}>
                              {part.drawingPDFFile?.drawingPDFFilePath &&
                                <Tooltip
                                  color={"rgba(82,82,82,0.9)"}
                                  overlayClassName={"small-tooltip"}
                                  size={"small"}
                                  title={<span className={"text12-500"} style={{color: "white"}}>Part Drawing</span>}
                                >
                                  <Button
                                    size={"small"}
                                    type={"primary"}
                                    className={"exportPdfButton"}
                                    href={`${S3_BUCKET_URL + part.drawingPDFFile.drawingPDFFilePath}`}
                                  >
                                    <div>
                                      <FilePdfOutlined style={{fontSize: 12}}/>
                                    </div>
                                </Button>
                                </Tooltip>
                              }
                              {((role === "producerAdmin" || role === "producerSubAdmin") && part.originType !== "additional") &&
                                <Button
                                  size={"small"}
                                  type={"primary"}
                                  onClick={() => handleShowDownloadFiles(part)}
                                >
                                  <DownloadOutlined style={{fontSize: 13}}/>
                                </Button>
                              }
                              <Button
                                size={"small"}
                                type={"primary"}
                                style={{marginLeft: 5}}
                                onClick={()=>{
                                  if(part.fileType === "other" || (part.processingType === "manual" && !part.fileType)) {
                                    showOtherItem(part)
                                  } else {
                                    showDetailsItem(part)
                                  }
                                }}
                              >
                                <div>
                                  <SearchOutlined style={{fontSize: 13}}/>
                                </div>
                              </Button>
                            </div>
                          )
                        }
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div>
                {part.partType === "sheetMetal" ?
                  <div
                    style={{fontSize: 12, fontWeight: 500}}
                  >
                    Material: <span style={{fontWeight: 600}}><Trans>{part.selectedMaterial.grade}</Trans> ({part.selectedMaterial.thickness}mm)</span>
                  </div>
                  :
                  (part.partType === "machined" || part.partType === "profile" ?
                    <div
                      style={{fontSize: 12, fontWeight: 500}}
                    >
                      Material: <span style={{fontWeight: 600}}><Trans>{part.selectedMaterial?.grade || part.materialGrade}</Trans></span>
                    </div>
                    :
                    <div
                      style={{fontSize: 12, fontWeight: 500}}
                    >
                      Standard: <span style={{fontWeight: 600}}>{part.standard}</span>
                    </div>
                  )
                }
                <div style={{display:"flex", justifyContent: "space-between"}}>
                  {(role === "producerAdmin" || role === "producerSubAdmin") && (orderState === "accepted" || orderState === "inProgress") ?
                    <div style={{fontSize: 12, fontWeight: 500, marginRight: 8}}>
                      Produced Quantity:
                      <span style={{fontWeight: 600, marginLeft: 4, color: part.producedQuantity === part.quantity ? "#047a04" : "rgba(0, 0, 0, 0.85)"}}>
                        {part.producedQuantity}/{part.quantity}
                        <EditOutlined
                          className={"editQuantityButton"}
                          onClick={() => {
                            handleQuantityChange(part)
                          }}
                        />
                      </span>
                    </div>
                    :
                    <div style={{fontSize: 12, fontWeight: 500, marginRight: 8}}>
                      Quantity:
                      <span style={{fontWeight: 600, marginLeft: 4}}>{part.quantity}</span>
                    </div>
                  }
                  {isEditing && editMap.items[part.id] ?
                    <div
                      style={{fontSize: 12, fontWeight: 500}}
                    >
                      1pc:
                      <span style={{fontWeight: 600, color: "#047a04"}}>
                        {editMap.items[part.id].newPrice} €
                      </span>
                    </div>
                    :
                    <div
                      style={{fontSize: 12, fontWeight: 500}}
                    >
                      1pc:
                      <span style={{
                        fontWeight: 600,
                        color: part.state === "edited" && (orderState === "quoted" || orderState === "awaitingQuote" || orderState === "awaitingConfirmation") ? "#047a04" : "rgba(0, 0, 0, 0.85)"}}
                      >
                        {(part.state === "declined" ? 0 : (parseFloat(part.pricePerPiece) || 0)).toFixed(2)} €
                      </span>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
    </Spin>
  )
}