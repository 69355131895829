import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Card, Row, Col, Checkbox, Tooltip, Input,
  Spin, Grid, Button, Tag, Form
} from 'antd';
import {NestingGrid} from "../../NestingGrid";

import "./style.css"
import {NestingList} from "../../NestingList";
import {
  CaretDownOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
  QuestionCircleFilled,
  SearchOutlined
} from "@ant-design/icons";
import {
  checkCurrentNestingJob,
  fetchAcceptedOrdersForNesting,
  fetchCurrentNestingJob, setCurrentNestingJob,
  reFetchCurrentNestingJob
} from "../../../../../actions/nestingActions";
import {useDispatch, useSelector} from "react-redux";
import {debounce, escapeRegExp} from "lodash";
import {formatDateTime} from "../../../../../utils/utility";
import CalendarTodayRounded from "@mui/icons-material/CalendarTodayRounded";
import {withStyles} from "@material-ui/core/styles";
import TooltipMui from "@mui/material/Tooltip";

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "#07223d"
  },
  arrow: {
    color: "#07223d"
  }
})(TooltipMui);

export const NewNesting = (props) => {
  const { selectedTab, onViewPlate, onStartNesting, onViewLeftovers, onAcceptJob } = props

  const [searchForm] = Form.useForm();

  const [checkedOrders, setCheckedOrders] = useState({});
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedLen, setCheckedLen] = useState(0);
  const [allOrders, setAllOrders] = useState({})
  const [allOrdersLen, setAllOrdersLen] = useState(0);
  const [nonNestedPartsCount, setNonNestedPartsCount] = useState(0);

  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchingOrders, setSearchingOrders] = useState(false);
  const [ordersFiltered, setOrdersFiltered] = useState(false);

  const [checkedOrdersArray, setCheckedOrdersArray] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);

  const dispatch = useDispatch()
  const screens = Grid.useBreakpoint();

  const orders = useSelector(state => state.nesting.orders)
  const isFetchingOrders = useSelector(state => state.nesting.isFetchingOrders)

  const isCurrentlyNesting = useSelector(state => state.nesting.currentNesting.isCurrentlyNesting);
  const lastChangeTime = useSelector(state => state.nesting.currentNesting.lastChangeTime);
  const nestedPlates = useSelector(state => state.nesting.currentNesting.nestedPlates);
  const nonNestedParts = useSelector(state => state.nesting.currentNesting.nonNestedParts);
  const error = useSelector(state => state.nesting.currentNesting.error);
  const userName = useSelector(state => state.nesting.currentNesting.userName);
  const isFetchingCurrentNesting = useSelector(state => state.nesting.isFetchingCurrentNesting);

  const lastChangeTimeRef = useRef(lastChangeTime)

  useEffect(()=>{
    if(nonNestedParts) {
      let nonCount = nonNestedParts.reduce((total, item) => total + item.quantity, 0) || 0
      setNonNestedPartsCount(nonCount)
    }
  }, [nonNestedParts])

  const isDescendantOfNestItem = (child) => {
    let parent = child.parentNode;
    while (parent) {
      if (parent.className === "nestingItem")
        return true;
      parent = parent.parentNode;
    }
    return false;
  };

  const handleClickOutsideButton = (e) => {
    if (checkedOrdersArray.length > 1) {
      let dropdownButton = document.getElementById('dropdownButton');
      let isClickInside = dropdownButton.contains(e.target);
      if (!isClickInside) {
        if (!isDescendantOfNestItem(e.target)) {
          setShowDropDown(false);
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideButton)
    return () => {
      document.removeEventListener("click", handleClickOutsideButton)
    }
  })

  const resetAll = () => {
    setCheckedOrders({})
    setCheckAll(false)
    setIndeterminate(false)
    setCheckedLen(0)
    setOrdersFiltered(false)
    setSearchingOrders(false)
    searchForm?.setFieldsValue({
      search: ""
    })
  }

  useEffect(() => {
    lastChangeTimeRef.current = lastChangeTime
  }, [lastChangeTime])

  useEffect(() => {
    const interval = setInterval(() => {
      if(!isFetchingCurrentNesting && lastChangeTimeRef.current) {
        dispatch(checkCurrentNestingJob()).then((data) => {
          if(data.lastChangeTime !== lastChangeTimeRef.current) {
            dispatch(reFetchCurrentNestingJob()).then((data)=>{
              dispatch(setCurrentNestingJob(data))
            }).catch(()=>{})
          }
        }).catch(()=>{})
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [])

  useEffect(()=>{
    if(selectedTab === "1") {
      setFilteredOrders(orders)
      dispatch(fetchCurrentNestingJob()).catch(()=>{})
      dispatch(fetchAcceptedOrdersForNesting()).then((orders) => {
        let tmpOrder = {}
        let orderCount = 0
        for (let order of orders) {
          tmpOrder[order.id] = {id: order.id, name: order.orderName, parts: order.remainingParts}
          orderCount += 1
        }
        setFilteredOrders(orders)
        setAllOrders(tmpOrder)
        setAllOrdersLen(orderCount)
      }).catch(() => {
      })
    }
    resetAll()
  }, [selectedTab])

  useEffect(()=>{
    let tmpOrder = {}
    let orderCount = 0
    let checkedCount = 0
    for(let order of filteredOrders) {
      tmpOrder[order.id] = {id: order.id, name: order.orderName, parts: order.remainingParts}
      orderCount += 1

      if(checkedOrders[order.id]) {
        checkedCount += 1
      }
    }

    setCheckAll(!!checkedCount && checkedCount === orderCount);
    setIndeterminate(!!checkedCount && checkedCount < orderCount);

    setAllOrders(tmpOrder)
  },[filteredOrders])

  useEffect(() => {
    setCheckedOrdersArray(Object.values(checkedOrders))
  }, [checkedOrders])

  const onCheckAllChange = e => {
    let tmpOrder = {...checkedOrders}
    if(e.target.checked) {
      for(let order of Object.values(allOrders)) {
        tmpOrder[order.id] = {id: order.id, name: order.name, parts: order.parts}
      }
    }
    else {
      for(let order of Object.values(allOrders)) {
        if(tmpOrder[order.id]) {
          delete tmpOrder[order.id]
        }
      }
    }
    setCheckedOrders(tmpOrder);
    setCheckedLen(Object.keys(tmpOrder).length)
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onCheck = (id, value, name, parts) => {
    let checked = {...checkedOrders}
    if (!value) {
      if(checked[id]) {
        delete checked[id]
      }
    } else {
      checked[id] = {id, name, parts}
    }
    let totalCount = 0;
    let checkedCount = 0;


    for(let order of Object.values(allOrders)) {
      totalCount += 1
      if(checked[order.id]) {
        checkedCount += 1
      }
    }

    const checkedLen = Object.keys(checked).length

    setCheckedLen(checkedLen)
    setCheckedOrders({...checked});
    setIndeterminate(!!checkedCount && checkedCount < totalCount);
    setCheckAll(checkedCount === totalCount);
  };

  const filterOrders = (data) => {
    if(!data.text) {
      setFilteredOrders(data.orders)
      setOrdersFiltered(false)
    } else {
      const orders = data.orders.filter(item => (`${item.orderName} ${item.customerName}`).toLowerCase().search(escapeRegExp(data.text.toLowerCase())) !== -1)
      setFilteredOrders(orders)
      setOrdersFiltered(true)
    }
    setSearchingOrders(false)
  }

  const debounceSearchOrders = useCallback(
    debounce(data => filterOrders(data), 800),
    []
  );

  const searchOrders = (text) => {
    setSearchingOrders(true)
    debounceSearchOrders({orders,text})
  }

  return (
    <React.Fragment>
      <Spin
        spinning={isCurrentlyNesting}
        wrapperClassName={"fetchingOrderSpin dimmerWrapper"}
        indicator={
          <div style={{position: "absolute", top: "35%", left: "50%", width: 400, transform: "translateX(-50%)"}}>
            <LoadingOutlined style={{ fontSize: 72 }} spin />
            <div style={{fontSize: 27, fontWeight: 500, marginTop: 10}}>Nesting Job in Progress</div>
          </div>
        }
      >
        <Card className={"tabMaterialCard yesSelect"} bodyStyle={{paddingRight: 0, paddingBottom: 0}}>
          <div style={{height: 'calc(100vh - 95px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
            <Row>
              <Col xxl={0} xl={0} lg={24} md={24} sm={24} xs={24}>
                <Card style={{marginBottom: 15, height: 60}} bodyStyle={{padding: "8px 15px 8px 15px"}}>
                  <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{display: "flex"}}>
                      <div style={{fontSize: 15, fontWeight: 500, whiteSpace: "nowrap", marginRight: 8}}>
                        Nesting Job
                      </div>
                      {!isCurrentlyNesting && lastChangeTime && (nestedPlates.length > 0 || nonNestedParts.length > 0) &&
                        <div style={{marginLeft: 2, marginTop: 2}}>
                          <CustomTooltip
                            placement={"bottom"}
                            arrow
                            title={
                              <div align={"center"}>
                                <div style={{fontSize: 13, fontWeight: 500}}>
                                  Current Nesting Job Done:
                                </div>
                                <div style={{fontSize: 14, fontWeight: 500}}>
                                  {formatDateTime(lastChangeTime)}
                                </div>
                                {userName &&
                                  <div style={{fontSize: 14, fontWeight: 500}}>
                                    by {userName}
                                  </div>
                                }
                              </div>
                            }>
                          <CalendarTodayRounded style={{fontSize: 18, color: "#1890ff"}}/>
                          </CustomTooltip>
                        </div>
                      }
                    </div>
                    <div>
                      <Button
                        type={"primary"}
                        style={{
                          height: 40,
                          width: 300,
                        }}
                        disabled={checkedLen === 0}
                        onClick={() => {
                          onStartNesting(checkedOrders)
                        }}
                      >
                        <div style={{fontSize: 16, fontWeight: 600}}>
                          Start Nesting
                        </div>
                      </Button>
                    </div>
                    {checkedOrdersArray.length === 0 && <div align={"right"} style={{width: 140, marginLeft: 35}}/>}
                    {checkedOrdersArray.length === 1 &&
                      <div align={"right"} style={{width: 140, marginLeft: 35}}>
                        <div className={"nestingCard"} style={{height: 40, paddingRight: 0}}>
                          <Tag align={"left"} style={{width: 90, lineHeight: "18px"}} key={checkedOrdersArray[0].id} color="#1890ff" closable onClose={()=>{onCheck(checkedOrdersArray[0].id, false)}}>
                            <span style={{fontWeight: 500, display: "inline-block", width: 60, minWidth: 60}}>{checkedOrdersArray[0].name}</span>
                          </Tag>
                        </div>
                      </div>
                    }
                    {checkedOrdersArray.length > 1 &&
                      <div align={"left"} style={{width: 140, marginLeft: 35}}>
                        <div className={"nestingCard hoverPointer"} style={{height: 40}}>
                          <div className={"centeredCol"} id={"dropdownButton"} onClick={() => {
                            setShowDropDown(prev => !prev);
                          }}>
                            <span style={{ whiteSpace: "nowrap" }}>View Orders <CaretDownOutlined /></span>
                          </div>
                        </div>
                        <div className={"dropdownOrdersWrapper"} style={{ display: showDropDown ? "block" : "none" }}>
                          <div className={"dropdownOrders"}>
                            {checkedOrdersArray.map(item => {
                              return(
                                <Tag align={"left"}
                                     style={{width: 90, lineHeight: "18px"}}
                                     key={item.id}
                                     color="#1890ff"
                                     closable
                                     onClose={()=>{onCheck(item.id, false)}}
                                >
                                  <span style={{fontWeight: 500, display: "inline-block", width: 60, minWidth: 60}}>{item.name}</span>
                                </Tag>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </Card>
              </Col>
            </Row>
            <Row style={{height: screens["xl"] ? '100%' : "calc(100% - 75px)"}} gutter={10}>
              <Col xxl={8} xl={8} lg={10} md={24} sm={24} xs={24} style={{paddingBottom: screens["lg"] ? 25 : 10}}>
                <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                  <div style={{fontSize: 13, fontWeight: 500}}>
                    Search orders:
                  </div>
                  <div style={{marginLeft: 8, fontSize: 13, fontWeight: 500}}>
                    <Form
                      form={searchForm}
                    >
                      <Form.Item
                        name={"search"}
                        style={{marginBottom: 0, height: 28}}
                      >
                        <Input
                          autoComplete="off"
                          disabled={isFetchingOrders}
                          prefix={<SearchOutlined/>}
                          placeholder={"Type here to search"}
                          style={{height: 28, marginTop: -2}}
                          onChange={(e)=>searchOrders(e.target.value)}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                </div>
                <div className={"nestingListCard"} style={{height: 'calc(100% - 38px)', overflowX: "hidden"}}>
                  <div style={{
                    background: "white",
                    padding: 6,
                    width: "100%",
                    height: 45,
                    borderRadius: "3px 3px 0 0",
                    marginBottom: 5,
                    borderBottom: "1px solid rgb(205, 205, 205)"
                  }}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", height: "100%"}}>
                      <div style={{display: "flex", alignItems: "center", height: "100%"}}>
                        <div style={{marginLeft: 8}}>
                          <Checkbox disabled={isFetchingOrders || orders.length === 0} indeterminate={indeterminate} checked={checkAll} onChange={onCheckAllChange}/>
                        </div>
                        <div className={"selectAllText"} style={{marginLeft: 8, fontSize: 13, fontWeight: 500}} onClick={(e) => {
                          e.stopPropagation();
                          e.target.checked = !checkAll;
                          onCheckAllChange(e);
                        }}>
                          Select all {ordersFiltered && "filtered"} orders
                        </div>
                      </div>
                      <div style={{display: "flex", alignItems: "center"}}>
                        <Tooltip
                          overlayClassName={"name-tooltip"}
                          placement={'bottom'}
                          overlayStyle={{width: 300}}
                          getPopupContainer={trigger => trigger.parentNode}
                          color={"rgba(82,82,82,0.9)"}
                          title={
                            <span className={"text12-500"} style={{color: "white"}}>
                              Select the orders that you would like to include in your new nesting job.
                            </span>
                          }
                        >
                          <QuestionCircleFilled style={{fontSize: 18, marginRight: 6, color: "rgba(137,137,137,0.65)"}}/>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div style={{height: "calc(100% - 56px)", minHeight: 400, overflow: "auto", overflowX: "hidden", marginRight: 5}}>
                    <NestingList
                      orders={filteredOrders}
                      isFetchingOrders={isFetchingOrders}
                      searching={searchingOrders}
                      onCheck={onCheck}
                      checkedOrders={checkedOrders}
                    />
                  </div>
                </div>
              </Col>
              <Col xxl={5} xl={4} lg={0} md={0} sm={0} xs={0}>
                <Card
                  bodyStyle={{height: 'calc(100vh - 122px)', minHeight: 488, paddingRight: 10, paddingLeft: 10}}
                >
                  <div align={"center"} style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
                    <div>
                      <div style={{fontSize: 18, fontWeight: 500}}>
                        Create New Nesting Job
                      </div>
                      {!isCurrentlyNesting && lastChangeTime && (nestedPlates.length > 0 || nonNestedParts.length > 0) &&
                        <div>
                          <div style={{fontSize: 12, fontWeight: 500}}>
                            Current Nesting Job Done:
                          </div>
                          <div style={{fontSize: 13, fontWeight: 500}}>
                            {formatDateTime(lastChangeTime)}
                          </div>
                          {userName &&
                            <div style={{fontSize: 13, fontWeight: 500}}>
                              by {userName}
                            </div>
                          }
                        </div>
                      }
                    </div>
                    <div style={{height: 400}}>
                      <div style={{fontSize: 13, fontWeight: 500, marginBottom: -7, color: "#48a2f8", height: 21}}>
                        {checkedLen > 0 &&
                          <span>{checkedLen === allOrdersLen ? "All orders selected" : `${checkedLen} ${checkedLen === 1 ? "order" : "orders"} selected`}</span>
                        }
                      </div>
                      <div className={"arrowWrapper"}>
                        <div className={checkedLen > 0 ? "resize-h blueResize" : "resize-h"} />
                        <div className={checkedLen > 0 ? "arrow-left blueLeft" : "arrow-left"} />
                      </div>
                      <Button
                        type={"primary"}
                        style={{
                          height: 40,
                          width: "100%"
                        }}
                        disabled={checkedLen === 0}
                        onClick={() => {
                          onStartNesting(checkedOrders)
                        }}
                      >
                        <div style={{fontSize: 16, fontWeight: 600}}>
                          Start Nesting
                        </div>
                      </Button>
                      {checkedLen > 0 &&
                        <div align={"center"} style={{marginTop: 15}}>
                          <div className={"nestingCard"} style={{maxHeight: 250, paddingRight: 0}}>
                          {checkedOrdersArray.map(item => {
                            return(
                              <Tag align={"left"} style={{width: 90, lineHeight: "18px"}} key={item.id} color="#1890ff" closable onClose={()=>{onCheck(item.id, false)}}>
                                <span style={{fontWeight: 500, display: "inline-block", width: 60, minWidth: 60}}>{item.name}</span>
                              </Tag>
                            )
                          })}
                          </div>
                        </div>
                      }
                    </div>
                    <div/>
                  </div>
                </Card>
              </Col>
              <Col xxl={11} xl={12} lg={14} md={24} sm={24} xs={24} style={{paddingBottom: screens["lg"] ? 25 : 15}}>
                <div style={{display: "flex", alignItems: "center", marginBottom: 6, justifyContent: "space-between"}}>
                  <div style={{fontSize: 18, fontWeight: 500}}>
                    Nested Sheets:
                  </div>
                  <div style={{display:"flex", alignItems: "center"}}>
                    {error &&
                      <div style={{marginRight: 8, marginTop: 4}}>
                        <Tooltip
                          color={"#ffc107"}
                          overlayClassName={"small-tooltip nestingErrorTooltip"}
                          size={"small"}
                          style={{width: 400}}
                          title={<span className={"text14-500"} style={{color: "#333f48"}}>Latest Error: {error}</span>}
                        >
                          <ExclamationCircleFilled style={{color: "#ffc107", fontSize: 22}}/>
                        </Tooltip>
                      </div>
                    }
                    <div>
                      <Button
                        className={"leftoverButton"}
                        disabled={isFetchingCurrentNesting || nonNestedPartsCount === 0}
                        onClick={() => {onViewLeftovers(nonNestedParts)}}
                      >
                        <span style={{fontSize: 13, fontWeight: 600}}>
                          Leftover Parts: {nonNestedPartsCount}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className={"nestingCard"} style={{height: 'calc(100% - 38px)', minHeight: 400, overflowX: "hidden"}}>
                  <NestingGrid
                    gridItems={nestedPlates}
                    isFetchingItems={isFetchingCurrentNesting}
                    buttonText={"Accept"}
                    onClick={onAcceptJob}
                    viewPlate={onViewPlate}
                    plateState={"current"}
                  />
                </div>
              </Col>
            </Row>
          </div>
      </Card>
      </Spin>
    </React.Fragment>
  )
}