import Icon from "@ant-design/icons";
import React from "react";


const LeftMouseClick = () => (
    <svg width="0.7em" height="1.4em" viewBox="0 0 1024 1024">
        <g transform="translate(0.000000,1318.000000) scale(0.100000,-0.100000)"
           fill="currentColor" stroke="none">
            <path d="M3010 13169 c-476 -21 -867 -125 -1300 -345 -313 -158 -564 -346
            -831 -622 -434 -448 -700 -960 -830 -1599 -28 -137 -31 -172 -40 -473 -5 -190
            -8 -1781 -6 -3825 3 -3860 -3 -3505 62 -3812 111 -525 345 -994 704 -1413 530
            -619 1358 -1034 2135 -1070 267 -13 2846 -12 3021 0 273 20 590 97 905 220
            102 40 352 171 473 247 231 147 389 276 593 484 390 398 669 903 799 1443 63
            258 67 297 75 696 11 588 4 7187 -9 7301 -22 203 -97 505 -178 722 -278 742
            -830 1359 -1532 1712 -300 152 -570 238 -933 301 -129 22 -201 27 -463 34
            -373 11 -2412 10 -2645 -1z m838 -737 c13 -4 24 -11 25 -15 1 -5 -40 -24 -92
            -44 l-93 -35 -37 34 c-20 18 -40 37 -46 41 -21 17 24 27 120 26 55 -1 110 -4
            123 -7z m1699 -12 c663 -89 1228 -361 1668 -804 439 -443 707 -979 800 -1601
            19 -130 24 -399 30 -1882 l5 -1203 -1645 0 -1645 0 0 478 0 477 109 26 c199
            49 386 156 527 301 99 102 136 154 199 282 71 146 93 230 105 401 5 78 10 682
            10 1370 0 1204 0 1232 -20 1305 -115 423 -371 702 -755 823 -44 14 -89 28
            -100 30 -43 10 638 6 712 -3z m-2325 -212 c48 -43 97 -88 110 -98 17 -14 20
            -22 13 -33 -23 -32 -98 -122 -104 -125 -6 -2 -204 172 -381 335 l-54 50 104
            25 105 25 60 -49 c33 -28 99 -86 147 -130z m-517 -334 c182 -162 340 -305 352
            -317 22 -22 23 -26 15 -139 l-7 -117 -100 91 c-55 50 -179 161 -275 247 -275
            245 -490 442 -490 450 0 9 141 80 160 80 8 1 164 -132 345 -295z m1833 -10
            c207 -43 404 -217 501 -444 54 -126 62 -174 72 -415 11 -275 2 -1788 -11
            -1895 -22 -184 -134 -387 -279 -507 -140 -115 -298 -170 -470 -160 -164 8
            -295 66 -423 186 -114 106 -187 234 -237 411 l-26 95 0 1020 c0 961 1 1025 19
            1100 24 105 48 163 104 259 163 280 447 412 750 350z m-2040 -604 l572 -514 0
            -118 c0 -65 -3 -118 -6 -118 -5 0 -315 276 -584 520 -116 105 -159 143 -530
            476 -140 125 -255 230 -255 232 0 2 31 30 69 62 l70 58 46 -41 c25 -23 304
            -274 618 -557z m-708 -165 c195 -175 378 -339 406 -364 28 -25 236 -212 462
            -415 l412 -370 0 -119 0 -118 -27 20 c-27 19 -330 290 -438 390 -45 41 -480
            431 -653 585 -107 94 -426 382 -549 495 -98 89 -112 105 -101 120 7 9 32 40
            56 69 39 47 46 51 61 39 9 -7 176 -157 371 -332z m-538 -316 c232 -209 675
            -607 1301 -1168 l517 -465 0 -124 0 -124 -142 128 c-79 71 -192 173 -253 228
            -173 156 -177 160 -425 381 -220 197 -698 626 -1085 975 -93 84 -171 161 -173
            171 -4 21 66 162 78 157 5 -2 87 -73 182 -159z m-218 -603 c98 -89 204 -183
            235 -211 216 -193 556 -498 606 -544 33 -31 98 -90 145 -132 47 -41 220 -197
            385 -345 727 -653 915 -822 995 -893 46 -42 107 -97 135 -122 27 -25 60 -54
            72 -65 12 -10 109 -98 217 -194 l195 -175 1 -123 0 -124 -52 48 c-29 26 -111
            99 -183 163 -71 63 -175 156 -230 206 -56 49 -141 126 -190 170 -119 106 -289
            259 -345 311 -25 23 -137 124 -250 225 -113 101 -268 240 -345 309 -77 69
            -207 186 -290 260 -82 73 -213 191 -290 260 -129 117 -1045 939 -1054 947 -5
            4 14 111 29 166 8 25 15 35 23 30 7 -4 93 -79 191 -167z m-32 -773 c123 -110
            288 -258 367 -329 126 -112 369 -331 671 -604 47 -42 105 -94 130 -116 25 -21
            90 -79 145 -129 55 -49 159 -142 230 -205 71 -63 228 -205 350 -315 673 -607
            800 -722 829 -747 l31 -27 -137 -1 -137 0 -314 283 c-697 628 -1153 1038
            -1253 1126 -27 24 -87 78 -134 121 -47 42 -191 171 -320 286 -573 509 -718
            644 -724 676 -8 39 -8 156 0 185 8 30 -2 37 266 -204z m123 -908 c164 -147
            622 -558 725 -650 19 -18 87 -79 150 -136 63 -58 241 -217 395 -354 287 -256
            333 -297 420 -378 l50 -47 -137 0 -137 0 -214 193 c-117 105 -331 298 -476
            427 -146 129 -341 304 -435 389 -94 85 -211 191 -261 235 -49 45 -117 106
            -150 137 -33 30 -120 108 -192 173 l-133 118 0 125 0 124 103 -93 c56 -51 188
            -169 292 -263z m-251 -572 c72 -64 158 -142 191 -173 33 -30 98 -89 145 -131
            250 -224 500 -448 619 -554 l134 -120 -59 -6 c-33 -4 -91 -7 -130 -8 l-71 -1
            -219 196 c-747 669 -741 664 -748 703 -8 47 -8 211 0 211 3 0 65 -53 138 -117z
            m190 -984 c-3 -5 -61 -9 -129 -9 l-123 0 -41 37 -41 36 0 124 0 124 170 -152
            c93 -83 167 -156 164 -160z m6981 -1921 c-7 -1542 -10 -1709 -35 -1875 -49
            -330 -125 -565 -281 -873 -391 -776 -1132 -1325 -1999 -1484 -238 -43 -441
            -49 -1499 -43 -1043 6 -1015 5 -1286 63 -722 157 -1335 581 -1752 1211 -205
            310 -345 675 -418 1083 -24 131 -27 183 -35 530 -5 212 -9 874 -9 1473 l-1
            1087 3660 0 3660 0 -5 -1172z"
            />
        </g>
    </svg>
);

export const LeftMouse = props => <Icon component={LeftMouseClick} {...props} />;