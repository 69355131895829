import React, {useRef} from "react";
import {Button, Card, Col, Row, Form, Input, Select, Spin} from "antd";
import {
  BankOutlined,
  CiCircleOutlined, GlobalOutlined, HomeOutlined, LoadingOutlined,
  LockOutlined,
  MailOutlined,
  PercentageOutlined, PhoneOutlined, UserOutlined,
} from "@ant-design/icons";
import {countries} from "../../../data/countries";
import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTCHA_SITE_KEY} from "../../../constants";


export const CompanyForm = (props) => {
  const { isRegistering } = props;
  const recaptchaRef = useRef(null)

  const handleClick = async (values) => {
    props.handleClick(values).then(()=>{
      if(recaptchaRef?.current) {
        recaptchaRef.current.reset();
      }
    })
  }

  return (
    <Row>
      <Col span={24}>
        <Form
          name={'register-company-personal'}
          onFinish={handleClick}
        >
          <Card
            size={"small"}
            bodyStyle={{paddingBottom: 0}}
          >
            <Row gutter={18}>
              <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                <span className={"loginLabel"}>Email</span>
                <Form.Item
                  name={'email'}
                  rules={[
                    {required: true, message: 'This field is required'},
                    {type: 'email', message: 'Please enter a valid email address'},
                  ]}
                  validateTrigger={'onSubmit'}

                >
                  <Input
                    placeholder="Enter your email address"
                    prefix={<MailOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                <span className={"loginLabel"}>Country</span>
                <Form.Item
                  name={'country'}
                  rules={[
                    {required: true, message: 'This field is required'},
                  ]}
                  validateTrigger={'onSubmit'}
                >

                  <Select
                    showSearch
                    style={{width: '100%'}}
                    prefix={<GlobalOutlined />}
                    placeholder="Select your country"
                    optionFilterProp="children"
                    notFoundContent={"Country not found"}
                    filterOption={(input, option) =>
                      option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    autoComplete="new-password"
                  >
                    {
                      countries.map(item => {
                        return (
                          <Select.Option key={item.name} value={item.name} className={"countrySelect"}>
                            <img src={item.flag} alt="" style={{position: "relative", top: -2, width: 18}}/> {item.name}
                          </Select.Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <span className={"loginLabel"}>Company ID</span>
                <Form.Item
                  name={"uid"}
                  rules={[
                    {required: true, message: 'This field is required'},
                    {type: 'string', min: 3 ,message: 'Too short!'},
                    {type: 'string', max: 60 ,message: 'Too long!'},
                  ]}
                  validateTrigger={'onSubmit'}
                >
                  <Input
                    placeholder="Enter your company id number"
                    prefix={<CiCircleOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                <span className={"loginLabel"}>Password</span>
                <Form.Item
                  name={'password'}
                  rules={[
                    {required: true, message: 'This field is required'},
                    {type: 'string', min: 6 ,message: 'Too short!'},
                    {type: 'string', max: 60 ,message: 'Too long!'},
                  ]}
                  validateTrigger={'onSubmit'}
                >
                  <Input.Password
                    placeholder="Enter your password"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                <span className={"loginLabel"}>Region</span>
                <Form.Item
                  name={'region'}
                  rules={[
                    {required: true, message: 'This field is required'},
                    {type: 'string', min: 2 ,message: 'City must be 2 characters or longer'},
                    {type: 'string', max: 60 ,message: 'City name too long!'},
                  ]}
                  validateTrigger={'onSubmit'}
                >
                  <Input
                    placeholder="Enter your region"
                    prefix={<GlobalOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <span className={"loginLabel"}>VAT</span>
                <Form.Item
                  name={'vat'}
                  rules={[
                    {required: true, message: 'This field is required'},
                    {type: 'string', min: 3 ,message: 'Too short!'},
                    {type: 'string', max: 60 ,message: 'Too long!'},
                  ]}
                  validateTrigger={'onSubmit'}
                >
                  <Input
                    placeholder="Enter your VAT number"
                    prefix={<PercentageOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                <span className={"loginLabel"}>Company Name</span>
                <Form.Item
                  name={'name'}
                  rules={[
                    {required: true, message: 'This field is required'},
                    {type: 'string', min: 2 ,message: 'Too short!'},
                    {type: 'string', max: 60 ,message: 'Too long!'},
                  ]}
                  validateTrigger={'onSubmit'}
                >
                  <Input
                    placeholder="Enter your company name"
                    prefix={<BankOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{borderRight: "1px solid #dadada"}}>
                <span className={"loginLabel"}>City</span>
                <Form.Item
                  name={'city'}
                  rules={[
                    {required: true, message: 'This field is required'},
                    {type: 'string', min: 2 ,message: 'Too short!'},
                    {type: 'string', max: 60 ,message: 'Too long!'},
                  ]}
                  validateTrigger={'onSubmit'}
                >
                  <Input
                    placeholder="Enter your city"
                    prefix={<GlobalOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <span className={"loginLabel"}>Contact Name</span>
                <Form.Item
                  name={'contactName'}
                  rules={[
                    {required: true, message: 'This field is required'},
                    {type: 'string', min: 2 ,message: 'Too short!'},
                    {type: 'string', max: 60 ,message: 'Too long!'},
                  ]}
                  validateTrigger={'onSubmit'}
                >
                  <Input
                    placeholder="Enter your contact first name"
                    prefix={<UserOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col span={8} style={{borderRight: "1px solid #dadada", height: 55}}>
                <div style={{height: 74}}>
                  <span className={"loginLabel"}>Address</span>
                  <Form.Item
                    name={'address'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 2 ,message: 'Too short!'},
                      {type: 'string', max: 60 ,message: 'Too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      placeholder="Enter your address"
                      prefix={<HomeOutlined />}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={8} style={{borderRight: "1px solid #dadada", height: 55}}>
                <div style={{height: 74}}>
                  <span className={"loginLabel"}>Postal Code</span>
                  <Form.Item
                    name={'postalCode'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 3 ,message: 'Too short!'},
                      {type: 'string', max: 60 ,message: 'Too long!'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input
                      prefix={<GlobalOutlined />}
                      placeholder="Enter your postal code"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={8}>
                <span className={"loginLabel"}>Phone</span>
                <Form.Item
                  name={'phone'}
                  rules={[
                    {required: true, message: 'This field is required'},
                    {type: 'string', min: 6 ,message: 'Too short!'},
                    {type: 'string', max: 60 ,message: 'Too long!'},
                  ]}
                  validateTrigger={'onSubmit'}
                >
                  <Input
                    placeholder="Enter your contact phone number"
                    prefix={<PhoneOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div style={{display: "flex", justifyContent: "center"}}>
              <div style={{position: "absolute", marginTop: 25}}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}/>
              </div>
              <div style={{textAlign: "left", zIndex: 2}}>
                <Form.Item
                  name={"reCaptcha"}
                  rules={[
                    {required: true, message: 'reCaptcha is required'},
                  ]}
                  className={"reCaptchaFormItem"}
                  validateTrigger={'onSubmit'}
                >
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_SITE_KEY}
                  />
                </Form.Item>
              </div>
            </div>
          </Card>
          <Form.Item>
            <div style={{display: "flex", width: '100%', justifyContent: "center"}}>
              <Button
                style={{width: '100%', marginTop: '10px', maxWidth: 500}}
                type="primary"
                htmlType="submit"
                className={"loginText darkButton"}
                loading={isRegistering}
              >
                Sign Up
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}


