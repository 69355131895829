// General ----------------------------------------------------------------
const getEnergyConsumptionCost = (data) => {
  return data.energyConsumption * data.electricityCost;
}

const getMachineHourlyExpense = (data) => {

  let interestExpenses = data.credit * 0.5 * data.interestRate * 0.01;

  let amortisationExpenses = data.investedCapital / data.machineLifespan;

  let insuranceExpenses = data.investedCapital * data.insurance * 0.01;

  let spaceExpenses = data.requiredSpace * data.rent * 12;

  let maintenanceExpenses = data.investedCapital * data.maintenanceExpense * 0.01;

  let machineWorkHours = (data.yearlyHoursPerShift * data.timeEfficiency * data.numberOfShifts * 0.01) || 1

  return (interestExpenses + amortisationExpenses + insuranceExpenses + spaceExpenses + maintenanceExpenses) / machineWorkHours;
}

const getJetExpense = (data) => {
  let jetExpensesPerHead = 1 / data.singleJetLifespan
  return jetExpensesPerHead * data.singleJetCost * data.numOfActiveJets
}

// ------------------------------------------------------------------------

// Laser ------------------------------------------------------------------

const getLaserGasExpense = (data) => {
  return (data.workingGasPrice * data.workingGasConsumption + data.laserGasPrice * data.laserGasConsumption) / 1000
}

const getLensExpense = (data) => {
  let lensConsumption = 1 / data.lensLifespan

  return lensConsumption * data.lensPrice
}

const getLaserCosts = (data) => {
  return getLaserGasExpense(data) + getLensExpense(data)
}

// ------------------------------------------------------------------------

// Plasma -----------------------------------------------------------------

const getPlasmaGasExpense = (data) => {
  return (data.coolingGasPrice * data.coolingGasConsumption + data.plasmaGasPrice * data.plasmaGasConsumption) / 1000
}

const getCathodeExpense = (data) => {
  let cathodeConsumption = 1 / data.cathodeLifespan
  return cathodeConsumption * data.cathodePrice * data.numOfActiveJets
}

const getPlasmaCosts = (data) => {
  return getPlasmaGasExpense(data) + getCathodeExpense(data)
}

// -----------------------------------------------------------------------

// Abrasive --------------------------------------------------------------

const getAbrasiveCosts = (data) => {
  return  data.abrasivePrice * data.abrasiveConsumption
}

// -----------------------------------------------------------------------

// Plasma -----------------------------------------------------------------

const getGasCosts = (data) => {
  return (
    data.oxygenConsumption * data.oxygenPrice +
    data.cuttingGasConsumption * data.cuttingGasPrice +
    data.preheatingGasConsumption * data.preheatingGasPrice
  ) / 1000;
}

// -----------------------------------------------------------------------

export const getCuttingPricePerHour = (data) => {
  // let data = {
  //   energyConsumption: 0,
  //   electricityCost: 0,
  //
  //   credit: 0,
  //   interestRate: 0,
  //   investedCapital: 0,
  //   machineLifespan: 1, // not 0
  //   insurance: 0,
  //   requiredSpace: 0,
  //   rent: 0,
  //   maintenanceExpense: 0,
  //   yearlyHoursPerShift: 0,
  //   timeEfficiency: 0,
  //   numberOfShifts: 0,
  //
  //   singleJetLifespan: 1, // not 0
  //   singleJetCost: 0,
  //   numOfActiveJets: 0,
  //
  //   type: "laser",
  //   laser: {
  //     workingGasPrice: 0,
  //     workingGasConsumption: 0,
  //     laserGasPrice: 0,
  //     laserGasConsumption: 0,
  //
  //     lensLifespan: 1,  // not 0
  //     lensPrice: 0
  //   },
  //   plasma: {
  //     coolingGasPrice: 0,
  //     coolingGasConsumption: 0,
  //     plasmaGasPrice: 0,
  //     plasmaGasConsumption: 0,
  //
  //     cathodeLifespan: 1, // not 0
  //     cathodePrice: 0
  //   },
  //   abrasive: {
  //     abrasivePrice: 0,
  //     abrasiveConsumption: 0,
  //   },
  //   gas: {
  //     oxygenPrice: 0,
  //     oxygenConsumption: 0,
  //
  //     cuttingGasPrice: 0,
  //     cuttingGasConsumption: 0,
  //
  //     preheatingGasPrice : 0
  //     preheatingGasConsumption: 0,
  //   }
  // }

  let cuttingPricePerHour;
  cuttingPricePerHour = getEnergyConsumptionCost(data) + getMachineHourlyExpense(data) + getJetExpense(data)

  if(data.type === "laser") {
    cuttingPricePerHour += getLaserCosts(data)
  } else if(data.type === "plasma") {
    cuttingPricePerHour += getPlasmaCosts(data)
  } else if(data.type === "abrasive") {
    cuttingPricePerHour += getAbrasiveCosts(data)
  } else if(data.type === "gas") {
    cuttingPricePerHour += getGasCosts(data)
  }

  return cuttingPricePerHour
}

/*

let data = {
  energyConsumption: (kW),
  electricityCost: (€/kWh),

  credit: (€),
  interestRate: (%/year),
  investedCapital: (€),
  machineLifespan: (years),
  insurance: (%),
  requiredSpace: (m²),
  rent: (€/m²),
  maintenanceExpense: (%),
  yearlyHoursPerShift: (h),
  timeEfficiency: (%),
  numberOfShifts: (Number),

  singleJetLifespan: (h),
  singleJetCost: (€),
  numOfActiveJets: (Number),

  type: "laser",
  laser: {
    workingGasPrice: (€/m³),
    workingGasConsumption: (l/h),
    laserGasPrice: (€/m³),
    laserGasConsumption: (l/h),

    lensLifespan: (h),
    lensPrice: (€)
  },
  plasma: {
    coolingGasPrice: (€/m³),
    coolingGasConsumption: (l/h),
    plasmaGasPrice: (€/m³),
    plasmaGasConsumption: (l/h),

    cathodeLifespan: (h),
    cathodePrice: (€)
  },
  abrasive: {
    abrasivePrice: (€/l),
    abrasiveConsumption: (l/h),
  },
  gas: {
    oxygenPrice: (€/m³),
    oxygenConsumption: (l/h),

    cuttingGasPrice: (€/m³),
    cuttingGasConsumption: (l/h),

    preheatingGasPrice : (€/m³)
    preheatingGasConsumption: (l/h),
  }
}

*/

