export const AUTH_SET_USER = 'AUTH_SET_USER'
export const AUTH_SET_LOGGING_IN = 'AUTH_SET_LOGGING_IN'
export const AUTH_SET_REGISTERING = 'AUTH_SET_REGISTERING'
export const AUTH_SET_REDIRECT = 'AUTH_SET_REDIRECT'
export const AUTH_SET_REGISTERING_COMPANY = 'AUTH_SET_REGISTERING_COMPANY'

export const UTIL_COLLAPSE = 'UTIL_COLLAPSE';
export const UTIL_UPDATE_ACTIVE_SUBMENU_KEYS = 'UTIL_UPDATE_ACTIVE_SUBMENU_KEYS';
export const UTIL_UPDATE_CUSTOMER_ACTIVE_SUBMENU_KEYS = 'UTIL_UPDATE_CUSTOMER_ACTIVE_SUBMENU_KEYS';

export const PRODUCERS_PRODUCERS = 'PRODUCERS_PROVIDER'
export const PRODUCERS_FETCHING_PRODUCERS = 'PRODUCERS_FETCHING_PRODUCERS_PROVIDER'
export const PRODUCERS_PAGINATION = 'PRODUCERS_PROVIDER_PAGINATION'
export const PRODUCERS_FETCHING_PRODUCER_DETAILS = 'PRODUCERS_FETCHING_PRODUCER_DETAILS'
export const PRODUCERS_IS_ADDING_SUB_ADMIN = 'PRODUCERS_IS_ADDING_SUB_ADMIN'

export const SUB_ADMINS_IS_ADDING_SUB_ADMIN = 'SUB_ADMINS_IS_ADDING_SUB_ADMIN'
export const SUB_ADMINS_IS_FETCHING_SUB_ADMINS = 'SUB_ADMINS_IS_FETCHING_SUB_ADMINS'
export const SUB_ADMINS_SET_SUB_ADMINS = 'SUB_ADMINS_SET_SUB_ADMINS'
export const SUB_ADMINS_SET_SUB_ADMINS_PAGINATION = 'SUB_ADMINS_SET_SUB_ADMINS_PAGINATION'
export const SUB_ADMINS_IS_FETCHING_SUB_ADMINS_LIMIT = 'SUB_ADMINS_IS_FETCHING_SUB_ADMINS_LIMIT'
export const SUB_ADMINS_SET_CURRENT_SUB_ADMINS = 'SUB_ADMINS_SET_CURRENT_SUB_ADMINS'
export const SUB_ADMINS_SET_MAX_SUB_ADMINS = 'SUB_ADMINS_SET_MAX_SUB_ADMINS'
export const SUB_ADMINS_IS_EDITING_SUB_ADMINS = 'SUB_ADMINS_IS_EDITING_SUB_ADMINS'
export const SUB_ADMINS_SET_ADMIN_PERMISSIONS = 'SUB_ADMINS_SET_ADMIN_PERMISSIONS'

export const CUSTOMERS_CUSTOMERS = 'CUSTOMERS_CUSTOMERS'
export const CUSTOMERS_CUSTOMERS_PAGINATION = 'CUSTOMERS_CUSTOMERS_PAGINATION'
export const CUSTOMERS_FETCHING_CUSTOMERS = 'CUSTOMERS_FETCHING_CUSTOMERS'
export const CUSTOMER_FETCHING_CUSTOMER_DETAILS = 'CUSTOMER_FETCHING_CUSTOMER_DETAILS'
export const CUSTOMER_SET_IS_REMOVING_CUSTOMER = 'CUSTOMER_SET_IS_REMOVING_CUSTOMER'
export const CUSTOMER_REMOVE_CUSTOMER = 'CUSTOMER_REMOVE_CUSTOMER'
export const CUSTOMER_TOGGLING_BLOCK = 'CUSTOMER_TOGGLING_BLOCK'
export const CUSTOMER_BLOCK_CUSTOMER = 'CUSTOMER_BLOCK_CUSTOMER'

export const CORPORATE_CUSTOMERS_FETCHING_CUSTOMERS = 'CORPORATE_CUSTOMER_FETCHING_CUSTOMERS'
export const CORPORATE_CUSTOMERS_FETCHING_DETAILS = 'CORPORATE_CUSTOMERS_FETCHING_DETAILS'
export const CORPORATE_CUSTOMERS_CUSTOMERS = 'CORPORATE_CUSTOMER_CUSTOMERS'
export const CORPORATE_CUSTOMERS_PAGINATION = 'CORPORATE_CUSTOMERS_PAGINATION'
export const CORPORATE_CUSTOMERS_BLOCK_CUSTOMER = 'CUSTOMER_BLOCK_CUSTOMER'
export const CORPORATE_CUSTOMERS_IS_REMOVING_CUSTOMER = 'CORPORATE_CUSTOMERS_IS_REMOVING_CUSTOMER'
export const CORPORATE_CUSTOMERS_REMOVE_CUSTOMER = 'CORPORATE_CUSTOMERS_REMOVE_CUSTOMER'
export const CORPORATE_CUSTOMERS_TOGGLING_BLOCK = 'CORPORATE_CUSTOMERS_TOGGLING_BLOCK'

export const MATERIALS_FETCHING_AVAILABLE_MATERIALS = 'MATERIALS_FETCHING_AVAILABLE_MATERIALS'
export const MATERIALS_SET_AVAILABLE_MATERIALS = 'MATERIALS_SET_AVAILABLE_MATERIALS'
export const MATERIALS_UPDATE_AVAILABLE_MATERIALS = 'MATERIALS_UPDATE_AVAILABLE_MATERIALS'
export const MATERIALS_SET_MATERIAL_PRICES = 'MATERIALS_SET_MATERIAL_PRICES'
export const MATERIALS_FETCHING_MATERIAL_PRICES = 'MATERIALS_FETCHING_MATERIAL_PRICES'
export const MATERIALS_UPDATE_MATERIAL_PRICE = 'MATERIALS_UPDATE_MATERIAL_PRICE'
export const MATERIALS_SET_SINGLE_MATERIAL_PRICES = 'MATERIALS_SET_SINGLE_MATERIAL_PRICES'
export const MATERIALS_FETCHING_MATERIAL_PLATES = 'MATERIALS_FETCHING_MATERIAL_PLATES'
export const MATERIALS_SET_MATERIAL_PLATES = 'MATERIALS_SET_MATERIAL_PLATES'
export const MATERIALS_SET_SINGLE_MATERIAL_PLATES = 'MATERIALS_SET_SINGLE_MATERIAL_PLATES'
export const MATERIALS_SET_MATERIAL_PLATES_DATA = 'MATERIALS_SET_MATERIAL_PLATES_DATA'
export const MATERIALS_FETCHING_SINGLE_MATERIAL_PLATES = 'MATERIALS_FETCHING_SINGLE_MATERIAL_PLATES'
export const MATERIALS_RESET_PLATES_DATA = 'MATERIALS_RESET_PLATES_DATA'
export const MATERIALS_IS_ADDING_NEW_PLATE = 'MATERIALS_IS_ADDING_NEW_PLATE'
export const MATERIALS_IS_EDITING_PLATE = 'MATERIALS_IS_EDITING_PLATE'
export const MATERIALS_IS_DELETING_PLATES = 'MATERIALS_IS_DELETING_PLATES'

export const BENDING_IS_FETCHING_BENDING_DATA = 'BENDING_IS_FETCHING_BENDING_DATA'
export const BENDING_SET_BENDING_DATA = 'BENDING_SET_BENDING_DATA'
export const BENDING_SET_BENDING_SERVICE = 'BENDING_SET_BENDING_SERVICE'
export const BENDING_SET_BENDING_MAX_LENGTH = 'BENDING_SET_BENDING_MAX_LENGTH'
export const BENDING_SET_BENDING_PREPARATION_PRICE = 'BENDING_SET_BENDING_PREPARATION_PRICE'
export const BENDING_SET_BENDING_AVERAGE_PREPARATION_TIME = 'BENDING_SET_BENDING_AVERAGE_PREPARATION_TIME'
export const BENDING_FETCHING_SINGLE_MATERIAL_BENDS = 'BENDING_FETCHING_SINGLE_MATERIAL_BENDS'
export const BENDING_SET_MATERIAL_BENDING_DATA = 'BENDING_SET_MATERIAL_BENDING_DATA'
export const BENDING_RESET_BENDS_DATA = 'BENDING_RESET_BENDS_DATA'
export const BENDING_IS_ADDING_NEW_BEND = 'BENDING_IS_ADDING_NEW_BEND'
export const BENDING_SET_SINGLE_MATERIAL_BENDS = 'BENDING_SET_SINGLE_MATERIAL_BENDS'
export const BENDING_IS_EDITING_BEND = 'BENDING_IS_EDITING_BEND'
export const BENDING_IS_DELETING_BENDS = 'BENDING_IS_DELETING_BENDS'

export const SIMPLE_MACHINING_SET_DATA = 'SIMPLE_MACHINING_SET_DATA'
export const SIMPLE_MACHINING_IS_FETCHING_DATA = 'SIMPLE_MACHINING_IS_FETCHING_DATA'
export const SIMPLE_MACHINING_SET_SIMPLE_MACHINING_SERVICE = 'SIMPLE_MACHINING_SET_SIMPLE_MACHINING_SERVICE'
export const SIMPLE_MACHINING_SET_DEBURRING_SERVICE = 'SIMPLE_MACHINING_SET_DEBURRING_SERVICE'
export const SIMPLE_MACHINING_SET_CHAMFERING_SERVICE = 'SIMPLE_MACHINING_SET_CHAMFERING_SERVICE'
export const SIMPLE_MACHINING_SET_DRILLING_SERVICE = 'SIMPLE_MACHINING_SET_DRILLING_SERVICE'
export const SIMPLE_MACHINING_SET_DEBURRING_DATA = 'SIMPLE_MACHINING_SET_DEBURRING_DATA'
export const SIMPLE_MACHINING_SET_CHAMFERING_DATA = 'SIMPLE_MACHINING_SET_CHAMFERING_DATA'
export const SIMPLE_MACHINING_SET_DRILLING_DATA = 'SIMPLE_MACHINING_SET_DRILLING_DATA'
export const SIMPLE_MACHINING_SET_PREPARATION_DATA = 'SIMPLE_MACHINING_SET_PREPARATION_DATA'

export const SURFACE_COATING_SET_DATA = 'SURFACE_COATING_SET_DATA'
export const SURFACE_COATING_IS_FETCHING_DATA = 'SURFACE_COATING_IS_FETCHING_DATA'
export const SURFACE_COATING_SET_SURFACE_COATING_SERVICE = 'SURFACE_COATING_SET_SURFACE_COATING_SERVICE'
export const SURFACE_COATING_SET_PAINTING_SERVICE = 'SURFACE_COATING_SET_PAINTING_SERVICE'
export const SURFACE_COATING_SET_POWDER_PAINTING_SERVICE = 'SURFACE_COATING_SET_POWDER_PAINTING_SERVICE'
export const SURFACE_COATING_SET_GALVANIZING_SERVICE = 'SURFACE_COATING_SET_GALVANIZING_SERVICE'
export const SURFACE_COATING_SET_PAINTING_DATA = 'SURFACE_COATING_SET_PAINTING_DATA'
export const SURFACE_COATING_SET_POWDER_PAINTING_DATA = 'SURFACE_COATING_SET_POWDER_PAINTING_DATA'
export const SURFACE_COATING_SET_GALVANIZING_DATA = 'SURFACE_COATING_SET_GALVANIZING_DATA'

export const CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINES = 'CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINES'
export const CUTTING_MACHINES_SET_CUTTING_MACHINES = 'CUTTING_MACHINES_SET_CUTTING_MACHINES'
export const CUTTING_MACHINES_IS_FETCHING_MACHINE_TYPES = 'CUTTING_MACHINES_IS_FETCHING_MACHINE_TYPES'
export const CUTTING_MACHINES_SET_MACHINE_TYPES = 'CUTTING_MACHINES_SET_MACHINE_TYPES'
export const CUTTING_MACHINES_IS_FETCHING_MACHINE_PRIORITIES = 'CUTTING_MACHINES_IS_FETCHING_MACHINE_PRIORITIES'
export const CUTTING_MACHINES_SET_MACHINE_PRIORITIES = 'CUTTING_MACHINES_SET_MACHINE_PRIORITIES'
export const CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINE_DATA = 'CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINE_DATA'
export const CUTTING_MACHINES_SET_MACHINE_DATA = 'CUTTING_MACHINES_SET_MACHINE_DATA'
export const CUTTING_MACHINES_SET_CUTTING_SPEED = 'CUTTING_MACHINES_SET_CUTTING_SPEED'
export const CUTTING_MACHINES_SET_MACHINE_PARAMETER = 'CUTTING_MACHINES_SET_MACHINE_PARAMETER'
export const CUTTING_MACHINES_IS_UPDATING_MACHINE_PRIORITIES = 'CUTTING_MACHINES_IS_UPDATING_MACHINE_PRIORITIES'
export const CUTTING_MACHINES_IS_CREATING_CUTTING_MACHINE = 'CUTTING_MACHINES_IS_CREATING_CUTTING_MACHINE'
export const CUTTING_MACHINES_IS_EDITING_CUTTING_MACHINE = 'CUTTING_MACHINES_IS_EDITING_CUTTING_MACHINE'
export const CUTTING_MACHINES_IS_DELETING_CUTTING_MACHINE = 'CUTTING_MACHINES_IS_DELETING_CUTTING_MACHINE'

export const ADMIN_LOGS = 'ADMIN_LOGS'
export const ADMIN_LOGS_PAGINATION = 'ADMIN_LOGS_PAGINATION'
export const ADMIN_FETCHING_LOGS = 'ADMIN_FETCHING_LOGS'

export const CUSTOMER_SETTINGS_SET_EDITING_CUSTOMER_SETTINGS = 'CUSTOMER_SETTINGS_SET_EDITING_CUSTOMER_SETTINGS'
export const CUSTOMER_SETTINGS_SET_RESETTING_PASSWORD = 'CUSTOMER_SETTINGS_SET_RESETTING_PASSWORD'

export const COMPANY_SETTINGS_SET_FETCHING_COMPANY_DETAILS = 'COMPANY_SETTINGS_SET_FETCHING_COMPANY_DETAILS'
export const COMPANY_SETTINGS_SET_COMPANY_DETAILS = 'COMPANY_SETTINGS_SET_COMPANY_DETAILS'
export const COMPANY_SETTINGS_SET_EDITING_COMPANY_SETTINGS = 'COMPANY_SETTINGS_SET_EDITING_COMPANY_SETTINGS'
export const COMPANY_SETTINGS_SET_RESETTING_PASSWORD = 'COMPANY_SETTINGS_SET_RESETTING_PASSWORD'

export const CUSTOMER_RELATIONS_SET_EXTERNAL_RELATIONS = 'CUSTOMER_RELATIONS_SET_EXTERNAL_RELATIONS'
export const CUSTOMER_RELATIONS_EXTERNAL_PAGINATION = 'CUSTOMER_RELATIONS_EXTERNAL_PAGINATION'
export const CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER = 'CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER'
export const CUSTOMER_RELATIONS_SET_FETCHING_EXTERNAL_RELATIONS = 'CUSTOMER_RELATIONS_SET_FETCHING_EXTERNAL_RELATIONS'

export const CUSTOMER_RELATIONS_SET_INTERNAL_RELATIONS = 'CUSTOMER_RELATIONS_SET_INTERNAL_RELATIONS'
export const CUSTOMER_RELATIONS_INTERNAL_PAGINATION = 'CUSTOMER_RELATIONS_INTERNAL_PAGINATION'
export const CUSTOMER_RELATIONS_SET_FETCHING_INTERNAL_RELATIONS = 'CUSTOMER_RELATIONS_SET_FETCHING_INTERNAL_RELATIONS'

export const CUSTOMER_RELATIONS_SET_UPDATING_REGULAR_CUSTOMER = 'CUSTOMER_RELATIONS_SET_UPDATING_REGULAR_CUSTOMER'
export const CUSTOMER_RELATIONS_SET_UPDATING_CORPORATE_CUSTOMER = 'CUSTOMER_RELATIONS_SET_UPDATING_CORPORATE_CUSTOMER'
export const CUSTOMER_RELATIONS_SET_IS_UPDATING_EXTERNAL_CUSTOMER = 'CUSTOMER_RELATIONS_SET_IS_UPDATING_EXTERNAL_CUSTOMER'

export const ORDER_SET_ORDER = 'ORDER_SET_ORDER'
export const ORDER_RESET_ORDER = 'ORDER_RESET_ORDER'
export const ORDER_SET_PART_COLOR = 'ORDER_SET_PART_COLOR'
export const ORDER_REMOVE_PART_COLOR = 'ORDER_REMOVE_PART_COLOR'
export const ORDER_SET_PART_QUANTITY = 'ORDER_SET_PART_QUANTITY'
export const ORDER_SET_PART_NOTE = 'ORDER_SET_PART_NOTE'
export const ORDER_SET_PART_MATERIAL = 'ORDER_SET_PART_MATERIAL'
export const ORDER_SET_PART_THICKNESS = 'ORDER_SET_PART_THICKNESS'
export const ORDER_RESET_TO_PROCESS = 'ORDER_RESET_TO_PROCESS'
export const ORDER_REMOVE_ITEM = 'ORDER_REMOVE_ITEM'
export const ORDER_REMOVE_MULTIPLE_ITEMS = 'ORDER_REMOVE_MULTIPLE_ITEMS'
export const ORDER_APPLY_MULTIPLE_ITEMS = 'ORDER_APPLY_MULTIPLE_ITEMS'
export const ORDER_SET_MANUAL_PART_TYPE = 'ORDER_SET_MANUAL_PART_TYPE'
export const ORDER_ADD_NEW_PART = 'ORDER_ADD_NEW_PART'
export const ORDER_UPDATE_PART = 'ORDER_UPDATE_PART'
export const ORDER_INCREMENT_TO_PROCESS = 'ORDER_INCREMENT_TO_PROCESS'
export const ORDER_INCREMENT_UPLOADED = 'ORDER_INCREMENT_UPLOADED'
export const ORDER_INCREMENT_PROCESSED = 'ORDER_INCREMENT_PROCESSED'

export const ORDER_SET_DRAFT_STAGE = 'ORDER_SET_DRAFT_STAGE'
export const ORDER_SET_SHIPPING_DATA = 'ORDER_SET_SHIPPING_DATA'
export const ORDER_UPDATE_PART_DATA = 'ORDER_UPDATE_PART_DATA'
export const ORDER_UPDATE_PART_FILE_PATH = 'ORDER_UPDATE_PART_FILE_PATH'
export const ORDER_CHANGE_PART_TO_ERROR = 'ORDER_CHANGE_PART_TO_ERROR'
export const ORDER_UPDATE_ORDER_PRICE = 'ORDER_UPDATE_ORDER_PRICE'
export const ORDER_REMOVE_ERROR_PARTS = 'ORDER_REMOVE_ERROR_PARTS'
export const ORDER_SET_DELIVERY_DATE = 'ORDER_SET_DELIVERY_DATE'
export const ORDER_UPDATE_PART_PRICE = 'ORDER_UPDATE_PART_PRICE'
export const ORDER_UPDATE_MANY_PART_PRICES = 'ORDER_UPDATE_MANY_PART_PRICES'
export const ORDER_SET_DRAFT_NAME = 'ORDER_SET_DRAFT_NAME'
export const ORDER_SET_PART_DRAWING = 'ORDER_SET_PART_DRAWING'
export const ORDER_SET_CHANGED_ADJUSTMENT = 'ORDER_SET_CHANGED_ADJUSTMENT'

export const BULK_ORDER_ADD_NEW_PART = 'BULK_ORDER_ADD_NEW_PART'
export const BULK_ORDER_UPDATE_PART = 'BULK_ORDER_UPDATE_PART'
export const BULK_ORDER_UPDATE_PART_DATA = 'BULK_ORDER_UPDATE_PART_DATA'
export const BULK_ORDER_UPDATE_ORDER_PRICE = 'BULK_ORDER_UPDATE_ORDER_PRICE'
export const BULK_ORDER_INCREMENT_TO_PROCESS = 'BULK_ORDER_INCREMENT_TO_PROCESS'
export const BULK_ORDER_INCREMENT_UPLOADED = 'BULK_ORDER_INCREMENT_UPLOADED'
export const BULK_ORDER_INCREMENT_PROCESSED = 'BULK_ORDER_INCREMENT_PROCESSED'
export const BULK_ORDER_CHANGE_PART_TO_ERROR = 'BULK_ORDER_CHANGE_PART_TO_ERROR'
export const BULK_ORDER_REMOVE_ERROR_PARTS = 'BULK_ORDER_REMOVE_ERROR_PARTS'
export const BULK_ORDER_UPDATE_PART_FILE_PATH = 'BULK_ORDER_UPDATE_PART_FILE_PATH'
export const BULK_ODER_SET_BULK_ORDER = 'BULK_ODER_SET_BULK_ORDER'
export const BULK_ORDER_SET_PART_COLOR = 'BULK_ORDER_SET_PART_COLOR'
export const BULK_ORDER_REMOVE_PART_COLOR = 'BULK_ORDER_REMOVE_PART_COLOR'
export const BULK_ORDER_SET_PART_QUANTITY = 'BULK_ORDER_SET_PART_QUANTITY'
export const BULK_ORDER_SET_PART_MATERIAL_SURCHARGE = 'BULK_ORDER_SET_PART_MATERIAL_SURCHARGE'
export const BULK_ORDER_SET_PART_NOTE = 'BULK_ORDER_SET_PART_NOTE'
export const BULK_ORDER_SET_PART_MATERIAL = 'BULK_ORDER_SET_PART_MATERIAL'
export const BULK_ORDER_SET_PART_THICKNESS = 'BULK_ORDER_SET_PART_THICKNESS'
export const BULK_ORDER_SET_PART_STANDARD = 'BULK_ORDER_SET_PART_STANDARD'
export const BULK_ORDER_UPDATE_PART_PRICE = 'BULK_ORDER_UPDATE_PART_PRICE'
export const BULK_ORDER_SET_PART_DRAWING = 'BULK_ORDER_SET_PART_DRAWING'
export const BULK_ORDER_RESET_TO_PROCESS = 'BULK_ORDER_RESET_TO_PROCESS'
export const BULK_ORDER_REMOVE_ITEM = 'BULK_ORDER_REMOVE_ITEM'
export const BULK_ORDER_REMOVE_MULTIPLE_ITEMS = 'BULK_ORDER_REMOVE_MULTIPLE_ITEMS'
export const BULK_ORDER_APPLY_MULTIPLE_ITEMS = 'BULK_ORDER_APPLY_MULTIPLE_ITEMS'
export const BULK_ORDER_SET_MANUAL_PART_TYPE = 'BULK_ORDER_SET_MANUAL_PART_TYPE'
export const BULK_ORDER_CLEAR_ORDER = 'BULK_ORDER_CLEAR_ORDER'
export const BULK_ORDER_SAVE_TO_ARCHIVE = 'BULK_ORDER_SAVE_TO_ARCHIVE'
export const BULK_ORDER_SET_IS_DELETING_ARCHIVED_BULK_ORDER = 'BULK_ORDER_SET_IS_DELETING_ARCHIVED_BULK_ORDER'
export const BULK_ORDER_DELETE_ARCHIVED_BULK_ORDER = 'BULK_ORDER_DELETE_ARCHIVED_BULK_ORDER'
export const BULK_ODER_RESET_BULK_ODER = 'BULK_ODER_RESET_BULK_ODER'
export const BULK_ORDER_SET_PARTS_TO_ANALYZE = 'BULK_ORDER_SET_PARTS_TO_ANALYZE'
export const BULK_ORDER_UPDATE_MANY_PART_PRICES = 'BULK_ORDER_UPDATE_MANY_PART_PRICES'

export const ORDERS_SET_PRODUCER_PENDING_ORDERS = 'ORDERS_SET_PRODUCER_PENDING_ORDERS'
export const ORDERS_SET_PRODUCER_PENDING_ORDERS_PAGINATION = 'ORDERS_SET_PRODUCER_PENDING_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_PRODUCER_PENDING_ORDERS = 'ORDERS_SET_IS_FETCHING_PRODUCER_PENDING_ORDERS'
export const ORDERS_SET_PRODUCER_ACCEPTED_ORDERS = 'ORDERS_SET_PRODUCER_ACCEPTED_ORDERS'
export const ORDERS_SET_PRODUCER_ACCEPTED_ORDERS_PAGINATION = 'ORDERS_SET_PRODUCER_ACCEPTED_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_PRODUCER_ACCEPTED_ORDERS = 'ORDERS_SET_IS_FETCHING_PRODUCER_ACCEPTED_ORDERS'
export const ORDERS_SET_PRODUCER_ARCHIVED_ORDERS = 'ORDERS_SET_PRODUCER_ARCHIVED_ORDERS'
export const ORDERS_SET_PRODUCER_ARCHIVED_ORDERS_PAGINATION = 'ORDERS_SET_PRODUCER_ARCHIVED_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_PRODUCER_ARCHIVED_ORDERS = 'ORDERS_SET_IS_FETCHING_PRODUCER_ARCHIVED_ORDERS'

export const ORDERS_SET_CUSTOMER_PENDING_ORDERS = 'ORDERS_SET_CUSTOMER_PENDING_ORDERS'
export const ORDERS_SET_CUSTOMER_PENDING_ORDERS_PAGINATION = 'ORDERS_SET_CUSTOMER_PENDING_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_CUSTOMER_PENDING_ORDERS = 'ORDERS_SET_IS_FETCHING_CUSTOMER_PENDING_ORDERS'
export const ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS = 'ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS'
export const ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS_PAGINATION = 'ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_CUSTOMER_ACCEPTED_ORDERS = 'ORDERS_SET_IS_FETCHING_CUSTOMER_ACCEPTED_ORDERS'
export const ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS = 'ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS'
export const ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS_PAGINATION = 'ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_CUSTOMER_ARCHIVED_ORDERS = 'ORDERS_SET_IS_FETCHING_CUSTOMER_ARCHIVED_ORDERS'

export const ORDERS_SET_IS_FETCHING_DRAFT_ORDERS = 'ORDERS_SET_IS_FETCHING_DRAFT_ORDERS'
export const ORDERS_SET_DRAFT_ORDERS = 'ORDERS_SET_DRAFT_ORDERS'
export const ORDERS_SET_IS_REMOVING_DRAFT_ORDER = 'ORDERS_SET_IS_REMOVING_DRAFT_ORDER'
export const ORDERS_REMOVE_DRAFT_ORDER = 'ORDERS_REMOVE_DRAFT_ORDER'
export const ORDERS_SET_IS_CREATING_DRAFT_ORDER = 'ORDERS_SET_IS_CREATING_DRAFT_ORDER'
export const ORDERS_SET_IS_FETCHING_DRAFT_ORDER = 'ORDERS_SET_IS_FETCHING_DRAFT_ORDER'

export const ORDERS_SET_IS_CHANGING_DRAFT_STAGE = 'ORDERS_SET_IS_CHANGING_DRAFT_STAGE'
export const ORDERS_SET_PARTS_TO_ANALYZE = 'ORDERS_SET_PARTS_TO_ANALYZE'
export const ORDERS_SET_IS_CHANGING_DELIVERY_DATE = 'ORDERS_SET_IS_CHANGING_DELIVERY_DATE'
export const ORDERS_SET_IS_FETCHING_PENDING_ORDER = 'ORDERS_SET_IS_FETCHING_PENDING_ORDER'
export const ORDERS_SET_PENDING_ORDER = 'ORDERS_SET_PENDING_ORDER'
export const ORDERS_SET_IS_ADDING_NEW_PART = 'ORDERS_SET_IS_ADDING_NEW_PART'
export const ORDERS_SET_PENDING_ORDER_PRICE = 'ORDERS_SET_PENDING_ORDER_PRICE'
export const ORDERS_SET_PENDING_ORDER_PARTS = 'ORDERS_SET_PENDING_ORDER_PARTS'
export const ORDERS_SET_IS_FETCHING_PENDING_ORDER_PARTS = 'ORDERS_SET_IS_FETCHING_PENDING_ORDER_PARTS'
export const ORDERS_SET_IS_EDITING_PENDING_ORDER = 'ORDERS_SET_IS_EDITING_PENDING_ORDER'
export const ORDERS_SET_PENDING_ORDER_DELIVERY_DATE = 'ORDERS_SET_PENDING_ORDER_DELIVERY_DATE'
export const ORDERS_SET_PENDING_ORDER_STAGE = 'ORDERS_SET_PENDING_ORDER_STAGE'
export const ORDERS_SET_IS_RESOLVING_PENDING_ORDER = 'ORDERS_SET_IS_RESOLVING_PENDING_ORDER'
export const ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_ORDERS = 'ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_ORDERS'
export const ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_ORDERS = 'ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_ORDERS'
export const ORDERS_SET_IS_FETCHING_PRODUCER_AWAITING_CONFIRMATION_ORDERS = 'ORDERS_SET_IS_FETCHING_PRODUCER_AWAITING_CONFIRMATION_ORDERS'
export const ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_PAGINATION = 'ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_PAGINATION'
export const ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_ORDERS = 'ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_ORDERS'
export const ORDERS_SET_IS_REVERTING_ORDER = 'ORDERS_SET_IS_REVERTING_ORDER'
export const ORDERS_SET_IS_CHANGING_DRAFT_NAME = 'ORDERS_SET_IS_CHANGING_DRAFT_NAME'
export const ORDERS_SET_IS_FETCHING_AWAITING_CHANGES = 'ORDERS_SET_IS_FETCHING_AWAITING_CHANGES'
export const ORDERS_SET_AWAITING_CHANGES = 'ORDERS_SET_AWAITING_CHANGES'
export const ORDERS_SET_IS_FETCHING_RECENT_CHANGES = 'ORDERS_SET_IS_FETCHING_RECENT_CHANGES'
export const ORDERS_SET_RECENT_CHANGES = 'ORDERS_SET_RECENT_CHANGES'
export const ORDERS_SET_IS_GENERATING_ZIP = 'ORDERS_SET_IS_GENERATING_ZIP'

export const ORDERS_SET_ARCHIVED_BULK_ORDERS = 'ORDERS_SET_ARCHIVED_BULK_ORDERS'
export const ORDERS_SET_FETCHING_ARCHIVED_BULK_ORDERS = 'ORDERS_SET_FETCHING_ARCHIVED_BULK_ORDERS'
export const ORDERS_SET_IS_FETCHING_INTERNAL_DRAFT_ORDER = 'ORDERS_SET_IS_FETCHING_INTERNAL_DRAFT_ORDER'
export const ORDERS_SET_INTERNAL_ARCHIVED_ORDERS = 'ORDERS_SET_INTERNAL_ARCHIVED_ORDERS'
export const ORDERS_SET_IS_ARCHIVING_CALCULATION = 'ORDERS_SET_IS_ARCHIVING_CALCULATION'
export const ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION = 'ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION'
export const ORDERS_SET_ARCHIVED_CALCULATION = 'ORDERS_SET_ARCHIVED_CALCULATION'
export const ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION_CUSTOMERS = 'ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION_CUSTOMERS'
export const ORDERS_SET_ARCHIVED_CALCULATION_CUSTOMERS = 'ORDERS_SET_ARCHIVED_CALCULATION_CUSTOMERS'

export const ORDERS_SET_PENDING_ORDER_PART_PRODUCED_QUANTITY = 'ORDERS_SET_PENDING_ORDER_PART_PRODUCED_QUANTITY'

export const ORDERS_SET_FILES_TO_UPLOAD = 'ORDERS_SET_FILES_TO_UPLOAD'

export const NESTING_SET_ORDERS = 'NESTING_SET_ORDERS'
export const NESTING_SET_ACCEPTED_JOBS = 'NESTING_SET_ACCEPTED_JOBS'
export const NESTING_SET_COMPLETED_JOBS = 'NESTING_SET_COMPLETED_ORDERS'
export const NESTING_SET_IS_FETCHING_ORDERS = 'NESTING_SET_IS_FETCHING_ORDERS'
export const NESTING_SET_CURRENT_JOB = 'NESTING_SET_CURRENT_JOB'
export const NESTING_SET_IS_FETCHING_CURRENT_JOB = 'NESTING_SET_IS_FETCHING_CURRENT_JOB'
export const NESTING_SET_IS_FETCHING_PLATE_DATA = 'NESTING_SET_IS_FETCHING_PLATE_DATA'
export const NESTING_SET_PLATE_DATA = 'NESTING_SET_PLATE_DATA'
export const NESTING_SET_FETCHING_ACCEPTED_JOBS = 'NESTING_SET_FETCHING_ACCEPTED_JOBS'
export const NESTING_SET_FETCHING_COMPLETED_JOBS = 'NESTING_SET_FETCHING_COMPLETED_JOBS'
export const NESTING_SET_IS_CURRENTLY_NESTING = 'NESTING_SET_IS_CURRENTLY_NESTING'
export const NESTING_REMOVE_NESTED_PLATE = 'NESTING_REMOVE_NESTED_PLATE'

export const MATERIALS_FETCHING_MATERIAL_MARGINS = 'MATERIALS_FETCHING_MATERIAL_MARGINS'
export const MATERIALS_SET_MATERIAL_MARGINS = 'MATERIALS_SET_MATERIAL_MARGINS'
export const MATERIALS_UPDATE_MATERIAL_MARGIN = 'MATERIALS_UPDATE_MATERIAL_MARGIN'