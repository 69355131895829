import {
  MATERIALS_FETCHING_AVAILABLE_MATERIALS,
  MATERIALS_SET_AVAILABLE_MATERIALS,
  MATERIALS_UPDATE_AVAILABLE_MATERIALS,
  MATERIALS_FETCHING_MATERIAL_PRICES,
  MATERIALS_SET_MATERIAL_PRICES,
  MATERIALS_UPDATE_MATERIAL_PRICE,
  MATERIALS_SET_SINGLE_MATERIAL_PRICES,
  MATERIALS_FETCHING_MATERIAL_PLATES,
  MATERIALS_SET_MATERIAL_PLATES,
  MATERIALS_SET_SINGLE_MATERIAL_PLATES,
  MATERIALS_SET_MATERIAL_PLATES_DATA,
  MATERIALS_FETCHING_SINGLE_MATERIAL_PLATES,
  MATERIALS_RESET_PLATES_DATA,
  MATERIALS_IS_ADDING_NEW_PLATE,
  MATERIALS_IS_EDITING_PLATE,
  MATERIALS_IS_DELETING_PLATES,
  MATERIALS_FETCHING_MATERIAL_MARGINS,
  MATERIALS_SET_MATERIAL_MARGINS, MATERIALS_UPDATE_MATERIAL_MARGIN
} from './actionTypes'
import { API_BASE_URL } from '../constants'
import { http, message } from '../utils'
import {forceLogoutUser} from "./authActions";

const fetchAvailableMaterials = () => dispatch => {
  dispatch({
    type: MATERIALS_FETCHING_AVAILABLE_MATERIALS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/material/producer-materials`)
    .then(response => {
      dispatch({
        type: MATERIALS_SET_AVAILABLE_MATERIALS,
        payload: response.data,
      })

      dispatch({
        type: MATERIALS_FETCHING_AVAILABLE_MATERIALS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MATERIALS_FETCHING_AVAILABLE_MATERIALS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchMaterialPrices = () => dispatch => {
  dispatch({
    type: MATERIALS_FETCHING_MATERIAL_PRICES,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/material/producer-materials/prices`)
    .then(response => {
      let data = response.data.groupNames.map( name => {
        let item = response.data.prices.find(item => item.groupName === name)
        if(item) {
          return item
        }
        else {
          return {
            groupName: name,
            rows: [],
            columns: [],
            allAvailable: {}
          }
        }
      })

      dispatch({
        type: MATERIALS_SET_MATERIAL_PRICES,
        payload: [...data],
      })

      dispatch({
        type: MATERIALS_FETCHING_MATERIAL_PRICES,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MATERIALS_FETCHING_MATERIAL_PRICES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchSingleMaterialPrices = payload => dispatch => {
  return http
    .post(`${API_BASE_URL}/material/producer-materials/prices`, {groupName: payload.groupName})
    .then(response => {
      if(response.data) {
        dispatch({
          type: MATERIALS_SET_SINGLE_MATERIAL_PRICES,
          payload: response.data,
        })
      }
      else {
        dispatch({
          type: MATERIALS_SET_SINGLE_MATERIAL_PRICES,
          payload: {
            groupName: payload.groupName,
            rows: [],
            columns: [],
            allAvailable: {}
          },
        })
      }
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchMaterialPlates = () => dispatch => {
  dispatch({
    type: MATERIALS_FETCHING_MATERIAL_PLATES,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/material/producer-materials/plates`)
    .then(response => {
      let data = response.data.groupNames.map( name => {
        let item = response.data.plates.find(item => item.groupName === name)
        if(item) {
          return item
        }
        else {
          return {
            groupName: name,
            rows: [],
            columns: [],
            allAvailable: {}
          }
        }
      })

      dispatch({
        type: MATERIALS_SET_MATERIAL_PLATES,
        payload: [...data],
      })

      dispatch({
        type: MATERIALS_FETCHING_MATERIAL_PLATES,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MATERIALS_FETCHING_MATERIAL_PLATES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchSingleMaterialPlates = payload => dispatch => {
  return http
    .post(`${API_BASE_URL}/material/producer-materials/plates`, {groupName: payload.groupName})
    .then(response => {
      if(response.data) {
        dispatch({
          type: MATERIALS_SET_SINGLE_MATERIAL_PLATES,
          payload: response.data,
        })
      }
      else {
        dispatch({
          type: MATERIALS_SET_SINGLE_MATERIAL_PLATES,
          payload: {
            groupName: payload.groupName,
            rows: [],
            columns: [],
            allAvailable: {}
          },
        })
      }
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchSingleMaterialPlatesData = payload => dispatch => {
  dispatch({
    type: MATERIALS_FETCHING_SINGLE_MATERIAL_PLATES,
    payload: true,
  })
  return http
    .get(`${API_BASE_URL}/material/${payload.materialId}/plates`)
    .then(response => {
      dispatch({
        type: MATERIALS_SET_MATERIAL_PLATES_DATA,
        payload: response.data,
      })

      dispatch({
        type: MATERIALS_FETCHING_SINGLE_MATERIAL_PLATES,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MATERIALS_FETCHING_SINGLE_MATERIAL_PLATES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const addNewPlate = payload => dispatch => {
  dispatch({
    type: MATERIALS_IS_ADDING_NEW_PLATE,
    payload: true,
  })
  return http
    .post(`${API_BASE_URL}/material/${payload.materialId}/plates`, {width: payload.width, height: payload.height})
    .then(response => {
      dispatch({
        type: MATERIALS_IS_ADDING_NEW_PLATE,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MATERIALS_IS_ADDING_NEW_PLATE,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
        throw error;
      }
    })
}

const editPlate = payload => dispatch => {
  dispatch({
    type: MATERIALS_IS_EDITING_PLATE,
    payload: true,
  })
  return http
    .put(`${API_BASE_URL}/material/${payload.materialId}/plates/${payload.plateId}`, {width: payload.width, height: payload.height})
    .then(response => {
      dispatch({
        type: MATERIALS_IS_EDITING_PLATE,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MATERIALS_IS_EDITING_PLATE,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
        throw error;
      }
    })
}

const deletePlates = payload => dispatch => {

  dispatch({
    type: MATERIALS_IS_DELETING_PLATES,
    payload: true,
  })

  return http
    .deleteData(`${API_BASE_URL}/material/${payload.materialId}/plates`, {plates: [...payload.plates]})
    .then(response => {

      dispatch({
        type: MATERIALS_SET_MATERIAL_PLATES_DATA,
        payload: response.data,
      })

      dispatch({
        type: MATERIALS_IS_DELETING_PLATES,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MATERIALS_IS_DELETING_PLATES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}



const resetMaterialPlatesData = () => dispatch => {
  dispatch({
    type: MATERIALS_RESET_PLATES_DATA,
    payload: null,
  })
}

const updateAvailableMaterials = payload => dispatch => {
  dispatch({
    type: MATERIALS_UPDATE_AVAILABLE_MATERIALS,
    payload: payload,
  })
}

const updateMaterialPrice = payload => dispatch => {
  dispatch({
    type: MATERIALS_UPDATE_MATERIAL_PRICE,
    payload: payload,
  })
}

const updateMaterialMargin = payload => dispatch => {
  dispatch({
    type: MATERIALS_UPDATE_MATERIAL_MARGIN,
    payload: payload,
  })
}

const fetchMaterialMargins = () => dispatch => {
  dispatch({
    type: MATERIALS_FETCHING_MATERIAL_MARGINS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/nesting/margin`)
    .then(response => {

      dispatch({
        type: MATERIALS_SET_MATERIAL_MARGINS,
        payload: response.data.margins,
      })

      dispatch({
        type: MATERIALS_FETCHING_MATERIAL_MARGINS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MATERIALS_FETCHING_MATERIAL_MARGINS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}


export {
  fetchAvailableMaterials,
  updateAvailableMaterials,
  fetchMaterialPrices,
  fetchSingleMaterialPrices,
  updateMaterialPrice,
  fetchMaterialPlates,
  fetchSingleMaterialPlates,
  fetchSingleMaterialPlatesData,
  resetMaterialPlatesData,
  addNewPlate,
  editPlate,
  deletePlates,

  fetchMaterialMargins,
  updateMaterialMargin
}
