import React, { Component } from 'react'
import { Row, Spin } from 'antd'
import { bindActionCreators } from 'redux'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { API_BASE_URL } from '../../constants'
import {http, message} from "../../utils";
import {LoadingOutlined} from "@ant-design/icons";

class VerifyEmail extends Component {

  componentDidMount() {
    const token = new URLSearchParams(this.props.location.search).get('token')
    if (token === null) {
      this.props.history.push('/login')
    }
    else {
      http
        .post(`${API_BASE_URL}/auth/verify-email`, {token: token})
        .then(() => {
          message.show(message.type.success, 'Email successfully verified.')
          this.props.history.push('/login')
        })
        .catch(error => {
          if(error.response?.data?.msg) {
            message.show(message.type.error, http.getErrorMessage(error))
          } else {
            message.show(message.type.info, "Email already verified!")
          }
          this.props.history.push('/login')
        })
    }
  }

  render() {
    return (
      <Row>
        <div style={{position:'absolute' ,top: '50%', left: '50%', transform: 'translate(-50%,-50%)'}}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 80 }} spin />}/>
        </div>
      </Row>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)
)

