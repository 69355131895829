import {
    ADMIN_LOGS,
    ADMIN_FETCHING_LOGS,
    ADMIN_LOGS_PAGINATION
} from '../actions/actionTypes'
import initialState from './initialState'

const adminReducer = (state = initialState.admin, action) => {
    switch (action.type) {
        case ADMIN_LOGS:
            return {
                ...state,
                logs: action.payload,
            }

        case ADMIN_LOGS_PAGINATION:
            return {
                ...state,
                logsPagination: action.payload,
            }

        case ADMIN_FETCHING_LOGS:
            return {
                ...state,
                isFetchingLogs: action.payload,
            }

        default:
            return state
    }
}

export default adminReducer
