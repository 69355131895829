import React, {createRef, useCallback, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {CompanySidebar} from "../../../../components/company";
import {logoutUser} from "../../../../actions/authActions";
import {Button, Card, Col, Divider, Form, Grid, Input, Layout, Modal, Row, Select, Spin, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {IconButton} from "@mui/material";
import "./style.css";
import history from "../../../../utils/history";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import {
  CaretDownOutlined,
  DeleteOutlined, FilePdfOutlined, FileTextOutlined,
  FileZipOutlined,
  GlobalOutlined,
  HomeOutlined,
  LoadingOutlined,
  MailOutlined,
  PhoneOutlined,
  SearchOutlined,
  UserOutlined
} from "@ant-design/icons";
import {OrderExcelExportButton} from "../../../../components/Order/OrderExcelExportButton";
import {ArchivedBulkOrderPartList} from "../../../../components/Order/ArchivedBulkOrderPartList";
import {
  calculationToOrder, downloadOrderZip,
  fetchArchivedCalculation,
  fetchArchivedCalculationCustomerById,
  fetchArchivedCalculationCustomers, setGeneratingZip,
  deleteCalculation
} from "../../../../actions/ordersActions";
import {DropIcon} from "../../../../assets/icons/drop";
import {calcAdjustment, getManualType} from "../../../../utils/utility";
import ItemVisualisation from "../../../../components/Order/ItemVisualisation";
import {CustomerGrid} from "../../../../components/Order/CustomerGrid";
import PersonIcon from "@mui/icons-material/Person";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DatePicker from "react-datepicker";
import {countries} from "../../../../data/countries";
import PublicIcon from "@mui/icons-material/Public";
import {debounce} from "lodash";
import {S3_BUCKET_URL} from "../../../../constants";
import {Trans} from "react-i18next";
import {OrderOfferPdfExportButton} from "../../../../components/Order/OrderOfferPdfExportButton";

export const BulkOrderDetailsPage = (props) => {
  const user = useSelector(state => state.auth.user);
  const archivedCalculation = useSelector(state => state.orders.archivedCalculation);
  const isFetchingArchivedCalculation = useSelector(state => state.orders.isFetchingArchivedCalculation);
  const archivedCalculationCustomers = useSelector(state => state.orders.archivedCalculationCustomers);
  const isFetchingArchivedCalculationCustomers = useSelector(state => state.orders.isFetchingArchivedCalculationCustomers);
  const isGeneratingZip = useSelector(state => state.orders.isGeneratingZip)
  const isCollapsed = useSelector(state => state.util.isCollapsed);

  const [filteredCustomers, setFilteredCustomers] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [isDetailsViewVisible, setIsDetailsViewVisible] = useState(false)
  const [detailsItem, setDetailsItem] = useState(null)

  const [viewVisible, setViewVisible] = useState(false)
  const [itemView, setItemView] = useState(null)
  const [modalWidth, setModalWidth] = useState(window.innerWidth-100)
  const [modalHeight, setModalHeight] = useState(window.innerHeight-135)

  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [selectedCustomerId, setSelectedCustomerId] = useState(null)

  const [selectedCustomerData, setSelectedCustomerData] = useState(null)
  const [fetchingCustomerData, setFetchingCustomerData] = useState(false)

  const [selectedDate, setSelectedDate] = useState(null)

  const [filesItem, setFilesItem] = useState(null)
  const [downloadFilesVisible, setDownloadFilesVisible] = useState(false)

  const [isCreatingOrder, setIsCreatingOrder] = useState(false)

  const [adjustment, setAdjustment] = useState(0)

  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const { orderId } = useParams()

  const handleResize = () => {
    setModalWidth(window.innerWidth-100);
    setModalHeight(window.innerHeight-135);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  useEffect(()=>{
    if(orderId) {
      dispatch(fetchArchivedCalculation({orderId}))
        .then(()=>{})
        .catch(() => {
          history.push('/calculation')
        })
      dispatch(fetchArchivedCalculationCustomers())
        .then((customers)=>{
          setFilteredCustomers(customers)
        })
        .catch(() => {
          history.push('/calculation')
        })
    } else {
      history.push('/calculation')
    }
  }, [])

  const handleDownloadZip = () => {
    dispatch(downloadOrderZip({orderId: archivedCalculation.id})).then((zipUrl)=>{
      setTimeout(()=>{
        const link = document.createElement('a');
        link.href = zipUrl;

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        dispatch(setGeneratingZip(false))
      }, 1000)
    }).catch(()=>{
      dispatch(setGeneratingZip(false))
    })
  }

  const showDetailsView = (item) => {
    setIsDetailsViewVisible(true)
    setDetailsItem(item)
  }

  const closeDetailsView = () => {
    setIsDetailsViewVisible(false)
  }

  const showItem = (item) => {
    setItemView({...item, viewType: "details"})
    setViewVisible(true)
  }

  const cancelShowItem = () => {
    setItemView(null)
    setViewVisible(false)
  }

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer)
    setSelectedCustomerId(customer.id)
  }

  useEffect(()=>{
    if(selectedCustomer && selectedCustomer.id) {
      setFetchingCustomerData(true)
      dispatch(fetchArchivedCalculationCustomerById({customerId: selectedCustomer.id, type: selectedCustomer.type})).then((customer)=>{
        setSelectedCustomerData(customer)
        form.setFieldsValue({
          name: customer?.name,
          email: customer?.email,
          phone: customer?.phone,
          country: customer?.country,
          city: customer?.city,
          postalCode: customer?.postalCode,
          address: customer?.address
        })
        setAdjustment(customer.adjustment)
        setFetchingCustomerData(false)
      }).catch(()=>{setFetchingCustomerData(false)})
    }
  }, [selectedCustomer])

  const handleDateChange = date => {
    let datum = new Date(date)
    if(datum < new Date()) {
      datum = null
    }
    setSelectedDate(datum)
  };

  const escapeRegExp = (text) => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const filterCustomers = (data) => {
    if(!data.text) {
      setFilteredCustomers(data.archivedCalculationCustomers)
    } else {
      const customers = data.archivedCalculationCustomers.filter(item => item.name.toLowerCase().search(escapeRegExp(data.text.toLowerCase())) !== -1)
      setFilteredCustomers(customers)
    }
    setIsSearching(false)
  }

  const debounceSearchCustomers = useCallback(
    debounce(data => filterCustomers(data), 800),
    []
  );

  const searchCustomers = (text) => {
    setIsSearching(true)
    debounceSearchCustomers({archivedCalculationCustomers,text})
  }

  const handleCreateOrder = (values) => {
    setIsCreatingOrder(true)
    let data = {
      deliveryDate: values.deliveryDate,
      customerId: selectedCustomerId,
      orderId: archivedCalculation.id,
      customerType: selectedCustomerData.type,
      shippingData: {
        country: values.country,
        city: values.city,
        postalCode: values.postalCode,
        address: values.address,
        phone: values.phone
      }
    }

    dispatch(calculationToOrder(data)).then(()=>{
      setIsCreatingOrder(false)
    }).catch(()=>{
      setIsCreatingOrder(false)
    })
  }

  const handleShowDownloadFiles = (item) => {
    setFilesItem(item)
    setDownloadFilesVisible(true)
  }

  const handleCloseDownloadFiles = () => {
    setDownloadFilesVisible(false)
    setFilesItem(null)
  }

  const handleDeleteCalculation = (calculationId, e) => {
    dispatch(deleteCalculation({calculationId})).then(()=>{
      e()
      history.push('/calculation')
    }).catch(()=>{
      e()
    })
  }

  const onDeleteCalculation = (calculationId) => {
    let modal = Modal.confirm({
      className: "inputInfoText",
      visible: true,
      style: {minWidth: '500px', maxHeight: '90vh', marginTop: -50},
      centered: true,
      onOk: (e) => {
        modal.update(prevConfig => ({
          ...prevConfig,
          cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
          okButtonProps: {...prevConfig.okButtonProps, loading: true}
        }));
        handleDeleteCalculation(calculationId, e)
      },
      confirmLoading: true,
      destroyOnClose: true,
      width: 500,
      title: <span>{"Delete Calculation"}</span>,
      content: <div>
        <div>Are you sure you want to DELETE this Calculation?</div>
        <div>This action can NOT be reverted!</div>
      </div>,
      okText: <span style={{fontSize: 13, fontWeight: 500}}>Delete</span>,
      okButtonProps: {type: "danger", loading: false},
      cancelText: <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
      icon: <DeleteOutlined style={{color: "red"}}/>,
      zIndex: 2,
    })
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={() => dispatch(logoutUser({}))}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}} type="flex">
        <Spin
          spinning={isFetchingArchivedCalculation || isFetchingArchivedCalculationCustomers}
          wrapperClassName={"fetchingArchivedOrderSpin"}
          indicator={
            <div style={{position: "absolute", top: "35%", left: "50%", width: 400, transform: "translateX(-50%)"}}>
              <LoadingOutlined style={{ fontSize: 72 }} spin />
              <div style={{fontSize: 27, fontWeight: 500, marginTop: 10}}>Fetching Your Calculation</div>
            </div>
          }
        >
          <div style={{overflowX: 'hidden', height: "100%"}}>
            <div style={{marginRight: 15, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
              {archivedCalculation.id && !isFetchingArchivedCalculation && !isFetchingArchivedCalculationCustomers &&
                <Row gutter={[15, 10]}>
                  <Col span={24} order={1}>
                    <Card
                      bodyStyle={{
                        padding: "10px 15px 15px 15px"
                      }}
                      style={{
                        width: "100%",
                        borderRadius: 5,
                        border: "1px solid rgb(205, 205, 205)",
                        boxShadow: "rgba(0, 0, 0, 0.07) 0 2px 4px 1px"
                      }}
                    >
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{display: "flex"}}>
                          <div style={{marginRight: 5, marginTop: 6}}>
                            <IconButton
                              color="primary"
                              aria-label="back to pending orders"
                              size="small"
                              onClick={()=>{
                                history.push('/calculation')
                              }}
                            >
                              <ArrowBackIosRoundedIcon  fontSize="inherit" />
                            </IconButton>
                          </div>
                          <div className={"orderDetailsTitle"} style={{marginTop: 5}}>
                            {archivedCalculation.customName || archivedCalculation.name}
                          </div>
                        </div>
                        <div align={"right"}>
                          <Button
                            type="danger"
                            style={{
                              fontSize: 13,
                              fontWeight: 500,
                              marginTop: 5
                            }}
                            onClick={()=>{onDeleteCalculation(archivedCalculation.id)}}
                            loading={false}
                          >
                            <DeleteOutlined  style={{fontSize: 14}}/>
                            Delete
                          </Button>
                          <Divider type={"vertical"} style={{marginRight: 10, marginLeft: 10, borderLeft: "1px solid #cccccc", height: 27}}/>
                          <OrderExcelExportButton
                            role={user?.role}
                            name={archivedCalculation.customName || archivedCalculation.name}
                            isEditing={false}
                            parts={archivedCalculation.parts || []}
                            adjustment={adjustment}
                          />
                          <Button
                            type="primary"
                            style={{
                              marginRight: 5,
                              fontSize: 13,
                              fontWeight: 500,
                              marginTop: 5
                            }}
                            onClick={handleDownloadZip}
                            loading={isGeneratingZip}
                          >
                            <FileZipOutlined style={{fontSize: 14}}/>
                            Download Files
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xxl={9} xl={10} lg={{span: 12, order: 2}} span={24} order={3}>
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#333f48",
                      }}
                    >
                      Parts in Calculation
                    </div>
                    <ArchivedBulkOrderPartList
                      parts={archivedCalculation.parts || []}
                      showOtherItem={showDetailsView}
                      showDetailsItem={showItem}
                      handleShowDownloadFiles={handleShowDownloadFiles}
                      adjustment={adjustment}
                    />
                  </Col>
                  <Col xxl={15} xl={14} lg={{span: 12, order: 3}} span={24} order={2}>
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#333f48",
                      }}
                    >
                      Summary
                    </div>
                    <div
                      style={{
                        width: "100%",
                        borderRadius: 5,
                        border: "1px solid rgb(205, 205, 205)",
                        boxShadow: "rgba(0, 0, 0, 0.07) 0 2px 4px 1px"
                      }}
                    >
                      <Row>
                        <Col span={24}>
                          <Card
                            style={{
                              borderRadius: 5,
                              height: "70px",
                              width: "100%"
                            }}
                            bodyStyle={{
                              height: "100%",
                              width: "100%",
                              padding: "0 12px 0 12px",
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start  ", width: "100%", flexDirection: "column", whiteSpace: "nowrap", paddingRight: 10}}>
                                <div style={{fontSize: 13, fontWeight: 600, display: "flex", flexDirection: "row"}}>
                                  <span style={{width: 130, display: "inline-block"}}>Total Price:</span>
                                  {adjustment !== 0 ?
                                    <span style={{fontWeight: 500, color: "#047a04"}}>{Number(archivedCalculation.totalPrice * calcAdjustment(adjustment)).toFixed(2)} €</span>
                                    :
                                    <span style={{fontWeight: 500}}>{Number(archivedCalculation.totalPrice).toFixed(2)} €</span>
                                  }
                                </div>
                                <div style={{fontSize: 13, fontWeight: 600, display: "flex"}}>
                                  <span style={{width: 130, display: "inline-block"}}>Total with VAT:</span>
                                  {adjustment !== 0 ?
                                    <span
                                      style={{fontWeight: 500, color: "#047a04"}}>{Number(archivedCalculation.totalPrice * (1 + archivedCalculation.vatValue) * calcAdjustment(adjustment)).toFixed(2)} €</span>
                                    :
                                    <span style={{fontWeight: 500}}>{Number(archivedCalculation.totalPrice * (1 + archivedCalculation.vatValue)).toFixed(2)} €</span>
                                  }
                                </div>
                              </div>
                              <div style={{ maxWidth: 350, width: "100%", justifyContent: "flex-end", alignItems: "flex-end", marginTop: -4}}>
                                <div style={{fontSize: 13, fontWeight: 500, marginRight: 10, whiteSpace: "nowrap", marginBottom: 2}}>
                                  Search Customers:
                                </div>
                                <div>
                                  <Input
                                    allowClear
                                    placeholder={"Type to search"}
                                    onChange={(e)=>searchCustomers(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                        <Col span={24}>
                          <Card
                            style={{borderRadius: 5, width: "100%"}}
                            bodyStyle={{width: "100%", padding: "6px 6px 6px 6px"}}
                          >
                            <div style={{ paddingBottom: 4, paddingLeft: 6, fontWeight: 500 }}>Select a customer to create an order:</div>
                            <div style={{height: 'calc(100vh - 677px)', overflowY: "auto", overflowX: "hidden", minHeight: 150}}>
                              <CustomerGrid
                                customers={filteredCustomers}
                                itemCount={filteredCustomers.length}
                                itemSize={300}
                                selectCustomer={handleSelectCustomer}
                                selectedId={selectedCustomerId}
                                searching={isSearching}
                              />
                            </div>
                            <Divider style={{marginTop: 5, marginBottom: 5}}/>
                            <div style={{display: "flex", justifyContent: "flex-end", marginRight: 5, height: 21}}>
                              <div style={{marginRight: 15}}>
                                <div style={{display: "flex"}}>
                                  <div style={{marginRight: 5}}>
                                    <PersonIcon style={{fontSize: 15, color: "#008000"}}/>
                                  </div>
                                  <div style={{fontSize: 12, fontWeight: 500}}>
                                    On-Hefeos Customer
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div style={{display: "flex"}}>
                                  <div style={{marginRight: 5, marginTop: 1}}>
                                    <AccountBoxIcon style={{fontSize: 16, color: "#0054a1"}}/>
                                  </div>
                                  <div style={{fontSize: 12, fontWeight: 500}}>
                                    Off-Hefeos Customer
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      {!selectedCustomerData && fetchingCustomerData &&
                        <Spin
                          spinning={true}
                          indicator={<LoadingOutlined style={{ fontSize: 48, marginLeft: -24, marginTop: -24 }} spin />}
                        >
                          <div style={{height: 300}}>
                          </div>
                        </Spin>
                      }
                      {selectedCustomerData &&
                        <Spin
                          spinning={fetchingCustomerData}
                          indicator={<LoadingOutlined style={{ fontSize: 48, marginLeft: -24, marginTop: -24 }} spin />}
                        >
                          {!fetchingCustomerData &&
                            <Card
                              style={{
                                boxShadow: "0 0 24px 0 rgba(0, 0, 0, 0.13)",
                                border: '1px solid #e0e0e0',
                                borderRadius: 5,
                                marginTop: 15
                              }}
                              bodyStyle={{
                                padding: "10px 15px 10px 15px"
                              }}
                            >
                              <OrderOfferPdfExportButton
                                orderData={{
                                  totalPrice: archivedCalculation.totalPrice,
                                  producer: user.producer,
                                  customer: selectedCustomerData,
                                }}
                                isEditing={false}
                                parts={archivedCalculation.parts || []}
                              />
                            </Card>
                          }
                        <Card
                          style={{
                            boxShadow: "0 0 24px 0 rgba(0, 0, 0, 0.13)",
                            border: '1px solid #e0e0e0',
                            borderRadius: 5,
                            marginTop: 15
                          }}
                          bodyStyle={{
                            padding: "10px 15px 10px 15px"
                          }}
                        >
                          <Form
                            name={"shippingForm"}
                            form={form}
                            onFinish={handleCreateOrder}
                          >
                            <Row gutter={30}>
                              <Col span={24} style={{borderBottom: "1px dashed rgba(0, 0, 0, 0.14)"}}>
                                <div style={{display: "flex", justifyContent: "space-between", marginBottom: 8}}>
                                  <div style={{display: "flex"}}>
                                    <div
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#333f48"
                                      }}
                                    >
                                      Delivery date
                                    </div>
                                    <div style={{marginLeft: 20, marginTop: -3}}>
                                      <Form.Item
                                        name={"deliveryDate"}
                                        rules={[
                                          {required: true, message: ""}
                                        ]}
                                        style={{marginBottom: 0, height: 32}}
                                        autoComplete="off"
                                        initialValue={null}
                                      >
                                        <DatePicker
                                          selected={selectedDate}
                                          onChange={handleDateChange}
                                          dateFormat={'dd/MM/yyyy'}
                                          minDate={new Date()}
                                          isClearable={true}
                                          autoComplete="off"
                                          placeholderText="Click to select a date"
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                  <div>
                                  </div>
                                </div>
                              </Col>
                              {(screens['xl'] || !screens['lg']) &&
                                <Col
                                  xl={12}
                                  xs={24}
                                  style={{
                                    borderRight: "1px dashed rgba(0, 0, 0, 0.14)",
                                    borderBottom: "1px dashed rgba(0, 0, 0, 0.14)"
                                  }}
                                >
                                  <Row gutter={10} style={{marginTop: 8}}>
                                    <Col xxl={12} xl={24} xs={12}>
                                      <div style={{fontSize: 13, fontWeight: 500}}>Full Name:</div>
                                      <Form.Item
                                        name={"name"}
                                        rules={[
                                          {required: true, message: ""}
                                        ]}
                                        style={{marginBottom: 8, height: 32}}
                                        initialValue={selectedCustomerData.name}
                                      >
                                        <Input
                                          prefix={<UserOutlined/>}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xxl={12} xl={24} xs={12}>
                                      <div style={{fontSize: 13, fontWeight: 500}}>Email:</div>
                                      <Form.Item
                                        name={"email"}
                                        rules={[
                                          {required: true, message: ""}
                                        ]}
                                        style={{marginBottom: 8, height: 32}}
                                        initialValue={selectedCustomerData.email}
                                      >
                                        <Input
                                          prefix={<MailOutlined/>}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                      <div style={{fontSize: 13, fontWeight: 500}}>Phone:</div>
                                      <Form.Item
                                        name={"phone"}
                                        rules={[
                                          {required: true, message: ""}
                                        ]}
                                        style={{marginBottom: 8, height: 32}}
                                        initialValue={selectedCustomerData.phone}
                                      >
                                        <Input
                                          placeholder={"Enter contact phone"}
                                          prefix={<PhoneOutlined/>}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Col>
                              }
                              <Col
                                xl={12}
                                xs={24}
                                style={{borderBottom: "1px dashed rgba(0, 0, 0, 0.14)", paddingBottom: 4}}
                              >
                                <div style={{fontSize: 13, fontWeight: 500, marginTop: 8}}>Country:</div>
                                <Form.Item
                                  name={"country"}
                                  rules={[
                                    {required: true, message: ""}
                                  ]}
                                  className={"shippingArchivedFormItem"}
                                  initialValue={selectedCustomerData.country}
                                >
                                  <Select
                                    showSearch
                                    style={{width: '100%'}}
                                    prefix={<GlobalOutlined/>}
                                    suffixIcon={(e) => {
                                      return e.focused ? <SearchOutlined/> : <CaretDownOutlined/>
                                    }}
                                    placeholder="Select your country"
                                    optionFilterProp="children"
                                    notFoundContent={"Country not found"}
                                    filterOption={(input, option) =>
                                      option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    autoComplete="new-password"
                                  >
                                    {
                                      countries.map(item => {
                                        return (
                                          <Select.Option
                                            key={item.name}
                                            value={item.name}
                                            className={"countrySelect"}
                                          >
                                            <img
                                              src={item.flag}
                                              alt=""
                                              style={{
                                                position: "relative",
                                                top: -2,
                                                width: 18
                                              }}
                                            /> {item.name}
                                          </Select.Option>
                                        )
                                      })
                                    }
                                  </Select>
                                </Form.Item>
                                <Row gutter={10}>
                                  <Col span={13}>
                                    <div style={{fontSize: 13, fontWeight: 500}}>City:</div>
                                    <Form.Item
                                      name={"city"}
                                      rules={[
                                        {required: true, message: ""}
                                      ]}
                                      className={"shippingArchivedFormItem"}
                                      initialValue={selectedCustomerData.city}
                                    >
                                      <Input
                                        placeholder={"Enter country"}
                                        prefix={<PublicIcon style={{fontSize: 17}}/>}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={11}>
                                    <div style={{fontSize: 13, fontWeight: 500}}>Postal Code:</div>
                                    <Form.Item
                                      name={"postalCode"}
                                      className={"shippingArchivedFormItem"}
                                      rules={[
                                        {required: true, message: ""}
                                      ]}
                                      initialValue={selectedCustomerData.postalCode}
                                    >
                                      <Input
                                        placeholder={"Enter postal code"}
                                        prefix={<PublicIcon style={{fontSize: 17}}/>}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <div style={{fontSize: 13, fontWeight: 500}}>Address:</div>
                                <Form.Item
                                  name={"address"}
                                  rules={[
                                    {required: true, message: ""}
                                  ]}
                                  className={"shippingArchivedFormItem"}
                                  initialValue={selectedCustomerData.address}
                                >
                                  <Input
                                    placeholder={"Enter delivery address"}
                                    prefix={<HomeOutlined/>}
                                  />
                                </Form.Item>
                                {!screens['xl'] && screens['lg'] &&
                                  <div>
                                    <div style={{fontSize: 13, fontWeight: 500}}>Phone:</div>
                                    <Form.Item
                                      name={"phone"}
                                      rules={[
                                        {required: true, message: ""}
                                      ]}
                                      style={{marginBottom: 8, height: 32}}
                                      initialValue={selectedCustomerData.phone}
                                    >
                                      <Input
                                        placeholder={"Enter contact phone"}
                                        prefix={<PhoneOutlined/>}
                                      />
                                    </Form.Item>
                                  </div>
                                }
                              </Col>
                            </Row>
                            <div align={"right"} style={{marginTop: 10}}>
                              <Tooltip
                                color={"rgba(89,89,89,0.88)"}
                                overlayClassName={!selectedDate ? "small-tooltip" : "small-tooltip dont-show-wrapper"}
                                size={"small"}
                                title={<span className={"text12-500"} style={{color: "#ffffff"}}>Pick delivery date!</span>}
                              >
                                <Button
                                  type={"primary"}
                                  key={"submit"}
                                  htmlType={"submit"}
                                  disabled={!selectedDate}
                                  loading={isCreatingOrder}
                                >
                                  <span
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 500
                                    }}
                                  >
                                    Create Order
                                  </span>
                                </Button>
                              </Tooltip>
                            </div>
                          </Form>
                        </Card>
                        </Spin>
                      }
                    </div>
                  </Col>
                </Row>
              }
            </div>
          </div>
        </Spin>
        <ItemVisualisation
          isModelViewVisible={viewVisible}
          itemView={itemView}
          modalWidth={modalWidth}
          modalHeight={modalHeight}
          onCloseView={cancelShowItem}
          adjustment={adjustment}
        />
        <Modal
          visible={isDetailsViewVisible}
          centered={true}
          onCancel={closeDetailsView}
          title="Part Details"
          footer={null}
          width={600}
        >
          <div>
            {detailsItem &&
              <div style={{width: "100%"}}>
                <Card
                  className={"whiteModalCard"}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 5,
                    border: "1px solid rgb(215, 215, 215)",
                    boxShadow: "rgba(0, 0, 0, 0.07) 0 2px 4px 1px"
                  }}
                >
                  <div>
                    <div style={{fontSize: 20, fontWeight: 600, marginBottom: 10}}>{detailsItem.fileName || detailsItem.name}</div>
                    {detailsItem.partType === "sheetMetal" &&
                      <div>
                        <div className={"orderItemDetailsLabel"}>Material Grade:</div>
                        <div className={"orderItemDetailsValue"}><Trans>{detailsItem.selectedMaterial?.grade}</Trans></div>
                        <div className={"orderItemDetailsLabel"}>Thickness:</div>
                        <div className={"orderItemDetailsValue"}>{detailsItem.selectedMaterial?.thickness}mm</div>
                      </div>
                    }
                    {(detailsItem.partType === "machined" || detailsItem.partType === "profile")  &&
                      <div>
                        <div className={"orderItemDetailsLabel"}>Material Grade:</div>
                        <div className={"orderItemDetailsValue"}><Trans>{detailsItem.selectedMaterial?.grade || detailsItem.materialGrade}</Trans></div>
                      </div>
                    }
                    {detailsItem.standard &&
                      <div>
                        <div className={"orderItemDetailsLabel"}>Standard:</div>
                        <div className={"orderItemDetailsValue"}>{detailsItem.standard}</div>
                      </div>
                    }
                    <div className={"orderItemDetailsLabel"}>Quantity:</div>
                    <div className={"orderItemDetailsValue"}>{detailsItem.quantity}</div>
                    {detailsItem.coating?.hasCoating ?
                      <div style={{marginRight: 30, display: "flex"}}>
                        <div style={{marginTop: 10, marginLeft: 2, marginRight: 5}}>
                          <DropIcon style={{color: detailsItem.coating.ralHex, fontSize: 20}}/>
                        </div>
                        <div>
                          <div className={"orderItemDetailsLabel"}>Coating:</div>
                          <div
                            className={"orderItemDetailsValue"}
                          >
                            RAL {detailsItem.coating.colorRAL}, {detailsItem.coating.colorGlossId === "1" ? "Glossy (70±5)" : "Matte (30±5)"}
                          </div>
                        </div>
                      </div>
                      :
                      null
                    }
                    {detailsItem.note ?
                      <div>
                        <div className={"orderItemDetailsLabel"}>Note:</div>
                        <div className={"orderItemDetailsValue"}>{detailsItem.note}</div>
                      </div>
                      :
                      null
                    }
                    {detailsItem.quoteType === "quoted" &&
                      <div>
                        <div className={"orderItemDetailsLabel"}>Part Type:</div>
                        <div className={"orderItemDetailsValue"}>{getManualType(detailsItem.partType)}</div>
                        <div style={{display: "flex"}}>
                          <div style={{marginRight: 30}}>
                            <div className={"orderItemDetailsLabel"}>Price 1pc:</div>
                            <div className={"orderItemDetailsValue"}>€ {(parseFloat(detailsItem.pricePerPiece) || 0).toFixed(2)}</div>
                          </div>
                          <div>
                            <div className={"orderItemDetailsLabel"}>Total Price:</div>
                            <div className={"orderItemDetailsValue"}>€ {(parseFloat(detailsItem.totalPrice) || 0).toFixed(2)}</div>
                          </div>
                        </div>
                      </div>
                    }
                    {detailsItem.quoteType === "unquoted" &&
                      <div>
                        <div className={"orderItemDetailsLabel"}>Proposed Type:</div>
                        <div className={"orderItemDetailsValue"}>{getManualType(detailsItem.partType)}</div>
                        <div style={{display: "flex"}}>
                          <div style={{marginRight: 30}}>
                            <div className={"orderItemDetailsLabel"}>Price:</div>
                            <div className={"orderItemDetailsValue"}>RFQ</div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </Card>
              </div>
            }
          </div>
        </Modal>

        <Modal
          title="Download files"
          centered={true}
          visible={downloadFilesVisible}
          onCancel={() => handleCloseDownloadFiles()}
          footer={null}
          width={350}
        >
          <div>
            {filesItem &&
              <div align={"center"}>
                {filesItem.s3FilePath &&
                  <div style={{marginBottom: 8}}>
                    <Button
                      style={{width: 150}}
                      type={"primary"}
                      href={`${S3_BUCKET_URL + filesItem.s3FilePath}`}
                    >
                      <FileTextOutlined style={{fontSize: 14}} />
                      <span style={{fontSize: 13, fontWeight: 500}}>
                        Original File
                      </span>
                    </Button>
                  </div>
                }
                {filesItem.fileType === "step" &&
                  <div>
                    {filesItem.stepData?.filePaths?.unfoldedStepFilePath &&
                      <div style={{marginBottom: 8}}>
                        <Button
                          style={{width: 150}}
                          type={"primary"}
                          href={`${S3_BUCKET_URL + filesItem.stepData.filePaths.unfoldedStepFilePath}`}
                        >
                          <FileTextOutlined style={{fontSize: 14}} />
                          <span style={{fontSize: 13, fontWeight: 500}}>
                            Unfolded STEP
                          </span>
                        </Button>
                      </div>
                    }
                    {filesItem.stepData?.filePaths?.unfoldedDxfFilePath &&
                      <div style={{marginBottom: 8}}>
                        <Button
                          style={{width: 150}}
                          type={"primary"}
                          href={`${S3_BUCKET_URL + filesItem.stepData.filePaths.unfoldedDxfFilePath}`}
                        >
                          <FileTextOutlined style={{fontSize: 14}} />
                          <span style={{fontSize: 13, fontWeight: 500}}>
                            Generated DXF
                          </span>
                        </Button>
                      </div>
                    }
                  </div>
                }
                {filesItem.drawingPDFFile?.drawingPDFFilePath &&
                  <div>
                    <Button
                      style={{width: 150}}
                      className={"exportPdfButton"}
                      type={"primary"}
                      href={`${S3_BUCKET_URL + filesItem.drawingPDFFile.drawingPDFFilePath}`}
                    >
                      <FilePdfOutlined style={{fontSize: 14}}/>
                      <span style={{fontSize: 13, fontWeight: 500}}>
                        Part Drawing
                      </span>
                    </Button>
                  </div>
                }
              </div>
            }
          </div>
        </Modal>

      </Layout.Content>
    </Layout>
  )
}