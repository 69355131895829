import React from 'react'
import {useSelector} from 'react-redux'
import {Row, Col, Layout, Anchor} from 'antd'
import { logoutUser } from '../../../actions/authActions'
import { Sidebar } from '../../../components'

import prva from "../../../assets/images/step_img_1.png";
import druga from "../../../assets/images/step_img_2.png";
import treca from "../../../assets/images/step_img_3.png";
import cetvrta from "../../../assets/images/step_img_4.png";
import peta from "../../../assets/images/step_img_5.png";
import sesta from "../../../assets/images/step_img_6.png";
import sedma from "../../../assets/images/step_img_7.png";
import osma from "../../../assets/images/step_img_8.png";
import deveta from "../../../assets/images/step_img_9.png";
import deseta from "../../../assets/images/step_img_10.png";
import jedanaesta from "../../../assets/images/step_img_11.png";
import {CompanySidebar} from "../../../components/company";
import "./style.css";


const StepTipsPage = (props) => {
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user);

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      {(user.role === "producerAdmin" || user.role === "producerSubAdmin") ?
        <CompanySidebar
          onLogout={()=>{logoutUser()}}
          user={user}
        />
        :
        <Sidebar
          user={user}
          onLogout={() => logoutUser({})}
        />
      }

      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: isCollapsed ? 60 : 200,
            color: '#707070',
            backgroundColor: '#fff',
            zIndex: 10,
            width: 320
          }}
        >
          <div style={{marginLeft: 15, marginTop: 20}}>
            <div style={{fontSize: 25, marginBottom: 10, color: "white"}}>Table of Contents</div>
            <Anchor
              showInkInFixed={true}
              style={{marginLeft: 8}}
            >
              <Anchor.Link href="#1" title="Sheet Metal Design Tips for Bending"/>
              <Anchor.Link href="#2" title="Minimum Flange Length" />
              <Anchor.Link href="#3" title="Chamfered Sides"/>
              <Anchor.Link href="#4" title="Hole Distance from Bend" />
              <Anchor.Link href="#5" title="Symmetry" />
              <Anchor.Link href="#6" title="Rivet Nuts" />
              <Anchor.Link href="#7" title="Small Flanges on Big Parts" />
              <Anchor.Link href="#8" title="Bends Next to Each Other" />
              <Anchor.Link href="#9" title="Keep the Bends on the Same Line" />
              <Anchor.Link href="#10" title="The Bending Line is Parallel to a Side" />
              <Anchor.Link href="#11" title="Bend Relief" />
              <Anchor.Link href="#12" title="Bending a Box" />
              <Anchor.Link href="#13" title="Check the Flat Pattern" />
              <Anchor.Link href="#14" title="Minimum Bend Radius" />
              <Anchor.Link href="#15" title="Hemming" />
              <Anchor.Link href="#16" title="Consider the Material" />
            </Anchor>
          </div>
        </div>
        <Layout style={{marginLeft: 300}}>
          <Row>
            <Col xs={{offset: 1, span:22}} sm={{offset: 1, span:22}} md={{offset: 1, span:22}} lg={{offset: 1, span:22}} xl={{offset: 1, span:18}} xxl={{offset: 1, span:19}}>
              <div style={{fontSize: 33, color: 'black', fontWeight:'bold'}} id={"1"} className={"anchor-title"}>
                Sheet Metal Design Tips for Bending
                <a href="#1" className="anchor">#</a>
              </div>

              <div className={"anchorText"}>
                With the help of an experienced engineering team who know a fair bit about sheet metal bending,
                we created a guideline on how to create good sheet metal parts, appropriate for bending.
                Here is a list of common mistakes and the solutions to avoid them.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"2"} className={"anchor-title"}>
                Minimum Flange Length
                <a href="#2" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                There exists a minimum flange length.
                Depending on the bending force, and the thickness, the length differs.
                If you design a flange that is too short, it will "fall" awkwardly and you won't get the result you're looking for.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"3"} className={"anchor-title"}>
                Chamfered Sides
                <a href="#3" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                <img src={prva} alt={''} width={650}/>
                <br/>
                The chamfer must stop before the base of the detail
                <br/>
                <br/>
                If you want to make a flange that has one or two ends chamfered, the previous rule of a minimum flange length still applies.
                The chamfers have to leave enough room to accomplish proper bends, otherwise it will just look deformed and nobody's really satisfied.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"4"} className={"anchor-title"}>
                Hole Distance from Bend
                <a href="#4" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                <img src={druga} alt={''} width={650}/>
                <br/>
                Close-by holes may get warped
                <br/>
                <br/>
                If the holes are too close to the bend, they may get deformed.
                Round holes are not as problematic as other types but your bolts may still not fit through.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"5"} className={"anchor-title"}>
                Symmetry
                <a href="#5" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                <img src={treca} alt={''} width={650}/>
                <br/>
                To avoid confusion, the fourth round hole could be on both sides
                <br/>
                <br/>
                There lies a great danger in making parts that are almost symmetric.
                If possible, make it symmetric. If it is nearly symmetric, the bending press operator may get confused.
                The result? Your part will be bent in the wrong direction.
                <br/>
                <br/>
                The symmetry cannot be guaranteed in every instance,
                but then make sure that it is easily understood how the manufacturing should be done.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"6"} className={"anchor-title"}>
                Rivet Nuts
                <a href="#6" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                <img src={cetvrta} alt={''} width={650}/>
                <br/>
                Rivet nut in the way of bending tools
                <br/>
                <br/>
                If you use rivet nuts near the bending line, it's known that inserting them before bending is good for securing the applicability of it.
                After bending, the holes may be deformed. Still, make sure that the nuts won't be in the way of tools when bending.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"7"} className={"anchor-title"}>
                Small Flanges on Big Parts
                <a href="#7" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                <img src={peta} alt={''} width={650}/>
                <br/>
                A small bend at the end of a large part may lead to difficulties
                <br/>
                <br/>
                It is better to omit small flanges with big and heavy parts.
                It makes manufacturing very difficult and manual labour may be needed.
                But it costs more than simple machining.
                As a result, it is wiser to opt for alternative solution, if possible.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"8"} className={"anchor-title"}>
                Bends Next to Each Other
                <a href="#8" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                <img src={sesta} alt={''} width={650}/>
                <br/>
                The successive bends rely on minimum flange length
                <br/>
                <br/>
                If you want to include successive bends, check if it’s feasible.
                A problem arises when you cannot fit the already-bent part onto the die.
                If your bends face the same direction (a U-bend), then a common rule is to make design the intermediate part as longer than the flanges.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"9"} className={"anchor-title"}>
                Keep the Bends on the Same Line
                <a href="#9" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                <img src={sedma} alt={''} width={650}/>
                <br/>
                This part needs numerous readjustments
                <br/>
                <br/>
                It is best to keep the bends on the same line in case you have several flanges in succession.
                With this in mind, you can keep the number of operations at minimum.
                Otherwise, the operator needs to readjust the parts for every single bend, which means more time and more money.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"10"} className={"anchor-title"}>
                The Bending Line is Parallel to a Side
                <a href="#10" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                <img src={osma} alt={''} width={650}/>
                <br/>
                This kind of bending lines lead to inaccurate results
                <br/>
                <br/>
                As the headline says. There has to be a parallel side to your bending line for positioning purposes.
                If not, aligning the part is a real headache and you may end up with an unsatisfactory result.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"11"} className={"anchor-title"}>
                Bend Relief
                <a href="#11" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                <img src={deveta} alt={''} width={650}/>
                <br/>
                Bend reliefs are necessary
                <br/>
                <br/>
                To get the best outcome, it is advisable to not only make a small laser cut incision but an actual cutout on the sides of the flange-to-be – a bend relief.
                The width of such a cut should be above material thickness. This ensures that there are no tears or deformations to the final bend.
                Another good practice here is to include small radii to the bend reliefs, as they also relieve material stress.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"12"} className={"anchor-title"}>
                Bending a Box
                <a href="#12" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                <img src={deseta} alt={''} width={650}/>
                <br/>
                Small gaps guarantee a doable job
                <br/>
                <br/>
                When bending a box, small gaps should be left between the flanges.
                Otherwise the last bend can crash into the existing ones, breaking the whole structure.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"13"} className={"anchor-title"}>
                Check the Flat Pattern
                <a href="#13" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                One thing to keep in mind is switching your CAD view to flat pattern from time to time. There are many upsides to that.
                Firstly, if you get carried away with your flanges, you may end up with something that cannot exist in a flat pattern.
                What cannot exist in flat pattern, cannot exist in any other way.
                <br/>
                <br/>
                Measure the layout. Maybe you can adjust the design for optimal fit.
                Try to avoid going for a bigger sheet if the smaller size is in reach.
                Maybe you could fit 2 pieces onto the same sheet, if you just shed a few millimetres off?
                It will reflect on the final price quotation.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"14"} className={"anchor-title"}>
                Rule of Thumb for Minimum Bend Radius
                <a href="#14" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                Keep it simple. What could be simpler than choosing the inner radius (ir) just the same as the material thickness.
                This avoids later troubles, overthinking and silly mistakes. Dropping below that value can bring problems your way.
                Larger radius will just make some other calculations a little more difficult.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"15"} className={"anchor-title"}>
                Hemming
                <a href="#15" className="anchor">#</a>
              </div>
              <div className={"anchorText"}>
                <img src={jedanaesta} alt={''} width={650}/>
                <br/>
                Leave an inside radius, if possible
                <br/>
                <br/>
                If you want to strengthen the edges of your metal sheet, hemming is a great option.
                Still, some advice applies. It is better to leave a small radius inside the hem.
                Completely crushing the radius needs great power and tonnage.
                Also, it puts the material in danger of cracking. Leaving a radius, on the other hand, relieves this danger.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight:'bold'}} id={"16"} className={"anchor-title"}>
                Consider the Material
                <a href="#16" className="anchor">#</a>
              </div>
              <div className={"anchorText"} style={{ marginBottom: 100 }}>
                The regular thin 1…3 mm structural steel sheets can pretty much take anything.
                After that, you need to do your research. Some materials are much more capricious about the way they are handled.
                Getting a good result depends on your knowledge and on the help your production engineer is able to provide.
              </div>
            </Col>
          </Row>
        </Layout>
      </Layout.Content>
    </Layout>
  )
}

export default StepTipsPage