import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  Table, Layout, Row, Col,
  Button, Modal, Form,
  InputNumber, Divider, Checkbox, Input, Dropdown, Menu, Tag
} from 'antd'

import {CompanySidebar} from '../../../components/company'
import {logoutUser} from '../../../actions/authActions'
import {
  fetchInternalRelations,
  changeCustomerStatus,
  changeCustomerLimit, changeCustomerAdjustment
} from '../../../actions/customerRelations'
import {LoadingOutlined, MoreOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {getColumnSearchProps} from "../../../utils/utility";
import "./style.css";

export const InternalCustomerRelations = (props) => {
  let searchInput = null;
  const [isLimitVisible, setIsLimitVisible] = useState(false);
  const [isAdjustmentVisible, setIsAdjustmentVisible] = useState(false);
  const [details, setDetails] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const [filteredInfo, setFilteredInfo] = useState({})

  const user = useSelector(state => state.auth.user);
  const relations = useSelector(state => state.customerRelations.internalCustomerRelations);
  const isFetchingCustomerRelations = useSelector(state => state.customerRelations.fetchingInternalCustomerRelations);
  const isUpdatingCustomer = useSelector(state => state.customerRelations.updatingInternalCustomer);
  const pagination = useSelector(state => state.customerRelations.internalCustomerRelationsPagination);
  const isCollapsed = useSelector(state => state.util.isCollapsed);

  const dispatch = useDispatch();

  const getPageSize = () => {
    const count = Math.floor((window.innerHeight - 150) / 50)
    return count > 10 ? count : 10;
  }

  const pageSize = getPageSize();

  const initializeData = () => {
    const email = filteredInfo.email ? filteredInfo.email[0] : ""
    const name = filteredInfo.name ? filteredInfo.name[0] : ""

    dispatch(fetchInternalRelations({
      email: email,
      name: name,
      page: pagination.current,
      pagination: pagination
    }))
  }

  useEffect(() => {
    dispatch(fetchInternalRelations({
      email: "",
      name: "",
      page: 1,
      pagination: {...pagination, pageSize: pageSize}
    }))
  }, [])

  const handleLimitClose = () => {
    setIsLimitVisible(false);
    setDetails(null);
    setHasChanges(false);
  }
  const handleAdjustmentClose = () => {
    setIsAdjustmentVisible(false);
    setDetails(null);
    setHasChanges(false);
  }

  const handleTableChange = (pagination, filters) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    const email = filters.email ? filters.email[0] : ""
    const name = filters.name ? filters.name[0] : ""

    setFilteredInfo(filters)

    dispatch(fetchInternalRelations({
      email: email,
      name: name,
      page: pagination.current,
      pagination: pager
    }))
  }

  const handleChangeCustomerState = (customerId, isBlocked, e) => {
    dispatch(changeCustomerStatus({customerId, isBlocked})).then(()=>{
      e()
      initializeData()
    }).catch(()=>{
      e()
    })
  }

  const onToggleCustomerStatus = (isBlocked, customerId) => {
    if (isBlocked) {
      let modal = Modal.confirm({
        className: "inputInfoText",
        visible: true,
        style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
        centered: true,
        onOk: (e) => {
          modal.update(prevConfig => ({
            ...prevConfig,
            cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
            okButtonProps: {...prevConfig.okButtonProps, loading: true}
          }));
          handleChangeCustomerState(customerId, !isBlocked, e)
        },
        confirmLoading: true,
        destroyOnClose: true,
        width: 400,
        title: <span>{"Activate Customer"}</span>,
        content: <div>
          <div>Are you sure you want to ACTIVATE this Customer?</div>
        </div>,
        okText: <span style={{fontSize: 13, fontWeight: 500}}>Activate</span>,
        okButtonProps: {type: "primary", loading: false},
        cancelText: <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
        icon: <QuestionCircleOutlined style={{color: "#ffc739"}}/>,
        zIndex: 2,
      })
    } else {
      let modal = Modal.confirm({
        className: "inputInfoText",
        visible: true,
        style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
        centered: true,
        onOk: (e) => {
          modal.update(prevConfig => ({
            ...prevConfig,
            cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
            okButtonProps: {...prevConfig.okButtonProps, loading: true}
          }));
          handleChangeCustomerState(customerId, !isBlocked, e)
        },
        confirmLoading: true,
        destroyOnClose: true,
        width: 400,
        title: <span>{"Block Customer"}</span>,
        content: <div>
          <div>Are you sure you want to BLOCK this Customer?</div>
        </div>,
        okText: <span style={{fontSize: 13, fontWeight: 500}}>Block</span>,
        okButtonProps: {type: "danger", loading: false},
        cancelText: <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
        icon: <QuestionCircleOutlined style={{color: "#ffc739"}}/>,
        zIndex: 2,
      })
    }
  }

  const handleLimitUpdate = (values) => {
    dispatch(changeCustomerLimit({customerId: details.id, partLimit: values.partLimit, limited: !values.unlimited})).then(()=>{
      handleLimitClose()
      initializeData()
    }).catch(()=>{})
  }

  const handleAdjustmentUpdate = (values) => {
    dispatch(changeCustomerAdjustment({customerId: details.id, adjustment: values.adjustment / 100})).then(()=>{
      handleAdjustmentClose()
      initializeData()
    }).catch(()=>{})
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={() => {dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s", paddingLeft: 25, paddingRight: 25, paddingTop: 15}}>
        <Row type="flex">
          <Col span={24}>
            <div style={{ height: 32, display: "flex", alignItems: "center" }}>
              <div style={{fontWeight: 500, fontSize: 16}}>These are your On-Hefeos customer relations:</div>
            </div>
            <Divider style={{marginTop: 12, marginBottom: 14}}/>
          </Col>
        </Row>
        <Row type="flex">
          <Col span={24}>
            <React.Fragment>
              <Table
                columns={[
                  {
                    title: 'Name',
                    className: "darkerColumn",
                    key: 'name',
                    width: "27%",
                    render: row => (
                      <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#bdbdbd": "rgba(0, 0, 0, 0.85)"}}>
                        {row.name}
                      </div>
                    ),
                    ...getColumnSearchProps('name', searchInput, "name"),
                  },
                  {
                    title: 'Email',
                    key: 'email',
                    width: "27%",
                    render: row => (
                      <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#bdbdbd": "rgba(0, 0, 0, 0.85)"}}>
                        {row.email}
                      </div>
                    ),
                    ...getColumnSearchProps('email', searchInput, "email"),
                  },
                  {
                    title: 'Part Limit',
                    className: "darkerColumn",
                    key: 'limit',
                    align: "right",
                    width: "17%",
                    render: row => (
                      <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#bdbdbd": "rgba(0, 0, 0, 0.85)"}}>
                        {row.limited ? row.partLimit : "Unlimited"}
                      </div>
                    ),
                  },
                  {
                    title: 'Adjustment',
                    key: 'discount',
                    align: "right",
                    width: "17%",
                    render: row => (
                      <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#bdbdbd": "rgba(0, 0, 0, 0.85)"}}>
                        {Number(row.adjustment * 100).toFixed(2)}%
                      </div>
                    ),
                  },
                  {
                    title: 'Status',
                    className: "darkerColumn",
                    key: 'status',
                    width: "200px",
                    align: "center",
                    render: row => (
                      <div>
                        {!row.isBlocked ?
                          <Tag color="#51b364" style={{fontWeight: 600, textAlign: "center", width: 104, marginRight: 0}}>Active</Tag>
                          :
                          <Tag color="#b60a1c" style={{fontWeight: 600, textAlign: "center", width: 104, marginRight: 0}}>Blocked</Tag>
                        }
                      </div>
                    ),
                  },
                  {
                    title: '',
                    key: 'edit',
                    width: "20px",
                    align: "right",
                    render: row => (
                      <Dropdown
                        overlay={
                          <Menu className={'employeeEditMenu'}>
                            <Menu.Item
                              key='1'
                              disabled={row.isBlocked}
                              onClick={()=>{
                                setIsLimitVisible(true);
                                setDetails(row);
                                setHasChanges(false);
                              }}
                            >
                              <span style={{fontSize: 13, fontWeight: 600}}>
                                Edit Limit
                              </span>
                            </Menu.Item>
                            <Menu.Item
                              key='2'
                              disabled={row.isBlocked}
                              onClick={()=>{
                                setIsAdjustmentVisible(true);
                                setDetails(row);
                                setHasChanges(false);
                              }}
                            >
                              <span style={{fontSize: 13, fontWeight: 600}}>
                                Edit Adjustment
                              </span>
                            </Menu.Item>
                            <Menu.Item key='3' onClick={()=>{onToggleCustomerStatus(row.isBlocked, row.id)}}>
                              <span style={{fontSize: 13, fontWeight: 600}}>
                                {!row.isBlocked ? "Block" : "Activate"}
                              </span>
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <MoreOutlined style={{fontSize: 20, fontWeight: 'bold'}} />
                      </Dropdown>
                    ),
                  },
                ]}
                className={"pendingOrdersTable"}
                dataSource={relations}
                rowKey="id"
                pagination={pagination}
                loading={{
                  spinning: isFetchingCustomerRelations,
                  indicator: <LoadingOutlined style={{fontSize: 50, marginLeft: "-25px"}} spin/>
                }}
                onChange={handleTableChange}
                size={"small"}
                scroll={{x: '70%'}}
              />
            </React.Fragment>
          </Col>
        </Row>
        <Modal
          onCancel={handleLimitClose}
          title="Update Customer Limit"
          visible={isLimitVisible}
          footer={null}
          centered
          bodyStyle={{
            padding: 12,
          }}
          maskClosable={false}
          destroyOnClose
        >
          {details && (
            <React.Fragment>
              <Form
                className={"customerUpdateForm"}
                onFinish={handleLimitUpdate}
              >
                <div className={"infoBox"}>
                  <div className={"detailEntry"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 12, width: 195, whiteSpace: "nowrap" }}
                    >
                      Customer Name:
                    </div>
                    <Input
                      defaultValue={details.name}
                      disabled
                    />
                  </div>

                  <div className={"detailEntry"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 12, width: 195, whiteSpace: "nowrap" }}
                    >
                      Email:
                    </div>
                    <Input
                      defaultValue={details.email}
                      disabled
                    />
                  </div>

                  <div className={"detailEntry"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 12, width: 130, whiteSpace: "nowrap" }}
                    >
                      Unlimited:
                    </div>
                    <Form.Item
                      initialValue={!details.limited}
                      valuePropName={"checked"}
                      name={"unlimited"}
                    >
                      <Checkbox
                        onChange={(e) => {
                          setDetails(prev => ({...prev, limited: !e.target.checked}))
                          setHasChanges(true);
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className={"inlineField"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 10, width: 130, whiteSpace: "nowrap" }}
                    >
                      Part Limit:
                    </div>
                    <div className={"customerUpdateFormItemContainer"}>
                      <Form.Item name={"partLimit"} initialValue={details.partLimit}>
                        <InputNumber
                          disabled={!details.limited}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          precision={0}
                          onChange={() => {
                            setHasChanges(true);
                          }}
                          style={{width: 120}}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className={"updateButton"} align={"right"} style={{ width: "100%" }}>
                    <Form.Item>
                      <Button type={"primary"}
                              htmlType={"submit"}
                              style={{ fontWeight: 600, fontSize: 13, marginTop: 12 }}
                              disabled={!hasChanges}
                              loading={isUpdatingCustomer}
                      >
                        Update Customer
                      </Button>
                    </Form.Item>
                  </div>

                </div>
              </Form>
            </React.Fragment>
          )}
        </Modal>
        <Modal
          onCancel={handleAdjustmentClose}
          title="Update Customer Adjustment"
          visible={isAdjustmentVisible}
          footer={null}
          centered
          bodyStyle={{
            padding: 12,
          }}
          maskClosable={false}
          destroyOnClose
        >
          {details && (
            <React.Fragment>
              <Form
                className={"customerUpdateForm"}
                onFinish={handleAdjustmentUpdate}
              >
                <div className={"infoBox"}>
                  <div className={"detailEntry"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 12, width: 195, whiteSpace: "nowrap" }}
                    >
                      Customer Name:
                    </div>
                    <Input
                      defaultValue={details.name}
                      disabled
                    />
                  </div>

                  <div className={"detailEntry"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 12, width: 195, whiteSpace: "nowrap" }}
                    >
                      Email:
                    </div>
                    <Input
                      defaultValue={details.email}
                      disabled
                    />
                  </div>

                  <div className={"inlineField"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 10, width: 130, whiteSpace: "nowrap" }}
                    >
                      Adjustment:
                    </div>
                    <div className={"customerUpdateFormItemContainer"}>
                      <Form.Item
                        name={"adjustment"}
                        initialValue={details.adjustment * 100}
                      >
                        <InputNumber
                          formatter={(value, info) => info.userTyping ? `${value}` : `${Number(value).toFixed(2)}%`}
                          parser={value => value ? value.replace('%', '') : 0}
                          onChange={() => {
                            setHasChanges(true);
                          }}
                          min={-100}
                          max={100000}
                          style={{width: 120}}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className={"updateButton"} align={"right"} style={{ width: "100%" }}>
                    <Form.Item>
                      <Button type={"primary"}
                              htmlType={"submit"}
                              style={{ fontWeight: 600, fontSize: 13, marginTop: 12 }}
                              disabled={!hasChanges}
                              loading={isUpdatingCustomer}
                      >
                        Update Customer
                      </Button>
                    </Form.Item>
                  </div>

                </div>
              </Form>
            </React.Fragment>
          )}
        </Modal>
      </Layout.Content>
    </Layout>
  )
}
