import initialState from "./initialState";
import {
  CORPORATE_CUSTOMERS_BLOCK_CUSTOMER,
  CORPORATE_CUSTOMERS_CUSTOMERS,
  CORPORATE_CUSTOMERS_FETCHING_CUSTOMERS,
  CORPORATE_CUSTOMERS_FETCHING_DETAILS,
  CORPORATE_CUSTOMERS_IS_REMOVING_CUSTOMER,
  CORPORATE_CUSTOMERS_PAGINATION,
  CORPORATE_CUSTOMERS_REMOVE_CUSTOMER, CORPORATE_CUSTOMERS_TOGGLING_BLOCK
} from "../actions/actionTypes";

const corporateCustomersReducer = (state = initialState.corporateCustomers, action) => {
  switch (action.type) {
    case CORPORATE_CUSTOMERS_FETCHING_CUSTOMERS:
      return {
        ...state,
        fetchingCorporateCustomers: action.payload
      }

    case CORPORATE_CUSTOMERS_FETCHING_DETAILS:
      return {
        ...state,
        fetchingCorporateCustomerDetails: action.payload
      }

    case CORPORATE_CUSTOMERS_CUSTOMERS:
      return {
        ...state,
        corporateCustomers: action.payload
      }

    case CORPORATE_CUSTOMERS_PAGINATION:
      return {
        ...state,
        corporateCustomersPagination: action.payload
      }

    case CORPORATE_CUSTOMERS_BLOCK_CUSTOMER:
      return {
        ...state,
        corporateCustomers: state.corporateCustomers.map(item => {
          if (item.id === action.payload) {
            return {...item, isBlocked: !item.isBlocked}
          }
          return item
        })
      }

    case CORPORATE_CUSTOMERS_TOGGLING_BLOCK:
      return {
        ...state,
        togglingCorporateCustomerBlock: action.payload
      }

    case CORPORATE_CUSTOMERS_IS_REMOVING_CUSTOMER:
      return {
        ...state,
        removingCorporateCustomer: action.payload
      }

    case CORPORATE_CUSTOMERS_REMOVE_CUSTOMER:
      return {
        ...state,
        corporateCustomers: state.corporateCustomers.filter(item => item.id !== action.payload)
      }

    default:
      return state
  }
}

export default corporateCustomersReducer;
