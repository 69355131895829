import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Layout, Row, Col, Button, Card, Form, Input, Divider, Select, Upload, Checkbox, InputNumber} from 'antd'

import {adminRegisterCompany, logoutUser} from '../../../actions/authActions';
import {AdminSidebar} from '../../../components/admin'
import {
  PlusOutlined,
  FormOutlined, GlobalOutlined, BankOutlined,
  LockOutlined,
  MailOutlined,
  PercentageOutlined, PhoneOutlined, UserOutlined
} from "@ant-design/icons";
import {countries} from "../../../data/countries";

import "./style.css";
import {useWindowSize} from "../../../utils/utility";

const { Content } = Layout;

export const AdminDashboard = (props) => {
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [dividerBorderColor, setDividerBorderColor] = useState("rgba(0, 0, 0, 0.06)");
  const [displayTopBorder, setDisplayTopBorder] = useState(false);

  const isRegisteringCompany = useSelector(state => state.auth.registeringCompany)
  const dispatch = useDispatch();

  const windowSize = useWindowSize();
  const formRef = React.createRef();

  useEffect(() => {
    if (windowSize.width < 963) {
      setDividerBorderColor("transparent")
      setDisplayTopBorder(true);
    }
    else {
      setDividerBorderColor("rgba(0, 0, 0, 0.06)")
      setDisplayTopBorder(false);
    }
  }, [windowSize])

  //Nikad se nece pozvati ako nisu sva polja validna
  const onFinishForm = (values) => {

    const data = {
      "name": values.companyName,
      "country": values.country,
      "region": values.region,
      "city": values.city,
      "address": values.companyAddress,
      "postalCode": values.postalCode,
      "vat": values.vat,
      "uid": values.uid,
      "adminName": values.adminName,
      "email": values.adminEmail,
      "password": values.adminPassword,
      "contactName": values.contactFullName,
      "phone": values.contactPhoneNumber,
      "contactEmail": values.contactEmail,
      "hasBulkFunction": !!(values.companyBulk),
      "hasNestingFunction": !!(values.companyNesting),
      "hasOrderFunction": !!(values.companyOrders),
      "vatValue": values.vatValue,
      "domain": values.domain,
      "maxSubAdmins": values.maxSubAdmins
    }

    dispatch(adminRegisterCompany({image: values.logo.file, data: data}))
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => setImageUrl(reader.result));
    reader.readAsDataURL(img);
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <AdminSidebar
        onLogout={()=>{dispatch(logoutUser())}}
      />
      <Content style={{marginRight: 25, marginLeft: 60, paddingLeft: 20}}>
        <Card className={"tabMaterialCard"} style={{ marginTop: 15, marginLeft: 0, marginBottom: 20 }}>
          <h1>Register a New Producer</h1>
          <Divider style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 0"}}/>
          <Form
            ref={formRef}
            name={'admin-company-register'}
            onFinish={onFinishForm}
          >
            <Row gutter={25}>
              <Col flex={"400px"} style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column"}}>
                <Row>
                  <h1>Company logo</h1>
                  <div className={"redBoxLogo"}>
                  <Form.Item
                    name="logo"
                    valuePropName="logo"
                    rules={[
                      {required: true, message: 'The logo is required.'},
                    ]}
                    validateTrigger={'onChange'}
                  >
                    <Upload
                      name={"logo"}
                      listType={"picture-card"}
                      className={"logoUpload"}
                      showUploadList={false}
                      accept={".jpg,.jpeg,.png"}
                      beforeUpload={file => {
                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                        if (!isJpgOrPng) {
                          message.show(message.type.error, "Unsupported image format!")
                        }
                        const isLt2M = file.size / 1024 / 1024 < 2;
                        if (!isLt2M) {
                          message.show(message.type.error, "Maximum image size is 2MB!")
                        }
                        if(isJpgOrPng && isLt2M) {
                          setImageFile(file);
                          getBase64(file);
                          return false;
                        }
                        return false;
                      }}
                    >
                      {imageUrl ?
                        <img className={"uploadedImage"} src={imageUrl} alt="avatar" style={{maxWidth: '100%', maxHeight: '100%'}} />
                        : <div className={"uploadButton inputInfoText"}>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                          </div>
                      }
                    </Upload>
                  </Form.Item>
                </div>
                </Row>
                <Divider style={{width: "100%", minWidth: "0%", margin: "0 0 12px 0"}}/>
                <Row>
                  <h1>Additional functionalities</h1>
                  <Divider style={{width: "75%", minWidth: "0%", margin: "0 0 8px 0"}}/>
                  <div>
                    <Form.Item
                      name={"companyBulk"}
                      label={"Company provides handling in bulk"}
                      valuePropName={"checked"}
                      initialValue={true}
                    >
                      <Checkbox />
                    </Form.Item>

                    <Form.Item
                      name={"companyOrders"}
                      label={"Company allows order management"}
                      valuePropName={"checked"}
                      initialValue={true}
                    >
                      <Checkbox />
                    </Form.Item>

                    <Form.Item
                      name={"companyNesting"}
                      label={"Company uses nesting"}
                      valuePropName={"checked"}
                      initialValue={true}
                    >
                      <Checkbox />
                    </Form.Item>
                  </div>
                </Row>
              </Col>
              <Divider type="vertical" style={{ height: "auto", margin: "25px 12.5px 25px 12.5px", borderColor: dividerBorderColor}}/>
              <Col flex={"1 1 360px"} style={{ paddingLeft: 12}}>
                <Row gutter={25}>
                  {displayTopBorder && <Divider style={{width: "75%", minWidth: "0%", margin: "0 0 8px 12px"}}/>}
                  <Col flex={"1 1 300px"} style={{  maxWidth: 525 }}>
                    <div className={"redBox"}>
                      <h1>Company info</h1>
                      <Form.Item
                        label={"Company name"}
                        name={'companyName'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'string', min: 2 ,message: 'Company name must must be a least 2 characters long.'},
                          {type: 'string', max: 60 ,message: 'Company name is too long.'},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the company name"
                          prefix={<BankOutlined />}
                        />
                      </Form.Item>
                      <Form.Item
                        label={"Domain"}
                        name={'domain'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'string', min: 2 ,message: 'Domain must must be a least 2 characters long.'},
                          {type: 'string', max: 60 ,message: 'Domain is too long.'},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the company domain"
                          prefix={<BankOutlined />}
                        />
                      </Form.Item>

                      <Form.Item
                          label={"Country"}
                          name={'country'}
                          rules={[
                              {required: true, message: 'This field is required.'},
                          ]}
                          autoComplete="off"
                          validateTrigger={'onChange'}
                      >
                        <Select
                          showSearch
                          style={{width: '100%'}}
                          prefix={<GlobalOutlined />}
                          placeholder="Select your country"
                          optionFilterProp="children"
                          notFoundContent={"Country not found"}
                          filterOption={(input, option) =>
                            option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {
                            countries.map(item => {
                              return (
                                <Select.Option key={item.name} value={item.name} className={"countrySelect"}>
                                  <img src={item.flag} alt="" style={{position: "relative", top: -2, width: 18}}/> {item.name}
                                </Select.Option>
                              )
                            })
                          }
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label={"City"}
                        name={'city'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'string', min: 2 ,message: 'City name must be at least 2 characters long.'},
                          {type: 'string', max: 60 ,message: 'City name is too long.'},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the city name"
                          prefix={<GlobalOutlined />}
                        />
                      </Form.Item>

                      <Form.Item
                        label={"Region"}
                        name={'region'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'string', min: 2 ,message: 'Region name must be at least 2 characters long.'},
                          {type: 'string', max: 60 ,message: 'Region name is too long.'},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the region name"
                          prefix={<GlobalOutlined />}
                        />
                      </Form.Item>

                      <Form.Item
                        label={"Postal code"}
                        name={'postalCode'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'string', min: 2 ,message: 'Postal code must be at least 2 characters long.'},
                          {type: 'string', max: 10 ,message: 'Postal code is too long.'},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the postal code"
                          prefix={<GlobalOutlined />}
                        />
                      </Form.Item>

                      <Form.Item
                        label={"Company address"}
                        name={'companyAddress'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'string', min: 2 ,message: 'The address must be at least 2 characters long.'},
                          {type: 'string', max: 60 ,message: 'Address is too long.'},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the company address"
                          prefix={<GlobalOutlined />}
                        />
                      </Form.Item>

                      <Form.Item
                        label={"VAT"}
                        name={'vat'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'string', min: 2 ,message: 'VAT must be at least 2 characters long.'},
                          {type: 'string', max: 60 ,message: 'VAT is too long.'},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          type={'string'}
                          min={0}
                          placeholder="Enter the VAT number"
                          prefix={<PercentageOutlined />}
                        />
                      </Form.Item>

                      <Form.Item
                        label={"UID"}
                        name={'uid'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'string', min: 2 ,message: 'UID must be at least 2 characters long.'},
                          {type: 'string', max: 60 ,message: 'UID is too long.'},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the UID"
                          prefix={<FormOutlined />}
                        />
                      </Form.Item>
                      <Form.Item
                        label={"VAT Value"}
                        name={'vatValue'}
                        rules={[
                          {required: true, message: 'This field is required.'}
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <InputNumber
                          min={0}
                          max={1}
                          placeholder="Enter the VAT value"
                          style={{width: "100%"}}
                        />
                      </Form.Item>
                      <Divider style={{width: "75%", minWidth: "0%", margin: "0 0 8px 0"}}/>
                    </div>
                  </Col>
                  <Col flex={"1 1 300px"} >
                    <h1>Admin info</h1>
                    <div className={"redBox"}>
                      <Form.Item
                        label={"Admin name"}
                        name={'adminName'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'string', min: 2 ,message: 'Admin name must be at least 2 characters long.'},
                          {type: 'string', max: 60 ,message: 'Admin name is too long.'},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the admin name"
                          prefix={<UserOutlined />}
                        />
                      </Form.Item>

                      <Form.Item
                        label={"Admin email"}
                        name={'adminEmail'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'email', message: 'Please enter a valid email.'},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the admin email"
                          prefix={<MailOutlined />}
                        />
                      </Form.Item>

                      <Form.Item
                        label={"Admin password"}
                        name={'adminPassword'}
                        type={"password"}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'string', min: 2 ,message: 'Password must be at least 6 characters long.'},
                          {type: 'string', max: 20 ,message: 'Password is too long.'},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the admin password"
                          prefix={<LockOutlined />}
                        />
                      </Form.Item>
                      <Divider style={{width: "75%", minWidth: "0%", margin: "0 0 8px 0"}}/>

                      <h1>Contact info</h1>

                      <Form.Item
                        label={"Contacts' full name"}
                        name={'contactFullName'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'string', min: 2 ,message: "Contacts' full name must have at least 2 characters."},
                          {type: 'string', max: 60 ,message: "Contacts' full name is too long."},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the contacts' full name"
                          prefix={<UserOutlined />}
                        />
                      </Form.Item>

                      <Form.Item
                        label={"Contacts' phone number"}
                        name={'contactPhoneNumber'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'string', min: 2 ,message: "Phone number must be at least 6 digits long"},
                          {type: 'string', max: 60 ,message: "Phone number is too long."},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the contacts' phone number"
                          prefix={<PhoneOutlined />}
                        />
                      </Form.Item>

                      <Form.Item
                        label={"Contacts' email"}
                        name={'contactEmail'}
                        rules={[
                          {required: true, message: 'This field is required.'},
                          {type: 'email' ,message: 'Please enter a valid email.'},
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <Input
                          placeholder="Enter the contacts' email"
                          prefix={<MailOutlined />}
                        />
                      </Form.Item>
                      <Divider style={{width: "100%", minWidth: "0%", margin: "0 0 8px 0"}}/>
                      <Form.Item
                        label={"Max Sub Admins"}
                        name={'maxSubAdmins'}
                        rules={[
                          {required: true, message: 'This field is required.'}
                        ]}
                        validateTrigger={'onChange'}
                      >
                        <InputNumber
                          placeholder="Enter max admins"
                          min={0}
                          style={{width: "100%"}}
                        />
                      </Form.Item>
                      <Divider style={{width: "100%", minWidth: "0%", margin: "0 0 8px 0"}}/>

                      <Form.Item>
                        <Button
                          style={{ marginTop: 12 }}
                          type="primary"
                          loading={isRegisteringCompany}
                          htmlType="submit"
                        >
                          <span style={{ fontWeight: 600, fontSize: 13 }}>Register Company</span>
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Col >
            </Row>
          </Form>
        </Card>
      </Content>
    </Layout>
  )
}

