import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Layout, Row, Col, Divider} from 'antd'

import { CompanySidebar } from '../../../components/company'
import { logoutUser } from '../../../actions/authActions'
import history from "../../../utils/history";


import "./style.css"
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import TimelineIcon from '@mui/icons-material/Timeline';
import SnippetFolderOutlinedIcon from '@mui/icons-material/SnippetFolderOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import {MaterialIcon} from "../../../assets/icons/material";
import {DropIcon} from "../../../assets/icons/drop";

import sheet from "../../../assets/icons/bendIcon.webp";
import counter from "../../../assets/icons/counterSinkIcon.webp";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import {S3_BUCKET_URL} from "../../../constants";

const checkPermission = (user, permission) => {
  return (user?.role === "producerAdmin" || (user?.role === "producerSubAdmin" && user?.permissions?.includes(permission)))
}

export const CompanyDashboard = (props) => {

  const user = useSelector(state => state.auth.user)
  const role = useSelector(state => state.auth.user?.role)
  const hasOrderFunction = useSelector(state => state.auth.user?.producer?.hasOrderFunction)
  const hasBulkFunction = useSelector(state => state.auth.user?.producer?.hasBulkFunction)
  const hasNestingFunction = useSelector(state => state.auth.user?.producer?.hasNestingFunction)
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const dispatch = useDispatch()

  return (
    <Layout
        style={{
          overflow: 'auto',
          minHeight: '100vh',
        }}
    >
      <CompanySidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{overflowX: "hidden"}}>
          <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
            <Row type="flex">
              <Col span={24}>
                <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                  <div>
                    <div className={"dashboardWelcome"}>Welcome back, {user !== null ? user.producer.name : null}.</div>
                    <div style={{fontWeight: 500, fontSize: 16}}>Use the quick navigation below to get to the desired page:</div>
                  </div>
                  <div>
                    <img src={`${S3_BUCKET_URL}${user.producer.logoUrl}`} height={62}/>
                  </div>
                </div>
              </Col>
            </Row>
            <Divider style={{marginTop: 12, marginBottom: 16}}/>
            <Row gutter={[12, 0]}>
              {hasOrderFunction && checkPermission(user, "orders") &&
                <Col xxl={{span:12, order: 1}} xl={{span:24, order: 1}} md={{span:24, order: 1}} sm={{span:24, order: 1}} xs={{span:24, order: 1}}>
                  <div
                    className={"dashboardContainerBox"}
                    style={{backgroundColor: "rgba(255,255,255,0.6)", marginBottom: 10, padding: "6px 6px 12px 6px"}}
                  >
                    <div className={"dashboardTitle"}>
                      Instant Quoting & Orders
                    </div>
                    <Row gutter={[6, 8]}>
                      {hasBulkFunction && checkPermission(user, "bulk") &&
                        <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                          <div className={"dashboardCard"} onMouseDown={(e) => {
                            if (e.button === 1) {
                              window.open("/calculation", "_blank");
                            } else if (e.button === 0) {
                              history.push("/calculation")
                            }
                          }}>
                            <div className={"dashboardCardTitleWrapper"}>
                              <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                                <WysiwygOutlinedIcon style={{fontSize: 22, color: "#820ee3"}}/>
                              </div>
                              <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                                Instant Quoting
                              </div>
                            </div>
                            <div>
                              <div className={"dashboardText"}>Instantly quote the parts for production.</div>
                              <div className={"dashboardText"}>Turn quotations into orders.</div>
                            </div>
                          </div>
                        </Col>
                      }
                      <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/pending", "_blank");
                          } else if (e.button === 0) {
                            history.push("/pending")
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                              <PendingActionsIcon style={{fontSize: 22, color: "#f0bd27"}}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Pending Orders
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>Review your pending orders.</div>
                            <div className={"dashboardText"}>Quote the orders that need manual quotation.</div>
                          </div>
                        </div>
                      </Col>
                      <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e)=>{
                          if (e.button === 1) {
                            window.open("/awaiting", "_blank");
                          } else if (e.button === 0) {
                            history.push("/awaiting")
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                              <HourglassEmptyOutlinedIcon style={{fontSize: 22, color: "#9966cc"}}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Awaiting Orders
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View your awaiting orders.</div>
                            <div className={"dashboardText"}>See the recent awaiting confirmations.</div>
                          </div>
                        </div>
                      </Col>
                      <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e)=>{
                          if (e.button === 1) {
                            window.open("/accepted", "_blank");
                          } else if (e.button === 0) {
                            history.push("/accepted")
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                              <CheckCircleOutlinedIcon style={{fontSize: 21, color: "#51b364"}}/>
                            </div>
                            <div className={"dashboardCardTitle"}  style={{marginLeft: 5}}>
                              Accepted Orders
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View your accepted orders.</div>
                            <div className={"dashboardText"}>Update the status of production.</div>
                          </div>
                        </div>
                      </Col>
                      <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e)=>{
                          if (e.button === 1) {
                            window.open("/archive", "_blank");
                          } else if (e.button === 0) {
                            history.push("/archive")
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                              <SnippetFolderOutlinedIcon style={{fontSize: 21, color: "#1890ff"}}/>
                            </div>
                            <div className={"dashboardCardTitle"}  style={{marginLeft: 5}}>
                              Archived Orders
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>Review your archived orders.</div>
                            <div className={"dashboardText"}>Track all the completed orders.</div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              }
              {hasOrderFunction && ((checkPermission(user, "bulk") && hasBulkFunction) || (checkPermission(user, "nesting") && hasNestingFunction) || checkPermission(user, "customers")) &&
                <Col xxl={{span:12, order: 3}} xl={{span:24, order: 3}} md={{span:24, order: 3}} sm={{span:24, order: 3}} xs={{span:24, order: 3}}>
                  <div
                    className={"dashboardContainerBox"}
                    style={{backgroundColor: "rgba(255,255,255,0.6)", marginBottom: 11, padding: "6px 6px 12px 6px"}}
                  >
                    <div className={"dashboardTitle"}>
                      Customers & Employees
                    </div>
                    <Row gutter={[6, 8]}>
                      {hasNestingFunction && checkPermission(user, "nesting") &&
                        <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                          <div className={"dashboardCard"} onMouseDown={(e) => {
                            if (e.button === 1) {
                              window.open("/nesting", "_blank");
                            } else if (e.button === 0) {
                              history.push("/nesting")
                            }
                          }}>
                            <div className={"dashboardCardTitleWrapper"}>
                              <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                                <BackupTableOutlinedIcon style={{fontSize: 21, color: "#ef5a0c"}}/>
                              </div>
                              <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                                Nesting
                              </div>
                            </div>
                            <div>
                              <div className={"dashboardText"}>Company level nesting.</div>
                              <div className={"dashboardText"}>Nest your orders to prepare them for production.</div>
                            </div>
                          </div>
                        </Col>
                      }
                      {((role === "producerSubAdmin" && checkPermission(user, "customers") && hasBulkFunction) || (role === "producerAdmin" && hasBulkFunction && !hasNestingFunction)) &&
                        <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                          <div className={"dashboardCard"} onMouseDown={(e) => {
                            if (e.button === 1) {
                              window.open("/external-customer-relations", "_blank");
                            } else if (e.button === 0) {
                              history.push("/external-customer-relations")
                            }
                          }}>
                            <div className={"dashboardCardTitleWrapper"}>
                              <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                                <GroupAddOutlinedIcon style={{fontSize: 23, color: "#3778da"}}/>
                              </div>
                              <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                                Off-Hefeos Customers
                              </div>
                            </div>
                            <div>
                              <div className={"dashboardText"}>View all your Off-Hefeos customers.</div>
                              <div className={"dashboardText"}>Add new Off-Hefeos customers.</div>
                            </div>
                          </div>
                        </Col>
                      }
                      {checkPermission(user, "customers") &&
                        <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                          <div className={"dashboardCard"} onMouseDown={(e) => {
                            if (e.button === 1) {
                              window.open("/internal-customer-relations", "_blank");
                            } else if (e.button === 0) {
                              history.push("/internal-customer-relations")
                            }
                          }}>
                            <div className={"dashboardCardTitleWrapper"}>
                              <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                                <PeopleAltOutlinedIcon style={{fontSize: 22, color: "#0ac9c0"}}/>
                              </div>
                              <div className={"dashboardCardTitle"} style={{marginLeft: 5, maxWidth: 210}}>
                                On-Hefeos Customers
                              </div>
                            </div>
                            <div>
                              <div>
                                <div className={"dashboardText"}>View all your On-Hefeos customers.</div>
                                <div className={"dashboardText"}>Set customer price adjustments.</div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      }
                      {role === "producerAdmin" &&
                        <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                          <div className={"dashboardCard"} onMouseDown={(e) => {
                            if (e.button === 1) {
                              window.open("/employeeManagement", "_blank");
                            } else if (e.button === 0) {
                              history.push("/employeeManagement")
                            }
                          }}>
                            <div className={"dashboardCardTitleWrapper"}>
                              <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                                <BadgeOutlinedIcon style={{fontSize: 22, color: "#333f48"}}/>
                              </div>
                              <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                                Employees
                              </div>
                            </div>
                            <div>
                              <div className={"dashboardText"}>Manage your employees.</div>
                              <div className={"dashboardText"}>Add new employees or change existing ones.</div>
                            </div>
                          </div>
                        </Col>
                      }
                    </Row>
                  </div>
                </Col>
              }
              {!hasOrderFunction && (checkPermission(user, "orders") || checkPermission(user, "bulk") || checkPermission(user, "customers")) &&
                <Col xxl={{span:12, order: 1}} xl={{span:24, order: 1}} md={{span:24, order: 1}} sm={{span:24, order: 1}} xs={{span:24, order: 1}}>
                  <div
                    className={"dashboardContainerBox"}
                    style={{backgroundColor: "rgba(255,255,255,0.6)", marginBottom: 10, padding: "6px 6px 12px 6px"}}
                  >
                    <div className={"dashboardTitle"}>
                      {checkPermission(user, "orders") ? "Orders Management" : "Automation"}
                    </div>
                    <Row gutter={[6, 8]}>
                      {checkPermission(user, "bulk") &&
                        <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                          <div className={"dashboardCard"} onMouseDown={(e) => {
                            if (e.button === 1) {
                              window.open("/calculation", "_blank");
                            } else if (e.button === 0) {
                              history.push("/calculation")
                            }
                          }}>
                            <div className={"dashboardCardTitleWrapper"}>
                              <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                                <WysiwygOutlinedIcon style={{fontSize: 22, color: "#820ee3"}}/>
                              </div>
                              <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                                Calculation
                              </div>
                            </div>
                            <div>
                              <div className={"dashboardText"}>Quickly calculate the price of production.</div>
                              <div className={"dashboardText"}>Turn calculations into orders.</div>
                            </div>
                          </div>
                        </Col>
                      }
                      {checkPermission(user, "orders") &&
                        <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                          <div className={"dashboardCard"} onMouseDown={(e) => {
                            if (e.button === 1) {
                              window.open("/accepted", "_blank");
                            } else if (e.button === 0) {
                              history.push("/accepted")
                            }
                          }}>
                            <div className={"dashboardCardTitleWrapper"}>
                              <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                                <PendingActionsIcon style={{fontSize: 22, color: "#51b364"}}/>
                              </div>
                              <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                                Orders
                              </div>
                            </div>
                            <div>
                              <div className={"dashboardText"}>View your orders.</div>
                              <div className={"dashboardText"}>Update the status of production.</div>
                            </div>
                          </div>
                        </Col>
                      }
                      {checkPermission(user, "orders") &&
                        <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                          <div className={"dashboardCard"} onMouseDown={(e) => {
                            if (e.button === 1) {
                              window.open("/archive", "_blank");
                            } else if (e.button === 0) {
                              history.push("/archive")
                            }
                          }}>
                            <div className={"dashboardCardTitleWrapper"}>
                              <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                                <SnippetFolderOutlinedIcon style={{fontSize: 21, color: "#1890ff"}}/>
                              </div>
                              <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                                Archive
                              </div>
                            </div>
                            <div>
                              <div className={"dashboardText"}>Review your archived orders.</div>
                              <div className={"dashboardText"}>Track all the completed orders.</div>
                            </div>
                          </div>
                        </Col>
                      }
                      {checkPermission(user, "customers") &&
                        <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                          <div className={"dashboardCard"} onMouseDown={(e) => {
                            if (e.button === 1) {
                              window.open("/external-customer-relations", "_blank");
                            } else if (e.button === 0) {
                              history.push("/external-customer-relations")
                            }
                          }}>
                            <div className={"dashboardCardTitleWrapper"}>
                              <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                                <PeopleAltOutlinedIcon style={{fontSize: 22, color: "#0ac9c0"}}/>
                              </div>
                              <div className={"dashboardCardTitle"} style={{marginLeft: 5, maxWidth: 210}}>
                                Customers
                              </div>
                            </div>
                            <div>
                              <div>
                                <div className={"dashboardText"}>View all your customers.</div>
                                <div className={"dashboardText"}>Add new customers.</div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      }
                      {role === "producerAdmin"  &&
                        <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                          <div className={"dashboardCard"} onMouseDown={(e) => {
                            if (e.button === 1) {
                              window.open("/employeeManagement", "_blank");
                            } else if (e.button === 0) {
                              history.push("/employeeManagement")
                            }
                          }}>
                            <div className={"dashboardCardTitleWrapper"}>
                              <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                                <BadgeOutlinedIcon style={{fontSize: 22, color: "#333f48"}}/>
                              </div>
                              <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                                Employees
                              </div>
                            </div>
                            <div>
                              <div className={"dashboardText"}>Manage your employees.</div>
                              <div className={"dashboardText"}>Add new employees or change existing ones.</div>
                            </div>
                          </div>
                        </Col>
                      }
                    </Row>
                  </div>
                </Col>
              }
              {(checkPermission(user, "materials") || (!hasOrderFunction && hasNestingFunction && checkPermission(user, "nesting"))) &&
                <Col xxl={{span:12, order: 2}} xl={{span:24, order: 2}} md={{span:24, order: 2}} sm={{span:24, order: 2}} xs={{span:24, order: 2}}>
                <div className={"dashboardContainerBox"}
                     style={{backgroundColor: "rgba(255,255,255,0.6)", marginBottom: 11, padding: "6px 6px 12px 6px"}}
                >
                  <div className={"dashboardTitle"}>
                    Price Parameters
                  </div>
                  <Row gutter={[6, 8]}>
                    {checkPermission(user, "materials") &&
                      <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/materials?tab=material", "_blank");
                          } else if (e.button === 0) {
                            history.push("/materials", {tab: "6"})
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div className={"dashboardCardTitle"}>
                              <MaterialIcon style={{marginLeft: 1, color: "#ec050d"}}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Materials
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View company materials.</div>
                            <div className={"dashboardText"}>Add available materials and their parameters.</div>
                          </div>
                        </div>
                      </Col>
                    }
                    {checkPermission(user, "materials") &&
                      <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/materials?tab=cutting", "_blank");
                          } else if (e.button === 0) {
                            history.push("/materials", {tab: "1"})
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                              <TimelineIcon style={{fontSize: 21, color: "#d3b700"}}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Cutting Machines
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View your cutting machines.</div>
                            <div className={"dashboardText"}>Set machine parameters and speeds.</div>
                          </div>
                        </div>
                      </Col>
                    }
                    {checkPermission(user, "materials") &&
                      <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/materials?tab=bending", "_blank");
                          } else if (e.button === 0) {
                            history.push("/materials", {tab: "2"})
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: -2}} className={"dashboardCardTitle"}>
                              <img style={{height: 21}} src={sheet} alt={"part"}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Bending
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View your bending parameters.</div>
                            <div className={"dashboardText"}>Set up your bending machine.</div>
                          </div>
                        </div>
                      </Col>
                    }
                    {checkPermission(user, "materials") &&
                      <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/materials?tab=machining", "_blank");
                          } else if (e.button === 0) {
                            history.push("/materials", {tab: "3"})
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: -2}} className={"dashboardCardTitle"}>
                              <img style={{height: 22}} src={counter} alt={"part"}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Simple Machining
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View simple machining settings.</div>
                            <div className={"dashboardText"}>Adjust machining services and parameters.</div>
                          </div>
                        </div>
                      </Col>
                    }
                    {checkPermission(user, "materials") &&
                      <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/materials?tab=coating", "_blank");
                          } else if (e.button === 0) {
                            history.push("/materials", {tab: "4"})
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            {/*"#9de80b"*/}
                            <div className={"dashboardCardTitle"}>
                              <DropIcon style={{fontSize: 15, color: "#5c06f1"}}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Surface Coating
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>View surface coating settings.</div>
                            <div className={"dashboardText"}>Adjust coating services and parameters.</div>
                          </div>
                        </div>
                      </Col>
                    }
                    {!hasOrderFunction && hasNestingFunction && checkPermission(user, "nesting") &&
                      <Col xxl={12} xl={6} md={12} sm={24} xs={24}>
                        <div className={"dashboardCard"} onMouseDown={(e) => {
                          if (e.button === 1) {
                            window.open("/nesting", "_blank");
                          } else if (e.button === 0) {
                            history.push("/nesting")
                          }
                        }}>
                          <div className={"dashboardCardTitleWrapper"}>
                            <div style={{marginTop: 2}} className={"dashboardCardTitle"}>
                              <BackupTableOutlinedIcon style={{fontSize: 21, color: "#ef5a0c"}}/>
                            </div>
                            <div className={"dashboardCardTitle"} style={{marginLeft: 5}}>
                              Nesting
                            </div>
                          </div>
                          <div>
                            <div className={"dashboardText"}>Company level nesting.</div>
                            <div className={"dashboardText"}>Nest your orders to prepare them for production.</div>
                          </div>
                        </div>
                      </Col>
                    }
                  </Row>
                </div>
              </Col>
              }
            </Row>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}
