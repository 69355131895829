import {
  BULK_ORDER_SET_PART_COLOR,
  BULK_ORDER_REMOVE_PART_COLOR,
  BULK_ORDER_SET_PART_QUANTITY,
  BULK_ORDER_SET_PART_MATERIAL_SURCHARGE,
  BULK_ORDER_SET_PART_NOTE,
  BULK_ORDER_SET_PART_MATERIAL,
  BULK_ORDER_SET_PART_THICKNESS,
  BULK_ORDER_RESET_TO_PROCESS,
  BULK_ORDER_REMOVE_ITEM,
  BULK_ORDER_REMOVE_MULTIPLE_ITEMS,
  BULK_ORDER_APPLY_MULTIPLE_ITEMS,
  BULK_ORDER_SET_MANUAL_PART_TYPE,
  BULK_ORDER_CLEAR_ORDER,
  BULK_ORDER_SAVE_TO_ARCHIVE,
  BULK_ORDER_DELETE_ARCHIVED_BULK_ORDER,
  BULK_ORDER_SET_IS_DELETING_ARCHIVED_BULK_ORDER,
  ORDERS_SET_ARCHIVED_BULK_ORDERS,
  ORDERS_SET_FETCHING_ARCHIVED_BULK_ORDERS,
  BULK_ORDER_INCREMENT_TO_PROCESS,
  BULK_ORDER_INCREMENT_UPLOADED,
  BULK_ORDER_ADD_NEW_PART,
  BULK_ORDER_UPDATE_PART,
  BULK_ORDER_CHANGE_PART_TO_ERROR,
  BULK_ORDER_INCREMENT_PROCESSED,
  BULK_ORDER_UPDATE_PART_FILE_PATH,
  BULK_ORDER_UPDATE_PART_DATA,
  BULK_ORDER_UPDATE_PART_PRICE,
  BULK_ORDER_UPDATE_ORDER_PRICE,
  BULK_ORDER_REMOVE_ERROR_PARTS,
  BULK_ORDER_SET_PART_STANDARD,
  BULK_ORDER_SET_PART_DRAWING,
  BULK_ORDER_UPDATE_MANY_PART_PRICES, ORDER_ADD_NEW_PART
} from './actionTypes'

import {API_BASE_URL} from '../constants'
import {http, message} from '../utils'
import {forceLogoutUser} from "./authActions";


const uploadNewPart = payload => dispatch => {

  const formData = new FormData()
  formData.set('data', JSON.stringify({fileId: payload.data.fileId, uploadTime: payload.data.uploadTime, uploadIndex: payload.data.uploadIndex}))
  formData.set('file', payload.file)

  return http.formPost(`${API_BASE_URL}/order-parts/createOnOrder/${payload.data.orderId}`, formData).then(
    response => {
      for (let d of response.data) {
        dispatch({
          type: BULK_ORDER_ADD_NEW_PART,
          payload: {
            ...payload.data,
            fileId: d.fileId,
            fileName: d.fileName,
            uploadIndex: d.uploadIndex,
            state: "uploading"
          },
        })

        dispatch({
          type: BULK_ORDER_INCREMENT_TO_PROCESS,
          payload: null
        })

        if (response.data.state !== "error") {
          dispatch({
            type: BULK_ORDER_UPDATE_PART,
            payload: {...d}
          })
          payload.callback({orderId: payload.data.orderId, orderPartId: d.id})
        } else {
          dispatch({
            type: BULK_ORDER_CHANGE_PART_TO_ERROR,
            payload: {...d}
          })
        }

        dispatch({
          type: BULK_ORDER_INCREMENT_UPLOADED,
          payload: null
        })
      }
    }
  ).catch((error) => {
    if(error?.response?.status === 401) {
      dispatch(forceLogoutUser())
    }
  })
}

const clearBulkOrder = payload => dispatch => {
  return http
    .get(`${API_BASE_URL}/internal-order/clear-draft`)
    .then(res => {
      const emptyInternalOrder = res.data.internalOrder
      dispatch({
        type: BULK_ORDER_CLEAR_ORDER,
        payload: emptyInternalOrder
      });
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error;
      }
    })
}

const changeItemColor = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_SET_PART_COLOR,
    payload,
  })
}

const changeStandard = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_SET_PART_STANDARD,
    payload,
  })
}

const removeItemColor = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_REMOVE_PART_COLOR,
    payload,
  })
}

const changeItemQuantity = payload => dispatch => {
  if (payload.id && !isNaN(parseInt(payload.quantity))) {
    dispatch({
      type: BULK_ORDER_SET_PART_QUANTITY,
      payload,
    })
  }
}

const changeItemMaterialSurcharge = payload => dispatch => {
  if (payload.id && !isNaN(parseInt(payload.materialSurchargePerPiece))) {
    dispatch({
      type: BULK_ORDER_SET_PART_MATERIAL_SURCHARGE,
      payload,
    })
  }
}

const changeItemNote = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: BULK_ORDER_SET_PART_NOTE,
      payload,
    })
  }
}

const changeItemMaterial = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: BULK_ORDER_SET_PART_MATERIAL,
      payload,
    })
  }
}

const changeItemThickness = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: BULK_ORDER_SET_PART_THICKNESS,
      payload,
    })
  }
}

const changeManualItemType = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: BULK_ORDER_SET_MANUAL_PART_TYPE,
      payload,
    })
  }
}

const resetToProcess = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_RESET_TO_PROCESS,
    payload,
  })
}

const removeItem = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_REMOVE_ITEM,
    payload,
  })
}

const removeMultipleItems = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_REMOVE_MULTIPLE_ITEMS,
    payload,
  })
}

const applyMultipleItems = payload => dispatch => {
  // console.log(payload)
  dispatch({
    type: BULK_ORDER_APPLY_MULTIPLE_ITEMS,
    payload,
  })
}

const saveBulkOrderToArchive = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_SAVE_TO_ARCHIVE,
    payload: payload
  })
}

const fetchArchivedBulkOrders = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_FETCHING_ARCHIVED_BULK_ORDERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/companies/${payload.companyId}/bulkOrders`)
    .then(response => {
      let new_array = response.data.data.sort(function (a, b) {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      })
      dispatch({
        type: ORDERS_SET_ARCHIVED_BULK_ORDERS,
        payload: new_array,
      })
      dispatch({
        type: ORDERS_SET_FETCHING_ARCHIVED_BULK_ORDERS,
        payload: false,
      })
    })
    .catch(error => {
      if (error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        dispatch(payload.logout_user)
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: ORDERS_SET_FETCHING_ARCHIVED_BULK_ORDERS,
          payload: false,
        })
      }
    })
}

const deleteArchiveOrder = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_SET_IS_DELETING_ARCHIVED_BULK_ORDER,
    payload: true,
  })

  return http
    .delete(`${API_BASE_URL}/companies/${payload.companyId}/bulkOrders/${payload.orderId}`)
    .then(response => {
      dispatch({
        type: BULK_ORDER_DELETE_ARCHIVED_BULK_ORDER,
        payload: payload.orderId,
      })
      dispatch({
        type: BULK_ORDER_SET_IS_DELETING_ARCHIVED_BULK_ORDER,
        payload: false,
      })
    })
    .catch(error => {
      if (error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        dispatch(payload.logout_user)
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: BULK_ORDER_SET_IS_DELETING_ARCHIVED_BULK_ORDER,
          payload: false,
        })
      }
    })
}

const changeOrderPrice = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_UPDATE_ORDER_PRICE,
    payload: payload
  })
}

const changePartPrice = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_UPDATE_PART_PRICE,
    payload: payload
  })
}

const changeManyPartPrices = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_UPDATE_MANY_PART_PRICES,
    payload: payload
  })
}

const changePartToError = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_CHANGE_PART_TO_ERROR,
    payload: payload
  })
  dispatch({
    type: BULK_ORDER_INCREMENT_PROCESSED,
    payload: null
  })
}

const updatePartData = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_UPDATE_PART_DATA,
    payload: payload
  })
  dispatch({
    type: BULK_ORDER_INCREMENT_PROCESSED,
    payload: null
  })
}

const updatePartFilePath = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_UPDATE_PART_FILE_PATH,
    payload: payload
  })
}

const removeErrorParts = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_REMOVE_ERROR_PARTS,
    payload: null
  })

  return http
    .deleteData(`${API_BASE_URL}/order-parts/deletePartsWithErrorOnOrder/${payload.orderId}`, {partIds: payload.partIds})
    .then(() => {
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }

    })
}

const uploadBulkPartDrawing = payload => dispatch => {
  const formData = new FormData()
  formData.set('file', payload.file)

  return http
    .formPost(`${API_BASE_URL}/order-parts/${payload.orderPartId}/addPartDrawingOnOrder/${payload.orderId}`, formData)
    .then(() => {
      dispatch({
        type: BULK_ORDER_SET_PART_DRAWING,
        payload: {
          orderPartId: payload.orderPartId,
          data: {
            drawingPDFFileName: payload.file.name,
            drawingPDFFilePath: ""
          }
        }
      })
    })
    .catch(error => {
      console.log(error)
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })

}

const removeBulkPartDrawing = payload => dispatch => {
  dispatch({
    type: BULK_ORDER_SET_PART_DRAWING,
    payload: {
      orderPartId: payload.orderPartId,
      data: {
        drawingPDFFileName: "",
        drawingPDFFilePath: ""
      }
    }
  })
  return http
    .delete(`${API_BASE_URL}/order-parts/${payload.orderPartId}/removePartDrawingOnOrder/${payload.orderId}`)
    .then(() => {
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })

}

export {
  uploadNewPart, deleteArchiveOrder, clearBulkOrder, applyMultipleItems,
  changeItemColor, changeItemMaterial, changeItemNote, changeItemQuantity,
  changeItemMaterialSurcharge,
  changeItemThickness, changeManualItemType, changeStandard,
  removeItemColor, removeMultipleItems, resetToProcess, fetchArchivedBulkOrders,
  removeItem, saveBulkOrderToArchive, changeOrderPrice, changePartPrice,
  changePartToError, updatePartData, updatePartFilePath, removeErrorParts,
  uploadBulkPartDrawing, removeBulkPartDrawing, changeManyPartPrices
}