import React, { useEffect, useState } from 'react'
import {
  Row, Col, Button, Layout,
  Card, Upload, Input, Form,
  Select, Divider, Spin, Modal, InputNumber, Radio
} from 'antd'
import {
  BankOutlined, FormOutlined, GlobalOutlined,
  LoadingOutlined, LockOutlined, MailOutlined,
  PercentageOutlined, PhoneOutlined, PlusOutlined,
  UserOutlined
} from "@ant-design/icons";
import {fetchSelf, logoutUser} from '../../../actions/authActions'
import {CompanySidebar} from '../../../components/company'
import {message} from "../../../utils";
import './style.css'
import {countries} from "../../../data/countries";
import {useDispatch, useSelector} from "react-redux";
import {useWindowSize} from "../../../utils/utility";
import {CircularProgress} from "@mui/material";
import {changeLogo, updateProducer, changePassword} from "../../../actions/producerSettingsActions";
import {S3_BUCKET_URL} from "../../../constants";
import CurrencyList from 'currency-list'

const { Content } = Layout;

export const CompanySettingsPage = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [logoChanged, setLogoChanged] = useState(false);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [isUpdatingCompany, setIsUpdatingCompany] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const [companyData, setCompanyData] = useState({});
  const [hasCompanyChanges, setHasCompanyChanges] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const authUserData = useSelector(state => state.auth.user)
  const isCollapsed = useSelector(state => state.util.isCollapsed);

  const dispatch = useDispatch();
  const formRef = React.createRef();

  const windowSize = useWindowSize();

  const getCurrencies = () => {
    const currencyList = CurrencyList.getAll("en_US")
    const currencyListKeys = Object.keys(currencyList)

    const data = []
    currencyListKeys.forEach((key, index) => {
      data.push({
        "label": `${currencyList[key]["code"]} - ${currencyList[key]["name"]} - ${currencyList[key]["symbol_native"]}`,
        "value": `${currencyList[key]["code"]} - ${currencyList[key]["name"]} - ${currencyList[key]["symbol_native"]}`
      })
    })
    return data
  }

  const onFinishForm = (values) => {
    const data = {...values, vatValue: values.vatValue / 100}
    setIsUpdatingCompany(true)
    dispatch(updateProducer({data})).then(()=>{
      dispatch(fetchSelf()).then(()=>{
        setIsUpdatingCompany(false)
      }).catch(()=>{})
    }).catch(()=>{
      setIsUpdatingCompany(false)
    })
  }

  const setInitialData = () => {
    setCompanyData({
      "name": authUserData.producer.name,
      "country": authUserData.producer.country,
      "region": authUserData.producer.region,
      "city": authUserData.producer.city,
      "address": authUserData.producer.address,
      "postalCode": authUserData.producer.postalCode,
      "vat": authUserData.producer.vat,
      "uid": authUserData.producer.uid,
      "adminName": authUserData.name,
      "email": authUserData.email,
      "password": "Sample text",
      "contactName": authUserData.producer.contactName,
      "phone": authUserData.producer.phone,
      "contactEmail": authUserData.producer.contactEmail,
      "vatValue": authUserData.producer.vatValue
    })
    setImageUrl(`${S3_BUCKET_URL}${authUserData.producer.logoUrl}`)
  }

  useEffect(() => {
    setInitialData()
  }, [])

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => setImageUrl(reader.result));
    reader.readAsDataURL(img);
  }

  const handleLogoChange = () => {
    setIsUploadingLogo(true)
    setLogoChanged(false)
    dispatch(changeLogo({file: imageFile})).then(()=>{
      dispatch(fetchSelf()).then(()=>{
        setIsUploadingLogo(false)
      }).catch(()=>{})
    }).catch(()=>{
      setIsUploadingLogo(false)
      setLogoChanged(true)
    })
  }

  const handlePasswordChange = (values) => {
    setIsChangingPassword(true)
    dispatch(changePassword({
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    })).then(()=>{
      setIsChangingPassword(false)
      setIsModalVisible(false)
    }).catch(()=>{
      setIsChangingPassword(false)
    })
  }

  return (
    <Layout
      style={{
        overflow: 'hidden',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={authUserData}
      />
      <Content style={{marginRight: 25, marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s", paddingLeft: 25, paddingTop: 15, marginBottom: 15}}>

        <div style={{ height: 32, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{fontWeight: 500, fontSize: 16}}>Company Settings</div>
          <Button type={"primary"}
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
          >
            <span style={{ fontWeight: 600, fontSize: 13 }}>Account Settings</span>
          </Button>
        </div>
        <Divider style={{marginTop: 12, marginBottom: 12}}/>
        <Spin
          spinning={authUserData?.role === "producerSubAdmin" && !authUserData?.permissions?.includes("settings")}
          indicator={<div style={{width: 400, fontWeight: 500, fontSize: 38, marginLeft: -200, marginTop: 50}}>
            Unauthorized
          </div>}
        >
        <Card className={"settingsCard"}
              style={{
                margin: "0", minHeight: 650, backgroundColor: "rgba(255, 255, 255, 0.6)",
                borderRadius: 5, border: "1px solid rgb(205, 205, 205)"
              }}
              bodyStyle={{ height: "100%", padding: 0 }}
        >
          <div className="card-container">
            <Form
              ref={formRef}
              name={'admin-company-register'}
              onFinish={onFinishForm}
              onValuesChange={() => {!hasCompanyChanges && setHasCompanyChanges(true)}}
            >
              <Row gutter={25}>
                <Col flex={"1 1 400px"} style={{display: "flex", justifyContent: "flex-start", flexDirection: "column"}}>
                  <h1>Company logo</h1>
                  <div align={"center"}>
                    <Spin
                      spinning={isUploadingLogo}
                      size={"large"}
                      indicator={<CircularProgress sx={{color: "rgb(83,170,238)"}} style={{marginLeft: -20, marginTop: -20}}/>}
                    >
                      <div className={"redBoxLogo"}>
                        <Form.Item>
                          <Upload
                            name={"logo"}
                            listType={"picture-card"}
                            className={"logoUpload"}
                            showUploadList={false}
                            accept={".jpg,.jpeg,.png"}
                            beforeUpload={(file) => {
                              const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                              if (!isJpgOrPng) {
                                message.show(message.type.error, "Unsupported image format!")
                              }
                              const isLt2M = file.size / 1024 / 1024 < 2;
                              if (!isLt2M) {
                                message.show(message.type.error, "Maximum image size is 2MB!")
                              }
                              if (isJpgOrPng && isLt2M) {
                                setImageFile(file);
                                setLogoChanged(true)
                                getBase64(file);
                                return false;
                              }
                              return false;
                            }}
                          >
                            {imageUrl ?
                              <img className={"uploadedImage"} src={imageUrl} alt="avatar"
                                   style={{maxWidth: '100%', maxHeight: '100%'}}/>
                              :
                              <div className={"uploadButton"}>
                                <PlusOutlined/>
                                <div style={{marginTop: 8}}>Upload</div>
                              </div>
                            }
                          </Upload>
                        </Form.Item>
                      </div>
                    </Spin>
                  </div>
                  {logoChanged ?
                    <div
                      style={{display: "flex", justifyContent: "center"}}
                    >
                      <Button
                        type={"primary"}
                        onClick={() => handleLogoChange()}
                      >
                        <span style={{fontSize: 13, fontWeight: 500}}>Apply Logo Change</span>
                      </Button>
                    </div>
                    :
                    <div style={{height: 32}}/>
                  }
                </Col>
                {windowSize?.width > 945 &&
                  <Divider type="vertical" style={{height: "auto", margin: "25px 12.5px 25px 12.5px"}}/>
                }
                <Col flex={"40 1 360px"} style={{paddingLeft: 12}}>
                  { !(companyData.name) ?
                    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 62 }} spin />}/>
                    </div>
                    :
                    <div>
                      <Row gutter={25} style={{ height: "100%" }}>
                        <div style={{width: "100%", height: "100%", display: "contents"}}>
                          <Col xs={24} sm={12} style={{maxWidth: 525}}>
                            <div className={"redBox"}>
                              <h1 style={{marginBottom: 0}}>Company info</h1>
                              <Form.Item
                                label={"Company name"}
                                name={'name'}
                                rules={[
                                  {required: true, message: 'This field is required.'},
                                  {
                                    type: 'string',
                                    min: 2,
                                    message: 'Company name must must be a least 2 characters long.'
                                  },
                                  {type: 'string', max: 60, message: 'Company name is too long.'},
                                ]}
                                validateTrigger={'onChange'}
                                initialValue={companyData.name}
                              >
                                <Input
                                  placeholder="Enter the company name"
                                  prefix={<BankOutlined/>}
                                />
                              </Form.Item>

                              <Form.Item
                                label={"Country"}
                                name={'country'}
                                rules={[
                                  {required: true, message: 'This field is required.'},
                                ]}
                                validateTrigger={'onChange'}
                                initialValue={companyData.country}
                              >
                                <Select
                                  showSearch
                                  style={{width: '100%'}}
                                  prefix={<GlobalOutlined/>}
                                  placeholder="Select your country"
                                  optionFilterProp="children"
                                  notFoundContent={"Country not found"}
                                  filterOption={(input, option) =>
                                    option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {countries.map(item => {
                                    return (
                                      <Select.Option key={item.name} value={item.name} className={"countrySelect"}>
                                        <img src={item.flag} alt="" style={{position: "relative", top: -2, width: 18}}/> {item.name}
                                      </Select.Option>
                                    )
                                  })}
                                </Select>
                              </Form.Item>

                              <Form.Item
                                label={"City"}
                                name={'city'}
                                rules={[
                                  {required: true, message: 'This field is required.'},
                                  {type: 'string', min: 2, message: 'City name must be at least 2 characters long.'},
                                  {type: 'string', max: 60, message: 'City name is too long.'},
                                ]}
                                validateTrigger={'onChange'}
                                initialValue={companyData.city}
                              >
                                <Input
                                  placeholder="Enter the city name"
                                  prefix={<GlobalOutlined/>}
                                />
                              </Form.Item>

                              <Form.Item
                                label={"Region"}
                                name={'region'}
                                rules={[
                                  {required: true, message: 'This field is required.'},
                                  {type: 'string', min: 2, message: 'Region name must be at least 2 characters long.'},
                                  {type: 'string', max: 60, message: 'Region name is too long.'},
                                ]}
                                validateTrigger={'onChange'}
                                initialValue={companyData.region}
                              >
                                <Input
                                  placeholder="Enter the region name"
                                  prefix={<GlobalOutlined/>}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} style={{maxWidth: 525}}>
                            <div className={"redBox"} style={{ paddingTop: 33 }}>
                              <Form.Item
                                label={"Postal code"}
                                name={'postalCode'}
                                rules={[
                                  {required: true, message: 'This field is required.'},
                                  {type: 'string', min: 2, message: 'Postal code must be at least 2 characters long.'},
                                  {type: 'string', max: 10, message: 'Postal code is too long.'},
                                ]}
                                validateTrigger={'onChange'}
                                initialValue={companyData.postalCode}
                              >
                                <Input
                                  placeholder="Enter the postal code"
                                  prefix={<GlobalOutlined/>}
                                />
                              </Form.Item>

                              <Form.Item
                                label={"Company address"}
                                name={'address'}
                                rules={[
                                  {required: true, message: 'This field is required.'},
                                  {type: 'string', min: 2, message: 'The address must be at least 2 characters long.'},
                                  {type: 'string', max: 60, message: 'Address is too long.'},
                                ]}
                                validateTrigger={'onChange'}
                                initialValue={companyData.address}
                              >
                                <Input
                                  placeholder="Enter the company address"
                                  prefix={<GlobalOutlined/>}
                                />
                              </Form.Item>

                              <Form.Item
                                label={"VAT ID"}
                                name={'vat'}
                                rules={[
                                  {required: true, message: 'This field is required.'},
                                  {type: 'string', min: 2, message: 'VAT must be at least 2 characters long.'},
                                  {type: 'string', max: 60, message: 'VAT is too long.'},
                                ]}
                                validateTrigger={'onChange'}
                                initialValue={companyData.vat}
                              >
                                <Input
                                  type={'string'}
                                  min={0}
                                  placeholder="Enter the VAT ID"
                                  
                                />
                              </Form.Item>

                              <Form.Item
                                label={"UID"}
                                name={'uid'}
                                rules={[
                                  {required: true, message: 'This field is required.'},
                                  {type: 'string', min: 2, message: 'UID must be at least 2 characters long.'},
                                  {type: 'string', max: 60, message: 'UID is too long.'},
                                ]}
                                validateTrigger={'onChange'}
                                initialValue={companyData.uid}
                              >
                                <Input
                                  placeholder="Enter the UID"
                                  prefix={<FormOutlined/>}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </div>
                      </Row>
                      <Divider style={{minWidth: "0%", maxWidth: "1025px", margin: "0 0 8px 0"}}/>
                      <Row gutter={25} style={{ height: "100%" }}>
                        <Col xs={24} sm={12} style={{maxWidth: 525}}>
                          <div className={"redBox"}>
                            <h1 style={{marginBottom: 0}}>Contact info</h1>
                            <Form.Item
                              label={"Contacts' full name"}
                              name={'contactName'}
                              rules={[
                                {required: true, message: 'This field is required.'},
                                {
                                  type: 'string',
                                  min: 2,
                                  message: "Contacts' full name must have at least 2 characters."
                                },
                                {type: 'string', max: 60, message: "Contacts' full name is too long."},
                              ]}
                              validateTrigger={'onChange'}
                              initialValue={companyData.contactName}
                            >
                              <Input
                                placeholder="Enter the contacts' full name"
                                prefix={<UserOutlined/>}
                              />
                            </Form.Item>

                            <Form.Item
                              label={"Contacts' phone number"}
                              name={'phone'}
                              rules={[
                                {required: true, message: 'This field is required.'},
                                {type: 'string', min: 2, message: "Phone number must be at least 6 digits long"},
                                {type: 'string', max: 60, message: "Phone number is too long."},
                              ]}
                              validateTrigger={'onChange'}
                              initialValue={companyData.phone}
                            >
                              <Input
                                placeholder="Enter the contacts' phone number"
                                prefix={<PhoneOutlined/>}
                              />
                            </Form.Item>

                            <Form.Item
                              label={"Contacts' email"}
                              name={'contactEmail'}
                              rules={[
                                {required: true, message: 'This field is required.'},
                                {type: 'email', message: 'Please enter a valid email.'},
                              ]}
                              validateTrigger={'onChange'}
                              initialValue={companyData.contactEmail}
                            >
                              <Input
                                placeholder="Enter the contacts' email"
                                prefix={<MailOutlined/>}
                              />
                            </Form.Item>

                          </div>
                        </Col>
                        <Col xs={24} sm={12} style={{maxWidth: 525}}>
                          <div className={"redBox"}>
                            <h1 style={{marginBottom: 0}}>Price info</h1>
                            <Form.Item
                              label={"VAT Value"}
                              name={'vatValue'}
                              rules={[
                                {required: true, message: 'This field is required.'},
                              ]}
                              validateTrigger={'onChange'}
                              initialValue={companyData.vatValue * 100}
                            >
                              <InputNumber
                                formatter={(value, info) => info.userTyping ? `${value}` : `${Number(value).toFixed(2)}%`}
                                parser={value => value ? value.replace('%', '') : 0}
                                min={0}
                                max={100}
                                placeholder="Enter the VAT value"
                                style={{width: "100%"}}
                              />
                            </Form.Item>

                            {/*<Form.Item*/}
                            {/*  label={"Currency"}*/}
                            {/*  name={'currency'}*/}
                            {/*  rules={[*/}
                            {/*    {required: true, message: 'This field is required.'},*/}
                            {/*  ]}*/}
                            {/*  validateTrigger={'onChange'}*/}
                            {/*>*/}
                            {/*  <Select*/}
                            {/*    showSearch*/}
                            {/*    options={getCurrencies()}*/}
                            {/*    optionFilterProp={"children"}*/}
                            {/*    filterOption={(input, option) =>*/}
                            {/*      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())*/}
                            {/*    }*/}
                            {/*  />*/}
                            {/*</Form.Item>*/}

                            {/*<Form.Item*/}
                            {/*  label={"Units System"}*/}
                            {/*  name={'unitSystem'}*/}
                            {/*  rules={[*/}
                            {/*    {required: true, message: 'This field is required.'},*/}
                            {/*  ]}*/}
                            {/*  validateTrigger={'onChange'}*/}
                            {/*  className={"unitsRadioFieldInput"}*/}
                            {/*  initialValue={"met"}*/}
                            {/*>*/}
                            {/*  <Radio.Group>*/}
                            {/*    <Radio value={"met"}>Metric System</Radio>*/}
                            {/*    <Radio value={"imp"}>Imperial System</Radio>*/}
                            {/*  </Radio.Group>*/}
                            {/*</Form.Item>*/}
                            <Divider style={{width: "100%", minWidth: "0%", margin: "0 0 8px 0"}}/>
                            <Form.Item>
                              <Button
                                style={{marginTop: 17}}
                                type={"primary"}
                                htmlType={"submit"}
                                disabled={!hasCompanyChanges}
                                loading={isUpdatingCompany}
                              >
                                <span className={"inputInfoText"}>Save changes</span>
                              </Button>
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  }
                </Col>
              </Row>
            </Form>
          </div>
        </Card>
        </Spin>
        <Modal
          visible={isModalVisible}
          footer={null}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          destroyOnClose
          centered
          title={"Account Settings"}
          width={600}
          maskClosable={false}
        >
          <Form
            className={"accountSettingsForm"}
            onFinish={handlePasswordChange}
          >
            <Row>
              <Col span={24}>
                <div className={"infoBox"}>
                  <h2 style={{ margin: 0 }}>Password Change</h2>
                  <Divider style={{ margin: "12px 0 12px 0"}}/>
                  <Form.Item
                    label={"Old password"}
                    name={'oldPassword'}
                    rules={[
                      {required: true, message: 'This field is required.'},
                      {type: 'string', min: 6, message: "Password must have at least 6 characters."},
                      {type: 'string', max: 60, message: "Password is too long."},
                    ]}
                    validateTrigger={'onChange'}
                  >
                    <Input.Password
                      placeholder="Enter your old password"
                      prefix={<LockOutlined/>}
                    />
                  </Form.Item>

                  <Form.Item
                    label={"New password"}
                    name={'newPassword'}
                    rules={[
                      {required: true, message: 'This field is required.'},
                      {type: 'string', min: 6, message: "New password must have at least 6 characters."},
                      {type: 'string', max: 60, message: "New password is too long."},
                    ]}
                    validateTrigger={'onChange'}
                  >
                    <Input.Password
                      placeholder="Enter your new password"
                      prefix={<LockOutlined/>}
                    />
                  </Form.Item>

                  <Form.Item
                    label={"Repeat password"}
                    name={'repeatPassword'}
                    rules={[
                      {required: true, message: 'This field is required.'},
                      {type: 'string', min: 6, message: "New password must have at least 6 characters."},
                      {type: 'string', max: 60, message: "New password is too long."},
                      ({getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Passwords must match!');
                        },
                      }),
                    ]}
                    validateTrigger={'onChange'}
                  >
                    <Input.Password
                      placeholder="Enter your new password again"
                      prefix={<LockOutlined/>}
                    />
                  </Form.Item>

                  <div className={"accountFormButton"}>
                    <Form.Item>
                      <Button
                        style={{marginTop: 12}}
                        type={"primary"}
                        htmlType={"submit"}
                        loading={isChangingPassword}
                      >
                        <span style={{ fontSize: 13, fontWeight: 600 }}>Change password</span>
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Content>
    </Layout>
  )
}
