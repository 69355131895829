import React, {createRef, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Col, Divider, Form, Input, InputNumber, List, Row, Select, Spin, Tooltip, Upload} from "antd";
import {
  CaretDownOutlined,
  CheckOutlined,
  ExclamationCircleFilled,
  FilePdfOutlined, InfoCircleOutlined, LoadingOutlined,
  MinusOutlined
} from "@ant-design/icons";

import CoatingPicker from "../CoatingPicker";

import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import treeD from "../../../assets/icons/3D.svg";
import twoD from "../../../assets/icons/2D.svg";
import cube from "../../../assets/icons/cube.gif";
import squareGif from "../../../assets/icons/2d2.gif";
import {S3_BUCKET_URL} from "../../../constants";
import {DrawingIcon2} from "../../../assets/icons/drawing2";
import {useDispatch} from "react-redux";
import {removePartDrawing, uploadPartDrawing} from "../../../actions/orderActions";
import {DropIcon} from "../../../assets/icons/drop";
import {removeBulkPartDrawing, uploadBulkPartDrawing} from "../../../actions/bulkOrderActions";
import {translator} from "../../../translations/translators";
import TooltipMui from '@mui/material/Tooltip';
import {withStyles} from "@material-ui/core/styles";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import {calcAdjustment, getTotalSuggestedPrice} from "../../../utils/utility";

const { Option } = Select;
const { OptGroup } = Select;

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "#07223d"
  },
  arrow: {
    color: "#07223d"
  }
})(TooltipMui);

export const OrderItem = (props) => {
  const form = createRef()

  const [loaded, setLoaded] = useState(false)
  const [uploadingDrawing, setUploadingDrawing] = useState(false)

  const dispatch = useDispatch()

  const {
    orderId, item, materials,  changePartMaterial,
    changePartQuantity, changePartMaterialSurchargePerPiece,
    changePartThickness, changePartNote, changePartColor,
    handleColorRemove, showItem,
    parameterMissMatch, isOrderDetails,
    bulkItem, changePrice, adjustment
  } = props

  const addPartDrawing = (file) => {
    setUploadingDrawing(true)
    if(bulkItem) {
      dispatch(uploadBulkPartDrawing({orderId: orderId, orderPartId: item.id, file: file})).then(()=>{
        setUploadingDrawing(false)
      })
    } else {
      dispatch(uploadPartDrawing({orderId: orderId, orderPartId: item.id, file: file})).then(()=>{
        setUploadingDrawing(false)
      })
    }
  }

  const handleRemovePartDrawing = () => {
    if(bulkItem) {
      dispatch(removeBulkPartDrawing({orderId: orderId, orderPartId: item.id}))
    } else {
      dispatch(removePartDrawing({orderId: orderId, orderPartId: item.id}))
    }
  }

  useEffect(()=>{
    form.current.setFieldsValue({
      quantity: item.quantity
    })
  }, [item.quantity])

  useEffect(()=>{
    form.current.setFieldsValue({
      materialSurchargePerPiece: item.operationPrices.materialSurchargePerPiece
    })
  }, [item.operationPrices.materialSurchargePerPiece])

  useEffect(()=>{
    form.current.setFieldsValue({
      material: item.selectedMaterial.grade
    })
  }, [item.selectedMaterial])

  useEffect(()=>{
    if (bulkItem) {
      form.current.setFieldsValue({
        price: (item.pricePerPiece || 0).toFixed(2)
      })
    }
  }, [item.pricePerPiece])

  const strForSearch = (str) => {
    return str
      ? str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
      : str;
  };

  return (
    <Form
      ref={form}
      name={"form"+item.id}
      style={{height: "100%"}}
    >
      <Row style={{height: "100%"}}>
        <Col flex={"250px"} style={{borderRight: "1px solid #d8d8d8"}}>
          {(item.fileType === "step" && item.stepData?.filePaths?.pngFilePath) &&
            (<Row style={{height: 240}}>
              <Col>
                <div className={item.stepData?.filePaths?.foldedStlFilePath && "container"}>
                  <img
                    className={"productImage"}
                    style={{width: loaded ? 240 : 0, height: 240, padding: 10, position: "relative", background: "white", zIndex: 3}}
                    src={S3_BUCKET_URL + item.stepData.filePaths.pngFilePath}
                    alt={""}
                    onLoad={()=>{
                      setLoaded(true)
                    }}
                  />
                  {item.stepData?.filePaths?.foldedStlFilePath && (
                    <div>
                      {item.coating?.hasCoating &&
                        <div className={"dropOnOverlay"}>
                          <DropIcon style={{color: item.coating.ralHex, width: 16, height: 16}}/>
                        </div>
                      }
                      <div className={"overlay"} onClick={() => showItem(item)}>
                        <div className={"overlay-text"}>
                          <div className={"overlay-text-3D"}>3D</div>
                          <div className={"overlay-text-view"}>View</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col>
              </Col>
            </Row>)
          }
          {(item.fileType === "dxf" && item.dxfData.pngFilePath) &&
            (<Row style={{height: 240}}>
              <Col>
                <div
                  className={item.s3FilePath && "container"}
                  style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center", width: 240}}
                >
                  <div style={{width: loaded ? 240 : 0, height: 240, display: "flex", background: "white", alignItems: "center", justifyContent: "center"}}>
                    <img
                      className={"productImage"}
                      style={{
                        maxWidth: "99%",
                        maxHeight: "99%",
                        padding: 10,
                        position: "relative",
                        zIndex: 3
                      }}
                      src={S3_BUCKET_URL + item.dxfData.pngFilePath}
                      alt={""}
                      onLoad={() => {
                        setLoaded(true)
                      }}
                    />
                  </div>
                  {item.s3FilePath && (
                    <div>
                      {item.coating?.hasCoating &&
                        <div className={"dropOnOverlay"}>
                          <DropIcon style={{color: item.coating.ralHex, width: 16, height: 16}}/>
                        </div>
                      }
                      <div className={"overlay"} onClick={() => showItem(item)}>
                        <div className={"overlay-text"}>
                          <div className={"overlay-text-3D"}>2D</div>
                          <div className={"overlay-text-view"}>View</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col>
              </Col>
            </Row>)
          }
          {!loaded &&
            (<div
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80%",
                width: "100%",
                top: 0
              }}
            >
              <div style={{marginLeft: 6, marginTop: -20}}>
                {item.fileType === "step" ?
                  (<div>
                    <img
                      className={"productImage"}
                      style={{width: 200, height: 200, padding: 8}}
                      src={treeD}
                      alt={"part3D"}
                    />
                    <img
                      className={"productImage"}
                      style={{
                        width: 48,
                        height: 48,
                        zIndex: 2,
                        position: "absolute",
                        marginLeft: -96,
                        marginTop: 37
                      }}
                      src={cube}
                      alt={"part3D"}
                    />
                  </div>)
                  :
                  (
                    <div>
                      <img
                        className={"productImage"}
                        style={{width: 200, height: 200, padding: 8}}
                        src={twoD}
                        alt={"part3D"}
                      />
                      <img
                        className={"productImage"}
                        style={{
                          width: 48,
                          height: 48,
                          zIndex: 2,
                          position: "absolute",
                          marginLeft: -96,
                          marginTop: 35
                        }}
                        src={squareGif}
                        alt={"part3D"}
                      />
                    </div>
                  )
                }
              </div>
            </div>)
          }
        </Col>
        <Col flex="1 1 33%" style={{borderRight: window.innerWidth > 948  ? "1px solid #d8d8d8" : "none"}}>
          <Row gutter={10} style={{marginLeft: 10, marginRight: 10}}>
            <Col span={24}>
              <span className={"text12-500-12"}>
                Material
                {item.selectedMaterial.isAvailable === false && item.fileType === "step" &&
                  <Tooltip
                    color={"#ffc107"}
                    overlayClassName={"small-tooltip"}
                    size={"small"}
                    title={<span className={"text12-500"} style={{color: "#333f48"}}>Material not available.</span>}
                  >
                    <ExclamationCircleFilled style={{color: "#ffc107", fontSize: 13, marginLeft: 6, position: "relative", top: -1}} />
                  </Tooltip>
                }
              </span>
              <Form.Item
                name={"material"}
                style={{marginBottom: 8}}
                initialValue={item.selectedMaterial.grade}
              >
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  onChange={(e)=> changePartMaterial(item.id, e, item.fileType, item.processingType, item.selectedMaterial.thickness, item.materials)}
                  getPopupContainer={trigger => trigger.parentNode}
                  showSearch
                  filterOption={(input, option) => {
                    if (option.value) {
                      return strForSearch(option.children.props.children).includes(strForSearch(input));
                    } else {
                      return false;
                    }
                  }}
                  className={item.selectedMaterial.isAvailable === false ? "notAvailable" : ""}
                  virtual={false}
                >
                  {materials.map(material => {
                    return (
                      item.materials[material.groupName] &&
                      <OptGroup
                        key={material.groupName}
                        label={material.groupName}>
                        {material.grades.map(t => {
                          return (
                            item.materials[material.groupName][t.grade] &&
                            <Option
                              key={t.grade}
                              value={t.grade}
                            >
                              <span className={"text12-600"}>
                                {translator(t.grade)}
                              </span>
                            </Option>
                          )
                        })}
                      </OptGroup>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              {item.fileType === 'step' ?
                <React.Fragment>
                  <Tooltip
                    getPopupContainer={trigger => trigger.parentNode}
                    overlayClassName={"thickness-tooltip"}
                    color={"rgba(82,82,82,0.9)"}
                    title={
                      <span
                        className={"text12-500"}
                        style={{color: "white"}}
                      >
                        The thickness is automatically detected from the file
                      </span>
                    }
                  >
                    <span className={"text12-500-12"}>Thickness</span>
                    <Form.Item
                      style={{marginBottom: 8}}
                    >
                      <Select
                        disabled={true}
                        suffixIcon={<CaretDownOutlined />}
                        virtual={false}
                        value={item.selectedMaterial.materialId}
                      >
                        <Option
                          key={item.selectedMaterial.materialId}
                          value={item.selectedMaterial.materialId}
                        >
                          <span className={"text12-600"} style={{color: "#bdbdbd"}}>
                            {item.selectedMaterial.thickness} mm
                          </span>
                        </Option>
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </React.Fragment>
                :
                <React.Fragment>
                  <span className={"text12-500-12"}>
                    Thickness
                    {item.selectedMaterial.isAvailable === false &&
                      <Tooltip
                        color={"#ffc107"}
                        overlayClassName={"small-tooltip"}
                        size={"small"}
                        title={<span className={"text12-500"} style={{color: "#333f48"}}>Material/Thickness not available.</span>}
                      >
                        <ExclamationCircleFilled style={{color: "#ffc107", fontSize: 13, marginLeft: 6}} />
                      </Tooltip>
                    }
                  </span>
                  <Form.Item
                    style={{marginBottom: 8}}
                  >
                    <Select
                      suffixIcon={<CaretDownOutlined />}
                      value={item.selectedMaterial.isAvailable === false ? `${item.selectedMaterial.thickness} mm` : item.selectedMaterial.materialId }
                      onChange={(value)=> changePartThickness({
                        id: item.id,
                        thickness: item.availableThicknesses.find(thick => thick.id === value)
                      })}
                      className={item.selectedMaterial.isAvailable === false ? "notAvailable" : ""}
                      getPopupContainer={trigger => trigger.parentNode}
                      virtual={false}
                    >
                      {item.availableThicknesses.map(thick => {
                        return (
                          <Option
                            key={thick.id}
                            value={thick.id}
                          >
                            <span className={"text12-600"}>
                              {thick.thickness} mm
                            </span>
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </React.Fragment>
              }
            </Col>
            <Col span={12}>
              <span className={"text12-500-12"}>Quantity</span>
              <Form.Item
                name={"quantity"}
                style={{marginBottom: 8}}
                initialValue={item.quantity}
                rules={[
                  {required: true, message: ""}
                ]}
                className={"quantity-field"}
              >
                <InputNumber
                  className={"item-number-input"}
                  placeholder={"Enter quantity"}
                  type={"number"}
                  style={{width: '100%'}}
                  min={1}
                  max={10000}
                  formatter={value => value ? value : 1}
                  parser={value => value ? value : 1}
                  step={1}
                  onChange={(q) => changePartQuantity({orderId: orderId, id: item.id, quantity: q})}
                />
              </Form.Item>
            </Col>
            <Col span={24} >
              <span className={"text12-500-12"}>Note (Optional)</span>
              <Form.Item
                name={"note"}
                initialValue={item.note}
              >
                <Input
                  size={'large'}
                  className={"text12-500"}
                  placeholder={"Notes and additional requirements"}
                  onChange={(e)=> changePartNote({orderId: orderId, id: item.id, note: e.target.value})}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              {!isOrderDetails &&
                <div style={{display: "flex", height: '100%'}}>
                  {item.drawingPDFFile?.drawingPDFFileName ?
                    <div style={{border: "1px dashed #1890ff", padding: "8px 10px 8px 10px", borderRadius: 5, marginBottom: -10, height: 40, width: "100%"}}>
                      <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                        <div style={{display: "flex", width: "calc(100% - 20px)"}}>
                          <div>
                            <FilePdfOutlined style={{color: "#1890ff", marginRight: 4}}/>
                          </div>
                          <div
                            className={"addDrawingText ellipsisText"}
                            style={{color: "#1890ff", marginTop: 4, cursor: "default"}}
                          >
                            {item.drawingPDFFile.drawingPDFFileName}
                          </div>
                        </div>
                        <div style={{marginTop: 0, justifyContent: "flex-end", alignItems: "center"}}>
                          <IconButton
                            size={'small'}
                            aria-label="clear"
                            style={{width: 18, height: 18, marginRight: -3, marginTop: -1}}
                            onClick={()=> handleRemovePartDrawing() }
                          >
                            <CloseIcon style={{ fontSize: 14, color: '#fc505a'}}/>
                          </IconButton>
                        </div>
                      </div>
                    </div>
                    :
                    <Spin
                      spinning={uploadingDrawing}
                      wrapperClassName={"documentUploadSpin"}
                      indicator={<LoadingOutlined style={{ fontSize: 30, marginLeft: -71, marginTop: -17 }} spin />}
                    >
                      <Upload
                        showUploadList={false}
                        accept=".pdf"
                        beforeUpload={(file) => {
                          addPartDrawing(file)
                          return false
                        }}
                      >
                        <Button
                          style={{border: "1px dashed", borderRadius: 5, marginBottom: -10, height: '100%', paddingInline: 8}}
                          icon={<DrawingIcon2 className={"drawingIcon"}/>}
                        >
                                  <span
                                    className={"addDrawingText"}
                                    style={{position: 'relative', top: -3}}
                                  >
                                    Add part drawing
                                  </span>
                        </Button>
                      </Upload>
                    </Spin>
                  }
                </div>
              }
            </Col>
            {parameterMissMatch &&
            <Col span={24}>
              <div style={{
                display: "flex",
                backgroundColor: "#ececec",
                padding: 5,
                borderRadius: 5,
                marginTop: -10,
                marginBottom: 10,
                border: "1px solid #aaaaaa"
              }}>
                <div>
                  <ExclamationCircleFilled style={{color: "#ffc107", fontSize: 15, position: "relative", top: 4}}/>
                </div>
                <div className={"text12-600"} style={{marginLeft: 10, lineHeight: 1.4}}>
                  Warning: Some parameters that the customer selected are not applicable to this part!
                </div>
              </div>
            </Col>
            }
          </Row>
        </Col>
        <Col flex="1 1 38%">
          <List
            style={item.coating?.hasCoating ? {marginLeft: 10, minWidth: 300} : {marginLeft: 10, minWidth: 300}}
          >
            <List.Item style={{paddingTop: 4, paddingBottom: 4}}>
              <div
                style={{display: 'flex', justifyContent: "space-between", width: '100%'}}
              >
                <div>
                  <span>
                      <CheckOutlined style={{color: '#0b89ff'}}/>
                  </span>
                  <span
                    className={"text14-400"}
                    style={{fontWeight: 500, marginLeft: 8}}
                  >
                    Cutting
                  </span>
                </div>
              </div>
            </List.Item>
            <List.Item style={{paddingTop: 4, paddingBottom: 4}}>
              <div
                style={{display: 'flex', justifyContent: "space-between", width: '100%'}}
              >
                <div>
                  <span>
                    {item?.stepData?.partData?.hasBends ?
                      <CheckOutlined style={{color: '#0b89ff'}}/>
                      :
                      <MinusOutlined style={{color: '#bdbdbd'}}/>
                    }
                  </span>
                  <span
                    className={"text14-400"}
                    style={item?.stepData?.partData?.hasBends ?
                      {fontWeight: 500, marginLeft: 8}
                      :
                      {color: '#bdbdbd', marginLeft: 8}
                    }
                  >
                    Bending
                  </span>
                </div>
                <div style={{marginRight: 15}}>
                  {item?.stepData?.partData?.hasBends &&
                    <span className={"text12-500"} >Count: {item?.stepData?.partData?.numberOfBends}</span>
                  }
                </div>
              </div>
            </List.Item>
            <List.Item style={{paddingTop: 4, paddingBottom: 4}}>
              <div
                style={{display: 'flex', justifyContent: "space-between", width: '100%'}}
              >
                <div>
                  <span>
                    {item?.stepData?.partData?.numOfMachinedHoles ?
                      <CheckOutlined style={{color: '#0b89ff'}}/>
                      :
                      <MinusOutlined style={{color: '#bdbdbd'}}/>
                    }
                  </span>
                  <span
                    className={"text14-400"}
                    style={
                      item?.stepData?.partData?.numOfMachinedHoles ?
                        {fontWeight: 500, marginLeft: 8}
                        :
                        {color: '#bdbdbd', marginLeft: 8}
                    }
                  >
                    Countersinking
                  </span>
                </div>
                <div style={{marginRight: 15}}>
                  {item?.stepData?.partData?.numOfMachinedHoles ?
                    <span className={"text12-500"} >Count: {item.stepData.partData.numOfMachinedHoles}</span> : ""
                  }
                </div>
              </div>
            </List.Item>
            <List.Item style={{paddingTop: 4, paddingBottom: 4}}>
              <div
                style={{display: 'flex', justifyContent: "space-between", width: '100%'}}
              >
                <div>
                  <span>
                    {item?.stepData?.partData?.numOfSlopedEdges ?
                      <CheckOutlined style={{color: '#0b89ff'}}/>
                      :
                      <MinusOutlined style={{color: '#bdbdbd'}}/>
                    }
                  </span>
                  <span
                    className={"text14-400"}
                    style={
                      item?.stepData?.partData?.numOfSlopedEdges ?
                        {fontWeight: 500, marginLeft: 8}
                        :
                        {color: '#bdbdbd', marginLeft: 8}
                    }
                  >
                    Sloped Edges
                  </span>
                </div>
                <div style={{marginRight: 15}}>
                  {item?.stepData?.partData?.numOfSlopedEdges ?
                    <span className={"text12-500"} >Count: {item.stepData.partData.numOfSlopedEdges}</span> : ""
                  }
                </div>
              </div>
            </List.Item>
            <List.Item style={{paddingTop: 4, paddingBottom: 4}}>
              <div
                style={{display: 'flex', justifyContent: "space-between", width: '100%'}}
              >
                <div style={{marginTop: 7}}>
                  <span>
                    {item.coating?.hasCoating ?
                      <CheckOutlined style={{color: '#0b89ff'}}/>
                      :
                      <MinusOutlined style={{color: '#bdbdbd'}}/>
                    }
                  </span>
                  <span
                    className={"text14-400"}
                    style={
                      item.coating?.hasCoating ?
                        {fontWeight: 500, marginLeft: 8}
                        :
                        {color: '#bdbdbd', marginLeft: 8}
                    }
                  >
                    Coating
                  </span>
                </div>
                <div style={{marginRight: 15}}>
                  <div style={{display: 'flex', justifyContent: "space-between"}}>
                    <div style={{display: "flex"}}>
                      {item.surfaceCoatingParams?.hasSurfaceCoating === false &&
                        <Tooltip
                          color={"#ffc107"}
                          overlayClassName={"small-tooltip"}
                          size={"small"}
                          title={<span className={"text12-500"} style={{color: "#333f48"}}>Coating not available.</span>}
                        >
                          <ExclamationCircleFilled style={{color: "#ffc107", fontSize: 13, marginRight: 8, marginTop: 9}} />
                        </Tooltip>
                      }
                      <CoatingPicker
                        partId={item.id}
                        changeColor={changePartColor}
                        hasCoating={item.coating?.hasCoating}
                        colorRAL={item.coating?.colorRAL}
                        colorGlossId={item.coating?.colorGlossId}
                        ralRow={item.coating?.ralRow}
                        ralHex={item.coating?.ralHex}
                        isMultiEdit={isOrderDetails}
                        disabled={item.surfaceCoatingParams?.hasSurfaceCoating === false}
                      />
                    </div>
                    {item.coating?.hasCoating &&
                      <IconButton
                        size={'small'}
                        aria-label="clear"
                        style={{marginLeft: 5, width: 25, height: 25, marginRight: -8, marginTop: 4}}
                        onClick={()=>{
                          handleColorRemove(item.id)
                        }}
                      >
                        <CloseIcon style={{ fontSize: 14, color: '#fc505a'}}/>
                      </IconButton>
                    }
                  </div>
                </div>
              </div>
            </List.Item>
            <List.Item>
              <div style={{width: '100%'}}>
                <table style={{width: "100%"}}>
                  <tbody style={{height: "100%"}}>
                  <tr style={{height: "100%" }}>
                    <td style={{width: "33%", paddingLeft: 10}}>
                      {bulkItem ? (
                        <div align={"right"}>
                          <div style={{minWidth: 180, display: "flex", justifyContent: "flex-end"}}>
                          <div style={{marginRight: 15, display: 'flex', justifyContent: "space-between", textAlign: 'right'}}>
                            <div style={{marginRight: 30}}>
                              <div className={"text12-400"}>Price per piece</div>
                              <div className={"priceText"}
                                   style={{paddingTop: 6.2}}
                              >
                                <Form.Item
                                  className={"priceInputField"}
                                  name={"price"}
                                  initialValue={(item.pricePerPiece || 0).toFixed(2)}
                                >
                                  <InputNumber formatter={(value, info) => info.userTyping ? `${value}` : `${Number(value).toFixed(2)} €`}
                                               parser={value => value ? value.replace('€', '') : 0}
                                               min={0}
                                               onChange={(p) => changePrice({orderId: orderId, id: item.id, quantity: item.quantity, price: p})}
                                               size={"small"}
                                               style={{ width: 115 }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div style={{marginRight: 30}}>
                              <div className={"text12-400"}>Material surcharge</div>
                              <div className={"priceText"}
                                   style={{paddingTop: 6.2}}
                              >
                                <Form.Item
                                  className={"priceInputField"}
                                  name={"materialSurchargePerPiece"}
                                  initialValue={(item.operationPrices.materialSurchargePerPiece || 0).toFixed(2)}
                                >
                                  <InputNumber formatter={(value, info) => info.userTyping ? `${value}` : `${Number(value).toFixed(2)} %`}
                                               parser={value => value ? value.replace('%', '') : 0}
                                               onChange={(matSurcharge) => changePartMaterialSurchargePerPiece({orderId: orderId, id: item.id, materialSurchargePerPiece: matSurcharge})}
                                               size={"small"}
                                               style={{ width: 115 }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div>
                              <div className={"priceText"}>Total</div>
                              <div className={"priceText"} style={{paddingTop: 6, whiteSpace: "nowrap"}}>
                                {(item.pricePerPiece * item.quantity).toFixed(2)} €
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      ) : (
                        <div style={{minWidth: 180, display: "flex", justifyContent: "flex-end"}}>
                          <div style={{marginRight: 15, display: 'flex', justifyContent: "space-between", textAlign: 'right'}}>
                            <div style={{marginRight: 30}}>
                              <div className={"text12-400"}>1 pc</div>
                              <div className={"priceText"} style={{paddingTop: 10}}>
                                {((item.pricePerPiece || 0) * calcAdjustment(adjustment)).toFixed(2)} €
                              </div>
                            </div>
                            <div>
                              <div className={"priceText"}>Total</div>
                              <div
                                className={"priceText"}
                                style={{paddingTop: 6}}
                              >
                                {((item.totalPrice || 0) * calcAdjustment(adjustment)).toFixed(2)} €
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr style={{height: "100%"}}>

                    <td style={{width: "100%",  paddingLeft: 10, paddingTop: 20}}>
                      <div className={"totalPriceInfo"}
                          style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                      >
                        {bulkItem  && <CustomTooltip
                          placement={"top"}
                          arrow
                          title={
                            <div className={"priceInfoTooltip"}>
                              <div className={"tooltipLine"} style={{ justifyContent: "center", fontSize: 16 }}>
                                <b>Price per 1 piece:</b>
                              </div>
                              <div className={"tooltipLine"}>
                                <span>Cutting price:</span>
                                <span>{item?.operationPrices?.cuttingPricePerPiece.toFixed(2)}€</span>
                              </div>
                              <div className={"tooltipLine"}>
                                <span>Cutting preparation price:</span>
                                <span>{(item?.operationPrices?.cuttingPreparationPrice/item.quantity).toFixed(2)}€</span>
                              </div>
                              <div className={"tooltipLine"}>
                                <span>Total cutting price:</span>
                                <span>{((item?.operationPrices?.cuttingPricePerPiece * item.quantity + item?.operationPrices?.cuttingPreparationPrice)/item.quantity).toFixed(2)}€</span>
                              </div>
                              <Divider style={{ margin: 2, borderColor: "whitesmoke" }}/>
                              <div className={"tooltipLine"}>
                                <span>Material price: </span>
                                <span>{(item?.operationPrices?.materialPricePerPiece).toFixed(2)}€</span>
                              </div>
                              {}
                              <div className={"tooltipLine"}>
                                <span>Material price with surcharge: </span>
                                <span>{(item?.operationPrices?.materialPricePerPiece + item?.operationPrices?.materialPricePerPiece * item?.operationPrices?.materialSurchargePerPiece / 100).toFixed(2) || item?.operationPrices?.materialPricePerPiece.toFixed(2)}€</span>
                              </div>
                              <Divider style={{ margin: 2, borderColor: "whitesmoke" }}/>
                              <div className={"tooltipLine"}>
                                <span>Bending price:</span>
                                <span>{item?.operationPrices?.bendingPricePerPiece.toFixed(2)}€</span>
                              </div>
                              <div className={"tooltipLine"}>
                                <span>Bending preparation price:</span>
                                <span>{(item?.operationPrices?.bendingPreparationPrice / item.quantity).toFixed(2)}€</span>
                              </div>
                              <div className={"tooltipLine"}>
                                <span>Total bending price:</span>
                                <span>{((item?.operationPrices?.bendingPricePerPiece * item.quantity + item?.operationPrices?.bendingPreparationPrice)/item.quantity).toFixed(2)}€</span>
                              </div>
                              <Divider style={{ margin: 2, borderColor: "whitesmoke" }}/>
                              <div className={"tooltipLine"}>
                                <span>Machining price: </span>
                                <span>{item?.operationPrices?.machiningPricePerPiece.toFixed(2)}€</span>
                              </div>
                              <div className={"tooltipLine"}>
                                <span>Machining preparation price: </span>
                                <span>{(item?.operationPrices?.machiningPreparationPrice / item.quantity).toFixed(2)}€</span>
                              </div>
                              <div className={"tooltipLine"}>
                                <span>Total Machining price:</span>
                                <span>{((item?.operationPrices?.machiningPricePerPiece * item.quantity + item?.operationPrices?.machiningPreparationPrice)/item.quantity).toFixed(2)}€</span>
                              </div>
                              <Divider style={{ margin: 2, borderColor: "whitesmoke" }}/>
                              <div className={"tooltipLine"}>
                                <span>Coating price:</span>
                                <span>{item?.operationPrices?.coatingPricePerPiece.toFixed(2)}€</span>
                              </div>
                              <Divider style={{ margin: 2, borderColor: "whitesmoke" }}/>
                              <div className={"tooltipLine"} style={{ fontSize: 15 }}>
                                <b>Total suggested price:</b>
                                <b style={{marginLeft: 5}}>{(getTotalSuggestedPrice(item?.operationPrices, item.quantity) || 0).toFixed(2)}€</b>
                              </div>
                            </div>
                          }>

                            <Button
                              style={{border: "1px dashed", borderRadius: 5, marginBottom: -10, height: '100%', paddingInline: 8, marginLeft: 15}}
                              icon={<LocalAtmIcon className="drawingIcon" style={{marginRight: 5}}/>}
                            >
                                    <span
                                      className={"addDrawingText"}
                                      style={{position: 'relative', top: -3}}
                                    >
                                      Price breakdown
                                    </span>
                            </Button>
                        </CustomTooltip>}
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </List.Item>
          </List>
        </Col>
      </Row>
    </Form>
  )
}

OrderItem.propTypes = {
  item: PropTypes.object,
  changePartMaterial: PropTypes.func,
  changePartQuantity: PropTypes.func,
  changePartThickness: PropTypes.func,
  changePartNote: PropTypes.func,
  changePartColor: PropTypes.func,
  handleColorRemove: PropTypes.func,
};