import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from "react";
import Icon from "@ant-design/icons";


const DrawingIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 1024 1024">
    <g transform="translate(-400.0,-940.0) scale(2.8,2.8)" fill="currentColor">
      <polygon className="st5" points="332,455.2 240.2,628.9 212,645.1 212,613.2 304.4,440.5 		"/>
      <path className="st6" d="M353.3,407.6c0,8.6-6.9,15.5-15.5,15.5c-8.6,0-15.5-7-15.5-15.5s7-15.5,15.5-15.5
			C346.4,392.1,353.3,399,353.3,407.6z"/>
      <path className="st5" d="M358.6,363.9c-21.6-10.4-48.4-2.2-61,17.7c-14.4,22.8-6.8,55.3,17.7,68.3c22.7,12,51.7,3,64.2-19.3
			C392.8,406.8,382.8,375.6,358.6,363.9z M337.8,423.1c-8.6,0-15.5-7-15.5-15.5s7-15.5,15.5-15.5c8.6,0,15.5,6.9,15.5,15.5
			S346.4,423.1,337.8,423.1z"/>
      <path className="st6" d="M353.3,407.6c0,8.6-6.9,15.5-15.5,15.5c-8.6,0-15.5-7-15.5-15.5s7-15.5,15.5-15.5
			C346.4,392.1,353.3,399,353.3,407.6z"/>
      <path className="st5" d="M360.4,486.5L436,629l28.2,19.3v-33.9l-75.1-143c-1.5,2.5-4.8,7.3-11,11C370.6,486.8,363.2,486.7,360.4,486.5
			z"/>
      <path className="st7" d="M347.6,363.7c-1.8,1.7-5,1.5-7.6,0.8c-2.6-0.7-5.3-2.1-7.2-3.1c-0.3-0.2-0.5-0.5-0.5-0.9l-0.2-30.1
			c0-0.8,0.8-1.3,1.4-0.9c3,2,7.2,5.6,10.5,11.4C348.4,348.2,351.5,360.1,347.6,363.7z"/>
      <path className="st5" d="M351.5,344.1"/>
      <polygon className="st7" points="501.8,482.9 460,528.3 436,485 477.3,443.7 		"/>
      <polygon className="st7" points="178.8,484.4 218.5,529.8 242,486 202.8,446.9 		"/>
      <polygon className="st7" points="313.4,557.5 290,600.8 338,647.7 385.4,600.3 363,557 337.4,581 		"/>
    </g>
  </svg>
);

export const DrawingIcon2 = props => <Icon component={DrawingIcon} {...props} />;