import Icon from "@ant-design/icons";
import React from "react";

const RightMouseClick = () => (
    <svg width="0.7em" height="1.4em" viewBox="0 0 1024 1024">
        <g transform="translate(0.000000,1318.000000) scale(0.100000,-0.100000)"
           fill="currentColor" stroke="none">
            <path d="M3010 13169 c-476 -21 -867 -125 -1300 -345 -313 -158 -564 -346
            -831 -622 -466 -481 -747 -1053 -856 -1742 -17 -110 -18 -288 -18 -3880 0
            -4159 -6 -3778 60 -4087 111 -525 345 -994 704 -1413 530 -619 1358 -1034
            2135 -1070 267 -13 2846 -12 3021 0 273 20 590 97 905 220 102 40 352 171 473
            247 231 147 389 276 593 484 390 398 669 903 799 1443 63 258 67 297 75 696
            11 590 4 7187 -9 7301 -22 203 -97 505 -178 722 -278 742 -830 1359 -1532
            1712 -300 152 -570 238 -933 301 -129 22 -201 27 -463 34 -373 11 -2412 10
            -2645 -1z m2466 -821 c205 -191 661 -615 729 -678 75 -71 182 -171 280 -261
            120 -110 242 -224 320 -299 44 -42 143 -134 220 -205 77 -71 201 -186 275
            -255 383 -358 474 -443 574 -535 139 -129 152 -151 161 -282 4 -57 3 -103 -2
            -103 -4 0 -33 23 -63 51 -30 28 -78 72 -105 98 -58 53 -301 280 -420 391 -44
            41 -105 98 -135 125 -30 28 -100 93 -155 145 -55 52 -125 118 -155 145 -30 28
            -183 170 -340 316 -157 146 -357 332 -445 414 -88 82 -223 208 -300 280 -164
            153 -434 405 -520 484 -33 31 -109 102 -169 158 l-108 103 129 0 129 0 100
            -92z m-1581 62 c-307 -81 -568 -290 -710 -570 -15 -30 -43 -100 -62 -155 -30
            -88 -35 -118 -43 -255 -5 -85 -9 -704 -10 -1375 l0 -1220 25 -96 c62 -243 146
            -391 318 -556 139 -134 307 -225 498 -272 l109 -26 0 -477 0 -478 -1645 0
            -1645 0 0 1188 c1 1265 9 1729 35 1907 52 364 182 731 370 1047 192 321 485
            636 795 854 357 250 768 413 1210 479 138 21 192 23 490 24 l335 0 -70 -19z
            m2336 -156 c115 -44 350 -156 384 -182 14 -11 87 -78 163 -149 75 -72 288
            -270 472 -442 335 -311 335 -312 398 -419 68 -117 187 -351 181 -357 -3 -2
            -65 53 -139 122 -74 69 -189 176 -255 237 -65 61 -153 143 -195 182 -41 38
            -111 104 -155 145 -44 41 -138 128 -208 193 -71 66 -137 127 -147 136 -108 94
            -608 570 -599 570 4 0 49 -16 100 -36z m-1693 -390 c204 -42 407 -218 497
            -434 58 -138 67 -183 75 -395 9 -219 4 -1790 -6 -1895 -13 -145 -88 -320 -189
            -442 -220 -265 -580 -334 -863 -166 -67 40 -169 133 -217 198 -55 75 -114 201
            -143 307 l-27 98 0 1025 c1 1020 1 1025 23 1112 76 306 294 533 577 601 46 11
            201 6 273 -9z m1397 -729 c88 -82 189 -176 225 -210 36 -33 159 -148 275 -255
            115 -107 250 -233 300 -280 50 -47 122 -114 160 -149 39 -35 99 -91 135 -125
            36 -34 97 -90 135 -125 39 -36 104 -97 145 -136 41 -39 199 -186 350 -326 151
            -141 301 -280 333 -310 l58 -54 -3 -118 -3 -117 -30 27 c-37 34 -318 295 -325
            303 -3 3 -66 61 -140 130 -229 212 -441 410 -525 490 -44 41 -143 134 -220
            205 -77 71 -167 154 -200 185 -33 31 -96 90 -140 131 -44 41 -114 106 -155
            145 -61 57 -311 290 -557 518 l-43 39 0 121 0 120 33 -30 c17 -17 104 -97 192
            -179z m355 -1115 c168 -156 330 -307 360 -335 147 -138 220 -206 275 -256 33
            -30 127 -118 210 -194 82 -77 177 -165 210 -196 33 -30 132 -123 220 -205 366
            -341 431 -402 458 -424 26 -23 27 -27 26 -119 -1 -145 -2 -146 -85 -70 -140
            130 -361 335 -429 400 -38 36 -171 160 -295 274 -124 115 -252 234 -285 265
            -33 31 -163 153 -290 271 -126 117 -235 218 -241 224 -6 5 -67 62 -135 125
            -68 63 -158 147 -199 185 -109 103 -194 181 -292 271 l-86 79 -1 124 -1 123
            138 -129 c75 -71 274 -257 442 -413z m-433 -385 c106 -100 283 -266 303 -285
            3 -3 30 -27 60 -54 30 -27 87 -79 125 -115 39 -36 133 -125 210 -196 77 -71
            167 -155 200 -186 33 -30 98 -91 145 -134 47 -44 110 -102 140 -131 30 -28
            122 -114 205 -190 82 -76 192 -180 245 -229 175 -166 502 -470 531 -494 28
            -23 29 -27 28 -110 -1 -47 -4 -99 -8 -116 l-6 -30 -80 75 c-44 41 -134 125
            -200 185 -66 61 -156 144 -200 186 -44 42 -141 132 -215 201 -120 110 -448
            416 -1125 1047 -296 276 -320 298 -416 387 l-87 79 -1 118 c-1 67 3 117 8 115
            5 -1 67 -57 138 -123z m-27 -761 c36 -34 96 -90 135 -125 69 -64 185 -171 300
            -279 33 -31 128 -119 210 -195 237 -219 572 -531 665 -619 47 -45 144 -135
            214 -201 71 -66 143 -133 161 -150 17 -16 113 -106 213 -199 100 -93 182 -171
            182 -173 0 -2 -57 -3 -127 -3 l-128 0 -75 73 c-82 79 -564 529 -779 727 -130
            119 -503 466 -661 614 -42 39 -158 147 -258 240 -203 189 -187 160 -175 314
            l6 83 26 -23 c14 -13 55 -50 91 -84z m-216 -581 c153 -144 214 -201 281 -263
            222 -204 606 -561 725 -674 95 -90 353 -332 415 -389 l40 -36 -130 -1 -130 0
            -55 53 c-31 28 -217 202 -415 386 -198 184 -396 368 -440 410 -44 42 -169 159
            -278 260 -117 110 -196 190 -193 198 10 25 88 123 99 123 6 0 42 -30 81 -67z
            m-341 -470 c95 -87 228 -210 297 -273 69 -64 139 -129 155 -144 17 -15 135
            -125 264 -244 l233 -217 -58 -6 c-33 -4 -88 -7 -123 -8 -73 -1 -39 -26 -306
            224 -77 72 -210 196 -295 275 -232 215 -256 238 -365 341 -55 52 -113 106
            -129 120 l-28 27 78 30 c44 17 85 31 92 31 8 1 91 -70 185 -156z m-235 -561
            c147 -137 284 -264 304 -281 21 -18 35 -37 32 -42 -3 -5 -57 -9 -121 -9 -134
            0 -99 -21 -322 189 -73 69 -142 132 -152 139 -17 12 -19 27 -19 133 0 65 2
            119 5 119 3 0 126 -112 273 -248z m3007 -2244 c-7 -1542 -10 -1709 -35 -1875
            -49 -330 -125 -565 -281 -873 -391 -776 -1132 -1325 -1999 -1484 -238 -43
            -441 -49 -1499 -43 -1043 6 -1015 5 -1286 63 -722 157 -1335 581 -1752 1211
            -205 310 -345 675 -418 1083 -24 131 -27 183 -35 530 -5 212 -9 874 -9 1473
            l-1 1087 3660 0 3660 0 -5 -1172z"
            />
        </g>
    </svg>
);

export const RightMouse = props => <Icon component={RightMouseClick} {...props} />;