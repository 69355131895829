const HOME_PAGE_URL = process.env.REACT_APP_HOMEPAGE_URL

let API_BASE_URL_PRE = "";
let SOCKET_URL_PRE = "";
if (process.env.REACT_APP_RUN_ENV === "remote") {
  API_BASE_URL_PRE = `https://${window.location.hostname}${process.env.REACT_APP_REST_API_BASE_PATH}`;
  SOCKET_URL_PRE = `https://${window.location.hostname}`;

} else if (process.env.REACT_APP_RUN_ENV === "local") {
  API_BASE_URL_PRE = `${process.env.REACT_APP_DEVELOP_API_BASE_URL}${process.env.REACT_APP_REST_API_BASE_PATH}`;
  SOCKET_URL_PRE = `${process.env.REACT_APP_DEVELOP_API_BASE_URL}`;
}

const API_BASE_URL = API_BASE_URL_PRE;
const SOCKET_URL = SOCKET_URL_PRE;

const SOCKET_PATH = process.env.REACT_APP_SOCKET_API_BASE_PATH;
const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
const lineBreakLimit = 1426;

export { HOME_PAGE_URL, API_BASE_URL, lineBreakLimit, SOCKET_URL, SOCKET_PATH, S3_BUCKET_URL, RECAPTCHA_SITE_KEY }