import React from 'react'
import {Row, Col, Layout, Anchor} from 'antd'


import {logoutUser} from '../../../actions/authActions'

import {Sidebar} from '../../../components'
import {Picture} from "../../LoginPage/styles";
import prva from "../../../assets/images/prva.png";
import druga from "../../../assets/images/Threaded Holes.jpg";
import treca from "../../../assets/images/distance.png";
import cetvrta from "../../../assets/images/one_file.png";
import peta from "../../../assets/images/O_1.png";
import sesta from "../../../assets/images/O_2.png";
import sedma from "../../../assets/images/breaks.png";
import osma from "../../../assets/images/lines.png";
import {useSelector} from "react-redux";
import {CompanySidebar} from "../../../components/company";


const DxfTipsPage = (props) => {
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user);

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      {(user.role === "producerAdmin" || user.role === "producerSubAdmin") ?
        <CompanySidebar
          onLogout={()=>{logoutUser()}}
          user={user}
        />
        :
        <Sidebar
          user={user}
          onLogout={() => logoutUser({})}
        />
      }
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <Layout.Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: isCollapsed ? 60 : 200,
            color: '#707070',
            backgroundColor: '#fff',
            zIndex: 10,
            width: 320
          }}
          width={300}
        >
          <div style={{marginLeft: 15, marginTop: 20}}>
            <div style={{fontSize: 25, marginBottom: 10}}>Table of Contents</div>
            <Anchor
              showInkInFixed={true}
              style={{marginLeft: 8}}
            >
              <Anchor.Link href="#1" title="Help for Engineering Drawings"/>
              <Anchor.Link href="#2" title="Using the Correct File Format"/>
              <Anchor.Link href="#3" title="Create a Drawing from Scratch"/>
              <Anchor.Link href="#4" title="Chamfered Holes"/>
              <Anchor.Link href="#5" title="Threaded Holes"/>
              <Anchor.Link href="#6" title="Leave Room Between Cuts"/>
              <Anchor.Link href="#7" title="Small Holes"/>
              <Anchor.Link href="#8" title="One Part per Drawing"/>
              <Anchor.Link href="#9" title="Hatched Holes and Centre Lines"/>
              <Anchor.Link href="#10" title="Connect the Letter Insides"/>
              <Anchor.Link href="#11" title="Laser Cutting Leaves an Inside Radius"/>
              <Anchor.Link href="#12" title="Check the Scale"/>
              <Anchor.Link href="#13" title="Overwriting Dimensions"/>
              <Anchor.Link href="#14" title="Breaking the Views"/>
              <Anchor.Link href="#15" title="Glitches, Errors and the Like"/>
              <Anchor.Link href="#16" title="Nice Side Up"/>
              <Anchor.Link href="#17" title="Infinite Coastline"/>
              <Anchor.Link href="#18" title="No Title Block"/>
              <Anchor.Link href="#19" title="Overlapping Lines"/>
              <Anchor.Link href="#20" title="Helpers"/>
              <Anchor.Link href="#21" title="Everything Must Be Connected"/>
              <Anchor.Link href="#22" title="Cut to Normal"/>
            </Anchor>
          </div>
        </Layout.Sider>
        <Layout style={{marginLeft: 300}}>
          <Row>
            <Col xs={{offset: 1, span: 22}} sm={{offset: 1, span: 22}} md={{offset: 1, span: 22}}
                 lg={{offset: 1, span: 22}} xl={{offset: 1, span: 18}} xxl={{offset: 1, span: 12}}>
              <div style={{fontSize: 33, color: 'black', fontWeight: 'bold'}} id={"1"} className={"anchor-title"}>
                Help for Engineering Drawings
                <a href="#1" className="anchor">#</a>
              </div>

              <div style={{fontSize: 18, marginTop: 15}}>
                <Picture src={prva} width={650}/>
                <br/>
                Mechanical engineers learn a list of rules to follow when making engineering drawings.
                Those rules don't always apply, though.
                When making drawings for automated cutting jobs, they have to be very simple.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"2"}
                   className={"anchor-title"}>
                Using the Correct File Format
                <a href="#2" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                Every CNC setup is different, but there are general file formats that most machines support.
                That being said, use the <b>DXF</b> file format when uploading. This is the preferred file format
                as DXF files can be fed straight to CNC machines.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"3"}
                   className={"anchor-title"}>
                Create a Drawing from Scratch
                <a href="#3" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                Don't use the 'save as' command to create a new drawing.
                Start a new one instead. Otherwise, you can end up with a faulty drawing.
                We see ones that have leftover lines or views from old drawings laying around.
                This can lead to 36×20 metre sheet sizes and the like.
                <br/>
                <br/>
                Also, blocks and layers that are not related to the drawing make simple flat pattern files unnecessarily
                big (e.g 1.5 Mb).
                This slows down the uploading process.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"4"}
                   className={"anchor-title"}>
                Chamfered Holes
                <a href="#4" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                Plasma and laser cutting are for perpendicular cuts only. Add the required chamfers later.
                The manufacturing drawing should include the cutting line for the hole, nothing more.
                Show the chamfers on a separate PDF and add it to your quotation request.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"5"}
                   className={"anchor-title"}>
                Threaded Holes
                <a href="#5" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                <Picture src={druga} width={650}/>
                <br/>
                Blind hole sizes for thread size
                <br/>
                <br/>
                Threading is an extra operation.
                You can still cut a blind hole into the sheet with a laser to make the threading effortless and
                accurate.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"6"}
                   className={"anchor-title"}>
                Leave Room Between Cuts
                <a href="#6" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                <Picture src={treca} width={650}/>
                <br/>
                Leave some room between two cuts.
                If they are too close, the metal melts and may spray around, leaving an ugly area between the holes.
                Even worse, the bridge may melt altogether, if it is very narrow.
                The rule is to leave a bridge as wide as the thickness of the material.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"7"}
                   className={"anchor-title"}>
                Small Holes
                <a href="#7" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                Holes with a diameter smaller than the thickness of the plate must be drilled after the cutting process.
                Our advice is to adjust the size if possible to keep the costs low.
                Otherwise, the price of drilling gets added later.
                If you really need holes that small, you can get the centre marks engraved to ensure accurate
                positioning.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"8"}
                   className={"anchor-title"}>
                One Part per Drawing
                <a href="#8" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                <Picture src={cetvrta} width={650}/>
                <br/>
                Delete anything that isn't necessary.
                If you submit a collage of 20 details, someone will have to copy them onto separate drawings manually.
                Do it before uploading the drawings to get the fastest possible laser cutting service.
                No side-views or anything, just a flat pattern.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"9"}
                   className={"anchor-title"}>
                Hatched Holes and Centre Lines
                <a href="#9" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                You may ask yourself – who hatches holes? Someone.
                This is neither a standard, nor useful in any way.
                We were all taught to mark holes with centre lines in our classes but those are also just a distraction.
                Keep it blank.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"10"}
                   className={"anchor-title"}>
                Connect the Letter Insides
                <a href="#10" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                <Picture src={peta} width={650}/>
                <br/>
                Letter “O” with connecting bridges
                <br/>
                <br/>
                This is very often overlooked.
                When you want to add some words on your sheet of metal, consider the fact that the insides cannot
                levitate in the air.
                In our example, the inside ring of “O” is connected by 2 bridges.
                Otherwise, the inside ring would fall away and there would be an extra cut unnecessary to the final
                outcome.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"11"}
                   className={"anchor-title"}>
                Laser Cutting Leaves an Inside Radius
                <a href="#11" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                <Picture src={sesta} width={650}/>
                <br/>
                Laser cutting leaves an inside radius
                <br/>
                <br/>
                So, the last example was almost good. Still, you cannot make sharp cuts.
                A laser beam itself is round, thus leaving an inside radius.
                The simplest way is to follow material thickness – the minimal inside radius is 1/10 of the thickness.
                As an example, you can use R1, if you have a 10 mm thick steel plate.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"12"}
                   className={"anchor-title"}>
                Check the Scale
                <a href="#12" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                All drawings must be 1:1. Check the scale after creating the DXF files.
                Our system gives your sheet’s general dimensions after uploading the drawings.
                Make sure it corresponds to reality. If not, adjust the scale.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"13"}
                   className={"anchor-title"}>
                Overwriting Dimensions
                <a href="#13" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                We receive engineering drawings that have overwritten dimensions on them.
                If you just delete the old value and replace it with the right one, the scale will still stay the same.
                The dimension does not drive the line length.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"14"}
                   className={"anchor-title"}>
                Breaking the Views
                <a href="#14" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                <Picture src={sedma} width={650}/>
                <br/>
                Breaking a view may be a useful tool to make a drawing easily readable for a person.
                However, engineering drawings heading to automated cutting are read by computers.
                Breaking a detail gives the opposite effect and confuses the system, so forget about this function for
                now.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"15"}
                   className={"anchor-title"}>
                Glitches, Errors and the Like
                <a href="#15" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                This is especially common when converting the drawing format (e.g. PDF to DXF).
                Check the converted one for scrambled lines, random dots etc.
                If it keeps occurring, some Googling may lead you towards solving the problem.
                All my bolted connections used to show up scrambled, a 2-minute tutorial saved me from manual labour.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"16"}
                   className={"anchor-title"}>
                Nice Side Up
                <a href="#16" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                The prettier side of the sheet must be facing you.
                If you choose to get your sheet metal brushed, include the direction of brushing on your PDF drawing.
                Otherwise, you can orientate your DXF knowing that the brushing will be done horizontally in relation to
                the drawing.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"17"}
                   className={"anchor-title"}>
                Infinite Coastline
                <a href="#17" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                The coastline paradox says that the coastline of a landmass has no definitive length.
                Coastlines have fractal-like properties that lead to infinite lengths.
                We have received similar drawings that have zig-zaggy lines.
                Our calculator gives the price accordingly, although the zigginess of the lines is a mishap.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"18"}
                   className={"anchor-title"}>
                No Title Block
                <a href="#18" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                Title block is another confusing element for the computer.
                Only include lines that are used for cutting.
                The system does not care who is the author of those drawings, nor who checked them.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"19"}
                   className={"anchor-title"}>
                Overlapping Lines
                <a href="#19" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                We have received engineering drawings that have lines on top of each other.
                You may not notice them but the computer does and calculates them as separate cuts, adding to the price
                quotation.
                You will not be charged for those cuts later but the initial price will not represent the reality.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"20"}
                   className={"anchor-title"}>
                Helpers
                <a href="#20" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                <Picture src={osma} width={650}/>
                <br/>
                Leave them out.
                <br/>
                The same applies for bending lines.
                They are not related to cutting but will be seen as lines to cut, thus adding to the price offered.
                Add those to your PDF or just upload a .STP file for sheet metal bending.
                A submitted drawing should only have cutting lines in it.
                <br/>
                <br/>
                Also, make sure that it is possible to manufacture your design with the available press brake tooling.
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"21"}
                   className={"anchor-title"}>
                Everything Must Be Connected
                <a href="#21" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15}}>
                If you draw in a 2D environment, like AutoCAD, connect all the lines on the drawing.
                One way for an easy check is to try to hatch an area – if it is not properly connected, the hatch cannot
                be done.
                Just don’t forget to delete the hatched area after a successful try!
              </div>

              <div style={{fontSize: 27, color: 'black', marginTop: 15, fontWeight: 'bold'}} id={"22"}
                   className={"anchor-title"}>
                Cut to Normal
                <a href="#22" className="anchor">#</a>
              </div>
              <div style={{fontSize: 18, marginTop: 15, marginBottom: 100}}>
                As stated before, laser and plasma cutting are for perpendicular cuts only.
                If you design a product in a 3D CAD-software and want to cut a hole in a shallow cylinder, use the
                function cut to normal to avoid confusion.
                This results in cuts that are marked with one continuous line.
                <br/>
                <br/>
                The points above will shorten your time spent on drawing.
                Remember to keep it simple.
                It may be difficult to abandon all the drawings rules taught at university but it will make the service
                smoother for everybody.
              </div>
            </Col>
          </Row>
        </Layout>
      </Layout.Content>
    </Layout>
  )
}
 export default DxfTipsPage