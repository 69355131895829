import React, {createRef, useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  Layout, Row, Col, Button, Card,
  Upload, List, Grid,
  notification, Progress, Spin, Modal,
  Tooltip, Form, Input, Divider, Radio
} from 'antd'
import {CompanySidebar} from '../../../../components/company'
import {forceLogoutUser, logoutUser} from '../../../../actions/authActions'
import './style.css'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider as MiDivider,
} from "@material-ui/core";
import Sticky from "react-sticky-el";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EngineeringIcon from '@mui/icons-material/Engineering';
import {
  clearBulkOrder, applyMultipleItems, changeItemColor, changeItemMaterial,
  changeItemNote, changeItemQuantity, changeItemMaterialSurcharge, changeItemThickness,
  changeStandard, removeItemColor, removeMultipleItems, resetToProcess,
  removeItem, changeManualItemType, changeOrderPrice, changePartPrice,
  changePartToError, updatePartData, updatePartFilePath,
  removeErrorParts, changeManyPartPrices
} from "../../../../actions/bulkOrderActions";
import {debounce} from "lodash";
import {ArchivedBulkOrdersList} from "../../../../components/Order/ArchivedBulkOrdersList";
import {ProcessedItemCard, ProcessingItemCard, UploadingItemCard } from "../../../../components/Order/OrderItemCards";
import ItemVisualisation from "../../../../components/Order/ItemVisualisation";
import dragndropwhite from "../../../../assets/icons/dragndropwhite.png";
import {OrderUploadButton} from "../../../../components/Order/OrderUploadButton";
import {OrderUploadProcessProgress} from "../../../../components/Order/OrderUploadProcessProgress";
import cuid from "cuid";
import {OrderMultiEdit} from "../../../../components/Order/OrderMultiEdit";
import {getAvailableThicknesses} from "../../../../utils/material";
import storage from "../../../../utils/storage";
import {io} from "socket.io-client";
import {SOCKET_PATH, SOCKET_URL} from "../../../../constants";
import {uploadNewPart} from "../../../../actions/bulkOrderActions";
import {
  fetchInternalDraftOrder,
  resetToAnalyze,
  fetchInternalArchivedOrders,
  archiveCurrentCalculation
} from "../../../../actions/ordersActions";
import {OrderErrorPartsList} from "../../../../components/Order/OrderErrorPartsList";
import {
  DeleteOutlined,
  ExclamationCircleFilled, LoadingOutlined,
  SearchOutlined
} from "@ant-design/icons";

const { Dragger } = Upload;

let dragging = 0;

var socket;

export const BulkOrderPage = (props) => {
  const user = useSelector(state => state.auth.user);
  const isFetchingInternalDraft = useSelector(state => state.orders.isFetchingInternalDraft);
  const orderId = useSelector(state => state.bulkOrder.id);
  const items = useSelector(state => state.bulkOrder.parts)
  const errorItems = useSelector(state => state.bulkOrder.errorList)
  const needsManualQuote = useSelector(state => state.bulkOrder.needsManualQuote); //TODO: Proveri pre arhiviranja
  const materials = useSelector(state => state.bulkOrder.materials)
  const totalPrice = useSelector(state => state.bulkOrder.totalPrice)
  const vatValue = useSelector(state => state.bulkOrder.vatValue)
  const toProcess = useSelector(state => state.bulkOrder.toProcess)
  const currentlyUploaded = useSelector(state => state.bulkOrder.currentlyUploaded)
  const currentlyProcessed = useSelector(state => state.bulkOrder.currentlyProcessed)
  const companySelectableMaterials = useSelector(state => state.bulkOrder.selectableMaterials)
  const toAnalyze = useSelector(state => state.orders.toAnalyze)
  const archivedInternalOrders = useSelector(state => state.orders.archivedInternalOrders)
  const isArchivingCalculation = useSelector(state => state.orders.isArchivingCalculation)
  const isCollapsed = useSelector(state => state.util.isCollapsed);

  const [selectableThickness, setSelectableThickness] = useState(new Set([]))
  const [allItemsCount, setAllItemsCount] = useState(0);
  const [viewVisible, setViewVisible] = useState(false)
  const [itemView, setItemView] = useState(null)
  const [modalWidth, setModalWidth] = useState(window.innerWidth-100)
  const [modalHeight, setModalHeight] = useState(window.innerHeight-135)
  const [largeThreshold, _setLargeThreshold] = useState(window.innerWidth < 1300)
  const [smallThreshold, _setSmallThreshold] = useState(window.innerWidth < 750)
  const [indeterminate, setIndeterminate] = useState(false);

  const [selectedItems, setSelectedItems] = useState({})
  const [selectableItemsCount, setSelectableItemsCount] = useState(0)
  const [selectedItemsCount, setSelectedItemsCount] = useState(0)
  const [selectedAllItems, setSelectedAllItems] = useState(false)
  const [selectedParameters, setSelectedParameters] = useState({
    material: null,
    thickness: null,
    coating: {
      hasCoating: false,
      colorRAL: undefined,
      colorGlossId: undefined,
      ralRow: undefined,
      ralHex: undefined,
    },
    quantity: null,
    materialSurchargePerPiece: null
  })
  const [working, setWorking] = useState(false)
  const [actionMap, setActionMap] = useState(new Map());
  const [selectableMaterials, setSelectableMaterials] = useState(new Set([...(companySelectableMaterials || [])]))
  const [selectedItemsMaterials, setSelectedItemsMaterials] = useState({})
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [existsZeroPrice, setExistsZeroPrice] = useState(false);
  const [selectedCalc, setSelectedCalc] = useState("self");
  const [isFetchingInternalArchived, setIsFetchingInternalArchived] = useState(false);
  const [internalArchivedCalculations, setInternalArchivedCalculations] = useState([]);
  const [searchingArchived, setSearchingArchived] = useState(false);
  const [archiveOrderForm] = Form.useForm();
  const [socketConnected, setSocketConnected] = useState(false)

  const smallThresholdRef = React.useRef(smallThreshold);
  const largeThresholdRef = React.useRef(largeThreshold);
  const multiformRef = createRef()
  const workingRef = useRef(working)
  const form = createRef()

  const screens = Grid.useBreakpoint();

  const dispatch = useDispatch();

  const updateActions = (k,v) => {
    setActionMap(new Map(actionMap.set(k,v)));
  }

  const handleAnalysedPart = (data) => {
    dispatch(updatePartData(data))
  }

  const handleUpdateOrderPartFilePath = (data) => {
    dispatch(updatePartFilePath(data))
  }

  const handlePartProductionError = (data) => {
    dispatch(changePartToError(data))
  }

  const handleOrderPriceChange = (data) => {
    dispatch(changeOrderPrice(data))
  }

  const handleDeletePartsResponse = (data) => {
    const tmpMap = actionMap
    tmpMap.delete(data.actionId)
    setActionMap(new Map(tmpMap));
  }

  const handleUpdatePartResponse = (data) => {
    const tmpMap = actionMap
    tmpMap.delete(data.actionId)
    setActionMap(new Map(tmpMap));
    dispatch(changePartPrice(data))
  }

  const handleUpdateManyPartsResponse = (data) => {
    const tmpMap = actionMap
    tmpMap.delete(data.actionId)
    setActionMap(new Map(tmpMap));
    dispatch(changeManyPartPrices(data))
  }

  useEffect(()=>{
    if(selectedItemsCount > 0 && selectedItemsCount < selectableItemsCount) {
      setIndeterminate(true);
    } else {
      setIndeterminate(false);
    }
  }, [selectableItemsCount, selectedItemsCount])

  useEffect(() => {
    const accessToken = storage.retrieveAccessToken();

    socket = io(SOCKET_URL, {
      path: SOCKET_PATH,
      auth: {
        token: accessToken.substring(7)
      },
    });

    socket.on("connect", () => {
      setSocketConnected(true)
    });

    socket.on("analysedPart_OrderPart", (data) => {
      handleAnalysedPart(data)
    });

    socket.on("partProductionError_OrderPart", (data) => {
      handlePartProductionError(data)
    });

    socket.on("updateOrderPartFilePaths_OrderPart", (data) => {
      handleUpdateOrderPartFilePath(data)
    });

    socket.on("orderPriceChange_OrderPart", (data) => {
      handleOrderPriceChange(data)
    });

    socket.on("deleteOneOrMoreParts_OrderPart", (data) => {
      handleDeletePartsResponse(data)
    })

    socket.on("updateOnePart_OrderPart", (data) => {
      handleUpdatePartResponse(data)
    })

    socket.on("put-many-order-parts", (data) => {
      handleUpdateManyPartsResponse(data)
    })


    socket.on("connect_error", (err) => {
      if (err.message === "invalid credentials") {
        storage.refreshAccessToken().then(() => {
          const accessToken = storage.retrieveAccessToken();
          if(accessToken) {
            socket.auth.token = accessToken.substring(7);

            socket.connect();
          }
        })
      }
    });

    socket.on("server-error", (err) => {
      if (err.msg === "invalid credentials") {
        dispatch(forceLogoutUser())
      }
      if (err.event === "put-many-order-parts" && err.data?.actionId) {
        const actionId = err.data.actionId
        const tmpMap = actionMap
        tmpMap.delete(actionId)
        setActionMap(new Map(tmpMap));
      }
      console.log(err.msg)
    });

    // Destroys the socket reference
    // when the connection is closed
    return () => {
      socket.disconnect();
    };
  }, []);

  const triggerPartProcessing = ({orderId, orderPartId}) => {
    if(socket) {
      socket.emit("triggerPartProcessing_OrderPart", { orderId, orderPartId });
    }
  }

  const removeOneOrMoreParts = (data) => {
    if(socket) {
      socket.emit("deleteOneOrMoreParts_OrderPart", { ...data });
    }
  }

  const triggerUpdatePart = (data) => {
    if(socket) {
      socket.emit("updateOnePart_OrderPart", data);
    }
  }

  const triggerUpdateMultipleParts = (data) => {
    if(socket) {
      socket.emit("put-many-order-parts", data);
    }
  }

  const triggerPartNoteChange = (data) => {
    if(socket) {
      socket.emit("updateOnePartNote_OrderPart", data);
    }
  }

  const triggerChangeManualPartType = (data) => {
    if(socket) {
      socket.emit("updateManualPartType_OrderPart", data);
    }
  }

  const triggerPartStandardChange = (data) => {
    if (socket) {
      socket.emit("updateOnePartStandard_OrderPart", data)
    }
  }

  const triggerPartPriceChange = (data) => {
    if (socket) {
      socket.emit("updateOnePartPrice_OrderPart", data)
    }
  }

  useEffect(()=>{
    if(currentlyUploaded < toProcess || currentlyProcessed < toProcess) {
      setWorking(true)
      workingRef.current = true
    }
    else {
      if (workingRef.current) {
        // TODO fetch the order to confirm everything
      }
      setWorking(false)
      workingRef.current = false
    }
  }, [toProcess, currentlyUploaded])

  useEffect(()=>{
    if(socketConnected) {
      if(toAnalyze.length > 0) {
        analyzeParts([...toAnalyze])
        resetToAnalyze()
      }
    }
  }, [socketConnected, toAnalyze])

  const analyzeParts = parts => {
    for(let part of parts) {
      triggerPartProcessing({orderId: orderId, orderPartId: part.id})
    }
  }

  const handleDragEnter = e => {
    dragging++
    e.stopPropagation();
    e.preventDefault();

    let dt = e.dataTransfer;
    if (dt.types != null && ((dt.types.length && (dt.types[0] === 'Files' || dt.types[0] === 'application/x-moz-file')))) {
      let dropper = document.getElementsByClassName("fileDropZone")[0]
      if(dropper.style.display === "none" && ! isOpenBrowseRef.current) {
        dropper.style.display = "block"
      }
    }
    return false
  };

  const handleDragLeave = e => {
    dragging--;
    if (dragging === 0) {
      document.getElementsByClassName("fileDropZone")[0].style.display = "none"
    }

    e.stopPropagation();
    e.preventDefault();
    return false
  };

  const handleDrop = e => {
    e.preventDefault();
    dragging=0
    document.getElementsByClassName("fileDropZone")[0].style.display = "none"
  };

  const [isOpenBrowse, _setIsOpenBrowse] = useState(false);
  const isOpenBrowseRef = useRef(null);
  isOpenBrowseRef.current = isOpenBrowse;
  const setIsOpenBrowse = (value) => {
    isOpenBrowseRef.current = value;
    _setIsOpenBrowse(value);
  }

  const handleFocus = useCallback(() => {
    if (isOpenBrowseRef.current) {
      setIsOpenBrowse(false);
    }
  }, []);

  useEffect(()=>{
    if(toProcess !== 0 && currentlyProcessed === toProcess) {
      dispatch(resetToProcess({}))
    }
  }, [currentlyProcessed, toProcess, dispatch])

  useEffect(() => {
    dispatch(fetchInternalDraftOrder()).then((order)=>{
      setSelectableMaterials(new Set([...(order?.selectableMaterials || [])]))
    })
  }, [])

  useEffect(() => {
    document.addEventListener("dragenter", handleDragEnter);
    document.addEventListener("dragleave", handleDragLeave);
    document.addEventListener("drop", handleDrop, false);
    window.addEventListener("focus", handleFocus)
    window.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("dragenter", handleDragEnter);
      document.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("drop", handleDrop, false);
      window.removeEventListener("focus", handleFocus)
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  const setLargeThreshold = value => {
    largeThresholdRef.current = value
    _setLargeThreshold(value)
  }
  const setSmallThreshold = value => {
    smallThresholdRef.current = value
    _setSmallThreshold(value)
  }
  const handleResize = () => {
    const width = window.innerWidth
    if(width < 1300) {
      if(!largeThresholdRef.current) {
        setLargeThreshold(true)
      }
    }
    else {
      if(largeThresholdRef.current) {
        setLargeThreshold(false)
      }
    }

    if(width < 750) {
      if(!smallThresholdRef.current) {
        setSmallThreshold(true)
      }
    }
    else {
      if(smallThresholdRef.current) {
        setSmallThreshold(false)
      }
    }

    setModalWidth(width-100);
    setModalHeight(window.innerHeight-135);
  };

  const strForSearch = (str) => {
    return str
      ? str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
      : str;
  };

  const findMaterial = (name) => {
    let rez;
    for(let material of materials) {
      rez = material.grades.find(type => type.grade === name)
      if (rez) {
        rez.groupName = material.groupName
        break;
      }
    }
    return rez
  }

  const changePartMaterial = (itemId, materialName, type, processingType, thickness, itemMaterials) => {
    let rez = findMaterial(materialName);

    if (type === "step" && processingType === "automatic") {
      let thick = rez.thickness.find(t => parseFloat(t.thickness) === parseFloat(thickness))
      rez.selectedMaterial = {id: thick.id, thickness: thick.thickness}
    } else {
      if(type === "dxf" && processingType === "automatic") {
        rez.thickness = rez.thickness.filter(item => itemMaterials?.[rez.groupName]?.[rez.grade]?.[item.id])
      }
      let thick = rez.thickness.find(t => parseFloat(t.thickness) === parseFloat(thickness))
      rez.selectedMaterial = {
        id: thick ? thick.id : rez.thickness[0].id,
        thickness: thick ? thick.thickness : rez.thickness[0].thickness
      }
    }

    if(rez?.selectedMaterial?.id) {
      const actionId =  cuid()
      const data = {
        orderId: orderId,
        orderPartId: itemId,
        actionId,
        fields: [
          {
            fieldName: "material",
            fieldValue: rez.selectedMaterial.id
          },
        ]
      }
      updateActions(actionId, true)
      triggerUpdatePart(data)
    }
    dispatch(changeItemMaterial({id: itemId, material: rez}))
  }
  const changePartQuantity = (data) => {
    debouncedQuantityChange(data)
  }
  const changePartMaterialSurchargePerPiece = (data) => {
    debouncedMaterialSurchargeChange(data)
  }

  const changePartNote = (data) => {
    debouncedNoteChange(data)
  }

  const changePartThickness = (data) => {
    const actionId =  cuid()
    const data2 = {
      orderId: orderId,
      actionId,
      orderPartId: data.id,
      fields: [{fieldName: "material", fieldValue: data.thickness.id}]
    }
    updateActions(actionId, true)
    triggerUpdatePart(data2)
    dispatch(changeItemThickness(data))
  }

  const changePartColor = (data) => {
    const actionId = cuid()
    const data2 = {
      orderId: orderId,
      actionId,
      orderPartId: data.id,
      fields: [{
        fieldName: "coating",
        fieldValue: {
          hasCoating: true,
          colorRAL: data.selectedRAL,
          colorGlossId: data.selectedGloss.toString(),
          ralRow: data.selectedRow,
          ralHex: data.ralHex
        }
      }]
    }

    updateActions(actionId, true)
    triggerUpdatePart(data2)

    dispatch(changeItemColor(data))
  }
  const changePartStandard = (data) => {
    debouncedStandardChange(data)
  }
  const changePrice = (data) => {
    debouncedPriceChange(data)
  }

  const handleChangeManualItemType = (id, partType) => {
    triggerChangeManualPartType({orderId: orderId, orderPartId: id, partType})
    dispatch(changeManualItemType({id, partType}))
  }
  const handleRemoveMultipleItems = () => {
    const actionId =  cuid()
    updateActions(actionId, true)
    const data = {
      actionId,
      orderId: orderId,
      partIds: []
    }

    dispatch(removeMultipleItems({selected: {...selectedItems}}))
    let map = {...selectedItems}
    for(let key of Object.keys(selectedItems)) {
      if(selectedItems[key]) {
        data.partIds.push(key)
        delete map[key]
      }
    }
    removeOneOrMoreParts(data)
    setSelectedItems({...map})
    setSelectedItemsCount(0)
    setSelectedAllItems(false)
    setIndeterminate(false)
    setSelectedItemsMaterials({})
    setSelectableMaterials(new Set([...companySelectableMaterials]))
    setSelectableThickness(new Set([]))
  }
  const handleMultiClear = () => {
    setSelectedParameters({
      material: null,
      thickness: null,
      coating: {
        hasCoating: false,
        colorRAL: undefined,
        colorGlossId: undefined,
        ralRow: undefined,
        ralHex: undefined,
      },
      quantity: null,
      materialSurchargePerPiece: null,
    })
    multiformRef?.current?.setFieldsValue({
      material: null,
      thickness: null,
      quantity: null,
      materialSurchargePerPiece: null
    })
  }
  const handleMultiMaterialClear = () => {
    setSelectedParameters((prev) => ({
      ...prev,
      material: null,
      thickness: null
    }))
    multiformRef.current.setFieldsValue({
      thickness: null
    })
  }
  const handleMultiColorSelect = (item) => {
    setSelectedParameters((prev) => ({
      ...prev,
      coating: {
        hasCoating: true,
        colorRAL: item.selectedRAL,
        colorGlossId: item.selectedGloss,
        ralRow: item.selectedRow,
        ralHex: item.ralHex,
      }
    }))
  }
  const handleMultiColorClear = () => {
    setSelectedParameters((prev) => ({
      ...prev,
      coating: {
        hasCoating: false,
        colorRAL: undefined,
        colorGlossId: undefined,
        ralRow: undefined,
        ralHex: undefined,
      }
    }))
  }
  const handleMultiQuantityChange = (q) => {
    setSelectedParameters((prev) => ({
      ...prev,
      quantity: q
    }))
  }
  const handleMultiMaterialSurchargePerPieceChange = (s) => {
    setSelectedParameters((prev) => ({
      ...prev,
      materialSurchargePerPiece: s
    }))
  }
  const handleMultiMaterialSelect = (materialName) => {
    let rez = findMaterial(materialName);
    if(rez) {
      let a = [...rez.thickness]
      for (let part of items) {
        if(selectedItems[part.id] && part.fileType === "dxf" && part.processingType === "automatic") {
          let thicknesses = part.materials[rez.groupName][rez.grade]
          a = a.filter(t => thicknesses[t.id])
        }
      }
      let b = [...a]
      setSelectableThickness(new Set(b.map(item => item.id)))
      let oldThickness = selectedParameters.thickness
      setSelectedParameters((prev) => ({
        ...prev,
        material: rez
      }))
      if(!oldThickness || !rez.thickness.some(e => e.value === oldThickness)) {
        setSelectedParameters((prev) => ({
          ...prev,
          thickness: null
        }))
        multiformRef.current.setFieldsValue({
          thickness: null
        })
      }
    }
  }
  const handleMultiThicknessSelect = (thickness) => {
    setSelectedParameters((prev) => ({
      ...prev,
      thickness: thickness
    }))
  }
  const handleMultiThicknessClear = () => {
    setSelectedParameters((prev) => ({
      ...prev,
      thickness: null
    }))
  }

  const handleMultiApply = () => {
    const applyingChanges = {}
    const partsToSend = {}
    const { thickness, material, coating, quantity, materialSurchargePerPiece } = selectedParameters;
    if (material || coating?.hasCoating || quantity || materialSurchargePerPiece) {
      for (let item of items) {
        if(selectedItems[item.id]) {
          let newMaterial = {};
          if (material) {
            if (item.fileType === 'step' && item.processingType === "automatic") {
              let rez = material.thickness.find(thick => parseFloat(thick.thickness) === parseFloat(item.selectedMaterial.thickness))
              newMaterial = {
                grade: material.grade,
                materialId: rez.id,
                thickness: rez.thickness
              }
              newMaterial.availableThicknesses = [{id: rez.id, thickness: rez.thickness}]
            } else {
              let availableThicknesses = getAvailableThicknesses(item.fileType, item.processingType, materials, material, item.materials)
              if (thickness) {
                let rez = material.thickness.find(thick => parseFloat(thick.thickness) === parseFloat(thickness))
                newMaterial = {
                  grade: material.grade,
                  materialId: rez.id,
                  thickness: rez.thickness
                }
              } else {
                let rez = availableThicknesses.find(thick => parseFloat(thick.thickness) === parseFloat(item.selectedMaterial.thickness))
                newMaterial = {
                  grade: material.grade,
                  materialId: rez ? rez.id : availableThicknesses[0].id,
                  thickness: rez ? item.thickness : availableThicknesses[0].thickness
                }
              }

              newMaterial.availableThicknesses = availableThicknesses
            }
          }
          let tmpCoating = coating
          if(coating.hasCoating) {
            if(!item.surfaceCoatingParams?.hasSurfaceCoating) {
              tmpCoating = {
                hasCoating: false,
                colorRAL: "",
                colorGlossId: "",
                ralRow: "",
                ralHex: ""
              }
            }
          }

          applyingChanges[item.id] = { material: newMaterial, quantity, coating: tmpCoating }
          let fields = []
          if(newMaterial.materialId) {
            fields.push({
              fieldName: "material",
              fieldValue: newMaterial.materialId
            })
          }

          if(tmpCoating.hasCoating) {
            fields.push({
              fieldName: "coating",
              fieldValue: {
                hasCoating: true,
                colorRAL: coating.colorRAL,
                colorGlossId: coating.colorGlossId.toString(),
                ralRow: coating.ralRow,
                ralHex: coating.ralHex
              }
            })
          }

          if(quantity) {
            fields.push({
              fieldName: "quantity",
              fieldValue: quantity
            })
          }

          if(materialSurchargePerPiece) {
            fields.push({
              fieldName: "materialSurchargePerPiece",
              fieldValue: materialSurchargePerPiece
            })
          }

          if(fields.length === 0) {
            delete applyingChanges[item.id]
            continue // ??
          }

          partsToSend[item.id] = fields
        }
      }
      const actionId = cuid()
      updateActions(actionId, true)
      triggerUpdateMultipleParts({
        orderId,
        actionId,
        parts: partsToSend
      })
      dispatch(applyMultipleItems({applyingChanges}))
    }
  }

  const handleSelectAll = (checked) => {
    if(checked) {
      let map = {}
      let selectable = {}
      let a = null
      for(let item of items) {
        if(item.state === "processed") {
          map[item.id] = true
          selectable[item.id] = new Set([...item.selectableGrades])
          if (!a) {
            a = selectable[item.id]
          } else {
            a = new Set([...a].filter(x => selectable[item.id].has(x)))
          }
        }
      }
      setSelectedItems({...map})
      setSelectedItemsCount(selectableItemsCount)
      setSelectedItemsMaterials({...selectable})

      for(const key in selectable) {
        if (!a) {
          a = selectable[key]
        } else {
          a = new Set([...a].filter(x => selectable[key].has(x)))
        }
      }

      if(selectedParameters.material) {
        if(!a.has(selectedParameters.material.grade)) {
          setSelectedParameters((prev) => ({
            ...prev,
            material: null,
            thickness: null
          }))
          multiformRef.current.setFieldsValue({
            material: null,
            thickness: null,
          })
        } else {
          let thick = [...selectedParameters.material.thickness]
          for (let part of items) {
            if(map[part.id] && part.fileType === "dxf" && part.processingType === "automatic") {
              let thicknesses = part.materials[selectedParameters.material.groupName][selectedParameters.material.grade]
              thick = thick.filter(t => thicknesses[t.id])
            }
          }
          setSelectableThickness(new Set(thick.map(item => item.id)))
          setSelectedParameters((prev) => ({
            ...prev,
            thickness: null
          }))
          multiformRef.current.setFieldsValue({
            thickness: null,
          })
        }
      }

      setSelectableMaterials(a)
    }
    else {
      setSelectedItems({})
      setSelectedItemsCount(0)
      setSelectedItemsMaterials({})
      setSelectableMaterials(new Set([...companySelectableMaterials]))
      if(selectedParameters.material) {
        setSelectableThickness(new Set([...(selectedParameters.material.thickness.map(item => item.id))]))
      } else {
        setSelectableThickness(new Set([]))
      }
    }
    setIndeterminate(false);
  }

  const handleQuantityChange = (data) => {
    if (data.id && !isNaN(parseInt(data.quantity))) {
      const actionId = cuid()
      const data2 = {
        orderId: data.orderId,
        actionId,
        orderPartId: data.id,
        fields: [{fieldName: "quantity", fieldValue: data.quantity}]
      }
      updateActions(actionId, true)
      triggerUpdatePart(data2)
    }
    dispatch(changeItemQuantity(data))
  }
  
  const handleMaterialSurchargeChange = (data) => {
    if (data.id && !isNaN(parseInt(data.materialSurchargePerPiece))) {
      const actionId = cuid()
      const data2 = {
        orderId: data.orderId,
        actionId,
        orderPartId: data.id,
        fields: [{fieldName: "materialSurchargePerPiece", fieldValue: data.materialSurchargePerPiece}]
      }
      updateActions(actionId, true)
      triggerUpdatePart(data2)
    }

    dispatch(changeItemMaterialSurcharge(data))
  }

  const handleNoteChange = (data) => {
    const data2 = {
      orderId: data.orderId,
      orderPartId: data.id,
      note: data.note
    }
    triggerPartNoteChange(data2)
    dispatch(changeItemNote(data))
  }

  const handleColorRemove = (id) => {
    const actionId = cuid()
    const data2 = {
      orderId: orderId,
      actionId,
      orderPartId: id,
      fields: [{
        fieldName: "coating",
        fieldValue: {
          hasCoating: false,
          colorRAL: "",
          colorGlossId: "",
          ralRow: 0,
          ralHex: ""
        }
      }]
    }

    updateActions(actionId, true)
    triggerUpdatePart(data2)

    dispatch(removeItemColor({id}))
  }

  const handlePriceChange = (data) => {
    const actionId = cuid()
    const data2 = {
      actionId,
      orderId: data.orderId,
      orderPartId: data.id,
      pricePerPiece: data.price,
      quantity: data.quantity
    }
    updateActions(actionId, true)
    triggerPartPriceChange(data2)
    dispatch(changePartPrice({orderPartId: data.id, pricePerPiece: data.price, totalPrice: data.quantity * data.price}))
  }

  const handleStandardChange = (data) => {
    const data2 = {
      orderId: data.orderId,
      orderPartId: data.id,
      standard: data.standard
    }
    triggerPartStandardChange(data2)
    dispatch(changeStandard({orderPartId: data.id, standard: data.standard}))
  }

  const handleRemoveErrorParts = () => {
    const partIds = errorItems.map(item => item.id)
    dispatch(removeErrorParts({orderId: orderId, partIds}))
  }
  
  useEffect(() => {
    if(items) {
      setAllItemsCount(items.length)
      setSelectableItemsCount(items.filter(item => item.state === "processed").length)
    }
  }, [items])

  const triggerUpload = (file) => {
    const id = cuid()
    const uploadTime = Date.now()
    const uploadIndex = parseInt(file.uid.slice(file.uid.lastIndexOf("-")+1))
    dispatch(uploadNewPart({
      file: file,
      data: {
        orderId: orderId,
        fileId: id,
        fileName: file.name,
        uploadTime,
        uploadIndex
      },
      callback: triggerPartProcessing
    }))
  }

  const debouncedQuantityChange = useCallback(
    debounce(data => handleQuantityChange(data), 800),
    []
  );

  const debouncedMaterialSurchargeChange = useCallback(
    debounce(data => handleMaterialSurchargeChange(data), 800),
    []
  );

  const debouncedNoteChange = useCallback(
    debounce(data => handleNoteChange(data), 1000),
    []
  );

  const debouncedPriceChange = useCallback(
    debounce(data => handlePriceChange(data), 800),
    []
  );

  const debouncedStandardChange = useCallback(
    debounce(data => handleStandardChange(data), 1000),
    []
  );


  const showItem = (item) => {
    setItemView({...item, viewType: "model"})
    setViewVisible(true)
  }

  const cancelShowItem = ()  => {
    setItemView(null)
    setViewVisible(false)
  }

  const handleClearOrder = (e) => {
    dispatch(clearBulkOrder({})).then(() => {
      setSelectableItemsCount(0);
      setSelectedItems({})
      setSelectedItemsCount(0)
      setSelectedItemsMaterials({})
      setSelectedAllItems(false)
      setIndeterminate(false)
      setSelectableMaterials(new Set([...companySelectableMaterials]))
      setSelectableThickness(new Set([]))
      handleMultiClear()
      e()
    }).catch((error)=>{
      console.log(error)
    })
  }

  const openClearOrder = () => {
    let modal = Modal.confirm({
      className: "inputInfoText",
      visible: true,
      style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
      centered: true,
      onOk: (e) => {
        modal.update(prevConfig => ({
          ...prevConfig,
          cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
          okButtonProps: {...prevConfig.okButtonProps, loading: true}
        }));
        handleClearOrder(e)
      },
      confirmLoading: true,
      destroyOnClose: true,
      width: 400,
      title: <span>{"Clear Quotation"}</span>,
      content: <div>
        <div>Are you sure you want to remove all parts from the current quotation?</div>
        <Divider style={{marginBottom: 0, position: 'relative', right: 36, width: 334}}/>
      </div>,
      okText: <span style={{fontSize: 13, fontWeight: 500}}>Confirm</span>,
      okButtonProps: {type: "primary", loading: false},
      cancelText: <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
      icon: <DeleteOutlined style={{color: "red"}}/>,
      zIndex: 100,
    })
  }

  const handleMaterialFilter = (item, checked) => {
    let a = null

    if(checked) {
      a = new Set(item.selectableGrades)
      a = new Set([...a].filter(x => selectableMaterials.has(x)))
      setSelectedItemsMaterials((prev)=>({...prev, [item.id]: new Set(item.selectableGrades)}))
    }
    else {
      const tmpSelectedItemsMaterials = {...selectedItemsMaterials}
      delete tmpSelectedItemsMaterials[item.id]
      for(const key in tmpSelectedItemsMaterials) {
        if (!a) {
          a = selectedItemsMaterials[key]
        } else {
          a = new Set([...a].filter(x => selectedItemsMaterials[key].has(x)))
        }
      }
      setSelectedItemsMaterials({...tmpSelectedItemsMaterials})
    }
    if(!a) {
      a = new Set([...companySelectableMaterials])
    }
    if(selectedParameters.material) {
      if(!a.has(selectedParameters.material.grade)) {
        setSelectedParameters((prev) => ({
          ...prev,
          material: null,
          thickness: null
        }))
        multiformRef.current.setFieldsValue({
          material: null,
          thickness: null,
        })
      } else {
        if(item.fileType === "dxf" && item.processingType === "automatic") {
          if (checked) {
            let newThicknesses = [...selectableThickness]
            let thicknesses = item.materials[selectedParameters.material.groupName][selectedParameters.material.grade]
            newThicknesses = newThicknesses.filter(t => thicknesses[t])
            setSelectableThickness(new Set(newThicknesses))
            setSelectedParameters((prev) => ({
              ...prev,
              thickness: null
            }))
            multiformRef.current.setFieldsValue({
              thickness: null,
            })
          } else {
            let thick = [...selectedParameters.material.thickness]
            for (let part of items) {
              if(selectedItems[part.id] && part.fileType === "dxf" && part.processingType === "automatic" && part.id !== item.id) {
                let thicknesses = part.materials[selectedParameters.material.groupName][selectedParameters.material.grade]
                thick = thick.filter(t => thicknesses[t.id])
              }
              setSelectableThickness(new Set(thick.map(item => item.id)))
            }
          }
        }
      }
    }
    setSelectableMaterials(a)
  }

  const handleRemoveItem = (id) => {
    const actionId =  cuid()
    updateActions(actionId, true)
    const data = {
      actionId,
      orderId: orderId,
      partIds: [id]
    }
    removeOneOrMoreParts(data)
    dispatch(removeItem({id}))
    if (selectedItems[id]) {
      let map = {...selectedItems}
      delete map[id]
      setSelectedItems({...map})
      setSelectedItemsCount(prev => prev - 1)
      setSelectedAllItems(false)
      setIndeterminate(Object.keys(map).length !== 0)
      handleMaterialFilter({id}, false)
    }
  }

  const onCheck = (item, checked) => {
    setSelectedItems({...selectedItems, [item.id]: checked})
    setSelectedItemsCount(checked ? selectedItemsCount + 1 : selectedItemsCount - 1)
    setSelectedAllItems(!checked ? false : selectedAllItems)
    handleMaterialFilter(item, checked)
  }

  const openFileSizeNotification = () => {
    notification.error({
      key: "file2Large",
      placement: "bottomRight",
      message: (<div style={{fontWeight: 600}}>File Too Large</div>),
      description: (
        <div style={{fontSize: 13, fontWeight: 500}}>
          Some of the files are above the allowed 100MB file size limit.
        </div>
      )
    });
  };

  const checkIfPriceIsZero = () => {
    return items.filter(item => item.totalPrice === 0).length > 0
  }

  const fetchArchivedList = () => {
    form?.current?.setFieldsValue({
      search: ""
    })
    setIsFetchingInternalArchived(true)
    dispatch(fetchInternalArchivedOrders({filter: selectedCalc})).then(orders => {
      setInternalArchivedCalculations(orders)
      setIsFetchingInternalArchived(false)
    })
  }

  const handleArchiveOrder = (values) => {
    dispatch(archiveCurrentCalculation({customName: values.customName, orderNote: values.orderNote})).then(()=>{
      archiveOrderForm.setFieldsValue({
        customName: "",
        orderNote: ""
      })
      setIsArchiveModalVisible(false)
      fetchArchivedList()
    })
  }

  useEffect(()=>{
    fetchArchivedList()
  }, [selectedCalc])

  const escapeRegExp = (text) => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const filterArchivedCalculations = (data) => {
    if(!data.text) {
      setInternalArchivedCalculations(data.archivedInternalOrders)
    } else {
      const orders = data.archivedInternalOrders.filter(item => (item.customName || item.name).toLowerCase().search(escapeRegExp(data.text.toLowerCase())) !== -1)
      setInternalArchivedCalculations(orders)
    }
    setSearchingArchived(false)
  }

  const debounceSearchArchivedCalculations = useCallback(
    debounce(data => filterArchivedCalculations(data), 800),
    []
  );

  const searchArchivedCalculations = (text) => {
    setSearchingArchived(true)
    debounceSearchArchivedCalculations({archivedInternalOrders,text})
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <div style={{position: 'fixed', top:0, width: '100%', zIndex: 10000 }}>
        {(working || actionMap.size > 0) &&
        <div style={{marginTop: -8}}>
          <Progress className={"progress"} percent={100} status="active" showInfo={false}/>
        </div>
        }
      </div>
      <CompanySidebar
        onLogout={() => dispatch(logoutUser({}))}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}} type="flex">
        <Spin
          spinning={isFetchingInternalDraft || !socketConnected}
          wrapperClassName={"fetchingOrderSpin"}
          indicator={
            <div style={{position: "absolute", top: "35%", left: "50%", width: 400, transform: "translateX(-50%)"}}>
              <LoadingOutlined style={{ fontSize: 72 }} spin />
              <div style={{fontSize: 27, fontWeight: 500, marginTop: 10}}>Fetching Your Quotation</div>
            </div>
          }
        >
          <div style={{overflowX: 'hidden', height: "100%"}}>
            <div style={{marginRight: 15, marginBottom: 15, marginLeft: 25, marginTop: 15, minHeight: "calc(100vh - 30px)"}}>
            {orderId &&
              <Row wrap={false} style={{height: '100%', minHeight: "calc(100vh - 30px)"}}>
                <Col span={largeThreshold ? 24 : 17}>
                  <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
                    <div className={"text14-500"} style={{fontSize: 20}}>
                      Instant Quoting
                    </div>
                    <div style={{marginRight: 20, display: 'flex'}}>
                      <MiDivider orientation="vertical"
                                 flexItem
                                 style={{
                                   marginTop: -20,
                                   marginBottom: -15,
                                   display: largeThreshold ? 'none' : 'block',
                                   marginLeft: 10
                                 }}
                      />
                      <div style={{marginLeft: 20}}>
                        <Button
                          className={"clearOrderButton"}
                          disabled={allItemsCount === 0}
                          onClick={() => {
                            openClearOrder()
                          }}
                        >
                          <span style={{fontSize: 14, fontWeight: 600}}>Clear Quotation</span>
                        </Button>
                      </div>
                      <div style={{marginLeft: 20}}>
                        {allItemsCount < 1 ? (
                          <Tooltip
                            overlayClassName={"adminTooltip"}
                            placement={'bottomRight'}
                            overlayStyle={{width: 300}}
                            getPopupContainer={trigger => trigger.parentNode}
                            title={
                              <span className={"text12-500"} style={{color: "white", fontSize: 13}}>
                                There are currently has no parts here. Upload your parts to quote them.
                              </span>
                                }
                          >
                            <Button type={"primary"}
                                    disabled={true}
                            >
                              <span style={{fontSize: 14, fontWeight: 600}}>Store This Quotation</span>
                            </Button>
                          </Tooltip>
                          ) : (
                            <Button type={"primary"}
                                    onClick={() => {
                                      setExistsZeroPrice(checkIfPriceIsZero())
                                      setIsArchiveModalVisible(true)}
                                    }
                            >
                              <span style={{fontSize: 14, fontWeight: 600}}>Store This Quotation</span>
                            </Button>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <MiDivider style={{marginLeft: -25, marginBottom: 15, marginTop: 15}}/>
                  {/*-------------Archived Bulk Orders-------------*/}
                  <div style={{paddingBottom: 12, marginRight: 10, display: largeThreshold ? 'block' : 'none'}}>
                    <Spin
                      spinning={false}
                      indicator={
                        <div align={"center"}>
                          <EngineeringIcon style={{fontSize: 30, marginTop: -5}}/>
                        </div>
                      }
                    >
                    <Accordion
                      style={{borderRadius: "4px", border: '1px solid #e0e0e0', boxShadow: 'none'}}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1c-content"
                                        id="panel1c-header2"
                                        style={{pointerEvents: 'auto'}}
                      >
                        <div className={"text14-500"} style={{fontSize: 20, marginLeft: 5}}>
                          Stored Quotations
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{
                        alignItems: 'center',
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        height: "400px",
                        padding: "0 0 5px 7px",
                      }}>
                        <div style={{ width: "100%", height: "100%"}}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ marginLeft: 8, width: 135, minWidth: 135 }} >
                              <Radio.Group
                                value={selectedCalc}
                                onChange={(e) => {setSelectedCalc(e.target.value)}}
                                className={"loginText"}
                              >
                                <Radio value="self">Mine</Radio>
                                <Radio value="all">All</Radio>
                              </Radio.Group>
                            </div>
                            <Input
                              suffix={<SearchOutlined style={{fontSize: 16, color: '#1890ff', margin: 0}}/>}
                              placeholder={"Type to search"}
                              className={"archiveSearch"}
                              onChange={(e)=>searchArchivedCalculations(e.target.value)}
                            />
                          </div>
                          <MiDivider style={{ margin: "0px 0px 0px -8px" }}/>
                          <div style={{
                            height: "351px",
                            width: "100%",
                            overflow: "auto",
                            marginRight: 5,
                            overflowX: "hidden"}}
                          >
                            <ArchivedBulkOrdersList items={internalArchivedCalculations} loading={isFetchingInternalArchived} searching={searchingArchived}/>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    </Spin>
                  </div>
                  <div>
                    <div style={{marginRight: largeThreshold ? 10 : 20}}>
                      {/*----------------Upload Button-----------------*/}
                      <OrderUploadButton
                        allItemsCount={allItemsCount}
                        errorItems={errorItems}
                        smallThreshold={smallThreshold}
                        screens={screens}
                        openFileSizeNotification={openFileSizeNotification}
                        triggerUpload={triggerUpload}
                        onOpen={()=>{setIsOpenBrowse(true)}}
                      />

                      {/*----------------Upload/Process Progress-----------------*/}
                      {toProcess > 0 &&
                      <OrderUploadProcessProgress
                        toProcess={toProcess}
                        currentlyUploaded={currentlyUploaded}
                        currentlyProcessed={currentlyProcessed}
                      />
                      }
                      {/*----------------------Order Summary---------------------*/}
                      {allItemsCount > 0 &&
                        <Card
                          style={{
                            marginTop: toProcess > 0 ? 12 : 0,
                            borderRadius: 4,
                            border: '1px solid rgb(224, 224, 224)',
                            height: "58px",
                            overflow: "auto"
                          }}
                          bodyStyle={{padding: "5px 0", height: "100%"}}
                        >
                          <div className={"summaryContainer"}>
                            <div className={"text14-500 orderSummary"}>
                              Quotation Summary:
                            </div>
                            <div className={"summaryContent"}>
                              <div>
                                <div style={{fontSize: 13, fontWeight: 600}}>
                                  <span style={{width: 110, display: "inline-block"}}>Total Price:</span> <span
                                  style={{fontWeight: 500}}>{(totalPrice || 0).toFixed(2)} €</span>
                                </div>
                              </div>
                              <div>
                                <div style={{fontSize: 13, fontWeight: 600}}>
                                  <span style={{width: 110, display: "inline-block"}}>Total with VAT:</span> <span
                                  style={{fontWeight: 500}}>{(totalPrice * (1 + vatValue) || totalPrice || 0).toFixed(2)} €</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      }
                      {/*----------------Multi Edit-----------------*/}
                      {selectableItemsCount > 0 &&
                      <OrderMultiEdit
                        selectedItemsCount={selectedItemsCount}
                        handleRemoveMultipleItems={handleRemoveMultipleItems}
                        selectedAllItems={selectedAllItems}
                        setSelectedAllItems={setSelectedAllItems}
                        handleSelectAll={handleSelectAll}
                        indeterminate={indeterminate}
                        selectableItemsCount={selectableItemsCount}
                        handleMultiMaterialSelect={handleMultiMaterialSelect}
                        handleMultiMaterialClear={handleMultiMaterialClear}
                        materials={materials}
                        selectableMaterials={selectableMaterials}
                        selectableThickness={selectableThickness}
                        multiformRef={multiformRef}
                        selectedParameters={selectedParameters}
                        handleMultiThicknessSelect={handleMultiThicknessSelect}
                        handleMultiThicknessClear={handleMultiThicknessClear}
                        handleMultiQuantityChange={handleMultiQuantityChange}
                        handleMultiMaterialSurchargePerPieceChange={handleMultiMaterialSurchargePerPieceChange}
                        handleMultiColorSelect={handleMultiColorSelect}
                        handleMultiColorClear={handleMultiColorClear}
                        handleMultiClear={handleMultiClear}
                        handleMultiApply={handleMultiApply}
                        isBulkOrder={true}
                      />
                      }
                      {/*-------------------PARTS-------------------*/}
                      {allItemsCount > 0 &&
                      <List
                        dataSource={items}
                        key={"id"}
                        style={{marginTop: 10}}
                        renderItem={item => {
                          switch (item.state) {
                            case "processed":
                              return (
                                <ProcessedItemCard
                                  orderId={orderId}
                                  item={item}
                                  materials={materials}
                                  changePartMaterial={changePartMaterial}
                                  changePartThickness={changePartThickness}
                                  changePartQuantity={changePartQuantity}
                                  changePartMaterialSurchargePerPiece={changePartMaterialSurchargePerPiece}
                                  changePartNote={changePartNote}
                                  changePartColor={changePartColor}
                                  changePartStandard={changePartStandard}
                                  handleColorRemove={handleColorRemove}
                                  handleChangeManualItemType={handleChangeManualItemType}
                                  handleRemoveItem={handleRemoveItem}
                                  showItem={showItem}
                                  selectedItems={selectedItems}
                                  onCheck={onCheck}
                                  isOrderDetails={false}
                                  parameterMissMatch={false}
                                  bulkItem
                                  changePrice={changePrice}
                                  adjustment={0}
                                />
                              )
                            case "uploaded":
                            case "processing":
                              return (
                                <ProcessingItemCard item={item}/>
                              )
                            case "uploading":
                              return (
                                <UploadingItemCard item={item}/>
                              )
                            default:
                              return (<div/>)
                          }
                        }}
                      />
                      }
                      {errorItems.length > 0 &&
                      <OrderErrorPartsList
                        errorParts={errorItems}
                        handleRemoveErrorParts={handleRemoveErrorParts}
                      />
                      }
                    </div>
                  </div>
                </Col>
                <MiDivider orientation="vertical" flexItem
                           style={{marginTop: -20, marginBottom: -15, display: largeThreshold ? 'none' : 'block'}}/>
                <Col flex={"auto"} style={{display: largeThreshold ? 'none' : 'block'}}>
                  <div style={{marginLeft: 20, marginTop: -15}}>
                    <Sticky
                      scrollElement="window"
                    >
                      <div className={"archivedOrdersSticky"}>
                        <Spin
                          spinning={isFetchingInternalArchived}
                          indicator={
                            <div align={"center"}>
                              <LoadingOutlined style={{ fontSize: 60, marginLeft: -15, marginTop: 30 }} spin />
                            </div>
                          }
                        >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                          <div className={"text14-500"} style={{fontSize: 20, whiteSpace: "nowrap", marginRight: 20}}>
                            Stored Quotations
                          </div>
                        </div>
                        <div style={{paddingTop: 17, height: "calc(100vh - 70px)"}}>
                          <Card style={{borderRadius: 5, border: '1px solid #c0c0c0', height: "100%"}}
                                bodyStyle={{padding: "5px 0 5px 7px", height: "100%"}}>
                            <div style={{ width: "100%", height: "100%" }}>
                              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "2px 4px" }}>
                                <div style={{ marginLeft: 2, width: 135, minWidth: 135 }}>
                                  <Radio.Group
                                    value={selectedCalc}
                                    onChange={(e) => {if(!searchingArchived){setSelectedCalc(e.target.value)}}}
                                    className={"loginText"}
                                  >
                                    <Radio value="self">Mine</Radio>
                                    <Radio value="all">All</Radio>
                                  </Radio.Group>
                                </div>
                                <div style={{ paddingRight: 3 }}>
                                  <Form ref={form}>
                                    <Form.Item name={"search"} style={{marginBottom: 0}}>
                                      <Input suffix={<SearchOutlined style={{fontSize: 16, color: '#1890ff'}}/>}
                                             placeholder={"Type to search"}
                                             onChange={(e)=>searchArchivedCalculations(e.target.value)}
                                      />
                                    </Form.Item>
                                  </Form>

                                </div>
                              </div>
                              <MiDivider style={{ margin: "4px 0px 0px -8px" }}/>
                              <div style={{width: "100%", height: "calc(100% - 37px)", overflow: "auto", overflowX: "hidden", marginRight: 5}}>
                                <ArchivedBulkOrdersList items={internalArchivedCalculations} loading={isFetchingInternalArchived} searching={searchingArchived}/>
                              </div>
                            </div>
                          </Card>
                        </div>
                        </Spin>
                      </div>
                    </Sticky>
                  </div>

                </Col>
              </Row>
            }
          </div>
        </div>
        </Spin>
        <ItemVisualisation
          isModelViewVisible={viewVisible}
          itemView={itemView}
          modalWidth={modalWidth}
          modalHeight={modalHeight}
          onCloseView={cancelShowItem}
          adjustment={0}
        />
        <Dragger
          className={"fileDropZone"}
          name = {'file'}
          beforeUpload={(file, fileList) => {
            if(fileList.length > 100) {
              let num = fileList.findIndex(item => item.uid === file.uid)
              if(num > 99) {
                return false
              }
            }

            const is2Large = file.size / 1024 / 1024 < 100;
            if (!is2Large) {
              openFileSizeNotification()
            }
            else {
              triggerUpload(file)
            }
            return false;
          }}
          multiple={true}
          showUploadList={false}
          style={{
            display: 'none',
            position: 'fixed',
            margin: 5,
            top: 0,
            left: 0,
            width: 'calc(100% - 12px)',
            maxHeight: 'calc(100vh - 12px)',
            background: "rgba(0.5,0.5,0.5,0.2)",
            border: "2px dashed #066fc1",
            zIndex: 2000
          }}
        >
          <div align={'center'}>
            <Card
              style={{
                borderRadius: "5px",
                background: "#2076ba",
                border: "0px",
                maxWidth: "300px"
              }}
            >
              <img src={dragndropwhite} className={"drop-file-icon"} alt={"file"} />
              <span className={"text14-600"} style={{color: "white", marginLeft: 15, fontSize: 16}}>
                Drop your files here
              </span>
            </Card>
          </div>
        </Dragger>
        <Modal
          className={"archiveModal"}
          visible={isArchiveModalVisible}
          width={600}
          closable={true}
          destroyOnClose={true}
          bodyStyle={{
            paddingTop: 36,
            paddingBottom: 12
          }}
          onCancel={() => {
            archiveOrderForm.setFieldsValue({
              customName: "",
              orderNote: ""
            })
            setIsArchiveModalVisible(false)
          }}
          footer={
            <div>
              <Button disabled={isArchivingCalculation} onClick={() => setIsArchiveModalVisible(false)}>
                <span style={{fontWeight: 500}}>Cancel</span>
              </Button>
              <Button key="submit"
                      htmlType="submit"
                      form={"archiveForm"}
                      type={"primary"}
                      loading={isArchivingCalculation}
              >
                <span style={{fontWeight: 500}}>Store</span>
              </Button>
            </div>
          }
        >
          {items &&
            <Form
              form={archiveOrderForm}
              name={"archiveForm"}
              onFinish={handleArchiveOrder}
            >
              <div style={{ marginTop: 20}}>
                <Card
                  style={{
                    marginTop: toProcess > 0 ? 12 : 0,
                    borderRadius: 4,
                    border: '1px solid rgb(224, 224, 224)',
                    height: "80px",
                    overflow: "auto"
                  }}
                  bodyStyle={{padding: "5px 0", height: "100%"}}
                >
                  <div className={"summaryContainer"}>
                    <div className={"text14-500 orderSummary"} style={{fontSize: 18}}>
                      Quotation Summary:
                    </div>
                    <div className={"summaryContent"} style={{ width: 380 }}>
                      <div style={{fontSize: 13, fontWeight: 600, display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                        <span style={{width: 140, display: "inline-block"}}>Number of parts:</span>
                        <span style={{fontWeight: 500, whiteSpace: "nowrap"}}>
                          {allItemsCount}
                        </span>
                      </div>
                      <Divider style={{ margin: "2px 0 2px 0" }}/>
                      <div style={{fontSize: 13, fontWeight: 600, display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                        <span style={{width: 140, display: "inline-block"}}>Total Price:</span>
                        <span style={{fontWeight: 500, whiteSpace: "nowrap"}}>
                          {(totalPrice || 0).toFixed(2)} €
                        </span>
                      </div>
                      <div style={{fontSize: 13, fontWeight: 600, display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                        <span style={{width: 140, display: "inline-block"}}>Total with VAT:</span>
                        <span style={{fontWeight: 500, whiteSpace: "nowrap"}}>
                          {(totalPrice * (1 + vatValue) || totalPrice || 0).toFixed(2)} €
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>

                {existsZeroPrice &&
                <div style={{
                  display: "flex",
                  backgroundColor: "#ececec",
                  padding: 5,
                  borderRadius: 5,
                  marginTop: 10,
                  marginBottom: 10,
                  border: "1px solid #aaaaaa",
                  alignItems: "center",
                }}>
                  <div>
                    <ExclamationCircleFilled
                      style={{color: "#ffc107", fontSize: 15, marginLeft: 5}}/>
                  </div>
                  <div className={"text12-600"} style={{marginLeft: 10, lineHeight: 1.4, fontSize: 11}}>
                    The cost of one or more parts is set to 0.00 €. Make sure this is intentional.
                  </div>
                </div>
                }

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", marginTop: 10, width: "100%"}}>
                  <div className={"text12-500-12 width80"}
                       style={{ whiteSpace: "nowrap", marginRight: 12, height: 33, fontWeight: 500}}
                  >
                    Order Name:
                  </div>
                  <Form.Item
                    className={"archiveOrderNameInput"}
                    name={"customName"}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {required: true, message: 'Name required!'},
                    ]}
                  >
                    <Input
                      style={{ display: "inline-block"}}
                      size={'large'}
                      className={"text12-500"}
                      placeholder={"Enter a name for this quotation"}
                    />
                  </Form.Item>
                </div>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                  <div className={"text12-500-12 width80"}
                       align={"right"}
                       style={{ whiteSpace: "nowrap", marginRight: 12, height: 33, fontWeight: 500 }}
                  >
                    Note:
                  </div>
                  <Form.Item
                    className={"textAreaInput"}
                    name={"orderNote"}
                  >
                    <Input.TextArea
                      rows={3}
                      className={"textAreaInputText"}
                      placeholder={"Optional Note"}
                      maxLength={500}
                      showCount
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          }
        </Modal>
        <Modal>

        </Modal>
      </Layout.Content>
    </Layout>
  )
}
