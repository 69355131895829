import React, {createRef, useEffect, useState} from "react";

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  Row,
  Select,
  Tooltip,
  Upload,
  Spin,
  Checkbox, Collapse
} from "antd";
import {CaretDownOutlined, CheckOutlined, DeleteFilled, MinusOutlined, PlusCircleFilled} from "@ant-design/icons";

import CoatingPicker from "../CoatingPicker";

import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {DrawingIcon} from "../../../assets/icons/drawing";
import { debounce } from 'lodash';
import dragndrop from "../../../assets/icons/dragndrop.png";
import addfile from "../../../assets/icons/addfile.png";
import dragndropwhite from "../../../assets/icons/dragndropwhite.png";
import {resetToProcess} from "../../../actions/orderActions";
import testPart3 from "../../../assets/icons/testPart3.svg";
import fileFolded from "../../../assets/Step 1_fold.stl";
import fileUnfolded from "../../../assets/Step 1_unfold.stl";
import LazyLoad from "react-lazyload";
import {OrderItem} from "../OrderItem";
import {ProcessedItemCard} from "../OrderItemCards";
import {Trans} from "react-i18next";

const { Option } = Select;
const { OptGroup } = Select;

const { Dragger } = Upload;
const { Panel } = Collapse;

const FirstStep = (props) => {
  return (
    <div>
      <div style={{marginBottom: 5, fontWeight: 500}}>Select the appropriate part type:</div>
      <Select
        defaultValue={props.manualQuoteType}
        onChange={value => props.setManualQuoteType(value)}
        style={{width: 200}}
        suffixIcon={<CaretDownOutlined/>}
      >
        <Option value={"sheetMetal"}>Sheet Metal</Option>
        <Option value={"profile"}>Profile</Option>
        <Option value={"machined"}>Machined Part</Option>
        {/*<Option value={"assembly"}>Assembly</Option>*/}
        <Option value={"other"}>Other</Option>
      </Select>
      <div align={"right"}>
        <Button
          type={"primary"}
          onClick={()=>{props.setManualQuoteStep(2)}}
          style={{marginTop: 10, fontWeight: 500, fontSize: 13}}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

// const SecondStepSheetMetal = (props) => {
//   const {fileData, processingFile, materials} = props;
//
//   const [analysedFile, setAnalysedFile] = useState(resOfAnalysis)
//
//   const [isProcessingFile, setIsProcessingFile] = useState(false)
//
//   useEffect(() => {
//     setAnalysedFile(fileData)
//   }, [fileData])
//
//   useEffect(() => {
//     setIsProcessingFile(processingFile)
//   }, [processingFile])
//
//   return (
//     <div>
//       <Spin spinning={isProcessingFile}>
//         {!analysedFile ?
//           <Upload
//             showUploadList={false}
//             className={"upload-button"}
//             beforeUpload={(file) => {
//               setIsProcessingFile(true)
//               setTimeout(() => {
//                 setAnalysedFile(resOfAnalysis)
//                 setIsProcessingFile(false)
//               }, 1000)
//               return false;
//             }}
//           >
//             <div className={"drop-button"} style={{marginBottom: 10, height: 100}}>
//               <div
//                 className={"upload-button-side upload-button-side-left"}
//                 style={{width: "50%"}}
//               >
//                 <img src={dragndrop} className={"file-icon"} alt={"file"}/>
//                 <div style={{display: 'flex'}}>
//                   <div style={{display: 'block'}}>
//                     <div className={"text14-600"}>
//                       Drop one file here
//                     </div>
//                     <div className={"text12-500"} style={{paddingTop: 4}}>
//                       Supported file formats: .dxf, .stp, .step
//                     </div>
//                   </div>
//                 </div>
//                 <div className={"or-style"} style={{backgroundColor: "white"}}>
//                   or
//                 </div>
//               </div>
//               <div
//                 className={"upload-button-side jss163"}
//                 style={{width: "50%"}}
//               >
//                 <div style={{display: 'flex'}}>
//                   <img src={addfile} className={"file-icon"} alt={"file"}/>
//                   <div style={{display: 'block'}}>
//                     <div className={"text14-600"}>
//                       Add file from your computer
//                     </div>
//                     <div className={"text12-500"} style={{paddingTop: 4}}>
//                       Choose one file and add it
//                     </div>
//                   </div>
//                 </div>
//                 <PlusCircleFilled style={{color: "#1890ff", fontSize: 32}}/>
//               </div>
//             </div>
//           </Upload>
//           :
//           <ProcessedItemCard
//             item={analysedFile}
//             materials={materials}
//             changePartMaterial={()=>{}}
//             changePartThickness={()=>{}}
//             changePartQuantity={()=>{}}
//             changePartNote={()=>{}}
//             changePartColor={()=>{}}
//             handleColorRemove={()=>{}}
//             handleRemoveItem={()=>{}}
//             showItem={()=>{}}
//             selectedItems={()=>{}}
//             onCheck={()=>{}}
//             handleChangeManualItemType={()=>{}}
//             isOrderDetails={true}
//             parameterMissMatch={false}
//           />
//         }
//       </Spin>
//       <div align={"right"} style={{marginTop: 10}}>
//         <Button
//           type={"default"}
//           onClick={()=>{props.setManualQuoteStep(1)}}
//           style={{fontWeight: 500, fontSize: 13}}
//           disabled={isProcessingFile}
//         >
//           Back
//         </Button>
//         <Button
//           type={"primary"}
//           htmlType={"submit"}
//           style={{marginLeft: 5, fontWeight: 500, fontSize: 13}}
//           disabled={isProcessingFile || !analysedFile}
//         >
//           Next
//         </Button>
//       </div>
//     </div>
//   )
// }

const SecondStepSheetMetal = (props) => {
  const {item, materials, addNewPart, isAddingNewPart} = props;

  const findMaterial = (name) => {
    let rez;
    for(let material of materials) {
      rez = material.grades.find(type => type.grade === name)
      if(rez) {
        rez.groupName = material.groupName
        break;
      }
    }
    return rez
  }

  const getThickness = () => {
    if(item) {
      let rez = findMaterial(item.selectedMaterial?.grade)
      return rez?.thickness || []
    } else {
      return []
    }
  }

  const [thicknesses, setThicknesses] = useState(getThickness())
  const [coating, setCoating] = useState({
    hasCoating: false,
    colorRAL: "",
    colorGlossId: "",
    ralRow: "",
    ralHex: ""
  });

  const changePartColor = (data) => {
    setCoating({
      hasCoating: true,
      colorRAL: data.selectedRAL,
      ralRow: data.selectedRow,
      colorGlossId: data.selectedGloss,
      ralHex: data.ralHex
    })
  }

  const clearPartColor = () => {
    setCoating({
      hasCoating: false,
      colorRAL: "",
      colorGlossId: "",
      ralRow: "",
      ralHex: ""
    })
  }

  const changeThicknesses = (grade) => {
    let rez = findMaterial(grade)
    setThicknesses(rez?.thickness || [])
  }

  return (
    <div>
      <Form
        name={"manualQuoteOther"}
        onFinish={(values)=>{
          addNewPart({
            type: "sheetMetal",
            partId: item?.id,
            data: {
              ...values,
              hasCoating: coating.hasCoating,
              colorRAL: coating.colorRAL,
              colorGlossId: coating.colorGlossId,
              ralHex: coating.ralHex,
              uploadTime: Date.now(),
              uploadIndex: 1
            }
          })
        }}
      >
        <div style={{display: "flex"}}>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Name:</div>
            <Form.Item
              name={"name"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
              initialValue={item?.fileName || null}
            >
              <Input
                style={{width: 180, height: 32}}
                placeholder={'eg. Sheet Part'}
                disabled={!!item}
              />
            </Form.Item>
          </div>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Quantity:</div>
            <Form.Item
              name={"quantity"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
              initialValue={item ? item.quantity : 1}
            >
              <InputNumber
                min={1}
                max={1000000}
                style={{width: 150}}
              />
            </Form.Item>
          </div>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Price 1pc (€):</div>
            <Form.Item
              name={"pricePerPiece"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
              initialValue={0}
            >
              <InputNumber
                min={0}
                max={1000000}
                style={{width: 150}}
              />
            </Form.Item>
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Material:</div>
            <Form.Item
              name={"materialGrade"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
              initialValue={item?.selectedMaterial?.grade || null}
            >
              <Select
                suffixIcon={<CaretDownOutlined />}
                onChange={(e)=> {changeThicknesses(e)}}
                getPopupContainer={trigger => trigger.parentNode}
                virtual={false}
                style={{width: 200}}
                placeholder={"Select Material"}
              >
                {materials.map(material => {
                  return (
                    <OptGroup
                      key={material.groupName}
                      label={material.groupName}>
                      {material.grades.map(t => {
                        return (
                          <Option
                            key={t.grade}
                            value={t.grade}
                          >
                          <span className={"text12-600"}>
                            <Trans>{t.grade}</Trans>
                          </span>
                          </Option>
                        )
                      })}
                    </OptGroup>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Thickness:</div>
            <Form.Item
              name={"thickness"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
              initialValue={item?.selectedMaterial?.thickness || null}
            >
              <Select
                suffixIcon={<CaretDownOutlined />}
                getPopupContainer={trigger => trigger.parentNode}
                virtual={false}
                style={{width: 150}}
                disabled={thicknesses.length === 0}
                placeholder={"Select Thickness"}
              >
                {thicknesses.map(thick => {
                  return (
                    <Option
                      key={thick.id}
                      value={thick.thickness}
                    >
                      <span className={"text12-600"}>
                        {thick.thickness} mm
                      </span>
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <div style={{fontWeight: 500, fontSize: 13}}>Coating:</div>
            <div style={{display: 'flex'}}>
              <CoatingPicker
                partId={null}
                changeColor={changePartColor}
                hasCoating={coating.hasCoating}
                colorRAL={coating.colorRAL}
                colorGlossId={coating.colorGlossId}
                ralRow={coating.ralRow}
                ralHex={coating.ralHex}
                isMultiEdit={true}
              />
              {coating.hasCoating &&
                <IconButton
                  size={'small'}
                  aria-label="clear"
                  style={{marginLeft: 5, width: 25, height: 25, marginRight: -8, marginTop: 4}}
                  onClick={()=>{
                    clearPartColor()
                  }}
                >
                  <CloseIcon style={{ fontSize: 14, color: '#fc505a'}}/>
                </IconButton>
              }
            </div>
          </div>
        </div>
        <div align={"right"} style={{marginTop: 10}}>
          <Button
            type={"default"}
            onClick={()=>{props.setManualQuoteStep(1)}}
            style={{fontWeight: 500, fontSize: 13}}
            disabled={isAddingNewPart}
          >
            Back
          </Button>
          <Button
            type={"primary"}
            htmlType={"submit"}
            style={{marginLeft: 5, fontWeight: 500, fontSize: 13}}
            loading={isAddingNewPart}
          >
            {!!item ? "Quote Part" : "Add Part"}
          </Button>
        </div>
      </Form>
    </div>
  )
}

const SecondStepMachined = (props) => {
  const {item, materials, addNewPart, isAddingNewPart} = props;

  const [stepFile, setStepFile] = useState(null);
  const [coating, setCoating] = useState({
    hasCoating: false,
    colorRAL: "",
    colorGlossId: "",
    ralRow: "",
    ralHex: ""
  });

  const changePartColor = (data) => {
    setCoating({
      hasCoating: true,
      colorRAL: data.selectedRAL,
      ralRow: data.selectedRow,
      colorGlossId: data.selectedGloss,
      ralHex: data.ralHex
    })
  }

  const clearPartColor = () => {
    setCoating({
      hasCoating: false,
      colorRAL: "",
      colorGlossId: "",
      ralRow: "",
      ralHex: ""
    })
  }

  return (
    <div>
      <Form
        name={"manualQuoteOther"}
        onFinish={(values)=>{
          addNewPart({
            type: "machined",
            partId: item?.id,
            data: {
              ...values,
              hasCoating: coating.hasCoating,
              colorRAL: coating.colorRAL,
              colorGlossId: coating.colorGlossId,
              ralHex: coating.ralHex,
              uploadTime: Date.now(),
              uploadIndex: 1
            }
          })
        }}
      >
      <div style={{display: "flex"}}>
        <div style={{marginRight: 10}}>
          <div style={{fontWeight: 500, fontSize: 13}}>Name:</div>
          <Form.Item
            name={"name"}
            rules={[
              {required: true, message: 'This field is required'},
            ]}
            initialValue={item?.fileName || null}
          >
            <Input
              style={{width: 180, height: 32}}
              placeholder={'eg. N-2375-G'}
              disabled={!!item}
            />
          </Form.Item>
        </div>
        <div style={{marginRight: 10}}>
          <div style={{fontWeight: 500, fontSize: 13}}>Standard:</div>
          <Form.Item
            name={"standard"}
            rules={[
              {required: true, message: 'This field is required'},
            ]}
          >
            <Input
              style={{width: 180, height: 32}}
              placeholder={'eg. ISO 4762'}
            />
          </Form.Item>
        </div>
        <div>
          <div style={{fontWeight: 500, fontSize: 13}}>Quantity:</div>
          <Form.Item
            name={"quantity"}
            rules={[
              {required: true, message: 'This field is required'},
            ]}
            initialValue={item ? item.quantity : 1}
          >
            <InputNumber
              min={1}
              max={1000000}
              style={{width: 150}}
            />
          </Form.Item>
        </div>
      </div>
      <div style={{display: "flex"}}>
        <div style={{marginRight: 10}}>
          <div style={{fontWeight: 500, fontSize: 13}}>Material:</div>
          <Form.Item
            name={"materialGrade"}
            rules={[
              {required: true, message: 'This field is required'},
            ]}
            initialValue={item?.selectedMaterial?.grade || null}
          >
            <Select
              suffixIcon={<CaretDownOutlined />}
              onChange={(e)=> {console.log(e)}}
              getPopupContainer={trigger => trigger.parentNode}
              virtual={false}
              style={{width: 200}}
              placeholder={"Select Material"}
            >
              {materials.map(material => {
                return (
                  <OptGroup
                    key={material.groupName}
                    label={material.groupName}>
                    {material.grades.map(t => {
                      return (
                        <Option
                          key={t.grade}
                          value={t.grade}
                        >
                          <span className={"text12-600"}>
                            <Trans>{t.grade}</Trans>
                          </span>
                        </Option>
                      )
                    })}
                  </OptGroup>
                )
              })}
            </Select>
          </Form.Item>
        </div>
        <div style={{marginRight: 15}}>
          <div style={{fontWeight: 500, fontSize: 13}}>Coating:</div>
          <div style={{display: 'flex'}}>
            <CoatingPicker
              partId={null}
              changeColor={changePartColor}
              hasCoating={coating.hasCoating}
              colorRAL={coating.colorRAL}
              colorGlossId={coating.colorGlossId}
              ralRow={coating.ralRow}
              ralHex={coating.ralHex}
              isMultiEdit={true}
            />
            {coating.hasCoating &&
              <IconButton
                size={'small'}
                aria-label="clear"
                style={{marginLeft: 5, width: 25, height: 25, marginRight: -8, marginTop: 4}}
                onClick={()=>{
                  clearPartColor()
                }}
              >
                <CloseIcon style={{ fontSize: 14, color: '#fc505a'}}/>
              </IconButton>
            }
          </div>
        </div>
        <div>
          <div style={{fontWeight: 500, fontSize: 13}}>Price 1pc (€):</div>
          <Form.Item
            name={"pricePerPiece"}
            rules={[
              {required: true, message: 'This field is required'},
            ]}
            initialValue={0}
          >
            <InputNumber
              min={0}
              max={1000000}
              style={{width: 150}}
            />
          </Form.Item>
        </div>
        {/*<div style={{fontWeight: 500, fontSize: 13}}>File:</div>*/}
        {/*<span*/}
        {/*  className={"text14-400"}*/}
        {/*>*/}
        {/*  <Upload*/}
        {/*    showUploadList={false}*/}
        {/*    beforeUpload={(file)=> {*/}
        {/*      setStepFile(file)*/}
        {/*      return false;*/}
        {/*    }}*/}
        {/*    accept={".step,.stp"}*/}
        {/*  >*/}
        {/*    <Button*/}
        {/*      style={{border: "1px dashed", borderRadius: 5, height: 32, maxWidth: 270, display: "flex", alignItems: "center"}}*/}
        {/*    >*/}
        {/*      <div style={{display: "flex"}}>*/}
        {/*        <span className={"addDrawingText"}*/}
        {/*              style={{*/}
        {/*                maxWidth: 220,*/}
        {/*                whiteSpace: "nowrap",*/}
        {/*                overflow: "hidden",*/}
        {/*                textOverflow: "ellipsis",*/}
        {/*                alignSelf: "center"*/}
        {/*              }}*/}
        {/*        >*/}
        {/*          {stepFile ? stepFile.name : "Add Part STEP"}*/}
        {/*        </span>*/}
        {/*        {stepFile ?*/}
        {/*          <span*/}
        {/*            style={{marginLeft: 5, paddingLeft: 10, paddingRight: 10, color: "red"}}*/}
        {/*            onClick={(e)=>{*/}
        {/*              e.stopPropagation()*/}
        {/*              setStepFile(null)*/}
        {/*            }}*/}
        {/*          >*/}
        {/*             x*/}
        {/*          </span>*/}
        {/*          : null*/}
        {/*        }*/}
        {/*      </div>*/}

        {/*    </Button>*/}
        {/*  </Upload>*/}
        {/*</span>*/}
      </div>
      <div align={"right"} style={{marginTop: 10}}>
        <Button
          type={"default"}
          onClick={()=>{props.setManualQuoteStep(1)}}
          style={{fontWeight: 500, fontSize: 13}}
          disabled={isAddingNewPart}
        >
          Back
        </Button>
        <Button
          type={"primary"}
          htmlType={"submit"}
          style={{marginLeft: 5, fontWeight: 500, fontSize: 13}}
          loading={isAddingNewPart}
          // disabled={!stepFile}
        >
          {!!item ? "Quote Part" : "Add Part"}
        </Button>
      </div>
      </Form>
    </div>
  )
}

const SecondStepOther = (props) => {
  const {item, addNewPart,isAddingNewPart} = props;
  return (
    <div>
      <Form
        name={"manualQuoteOther"}
        onFinish={(values)=>{
          addNewPart({
            type: "other",
            partId: item?.id,
            data: {
              ...values,
              uploadTime: Date.now(),
              uploadIndex: 1
            }
          })
        }}
      >
        <div style={{display: "flex"}}>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Name:</div>
            <Form.Item
              name={"name"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
              initialValue={item?.fileName || null}
            >
              <Input
                style={{width: 180, height: 32}}
                placeholder={'eg. Screw M1.6'}
                disabled={!!item}
              />
            </Form.Item>
          </div>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Standard:</div>
            <Form.Item
              name={"standard"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
            >
              <Input
                style={{width: 180, height: 32}}
                placeholder={'eg. ISO 4762'}
              />
            </Form.Item>
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Quantity:</div>
            <Form.Item
              name={"quantity"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
              initialValue={item ? item.quantity : 1}
            >
              <InputNumber
                min={1}
                max={1000000}
                style={{width: 180}}
                placeholder={'Part quantity'}
              />
            </Form.Item>
          </div>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Price 1pc (€):</div>
            <Form.Item
              name={"pricePerPiece"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
              initialValue={0}
            >
              <InputNumber
                min={0}
                max={1000000}
                style={{width: 180}}
                placeholder={'Price per piece'}
              />
            </Form.Item>
          </div>
        </div>
        <div align={"right"} style={{marginTop: 10}}>
          <Button
            type={"default"}
            onClick={()=>{props.setManualQuoteStep(1)}}
            style={{fontWeight: 500, fontSize: 13}}
            disabled={isAddingNewPart}
          >
            Back
          </Button>
          <Button
            type={"primary"}
            htmlType={"submit"}
            style={{marginLeft: 5, fontWeight: 500, fontSize: 13}}
            loading={isAddingNewPart}
          >
            {!!item ? "Quote Part" : "Add Part"}
          </Button>
        </div>
      </Form>
    </div>
  )
}

const SecondStepProfile = (props) => {
  const {item, materials, addNewPart, isAddingNewPart} = props;
  return (
    <div>
      <Form
        name={"manualQuoteProfile"}
        onFinish={(values)=>{
          addNewPart({
            type: "profile",
            partId: item?.id,
            data: {
              ...values,
              uploadTime: Date.now(),
              uploadIndex: 1
            }
          })
        }}
      >
        <div style={{display: "flex"}}>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Name:</div>
            <Form.Item
              name={"name"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
              initialValue={item?.fileName || null}
            >
              <Input
                style={{width: 180, height: 32}}
                placeholder={'eg. Flat Bar'}
                disabled={!!item}
              />
            </Form.Item>
          </div>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Standard:</div>
            <Form.Item
              name={"standard"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
            >
              <Input
                style={{width: 180, height: 32}}
                placeholder={'eg. ISO 6929'}
              />
            </Form.Item>
          </div>
          <div>
            <div style={{fontWeight: 500, fontSize: 13}}>Material:</div>
            <Form.Item
              name={"materialGrade"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
              initialValue={item?.selectedMaterial?.grade || null}
            >
              <Select
                suffixIcon={<CaretDownOutlined />}
                getPopupContainer={trigger => trigger.parentNode}
                virtual={false}
                style={{width: 200}}
                placeholder={"Select Material"}
              >
                {materials.map(material => {
                  return (
                    <OptGroup
                      key={material.groupName}
                      label={material.groupName}>
                      {material.grades.map(t => {
                        return (
                          <Option
                            key={t.grade}
                            value={t.grade}
                          >
                            <span className={"text12-600"}>
                              <Trans>{t.grade}</Trans>
                            </span>
                          </Option>
                        )
                      })}
                    </OptGroup>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Quantity:</div>
            <Form.Item
              name={"quantity"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
              initialValue={item ? item.quantity : 1}
            >
              <InputNumber
                min={1}
                max={1000000}
                style={{width: 180}}
                placeholder={'Part quantity'}
              />
            </Form.Item>
          </div>
          <div style={{marginRight: 10}}>
            <div style={{fontWeight: 500, fontSize: 13}}>Price 1pc (€):</div>
            <Form.Item
              name={"pricePerPiece"}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
              initialValue={0}
            >
              <InputNumber
                min={0}
                max={1000000}
                style={{width: 180}}
                placeholder={'Price per piece'}
              />
            </Form.Item>
          </div>
        </div>
        <div align={"right"} style={{marginTop: 10}}>
          <Button
            type={"default"}
            onClick={()=>{props.setManualQuoteStep(1)}}
            style={{fontWeight: 500, fontSize: 13}}
            disabled={isAddingNewPart}
          >
            Back
          </Button>
          <Button
            type={"primary"}
            htmlType={"submit"}
            style={{marginLeft: 5, fontWeight: 500, fontSize: 13}}
            loading={isAddingNewPart}
          >
            {!!item ? "Quote Part" : "Add Part"}
          </Button>
        </div>
      </Form>
    </div>
  )
}

let dragging = 0;
let canUpload = false;

export const OrderDetailsManualQuote = (props) => {

  const {
    isManualQuoteModalVisible,
    item, handleClose, addNewPart,
    materials, isAddingNewPart
  } = props

  const [manualQuoteStep, setManualQuoteStep] = useState(1)
  const [manualQuoteType, setManualQuoteType] = useState(item ? item.partType : "sheetMetal")
  const [fileData, setFileData] = useState(null)
  const [isProcessingFile, setIsProcessingFile] = useState(false)

  const handleModalClose = () => {
    handleClose()
  }
  //
  // const handleDragEnter = e => {
  //   dragging++
  //   e.stopPropagation();
  //   e.preventDefault();
  //
  //   let dt = e.dataTransfer;
  //   if (dt.types != null && ((dt.types.length && (dt.types[0] === 'Files' || dt.types[0] === 'application/x-moz-file')))) {
  //     let dropper = document.getElementsByClassName("fileDropZone")[0]
  //     if(dropper.style.display === "none" && canUpload) {
  //       dropper.style.display = "block"
  //     }
  //   }
  //   return false
  // };
  //
  // const handleDragLeave = e => {
  //   dragging--;
  //   if (dragging === 0) {
  //     document.getElementsByClassName("fileDropZone")[0].style.display = "none"
  //   }
  //
  //   e.stopPropagation();
  //   e.preventDefault();
  //   return false
  // };
  //
  // const handleDrop = e => {
  //   e.preventDefault();
  //   dragging=0
  //   document.getElementsByClassName("fileDropZone")[0].style.display = "none"
  // };
  //
  // useEffect(() => {
  //   document.addEventListener("dragenter", handleDragEnter);
  //   document.addEventListener("dragleave", handleDragLeave);
  //   document.addEventListener("drop", handleDrop, false);
  //   return () => {
  //     document.removeEventListener("dragenter", handleDragEnter);
  //     document.removeEventListener("dragleave", handleDragLeave);
  //     document.removeEventListener("drop", handleDrop, false);
  //   }
  // }, [])

  useEffect(() => {
    setManualQuoteType(item ? item.partType : "sheetMetal" )
  }, [item])

  useEffect(() => {
    if(isManualQuoteModalVisible) {
      setManualQuoteStep(1)
      setManualQuoteType(item ? item.partType : "sheetMetal")
    }
  }, [isManualQuoteModalVisible])

  useEffect(() => {
    canUpload = isManualQuoteModalVisible && manualQuoteStep === 2 && manualQuoteType === "sheetMetal";
  }, [isManualQuoteModalVisible, manualQuoteStep, manualQuoteType])

  const step = () => {
    switch(manualQuoteStep) {

      case 1:   return <FirstStep setManualQuoteType={setManualQuoteType}
                                  manualQuoteType={manualQuoteType}
                                  setManualQuoteStep={setManualQuoteStep}
                        />;
      case 2:   return itemType()
      case 3: return <div />;

      default:      return <div>Error</div>
    }
  }

  const itemType = () => {
    switch(manualQuoteType) {

      case "sheetMetal":   return <SecondStepSheetMetal item={item}
                                                        addNewPart={addNewPart}
                                                        materials={materials}
                                                        fileData={fileData}
                                                        setManualQuoteStep={setManualQuoteStep}
                                                        processingFile={isProcessingFile}
                                                        setFileData={setFileData}
                                                        isAddingNewPart={isAddingNewPart}
      />;

      case "profile": return <SecondStepProfile item={item}
                                                addNewPart={addNewPart}
                                                materials={materials}
                                                setManualQuoteStep={setManualQuoteStep}
                                                isAddingNewPart={isAddingNewPart}
      />;

      case "machined":   return <SecondStepMachined item={item}
                                                    addNewPart={addNewPart}
                                                    materials={materials}
                                                    setManualQuoteStep={setManualQuoteStep}
                                                    isAddingNewPart={isAddingNewPart}
      />;

      case "assembly": return <div />;

      case "other": return <SecondStepOther item={item}
                                            addNewPart={addNewPart}
                                            setManualQuoteStep={setManualQuoteStep}
                                            isAddingNewPart={isAddingNewPart}
      />;

      default:      return <div>Error</div>
    }
  }

  const getWidth = () => {
    switch(manualQuoteStep) {

      case 1:   return 400;
      case 2:   return manualQuoteType === "sheetMetal" || manualQuoteType === "machined" ? 680 : 640;
      case 3:   return 400;

      default:  return 400;
    }
  }

  return (
    <div>
      <Modal
        visible={isManualQuoteModalVisible}
        centered={true}
        onCancel={handleModalClose}
        title="Manual Quote"
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        width={getWidth()}
      >
        { step() }
      </Modal>
      {/*<Dragger*/}
      {/*  className={"fileDropZone"}*/}
      {/*  name = {'file'}*/}
      {/*  beforeUpload={(file) => {*/}
      {/*    setIsProcessingFile(true)*/}
      {/*    setTimeout(() => {*/}
      {/*      setFileData(resOfAnalysis)*/}
      {/*      setIsProcessingFile(false)*/}
      {/*    }, 1000)*/}
      {/*    return false;*/}
      {/*  }}*/}
      {/*  multiple={false}*/}
      {/*  showUploadList={false}*/}
      {/*  style={{*/}
      {/*    display: 'none',*/}
      {/*    position: 'fixed',*/}
      {/*    margin: 5,*/}
      {/*    top: 0,*/}
      {/*    left: 0,*/}
      {/*    width: 'calc(100% - 12px)',*/}
      {/*    maxHeight: 'calc(100vh - 12px)',*/}
      {/*    background: "rgba(0.5,0.5,0.5,0.2)",*/}
      {/*    border: "2px dashed #066fc1",*/}
      {/*    animationDuration: "0.5s",*/}
      {/*    zIndex: 2000*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div align={'center'}>*/}
      {/*    <Card*/}
      {/*      style={{*/}
      {/*        borderRadius: "5px",*/}
      {/*        background: "#2076ba",*/}
      {/*        border: "0px",*/}
      {/*        maxWidth: "300px"*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <img src={dragndropwhite} className={"drop-file-icon"} alt={"file"} />*/}
      {/*      <span className={"text14-600"} style={{color: "white", marginLeft: 15, fontSize: 16}}>*/}
      {/*          Drop your files here*/}
      {/*        </span>*/}
      {/*    </Card>*/}
      {/*  </div>*/}
      {/*</Dragger>*/}
    </div>

  )
}