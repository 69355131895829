import React, {Fragment, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Table, Layout, Row, Col, Button, Tag, Input, Divider} from 'antd'
import { Sidebar } from '../../../components'
import { logoutUser } from '../../../actions/authActions'
import {LoadingOutlined} from "@ant-design/icons";
import {fetchCustomerPendingOrders} from "../../../actions/ordersActions";
import format from "date-fns/format";
import {formatTableDate, getColumnSearchProps} from "../../../utils/utility";
import history from "../../../utils/history";

export const CustomerPendingPage = (props) => {
  let searchInput = null;

  const user = useSelector(state => state.auth.user)
  const isFetchingPendingOrders = useSelector(state => state.orders.isFetchingCustomerPendingOrders)
  const pendingOrders = useSelector(state => state.orders.customerPendingOrders)
  const pagination = useSelector(state => state.orders.customerPendingOrdersPagination)
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const dispatch = useDispatch()

  const getPageSize = () => {
    const count = Math.floor((window.innerHeight - 150) / 50)
    return count > 10 ? count : 10;
  }

  const pageSize = getPageSize();

  useEffect(() => {
    dispatch(fetchCustomerPendingOrders({
      sortField: "orderDate",
      sortDirection: "desc",
      orderName: "",
      page: pagination.current !== undefined ? pagination.current : 1,
      pagination: {...pagination, pageSize: pageSize}
    }))
  }, [])

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    const sortField = sorter.order ? sorter.columnKey : "orderDate"
    const sortDirection = sorter.order ? (sorter.order === "ascend" ? "asc" : "desc") : "desc"
    const orderName = filters.orderName ? filters.orderName[0] : ""


    dispatch(fetchCustomerPendingOrders({
      sortField: sortField,
      sortDirection: sortDirection,
      orderName: orderName,
      page: pagination.current,
      pagination: pager,
    }))
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <Sidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{overflowX: "hidden"}}>
          <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
            <Row type="flex">
              <Col span={24}>
                <div style={{ height: 32, display: "flex", alignItems: "center" }}>
                  <div style={{fontWeight: 500, fontSize: 16}}>These are your pending orders:</div>
                </div>
                <Divider style={{marginTop: 6, marginBottom: 11}}/>
              </Col>
            </Row>
            <Row type="flex">
              <Col span={24}>
                <Fragment>
                  <Table
                    columns={[
                      {
                        title: 'Order Code',
                        key: 'orderName',
                        width: "18%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.orderName}
                          </div>
                        ),
                        ...getColumnSearchProps('code', searchInput),
                      },
                      {
                        title: 'Custom Order Name',
                        key: 'customName',
                        className: "darkerColumn",
                        width: "18%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.customName || "-"}
                          </div>
                        ),
                      },
                      {
                        title: 'Order date',
                        key: 'orderDate',
                        width: "12%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {formatTableDate(row.orderDate)}
                          </div>
                        ),
                        sorter: true,
                      },
                      {
                        title: 'Delivery date',
                        key: 'deliveryDate',
                        className: "darkerColumn",
                        width: "12%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {formatTableDate(row.deliveryDate)}
                          </div>
                        ),
                        sorter: true,
                      },
                      {
                        title: 'Total with VAT',
                        key: 'totalWithVat',
                        width: "12%",
                        align: "right",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.state === "quoted" ?
                              `${(row.totalPrice * (1 + row.vatValue) || row.totalPrice || 0).toFixed(2)}€`
                              :
                              row.totalPrice ? `${(row.totalPrice * (1 + row.vatValue) || row.totalPrice || 0).toFixed(2)}€ + RFQ` : "RFQ"
                            }
                          </div>
                        ),
                      },
                      {
                        title: 'Status',
                        key: 'stateName',
                        className: "darkerColumn column200",
                        width: "200px",
                        align: "center",
                        render: row => (
                          <div>
                            <React.Fragment>
                              <Tag color="#f0bd27" style={{fontWeight: 600, textAlign: "center"}}>Pending</Tag>
                              {row.state === "quoted" ?
                                <Tag color="#808080" style={{fontWeight: 600, textAlign: "center", width: 78, marginRight: 0}}>Quoted</Tag>
                                :
                                <Tag color="#b60a1c" style={{fontWeight: 600, textAlign: "center", width: 78, marginRight: 0}}>Unquoted</Tag>
                              }
                            </React.Fragment>
                          </div>
                        ),
                      },
                      {
                        title: '',
                        key: 'more',
                        align: "right",
                        render: row => (
                          <Button
                            onMouseDown={(e) => {
                              if (e.button === 1) {
                                const win = window.open(`/pending-order?order=${row.id}`, "_blank");
                                win.focus();
                              } else if (e.button === 0) {
                                history.push(`/pending-order?order=${row.id}`)
                              }
                            }}
                            type="primary"
                            style={{
                              fontSize: 13,
                              fontWeight: 600
                            }}
                          >
                            Details
                          </Button>
                        ),
                      },
                    ]}
                    className={"pendingOrdersTable"}
                    dataSource={pendingOrders}
                    rowKey="id"
                    pagination={{...pagination, pageSize: pageSize}}
                    loading={{spinning: isFetchingPendingOrders, indicator: <LoadingOutlined style={{ fontSize: 50,marginLeft: "-25px" }} spin />}}
                    onChange={handleTableChange}
                    size={"small"}
                    scroll={{x: '70%'}}
                  />
                </Fragment>
              </Col>
            </Row>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}
