import {
  CUSTOMER_BLOCK_CUSTOMER,
  CUSTOMER_FETCHING_CUSTOMER_DETAILS,
  CUSTOMER_REMOVE_CUSTOMER,
  CUSTOMER_SET_IS_REMOVING_CUSTOMER,
  CUSTOMER_TOGGLING_BLOCK,
  CUSTOMERS_CUSTOMERS,
  CUSTOMERS_CUSTOMERS_PAGINATION,
  CUSTOMERS_FETCHING_CUSTOMERS,
} from './actionTypes'
import {API_BASE_URL} from '../constants'
import {http, message} from '../utils'

const fetchCustomers = payload => dispatch => {
  dispatch({
    type: CUSTOMERS_FETCHING_CUSTOMERS,
    payload: true,
  })
  return http
    .get(`${API_BASE_URL}/regular-customer?pageRowCount=6&pageIndex=${payload.page}&email=${payload.email}&name=${payload.name}`)
    .then(response => {
      const pagination = {...payload.pagination};

      pagination.total = response.data.totalCount;

      dispatch({
        type: CUSTOMERS_CUSTOMERS_PAGINATION,
        payload: pagination,
      })
      dispatch({
        type: CUSTOMERS_CUSTOMERS,
        payload: response.data.regularCustomers,
      })
      dispatch({
        type: CUSTOMERS_FETCHING_CUSTOMERS,
        payload: false,
      })
    })
    .catch(error => {
      if (error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {

      } else {
        dispatch({
          type: CUSTOMERS_FETCHING_CUSTOMERS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchCustomerDetails = payload => dispatch => {
  dispatch({
    type: CUSTOMER_FETCHING_CUSTOMER_DETAILS,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/regular-customer/${payload.id}`)
    .then(response => {

      dispatch({
        type: CUSTOMER_FETCHING_CUSTOMER_DETAILS,
        payload: false
      })

      return response
    })
    .catch(error => {
      dispatch({
        type: CUSTOMER_FETCHING_CUSTOMER_DETAILS,
        payload: false
      })
      message.show(message.type.error, http.getErrorMessage(error))
    })
}

const removeCustomer = payload => dispatch => {
  dispatch({
    type: CUSTOMER_SET_IS_REMOVING_CUSTOMER,
    payload: true
  })

  return http
    .delete(`${API_BASE_URL}/regular-customer/${payload.id}`)
    .then(response => {
      dispatch({
        type: CUSTOMER_REMOVE_CUSTOMER,
        payload: payload.id,
      })
      dispatch({
        type: CUSTOMER_SET_IS_REMOVING_CUSTOMER,
        payload: false
      })
    })
    .catch(error => {
      dispatch({
        type: CUSTOMER_SET_IS_REMOVING_CUSTOMER,
        payload: false
      })
      message.show(message.type.error, http.getErrorMessage(error))
    })
}

const toggleCustomerBlock = payload => dispatch => {
  dispatch({
    type: CUSTOMER_TOGGLING_BLOCK,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/regular-customer/block/${payload.id}`, {isBlocked: payload.isBlocked})
    .then(response => {
      dispatch({
        type: CUSTOMER_BLOCK_CUSTOMER,
        payload: payload.id
      })
      dispatch({
        type: CUSTOMER_TOGGLING_BLOCK,
        payload: false
      })
    })
    .catch(error => {
      message.show(message.type.error, http.getErrorMessage(error))
      dispatch({
        type: CUSTOMER_TOGGLING_BLOCK,
        payload: false
      })
    })
}

const updateCustomer = payload => dispatch => {
  return http
    .put(`${API_BASE_URL}/regular-customer`, payload)
    .then(response => {
      console.log(response)
    })
    .catch(error => {
      message.show(message.type.error, http.getErrorMessage(error))
    })
}

export {
  fetchCustomers, fetchCustomerDetails,
  removeCustomer, toggleCustomerBlock, updateCustomer
}