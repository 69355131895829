import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, Tabs, Form, Button, Modal, Spin, Input, Empty} from 'antd';

import MaterialTableContainer from "../../MaterialTableContainer";
import ModalTable from "../../MaterialTables/ModalTable";
import CheckboxTable from "../../MaterialTables/CheckboxTable";
import ValueTable from "../../MaterialTables/ValueTable";

import {DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { useWindowSize } from "../../../../../utils/utility";
import { lineBreakLimit } from "../../../../../constants";
import {Checkbox} from "antd/es";
import {DeletionModal} from "../../../../DeletionModal";
import {
  fetchAvailableMaterials,
  updateAvailableMaterials,
  fetchMaterialPrices,
  fetchSingleMaterialPrices,
  updateMaterialPrice,
  fetchMaterialPlates,
  fetchSingleMaterialPlates,
  fetchSingleMaterialPlatesData,
  resetMaterialPlatesData,
  addNewPlate,
  editPlate,
  deletePlates
} from "../../../../../actions/materialsActions"
import {Trans} from "react-i18next";

const { TabPane } = Tabs;

export const MaterialsTab = (props) => {
  const { selectedTab, socketConnected } = props

  const [mainModalVisible, setMainModalVisible] = useState(false);
  const [selectedFieldData, setSelectedFieldData] = useState({});

  const [editPlateModalVisible, setEditPlateModalVisible] = useState(false);
  const [deletePlateModalVisible, setDeletePlateModalVisible] = useState(false);
  const [addPlateModalVisible, setAddPlateModalVisible] = useState(false);
  const [currentPlate, setCurrentPlate] = useState({});

  const [multiDeleteMode, setMultiDeleteMode] = useState(false);
  const [multiDeleteSelection, setMultiDeleteSelection] = useState([]);

  const [shouldUpdatePrices, setShouldUpdatePrices] = useState(false)
  const [shouldUpdateAvailability, setShouldUpdateAvailability] = useState(false)

  const [activeLoadingValueTabs, setActiveLoadingValueTabs] = useState({})

  const [shouldUpdatePlates, setShouldUpdatePlates] = useState(false)
  const [activeLoadingPlateTabs, setActiveLoadingPlateTabs] = useState({})

  const [editPlateForm] = Form.useForm();
  const [addPlateForm] = Form.useForm();

  const user = useSelector(state => state.auth.user);

  const availableMaterials = useSelector(state => state.materials.availableMaterials)
  const isFetchingAvailableMaterials = useSelector(state => state.materials.isFetchingAvailableMaterials)

  const materialPrices = useSelector(state => state.materials.materialPrices)
  const isFetchingMaterialPrices = useSelector(state => state.materials.isFetchingMaterialPrices)

  const materialPlates = useSelector(state => state.materials.materialPlates)
  const isFetchingMaterialPlates = useSelector(state => state.materials.isFetchingMaterialPlates)

  const materialPlatesData = useSelector(state => state.materials.materialPlatesData)
  const isFetchingSingleMaterialPlates = useSelector(state => state.materials.isFetchingSingleMaterialPlates)

  const isAddingNewPlate = useSelector(state => state.materials.isAddingNewPlate)
  const isEditingPlate = useSelector(state => state.materials.isEditingPlate)
  const isRemovingPlate = useSelector(state => state.materials.isRemovingPlate)

  const dispatch = useDispatch()

  const windowSize = useWindowSize();

  const hasNestingFunction = user?.producer?.hasNestingFunction;

  useEffect(()=>{
    if(selectedTab === "6") {
      dispatch(fetchAvailableMaterials()).then(()=>{
        setShouldUpdateAvailability(true)
      })
      dispatch(fetchMaterialPrices()).then(()=>{
        setShouldUpdatePrices(true)
      })
      dispatch(fetchMaterialPlates()).then(()=>{
        setShouldUpdatePlates(true)
      })
    }
  }, [selectedTab])

  const setupMainModal = (params) => {
    setSelectedFieldData({
      materialGrade: params.materialGrade,
      materialThickness: params.materialThickness,
      platesMaterialId: params.materialId,
      platesGroupName: params.groupName,
      tab: params.tab
    })

    dispatch(fetchSingleMaterialPlatesData({materialId: params.materialId}))

    setMainModalVisible(true);
  }

  const handleMainModalClose = () => {
    setMainModalVisible(false);
    setMultiDeleteMode(false);
    setMultiDeleteSelection([]);
    dispatch(resetMaterialPlatesData())
  }

  const handleEditPlateModalVisible = (item) => {
    setCurrentPlate({
      ...item
    })
    editPlateForm.setFieldsValue({
      width: item.width,
      height: item.height
    })
    setEditPlateModalVisible(true);
  }

  const handleEditPlateModalClose = () => {
    editPlateForm.resetFields()
    setCurrentPlate({});
    setEditPlateModalVisible(false);
  }

  const handleAddPlateModalClose = () => {
    addPlateForm.resetFields()
    setAddPlateModalVisible(false);
  }

  const handleMultiDeleteCheck = (item) => {
    if (multiDeleteSelection.includes(item.id)) {
      setMultiDeleteSelection(multiDeleteSelection.filter(elem => elem !== item.id));
    } else {
      setMultiDeleteSelection(prevState => [...prevState, item.id])
    }
  }

  const handleMultiPlateDelete = () => {
    dispatch(deletePlates({materialId: selectedFieldData.platesMaterialId, plates: multiDeleteSelection})).then(()=>{
      setMultiDeleteMode(false);
      setMultiDeleteSelection([]);
      setActiveLoadingPlateTabs(prevState => ({...prevState, [selectedFieldData.tab]: true}))
      dispatch(fetchSingleMaterialPlates({groupName: selectedFieldData.platesGroupName})).then(()=>{
        setActiveLoadingPlateTabs(prevState => ({...prevState, [selectedFieldData.tab]: false}))
      })
    })
  }

  const handleSinglePlateDelete = (item) => {
    dispatch(deletePlates({materialId: selectedFieldData.platesMaterialId, plates: [item.id]})).then(()=>{
      setDeletePlateModalVisible(false)
      setActiveLoadingPlateTabs(prevState => ({...prevState, [selectedFieldData.tab]: true}))
      dispatch(fetchSingleMaterialPlates({groupName: selectedFieldData.platesGroupName})).then(()=>{
        setActiveLoadingPlateTabs(prevState => ({...prevState, [selectedFieldData.tab]: false}))
      })
    })
  }

  const submitEditPlateForm = (values) => {
    if(Number(values.width) === Number(currentPlate.width) && Number(values.height) === Number(currentPlate.height)) {
      handleEditPlateModalClose()
    }
    else {
      dispatch(editPlate({
        materialId: selectedFieldData.platesMaterialId,
        width: Number(values.width),
        height: Number(values.height),
        plateId: currentPlate.id
      })).then(()=>{
        handleEditPlateModalClose()
        dispatch(fetchSingleMaterialPlatesData({materialId: selectedFieldData.platesMaterialId}))
      }).catch(()=>{})
    }
  }

  const submitAddPlateForm = (values) => {
    dispatch(addNewPlate({
      materialId: selectedFieldData.platesMaterialId,
      width: Number(values.width),
      height: Number(values.height)
    })).then(()=>{
      handleAddPlateModalClose()
      dispatch(fetchSingleMaterialPlatesData({materialId: selectedFieldData.platesMaterialId}))
      setActiveLoadingPlateTabs(prevState => ({...prevState, [selectedFieldData.tab]: true}))
      dispatch(fetchSingleMaterialPlates({groupName: selectedFieldData.platesGroupName})).then(()=>{
        setActiveLoadingPlateTabs(prevState => ({...prevState, [selectedFieldData.tab]: false}))
      })
    }).catch(()=>{})
  }

  const changeAvailability = (params) => {
    props.addMaterialToStock({materialId: params.materialId, available: params.value})
    dispatch(updateAvailableMaterials(params))

    setActiveLoadingValueTabs(prevState => ({...prevState, [params.tab]: true}))
    dispatch(fetchSingleMaterialPrices({groupName: params.groupName})).then(()=>{
      setActiveLoadingValueTabs(prevState => ({...prevState, [params.tab]: false}))
    })

    setActiveLoadingPlateTabs(prevState => ({...prevState, [params.tab]: true}))
    dispatch(fetchSingleMaterialPlates({groupName: params.groupName})).then(()=>{
      setActiveLoadingPlateTabs(prevState => ({...prevState, [params.tab]: false}))
    })
  }

  const changePrice = (params) => {
    if(!activeLoadingValueTabs[params.tab]) {
      props.changeMaterialPrice({materialId: params.materialId, price: params.value})
      dispatch(updateMaterialPrice(params))
    }
    return Promise.resolve(10)
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"} bodyStyle={{paddingRight: 0, paddingBottom: 10, paddingTop: 15}}>
        <div style={{height: 'calc(100vh - 97px)', overflowY: (availableMaterials.length === 0 || materialPrices.length === 0 || materialPlates.length === 0) ? "hidden" : "auto", overflowX: "hidden", paddingRight: 15}}>
          <Form
            layout={'horizontal'}
          >
            <div className={windowSize.width >= lineBreakLimit ? "inLine" : "newLine"}>
              <div className={"customColumn"}>
                <div style={{maxWidth: 1220}}>
                  <Spin spinning={isFetchingAvailableMaterials || !socketConnected}
                        style={{zIndex: 1}}
                        indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
                  >
                    {availableMaterials.length === 0 ?
                      <Card bordered={false}
                            bodyStyle={{border: "1px solid #f0f0f0", borderRadius: 5, height: 394}}
                            style={{marginBottom: 20, borderRadius: 5}}
                      >

                      </Card>
                      :
                      <Tabs style={{maxWidth: 1220, marginLeft: -10, marginBottom: 15}}
                            type="card"
                            className={"laserSpeedTabs"}
                      >
                        {availableMaterials.map((item, index) => (
                          <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                            <MaterialTableContainer tableText={"Add material to stock"}
                                                    xAxisText={"Available Thickness (mm)"}
                                                    yAxisText={"Material Grade"}
                                                    xAxisStyle={{marginTop: 12, marginBottom: -12}}
                            >
                              <CheckboxTable
                                columns={item.columns}
                                rows={item.rows}
                                allAvailable={item.allAvailable}
                                tabIndex={index}
                                onChange={changeAvailability}
                                updateTable={shouldUpdateAvailability}
                                setUpdateTable={setShouldUpdateAvailability}
                                groupName={item.groupName}
                              />
                            </MaterialTableContainer>
                          </TabPane>
                        ))}
                      </Tabs>
                    }
                  </Spin>
                </div>
              </div>
              <div className={"customColumn"}>
                <div style={{maxWidth: 1220}}>
                  <Spin spinning={isFetchingMaterialPrices || !socketConnected}
                        style={{zIndex: 1}}
                        indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
                  >
                  {materialPrices.length === 0 ?
                    <Card bordered={false}
                          bodyStyle={{border: "1px solid #f0f0f0", borderRadius: 5, height: 394, maxWidth: 880}}
                          style={{marginBottom: 20, borderRadius: 5, maxWidth: 880}}
                    >

                    </Card>
                    :
                    <Tabs style={{maxWidth: 1220, marginLeft: -10, marginBottom: 15}}
                          type="card"
                          className={"laserSpeedTabs"}
                    >
                      {materialPrices.map((item, index) => {
                        return (
                          <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                            <MaterialTableContainer
                              tableText={"Material Price (€/kg)"}
                              xAxisText={"Available Thickness (mm)"}
                              yAxisText={"Material Grade"}
                              xAxisStyle={{marginTop: 12, marginBottom: -12}}
                              // additionalLeft={
                              //   <Button
                              //     type="primary"
                              //     style={{borderRadius: 3, paddingLeft: 10, paddingRight: 10}}
                              //   >
                              //     <span style={{fontSize: 12, fontWeight: 500}}>Multi Edit</span>
                              //     <EditOutlined style={{marginLeft: 6}}/>
                              //   </Button>
                              // }
                            >
                              <ValueTable
                                columns={item.columns}
                                rows={item.rows}
                                allAvailable={item.allAvailable}
                                tabIndex={index}
                                onChange={changePrice}
                                updateTable={shouldUpdatePrices}
                                setUpdateTable={setShouldUpdatePrices}
                                activeLoadingTabs={activeLoadingValueTabs}
                              />
                            </MaterialTableContainer>
                          </TabPane>
                        )
                      })}
                    </Tabs>
                  }
                  </Spin>
                </div>
              </div>
            </div>
            <div className={windowSize.width >= lineBreakLimit ? "inLine" : "newLine"}>
              {hasNestingFunction &&
                <div className={"customColumn"}>
                  <div style={{maxWidth: 1220}}>
                    <Spin spinning={isFetchingMaterialPlates || !socketConnected}
                          style={{zIndex: 1}}
                          indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
                    >
                      {materialPlates.length === 0 ?
                        <Card bordered={false}
                              bodyStyle={{border: "1px solid #f0f0f0", borderRadius: 5, height: 394, maxWidth: 880}}
                              style={{marginBottom: 20, borderRadius: 5, maxWidth: 880}}
                        >

                        </Card>
                        :
                        <Tabs style={{maxWidth: 1220, marginLeft: -10}}
                              type="card"
                              className={"laserSpeedTabs"}
                        >
                          {materialPlates.map((item, index) => (
                            <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                              <MaterialTableContainer
                                tableText={"Available Material Sheets"}
                                xAxisText={"Available Thickness (mm)"}
                                yAxisText={"Material Grade"}
                                xAxisStyle={{marginTop: 12, marginBottom: -12}}
                                // additionalLeft={
                                //   <Button
                                //     type="primary"
                                //     style={{borderRadius: 3, paddingLeft: 10, paddingRight: 8}}
                                //   >
                                //     <span style={{fontSize: 12, fontWeight: 500}}>Multi Add</span>
                                //     <AddSharpIcon style={{fontSize: 18, marginLeft: 3, marginTop: -9, marginBottom: -4, marginRight: 0}}/>
                                //   </Button>
                                // }
                                // additionalRight={
                                //   <Button
                                //     type="default"
                                //     danger
                                //     style={{borderRadius: 3, paddingLeft: 10, paddingRight: 10}}
                                //   >
                                //     <span style={{fontSize: 12, fontWeight: 500}}>Multi Remove</span>
                                //     <DeleteOutlined/>
                                //   </Button>
                                // }
                              >
                                <ModalTable
                                  columns={item.columns}
                                  rows={item.rows}
                                  tabIndex={index}
                                  allAvailable={item.allAvailable}
                                  onClick={setupMainModal}
                                  groupName={item.groupName}
                                  updateTable={shouldUpdatePlates}
                                  setUpdateTable={setShouldUpdatePlates}
                                  activeLoadingTabs={activeLoadingPlateTabs}
                                />
                              </MaterialTableContainer>
                            </TabPane>
                          ))}
                        </Tabs>
                      }
                    </Spin>
                  </div>
                </div>
              }
            </div>
          </Form>
        </div>
      </Card>
      <Modal visible={mainModalVisible}
             onCancel={handleMainModalClose}
             title={"Available material sheets"}
             footer={<div style={{ height: 20 }} />}
             centered={true}
             bodyStyle={{ height: 660 }}
             zIndex={1}
             width={550}
      >
        <React.Fragment>
          <div style={{ fontWeight: 500 }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <div>Material Grade: <Trans>{selectedFieldData.materialGrade}</Trans></div>
                <div>Material Thickness: {selectedFieldData.materialThickness} mm</div>
              </div>
              <div>
                {multiDeleteMode ?
                  (
                    <div style={{ display: "flex", flexDirection: "row"}}>
                      <Button
                              type={"danger"}
                              style={{ marginRight: 6 }}
                              onClick={() => handleMultiPlateDelete()}
                              disabled={multiDeleteSelection.length === 0 || isRemovingPlate}
                      >
                        <span className={"inputInfoText"}>Delete</span>
                      </Button>
                      <Button onClick={() => {
                                setMultiDeleteMode(false)
                                setMultiDeleteSelection([]);
                              }}
                              disabled={isRemovingPlate}
                      >
                        <span className={"inputInfoText"}>Cancel</span>
                      </Button>
                    </div>
                  )
                :
                  (
                    <div>
                      <Button type={"primary"} style={{ marginRight: 6 }}
                              onClick={() => {setAddPlateModalVisible(true)}}
                      >
                        <span className={"inputInfoText"}>Add sheet</span>
                      </Button>
                      <Button type={"danger"} onClick={() => {
                        setMultiDeleteMode(prevState => !prevState)
                      }}>
                        <span className={"inputInfoText"}>Delete multiple</span>
                      </Button>
                    </div>
                  )}
              </div>
            </div>
            <Spin spinning={isFetchingSingleMaterialPlates || isRemovingPlate}
                  indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-25%, -50%)" }} spin />}
            >
              <div className={"modalDataContainer"}>
                {materialPlatesData.length === 0 ?
                  (
                    <div style={{position: "relative", top: "25%"}}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Sheets"} />
                    </div>
                  )
                  :
                  (
                    <React.Fragment>
                      {materialPlatesData.map(item => (
                        <div className={"checkboxStyle"} key={item.id}>
                          {multiDeleteMode && <div className={"multiDeleteCheckbox"}>
                            <Checkbox onChange={() => handleMultiDeleteCheck(item)}/>
                          </div>}
                          <Card className={"bendCard"}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <div>
                                  <div>Length: {item.width} mm</div>
                                  <div>Width: {item.height} mm</div>
                                </div>
                              </div>
                              <div style={{display: "flex"}}>
                                <Button onClick={() => {handleEditPlateModalVisible(item)}} disabled={multiDeleteMode}>
                                  <EditOutlined style={{ color: "rgba(0, 21, 41, 0.6)" }}/>
                                </Button>
                                <Button disabled={multiDeleteMode} onClick={() => {
                                  DeletionModal(deletePlateModalVisible,
                                    "Remove Sheet",
                                    "Are you sure you want to remove this sheet",
                                    ()=>{setDeletePlateModalVisible(false)},
                                    ()=>{handleSinglePlateDelete(item)},
                                    "Confirm",
                                    "Cancel",
                                    {loading: isRemovingPlate}
                                  )
                                }}>
                                  <DeleteOutlined style={{color: multiDeleteMode ? "grey" : "red"}}/>
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </div>
                      ))}
                    </React.Fragment>
                  )
                }
              </div>
            </Spin>
          </div>
        </React.Fragment>
      </Modal>
      <Modal visible={editPlateModalVisible}
             onCancel={handleEditPlateModalClose}
             title="Edit sheet dimensions"
             width={400}
             footer={
               <div align={'right'}>
                 <Button
                   type={"primary"}
                   style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
                   form={"edit-plate"}
                   key={"submit"}
                   htmlType={"submit"}
                   loading={isEditingPlate}
                 >
                   Save Changes
                 </Button>
               </div>}
             centered={true}
             bodyStyle={{ height: 132 }}
             zIndex={2}
             destroyOnClose={true}
      >
        <React.Fragment>
          <div className={"inputInfoText"}>
            <Form form={editPlateForm}
                  name={'edit-plate'}
                  onFinish={submitEditPlateForm}
            >
              <div style={{ display: "flex", flexDirection: "row"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 52}}>
                  Length:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'width'}
                    initialValue={currentPlate.width}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {
                        required: true,
                        message: 'Input required!',
                      },
                      ({getFieldValue}) => ({
                        validator(rule, value) {
                          if (!value || parseFloat(getFieldValue('height')) <= parseFloat(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Length must be larger or equal to width!');
                        },
                      }),
                    ]}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      type={"number"}
                      suffix={<span className={"inputInfoText"}>mm</span>}
                      min={1}
                    />
                  </Form.Item>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 52}}>
                  Width:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'height'}
                    initialValue={currentPlate.height}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {
                        required: true,
                        message: 'Input required!',
                      },
                    ]}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      type={"number"}
                      suffix={<span className={"inputInfoText"}>mm</span>}
                      min={1}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </React.Fragment>
      </Modal>

      <Modal visible={addPlateModalVisible}
             onCancel={handleAddPlateModalClose}
             title="Add new sheet"
             width={400}
             footer={
               <div align={'right'}>
                 <Button
                   type={"primary"}
                   style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
                   form={"add-plate"}
                   key={"submit"}
                   htmlType={"submit"}
                   loading={isAddingNewPlate}
                 >
                   Add Sheet
                 </Button>
               </div>}
             centered={true}
             bodyStyle={{ height: 132 }}
             zIndex={2}
             destroyOnClose={true}
      >
        <React.Fragment>
          <div className={"inputInfoText"}>
            <Form form={addPlateForm}
                  name={'add-plate'}
                  onFinish={submitAddPlateForm}
            >
              <div style={{ display: "flex", flexDirection: "row"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 52}}>
                  Length:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'width'}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {
                        required: true,
                        message: 'Input required!',
                      },
                      ({getFieldValue}) => ({
                        validator(rule, value) {
                          if (!value || parseFloat(getFieldValue('height')) <= parseFloat(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Length must be larger or equal to width!');
                        },
                      }),
                    ]}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      type={"number"}
                      placeholder={"Sheet length"}
                      suffix={<span className={"inputInfoText"}>mm</span>}
                      min={1}
                    />
                  </Form.Item>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row"}}>
                <div style={{ display: "flex", height: 32, alignItems: "center", marginRight: 6, minWidth: 52}}>
                  Width:
                </div>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    name={'height'}
                    validateTrigger={"onSubmit"}
                    rules={[
                      {
                        required: true,
                        message: 'Input required!',
                      },
                    ]}
                  >
                    <Input
                      className={"testClass"}
                      style={{ width: "100%"}}
                      placeholder={"Sheet width"}
                      type={"number"}
                      suffix={<span className={"inputInfoText"}>mm</span>}
                      min={1}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </React.Fragment>
      </Modal>
    </React.Fragment>
  )
}