import { storage } from '../utils'
import {acceptedJobs} from "../data/jobs";

const authData = storage.retrieveAuthData()

const initialState = {
  auth: {
    user: authData ? authData.user : null,
    loggingIn: false,
    registering: false,
    registeringCompany: false,
    redirect: null,
  },
  util: {
    isCollapsed: false,
    activeSubmenuKeys: [],
    activeCustomerSubmenuKeys: [],
  },
  producers: {
    producers: [],
    fetchingProducers: false,
    fetchingProducerDetails: false,
    producersPagination: {pageSize: 10, showSizeChanger: false},
  },
  subAdmins: {
    isAddingSubAdmin: false,
    subAdmins: [],
    isFetchingSubAdmins: false,
    subAdminsPagination: {pageSize: 10, showSizeChanger: false},
    isFetchingSubAdminsLimit: true,
    maxSubAdmins: 0,
    currentSubAdmins: 0,
    isEditingSubAdmin: false
  },
  customers: {
    customers: [],
    customersPagination: {pageSize: 6, showSizeChanger: false},
    fetchingCustomers: true,
    fetchingCustomerDetails: true,
    removingCustomer: false,
    togglingCustomerBlock: false,
  },
  corporateCustomers: {
    corporateCustomers: [],
    fetchingCorporateCustomers: false,
    fetchingCorporateCustomerDetails: false,
    corporateCustomersPagination: {pageSize: 10, showSizeChanger: false},
    removingCorporateCustomer: false,
    togglingCorporateCustomerBlock: false,
  },
  materials: {
    availableMaterials: [],
    isFetchingAvailableMaterials: false,
    materialPrices: [],
    isFetchingMaterialPrices: false,
    materialPlates: [],
    isFetchingMaterialPlates: false,
    materialPlatesData: [],
    isFetchingSingleMaterialPlates: false,
    isAddingNewPlate: false,
    isEditingPlate: false,
    isRemovingPlate: false,

    materialMargins: [],
    isFetchingMaterialMargins: false
  },
  bending: {
    bendingData: {},
    isFetchingBendingData: false,
    isFetchingSingleMaterialBends: false,
    bendingBendsData: [],
    isAddingNewBend: false,
    isEditingBend: false,
    isRemovingBend: false
  },
  simpleMachining: {
    simpleMachiningData: {},
    isFetchingSimpleMachiningData: false,
  },
  surfaceCoating: {
    surfaceCoatingData: {},
    isFetchingSurfaceCoatingData: false,
  },
  cuttingMachines: {
    isFetchingMachines: false,
    cuttingMachines: [],
    selectedMachine: {},
    isFetchingSelectedMachine: false,
    machineTypes: [],
    isFetchingMachineTypes: false,
    machinePriorities: [],
    isFetchingMachinePriorities: false,
    isUpdatingMachinePriorities: false,
    isCreatingCuttingMachine: false,
    isEditingCuttingMachine: false,
    isRemovingMachine: false
  },
  companySettings: {
    companyDetails: null,
    fetchingCompanyDetails: true,
    editingCompanySettings: false,
    resettingPassword: false
  },
  customerSettings: {
    editingCustomerSettings: false,
    resettingPassword: false,
  },
  admin: {
    logs: [],
    logsPagination: {pageSize: 5, showSizeChanger: false},
    isFetchingLogs: false,
  },
  customerRelations: {
    internalCustomerRelations: [],
    internalCustomerRelationsPagination: {pageSize: 10, showSizeChanger: false},
    fetchingInternalCustomerRelations: false,
    updatingInternalCustomer: false,

    externalCustomerRelations: [],
    externalCustomerRelationsPagination: {pageSize: 10, showSizeChanger: false},
    fetchingExternalCustomerRelations: false,

    updatingRegularCustomer: false,
    updatingCorporateCustomer: false,
    updatingExternalCustomer: false,
  },
  bulkOrder: {
    toProcess: 0,
    currentlyUploaded: 0,
    currentlyProcessed: 0,
  },
  orders: {
    isFetchingProducerPendingOrders: false,
    producerPendingOrders: [],
    producerPendingOrdersPagination: {pageSize: 10, showSizeChanger: false},

    isFetchingProducerAwaitingConfirmationOrders: false,
    producerAwaitingConfirmation: [],
    producerAwaitingConfirmationPagination: {pageSize: 6, showSizeChanger: false},

    isFetchingProducerAcceptedOrders: false,
    producerAcceptedOrders: [],
    producerAcceptedOrdersPagination: {pageSize: 10, showSizeChanger: false},

    isFetchingProducerArchivedOrders: false,
    producerArchivedOrders: [],
    producerArchivedOrdersPagination: {pageSize: 10, showSizeChanger: false},

    isFetchingCustomerPendingOrders: false,
    customerPendingOrders: [],
    customerPendingOrdersPagination: {pageSize: 10, showSizeChanger: false},

    isFetchingCustomerAcceptedOrders: false,
    customerAcceptedOrders: [],
    customerAcceptedOrdersPagination: {pageSize: 10, showSizeChanger: false},

    isFetchingCustomerArchivedOrders: false,
    customerArchivedOrders: [],
    customerArchivedOrdersPagination: {pageSize: 10, showSizeChanger: false},

    isFetchingCustomerAwaitingConfirmationOrders: false,
    customerAwaitingConfirmation: [],

    isFetchingDrafts: false,
    draftOrders: [],

    isCreatingDraftOrder: false,
    isFetchingDraftOrder: false,

    isProceedingToShipping: false,
    isChangingDraftStage: false,
    toAnalyze: [],
    isChangingDeliveryDate: false,

    isFetchingPendingOrder: false,
    pendingOrder: {},
    isAddingNewPart: false,
    isFetchingPendingOrderParts: false,
    isEditingPendingOrder: false,
    isResolvingPendingOrder: false,
    isRevertingOrder: false,
    isChangingDraftName: false,

    isFetchingAwaitingChanges: false,
    awaitingChanges: [],

    isFetchingRecentChanges: false,
    recentChanges: [],
    isGeneratingZip: false,

    fetchingArchivedBulkOrders: false,
    archivedInternalOrders: [],
    archivedCalculation: {},
    isFetchingArchivedCalculation: false,
    isFetchingInternalDraft: false,
    isArchivingCalculation: false,

    isFetchingArchivedCalculationCustomers: false,
    archivedCalculationCustomers: [],

    filesToUpload: []
  },
  order: {
    toProcess: 0,
    currentlyUploaded: 0,
    currentlyProcessed: 0
  },
  nesting: {
    orders: [],
    acceptedJobs: [],
    completedJobs: [],
    isFetchingOrders: false,
    isFetchingAcceptedJobs: false,
    isFetchingCompletedJobs: false,
    currentNesting: {
      error: null,
      isCurrentlyNesting: false,
      lastChangeTime: null,
      nestedPlates: [],
      nonNestedParts: [],
      userName: null,
      userEmail: null
    },
    isFetchingCurrentNesting: false,
    isFetchingPlateData: false,
    plateData: null
  }
}


export default initialState
