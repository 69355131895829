import React from "react";
import {Divider, Modal} from "antd";
import {DeleteOutlined} from "@ant-design/icons";

export const DeletionModal = (visibility, title, mainText, onCancel, onOk, okText="Confirm", cancelText="Cancel", okButtonProps, icon=null) => {
  const modalConfig = {
    className: "inputInfoText",
    visible: visibility,
    style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
    centered: true,
    onCancel: onCancel,
    onOk: onOk,
    destroyOnClose: true,
    width: 400,
    title: <span>{title}</span>,
    content: <div>
      <div><span>{mainText}</span>?</div>
      <Divider style={{marginBottom: 0, position: 'relative', right: 36, width: 334}}/>
    </div>,
    okText: <span style={{fontWeight: 500}}>{okText}</span>,
    cancelText: <span style={{fontWeight: 500}}>{cancelText}</span>,
    icon: icon ? icon : <DeleteOutlined style={{color: "red"}}/>,
    zIndex: 2,
    okButtonProps: okButtonProps
  }
  return Modal.confirm(modalConfig)
}