import {
  SUB_ADMINS_SET_SUB_ADMINS,
  SUB_ADMINS_IS_ADDING_SUB_ADMIN,
  SUB_ADMINS_IS_FETCHING_SUB_ADMINS,
  SUB_ADMINS_SET_SUB_ADMINS_PAGINATION,
  SUB_ADMINS_IS_FETCHING_SUB_ADMINS_LIMIT,
  SUB_ADMINS_SET_CURRENT_SUB_ADMINS,
  SUB_ADMINS_SET_MAX_SUB_ADMINS,
  SUB_ADMINS_IS_EDITING_SUB_ADMINS, SUB_ADMINS_SET_ADMIN_PERMISSIONS
} from "./actionTypes";
import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import {forceLogoutUser} from "./authActions";

const addSubAdmin = payload => dispatch => {
  dispatch({
    type: SUB_ADMINS_IS_ADDING_SUB_ADMIN,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/producer-sub-admin`, payload.data)
    .then(() => {
      dispatch({
        type: SUB_ADMINS_IS_ADDING_SUB_ADMIN,
        payload: false
      })
      message.show(message.type.success, "Successfully added new Admin!")
    })
    .catch((error) => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: SUB_ADMINS_IS_ADDING_SUB_ADMIN,
          payload: false
        })
        throw error
      }
    })
}

const fetchSubAdmins = payload => dispatch => {
  dispatch({
    type: SUB_ADMINS_IS_FETCHING_SUB_ADMINS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/producer-sub-admin?pageRowCount=${payload.pagination.pageSize}&pageIndex=${payload.page}&email=${payload.email}&name=${payload.name}`)
    .then((response) => {
      const pagination = { ...payload.pagination };
      pagination.total = response.data.totalCount;

      dispatch({
        type: SUB_ADMINS_SET_SUB_ADMINS,
        payload: response.data.subAdmins,
      })

      dispatch({
        type: SUB_ADMINS_SET_SUB_ADMINS_PAGINATION,
        payload: pagination,
      })

      dispatch({
        type: SUB_ADMINS_IS_FETCHING_SUB_ADMINS,
        payload: false
      })
    })
    .catch((error) => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: SUB_ADMINS_IS_FETCHING_SUB_ADMINS,
          payload: false
        })
      }
    })
}

const editSubAdmin = payload => dispatch => {
  dispatch({
    type: SUB_ADMINS_IS_EDITING_SUB_ADMINS,
    payload: true,
  })

  return http
    .put(`${API_BASE_URL}/producer-sub-admin/permissions/${payload.subAdminId}`, {permissions: payload.data})
    .then(() => {
      dispatch({
        type: SUB_ADMINS_SET_ADMIN_PERMISSIONS,
        payload: payload,
      })

      dispatch({
        type: SUB_ADMINS_IS_EDITING_SUB_ADMINS,
        payload: false
      })
    })
    .catch((error) => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: SUB_ADMINS_IS_EDITING_SUB_ADMINS,
          payload: false
        })
        throw error
      }
    })
}

const fetchSubAdminLimit = payload => dispatch => {
  dispatch({
    type: SUB_ADMINS_IS_FETCHING_SUB_ADMINS_LIMIT,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/producer-sub-admin/count`)
    .then((response) => {

      dispatch({
        type: SUB_ADMINS_SET_CURRENT_SUB_ADMINS,
        payload: response.data.currentActiveSubAdmins
      })

      dispatch({
        type: SUB_ADMINS_SET_MAX_SUB_ADMINS,
        payload: response.data.maxSubAdmins
      })

      dispatch({
        type: SUB_ADMINS_IS_FETCHING_SUB_ADMINS_LIMIT,
        payload: false
      })
    })
    .catch((error) => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: SUB_ADMINS_IS_FETCHING_SUB_ADMINS_LIMIT,
          payload: false
        })
      }
    })
}

const changeAdminState = payload => dispatch => {
  return http
    .put(`${API_BASE_URL}/producer-sub-admin/block/${payload.subAdminId}`, {isBlocked: payload.status})
    .then(() => {

    })
    .catch((error) => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error
      }
    })
}

export { addSubAdmin, fetchSubAdmins, fetchSubAdminLimit, editSubAdmin, changeAdminState }