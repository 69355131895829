import React from "react";
import {Card} from "antd";
import {S3_BUCKET_URL} from "../../../../constants";
import {formatTableDate} from "../../../../utils/utility";


export const NestedPartsList = (props) => {
  const { plateData } = props;

  if (plateData === null) {
    return <div/>
  }

  return (
    <div>
      {plateData.parts.map((item, index)=>{
        return (
          <Card
            key={index}
            style={{marginBottom: 6, width: "calc(100% - 8px)"}}
            className={"nestingOrderCard"}
            onMouseDown={(e) => {
              if (e.button === 1 || e.button === 0) {
                const win = window.open(`/accepted-order?order=${item.orderId}`, "_blank");
                win.focus();
              }
            }}
          >
            <div style={{display: "flex", width: "100%"}}>
              <div
                style={{
                  border: "1px solid rgb(205, 205, 205)",
                  borderRadius: 3,
                  marginRight: 8,
                  width: 70,
                  height: 60,
                }}
              >
                {item.thumbnailUrl &&
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%"
                    }}
                  >
                    <img
                      style={{
                        width: 50,
                        maxHeight: 50,
                        padding: 3,
                        objectFit: "contain"
                      }}
                      src={S3_BUCKET_URL + item.thumbnailUrl}
                      alt={"part"}
                    />
                  </div>
                }
              </div>
              <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
                  <div className={"nestedPartName"} style={{fontSize: 12, fontWeight: 600}}>
                    {item.partName}
                  </div>
                  <div className={"nestedPartName"} style={{fontSize: 12, fontWeight: 500}}>
                    Delivery: {formatTableDate(item.deliveryDate)}
                  </div>
                  <div style={{fontSize: 12, fontWeight: 500}}>
                    Quantity: {item.quantity}
                  </div>
                </div>
                <div align={"right"}>
                  <div>
                    <div style={{width: 20, height: 20, background: `rgb(${item.color.red},${item.color.green},${item.color.blue})`, marginBottom:5}}>

                    </div>
                    <div style={{fontSize: 12, fontWeight: 500}}>
                      Order:
                    </div>
                    <div style={{fontSize: 12, fontWeight: 500, marginBottom: -2}}>
                      {item.orderName}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </Card>
        )
      })}
    </div>
  )
}