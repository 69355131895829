import React, {Fragment, useEffect, useState} from 'react';
import {Layout, Button, Col, Row, Table, Input, Modal, Divider, Dropdown, Menu, Checkbox} from 'antd';

import {logoutUser} from '../../../actions/authActions';
import {fetchProducers} from '../../../actions/producersActions';
import {AdminSidebar} from '../../../components/admin';
import {useDispatch, useSelector} from 'react-redux';
import http from "../../../utils/http";
import {API_BASE_URL} from "../../../constants";
import {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {getColumnSearchProps} from "../../../utils/utility";
import {
  fetchCorporateCustomerDetails,
  fetchCorporateCustomers,
  toggleCorporateCustomerBlock
} from "../../../actions/corporateCustomersActions";
import {fetchCustomerDetails, toggleCustomerBlock} from "../../../actions/customersActions";

const corporateDummyData = [
  {
    id: "1",
    name: "Pera Peric",
    country: "Serbia",
    region: "",
    city: "Belgrade",
    address: "Malog Pinjeta 16",
    postalCode: "11000",
    vat: "75448579645",
    uid: "DF5613242",
    contactName: "Pera Peric",
    phone: "011 56100100",
    isBlocked: true
  },
  {
    id: "2",
    name: "Sara Saric",
    country: "Serbia",
    region: "",
    city: "Belgrade",
    address: "Malog Pinjeta 18",
    postalCode: "11000",
    vat: "75448579645",
    uid: "DF5613242",
    contactName: "Sara",
    phone: "011 57101100",
    isBlocked: false
  }
]

export const AllCorporateCustomers = (props) => {
  const [details, setDetails] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isBlockVisible, setIsBlockVisible] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isUnblockVisible, setIsUnblockingVisible] = useState(false);
  const [isUnblocking, setIsUnblocking] = useState(false);
  const [actionId, setActionId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState(null);

  const corporateCustomers = useSelector(state => state.corporateCustomers.corporateCustomers);
  const fetchingCorporateCustomers = useSelector(state => state.corporateCustomers.fetchingCorporateCustomers);
  const pagination = useSelector(state => state.corporateCustomers.corporateCustomersPagination);
  const togglingCorporateCustomerBlock = useSelector(state => state.corporateCustomers.togglingCorporateCustomerBlock)

  const dispatch = useDispatch();
  let searchInput = null;

  const handleClose = () => {
    setIsVisible(false);
    setDetails(null);
  }

  const initializeData = () => {
    dispatch(fetchCorporateCustomers({
      logout_user: logoutUser,
      type: 'customer',
      page: pagination.current !== undefined ? pagination.current : 1,
      pagination: pagination
    }))
  }

  useEffect(() => {
    initializeData();
  }, [])

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = {...pagination};
    pager.current = pagination.current
    let search = '';
    let searchField = '&searchFields=';
    let searchString = '&searchStrings=';

    if (filters.email !== undefined && filters.email !== null) {
      if (filters.email[0] !== undefined) {
        if (filters.name !== undefined && filters.name !== null) {
          if (filters.name[0] !== undefined) {
            searchField = '&searchFields=email,name';
            searchString = searchString + filters.email + "," + filters.name;
          } else {
            searchField = '&searchFields=email';
            searchString = searchString + filters.email;
          }
        } else {
          searchField = '&searchFields=email';
          searchString = searchString + filters.email;
        }
      }
    }
    if (filters.name !== undefined && filters.name !== null) {
      if (filters.name[0] !== undefined) {
        if (filters.email !== undefined && filters.email !== null) {
          if (filters.email[0] !== undefined) {
          } else {
            searchField = '&searchFields=name';
            searchString = searchString + filters.name;
          }
        } else {
          searchField = '&searchFields=name';
          searchString = searchString + filters.name;
        }
      }
    }

    search = search + searchField + searchString

    fetchProducers({
      logout_user: logoutUser,
      type: 'customer',
      page: pagination.current,
      pagination: pager,
      search: search
    })
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <AdminSidebar history={history} onLogout={() => dispatch(logoutUser({}))}/>
      <Layout.Content style={{marginLeft: 60}}>
        <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
          <Row type={"flex"}>
            <Col span={24}>
              <div style={{ height: 32, display: "flex", alignItems: "center" }}>
                <div style={{fontWeight: 500, fontSize: 16}}>These are all the registered corporate customers:</div>
              </div>
              <Divider style={{marginTop: 6, marginBottom: 11}}/>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              <Fragment>
                <Table
                  columns={[
                    {
                      title: 'Name',
                      className: "darkerColumn",
                      key: 'name',
                      render: row => (
                        <div>
                          {row.name}
                        </div>
                      ),
                      ...getColumnSearchProps('name', searchInput),
                    },
                    {
                      title: 'Country',
                      key: 'country',
                      render: row => (
                        <div>
                          {row.country}
                        </div>
                      ),
                      ...getColumnSearchProps('country', searchInput),
                    },
                    {
                      title: 'Phone Number',
                      className: "darkerColumn",
                      key: 'phone',
                      render: row => (
                        <div>
                          {row.phone}
                        </div>
                      ),
                      ...getColumnSearchProps('phone', searchInput),
                    },
                    {
                      title: "Blocked",
                      align: 'center',
                      key: "isBlocked",
                      render: row => (
                        <div className={row.isBlocked ? "trueDisabledCheckbox" : "falseDisabledCheckbox"}>
                          <Checkbox checked={row.isBlocked}
                                    disabled
                          />
                        </div>
                      )
                    },
                    {
                      title: 'Details',
                      className: "darkerColumn",
                      key: 'details',
                      align: 'center',
                      render: row => {
                        return (
                          <Button
                            onClick={() => {
                              dispatch(fetchCorporateCustomerDetails({id: row.id}))
                                .then(res => {setDetails(res.data)})
                                .catch(() => {setDetails(null)})
                              setIsVisible(true);
                            }}
                            type="primary">
                            <span style={{fontWeight: 600, fontSize: 13}}>Details</span>
                          </Button>)
                      },
                    },
                    {
                      title: 'Action',
                      key: 'action',
                      align: 'center',
                      render: row => {
                        return (
                          <Button
                            disabled={togglingCorporateCustomerBlock}
                            onClick={() => {dispatch(toggleCorporateCustomerBlock({id: row.id, isBlocked: !row.isBlocked}))}}
                            type="danger"
                            style={{ width: 88}}
                          >
                            <span style={{fontWeight: 600, fontSize: 13}}>{row.isBlocked ? "Unblock" : "Block"}</span>
                          </Button>
                        )
                      },
                    }
                  ]}
                  className={"pendingOrdersTable"}
                  dataSource={corporateDummyData}
                  rowKey="id"
                  loading={{
                    spinning: fetchingCorporateCustomers,
                    indicator: <LoadingOutlined style={{fontSize: 50, marginLeft: "-25px"}} spin/>
                  }}
                  onChange={handleTableChange}
                  size={"small"}
                  pagination={pagination}
                  scroll={{x: '70%'}}
                />

                <Modal
                  onCancel={handleClose}
                  title="Details"
                  visible={isVisible}
                  footer={null}
                  centered={true}
                >
                  {details === null ? null : (
                    <Fragment>
                      <Row>
                        <Col span={14}>
                          Email:
                          <h3>{details.email}</h3>
                          Name:
                          <h3>{details.company.data.name}</h3>
                          Address 1:
                          <h3>{details.company.data.street_address1}</h3>
                          City:
                          <h3>{details.company.data.city}</h3>
                          Region:
                          <h3>{details.company.data.region}</h3>
                          Country:
                          <h3>{details.company.data.country}</h3>

                        </Col>
                        <Col span={10}>
                          Postal Code:
                          <h3>{details.company.data.postal_code}</h3>
                          VAT number:
                          <h3>{details.company.data.vat_number}</h3>
                          ID number:
                          <h3>{details.company.data.id_number}</h3>
                          Contact Name:
                          <h3>{details.company.data.contact_person_first_name} {details.company.data.contact_person_last_name}</h3>
                          Phone number:
                          <h3>{details.company.data.contact_phone}</h3>
                          Field of activity:
                          <h3>{details.company.data.field_of_activity}</h3>
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                </Modal>

                <Modal
                  title="Block"
                  centered={true}
                  visible={isBlockVisible}
                  onCancel={() => setIsBlockVisible(false)}
                  footer={[
                    <Button key="cancel" onClick={() => setIsBlockVisible(false)}>Cancel</Button>,
                    <Button
                      key="submit"
                      type="danger"
                      loading={isBlocking}
                      onClick={() => {
                        setIsBlocking(true)
                        http
                          .post(
                            `${API_BASE_URL}/users/${actionId}/block`
                          )
                          .then(() => {
                            setActionId(null);
                            setIsBlocking(false);
                            setIsBlockVisible(false);
                            initializeData();
                          })
                      }}>
                      Block
                    </Button>,
                  ]}
                >
                  <h3>Are you sure you want to BLOCK this user ? </h3>
                </Modal>

                <Modal
                  title="Unblock"
                  centered={true}
                  visible={isUnblockVisible}
                  onCancel={() => {
                    setIsUnblockingVisible(false);
                  }}
                  footer={[
                    <Button key="cancel" onClick={() => {
                      setIsUnblockingVisible(false)
                    }}>Cancel</Button>,
                    <Button
                      key="submit"
                      type="danger"
                      loading={isUnblocking}
                      onClick={() => {
                        setIsUnblocking(true);
                        http
                          .post(
                            `${API_BASE_URL}/users/${actionId}/unblock`
                          )
                          .then(() => {
                            setActionId(null);
                            setIsUnblocking(false);
                            setIsUnblockingVisible(false)
                            initializeData();
                          })
                      }}>
                      Unblock
                    </Button>,
                  ]}
                >
                  <h3>Are you sure you want to UNBLOCK this user ? </h3>
                </Modal>

                <Modal
                  title="Delete"
                  centered={true}
                  visible={isDeleteVisible}
                  onCancel={() => {
                    setIsDeleteVisible(false)
                  }}
                  footer={[
                    <Button key="cancel" onClick={() => {
                      setIsDeleteVisible(false);
                    }}>Cancel</Button>,
                    <Button
                      key="submit"
                      type="danger"
                      loading={isDeleting}
                      onClick={() => {
                        setIsDeleting(true)
                        http
                          .delete(
                            `${API_BASE_URL}/companies/${deleteId}`
                          )
                          .then(() => {
                            setDeleteId(null);
                            setIsDeleting(false);
                            setIsDeleteVisible(false);
                            initializeData();
                          })
                      }}>
                      Delete
                    </Button>,
                  ]}
                >
                  <h3>Are you sure you want to DELETE {deleteEmail} ? </h3>
                </Modal>

              </Fragment>
            </Col>
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  )
}

