import * as React from "react";
import { AutoSizer, List } from "react-virtualized"
import {Button, Card, Checkbox, Spin} from "antd";
import "./style.css"
import {LoadingOutlined, SearchOutlined} from "@ant-design/icons";
import {formatTableDate} from "../../../../utils/utility";

export const NestingList = (props) => {
  const {checkedOrders, onCheck, orders, isFetchingOrders, searching} = props;

  if (orders.length === 0) {
    return (
      <React.Fragment>
        <Spin
          spinning={searching || isFetchingOrders}
          indicator={
            <div align={"center"}>
              <LoadingOutlined style={{ fontSize: 50, marginLeft: -20 }} spin />
            </div>
          }

        >
          <div className={"emptyAwaiting"} style={{height: "350px"}}>
            {
              !isFetchingOrders &&
              <div className={"emptyDraftsText"}>No Orders</div>
            }
          </div>
        </Spin>
      </React.Fragment>
    )
  } else {
    return (
      <AutoSizer>
        {({height, width}) => {
          return (
            <Spin
              spinning={searching || isFetchingOrders}
              style={{width}}
              indicator={
                <div align={"center"}>
                  <LoadingOutlined style={{fontSize: 50, marginLeft: -20}} spin/>
                </div>
              }
            >
              <List
                className={'nestingList'}
                width={width}
                height={height}
                rowCount={orders.length}
                rowHeight={100}
                overscanRowCount={4}
                rowRenderer={
                  ({index, key, style}) => {
                    const item = orders[index];
                    return (
                      <div
                        className={'nestingRow'}
                        key={key}
                        style={style}
                      >
                        <div
                          className={'nestingItem'}
                          key={index}
                        >
                          <Card
                            className={checkedOrders[item.id] ? "nestingOrderCard fadeInCard selectedNestingOrderCard" : "nestingOrderCard fadeInCard"}
                            bodyStyle={{height: "100%", width: "100%"}}
                            onClick={() => {
                              onCheck(item.id, !checkedOrders[item.id], item.orderName, item.remainingParts)
                            }}
                            onMouseDown={(e) => {
                              if (e.button === 1) {
                                const win = window.open(`/accepted-order?order=${item.id}`, "_blank");
                                win.focus();
                              }
                            }}
                          >
                            <div
                              style={{display: "flex", height: "100%", width: "100%"}}
                            >
                              <div>
                                <Checkbox
                                  checked={checkedOrders[item.id]}
                                  onChange={(e) => {
                                    e.stopPropagation()
                                    onCheck(item.id, e.target.checked, item.orderName, item.remainingParts)
                                  }}
                                />
                              </div>
                              <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                marginLeft: 8
                              }}>
                                <div
                                  style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                  <div
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 500,
                                      lineHeight: 1.5
                                    }}
                                  >
                                    Order {item.orderName}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 500
                                    }}
                                  >
                                    {item.customerName}
                                  </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                  <div align={"right"}>
                                    <Button
                                      size={"small"}
                                      style={{marginLeft: 5}}
                                      onClick={(e)=>{
                                        e.stopPropagation()
                                        const win = window.open(`/accepted-order?order=${item.id}`, "_blank");
                                        win.focus();
                                      }}
                                    >
                                      <SearchOutlined style={{fontSize: 13, color: "#1890ff"}}/>
                                    </Button>
                                  </div>
                                  <div
                                    align={"right"}
                                    style={{
                                      fontSize: 12
                                    }}
                                  >
                                    Remaining Parts: <span style={{fontWeight: 500}}>{item.remainingParts}</span>
                                  </div>
                                  <div
                                    align={"right"}
                                    style={{
                                      fontSize: 12
                                    }}
                                  >
                                    Delivery Date: <span style={{fontWeight: 500}}>{formatTableDate(item.deliveryDate)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      </div>
                    )
                  }
                }
              />
            </Spin>
          )
        }}
      </AutoSizer>
    )
  }
}