import {
  SIMPLE_MACHINING_SET_DATA,
  SIMPLE_MACHINING_IS_FETCHING_DATA,
  SIMPLE_MACHINING_SET_SIMPLE_MACHINING_SERVICE,
  SIMPLE_MACHINING_SET_DEBURRING_SERVICE,
  SIMPLE_MACHINING_SET_CHAMFERING_SERVICE,
  SIMPLE_MACHINING_SET_DRILLING_SERVICE,
  SIMPLE_MACHINING_SET_DEBURRING_DATA,
  SIMPLE_MACHINING_SET_CHAMFERING_DATA,
  SIMPLE_MACHINING_SET_DRILLING_DATA,
  SIMPLE_MACHINING_SET_PREPARATION_DATA
} from "./actionTypes";
import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import {forceLogoutUser} from "./authActions";

const fetchSimpleMachiningData = () => dispatch => {
  dispatch({
    type: SIMPLE_MACHINING_IS_FETCHING_DATA,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/simple-machining`)
    .then(response => {
      dispatch({
        type: SIMPLE_MACHINING_SET_DATA,
        payload: response.data,
      })

      dispatch({
        type: SIMPLE_MACHINING_IS_FETCHING_DATA,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: SIMPLE_MACHINING_IS_FETCHING_DATA,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSimpleMachiningService = payload => dispatch => {
  dispatch({
    type: SIMPLE_MACHINING_SET_SIMPLE_MACHINING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/simple-machining`, {hasSimpleMachining: payload.value})
    .then(response => {

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSimpleMachiningDeburring = payload => dispatch => {
  dispatch({
    type: SIMPLE_MACHINING_SET_DEBURRING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/simple-machining/has-deburring`, {hasDeburring: payload.value})
    .then(response => {

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSimpleMachiningChamfering = payload => dispatch => {
  dispatch({
    type: SIMPLE_MACHINING_SET_CHAMFERING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/simple-machining/has-chamfering`, {hasChamfering: payload.value})
    .then(response => {

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSimpleMachiningDrilling = payload => dispatch => {
  dispatch({
    type: SIMPLE_MACHINING_SET_DRILLING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/simple-machining/has-drilling`, {hasDrilling: payload.value})
    .then(response => {

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setDeburringData = payload => dispatch => {
  dispatch({
    type: SIMPLE_MACHINING_SET_DEBURRING_DATA,
    payload: payload,
  })
}

const setChamferingData = payload => dispatch => {
  dispatch({
    type: SIMPLE_MACHINING_SET_CHAMFERING_DATA,
    payload: payload,
  })
}

const setDrillingData = payload => dispatch => {
  dispatch({
    type: SIMPLE_MACHINING_SET_DRILLING_DATA,
    payload: payload,
  })
}

const setPreparationData = payload => dispatch => {
  dispatch({
    type: SIMPLE_MACHINING_SET_PREPARATION_DATA,
    payload: payload,
  })
}

export {
  fetchSimpleMachiningData,
  setSimpleMachiningService,
  setSimpleMachiningDeburring,
  setSimpleMachiningChamfering,
  setSimpleMachiningDrilling,
  setDeburringData,
  setChamferingData,
  setDrillingData,
  setPreparationData
}
