import React, {Fragment, useEffect, useState} from 'react';
import {Layout, Col, Row, Table, Input, Button, Divider} from 'antd';
import {logoutUser} from '../../../actions/authActions';
import {AdminSidebar} from '../../../components/admin';
import {useDispatch, useSelector} from 'react-redux';
import {fetchLogs} from "../../../actions/logActions";
import {LoadingOutlined} from "@ant-design/icons";
import {getColumnSearchProps} from "../../../utils/utility";

export const AdminLogPage = () => {
  const logs = useSelector(state => state.admin.logs);
  const isFetchingLogs = useSelector(state => state.admin.isFetchingLogs);
  const pagination = useSelector(state => state.admin.logsPagination);

  const dispatch = useDispatch();

  let searchInput = null;

  const initializeData = () => {
    fetchLogs({
      logout_user: logoutUser,
      page: pagination.current !== undefined ? pagination.current : 1,
      pagination: pagination
    })
  }

  useEffect(() => {
    initializeData();
  }, [])

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = {...pagination};
    pager.current = pagination.current;

    let search = '';
    let searchField = '&searchFields=';
    let searchString = '&searchStrings=';

    if (filters.email !== undefined && filters.email !== null) {
      if (filters.email[0] !== undefined) {
        if (filters.message !== undefined && filters.message !== null) {
          if (filters.message[0] !== undefined) {
            searchField = '&searchFields=email,message';
            searchString = searchString + filters.email + "," + filters.message;
          } else {
            searchField = '&searchFields=email';
            searchString = searchString + filters.email;
          }
        } else {
          searchField = '&searchFields=email';
          searchString = searchString + filters.email;
        }
      }
    }
    if (filters.message !== undefined && filters.message !== null) {
      if (filters.message[0] !== undefined) {
        if (filters.email !== undefined && filters.email !== null) {
          if (filters.email[0] !== undefined) {
          } else {
            searchField = '&searchFields=message';
            searchString = searchString + filters.message;
          }
        } else {
          searchField = '&searchFields=message';
          searchString = searchString + filters.message;
        }
      }
    }

    search = search + searchField + searchString

    fetchLogs({
      logout_user: logoutUser,
      page: pagination.current,
      pagination: pager,
      search: search
    })
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}

    >
      <AdminSidebar onLogout={() => dispatch(logoutUser({}))}/>
      <Layout.Content style={{marginLeft: 60}}>
        <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
          <Row type={"flex"}>
            <Col span={24}>
              <div style={{ height: 32, display: "flex", alignItems: "center" }}>
                <div style={{fontWeight: 500, fontSize: 16}}>These are all the site logs:</div>
              </div>
              <Divider style={{marginTop: 6, marginBottom: 11}}/>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              {/*{isFetchingLogs ? (*/}
              {/*    <div style={{transform: 'translate(45%,200%)'}}>*/}
              {/*        <Spin indicator={<Icon type="loading" style={{ fontSize: 80 }} spin />}/>*/}
              {/*    </div>*/}
              {/*) : (*/}
              <Fragment>
                <Table
                  columns={[
                    {
                      title: 'Time',
                      key: 'time',
                      render: row => (
                        <div>
                          {row.creation_time}
                        </div>
                      ),
                    },
                    {
                      title: 'User',
                      key: 'user',
                      render: row => (
                        <div>
                          {row.user_id}
                        </div>
                      ),
                    },
                    {
                      title: 'Email',
                      key: 'email',
                      render: row => (
                        <div>
                          {row.email}
                        </div>
                      ),
                      ...getColumnSearchProps('email', searchInput),
                    },
                    {
                      title: 'Action',
                      key: 'message',
                      render: row => (
                        <div>
                          {row.message}
                        </div>
                      ),
                      ...getColumnSearchProps('message', searchInput),
                    },
                  ]}
                  className={"pendingOrdersTable"}
                  dataSource={logs}
                  rowKey="id"
                  pagination={pagination}
                  loading={{
                    spinning: isFetchingLogs,
                    indicator: <LoadingOutlined style={{fontSize: 50, marginLeft: "-25px"}} spin/>
                  }}
                  size={"small"}
                  onChange={handleTableChange}
                  scroll={{x: '70%'}}
                />
              </Fragment>
            </Col>
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  )
}

