import React, {useEffect, useState} from 'react';
import {
  Card, Spin, Tabs
} from 'antd';
import ValueTable from "../../../CompanyMaterials/MaterialTables/ValueTable";
import MaterialTableContainer from "../../../CompanyMaterials/MaterialTableContainer";
import {LoadingOutlined} from "@ant-design/icons";
import {
  fetchMaterialMargins, updateMaterialMargin,
} from "../../../../../actions/materialsActions";
import {useDispatch, useSelector} from "react-redux";

const { TabPane } = Tabs;

export const NestingSettings = (props) => {
  const { selectedTab, socketConnected } = props

  const [activeLoadingValueTabs, setActiveLoadingValueTabs] = useState({})
  const [shouldUpdateMargins, setShouldUpdateMargins] = useState(false);

  const dispatch = useDispatch()

  const materialMargins = useSelector(state => state.materials.materialMargins)
  const isFetchingMaterialMargins = useSelector(state => state.materials.isFetchingMaterialMargins)

  useEffect(()=>{
    if(selectedTab === "4") {
      dispatch(fetchMaterialMargins()).then(() => {
        setShouldUpdateMargins(true)
      })
    }
  }, [selectedTab])

  const onMarginChange = (params) => {
    if(!activeLoadingValueTabs[params.tab]) {
      props.changeMaterialMargin({materialId: params.materialId, margin: params.value})
      dispatch(updateMaterialMargin(params))
    }
    return Promise.resolve(10)
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"} bodyStyle={{paddingRight: 0}}>
        <div style={{height: 'calc(100vh - 120px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>

          <div className={"customColumn"}>
            <div style={{maxWidth: 1220}}>
              <Spin spinning={isFetchingMaterialMargins || !socketConnected}
                    style={{zIndex: 1}}
                    indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, 50%)" }} spin />}
              >
                {materialMargins.length === 0 ?
                  <Card bordered={false}
                        bodyStyle={{border: "1px solid #f0f0f0", borderRadius: 5, height: 394, maxWidth: 880}}
                        style={{marginBottom: 20, borderRadius: 5, maxWidth: 880}}
                  >

                  </Card>
                  :
                  <Tabs style={{maxWidth: 1220, marginLeft: -10, marginBottom: 20}}
                        type="card"
                        className={"laserSpeedTabs"}
                  >
                    {materialMargins.map((item, index) => {
                      return (
                        <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                          <MaterialTableContainer tableText={"Margins"}
                                                  xAxisText={"Available Thickness (mm)"}
                                                  yAxisText={"Material Grade"}
                                                  xAxisStyle={{marginTop: 12, marginBottom: -12}}
                          >
                            <ValueTable
                              columns={item.columns}
                              rows={item.rows}
                              allAvailable={item.allAvailable}
                              tabIndex={index}
                              onChange={onMarginChange}
                              updateTable={shouldUpdateMargins}
                              setUpdateTable={setShouldUpdateMargins}
                              activeLoadingTabs={activeLoadingValueTabs}
                            />
                          </MaterialTableContainer>
                        </TabPane>
                      )
                    })}
                  </Tabs>
                }
              </Spin>
            </div>
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}