import {Col, Row} from 'antd'
import {toast} from "react-toastify";
import {CheckCircleOutlined,CloseCircleOutlined,InfoCircleOutlined,WarningOutlined} from "@ant-design/icons";
import React from "react";

const type = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
}

const show = (type, content) => {
  const args = {
    position: "top-center",
    autoClose: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    toastId: type
  }
  if(type === 'error') {
    toast[type](<React.Fragment><Row><Col span={2} style={{}}><CloseCircleOutlined style={{ fontSize: '18px' }} /></Col><Col span={21} offset={1}>{content}</Col></Row></React.Fragment>, args)
  } else if(type === 'success') {
    toast[type](<React.Fragment><Row><Col span={2} style={{}}><CheckCircleOutlined style={{ fontSize: '18px' }} /></Col><Col span={21} offset={1}>{content}</Col></Row></React.Fragment>, args)
  } else if(type === 'info') {
    toast[type](<React.Fragment><Row><Col span={2} style={{}}><InfoCircleOutlined style={{ fontSize: '18px' }} /></Col><Col span={21} offset={1}>{content}</Col></Row></React.Fragment>, args)
  } else if(type === 'warning') {
    toast[type](<React.Fragment><Row><Col span={2} style={{}}><WarningOutlined style={{ fontSize: '18px' }} /></Col><Col span={21} offset={1}>{content}</Col></Row></React.Fragment>, args)
  }
}

export default {
  show,
  type,
}
