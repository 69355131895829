import {
  CUTTING_MACHINES_IS_CREATING_CUTTING_MACHINE, CUTTING_MACHINES_IS_DELETING_CUTTING_MACHINE,
  CUTTING_MACHINES_IS_EDITING_CUTTING_MACHINE,
  CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINE_DATA,
  CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINES,
  CUTTING_MACHINES_IS_FETCHING_MACHINE_PRIORITIES,
  CUTTING_MACHINES_IS_FETCHING_MACHINE_TYPES,
  CUTTING_MACHINES_IS_UPDATING_MACHINE_PRIORITIES,
  CUTTING_MACHINES_SET_CUTTING_MACHINES,
  CUTTING_MACHINES_SET_CUTTING_SPEED,
  CUTTING_MACHINES_SET_MACHINE_DATA,
  CUTTING_MACHINES_SET_MACHINE_PARAMETER,
  CUTTING_MACHINES_SET_MACHINE_PRIORITIES,
  CUTTING_MACHINES_SET_MACHINE_TYPES,
} from '../actions/actionTypes'
import initialState from './initialState'

const cuttingMachinesReducer = (state = initialState.cuttingMachines, action) => {
  switch (action.type) {
    case CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINES:
      return {
        ...state,
        isFetchingMachines: action.payload,
      }

    case CUTTING_MACHINES_SET_CUTTING_MACHINES:
      return {
        ...state,
        cuttingMachines: action.payload,
      }

    case CUTTING_MACHINES_IS_FETCHING_MACHINE_TYPES:
      return {
        ...state,
        isFetchingMachineTypes: action.payload,
      }

    case CUTTING_MACHINES_SET_MACHINE_TYPES:
      return {
        ...state,
        machineTypes: action.payload,
      }

    case CUTTING_MACHINES_IS_FETCHING_MACHINE_PRIORITIES:
      return {
        ...state,
        isFetchingMachinePriorities: action.payload,
      }

    case CUTTING_MACHINES_SET_MACHINE_PRIORITIES:
      return {
        ...state,
        machinePriorities: action.payload,
      }

    case CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINE_DATA:
      return {
        ...state,
        isFetchingSelectedMachine: action.payload,
      }

    case CUTTING_MACHINES_SET_MACHINE_DATA:
      return {
        ...state,
        selectedMachine: action.payload,
      }
    case CUTTING_MACHINES_SET_CUTTING_SPEED:
      let tmp = [...state.selectedMachine.tables]
      tmp[action.payload.tab].rows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        selectedMachine: {...state.selectedMachine, tables: tmp},
      }

    case CUTTING_MACHINES_SET_MACHINE_PARAMETER:
      return {
        ...state,
        selectedMachine: {...state.selectedMachine, ...action.payload},
      }

    case CUTTING_MACHINES_IS_UPDATING_MACHINE_PRIORITIES:
      return {
        ...state,
        isUpdatingMachinePriorities: action.payload
      }

    case CUTTING_MACHINES_IS_CREATING_CUTTING_MACHINE:
      return {
        ...state,
        isCreatingCuttingMachine: action.payload
      }

    case CUTTING_MACHINES_IS_EDITING_CUTTING_MACHINE:
      return {
        ...state,
        isEditingCuttingMachine: action.payload
      }

    case CUTTING_MACHINES_IS_DELETING_CUTTING_MACHINE:
      return {
        ...state,
        isRemovingMachine: action.payload
      }
    default:
      return state
  }
}

export default cuttingMachinesReducer
