export const getAvailableThicknesses = (fileType, processingType, materials, selectedMaterial, itemMaterials) => {
  let rez;
  for (let material of materials) {
    rez = material.grades.find(type => type.grade === selectedMaterial.grade)
    if (rez) {
      rez.groupName = material.groupName
      break;
    }
  }

  if(!rez) {
    return [{id: selectedMaterial.materialId, thickness: selectedMaterial.thickness, notAvailable: true}]
  }

  if(selectedMaterial.isAvailable || selectedMaterial.isAvailable === undefined) {
    if(fileType === "dxf" && processingType === "automatic") {
      return rez.thickness.filter(item => itemMaterials?.[rez.groupName]?.[rez.grade]?.[item.id])
    }
    else {
      return rez.thickness
    }
  }
  else {
    // TODO: Sredi kada ima materijal i dalje ali nema thickness
    return rez.thickness.filter(item => itemMaterials?.[rez.groupName]?.[rez.grade]?.[item.id])
  }
}

