import React from 'react'
import { render } from 'react-dom'
import './i18n';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import './index.css'
import 'antd/dist/antd.css'

import { auth } from './utils'
import { routes } from './routes'
import { App } from './containers'
import history from "./utils/history";
import { configureStore } from './store/configureStore'


const store = configureStore(history)
auth.setStore(store)
routes.setStore(store)

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App routes={routes.all} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)
